/** @module legacy/app/directives/activity */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/site_wrap/activity/_dropdown.html';

/***/
const DEFAULT_COUNT = { invitations: 0, notifications: 0, total: 0 };

/**
 * @class Dropdown
 *
 */
function Controller($root, $scope) {
  $scope.tab = 'notifications';
  $scope.pending = false;
  $scope.count = { ...DEFAULT_COUNT };
  $scope.onTabSelect = onTabSelect;
  $scope.openCloseState = 'closed';
  $scope.timeout = null;

  $root.$on('onNotification', (s, count) => run(() => {
    $scope.count = count;
    if (count.notifications > 0 && $scope.openCloseState === 'open') {
      // clear the notification after 10 seconds
      // if the user has the notifications dropdown open
      $scope.timeout = setTimeout(() => {
        $root.markAllNotificationsSeen();
      }, 10000);
    }
  }));

  $scope.toggleNotifsList = (state) => run(() => {
    $scope.openCloseState = state;
    if (state === 'open' && $scope.count.notifications > 0) {
      $root.markAllNotificationsSeen();
    }
  });

  $scope.$on('$destroy', () => {
    if ($scope.timeout != null) {
      clearTimeout($scope.timeout);
    }
  });

  function onTabSelect(tab) {
    $scope.tab = tab;
  }
}

Controller.$inject = [
  '$rootScope',
  '$scope'
];

export default {
  controller: Controller,
  template
};


