/** @module core/services/activity */
import find from 'lodash/find';
import each from 'lodash/each';

/***/
const RESULT_LIMIT = 50;

/**
 * @class NotificationCollectionFactory
 *
 */
function NotificationCollectionFactory($rootScope, $q, Signal, Notification, $Collection) {
  function $NotificationCollection(notifications, unread) {
    this.$collection = new $Collection(notifications);
    this.$pageRequest = null;
    this.unread = unread;
    this.unseen = 0;
    this.page = 1;
    this.end = false;
    this.onChange = new Signal(this);
    this.$collection.onChange.attach(this.$onChange.bind(this));

    $rootScope.$on('onNotification', (e, count, prevCount) => {
      if (count.notifications !== prevCount.notifications) {
        Notification.all({ perPage: count.notifications, page: 1 }).then(e => {
          this.unread = e.unread;
          this.$collection.unshift(e.notifications);
        });
      }
    });
  }

  $NotificationCollection.query = function query() {
    return Notification.all({ perPage: RESULT_LIMIT, page: 1 }).then(e =>
      new $NotificationCollection(e.notifications, e.unread)
    );
  };

  $NotificationCollection.prototype = {
    get values() {
      return this.$collection.values;
    }
  };

  $NotificationCollection.prototype.$onChange = function $onChange() {
    this.onChange.dispatch(this, { values: this.values, unread: this.unread });
  };

  $NotificationCollection.prototype.fetch = function fetch() {
    if (this.end) {
      this.$pageRequest = $q.resolve(this);
    } else if (this.$pageRequest == null) {
      this.$pageRequest = Notification.query({ perPage: RESULT_LIMIT, page: this.page + 1 })
        .then(notifications => {
          if (notifications.length < RESULT_LIMIT) {
            this.end = true;
          } else {
            this.page += 1;
          }
          return this.$collection.push(notifications);
        })
        .then(() => this)
        .finally(() => this.$pageRequest = null);
    }
    return this.$pageRequest;
  };

  $NotificationCollection.prototype.read = function read(id) {
    const notification = find(this.values, { id });
    if (notification != null && !notification.read) {
      notification.read = true;
      this.unread -= 1;
      return Notification.read(id)
        .then(() => this.$onChange())
        .then(() => this);
    }

    return $q.resolve(this);
  };

  $NotificationCollection.prototype.readAll = function readAll() {
    if (this.unread === 0) {
      return $q.resolve(this);
    }

    each(this.values, (n) => {
      if (!n.read) {
        this.unread -= 1;
      }
      n.read = true;
    });

    return Notification.readAll()
      .then(() => this.$onChange())
      .then(() => this);
  };

  return $NotificationCollection;
}

NotificationCollectionFactory.$inject = [ '$rootScope', '$q', 'core.Signal', 'core.services.Notification', 'core.services.activity.$Collection' ];

export default NotificationCollectionFactory;
