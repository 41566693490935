/**
 * @module Directives
 *
 */
import { uniq } from "app/utils/array";
import { run } from "app/utils/runloop";
import { postCreated, postReplied } from "app/utils/analytics-helpers/posts";
import { spaceIncludesJack } from "app/utils/appcues";
import template from "ngapp/templates/directives/_posting_form.html";
/**
 * @class PostingForm
 *
 */
function PostingForm(
  $q,
  $timeout,
  $translate,
  $location,
  Resource,
  identity,
  Post,
  Tag,
  paywallModal,
  planFeature,
  bulkPostHelpers,
  Team,
  TeamGroup,
  tracking,
  media,
  scheduledPostModal
) {
  function link(scope, element, attrs) {
    const postDetails = element.find(".post-form-details");
    scope.pending = true;
    scope.feedContext = attrs.feedContext || false;
    scope.scheduleContext = attrs.scheduleContext || false;
    scope.spaceTemplateContext = attrs.spaceTemplateContext || false;
    scope.reactSchedule = attrs.reactSchedule || false;
    scope.canPostMedia = null;
    scope.file = null;
    scope.previewCSS = null;
    scope.previewPlaceholderClassName = null;
    scope.onAttachment = onAttachment;
    scope.updateMediaPosting = updateMediaPosting;
    scope.onMediaLockedClick = onMediaLockedClick;
    scope.onScheduledAtChange = onScheduledAtChange;
    scope.selectedLocations = [];
    scope.errors = {};
    scope.inputTags = [];
    scope.currentUser = {};
    scope.post = {
      tags: [],
    };
    scope.canSchedulePosts = false;
    scope.scheduledValue = null;
    scope.onScheduleChange = onScheduleChange;
    scope.onScheduleClick = onScheduleClick;
    scope.canUserSchedule = hasActiveProSubscription();
    scope.isSmartList = window.location.pathname.includes("smart_list");
    scope.isSegments = window.location.pathname.includes("/spaces/post");
    scope.hasSelectSpaces = false;
    scope.url = window.location.pathname;
    scope.smartListFunction = smartListFunction();
    scope.onCheckboxChange = onCheckboxChangeFunction;

    // function canAddMedia() {
    //   console.log(scope.currentUser.planPermissions.includes("upload_to_library"));
    //   if (scope.currentUser != null) {
    //     const user = scope.currentUser;

    //     if (user != null) {
    //       return user.planPermissions.includes("upload_to_library");
    //     }
    //   }
    //   return false;
    // }

    function hasActiveProSubscription() {
      if (scope.currentUser != null) {
        const plan = scope.currentUser.plan;

        if (plan != null) {
          return plan.planCode.startsWith("coachpro+");
        }
      }
      return false;
    }

    function onScheduleClick() {
      paywallModal.proUpgrade();
    }

    if (scope.focus) {
      $timeout(() => run(() => postDetails.focus()));
    }

    function onScheduleChange(pickedAt, pickedTime) {
      scope.scheduledValue = pickedAt;
      if (pickedAt) {
        if (pickedTime === "Days") {
          scope.post.scheduledIn = pickedAt * 24;
        } else if (pickedTime === "Weeks") {
          scope.post.scheduledIn = pickedAt * 7 * 24;
        } else if (pickedTime === "Hours") {
          scope.post.scheduledIn = pickedAt;
        } else {
          return null;
        }
      }
    }

    identity
      .request()
      .then((user) =>
        run(() => {
          scope.currentUser = user;
          scope.canSchedulePosts = planFeature.user.canSchedulePosts(user);
          scope.canUserSchedule = planFeature.user.canSchedulePosts(user);
          scope.canPostMedia = planFeature.user.canAddMedia(user);

          if (scope.rootPost != null) {
            const teamGroup = scope.rootPost.teamGroup;
            let team = scope.rootPost.team;

            if (teamGroup != null) {
              team = teamGroup.team;
              scope.group = teamGroup;
            }

            if (team != null) {
              scope.team = team;
            }
          }
        })
      )
      .finally(() => run(() => (scope.pending = false)));

    scope.onScheduleClick = () => {
      paywallModal.proUpgrade();
    };

    // post.tags = values
    scope.onUpdateTags = (tags) => {
      tags = tags != null ? tags : [];
      const values = tags.map((item) => item.name);
      const curTagsAdded = (scope.inputTags || [])
        .slice(0)
        .filter((item) => values.indexOf(item) !== -1);
      const currentTags = (scope.post.tags || [])
        .slice(0)
        .filter((item) => values.indexOf(item) !== -1);
      const newTags = (values || [])
        .slice(0)
        .filter((item) => currentTags.indexOf(item) === -1);

      scope.inputTags = uniq([...curTagsAdded, ...newTags]);
      scope.post.tags = uniq([...currentTags, ...newTags]);
    };

    function onMediaLockedClick() {
      // This makes the most sense, because this can be any combination of team reason.
      // paywallModal.postMediaOtherOwner();
      paywallModal.proUpgrade();
    }

    function updateMediaPosting(value) {
      scope.canPostMedia = value;
    }

    function onScheduledAtChange(data) {
      scope.post.scheduledAt = new Date(data).toISOString();
    }

    function reset() {
      scope.expanded = false;
      if (!angular.isDefined(scope.allowChooseTags)) {
        scope.allowChooseTags = true;
      }
      if (!angular.isDefined(scope.allowSelectSpace)) {
        scope.allowSelectSpace = false;
      }
      scope.selectedLocations = [];
      scope.post = {
        tags: [],
      };
      scope.resource = {};
      scope.resources = [];
      scope.resourceId = null;
      scope.resourceType = "image";
      scope.file = null;
      scope.errors = {};
      scope.scheduledValue = null;
      scope.post.scheduledIn = null;
      scope.hasSelectSpaces = false;
    }

    function onCheckboxChangeFunction(selectedDestinations) {
      scope.selectedLocations = selectedDestinations;
    }

    scope.expandForm = () => {
      if (!scope.rootPost || (scope.rootPost && scope.rootPost.can.reply)) {
        scope.expanded = true;
        postDetails.focus();
        if (scope.allowChooseTags) {
          loadTags();
        }
      }
    };

    function smartListFunction() {
      if (scope.isSmartList) {
        scope.allowSelectSpace = true;
        scope.expanded = true;
        postDetails.focus();
        if (scope.allowChooseTags) {
          loadTags();
        }
        // const segments = scope.url.split('/');
        // const id = segments[segments.length - 1];
      }
    }

    scope.removeMedia = () => {
      // remove resource tags from post tags
      const tags = scope.post.tags.filter(
        (tag) => scope.resource.tags.indexOf(tag) === -1
      );
      scope.post.tags = uniq([...tags, ...scope.inputTags]);
      scope.resource = {};
      scope.resources = [];
      scope.file = null;
    };

    scope.$watch("post.details", (newValue) => {
      if (!newValue) return;
      scope.expandForm();
    });

    scope.cancel = () => {
      if (scope.rootPost) {
        postReplied("Cancelled", scope.rootPost);
      } else {
        postCreated("Cancelled", scope.post);
      }
      reset();

      if (scope.cancelHandler) {
        scope.cancelHandler();
      }
    };

    scope.onUpload = (resource) => run(() => (scope.resourceId = resource.id));

    const resyncPost = (id) => {
      return Post.query(
        {
          "options[include]": ["replies", "space", "scheduled_in"],
        },
        {
          id,
        }
      )
        .then((post) => {
          reset();
          setInitialExpansion();
          postDetails.focus();
          sendAnalytics(post);
          onPost(post);
          scope.$emit("loadListEvent");
        })
        .catch(() =>
          $translate("DIRECTIVES.POSTING_FORM.LOAD_ERROR_MESSAGE").then(alert)
        );
    };

    const sendAnalytics = (post) => {
      if (scope.group != null) {
        TeamGroup.get(scope.group.id).then((group) => {
          tracking.teamGroups.postCreated(group, group.team, post);
        });
      } else if (scope.team) {
        Team.get(scope.team.id).then((team) => {
          tracking.teams.postCreated(team, post);
        });
      } else {
        if (scope.rootPost) {
          postReplied("Created", scope.rootPost);
        } else {
          if (scope.isJumpingJackPost) {
            postCreated("Created for Jumping Jack", post);
          } else {
            postCreated("Created", post);
          }
        }
      }
    };

    const createPostError = (response) => {
      if (response && response.data && response.data.errors) {
        const errors = response.data.errors;
        Object.keys(errors).forEach((key) => {
          if (key === "details") {
            scope.errors[key] = $translate.instant(
              "DIRECTIVES.POSTING_FORM.ERRORS.NO_DETAILS"
            );
          } else {
            scope.errors[key] = errors[key][0];
          }
        });
      }
    };

    scope.create = function () {
      if (scope.processing) return;

      scope.processing = true;

      if (scope.postParams) {
        angular.extend(scope.post, scope.postParams);
      }

      scope.errors = {};
      if (!scope.rootPost) {
        if (scope.isSmartList) {
          const url = scope.url.split("/");
          const selectedLocationId = url[url.length - 1];

          scope.post = Object.assign({}, scope.post, {
            smart_list_ids: selectedLocationId,
          });
        }

        if (scope.isSmartList && scope.selectedLocations.length > 0) {
          scope.post = Object.assign({}, scope.post, {
            smart_list_ids: scope.selectedLocations[0].id,
          });
        }
        if (scope.selectedLocations.length > 0 && !scope.isSmartList) {
          // angular version
          scope.post = Object.assign(
            {},
            scope.post,
            bulkPostHelpers.buildBulkPostParams(scope.selectedLocations)
          );

          // react version
          // scope.post = Object.assign({}, scope.post, scope.selectedLocations);
        } else if (
          scope.post.spaceTemplateId == null &&
          scope.post.segmentIds == null &&
          !scope.isSmartList
        ) {
          scope.errors.noLocations = $translate.instant(
            "DIRECTIVES.POSTING_FORM.ERRORS.NO_LOCATIONS"
          );
          scope.processing = false;
          return;
        }
      }

      if (scope.scheduleContext && !scope.post.scheduledAt) {
        scope.errors.scheduledAt = $translate.instant(
          "DIRECTIVES.POSTING_FORM.ERRORS.NO_SCHEDULED_AT"
        );
        scope.processing = false;
        return;
      }

      // Workaround for ability to pick past time for schedule date
      if (
        (scope.scheduleContext || scope.feedContext) &&
        scope.post.scheduledAt
      ) {
        if (new Date(scope.post.scheduledAt) < new Date()) {
          scope.errors.scheduledAt = $translate.instant(
            "DIRECTIVES.POSTING_FORM.ERRORS.SCHEDULED_AT_INVALID"
          );
          scope.processing = false;
          return;
        }
      }

      scope.isJumpingJackPost = false;
      const sendPost = () => {
        return new Post(scope.post)
          .create()
          .then((postCreated) => resyncPost(postCreated.id))
          .catch((response) => createPostError(response))
          .finally(() => run(() => (scope.processing = false)));
      };

      if (
        scope.post &&
        scope.post.teamIds == null &&
        scope.post.spaceId != null &&
        scope.post.spaceId.length === 1
      ) {
        spaceIncludesJack(scope.post.spaceId[0]).then((hasJack) =>
          run(() => {
            if (hasJack) {
              scope.isJumpingJackPost = true;
            }
            sendPost();
          })
        );
      } else {
        sendPost();
      }
    };

    function onAttachment(attachments) {
      scope.file = attachments[0];
      setPostResource(scope.file);
      scope.resource.id = scope.file.id;
      scope.resourceId = null; // reset fileUploader
      loadResource();
    }

    function forceAttachment() {
      if (scope.attachment != null) {
        scope.file = scope.attachment;
        setPostResource(scope.file);
        scope.resource = scope.file.resource;
        scope.resourceId = null;
        displayResourcePreview();
      }
    }

    function displayResourcePreview() {
      scope.previewPlaceholderClassName = media.getSpriteClassName(
        scope.file.filename
      );
      scope.previewCSS = null;
      const previewUrl = media.getPreviewUrl(scope.resource);
      if (previewUrl != null) {
        scope.previewCSS = {
          "background-image": `url(${previewUrl})`,
        };
      }
    }

    scope.$watch("expanded", (resource, old) => {
      if (resource === old || !resource) {
        return;
      }

      if (resource && !old) {
        if (scope.rootPost) {
          postReplied("Started", scope.rootPost);
        } else {
          postCreated("Started", {});
        }
      }
    });

    function loadResource() {
      const handleResource = (resource) => {
        run(() => {
          scope.resource = resource;
          if (!resource.processed) {
            $timeout(() => loadResource(), 3000);
          } else {
            displayResourcePreview();
          }
        });
      };

      Resource.get(scope.resource.id)
        .then(handleResource)
        .catch(() =>
          $translate(
            "DIRECTIVES.POSTING_FORM.RESOURCE_LOAD_ERROR_MESSAGE"
          ).then(alert)
        );
    }

    function setPostResource(resource) {
      run(() => {
        if (resource.id) {
          scope.post.resourceId = resource.id;
          scope.post.resourceType = resource.type;
          scope.errors.details = null;

          const currentTags = (scope.post.tags || []).slice(0);
          scope.post.tags = uniq([
            ...currentTags,
            ...(resource.tags || []).slice(0),
          ]);
        } else {
          scope.post.resourceId = null;
        }
      });
    }

    let tagsLoaded = false;

    function loadTags() {
      if (tagsLoaded) return;
      tagsLoaded = true;
      Tag.onlyNames()
        .then((tags) => run(() => (scope.availableTags = tags)))
        .catch(() =>
          $translate("DIRECTIVES.POSTING_FORM.TAGS_LOAD_ERROR_MESSAGE").then(
            alert
          )
        );
    }

    function setInitialExpansion() {
      if (scope.initiallyExpanded) {
        scope.expandForm();
      }
    }

    function setInitialResource() {
      if (scope.initialResourceId) {
        scope.resourceId = scope.initialResourceId;
      }
    }

    function onPost(post) {
      if (scope.isSmartList) {
        // window.location.pathname = '/spaces';
        window.history.back();
      } else {
        if (scope.feedContext && post.scheduledAt !== null) {
          scheduledPostModal.showScheduledPostModal(post, null).result;
        } else {
          scope.posts.unshift(post);
        }
      }

      if (scope.onPost != null) {
        scope.onPost({
          post: post,
        });
      }
    }

    reset();
    setInitialExpansion();
    setInitialResource();
    forceAttachment();
    smartListFunction();
  }

  return {
    restrict: "E",
    template,
    replace: true,
    scope: {
      controlDisabled: "=",
      posts: "=",
      postParams: "=",
      focus: "=?",
      allowChooseTags: "=?",
      isCompact: "=?", // do now show 'Create' button under collapsed input
      allowSelectSpace: "=?",
      initiallyExpanded: "=?expanded",
      initialResourceId: "@?",
      attachment: "<?",
      cancelHandler: "&?onCancel",
      onPost: "&?onPost",
      rootPost: "=",
    },
    link: link,
  };
}

PostingForm.$inject = [
  "$q",
  "$timeout",
  "$translate",
  "$location",
  "Resource",
  "core.services.identity",
  "core.services.Post",
  "Tag",
  "paywallModal",
  "core.services.planFeature",
  "bulk_post.bulkPostHelpers",
  "core.services.Team",
  "core.services.TeamGroup",
  "core.services.tracking",
  "mediaHelpers",
  "scheduledPostModal",
];

export default PostingForm;
