/**
 * @module Core.Services.Tracking
 *
 */
import { AnalyticsService } from 'app/services';

/**
 * @class $client
 *
 */
function factory() {
  function track(event, data) {
    AnalyticsService.track(event, data);
  }

  return { track };
}


export default factory;
