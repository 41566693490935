
/**
 * @module Core.Services.Uploader
 *
 */
import uploader from 'app/utils/uploader';

/**
 * @class $uploaded
 *
 */
function factory() {
  function upload(blob, name, type, resourceType) {
    const request = uploader(blob, name, type, resourceType);
    return { ...request, uploaded: request.promise };
  }
  return upload;
}

factory.$inject = [ ];

export default factory;

