/**
 * @module Directives.Teams
 *
 */

/**
 * @class TeamInviteFlow
 *
 */
function factory($q, $location, teamModal, teamEmailInviteFlow, teamCSVImportFlow) {
  function TeamInviteFlow(team) {
    this._team = team;
  }

  TeamInviteFlow.prototype.start = function start() {
    return teamModal.pickInviteType().result.then((e) =>
      this._inviteByType(e)
    );
  };

  TeamInviteFlow.prototype._inviteByType = function _inviteByType(e) {
    switch (e.type) {
      case 'email':
        return this._inviteByEmail(this._team);
      case 'link':
        return this._inviteByLink(this._team);
      case 'csv':
        return this._importCSV(this._team, e.file);
      default:
        return $q.reject();
    }
  };

  TeamInviteFlow.prototype._inviteByEmail = function _inviteByEmail(team) {
    return teamEmailInviteFlow(team).then(() =>
      $location.search('status', 'pending')
    );
  };

  TeamInviteFlow.prototype._inviteByLink = function _inviteByLink(team) {
    return teamModal.inviteByLink(team).result;
  };

  TeamInviteFlow.prototype._importCSV = function _importCSV(team, file) {
    return teamCSVImportFlow(team, file);
  };

  return function start(team) {
    return new TeamInviteFlow(team).start();
  };
}

factory.inject = ['$q', '$location', 'teamModal', 'teamEmailInviteFlow', 'teamCSVImportFlow'];

export default factory;
