/** @module directives/teams */
import { AnalyticsService } from 'app/services';

/**
 * @class TeamCsvImportFlow
 *
 */
function TeamCSVImportFlow($q, importer, modals, teamModal, $translate, TeamGroupMember) {
  function transition(state, action) {
    switch (action.target) {
      case 'SELECT_FILE':
        return selectFile(state, action);
      case 'SELECT_SHEET':
        return selectSheet(state, action);
      case 'SELECT_ROLE':
        return selectRole(state, action);
      case 'ASSIGN_ATTRIBUTES':
        return assignAttributes(state, action);
      case 'ASSIGN_COLUMN':
        return assignColumn(state, action);
      case 'SELECT_GROUPS':
        return selectGroups(state, action);
      case 'IMPORT_CONFIRM':
        return importConfirm(state, action);
      case 'IMPORT_USERS':
        return importUsers(state, action);
    }

    return $q.resolve(state);
  }

  const canGoBack = (state) => state.workbook.length > 1;

  const handleBackOrError = (e, state, target) => {
    if (e != null && e.reason === 'back') {
      return transition(state, { target });
    }
    return $q.reject(e);
  };

  const getSheet = (state) => {
    return state.workbook.sheets[state.sheet];
  };

  const setState = (state, data) => {
    return Object.assign({}, state, data);
  };

  const trackImport = (team, role, status) => {
    AnalyticsService.track('Team Members Invited', {
      'Type': 'CSV',
      'Role': role,
      'Team Name': team.name,
      'Team Id': team.id,
      'Team Sport': team.sport,
      'Members Imported': status.imported,
      'Members Invited': status.invited,
      'Members Incomplete': status.incomplete
    });
  };

  function selectFile(state) {
    return modals.selectFile().result
      .then((data) => transition(setState(state, { workbook: data.workbook }), { target: 'SELECT_SHEET' }));
  }

  function selectSheet(state) {
    if (state.workbook.length === 1) {
      return transition(setState(state, { sheet: 0 }), { target: 'SELECT_ROLE' });
    }

    return modals.sheetSelector(state.workbook, state.sheet).result
      .then((data) => transition(setState(state, { sheet: data.sheet }), { target: 'SELECT_ROLE' }));
  }

  function selectRole(state) {
    return modals.roleSelector(canGoBack(state)).result
      .then((data) => transition(setState(state, { role: data.role }), { target: 'ASSIGN_ATTRIBUTES' }))
      .catch(e => handleBackOrError(e, state, 'SELECT_SHEET'));
  }

  function assignAttributes(state) {
    const sheet = getSheet(state);
    return modals.attributeSelector(sheet, state.columns).result.then(data => {
      if (data.type === 'UPDATE_COLUMN') {
        return transition(setState(state, { columns: data.columns }), { target: 'ASSIGN_COLUMN', for: data.for });
      }
      return transition(setState(state, { columns: data.columns }), { target: 'SELECT_GROUPS' }); // 'IMPORT_USERS' });
    });
  }

  function assignColumn(state, action) {
    const key = action.for;
    const value = state.columns[key];
    return modals.columnSelector(getSheet(state), { key, value }).result
      .then((data) => {
        const columns = { ...state.columns, [key]: data.column };
        return transition(setState(state, { columns }), { target: 'ASSIGN_ATTRIBUTES' });
      })
      .catch(e => handleBackOrError(e, state, 'ASSIGN_ATTRIBUTES'));
  }

  function selectGroups(state) {
    const sheet = getSheet(state);
    const closeText = $translate.instant('TEAMS.SELECT_GROUPS.BACK_BUTTON').toString();
    // const doneText = $translate.instant('TEAMS.SELECT_GROUPS.DONE_BUTTON').toString();

    const getFromRow = (row, type) => {
      if (state.columns[type] !== -1) {
        return row[state.columns[type]];
      }
      return '';
    };

    const members = sheet.data.map(row => {
      return {
        email: getFromRow(row, 'email'),
        name: getFromRow(row, 'name'),
        position: getFromRow(row, 'position'),
        jersey: getFromRow(row, 'jersey')
      };
    });

    return teamModal.addMembersToChannels(state.team, members, state.teamGroups, null, closeText).result
      .then((result) =>
        transition(setState(state, { teamGroups: result.teamGroups, memberList: result.members }), { target: 'IMPORT_CONFIRM' })
      )
      .catch(e => handleBackOrError(e, state, 'ASSIGN_ATTRIBUTES'));
  }

  function importConfirm(state) {
    return teamModal.importConfirm(state.team, state.memberList, state.teamGroups).result
      .then(() => transition(state, { target: "IMPORT_USERS" }))
      .catch(e => handleBackOrError(e, state, "SELECT_GROUPS"));
  }

  function importUsers(state) {
    const modal = modals.importProgress();
    const structure = {
      sheet: state.sheet,
      role: state.role,
      columns: state.columns
    };

    const teamGroupIds = state.teamGroups.map(item => item.id);
    const memberIds = state.memberList.map(m => m.id || m.email);

    const importRequest = importer.invite(state.team, state.workbook.blob, structure, teamGroupIds).result;

    importRequest.then(() => {
      return modal.close();
    });

    return $q.all([modal.result, importRequest]).then(([ , { status } ]) => {
      trackImport(state.team, state.role, status);

      teamGroupIds.forEach((groupId) => {
        TeamGroupMember.events.onMembersAdded.dispatch(TeamGroupMember, { groupId: groupId, memberIds: memberIds });
      });

      return modals.importSummary(state.team, status.imported, status.invited, status.incomplete).result;
    });
  }

  return function start(team) {
    const state = {
      team: team,
      workbook: null,
      sheet: -1,
      role: null,
      columns: {
        email: 0,
        name: 1,
        position: 2,
        jersey: 3,
      }
    };

    return transition(state, { target: 'SELECT_FILE' });
  };
}

TeamCSVImportFlow.$inject = [
  '$q',
  'core.services.teamCSVImport',
  'teamCSVImportModals',
  'teamModal',
  '$translate',
  'core.services.TeamGroupMember'
];

export default TeamCSVImportFlow;
