/**
 * @module Directives.Teams
 *
 */
import { run } from 'app/utils/runloop';
import _template from 'ngapp/templates/views/teams/_new_team_modal.html';

/**
 * @class NewTeamModal
 *
 */
function Controller($q, $scope, tracking, Team, template, sports) {
  $scope.pending = true;
  $scope.sports = [];
  $scope.templates = [];
  $scope.logoPreviewCSS = null;
  $scope.errors = {};
  $scope.fields = {
    name: '',
    sport: $scope.$root.user.sport,
    logoId: '',
    logo: '',
    spaceTemplateId: null,
    userSettings: {
      emailNotificationsEnabled: false,
      pushNotificationsEnabled: true
    }
  };

  $scope.templateOptions = null;
  $scope.selectedTemplate = null;
  $scope.onSelectTemplate = (value) => {
    $scope.selectedTemplate = value;
    if (value != null) {
      $scope.fields.spaceTemplateId = value.data.id;
    }
  };

  $q.all([sports.all(), template.all()]).then(([ sports, templates ]) => {
    run(() => {
      $scope.sports = sports.data;
      $scope.templates = templates;
      $scope.templateOptions = templates
        .map(item => ({ label: item.name, value: item.id, data: item }))
        .sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0);
      $scope.pending = false;
    });
  });

  $scope.onChange = (value) => {
    $scope.fields.sport = value && value.label ? value.label : null;
  };

  $scope.onCancel = () => {
    $scope.$props.modal.dismiss();
  };

  $scope.onSubmit = (e) => {
    e.preventDefault();
    const validationResults = validate($scope.fields);
    if (validationResults.isValid) {
      $scope.pending = true;
      Team.create($scope.fields)
        .then(team => run(() => {
          tracking.teams.created(team);
          $scope.$props.modal.close({ team: team });
        }))
        .finally(() => run(() => $scope.pending = false));
    }
    $scope.errors = validationResults.errors;
  };

  $scope.onLogoChange = (avatar) => {
    $scope.fields.logoId = avatar.id;
    $scope.fields.logo = avatar.url;
    $scope.logoPreviewCSS = {
      'background-image': 'url(' + avatar.url + ')'
    };
  };

  function validate(form) {
    const result = { errors: {}, isValid: true };
    if (typeof form.name === 'string') {
      if (form.name.trim() === '') {
        result.errors.name = 'required';
        result.isValid = false;
      }
    }
    return result;
  }
}

Controller.$inject = [
  '$q',
  '$scope',
  'core.services.tracking',
  'core.services.Team',
  'core.services.template',
  'Sports'
];

export default {
  bindings: {
    modal: '<'
  },
  template: _template,
  controller: Controller,
  controllerAs: '$props'
};
