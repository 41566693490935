
import assign from 'lodash/assign';

function HttpFactory($http, $serializer) {
  var HTTP_DEFAULTS = {
    paramSerializer: $serializer.qs.serialize,
    transformRequest: $serializer.json.serialize,
    transformResponse: $serializer.json.deserialize
  };

  function configure(options) {
    return assign({}, HTTP_DEFAULTS, options);
  }

  return {
    delete: function $delete(url, config) {
      return $http.delete(url, configure(config));
    },

    get: function $get(url, config) {
      return $http.get(url, configure(config));
    },

    post: function $post(url, data, config) {
      return $http.post(url, data, configure(config));
    },

    put: function $put(url, data, config) {
      return $http.put(url, data, configure(config));
    }
  };
}

HttpFactory.$inject = ['$http', 'core.services.$serializer'];

export default HttpFactory;
