/**
 * @module Directives
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/modals/_policy_acceptance_modal.html';

function Controller($scope, PrivacyPolicy) {
  $scope.pending = true;
  $scope.policy = null;
  $scope.onSubmit = onSubmit;

  PrivacyPolicy.fetch().then(policy => run(() => {
    $scope.policy = policy;
    $scope.pending = false;
  }));

  function onSubmit() {
    $scope.$props.modal.close();
    PrivacyPolicy.accept();
  }
}

Controller.$inject = ['$scope', 'core.services.PrivacyPolicy'];

export default {
  bindings: {
    modal: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
