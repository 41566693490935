/**
 * @module Core.Services
 *
 */
import { fetchGet, fetchPost, templateUrl } from 'app/utils/request';

/**
 * @class BulkTeamGroupAddAll
 *
 */
function factory($bulkJobPoller) {
  const URL = {
    imports: templateUrl('team_groups/{teamGroup}/memberships/imports'),
    import: templateUrl('team_groups/{teamGroup}/memberships/imports/{import}')
  };

  function toImportProgress({ id, status, importedMembersCount, totalMembersCount }) {
    return {
      id, status,
      loaded: importedMembersCount,
      total: totalMembersCount
    };
  }

  function enqueue(teamGroupId) {
    const url = URL.imports({ teamGroup: teamGroupId });
    return fetchPost(url).then(data => toImportProgress(data));
  }

  function getImportStatus(teamGroupId, importId) {
    const url = URL.import({ teamGroup: teamGroupId, import: importId });
    return fetchGet(url).then(data => toImportProgress(data));
  }

  function add(teamGroupId) {
    return $bulkJobPoller.enqueue(
      () => enqueue(teamGroupId),
      (statusId) => getImportStatus(teamGroupId, statusId)
    );
  }

  return { add };
}

factory.$inject = [
  'core.services.$bulkJobPoller'
];

export default factory;
