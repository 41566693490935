/**
 * @module Core.Services
 *
 */
const ACL = {
  edit: 'edit',
  like: 'like',
  delete: 'delete',
  update: 'update'
};

/**
 * @class Reply
 *
 */
function factory($resource) {
  const Reply = $resource(
    {
      url: '/api/v2/posts',
      name: 'reply'
    },
    [ $resource.acl(ACL) ]
  );

  /**
   *
   * @param {string} postId
   * @param {object} request
   */
  Reply.getPostReplies = function getPostReplies(postId, request) {
    const query = Object.assign({}, request);
    return Reply.$get(`${Reply.$url()}/${postId}/replies`, query);
  };

  /**
   * Likes or unlikes this post.
   *
   * @returns {Promise}
   */
  Reply.prototype.like = function like() {
    if (this.liked) {
      this.liked = false;
      this.likesCount -= 1;
    } else {
      this.liked = true;
      this.likesCount += 1;
    }

    if (this.liked) {
      return Reply.$post(Reply.$url(this.id, 'likes'));
    }
    return Reply.$delete(Reply.$url(this.id, 'likes'));
  };

  Reply.prototype.isNote = function() {
    return (this.type == 'note');
  };

  return Reply;
}

factory.$inject = [ 'core.services.$resource' ];

export default factory;
