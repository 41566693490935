
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import snakeCase from 'lodash/snakeCase';
import isDate from 'lodash/isDate';

function factory() {
  function $any(context, key) {
    if (isArray(context)) {
      return $array(context, key);
    } else if (isPlainObject(context)) {
      return $object(context, key);
    } else if (isDate(context)) {
      return $value(context.toISOString(), key);
    }

    return $value(context, key);
  }

  function $value(context, key) {
    var parts = [encodeURIComponent(key)];

    if (context == null) {
      parts.push('');
    } else {
      parts.push(encodeURIComponent(context));
    }

    return parts.join('=');
  }

  function $array(context, key) {
    var prefix = key + "[]";

    if (context.length === 0) {
      return $value(null, prefix);
    }

    return map(context, function (v) {
      return $any(v, prefix);
    }).join('&');
  }

  function $object(context, namespace) {
    var parameters = map(context, function (v, k) {
      var key = snakeCase(k);

      if (namespace != null) {
        key = namespace + "[" + key + "]";
      }

      return $any(v, key);
    });

    return parameters.join('&');
  }

  function serialize(value) {
    if (isPlainObject(value)) {
      return $object(value);
    }

    return '';
  }

  return {
    serialize: serialize
  };
}

factory.$inject = [];

export default factory;
