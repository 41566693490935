import {
  localGet,
  localSet
} from "app/utils/LocalStorage";

/**
* @module Core.Services.Tracking
*
* /


/**
 * @class $cache
 *
 */


function factory(moment, PostViews, userService) {
  let cache = localGet('postViews', []);
  let intervalId = 0;
  let models = [];

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entree => {
      if (entree.isIntersecting) {
        observer.unobserve(entree.target);
        const viewsModel = prepareViewsModel(models.find(model => model.id === entree.target.dataset.id));
        cache = localGet('postViews', []);
        cache.push(viewsModel);
        localSet('postViews', cache);
      }
    });
  }, {});

  function prepareViewsModel(post) {
    if (post) {
      if (post.isNote()) {
        return {
          post_id: post.id,
          seen_at: moment().toISOString(),
          viewed_at: moment().toISOString()
        };
      }
    }

    return {
      post_id: post.id,
      seen_at: moment().toISOString()
    };
  }

  function sendAndClearPostViews() {
    if (cache && cache.length > 0) {
      PostViews.sendPostViews(cache);
      cache = localSet('postViews', []);
    }
  }

  function onDestroy() {
    clearInterval(intervalId);
  }

  function createObserver($elem, $post, isReply = false) {
    models.push($post);

    const isInCache = cache && cache.find(view => view.post_id === $post.id);

    if (!isInCache && !isReply && !$post.seenAt && $post.userId !== userService.user.id) {
      observer.observe($elem.get(0));
    }

    if (!isInCache && isReply && !$post.seenAt && $post.userId !== userService.user.id) {
      observer.observe($elem);
    }
  }

  function sendContentView(post) {
    const model = {
      post_id: post.id,
      viewed_at: moment().toISOString()
    };

    if (!post.viewedAt) {
      post.viewedAt = moment().toISOString();
      PostViews.sendContentView(model);
    }
  }


  return {
    createObserver,
    sendAndClearPostViews,
    sendContentView,
    onDestroy
  };
}

factory.$inject = ['moment', 'PostViews', 'sharedUserService'];

export default factory;
