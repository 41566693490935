
import isPlainObject from 'lodash/isPlainObject';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import identity from 'lodash/identity';
import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';
import isArray from 'lodash/isArray';

function factory() {
  var KEY_BLACKLIST = ['$$hashKey'];

  function __map(any, projection) {
    if (isPlainObject(any)) {
      return reduce(any, function (result, v, k) {
        if (KEY_BLACKLIST.indexOf(k) === -1) {
          result[projection.key(k)] = __map(v, projection);
        }

        return result;
      }, {});
    } else if (isArray(any)) {
      return map(any, function (v) {
        return __map(v, projection);
      });
    }

    return projection.value(any);
  }

  function serialize(value) {
    var result = __map(value, {
      value: identity,
      key: snakeCase
    });

    return JSON.stringify(result);
  }

  function deserialize(value) {
    return __map(JSON.parse(value), {
      value: identity,
      key: camelCase
    });
  }

  return {
    serialize: serialize,
    deserialize: deserialize
  };
}

factory.$inject = [];

export default factory;
