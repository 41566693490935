
import moment from 'moment/moment.js';

function factory() {
  return moment;
}

factory.$inject = [];

export default factory;
