/** @module core/services/activity */
import find from 'lodash/find';

/**
 * @class $IncomingInvitationCollection
 *
 */
function factory($rootScope, Signal, Invitation, $Collection) {
  function $IncomingInvitationCollection(invitations) {
    this.$collection = new $Collection(invitations);
    this.onChange = new Signal(this);
    this.$collection.onChange.attach(this.$onChange.bind(this));

    $rootScope.$on('onNotification', (e, count, prevCount) => {
      if (count.invitations !== prevCount.invitations) {
        Invitation.all().then(i =>
          this.$collection.replaceAll(i)
        );
      }
    });
  }

  $IncomingInvitationCollection.query = function query() {
    return Invitation.all().then(invitations =>
      new $IncomingInvitationCollection(invitations)
    );
  };

  $IncomingInvitationCollection.prototype = {
    get pending() {
      return this.$collection.pending;
    },

    get values() {
      return this.$collection.values;
    }
  };

  $IncomingInvitationCollection.prototype.$onChange = function $onChange() {
    this.onChange.dispatch(this, { pending: this.pending, values: this.values });
  };

  $IncomingInvitationCollection.prototype.accept = function accept(id) {
    const asyncFunc = () => {
      const invitation = find(this.values, { id });
      if (invitation != null) {
        return Invitation.accept(id).then(() => invitation.goto());
      }
    };
    return this.$collection
      .async(asyncFunc)
      .then(() => this.$collection.remove(id))
      .then(() => this);
  };

  $IncomingInvitationCollection.prototype.acceptAll = function() {
    return this.$collection
      .async(() => this.$collection.removeAll())
      .then(() => this);
  };

  $IncomingInvitationCollection.prototype.decline = function(id) {
    return this.$collection
      .async(() => Invitation.decline(id))
      .then(() => this.$collection.remove(id))
      .then(() => this);
  };

  return $IncomingInvitationCollection;
}

factory.$inject = [ '$rootScope', 'core.Signal', 'core.services.Invitation', 'core.services.activity.$Collection' ];

export default factory;
