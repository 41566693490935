/** @module core/services/cache */

/**
 * @class $teamGroupCachePolicyFactory
 *
 */
function factory(qs, $cache, $createTeamCachePolicy, Team) {
  return function create(TeamGroup) {
    var teamCachePolicy = $createTeamCachePolicy(Team);

    function removeAll(id, teamId) {
      $cache.remove(Team.$url());
      $cache.remove(Team.$url(id));
      $cache.remove(TeamGroup.$url() + '?' + qs.stringify({ teamId: teamId }));
      $cache.remove(TeamGroup.$url() + '/joinable?' + qs.stringify({ teamId: teamId }));
    }

    function onRemoveAll(teamId) {
      $cache.remove(Team.$url());
      $cache.remove(TeamGroup.$url() + '?' + qs.stringify({ teamId: teamId }));
      $cache.remove(TeamGroup.$url() + '/joinable?' + qs.stringify({ teamId: teamId }));
    }

    function onRemove(id, teamId) {
      removeAll(id, teamId);
      teamCachePolicy.onUpdate(teamId);
    }

    function onCreate(id, teamId) {
      removeAll(id, teamId);
      teamCachePolicy.onUpdate(teamId);
    }

    function onUpdate(id, teamId) {
      removeAll(id, teamId);
    }

    return {
      onRemoveAll: onRemoveAll,
      onRemove: onRemove,
      onCreate: onCreate,
      onUpdate: onUpdate
    };
  };
}

factory.$inject = [
  'core.qs',
  'core.services.$cache',
  'core.services.cache.$teamCachePolicyFactory',
  'core.services.Team'
];

export default factory;
