/** @module directives/teams */
import { isBlank as _isBlank } from 'app/utils';
import template from 'ngapp/templates/views/teams/_csv_column_selector_modal.html';

const EMPTY_SHEET = { name: '', data: [], columns: 0 };

/**
 * @class TeamCSVColumnSelectorModal
 *
 */
function Controller($scope) {
  $scope.sheet = EMPTY_SHEET;
  $scope.fields = {
    column: 0
  };
  $scope.strings = {
    title: '',
    cta: ''
  };
  $scope.isBlank = isBlank;
  $scope.onBack = onBack;
  $scope.onPage = onPage;
  $scope.onSelect = onSelect;
  $scope.$props.$onChanges = $onChanges;

  function onBack() {
    $scope.$props.modal.dismiss({ reason: 'back' });
  }

  function onSelect(column) {
    $scope.$props.modal.close({ column: column });
  }

  function onPage(page) {
    $scope.fields.column = page - 1;
  }

  function isBlank(v) {
    return _isBlank(v);
  }

  function getLocalization(key) {
    switch (key) {
      case 'email':
        return {
          title: 'TEAMS.CSV_COLUMN_SELECTOR.TITLES.EMAIL',
          cta: 'TEAMS.CSV_COLUMN_SELECTOR.CTA.EMAIL'
        };
      case 'name':
        return {
          title: 'TEAMS.CSV_COLUMN_SELECTOR.TITLES.NAME',
          cta: 'TEAMS.CSV_COLUMN_SELECTOR.CTA.NAME'
        };
      case 'jersey':
        return {
          title: 'TEAMS.CSV_COLUMN_SELECTOR.TITLES.JERSEY',
          cta: 'TEAMS.CSV_COLUMN_SELECTOR.CTA.JERSEY'
        };
      case 'position':
        return {
          title: 'TEAMS.CSV_COLUMN_SELECTOR.TITLES.POSITION',
          cta: 'TEAMS.CSV_COLUMN_SELECTOR.CTA.POSITION'
        };
    }
  }

  function $onChanges(props) {
    if (props.sheet != null) {
      $scope.sheet = props.sheet.currentValue || EMPTY_SHEET;
    }

    if (props.column != null) {
      $scope.fields.column = Math.max(0, props.column.currentValue.value);
      $scope.strings = getLocalization(props.column.currentValue.key);
    }
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    modal: '<',
    sheet: '<',
    column: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
