/**
 * @module Core.Services
 *
 */
import differenceWith from 'lodash/differenceWith';
import filter from 'lodash/filter';
import { isBlank } from 'app/utils';

/**
 * @class UserConnections
 *
 */
function factory($q, $resource, $cache, $createCachePolicy) {
  var UserConnections = $resource({
    url: '/api/v1/connections',
    name: 'userConnections',
    httpConfig: {
      cache: $cache
    }
  });

  UserConnections.$cachePolicy = $createCachePolicy(UserConnections);

  UserConnections.current = function current(query, exclude) {
    return UserConnections
      .query()
      .then(function(connections) {
        var filtered = __filter(connections, query, exclude);
        return $q.resolve(filtered);
      });
  };

  UserConnections.onUpdate = function onUpdate() {
    UserConnections.$cachePolicy.onUpdate();
  };

  function __filter(connections, query, exclude) {
    var result = differenceWith(connections || [], exclude || [], function(a, b) {
      return a.id === b.id;
    });

    if(!isBlank(query)) {
      var queryLower = query.toLowerCase();

      result = filter(result, function(c) {
        var regEx = new RegExp('\\b'+queryLower, 'gi');
        return c.name.match(regEx) || c.email.toLowerCase().indexOf(queryLower) === 0;
      });
    }

    return result;
  }

  return UserConnections;
}

factory.$inject = [
  '$q',
  'core.services.$resource',
  'core.services.$cache',
  'core.services.cache.$userConnectionsPolicyFactory'
];

export default factory;
