
export default function tags() {
  return function filter(tags) {
    if (tags == null || tags.length === 0) {
      return '';
    }

    var result = [];
    var length = tags.length;

    for (var i = 0; i < length; i++) {
      if (tags != null && tags !== '') {
        result.push('#' + tags[i]);
      }
    }

    return result.join(' ');
  };
}
