
import filter from 'lodash/filter';
import find from 'lodash/find';
import each from 'lodash/each';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import reject from 'lodash/reject';
import take from 'lodash/take';
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/core/_pills.html';

const SEARCH_RESULT_LIMIT = 5;

function stringify(value) {
  if (value == null) {
    return '';
  }

  return value.toString();
}

function Controller($scope, $element) {
  $scope.id = "";
  $scope.input = "";
  $scope.dynamic = false;
  $scope.collapsed = true;
  $scope.focused = false;
  $scope.disabled = false;
  $scope.placeholder = "";
  $scope.class = "";
  $scope.options = { active: 0, all: [], filtered: [] };
  $scope.values = [];
  $scope.shouldShowOptions = shouldShowOptions;
  $scope.onRemove = onRemove;
  $scope.onSearch = onSearch;
  $scope.onKeydown = onKeydown;
  $scope.onFocus = onFocus;
  $scope.onBlur = onBlur;
  $scope.onOptionSelect = onOptionSelect;
  $scope.$props.$onDestroy = $onDestroy;
  $scope.$props.$onChanges = $onChanges;

  var $select = $element.find('.pills');
  var $input = $select.find('.pill-search');

  $select.on('focusin', onFocus);
  $select.on('focusout', onBlur);

  function onKeydown(e) {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if ($scope.dynamic) {
        if ($scope.input !== '') {
          e.preventDefault();
          insertValue({ value: $scope.input, label: $scope.input });
        } else if ($scope.options.active !== -1) {
          e.preventDefault();
          insertValue($scope.options.filtered[$scope.options.active]);
        }
      } else if ($scope.options.active !== -1) {
        e.preventDefault();
        insertValue($scope.options.filtered[$scope.options.active]);
      }
    } else if (e.key === 'Backspace') {
      if ($scope.input === '') {
        popValue();
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      selectNextOption();
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      selectPreviousOption();
    } else if (e.key === 'Escape') {
      $scope.collapsed = true;
      $scope.options.active = -1;
    }
  }

  function insertValue(option) {
    if (option != null) {
      if (!$scope.values.find(item => item.value === option.value)) {
        $scope.options.active = -1;
        $scope.input = '';

        if (!$scope.options.all.find(item => item.value === option.value)) {
          $scope.options.all = [ ...$scope.options.all, { value: option.value, label: option.label } ];
        }

        $scope.$props.onChange({ values: $scope.values.map(item =>  item.value).concat(option.value) });
      }
    }
  }

  function popValue() {
    $scope.options.active = -1;
    $scope.$props.onChange({ values: map($scope.values, 'value').slice(0, -1) });
  }

  function parseValues(values, options) {
    return reduce(values, (result, v) => {
      const option = options.find(item => item.value === v);
      if (option) {
        result.push({ value: v, label: stringify(option.label), highlight: false });
      } else {
        // handle added items
        result.push({ value: v, label: v, highlight: false });
      }
      return result;
    }, []);
  }

  function parseOptions(options) {
    return map(options, function (option) {
      if (typeof option === 'string') {
        return { value: option, label: option };
      }

      return { value: option.value, label: stringify(option.label) };
    });
  }

  function filterOptions(options, search, values) {
    return take(filter(options, function(option) {
      if (find(values, { value: option.value }) == null) {
        if (search.trim() === '') {
          return true;
        }

        return option.label.toLowerCase().indexOf(search.toLowerCase()) > -1;
      }

      return false;
    }), SEARCH_RESULT_LIMIT);
  }

  function selectNextOption() {
    if ($scope.options.active < $scope.options.filtered.length - 1) {
      $scope.options.active += 1;
    }
  }

  function selectPreviousOption() {
    if ($scope.dynamic) {
      if ($scope.input === "") {
        $scope.options.active = Math.max(0, $scope.options.active - 1);
      } else {
        $scope.options.active = Math.max(-1, $scope.options.active - 1);
      }
    } else {
      $scope.options.active = Math.max(0, $scope.options.active - 1);
    }
  }

  function shouldShowOptions() {
    if($scope.focused && !$scope.collapsed && !$scope.disabled) {
      if ($scope.options.filtered.length > 0) {
        return true;
      } else if ($scope.dynamic && $scope.input.length > 0) {
        return true;
      }
    }

    return false;
  }

  function onOptionSelect(e, option) {
    e.preventDefault();
    insertValue(option);
  }

  function onSearch() {
    each($scope.values, function(v, i) {
      if (v.label === $scope.input) {
        $scope.values[i].highlight = true;
      } else {
        $scope.values[i].highlight = false;
      }
    });

    $scope.collapsed = false;
    $scope.options.filtered = filterOptions($scope.options.all, $scope.input, $scope.values);

    if ($scope.options.active >= $scope.options.filtered.length) {
      $scope.options.active = $scope.options.filtered.length - 1;
    }
  }

  function onFocus() {
    if (!$scope.focused && !$scope.disabled) {
      run(() => {
        $scope.focused = true;
        $scope.collapsed = false;
        $input.focus();
      });
    }
  }

  function onBlur(e) {
    var focusLost = false;

    if ($scope.focused) {
      if (e.originalEvent.relatedTarget == null) {
        focusLost = true;
      } else if(!$element[0].contains(e.originalEvent.relatedTarget)) {
        focusLost = true;
      }

      if (focusLost) {
        run(() => {
          $scope.focused = false;
          $scope.collapsed = true;
        });
      } else {
        $input.focus();
      }
    }
  }

  function onRemove(value) {
    $scope.$props.onChange({
      values: map(reject($scope.values, { value: value }), 'value')
    });
  }

  function $onDestroy() {
    $select.off('focusin', onFocus);
    $select.off('focusout', onBlur);
  }

  function $onChanges(props) {
    if (props.disabled != null) {
      $scope.disabled = props.disabled.currentValue;
    }

    if (props.dynamic != null) {
      $scope.dynamic = props.dynamic.currentValue;
    }

    if (props.placeholder != null) {
      $scope.placeholder = props.placeholder.currentValue;
    }

    if (props.id != null) {
      $scope.id = props.id.currentValue;
    }

    if (props.class != null) {
      $scope.class = props.class.currentValue;
    }

    if (props.options != null || props.values != null) {
      if (props.options != null) {
        $scope.options.all = parseOptions(props.options.currentValue);
        $scope.options.active = -1;
      }

      if (props.values != null) {
        $scope.values = parseValues(props.values.currentValue, $scope.options.all.slice(0));
      } else {
        $scope.values = parseValues($scope.$props.values.slice(0), $scope.options.all.slice(0));
      }
      $scope.options.filtered = parseOptions(filterOptions($scope.options.all.slice(0), $scope.input, $scope.values.slice(0)));
    }
  }
}

Controller.$inject = ['$scope', '$element'];

export default {
  bindings: {
    class: '@',
    id: '@',
    placeholder: '@',
    dynamic: '<',
    options: '<',
    disabled: '<',
    values: '<',
    onChange: '&',
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
