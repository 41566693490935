/**
 * @module Core.Services
 *
 */
import { fetchGet, fetchPost, templateUrl } from 'app/utils/request';

/**
 * @class BulkInvitationAccept
 *
 */
function factory($bulkJobPoller) {
  var URL = {
    enqueue: templateUrl('invitations/bulk_joins'),
    status: templateUrl('invitations/bulk_joins/{jobId}')
  };

  function toProgress({ id, status, totalCount, successCount, failedCount }) {
    return {
      id, status,
      total: totalCount,
      succeeded: successCount,
      failed: failedCount
    };
  }

  function enqueue(invitationIds) {
    return fetchPost(URL.enqueue(), { invitationIds }).then(data => toProgress(data));
  }

  function getAcceptStatus(jobId) {
    return fetchGet(URL.status({ jobId })).then(data => toProgress(data));
  }

  function create (ids) {
    return $bulkJobPoller.enqueue(
      () => enqueue(ids),
      (id) => getAcceptStatus(id)
    );
  }

  return {
    create: create
  };
}

factory.$inject = [ 'core.services.$bulkJobPoller' ];

export default factory;
