/**
 * @module Angular.Services
 *
 */
import alertServiceFactory from './alert';
import AsyncQueue from './async_queue';
import Auth from './auth';
import ClientHelper from './client_helper';
import ConfirmFactory from './confirm';
import DiagnosticsFactory from './diagnostics';
import FormatFactory from './format';
import FormErrors from './forms/form_errors';
import SpaceForm from './forms/space_form';
import InvitationPresenter from './invitation_presenter';
import ISO3166 from './iso-3166-country-codes-angular';
import ListFilter from './list_filter';
import ListLoader from './list_loader';
import ListPaging from './list_paging';
import NullPromise from './null_promise';
import policyAcceptanceModal from './policy_acceptance_modal';
import PolicyModalService from './policy_modal_service';
import SharedUserService from './shared_user';
import Stripe from './stripe';
import StripeElementsFactory from './stripe_elements_factory';

export default function(__angular) {
  // components
  __angular.module('directives')
    .component('policyAcceptanceModal', policyAcceptanceModal);

  // service factories
  __angular.module('services')
    .factory('alert', alertServiceFactory)
    .factory('AsyncQueue', AsyncQueue)
    .factory('Auth', Auth)
    .factory('ClientHelper', ClientHelper)
    .factory('confirm', ConfirmFactory)
    .factory('diagnostics', DiagnosticsFactory)
    .factory('format', FormatFactory)
    .factory('FormErrors', FormErrors)
    .factory('SpaceForm', SpaceForm)
    .factory('InvitationPresenter', InvitationPresenter)
    .factory('ISO3166', ISO3166)
    .factory('listFilter', ListFilter)
    .factory('listLoader', ListLoader)
    .factory('listPaging', ListPaging)
    .factory('NullPromise', NullPromise)
    .factory('sharedUserService', SharedUserService)
    .factory('stripe', Stripe)
    .factory('stripeElementsFactory', StripeElementsFactory)
    .factory('policyModal', PolicyModalService);
}
