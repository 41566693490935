/**
 * @module Directives
 *
 */
import template from 'ngapp/templates/directives/_panel_loader.html';

/**
 * @class PanelLoader
 *
 */
function PanelLoaderController() {
}

export default {
  bindings: {
    'show': '<'
  },
  controller: PanelLoaderController,
  controllerAs: 'vm',
  template
};
