
function animation($css) {
  function enter(element, done) {
    return $css(element, {
      from: { opacity: 0 },
      to: { opacity: 1 },
      duration: 0.3,
      easing: 'ease-in-out'
    })
    .start()
    .then(done);
  }

  function leave(element, done) {
    done();
  }

  return { leave: leave, enter: enter };
}

animation.$inject = ['$animateCss'];

export default animation;
