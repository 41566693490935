
export default function FormErrors() {
  return function(form) {
    this.errors = null;

    this.apply = function(errors) {
      this.errors = errors;
      angular.forEach(errors, function(fieldErrors, fieldName) {
        var field = getField(fieldName);
        if(angular.isArray(field)) {
          handleArrayErrors(field, fieldErrors);
        } else {
          handleObjectErrors(field, fieldErrors);
        }
      });
    };

    this.reset = function() {
      angular.forEach(this.errors, function(fieldErrors, fieldName) {
        var field = getField(fieldName);
        if(angular.isArray(field)) {
          angular.forEach(field, function(field) {
            field.error = null;
          });
        } else {
          field.error = null;
        }
      });
      this.errors = null;
    };

    var getField = function(fieldName) {
      var field = form[fieldName];
      if(!field.prop) {
        field.prop = fieldName;
      }
      return field;
    };

    var handleObjectErrors = function(field, fieldErrors) {
      form[field.prop].error = errorStringForStringArray(fieldErrors);
    };

    var handleArrayErrors = function(field, fieldErrors) {
      angular.forEach(fieldErrors, function(fieldError) {
        if(fieldError) {
          var errorString = '';

          if (field.nestedProp) {
            errorString = errorStringForStringArray(fieldError.errors[field.nestedProp]);
          } else {
            errorString = errorStringForObject(fieldError.errors);
          }
          form[field.prop][fieldError.index].error = errorString;
        }
      });
    };

    var errorStringForStringArray = function(strArr) {
      return strArr.join(', ');
    };

    var errorStringForObject = function(obj) {
      var errStrings = [];
      angular.forEach(obj, function(fldErrors, fldName) {
        angular.forEach(fldErrors, function(fldError) {
          errStrings.push(fldName + ' ' + fldError);
        });
      });

      return errorStringForStringArray(errStrings);
    };
  };
}
