/**
 * @module Controllers
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/account/_settings.html';

/**
 * @class AccountSettingsController
 *
 */
function AccountSettingsController($root, $scope, $translate, $routeParams, UserSettings, toast) {
  $scope.isBusy = true;
  $scope.processing = false;
  $scope.tokenPresent = !!$routeParams.token;

  const setUser = (user) => run(() => $scope.user = user);
  setUser($root.user);

  $root.$on('userUpdated', (event, user) => {
    setUser(user);
  });

  UserSettings
    .get({}, { token: $routeParams.token })
    .then(settings => run(() => {
      $scope.userSettings = settings;
      $scope.isBusy = false;
    }))
    .catch(() => toast.danger($translate.instant('CONTROLLERS.ACCOUNT_SETTINGS.UPDATE_ERROR_MESSAGE')));

  $scope.saveSettings = function() {
    $scope.processing = true;

    let requestUrl = UserSettings.settingsUrl;
    if ($scope.tokenPresent) {
      requestUrl = requestUrl + '?token=' + $routeParams.token;
    }

    $scope.userSettings
      .$put(requestUrl)
      .then(() => toast.success($translate.instant('CONTROLLERS.ACCOUNT_EDIT.UPDATE_SUCCESS_MESSAGE')))
      .catch(() => toast.danger($translate.instant('CONTROLLERS.ACCOUNT_SETTINGS.UPDATE_ERROR_MESSAGE')))
      .finally(() => run(() => $scope.processing = false));
  };
}

AccountSettingsController.$inject = [
  '$rootScope',
  '$scope',
  '$translate',
  '$routeParams',
  'UserSettings',
  'core.ui.toast'
];

export default {
  controller: AccountSettingsController,
  template
};
