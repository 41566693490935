/** @module legacy/app/directives/activity */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/site_wrap/activity/_invitation_summary.html';

/**
 * @class InvitationSummary
 *
 */
function Controller($scope, $q, $translate, identity, planFeature, activityCollection, Invitation, toast) {
  $scope.pending = true;
  $scope.hasCoachPlus = false;
  $scope.invitations = [];
  $scope.onAccept = onAccept;
  $scope.onDecline = onDecline;
  $scope.acceptAll = acceptAll;
  $scope.$props.$onDestroy = $onDestroy;

  let unsubscribeFromInvitationActivity = null;

  const invitationRequest = (reload) => activityCollection.query(reload).then(e => run(() => {
    unsubscribeFromInvitationActivity = e.invitations.onChange.attach(onInvitationActivity);
    $scope.invitations = e.invitations.values;
  }));

  $q.all([ invitationRequest(), identity.request() ]).then(([ , user ]) => run(() => {
    $scope.hasCoachPlus = planFeature.user.hasCoachPlus(user);
    $scope.pending = false;
  }));

  function onInvitationActivity(e) {
    $scope.invitations = e.data.values;
    $scope.pending = e.data.pending;
  }

  function onAccept(invitation) {
    activityCollection.query().then(e =>
      e.invitations.accept(invitation.id)
    );
  }

  function onDecline(invitation) {
    activityCollection.query().then(e =>
      e.invitations.decline(invitation.id)
    );
  }

  function acceptAll() {
    toast.info($translate.instant('ACTIVITY.INVITATION_SUMMARY.JOINING_ALL_STATUS'));

    activityCollection.query().then(e => e.invitations.acceptAll());

    return Invitation.acceptAll().result.then((e) => {
      toast.success($translate.instant('ACTIVITY.INVITATION_SUMMARY.JOIN_ALL_SUCCESS'));
      return e;
    });
  }

  function $onDestroy() {
    if (unsubscribeFromInvitationActivity != null) {
      unsubscribeFromInvitationActivity();
    }
  }
}

Controller.$inject = [
  '$scope',
  '$q',
  '$translate',
  'core.services.identity',
  'core.services.planFeature',
  'core.services.activityCollection',
  'core.services.Invitation',
  'core.ui.toast'
];

export default {
  template,
  controller: Controller,
  controllerAs: '$props'
};

