/**
 * @module Directives.TeamsGroups
 *
 */
import template from 'ngapp/templates/views/teams_groups/_group_settings_menu.html';

/**
 * @class GroupSettingsMenu
 *
 */
function Controller($scope, $location, $translate, confirm, groupModal, toast, TeamGroup) {
  $scope.group = null;
  $scope.onSettingsClicked = onSettingsClicked;
  $scope.onLeaveClicked = onLeaveClicked;
  $scope.$props.$onChanges = $onChanges;

  function onSettingsClicked() {
    groupModal.groupSettings($scope.group);
  }

  function onLeaveClicked() {
    const args = {
      body: $translate.instant('TEAM_CHANNELS.CHANNEL_SETTINGS_MENU.LEAVE_CHANNEL_CONFIRMATION', { channel: $scope.group.name })
    };
    confirm(args).then(result => {
      if (result.confirmed) {
        TeamGroup.leave($scope.group).then(() => {
          toast.info($translate.instant('TEAM_CHANNELS.CHANNEL_SETTINGS_MENU.LEAVE_CHANNEL_SUCCESS', { channel: $scope.group.name }));
          $location.path($scope.group.teamUrl);
        });
      }
    });
  }

  function $onChanges(props) {
    if (props.group != null) {
      $scope.group = props.group.currentValue;
    }
  }
}

Controller.$inject = [
  '$scope',
  '$location',
  '$translate',
  'confirm',
  'groupModal',
  'core.ui.toast',
  'core.services.TeamGroup'
];

export default {
  bindings: {
    group: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
