/**
 * @module Directives.Paywalls
 *
 */
import template from 'ngapp/templates/views/paywalls/_library_modal.html';
import library_icon_png from 'app/images/paywall-body-icon-library.png';

/**
 * @class LibraryModal
 *
 */
function Controller($scope, tracking) {
  $scope.modal = $scope.$props.modal;
  $scope.onCancel = onCancel;
  $scope.feature = 'Library';
  $scope.library_icon_png = library_icon_png;

  tracking.paywalls.shown($scope.feature);

  function onCancel() {
    $scope.modal.dismiss();
  }
}

Controller.$inject = ['$scope', 'core.services.tracking'];

export default {
  bindings: {
    modal: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
