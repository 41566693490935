/** @module resource */

/**
 * @class ResourcePagerFactory
 *
 */
function ResourcePagerFactory(Resource, createPager, time) {
  const settings = {
    limit: 20,
    throttle: 10000
  };

  const MIN_DATE_VALUE = new Date(2012, 0);

  function serialize(filters) {
    const result = {
      orderBy: "created_at",
      order: "desc",
      from: undefined,
      to: undefined,
      tags: undefined,
      type: undefined
    };

    if (filters == null) {
      return result;
    }

    if (filters.tags) {
      result.tags = filters.tags;
    }

    if (filters.type) {
      if (Array.isArray(filters.type)) {
        result.type = filters.type.slice(0);
      } else {
        result.type = Object.keys(filters.type || {}).filter(key => filters.type[key] === true);
      }
    }

    if (filters.to != null) {
      result.to = filters.to;
    }

    if (filters.year != null && filters.month != null) {
      result.to = time.ceil(new Date(filters.year, filters.month), 'month').toISOString();
    }

    return result;
  }

  function create(filters) {
    function load(request) {
      return Resource.query(Object.assign({}, serialize(filters), { page: request.page, perPage: request.limit }));
    }

    return createPager(load, settings);
  }

  return {
    create: create,
    MIN_DATE_VALUE: MIN_DATE_VALUE
  };
}

ResourcePagerFactory.$inject = ['core.services.Resource', 'core.services.pagerFactory', 'core.time'];

export default ResourcePagerFactory;
