
export default function NgMultiple() {
  function link($scope, $element) {
    $scope.$watch('ngMultiple', function(include) {
      if (include) {
        $element.attr('multiple', 'multiple');
      } else {
        $element.removeAttr('multiple');
      }
    });
  }

  return {
    restrict: 'A',
    scope: {
      ngMultiple: '='
    },
    link: link
  };
}
