/** @module resources */

function UserTagFactory($resource) {
  const UserTag = $resource({
    url: 'api/v1/account/tags',
    name: 'tag'
  });

  UserTag.prototype.deleteWithParams = function(params) {
    return this.$delete(this.$url(), params);
  };

  UserTag.prototype.planCanEdit = function() {
    return (this.planPermissions.indexOf('edit') !== -1);
  };

  UserTag.prototype.planCanDelete = function() {
    return (this.planPermissions.indexOf('delete') !== -1);
  };

  return UserTag;
}

UserTagFactory.$inject = ['core.services.$resource'];

export default UserTagFactory;
