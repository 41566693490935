/** @module directives/teams */

/**
 * @class TeamMemberImportModals
 *
 */
function TeamMemberImportModals($modal) {
  function emailInviteForm(members) {
    return $modal.open({
      keyboard: false,
      template: '<team-member-email-invite-modal members="members" modal="modal"></team-member-email-invite-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
        $local.members = members;
      }]
    });
  }

  function importProgress() {
    return $modal.open({
      keyboard: false,
      template: '<team-member-import-progress-modal modal="modal"></team-member-import-progress-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
      }]
    });
  }

  function importSuccess(status) {
    return $modal.open({
      template: '<team-member-import-success-modal modal="modal" status="status"></team-member-import-success-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
        $local.status = status;
      }]
    });
  }

  return {
    emailInviteForm: emailInviteForm,
    importProgress: importProgress,
    importSuccess: importSuccess
  };
}

TeamMemberImportModals.inject = ['$modal'];

export default TeamMemberImportModals;
