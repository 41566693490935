/** @module directives/teams */
import mail_icon_png from 'app/images/mail-icon.png';
import template from 'ngapp/templates/views/teams/_member_import_progress_modal.html';

/**
 * @class TeamMemberImportProgressModal
 *
 */
function Controller($scope) {
  $scope.mail_icon_png = mail_icon_png;
}

Controller.$inject = [ '$scope' ];

export default {
  bindings: {},
  template,
  controller: Controller,
  controllerAs: '$props'
};
