/**
 * @module Directives.Teams
 *
 */
import { digest } from 'app/utils/runloop';
import template from 'ngapp/templates/views/teams/_team_settings_modal.html';

/**
 * @class TeamSettingsModal
 *
 */
function Controller(
  $scope,
  $translate,
  $location,
  validators,
  toast,
  tracking,
  Team,
  planFeature,
  confirm,
  sports
) {
  $scope.errors = {};
  $scope.pending = true;
  $scope.sports = [];
  $scope.team = $scope.$props.team;
  $scope.canManageGroupControls = planFeature.team.canManageGroupControls($scope.team);
  $scope.fields = {
    name: $scope.team.name,
    sport: $scope.team.sport,
    logoId: $scope.team.logoId,
    logo: $scope.team.logo,
    membersCanViewOtherMembers: $scope.team.membersCanViewOtherMembers,
    membersCanPost: $scope.team.membersCanPost,
    membersCanReply: $scope.team.membersCanReply,
    membersCanSeePostData: $scope.team.membersCanSeePostData,
  };
  $scope.logoPreviewCSS = { 'background-image': 'url(' + $scope.team.logo + ')' };
  $scope.filterSports = filterSports;
  $scope.onLogoChange = onLogoChange;
  $scope.onCancel = onCancel;
  $scope.onTransfer = onTransfer;
  $scope.onDelete = onDelete;
  $scope.onSubmit = onSubmit;
  $scope.onChange = onChange;

  sports.all().then(json => {
    digest(() => {
      $scope.sports = json;
      $scope.pending = false;
    });
  });

  function filterSports(search) {
    var sports = $scope.sports.slice();

    if (search !== '' && sports.indexOf(search) === -1) {
      sports.unshift(search);
    }

    return sports;
  }

  function validate(team) {
    var result = { errors: {}, isValid: true };

    if (validators.isBlank(team.name)) {
      result.errors.name = 'required';
      result.isValid = false;
    }

    return result;
  }

  function onChange(value) {
    $scope.fields.sport = value && value.label ? value.label : null;
  }

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onTransfer() {
    $scope.$props.modal.close({
      action: 'transfer'
    });
  }

  function onDelete() {
    $scope.pending = true;

    confirm({
      body: $translate.instant('TEAMS.SETTINGS.DELETE_CONFIRMATION', { name: $scope.team.name })
    })
    .then(function (result) {
      if (result.confirmed) {
        Team.remove($scope.team)
          .then(function () {
            tracking.teams.deleted($scope.team);
            toast.success($translate.instant('TEAMS.SETTINGS.DELETED_MESSAGE'));
            $scope.$props.modal.close({ action: 'delete' });
            $location.path('/groups');
          });
      }
    })
    .finally(function () {
      $scope.pending = false;
    });
  }

  function onSubmit(e) {
    e.preventDefault();

    var validationResults = validate($scope.fields);

    if (validationResults.isValid) {
      $scope.pending = true;

      Team.update($scope.team, $scope.fields)
        .then(function (result) {
          tracking.teams.updated(result);
          toast.success($translate.instant('TEAMS.SETTINGS.SAVE_SUCCESS_MESSAGE'));
          $scope.$props.modal.close({
            action: 'save'
          });
        })
        .finally(function () {
          $scope.pending = false;
        });
    }

    $scope.errors = validationResults.errors;
  }

  function onLogoChange(avatar) {
    $scope.fields.logoId = avatar.id;
    $scope.fields.logo = avatar.url;
    $scope.logoPreviewCSS = {
      'background-image': 'url(' + avatar.preview + ')'
    };
  }
}

Controller.$inject = [
  '$scope',
  '$translate',
  '$location',
  'core.validate',
  'core.ui.toast',
  'core.services.tracking',
  'core.services.Team',
  'core.services.planFeature',
  'confirm',
  'Sports',
];

export default {
  bindings: {
    modal: '<',
    team: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
