/**
 * @module Angular.Directives.Teams
 *
 */
import attachmentLightboxModal from './attachment_lightbox_modal';
import editTeamMemberModal from './edit_team_member_modal';
import GroupMemberPagerFactory from './group_member_pager_factory';
import LightboxModal from './lightbox_modal_service';
import newTeamButton from './new_team_button';
import newTeamModal from './new_team_modal';
import reportPostModal from './report_post_modal';
import scheduledPostsPanel from './scheduled_posts_panel';
import teamCreatedModal from './team_created_modal';
import TeamCreationFlow from './team_creation_flow';
import teamImportConfirmModal from './team_import_confirm_modal';
import teamCsvAttributeSelectorModal from './team_csv_attribute_selector_modal';
import teamCsvColumnSelectorModal from './team_csv_column_selector_modal';
import TeamCSVImportFlow from './team_csv_import_flow';
import TeamCSVImportModals from './team_csv_import_modals';
import teamCsvImportSummaryModal from './team_csv_import_summary_modal';
import teamCsvRoleSelectorModal from './team_csv_role_selector_modal';
import teamCsvSelectFileModal from './team_csv_select_file_modal';
import teamCsvSheetSelectorModal from './team_csv_sheet_selector_modal';
import teamFeed from './team_feed';
import TeamFeedPagerFactory from './team_feed_pager_factory';
import teamFeedPost from './team_feed_post';
import teamFeedReply from './team_feed_reply';
import teamFeedThread from './team_feed_thread';
import teamHeader from './team_header';
import TeamInviteFlow from './team_invite_flow';
import teamList from './team_list';
import TeamEmailInviteFlow from './team_member_email_invite_flow';
import teamMemberAddToChannels from './team_member_add_to_channels_modal';
import teamMemberEmailInviteModal from './team_member_email_invite_modal';
import teamMembersHeader from './team_member_header';
import TeamMemberImportModals from './team_member_import_modals';
import teamMemberImportProgressModal from './team_member_import_progress_modal';
import teamMemberImportSuccessModal from './team_member_import_success_modal';
import teamMemberInviteButton from './team_member_invite_button';
import teamMemberInviteModal from './team_member_invite_modal';
import teamMemberLinkInviteModal from './team_member_link_invite_modal';
import TeamMemberPagerFactory from './team_member_pager_factory';
import teamMemberRoleFilter from './team_member_role_filter';
import teamMembersList from './team_members_list';
import TeamModal from './team_modal_service';
import teamNav from './team_nav';
import teamNotificationSettingsModal from './team_notification_settings_modal';
import teamPostAttachmentList from './team_post_attachment_list';
import teamPostCounts from './team_post_counts';
import teamPostDisabled from './team_post_disabled';
import teamPostEditControl from './team_post_edit_control';
import teamPostEditForm from './team_post_edit_form';
import teamPostForm from './team_post_form';
import teamPostMedia from './team_post_media';
import teamSettingsMenu from './team_settings_menu';
import teamSettingsModal from './team_settings_modal';
import teamWrap from './team_wrap';
import TeamWrapModel from './team_wrap_model';
import transferTeamCompleteModal from './transfer_team_complete_modal';
import TransferTeamFlow from './transfer_team_flow';
import transferTeamModal from './transfer_team_modal';
import TransferTeamModal from './transfer_team_modal_service';


export default function(__angular) {
  // components
  __angular.module('directives')
    .component('attachmentLightboxModal', attachmentLightboxModal)
    .component('editTeamMemberModal', editTeamMemberModal)
    .component('newTeamButton', newTeamButton)
    .component('newTeamModal', newTeamModal)
    .component('reportPostModal', reportPostModal)
    .component('scheduledPostsPanel', scheduledPostsPanel)
    .component('teamCreatedModal', teamCreatedModal)
    .component('teamImportConfirmModal', teamImportConfirmModal)
    .component('teamCsvAttributeSelectorModal', teamCsvAttributeSelectorModal)
    .component('teamCsvColumnSelectorModal', teamCsvColumnSelectorModal)
    .component('teamCsvImportSummaryModal', teamCsvImportSummaryModal)
    .component('teamCsvRoleSelectorModal', teamCsvRoleSelectorModal)
    .component('teamCsvSelectFileModal', teamCsvSelectFileModal)
    .component('teamCsvSheetSelectorModal', teamCsvSheetSelectorModal)
    .component('teamFeed', teamFeed)
    .component('teamFeedPost', teamFeedPost)
    .component('teamFeedReply', teamFeedReply)
    .component('teamFeedThread', teamFeedThread)
    .component('teamHeader', teamHeader)
    .component('teamList', teamList)
    .component('teamMemberEmailInviteModal', teamMemberEmailInviteModal)
    .component('teamMemberAddToChannels', teamMemberAddToChannels)
    .component('teamMembersHeader', teamMembersHeader)
    .component('teamMemberImportProgressModal', teamMemberImportProgressModal)
    .component('teamMemberImportSuccessModal', teamMemberImportSuccessModal)
    .component('teamMemberInviteButton', teamMemberInviteButton)
    .component('teamMemberInviteModal', teamMemberInviteModal)
    .component('teamMemberLinkInviteModal', teamMemberLinkInviteModal)
    .component('teamMemberRoleFilter', teamMemberRoleFilter)
    .component('teamMembersList', teamMembersList)
    .component('teamNav', teamNav)
    .component('teamNotificationSettingsModal', teamNotificationSettingsModal)
    .component('teamPostAttachmentList', teamPostAttachmentList)
    .component('teamPostCounts', teamPostCounts)
    .component('teamPostDisabled', teamPostDisabled)
    .component('teamPostEditControl', teamPostEditControl)
    .component('teamPostEditForm', teamPostEditForm)
    .component('teamPostForm', teamPostForm)
    .component('teamPostMedia', teamPostMedia)
    .component('teamSettingsMenu', teamSettingsMenu)
    .component('teamSettingsModal', teamSettingsModal)
    .component('teamWrap', teamWrap)
    .component('transferTeamCompleteModal', transferTeamCompleteModal)
    .component('transferTeamModal', transferTeamModal);

  // factories
  __angular.module('directives')
    .factory('groupMemberPagerFactory', GroupMemberPagerFactory)
    .factory('lightboxModal', LightboxModal)
    .factory('teamCreationFlow', TeamCreationFlow)
    .factory('teamCSVImportFlow', TeamCSVImportFlow)
    .factory('teamCSVImportModals', TeamCSVImportModals)
    .factory('teamFeedPagerFactory', TeamFeedPagerFactory)
    .factory('teamInviteFlow', TeamInviteFlow)
    .factory('teamEmailInviteFlow', TeamEmailInviteFlow)
    .factory('teamMemberImportModals', TeamMemberImportModals)
    .factory('teamMemberPagerFactory', TeamMemberPagerFactory)
    .factory('teamModal', TeamModal)
    .factory('teamWrapModel', TeamWrapModel)
    .factory('transferTeamFlow', TransferTeamFlow)
    .factory('transferTeamModal', TransferTeamModal);
}
