/**
 * @module Core
 *
 */
import { isBlank, isEmpty } from 'app/utils';

/**
 * @class Validate
 *
 */
function factory() {
  const EMAIL_MATCH = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

  function isEmail(value) {
    if (typeof value === 'string') {
      return EMAIL_MATCH.test(value);
    }
    return false;
  }

  return {
    isEmpty: isEmpty,
    isBlank: isBlank,
    isEmail: isEmail
  };
}

factory.$inject = [];

export default factory;
