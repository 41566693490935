/**
 * @module Directives.TeamsGroups
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/teams_groups/_group_member_list.html';

/**
 * @class GroupMemberList
 *
 */
function Controller($scope, $translate, identity, confirm, format, tracking) {
  $scope.user = null;
  $scope.group = [];
  $scope.members = [];
  $scope.pending = true;
  $scope.filters = { query: '', roles: { coach: true, player: true, viewer: true }};

  identity.request().then(user =>
    run(() => $scope.user = user)
  );

  $scope.formatMemberSummary = function(member) {
    return format.memberSummary(member);
  };

  $scope.onPage = function() {
    $scope.$props.onPage();
  };

  $scope.onFilterChange = function() {
    $scope.$props.onFilter({ filters: $scope.filters });
  };

  $scope.onRemoveClick = function(member) {
    const args = {
      body: $translate.instant('TEAM_CHANNELS.CHANNEL_MEMBER_LIST.REMOVE_CONFIRMATION', { name: member.name })
    };

    function remove(member) {
      return member.remove().then(() => {
        tracking.teamGroups.memberDeleted($scope.group, $scope.group.team, member);
        $scope.$props.onRemoved({ member: member });
      });
    }

    confirm(args).then(result => {
      if (result.confirmed) {
        return remove(member);
      }
    });
  };

  $scope.$props.$onChanges = function(props) {
    if (props.members != null) {
      $scope.members = props.members.currentValue;
    }

    if (props.group != null) {
      $scope.group = props.group.currentValue;
    }

    if (props.pending != null) {
      $scope.pending = props.pending.currentValue;
    }
  };
}

Controller.$inject = [
  '$scope',
  '$translate',
  'core.services.identity',
  'confirm',
  'format',
  'core.services.tracking'
];

export default {
  bindings: {
    group: '<',
    members: '<',
    pending: '<',
    onRemoved: '&',
    onFilter: '&',
    onPage: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
