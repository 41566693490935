/**
 * @module Core.Services.Tracking
 *
 */

/**
 * @class $team_group
 *
 */
function factory($client, $post, $team) {
  const formatGroupType = (type) => {
    return type
      .split('_')
      .map(item => item.slice(0, 1).toUpperCase() + item.slice(1))
      .join('-');
  };

  function extendEventData(event, group) {
    const groupData =  {
      'Group Id': group.id,
      'Team Id': group.teamId,
      'Group Name': group.name,
      'Channel Type': formatGroupType(group.groupType)
    };
    return Object.assign({}, event, groupData);
  }

  function extendTeamEventData(event, group, team) {
    const groupData = extendEventData(event, group);
    return $team.extendEventData(groupData, team);
  }

  function created(group, team) {
    $client.track('Team Group Created', extendTeamEventData({}, group, team));
  }

  function deleted(group, team) {
    $client.track('Team Group Deleted', extendTeamEventData({}, group, team));
  }

  function memberDeleted(group, team, member) {
    $client.track('Team Group Member Deleted', extendTeamEventData({ 'Role': member.type }, group, team));
  }

  function membersAdded(group, team, count) {
    $client.track('Team Group Members Added', extendTeamEventData({ 'Added Count': count }, group, team));
  }

  function membersFiltered(group, team, filters) {
    const resolvedFilters = $team.resolveMemberFilter(filters);
    if (resolvedFilters != null) {
      $client.track('Team Group Members Filtered', extendTeamEventData(resolvedFilters, group, team));
    }
  }

  function postCreated(group, team, post) {
    $client.track('Team Group Post Created', extendTeamEventData($post.extendEventData({}, post), group, team));
  }

  function postDeleted(group, team, post) {
    $client.track('Team Group Post Deleted', extendTeamEventData($post.extendEventData({}, post), group, team));
  }

  function postEdited(group, team, post) {
    $client.track('Team Group Post Edited', extendTeamEventData($post.extendEventData({}, post), group, team));
  }

  function postLiked(group, team, post) {
    const eventName = post.liked ? 'Team Group Post Liked' : 'Team Group Post Unliked';
    $client.track(eventName, extendTeamEventData($post.extendEventData({}, post), group, team));
  }

  function postReported(group, team, post) {
    $client.track('Team Group Post Reported', extendTeamEventData($post.extendEventData({}, post), group, team));
  }

  function updated(group, team) {
    $client.track('Team Group Updated', extendTeamEventData({}, group, team));
  }

  return {
    created: created,
    deleted: deleted,
    memberDeleted: memberDeleted,
    membersAdded: membersAdded,
    membersFiltered: membersFiltered,
    postCreated: postCreated,
    postDeleted: postDeleted,
    postEdited: postEdited,
    postLiked: postLiked,
    postReported: postReported,
    updated: updated
  };
}

factory.$inject = ['core.services.tracking.$client', 'core.services.tracking.$post', 'core.services.tracking.$team'];

export default factory;

