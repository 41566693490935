/**
 * imports for all templates that use ng-include from other templates
 *
 */
import core_ui_toast from 'ngapp/templates/views/core/_toast.html';
import post from 'ngapp/templates/views/posts/_post.html';
import post_inner from 'ngapp/templates/views/posts/_post_inner.html';
import post_with_replies from 'ngapp/templates/views/posts/_post_with_replies.html';
import replies_list from 'ngapp/templates/views/replies/_list.html';
import space_memberships_invitation_list from 'ngapp/templates/views/space_memberships/_invitations_list.html';
import space_demo_memberships_invitation_list from 'ngapp/templates/views/space_memberships/_demo_invitations_list.html';
import space_memberships_invite_new from 'ngapp/templates/views/space_memberships/_new.html';
import recurly_billing_info from 'ngapp/templates/views/account/_recurly_billing_info.html';
import recurly_billing_info_edit from 'ngapp/templates/views/account/_recurly_billing_info_edit.html';

export default function preloadTemplates($templateCache) {
  $templateCache.put('core.ui.toast', core_ui_toast);
  $templateCache.put('posts.post', post);
  $templateCache.put('posts.post-inner', post_inner);
  $templateCache.put('posts.post-with-replies', post_with_replies);
  $templateCache.put('replies.list', replies_list);
  $templateCache.put('space_memberships.invitations_list', space_memberships_invitation_list);
  $templateCache.put('space_memberships.demo_invitations_list', space_demo_memberships_invitation_list);
  $templateCache.put('space_memberships.invite_new', space_memberships_invite_new);
  $templateCache.put('recurly_billing_info', recurly_billing_info);
  $templateCache.put('recurly_billing_info.edit', recurly_billing_info_edit);
}
