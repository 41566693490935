/**
 * @module Controllers
 *
 */
import unionBy from 'lodash/unionBy';
import filter from 'lodash/filter';

/**
 * @class RepliesController
 *
 */
function RepliesController($q, toast, $scope, $translate, $location, $modal, createRepliesPager, tracking) {
  $scope.parentPost = {};
  $scope.replies = [];
  $scope.replyParams = {};
  $scope.editingReplyDetails = {};
  $scope.feedContext = /^\/feed/.test($location.path());
  $scope.reportMessage = '';
  $scope.formatProfileSummary = formatProfileSummary;
  $scope.onMore = onMore;

  var pager = null;

  function onPage(e) {
    $scope.replies = unionBy(e.data.results, $scope.replies, 'id');
  }

  function onMore() {
    if (pager == null) {
      pager = createRepliesPager($scope.post.id, {
        cursor: $scope.post.replies[0].cursor
      });

      pager.get().then(results =>
        $scope.replies = unionBy(results, $scope.replies, 'id')
      );

      pager.onPage.attach(onPage);
    } else {
      pager.next();
    }
  }

  function formatProfileSummary(post) {
    if (post.teamMembership != null) {
      return filter([post.teamMembership.position]).join(' / ');
    }
    return ' ';
  }

  function removeReplayFromDom(reply) {
    const index = $scope.replies.indexOf(reply);
    $scope.replies.splice(index, 1);
    $scope.parentPost.repliesCount -= 1;
  }

  $scope.remove = function (reply) {
    if (reply.can.delete) {
      reply.remove()
        .then(function () {
          removeReplayFromDom(reply);

          if (reply.team) {
            tracking.teams.postDeleted(reply.team, reply);
          } else if (reply.teamGroup) {
            tracking.teamGroups.postDeleted(reply.teamGroup, reply.teamGroup.team, reply);
          }
        })
        .catch(() =>
          $translate('CONTROLLERS.REPLIES.DELETE_ERROR_MESSAGE').then(alert)
        );
    }
  };

  $scope.edit = function (reply) {
    if (reply.can.edit) {
      $scope.editingReplyDetails[reply.id] = true;
    }
  };

  $scope.report = function (event) {
    if (!event.post.isReply) {
      throw new Error("Post is not a reply");
    }

    const request = $scope.post.reportReply(event.post.id, event.message);

    $q.resolve(request)
      .then(() => {
        removeReplayFromDom(event.post);

        if (event.post.team) {
          tracking.teams.postReported(event.post.team, event.post);
        } else if (event.post.teamGroup) {
          tracking.teamGroups.postReported(event.post.teamGroup, event.post.teamGroup.team, event.post);
        }

        toast.success($translate.instant('TEAMS.REPORT_POST_MODAL.SUCCESS_TOAST'));
      })
      .catch(() =>
        $translate('CONTROLLERS.REPLIES.REPORT_ERROR_MESSAGE').then(alert)
      );
  };

  $scope.$watch('post', function (post, old) {
    if (post === old && !post) {
      return;
    }

    $scope.parentPost = post;
    $scope.replies = post.replies;
    $scope.replyParams.rootId = post.id;
    $scope.replyParams.spaceId = post.spaceId;
  });
}

RepliesController.$inject = [
  '$q',
  'core.ui.toast',
  '$scope',
  '$translate',
  '$location',
  '$modal',
  'repliesPagerFactory',
  'core.services.tracking'
];

export default {
  name: 'RepliesCtrl',
  controller: RepliesController
};

