/**
 * @module Directives.Upload.Pages
 *
 */
import toArray from 'lodash/toArray';
import { run } from 'app/utils/runloop';
import { isTypeOf } from 'app/utils/file';
import template from 'ngapp/templates/directives/_upload_dropzone.html';

/**
 * @class Dropzone
 *
 */
function Controller($scope, $element, features) {
  $scope.droppable = features.dragAndDropFiles;
  $scope.accept = '';
  $scope.multiple = false;
  $scope.error = null;
  $scope.$props.$onChanges = $onChanges;

  const $form = $element.find('form');

  $form.find('[type=file]').on('change', e => onFiles(e.target.files));

  $form
    .on('dragenter', () => $form.addClass('highlight'))
    .on('drop dragleave', e => !$form.is(e.relatedTarget) && $form.has(e.relatedTarget).length === 0 && $form.removeClass('highlight'))
    .on('drop', e => onFiles(e.originalEvent.dataTransfer.files))
    .on('dragenter dragover dragleave drop', e => e.preventDefault() && e.stopPropagation());

  function onFiles(files) {
    let payload = toArray(files);
    if (!$scope.multiple) {
      payload = [files[0]];
    }
    const invalid = payload.filter(f => !isTypeOf(f, $scope.accept));
    run(() => {
      if (invalid.length  > 0) {
        $scope.error = 'invalid_type';
      } else {
        $scope.error = null;
        $scope.$props.onFiles({ files: payload });
      }
    });
  }

  function $onChanges(props) {
    if (props.accept != null) {
      $scope.accept = props.accept.currentValue;
    }

    if (props.multiple != null) {
      $scope.multiple = props.multiple.currentValue;
    }
  }
}

Controller.$inject = ['$scope', '$element', 'core.features'];

export default {
  bindings: {
    accept: '<?',
    multiple: '<?',
    onFiles: '&'
  },
  template,
  controllerAs: '$props',
  controller: Controller
};
