
import template from 'ngapp/templates/views/teams/_team_post_counts.html';

function Controller($scope) {
  $scope.post = $scope.$props.post;
  $scope.likeIconClassNames = getLikeIconClassNames($scope.post);
  $scope.onLike = onLike;
  $scope.canSeePostData = canSeePostData;

  function getLikeIconClassNames(post) {
    if (post.can.like) {
      if (post.liked) {
        return { 'ico-fist': true };
      }
    }

    return { 'ico-fist-o': true };
  }

  function onLike() {
    $scope.$props.post.like()
      .then(function () {
        $scope.likeIconClassNames = getLikeIconClassNames($scope.post);
        $scope.$props.onLike({ post: $scope.post });
      });
  }

  function canSeePostData() {
    if($scope.post.permissions) {
      return $scope.post.permissions.includes('access_views') && $scope.post.permissions.includes('access_likes');
    }

    return false;
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    post: '<',
    onLike: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
