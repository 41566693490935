/**
 * @module Directives.BulkPost.Locations
 *
 */

/**
 * @class SegmentLocation
 *
 */
function segmentLocation($property, BulkPostLocation) {
  function SegmentLocation(destination) {
    BulkPostLocation.call(this, destination);
  }

  SegmentLocation.prototype = Object.create(BulkPostLocation.prototype);

  $property.get(SegmentLocation.prototype, 'paywallModalName', function() {
    return BulkPostLocation.MODAL_KINDS.SEGMENTS;
  });

  return SegmentLocation;
}

segmentLocation.$inject = ['core.$property', 'bulk_post.locations.$bulkPostLocation'];

export default segmentLocation;
