/**
 * @module Directives.Paywalls
 *
 */
import template from 'ngapp/templates/views/paywalls/_pro_upgrade_modal.html';
import pro_icon_png from 'app/images/paywall-body-icon-pro.png';

/**
 * @class ProUpgradeModal
 *
 */
function Controller($scope) {
  $scope.modal = $scope.$props.modal;
  $scope.onCancel = onCancel;
  $scope.feature = 'CoachNow PRO Upgrade';
  $scope.pro_icon_png = pro_icon_png;

  function onCancel() {
    $scope.modal.dismiss();
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    modal: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
