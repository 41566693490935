/**
 * @module Directives.BulkPost.Locations
 *
 */
import kebabCase from 'lodash/kebabCase';

/**
 * @class SpaceLoacation
 *
 */
function spaceLocation($property, BulkPostLocation) {
  function SpaceLocation(destination) {
    BulkPostLocation.call(this, destination);
  }

  SpaceLocation.prototype = Object.create(BulkPostLocation.prototype);

  $property.get(SpaceLocation.prototype, 'sport', function() {
    return this._destination.sport || '';
  });

  $property.get(SpaceLocation.prototype, 'paywallModalName', function() {
    return BulkPostLocation.MODAL_KINDS.SPACES;
  });

  $property.get(SpaceLocation.prototype, 'checkboxDataFeatureName', function() {
    return 'bulk-post-selector.space-checkbox'.concat('-', kebabCase(this.sport));
  });

  return SpaceLocation;
}

spaceLocation.$inject = ['core.$property', 'bulk_post.locations.$bulkPostLocation'];

export default spaceLocation;
