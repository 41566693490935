/** @module core/services */
import assign from 'lodash/assign';

/**
 * @class Team
 *
 */
function factory($property, $resource, $cache, $createTeamCachePolicy) {
  const Team = $resource({
    url: '/api/v2/teams',
    name: 'team',
    httpConfig: {
      cache: $cache
    }
  }, [
    $resource.acl({
      manage: 'manage_members',
      invite: 'manage_members',
      post: 'post',
      reply: 'reply',
      postMedia: 'post_media',
      createGroups: 'create_team_groups',
      edit: 'edit',
      delete: 'delete',
      leave: 'leave',
      transfer: 'transfer_ownership',
      viewMembers: 'view_members'
    })
  ]);

  Team.$cachePolicy = $createTeamCachePolicy(Team);

  Team.reload = function reload(teamId) {
    Team.$cachePolicy.onRemoveMember(teamId);
    return Team.get(teamId);
  };

  Team.transfer = function transfer(id, email) {
    const request = Team.$post(Team.$url(id, 'transfer_ownership'), { membership: { email: email }}, { rootWrapping: false })
      .then(() => ({}));

    request.then(() => {
      Team.$cachePolicy.onUpdate(id);
    });
    return request;
  };

  Team.create = function create(properties) {
    const request = new Team(properties).create();
    request.then((team) => {
      Team.$cachePolicy.onCreate(team.id);
    });
    return request;
  };

  Team.getInviteUrl = function getInviteUrl(id, role) {
    return Team.$post(Team.$url(id, 'short_link'), { role: role }, { rootWrapping: false })
      .then((e) => e.shortLink);
  };

  Team.update = function update(team, properties) {
    const entity = assign(team, properties);
    const request = team.update();
    request.then(() => {
      Team.$cachePolicy.onUpdate(entity.id);
    });
    return request;
  };

  Team.leave = function leave(id) {
    const request = Team.$post(Team.$url(id, 'leave'));
    request.then(() => {
      Team.$cachePolicy.onRemove(id);
    });
    return request;
  };

  Team.remove = function remove(team) {
    const request = team.delete();
    request.then(() => {
      Team.$cachePolicy.onRemove(team.id);
    });
    return request;
  };

  $property.get(Team.prototype, 'scheduledPostsUrl', function() {
    return '/schedule';
  });

  $property.get(Team.prototype, 'scheduledPostsQueryParams', function() {
    return { teamId: this.id };
  });

  $property.get(Team.prototype, 'timelineUrl', function () {
    return '/groups/' + this.id;
  });

  $property.get(Team.prototype, 'caption', function() {
    return 'Team';
  });

  $property.get(Team.prototype, 'membersUrl', function () {
    return '/groups/' + this.id + '/members';
  });

  Team.prototype.isOwnedByUser = function isOwnedByUser(user) {
    if (user != null) {
      return this.ownerId === user.id;
    }
    return false;
  };

  Team.prototype.isOwnedByMember = function isOwnedByMember(member) {
    if (member != null) {
      return this.ownerId === member.userId;
    }
    return false;
  };

  return Team;
}

factory.$inject = [
  'core.$property',
  'core.services.$resource',
  'core.services.$cache',
  'core.services.cache.$teamCachePolicyFactory'
];

export default factory;
