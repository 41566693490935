
function ActiveWhen($location) {
  function link(scope, element /*, attrs*/) {
    var update = function() {
      if (!scope.activeClass) {
        scope.activeClass = "active";
      }

      if ($location.path().match(scope.activeWhen)) {
        return element.addClass(scope.activeClass);
      } else {
        return element.removeClass(scope.activeClass);
      }
    };

    scope.$on('$routeChangeSuccess', function() {
      return update();
    });

    update();
  }

  return {
    restrict: 'A',
    scope: {
      activeWhen: "@",
      activeClass: "=?"
    },
    link: link
  };
}

ActiveWhen.$inject = ['$location'];

export default ActiveWhen;
