/**
 * @module Directives.Teams
 *
 */
import template from 'ngapp/templates/views/teams/_csv_sheet_selector_modal.html';

/**
 * @class TeamCsvSheetSelectorModal
 *
 */
function Controller($scope) {
  $scope.sheets = [];
  $scope.fields = { sheet: 0 };
  $scope.onCancel = onCancel;
  $scope.onSubmit = onSubmit;
  $scope.$props.$onChanges = $onChanges;

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onSubmit() {
    $scope.$props.modal.close({ sheet: $scope.fields.sheet });
  }

  function $onChanges(props) {
    if (props.workbook != null) {
      $scope.sheets = props.workbook.currentValue.sheets;
      $scope.fields.sheet = 0;
    }

    if (props.sheet != null) {
      $scope.fields.sheet = Math.max(props.sheet.currentValue, 0);
    }
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    workbook: '<',
    sheet: '<',
    modal: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
