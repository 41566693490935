/**
 * @module Directives
 *
 */
import app_store_btn from 'app/images/appstore-btn.png';
import play_store_btn from 'app/images/playstore-btn.png';
import template from 'ngapp/templates/directives/_app_promt.html';

/**
 * @class AppPromt
 *
 */
function AppPrompt($routeParams) {
  function link(_scope) {
    _scope.showPromt = function() {
      return $routeParams.app_promt === 'true';
    };

    _scope.images = {
      app_store_btn: app_store_btn,
      play_store_btn: play_store_btn
    };
  }

  return {
    template,
    replace: true,
    restrict: 'E',
    scope: {
      space: '=?'
    },
    link: link
  };
}

AppPrompt.$inject = ['$routeParams'];

export default AppPrompt;
