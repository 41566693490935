/**
 * @module Core.UI.Toast
 *
 */
import reject from 'lodash/reject';
import { run } from 'app/utils/runloop';

function $controller($scope, $timeout, uid, options) {
  $scope.toasts = [];
  $scope.onClick = onClick;

  function push(config) {
    run(() => {
      const toast = {
        id: uid.short(),
        alert: config.alert,
        level: config.level,
        className: getClassName(config.level)
      };

      $scope.toasts.push(toast);
      $timeout(
        () => remove(toast),
        options.timeout,
        false
      );
    });
  }

  function getClassName(level) {
    switch (level) {
      case 'danger':
        return 'toast-danger';
      case 'info':
        return 'toast-info';
      case 'success':
        return 'toast-success';
    }

    return '';
  }

  function remove(toast) {
    run(() => $scope.toasts = reject($scope.toasts, { id: toast.id }));
  }

  function onClick(toast) {
    remove(toast);
  }

  return { push: push };
}

$controller.$inject = [ '$scope', '$timeout', 'core.uid', 'options' ];

export default $controller;
