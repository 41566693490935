/**
 * @module Routes
 *
 */
import translationsEn from './locales/en';

import feed from './controllers/feed';

import resources from './controllers/resources';
import resourcesNew from './controllers/resource_new';
import resourcesEdit from './controllers/resource_edit';
import resourcesPost from './controllers/resource_post';

import invitations from './controllers/invitations';
import notifications from './controllers/notifications';

import postShow from './controllers/posts/post_show';

import segments from './controllers/segments/segments';
import segmentsEdit from './controllers/segments/segment_edit';

import segmentSpaces from './controllers/segment_spaces/segment_spaces';
import segmentSpacesPost from './controllers/segment_spaces_post';
import segmentSpacesEdit from './controllers/segment_spaces/segment_spaces_edit';
import segmentSpacesMembers from './controllers/segment_spaces/segment_spaces_members';

import spaceTemplates from './controllers/space_templates/space_templates';
import spaceTemplatesEdit from './controllers/space_templates/space_template_edit';
import spaceTemplatesPost from './controllers/space_template_posts';
import spaceTemplatesPostReorder from './controllers/space_template_posts_reorder';
import spaceTemplatesMembers from './controllers/space_template_members';

import spaces from './controllers/spaces/spaces';
import spacess from './templates/views/spaces/_spaces.html';

import { spaceNew, spaceNewMultiple } from './controllers/spaces/space_new';
import spaceTimeline from './controllers/spaces/space_timeline';
import spaceDemoTimeline from './controllers/spaces/space_demo_timeline';
import spaceScheduledPosts from './controllers/spaces/space_scheduled_posts';
import spaceMemberships from './controllers/space_memberships/space_memberships';
import spaceDemoMemberships from './controllers/space_memberships/space_demo_memberships';
import spaceInvitaionEdit from './controllers/spaces/space_invitation_edit';

import scheduledPosts from './controllers/scheduled_posts/scheduled_posts_feed';

import teams from './controllers/teams/teams';
import teamsTimeline from './controllers/teams/timeline';
import teamsMembers from './controllers/teams/members';

import teamsGroupsTimeline from './controllers/teams_groups/timeline';
import teamsGroupsMembers from './controllers/teams_groups/members';

import organization from './controllers/organizations/organizations';

import tags from './controllers/tags/tags';
import tagsNew from './controllers/tags/tags_new';
import tagsEdit from './controllers/tags/tags_edit';
import tagsDelete from './controllers/tags/tags_delete';

import accountSettings from './controllers/account_settings';
import accountEdit from './controllers/account_edit';
import accountSubscriptions from './controllers/subscriptions';
import accountPassword from './controllers/account_password';
import { accountBillingInfo, accountBillingInfoEdit } from './controllers/account_billing_info';
import accountConnections from './controllers/connections';

import searchPage from './controllers/search_page';

import benefits from 'ngapp/templates/views/_benefits.html';
import upgradePlus from 'ngapp/templates/views/_upgrade.html';
import not_found_template from 'ngapp/templates/views/_not_found.html';
import forbidden_template from 'ngapp/templates/views/_forbidden.html';
import server_error_template from 'ngapp/templates/views/_server_error.html';
import copy from './templates/views/copy/_copy.html';
import move from './templates/views/move/_move.html';
import getting_started from './templates/views/getting_started/_getting_started.html';

export default function (__angular) {
  __angular.module('app').config(routes);

  function routes($httpProvider, $routeProvider, $locationProvider, $translateProvider) {
    $translateProvider.translations('en', translationsEn);
    $translateProvider.useSanitizeValueStrategy('sce');
    $translateProvider.addInterpolation('$translateMessageFormatInterpolation');

    $httpProvider.interceptors.push('httpArrayQueryHandler');
    $httpProvider.interceptors.push('http401ErrorInterceptor');
    $httpProvider.interceptors.push('http402ErrorInterceptor');
    $httpProvider.interceptors.push('http403ErrorInterceptor');
    $httpProvider.interceptors.push('http404ErrorInterceptor');
    $httpProvider.interceptors.push('http500ErrorInterceptor');

    $routeProvider
      .when('/', feed)
      .when('/feed', { redirectTo: '/' })
      .when('/search', searchPage)
      .when('/organizations', organization)
      .when('/invitations', invitations)
      .when('/notifications', notifications)
      .when('/posts/:postId', postShow)

      .when('/resources', resources)
      .when('/resources/new', resourcesNew)
      .when('/resources/:resourceId', resourcesEdit)
      .when('/resources/:resourceId/post', resourcesPost)

      .when('/lists', segments)
      .when('/lists/:segmentId/spaces', segmentSpaces)
      .when('/lists/:segmentId/spaces/post', segmentSpacesPost)
      .when('/lists/:segmentId/spaces/edit', segmentSpacesEdit)
      .when('/lists/:segmentId/add_members', segmentSpacesMembers)
      .when('/lists/:segmentId/edit', segmentsEdit)

      .when('/space_templates', spaceTemplates)
      .when('/space_templates/:spaceTemplateId/edit', spaceTemplatesEdit)
      .when('/space_templates/:spaceTemplateId/posts', spaceTemplatesPost)
      .when('/space_templates/:spaceTemplateId/posts/reorder', spaceTemplatesPostReorder)
      .when('/space_templates/:spaceTemplateId/members', spaceTemplatesMembers)

      .when('/spaces', {template: spacess})
      .when('/spaces/all/:selectedList/:id', spaces)
      .when('/spaces/smartList/:selectedList/:id', spaces)
      .when('/spaces/list/:selectedList/:id', spaces)
      .when('/spaces/smartList/:selectedList/post_to_smart_list/:id', segmentSpacesPost)
      .when('/spaces/demo', spaces)
      .when('/spaces/new', spaceNew)
      .when('/spaces/new/multiple/:multiple', spaceNewMultiple)
      .when('/spaces/:spaceId', spaceTimeline)
      .when('/spaces/demo/:spaceId', spaceDemoTimeline)
      .when('/spaces/:spaceId/members', spaceMemberships)
      .when('/spaces/:spaceId/schedule', spaceScheduledPosts)
      .when('/spaces/demo/:spaceId/members', spaceDemoMemberships)
      .when('/spaces/:spaceId/invitations/:invitationId/edit', spaceInvitaionEdit)

      .when('/schedule', scheduledPosts)

      .when('/groups', teams)
      .when('/groups/:id', teamsTimeline)
      .when('/groups/:id/members', teamsMembers)
      .when('/groups/:teamId/channels/:id', teamsGroupsTimeline)
      .when('/groups/:teamId/channels/:id/members', teamsGroupsMembers)

      .when('/account/tags', tags)
      .when('/account/tags/new', tagsNew)
      .when('/account/tags/:tagId/edit', tagsEdit)
      .when('/account/tags/:tagId/delete', tagsDelete)

      .when('/account/settings', accountSettings)
      .when('/account/edit', accountEdit)
      .when('/account/paid_subscriptions', { redirectTo: '/account/subscriptions' })
      .when('/account/subscriptions', accountSubscriptions)
      .when('/account/password', accountPassword)
      .when('/account/billing_info', accountBillingInfo)
      .when('/account/billing_info/edit', accountBillingInfoEdit)
      .when('/account/connections', accountConnections)
      .when('/account/benefits', { template: benefits })

      .when('/copy/:id', { template: copy })
      .when('/move/:id', { template: move })
      
      .when('/getting-started', { template: getting_started })

      .when('/upgrade-plus', { template: upgradePlus })
      .when('/500', { template: server_error_template })
      .when('/403', { template: forbidden_template })
      .when('/404', { template: not_found_template })


      .otherwise({ redirectTo: '/404' });

    $locationProvider.html5Mode({ enabled: true, requireBase: false });
  }

  routes.$inject = ['$httpProvider', '$routeProvider', '$locationProvider', '$translateProvider'];
}
