
function LightboxModal($modal) {
  function showLightbox(selectedAttachment, attachmentList) {
    function modalController($scope, $modalInstance) {
      $scope.modal = $modalInstance;
      $scope.selectedAttachment = selectedAttachment;
      $scope.attachmentList = attachmentList;
    }

    modalController.$inject = ['$scope', '$modalInstance'];

    return $modal.open({
      static: false,
      template: `
        <attachment-lightbox-modal
          class="lightbox"
          attachment-list="attachmentList"
          selected-attachment="selectedAttachment"
          modal="modal"
        ></attachment-lightbox-modal>
      `,
      controller: modalController
    });
  }

  return {
    showLightbox: showLightbox,
  };
}

LightboxModal.inject = ['$modal'];

export default LightboxModal;
