/**
 * @module Filters
 *
 */
import Autolinker from 'autolinker';

export function formatText($sanitize, $filter) {
  return function(text) {
    var result = text;

    result = $filter('escapeHTML')(result);
    result = $filter('simpleFormat')(result);
    result = $filter('linkify')(result);

    return result;
  };
}

formatText.$inject = ['$sanitize', '$filter'];

export function unsafe($sce) {
  return function (val) {
    return $sce.trustAsHtml(val);
  };
}

unsafe.$inject = ['$sce'];

export function escapeHTML() {
  // Solution by Twitter's twitter-text.js
  // https://github.com/twitter/twitter-text-js/blob/master/twitter-text.js
  return function(text) {
    var HTML_ENTITIES = {
      '&': '&amp;',
      '>': '&gt;',
      '<': '&lt;',
      '"': '&quot;',
      "'": '&#39;'
    };

    return text && text.replace(/[&"'><]/g, function(character) {
      return HTML_ENTITIES[character];
    });
  };
}

export function simpleFormat() {
  return function(text) {
    if (text) {
      return text.replace(/\n/g, '<br/>');
    }
  };
}

export function linkify() {
  // Solution by Greg Jacobs's Autolinker.js
  // https://github.com/gregjacobs/Autolinker.js
  return function(text) {
    if (text) {
      return Autolinker.link(text, {twitter: false});
    }
  };
}

export function joinBy() {
  return function (input, delimiter) {
    return (input || []).join(delimiter || ', ');
  };
}

export function normalizeTag() {
  return function (tag) {
    return tag.replace(/#/, '');
  };
}

export function truncateTags($filter) {
  return function (tags, limit) {
    var stringifiedTags = tags.join(', ');

    if (stringifiedTags.length > limit) {
      return $filter('limitTo')(stringifiedTags, limit) + '...';
    } else {
      return stringifiedTags;
    }
  };
}

truncateTags.$inject = ['$filter'];

