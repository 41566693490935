/**
 * @module Controllers
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/organizations/_list.html';

/**
 * @class Organizations
 *
 */
function Controller($scope, Organization) {
  $scope.organizations = [];

  Organization.get().then(organizations => run(() => {
    $scope.organizations = organizations;
  }));
}

Controller.$inject = ['$scope', 'Organization'];

export default {
  controller: Controller,
  template
};

