/** @module directives/teams */

/**
 * @class TeamWrapModel
 *
 */
function TeamWrapModel($q, Team, TeamGroup) {
  let data = null;

  function findHelper(teamId) {
    const teamRequest = Team.get(teamId);
    const myTeamGroupsRequest = TeamGroup.all(teamId);
    const teamJoinableGroupsRequest = TeamGroup.joinable(teamId);

    return $q.all([
      teamRequest,
      myTeamGroupsRequest,
      teamJoinableGroupsRequest
    ]).then(([ team, myGroups, joinableGroups ]) => {
      data = { teamId, team, myGroups, joinableGroups };
      return data;
    });
  }

  function reloadHelper() {
    const teamId = data.teamId;

    const teamRequest = Team.reload(teamId);
    const myTeamGroupsRequest = TeamGroup.reload(teamId);
    const teamJoinableGroupsRequest = TeamGroup.reloadJoinable(teamId);

    return $q.all([
      teamRequest,
      myTeamGroupsRequest,
      teamJoinableGroupsRequest
    ]).then(([ team, myGroups, joinableGroups ]) => {
      data = { teamId: data.teamId, team, myGroups, joinableGroups };
      return data;
    });
  }

  return {
    find: findHelper,
    reload: reloadHelper,
  };
}

TeamWrapModel.$inject = ['$q', 'core.services.Team', 'core.services.TeamGroup'];

export default TeamWrapModel;
