/**
 * @module Directives.Posts
 *
 *
 *
 * @class TogglePinModalService
 *
 */
function TogglePinModalService($modal) {
  function togglePin(post) {
    return $modal.open({
      template:  '<toggle-pin-modal post="post" modal="modal"></toggle-pin-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
        $local.post = post;
      }]
    });
  }

  function togglePinNotAllowed(post) {
    return $modal.open({
      template:  '<toggle-pin-not-allowed-modal post="post" modal="modal"></toggle-pin-not-allowed-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
        $local.post = post;
      }]
    });
  }

  return {
    togglePin: togglePin,
    togglePinNotAllowed: togglePinNotAllowed
  };
}

TogglePinModalService.inject = ['$modal'];

export default TogglePinModalService;
