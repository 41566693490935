/** @module legacy/app/directives/activity */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/site_wrap/activity/_notification_summary.html';

/**
 * @class NotificationSummary
 *
 */
function Controller($scope, activityCollection) {
  $scope.pending = true;
  $scope.notifications = [];
  $scope.unread = 0;
  $scope.onRead = onRead;
  $scope.onReadAll = onReadAll;
  $scope.$props.$onDestroy = $onDestroy;

  let unsubscribeFromNotificationActivity = null;

  activityCollection.query().then((e) => run(() => {
    unsubscribeFromNotificationActivity = e.notifications.onChange.attach(onNotificationActivity);
    $scope.notifications = e.notifications.values;
    $scope.unread = e.notifications.unread;
    $scope.pending = false;
  }));

  function onRead(notification) {
    notification.goto();
    activityCollection.query().then((e) =>
      e.notifications.read(notification.id)
    );
  }

  function onReadAll() {
    activityCollection.query().then((e) =>
      e.notifications.readAll()
    );
  }

  function onNotificationActivity(e) {
    $scope.notifications = e.data.values;
    $scope.unread = e.data.unread;
  }

  function $onDestroy() {
    if (unsubscribeFromNotificationActivity) {
      unsubscribeFromNotificationActivity();
    }
  }
}

Controller.$inject = ['$scope', 'core.services.activityCollection'];

export default {
  template,
  controller: Controller,
  controllerAs: '$props'
};

