/**
 * @module Directives.BulkPost.Locations
 *
 */

/**
 * @class TeamLocation
 *
 */
function factory($translate, $property, BulkPostLocation) {
  function TeamLocation(destination) {
    BulkPostLocation.call(this, destination);
  }

  TeamLocation.prototype = Object.create(BulkPostLocation.prototype);

  $property.get(TeamLocation.prototype, 'description', function() {
    return $translate.instant('BULK_POST_SELECTOR.GENERAL');
  });

  $property.get(TeamLocation.prototype, 'paywallModalName', function() {
    var result = BulkPostLocation.MODAL_KINDS.POST_MEDIA;

    if (!this._destination.currentUserIsOwner) {
      result = BulkPostLocation.MODAL_KINDS.POST_MEDIA_OTHER_OWNER;
    }

    return result;
  });

  return TeamLocation;
}

factory.$inject = ['$translate', 'core.$property', 'bulk_post.locations.$bulkPostLocation'];

export default factory;
