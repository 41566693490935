
function http404ErrorInterceptor($q, $location) {
  return {
    responseError: function(response) {
      if (response.status === 404) {
        $location.path('/404');
        $location.replace();
      }
      return $q.reject(response);
    }
  };
}

http404ErrorInterceptor.$inject = ['$q', '$location'];

export default http404ErrorInterceptor;
