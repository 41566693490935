/**
 * @module Core.Services
 *
 */

/**
 * @class Tags
 *
 */
function factory($resource) {
  const Tag = $resource({
    url: '/api/v1/tags',
    name: 'tag'
  });

  const cache = {
    data: null,
    since: null
  };

  Tag.all = function all() {
    const now = Date.now();
    if (cache.since === null || (now - cache.since) > 5000) {
      cache.since = now;
      cache.data = Tag.query({});
    }
    return cache.data;
  };

  return Tag;
}

factory.$inject = ['core.services.$resource'];

export default factory;
