/**
 * @module Core.Services
 *
 */
import { isBlank } from 'app/utils';
import { fetchGet, fetchPost, fetchDelete } from 'app/utils/request';

/**
 * @class Integration
 *
 */
function factory($q, $facebook, identity, tracking) {
  function Integration() {}

  /**
    * Queries all Integrations
    *
    * @returns {Promise}
    */
  Integration.all = function all() {
    return fetchGet('account/integrations', {}, { version: 1 });
  };

  /**
    * Creates a new integration.
    *
    * @param {string} name
    * @param {string} token
    * @returns {Promise}
    */
  Integration.create = function create(name, accessToken) {
    const params = { integration: { name, accessToken }};
    return fetchPost('account/integrations', params, { version: 1 }).catch(e => {
      const error = { name, reason: 'unknown' };
      if (!isBlank(e) && !isBlank(e.errors)) {
        error.reason = e.errors[0];
      }
      return $q.reject(error);
    });
  };

  /**
    * Connects a new provider to the user.
    *
    * @param {string} name
    * @returns {Promise}
    */
  Integration.connect = function connect(name) {
    return Integration.$login(name)
      .then(token => Integration.create(name, token))
      .then(() => identity.$refresh())
      .then(() => tracking.user.connectedOAuthProvider(name))
      .then(() => Integration.all());
  };

  /**
    * Disconnects a provider from user.
    *
    * @param {string} name
    * @returns {Promise}
    */
  Integration.disconnect = function disconnect(name) {
    return fetchDelete(`account/integrations/${name}`, {}, { version: 1 })
      .then(() => Integration.$logout(name));
  };

  /**
    * Retrieves a token from a provider.
    *
    * @param {string} provider
    * @returns {Promise}
    */
  Integration.$login = function $token(provider) {
    switch (provider) {
      case 'facebook':
        return $facebook.login();
      default:
        return $q.reject(new Error('Unknown token provider "' + provider + '".'));
    }
  };

  /**
    * Logs out of provider, if supported.
    *
    * @param {string} provider
    * @returns {Promise}
    */
  Integration.$logout = function $logout(provider) {
    switch (provider) {
      case 'facebook':
        return $facebook.logout();
      default:
        return $q.resolve();
    }
  };

  return Integration;
}

factory.$inject = [
  '$q',
  'core.services.providers.$facebook',
  'core.services.identity',
  'core.services.tracking'
];

export default factory;
