/** @module resources */

/**
 * @class SpaceMember
 *
 */
function factory($resource) {
  const SpaceMember = $resource({
    url: 'api/v1/spaces/{{spaceId}}/members/{{id}}',
    name: 'member'
  });

  SpaceMember.per_page = 20;

  SpaceMember.prototype.canBeManaged = function () {
    return (this.permissions.indexOf('be_managed') !== -1);
  };

  SpaceMember.prototype.canBeDeleted = function () {
    return (this.permissions.indexOf('be_deleted') !== -1);
  };

  SpaceMember.prototype.canBecomeAthlete = function () {
    return (this.permissions.indexOf('become_athlete') !== -1);
  };

  return SpaceMember;
}

factory.$inject = ['core.services.$resource'];

export default factory;
