/**
 * @module Core.Services
 *
 */

/**
 * @class ActivityCollection
 *
 */
function factory($q, $IncomingInvitationCollection, $OutgoingInvitationCollection, $NotificationCollection) {
  let $request = null;

  function query(reload = false) {
    if ($request == null || reload === true) {
      const promiseList = [
        $IncomingInvitationCollection.query(),
        $OutgoingInvitationCollection.query(),
        $NotificationCollection.query()
      ];

      $request = $q.all(promiseList).then(([ invitations, outgoing, notifications ]) =>
        ({ invitations, outgoing, notifications })
      );
    }
    return $request;
  }

  return { query };
}

factory.$inject = [
  '$q',
  'core.services.activity.$IncomingInvitationCollection',
  'core.services.activity.$OutgoingInvitationCollection',
  'core.services.activity.$NotificationCollection'
];

export default factory;
