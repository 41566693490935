/**
 * @module Directives.Teams
 *
 */
import template from 'ngapp/templates/views/teams/_team_post_attachment_list.html';
import { run } from "app/utils/runloop";

/***/
const REPLY_CSS = {
  'background-color': '#ffffff',
  'border': '0',
  'max-height': '200px'
};

const REPLY_MARGINS = {
  'margin-left': '0.6rem',
  'margin-right': '0.5rem'
};

/**
 * @class TeamPostAttachementList
 *
 */
function Controller($scope, $element, mediaHelpers, lightboxModal, media, moment, tracking) {
  $scope.attachments = $scope.$props.resources || [];
  $scope.isReply = $scope.$props.isReply;
  $scope.createMediaItemCSS = mediaHelpers.createMediaItemCSS;
  $scope.createMediaSpriteClassName = mediaHelpers.createMediaSpriteClassName;
  $scope.createMediaItemIconClassName = mediaHelpers.createMediaItemIconClassName;
  $scope.createMediaItemClassNames = mediaHelpers.createMediaItemClassNames;
  $scope.hasPreviewImage = hasPreviewImage;
  $scope.getsPlayOverlay = getsPlayOverlay;
  $scope.getMediaIcon = getMediaIcon;
  $scope.showLightbox = showLightbox;
  $scope.getPreviewImage = getPreviewImage;
  $scope.styleIfReply = styleIfReply;
  $scope.onInit = onInit;
  $scope.remaining = '';
  $scope.isPlaying = false;
  $scope.onAudioClick = onAudioClick;
  $scope.getVideoDuration = getVideoDuration;
  $scope.onDatafileClick = onDatafileClick;
  let playerId = null;

  function onStatusUpdate(status) {
    run(() => {
      $scope.isPlaying = status.playing;
      $scope.remaining = moment.utc(1000 * status.remaining).format('mm:ss');
      $scope.isReady = status.isReady;
    });
  }

  function onDatafileClick() {
    tracking.postViews.sendContentView($scope.$props.post);
  }

  function onAudioClick() {
    $scope.isPlaying = media.togglePlayer(playerId, 'audio');
    tracking.postViews.sendContentView($scope.$props.post);
  }

  function onInit(resource) {
    const audio = $element.find('.audio')[0];
    playerId = media.createPlayer(resource, audio, onStatusUpdate);
  }

  function styleIfReply(isReply, noMargin) {
    if (isReply) {
      if (!noMargin) {
        return Object.assign({}, REPLY_CSS, REPLY_MARGINS);
      }
      return REPLY_CSS;
    }
  }

  function hasPreviewImage(attachment) {
    return mediaHelpers.getPreviewUrl(attachment) != null;
  }

  function getPreviewImage(attachment) {
    if (hasPreviewImage(attachment)) {
      return mediaHelpers.getPreviewUrl(attachment);
    }
  }

  function getMediaIcon(attachment) {
    return mediaHelpers.getSpriteClassName(attachment.url);
  }

  function getsPlayOverlay(attachment) {
    return attachment.type == 'audio' || attachment.type == 'video' && hasPreviewImage(attachment);
  }

  function showLightbox(attachment) {
    if (attachment.type === 'video' || attachment.type === 'image') {
      tracking.postViews.sendContentView($scope.$props.post);
    }

    lightboxModal.showLightbox(attachment, $scope.attachments);
  }

  function getVideoDuration(attachment) {
    if ($scope.$props.post.isDemo) {
      if (attachment.versions && attachment.versions.default && attachment.versions.default.durationInMs) {
        return moment.utc(attachment.versions.default.durationInMs).format('mm:ss');
      } else {
        return '00:00';
      }
    } else {
      if (attachment.duration) {
        return moment.utc(attachment.duration).format('mm:ss');
      } else {
        return '00:00';
      }
    }
  }  
}

Controller.$inject = ['$scope', '$element', 'mediaHelpers', 'lightboxModal', 'core.services.media', 'moment', 'core.services.tracking'];

export default {
  bindings: {
    resources: '<',
    isReply: '<',
    post: '<',
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
