/**
 * @module Controllers
 *
 */
import { fetchGet, fetchPost } from 'app/utils/request';
import { digest } from 'app/utils/runloop';

/**
 * @class PostCreator
 *
 */
function Controller($, $scope, $translate, Resource, Segment, $location, Tag) {
  var timelinePresent = ($('.timeline').length != 0);
  var inSpace = (/spaces\/\w{32}/).test(window.location.pathname);
  var fromResource = (/resource_id=\/\w{32}/).test(window.location.pathname);

  var reset = function () {
    $scope.post = {};
    $scope.post.details = "";
    $scope.post.tags = [];
    $scope.post.resource_id = null;

    if (inSpace) {
      updateTags([getCurrentSpaceId()]);
    } else {
      updateTags();
    }

    $scope.errors = [];
    $scope.uploading = false;
    $scope.creating = false;

    initSpacesSelector();
    initSegmentsSelector();
  };

  var getCurrentSpaceId = function () {
    return (/spaces\/(\w{32})/).exec(window.location.pathname)[1];
  };

  var initSegmentsSelector = function () {
    $scope.checkedSegments = {};

    Segment.query().then(function (segments) {
      $scope.userSegments = segments;

      $scope.userSegment.forEach(function (segment) {
        $scope.checkedSegments[segment.id] = false;
      });
    });
  };

  var initSpacesSelector = function () {
    $scope.checkedSpaces = {};

    fetchGet('spaces/grouped_by_athlete', { cache: true }, { version: 1 }).then((data) => {
      $scope.userSpaces = data;

      $scope.userSpaces.forEach((user) =>
        user.spaces.forEach(space =>
          $scope.checkedSpaces[space._id] = false
        )
      );
    });
  };

  var updateTimeline = function (postId, inSpace) {
    var url = "/posts/" + postId;

    if (!inSpace) {
      url += "?include_space_in_post=1";
    }

    $.ajax({ url: url, dataType: 'script' });
  };

  var isValid = function () {
    var errors = {},
      post = $scope.post;

    if (post.space_id == "" && post.segment_ids.length == 0) {
      errors.space_id = true;
    }

    if ((post.details == "") && (post.resource_id == null)) {
      errors.details = true;
    }

    $scope.errors = errors;

    return (Object.keys(errors || {}).length == 0);
  };

  var selectedSpaceIds = function () {
    return selectedIdsFor($scope.checkedSpaces);
  };

  var selectedSegmentIds = function () {
    return selectedIdsFor($scope.checkedGroups);
  };

  var selectedSmartListIds = function () {
    return selectedIdsFor($scope.checkedSmartLists);
  };

  var selectedIdsFor = function (hashMap) {
    var checkedIds = Object.keys(hashMap || {});

    return checkedIds.filter(function (id) {
      return (hashMap[id] === true);
    });
  };

  var updateTags = function (spaceIds) {
    Tag.onlyNames({ space_id: spaceIds }).then(function (data) {
      if (Array.isArray(data)) {
        $scope.availableTags = data;
      }
    });
  };

  var hideWindow = function () {
    $('.modal.create-post').modal('hide');
  };

  $('.modal.create-post').on('hidden', function () {
    reset();
    digest();
  });


  $scope.initWithResource = function (resourceId) {
    $scope.post.resource_id = resourceId;
    $scope.hiddenUploader = true;

    Resource.get(resourceId).
      then(function (resource) {
        $scope.post.tags = resource.tags;
      });
  };

  $scope.selectAllSpaces = function () {
    $scope.userSpaces.forEach(function (user) {
      user.spaces.forEach(function (space) {
        $scope.checkedSpaces[space._id] = true;
      });
    });
  };

  $scope.unselectAllSpaces = function () {
    $scope.userSpaces.forEach(function (user) {
      user.spaces.forEach(function (space) {
        $scope.checkedSpaces[space._id] = false;
      });
    });
  };

  $scope.publish = function () {
    $scope.post.space_id = (inSpace ? getCurrentSpaceId() : selectedSpaceIds());
    $scope.post.segment_ids = selectedSegmentIds();
    $scope.post.smart_list_ids = selectedSmartListIds();

    if (isValid()) {
      $scope.creating = true;

      fetchPost('posts/', $scope.post, { version: 1 })
        .then((post) => {
          if (timelinePresent) {
            $scope.close();
            updateTimeline(post._id, inSpace);
          } else {
            // TODO update to $location.path after this page will be
            // rewritten completely with Angular
            if (post.is_bulk) {
              $location.path('/feed');
            } else {
              $location.path('/spaces/' + post.space_id);
            }
          }
        })
        .catch(() => {
          $translate('CONTROLLERS.POST_CREATOR.PUBLISH_ERROR_MESSAGE').then(function (translation) {
            alert(translation);
          });
        });
    }
  };

  $scope.errorFor = function (fieldName) {
    return $scope.errors[fieldName];
  };

  $scope.close = function () {
    hideWindow();
  };

  $scope.cancel = function () {
    if (fromResource) {
      $location.path('/resources');
    } else {
      $location.path('/feed');
    }
  };

  $scope.inSpace = inSpace;

  reset();

  // watch for checked spaces and update tags
  if (!inSpace) {
    $scope.$watch(function (scope) {
      return angular.toJson(scope.checkedSpaces);
    }, function () {
      updateTags(selectedSpaceIds());
    });
  }
}

Controller.$inject = [
  '$',
  '$scope',
  '$translate',
  'Resource',
  'Segment',
  '$location',
  'Tag'
];

export default {
  name: 'PostCreatorCtrl',
  controller: Controller
};

