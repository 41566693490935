
import checkmark_green_png from 'app/images/checkmark-green.png';
import template from 'ngapp/templates/views/spaces/_transfer_space_complete_modal.html';

function Controller($scope) {
  $scope.member = $scope.$props.member;
  $scope.onDone = onDone;
  $scope.checkmark_green_png = checkmark_green_png;

  function onDone() {
    $scope.$props.modal.close();
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    modal: '<',
    member: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
