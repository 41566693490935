// Rename query params that contain array regarding rails
// convetions
// Example
//    tag: [1,2,3]
// param will be renamed into this
//    'tag[]': [1,2,3]
export default function httpArrayQueryHandler() {
  return {
    request: function (config) {
      if (config.method === 'GET') {
        var params = config.params;

        angular.forEach(params, function(value, name) {
          if (angular.isArray(value)) {
            delete params[name];
            params[name + '[]'] = value;
          }
        });
      }
      return config;
    }
  };
}
