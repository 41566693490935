/** @module controllers/teams_groups */
import { run } from 'app/utils/runloop';
import reject from 'lodash/reject';
import template from 'ngapp/templates/views/teams_groups/_members.html';

/**
 * @class MembersController
 *
 */
function Controller($q, $location, $scope, $params, tracking, TeamGroup, createPager, teamWrapModel) {
  $scope.teamId = $params.teamId;
  $scope.groupId = $params.id;
  $scope.pending = true;
  $scope.team = null;
  $scope.myGroups = null;
  $scope.joinableGroups = null;
  $scope.group = null;
  $scope.members = [];
  $scope.filters = {};
  $scope.onFilter = onFilter;
  $scope.onAddMembers = onAddMembers;
  $scope.onRemoved = onRemoved;
  $scope.onPage = onPage;
  $scope.onJoin = onJoin;

  teamWrapModel.find($params.teamId).then(model => run(() => {
    $scope.team = model.team;
    $scope.myGroups = model.myGroups;
    $scope.joinableGroups = model.joinableGroups;
  }));

  $scope.reloadGroups = () => {
    teamWrapModel.reload().then(model => run(() => {
      $scope.team = model.team;
      $scope.myGroups = model.myGroups;
      $scope.joinableGroups = model.joinableGroups;
    }));
  };

  const setMembers = (members) => {
    run(() => $scope.members = members);
  };

  let pager = createPager($scope.groupId, $scope.filters);
  $q.all([
    TeamGroup.get($scope.groupId).then(group => run(() => $scope.group = group)),
    pager.get().then(members => setMembers(members))
  ]).then(() => run(() => $scope.pending = false));

  pager.onPage.attach(e => setMembers([ ...$scope.members, ...e.data.results]));

  function onAddMembers() {
    refreshMemberPager();
  }

  function onFilter(filters) {
    $scope.filters = filters;
    tracking.teamGroups.membersFiltered($scope.group, $scope.team, filters);
    refreshMemberPager();
  }

  function onRemoved(member) {
    setMembers(reject($scope.members, { id: member.id }));
  }

  function onPage() {
    pager.next();
  }

  function refreshMemberPager() {
    if (pager != null) {
      pager.destroy();
    }
    pager = createPager($scope.groupId, $scope.filters);
    pager.onPage.attach(e => setMembers([ ...$scope.members, ...e.data.results ]));
    return pager.get().then(members => setMembers(members));
  }

  function onJoin(group) {
    if (group == null) {
      throw new Error('group is required for onJoin');
    }

    $scope.pending = true;
    TeamGroup.join(group).then(() =>
      teamWrapModel.find(group.team.id).then(result => run(() => {
        $scope.team = result.team;
        $scope.myGroups = result.myGroups;
        $scope.joinableGroups = result.joinableGroups;
        $scope.pending = false;
        $location.path(group.timelineUrl);
      }))
    );
  }
}

Controller.$inject = [
  '$q',
  '$location',
  '$scope',
  '$routeParams',
  'core.services.tracking',
  'core.services.TeamGroup',
  'groupMemberPagerFactory',
  'teamWrapModel'
];

export default {
  controller: Controller,
  template
};

