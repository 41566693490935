
import assign from 'lodash/assign';
import hasIn from 'lodash/hasIn';

function MemberForSelectPagerFactory(TeamMember, TeamGroup, createPager) {
  const settings = {
    limit: 20,
    throttle: 10000
  };

  function serialize(filters) {
    if (hasIn(filters, 'query')) {
      return { userName: filters.query };
    }
    return {};
  }

  return function create(id, filters) {
    function load(request) {
      return TeamMember.$get(
        TeamGroup.$url({ id }, '/members_for_select'),
        assign(serialize(filters), { page: request.page, perPage: request.limit })
      );
    }

    return createPager(load, settings);
  };
}

MemberForSelectPagerFactory.$inject = [ 'core.services.TeamMember', 'core.services.TeamGroup', 'core.services.pagerFactory' ];

export default MemberForSelectPagerFactory;
