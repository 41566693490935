/**
 * @module Core.Services
 *
 */

/**
 * @class ResourceUploader
 *
 */
function factory(uploader, Resource) {
  function upload(file, tags) {
    const request = uploader.file(file);
    request.uploaded = request.uploaded.then(({ id, filename }) =>
      Resource.create({ id, filename, tags })
    );
    return request;
  }
  return upload;
}

factory.$inject = ['core.services.uploader', 'core.services.Resource'];

export default factory;
