/** @module resources */

function TeamSpace($resource) {
  return $resource({
    url: '/api/v1/teams/{{teamId}}/spaces',
    name: 'space'
  });
}

TeamSpace.$inject = ['core.services.$resource'];

export default TeamSpace;
