/**
 * @module Controllers.SpaceMemberships
 *
 */
import template from 'ngapp/templates/views/space_memberships/_list.html';

/**
 * @class SpaceMemberships
 *
 */
function Controller(
  $scope,
  $location,
  $routeParams,
  $translate,
  toast,
  $route,
  planFeature,
  Space,
  SpaceMember,
  identity,
  listLoader,
  listFilter,
  listPaging
) {
  $scope.members = [];
  $scope.pending = true;

  listLoader($scope, 'members', loadMembersPromise, loadMembersHandler);
  listPaging($scope, SpaceMember.per_page);
  listFilter($scope, {}, 'members');

  $scope.spaceId = $routeParams.spaceId;
  $scope.space = {};

  identity.request().then(user => $scope.currentUser = user);

  Space.get($scope.spaceId, {
    'options[include]': ['team_info', 'user_settings']
  }).then((space) => {
    $scope.space = space;
    $scope.pending = false;
  });

  function loadMembersPromise() {
    $scope.startLoading();
    const queryParams = {
      page: $scope.page,
      per_page: SpaceMember.per_page
    };
    angular.extend(queryParams, $scope.filterCriteria);
    return SpaceMember.query(queryParams, {
      spaceId: $scope.spaceId
    });
  }

  function loadMembersHandler(members) {
    members.forEach(member => $scope.members.push(member));
  }

  $scope.canChangeAthlete = (member) => {
    return ($scope.space.id && member.canBecomeAthlete() && $scope.space.can.makeAthlete);
  };

  $scope.isSpaceAthlete = (user) => {
    return ($scope.space.id && $scope.space.isForAthlete && $scope.space.athleteId === user.id);
  };

  $scope.isSpaceCoach = (user) => {
    return (user.spaceRole === 'coach');
  };

  $scope.isTeamOwner = (user) => {
    return ($scope.space.id && $scope.space.isForTeam && $scope.space.team.billingUserId === user.id);
  };

  $scope.isTeamManager = (user) => {
    return ($scope.space.id && $scope.space.isForTeam && user.teamRole === 'admin');
  };

  $scope.isRecruiter = (user) => {
    return (user.spaceRole === 'recruiter');
  };

  $scope.makeAthlete = (member) => {
    if (planFeature.space.canManageMembers($scope.space)) {
      member.spaceId = $scope.spaceId;
      member.role = 'athlete';

      member.update()
        .then(() => {
          $scope.space.athleteId = member.id;
          toast.success($translate.instant('CONTROLLERS.SPACE_MEMBERSHIPS.MAKE_ATHLETE_SUCCESS_MESSAGE', {
            name: member.displayName
          }));
          $route.reload();
        })
        .catch(() => toast.danger($translate.instant('CONTROLLERS.SPACE_MEMBERSHIPS.MAKE_ATHLETE_ERROR_MESSAGE')));
    }
  };

  $scope.removeMember = (member) => {
    if (planFeature.space.canManageMembers($scope.space)) {
      member.spaceId = $scope.spaceId;
      member.remove()
        .then(() => {
          Array.removeObj($scope.members, member);
          toast.success(
            $translate.instant(
              'CONTROLLERS.SPACE_MEMBERSHIPS.REMOVE_MEMBER_SUCCESS_MESSAGE', {
                name: member.displayName,
                sport: $scope.space.sport
              }
            )
          );
        })
        .catch(() => {
          toast.danger($translate.instant('CONTROLLERS.SPACE_MEMBERSHIPS.REMOVE_MEMBER_ERROR_MESSAGE'));
        });
    }
  };

  $scope.leave = (member) => {
    if (planFeature.space.canLeave($scope.space)) {
      member.spaceId = $scope.spaceId;
      member.remove()
        .then(() => {
          Array.removeObj($scope.members, member);
          toast.success($translate.instant('CONTROLLERS.SPACE_MEMBERSHIPS.LEAVE_SUCCESS_MESSAGE', {
            sport: $scope.space.sport
          }));
          $location.path('/spaces');
        })
        .catch(() => toast.danger($translate.instant('CONTROLLERS.SPACE_MEMBERSHIPS.LEAVE_ERROR_MESSAGE')));
    }
  };

  $scope.loadList();
}

Controller.$inject = [
  '$scope',
  '$location',
  '$routeParams',
  '$translate',
  'core.ui.toast',
  '$route',
  'core.services.planFeature',
  'core.services.Space',
  'SpaceMember',
  'core.services.identity',
  'listLoader',
  'listFilter',
  'listPaging'
];

export default {
  controller: Controller,
  template
};
