/**
 * @module Core
 *
 */

/**
 * @class Features
 *
 */
function FeaturesFactory($window, $) {

  /**
    * Does the current UA support drag and drop?
    *
    * @returns {boolean}
    */
  function detectDragAndDrop() {
    var div = $('<div/>').get(0);

    if ('draggable' in div) {
      return true;
    }

    return 'ondragstart' in div && 'ondrop' in div;
  }

  /**
    * Does the current UA support the FileReader api?
    *
    * @return {boolean}
    */
  function detectFileReader() {
    return 'FileReader' in $window;
  }

  /**
    * Detects if file drag and drop is supported.
    *
    * @returns {boolean}
    */
  function detectDragAndDropFiles() {
    return detectDragAndDrop() && detectFileReader();
  }

  /**
    * Detects if passive listening is available.
    *
    * @return {boolean}
    */
  function detectPassiveListening() {
    var available = false;

    try {
      var options = Object.defineProperty({}, 'passive', {
        get() {
          available = true;
          return available;
        }
      });

      $window.addEventListener("$$passive", null, options);
      $window.removeEventListener("$passive", null, options);
    } catch (e) {
      return available;
    }
    return available;
  }

  return {
    dragAndDrop: detectDragAndDrop(),
    fileReader: detectFileReader(),
    dragAndDropFiles: detectDragAndDropFiles(),
    passiveListening: detectPassiveListening()
  };
}

FeaturesFactory.$inject = ['$window', '$'];

export default FeaturesFactory;
