
import template from 'ngapp/templates/views/teams_groups/_group_nav.html';

function Controller($scope, $location) {
  $scope.group = null;
  $scope.active = getActiveTab($scope.group);

  function getActiveTab(group={}) {
    if (group == null) {
      return '';
    }

    switch ($location.path()) {
      case group.timelineUrl:
        return 'timeline';
      case group.membersUrl:
        return 'members';
    }
  }

  $scope.changePath = function(url) {
    $location.path(url);
    $location.replace();
  };

  $scope.$props.$onChanges = function(props) {
    if (props.group != null) {
      $scope.group = props.group.currentValue;
      $scope.active = getActiveTab($scope.group);
    }
  };
}

Controller.$inject = ['$scope', '$location'];

export default {
  bindings: {
    group: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
