/**
 * @module Controllers.SegmentSpaces
 *
 */
import { all } from 'rsvp';
import pick from 'lodash/pick';
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/segments/_add_members.html';

/**
 * @class SegmentSpacesMembers
 *
 */
function Controller($scope, $routeParams, $translate, Segment, $location) {
  $scope.newMember = {name: '', email: '', role: 'coach'};
  $scope.processing = false;
  $scope.focused = true;
  $scope.role = 'coach';
  $scope.memberRoleOptions = [];
  $scope.selectedRole = null;
  $scope.resourceId = $routeParams.segmentId;

  all([
    $translate('SEGMENTS.ADD_MEMBERS.COACH'),
    $translate('SEGMENTS.ADD_MEMBERS.ATHLETE'),
    $translate('SEGMENTS.ADD_MEMBERS.FAN')
  ]).then(([ coach, athlete, contributor ]) => {
    run(() => {
      $scope.memberRoleOptions = [
        { value: "coach", label: coach.toString() },
        { value: "athlete", label: athlete.toString() },
        { value: "contributor", label: contributor.toString() },
      ];
      $scope.selectedRole = $scope.memberRoleOptions.find(item => item.value === $scope.role);
    });
  });

  $scope.onSelectRole = (value) => {
    $scope.selectedRole = value;
    if (value != null && value.length) {
      $scope.role = value.value;
    }
  };

  $scope.user = null;
  $scope.onSelectUser = (value) => {
    if (value != null && value.length) {
      const opt = value[0];
      $scope.user = opt.model;
    }
  };

  $scope.buttonsDisabled = function() {
    return $scope.processing || !$scope.user;
  };

  Segment.get($routeParams.segmentId)
    .then(segment => run(() => {
      $scope.segment = segment;
      $scope.processing = false;
    }))
    .catch(() => run(() => {
      $scope.processing = false;
      $translate('CONTROLLERS.SEGMENT_SPACES_MEMBERS.LOAD_ERROR_MESSAGE').then(alert);
    }));

  $scope.addMember = function() {
    $scope.processing = true;
    $scope.segment.addMember({invitation: $scope.newMember})
      .then(() => run(() => {
        $scope.processing = false;
        $location.path('/lists/');
      }))
      .catch(() => run(() => {
        $scope.processing = false;
        $translate('CONTROLLERS.SEGMENT_SPACES_MEMBERS.ADD_ERROR_MESSAGE').then(alert);
      }));
  };

  $scope.$watch('user', function(user) {
    if (!user) {
      return;
    }

    $scope.newMember = pick(user, 'name', 'email');
    $scope.newMember.role = $scope.role;
  });

  $scope.removeSelectedUser = function() {
    // reset user for react-user-select
    $scope.user = null;

    // reset to not post old data
    $scope.newMember.name = '';
    $scope.newMember.email = '';
    $scope.newMember.role = null;
    $scope.focused = true;
  };
}

Controller.$inject = [
  '$scope',
  '$routeParams',
  '$translate',
  'Segment',
  '$location'
];

export default {
  controller: Controller,
  template
};
