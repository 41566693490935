/**
 * @module Directives.Spaces
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/spaces/_space_settings_modal.html';

/**
 * @class SpaceSettingsModal
 *
 */
function Controller($scope, $q, $location, $translate, $clipboard, identity, Space, Sports, confirm, tracking, toast) {
  $scope.pending = false;
  $scope.user = null;
  $scope.space = $scope.$props.space;
  $scope.fields = {
    sendInvitationReminders: $scope.space.sendInvitationReminders,
    sport: $scope.space.sport,
    athleteName: $scope.space.athleteName,
    athletePhone: $scope.space.athletePhone
  };
  $scope.defaultPhoneCountry = null;
  $scope.onCancel = onCancel;
  $scope.onDelete = onDelete;
  $scope.onSubmit = onSubmit;
  $scope.onChange = onChange;
  $scope.onTransfer = onTransfer;
  $scope.onCopyClick = onCopyClick;
  $scope.onPhoneInputChange = onPhoneInputChange;

  identity.request().then(user => run(() => {
    $scope.user = user;
    setDefaultPhoneCountry(user.phoneCountry);
  }));

  function setDefaultPhoneCountry(phoneCountry) {
    if ($scope.fields.athletePhone) {
      return;
    }

    if (phoneCountry) {
      $scope.defaultPhoneCountry = phoneCountry.toLowerCase();
    } else {
      $scope.defaultPhoneCountry = 'us';
    }
  }

  function onPhoneInputChange(formattedValue) {
    $scope.fields.athletePhone = '+' + formattedValue;
  }

  function onTransfer() {
    $scope.$props.modal.close({
      action: 'transfer'
    });
  }

  function onChange(value) {
    run(() => {
      $scope.fields.sport = value && value.label ? value.label : null;
    });
  }

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onDelete() {
    const name = $scope.space.fullName;
    $scope.pending = true;

    const afterConfirm = (result) => {
      if (result.confirmed) {
        return Space.remove($scope.space)
          .then(() => identity.$refresh())
          .then(() => {
            tracking.spaces.deleted($scope.space);
            toast.success($translate.instant('SPACES.SETTINGS.DELETED_MESSAGE', { name: name }));
            $scope.$props.modal.close();
            $location.path('/spaces');
          });
      }
    };

    confirm({ body: $translate.instant('SPACES.SETTINGS.DELETE_CONFIRMATION', { name }) })
      .then(result => afterConfirm(result))
      .finally(() => run(() => $scope.pending = false));
  }

  function onSubmit(e) {
    e.preventDefault();
    $scope.pending = true;

    Space.update($scope.space, $scope.fields)
      .then(function (result) {
        tracking.spaces.updated(result);
        toast.success($translate.instant('SPACES.SETTINGS.SAVE_SUCCESS_MESSAGE'));
        $scope.$props.modal.close();
      })
      .finally(function () {
        $scope.pending = false;
      });
  }

  function onCopyClick(e) {
    $q.resolve($clipboard.copy($scope.space.email, e))
      .then(() => toast.success($translate.instant('SPACES.SETTINGS.COPY_SUCCESS')))
      .catch(() => toast.danger($translate.instant('SPACES.SETTINGS.COPY_ERROR')));
  }
}

Controller.$inject = [
  '$scope',
  '$q',
  '$location',
  '$translate',
  'core.services.$clipboard',
  'core.services.identity',
  'core.services.Space',
  'Sports',
  'confirm',
  'core.services.tracking',
  'core.ui.toast'
];

export default {
  bindings: {
    modal: '<',
    space: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
