/**
 * @module Services
 *
 */
import { resolve } from 'rsvp';
import { run } from 'app/utils/runloop';

/**
 * @class Auth
 *
 */
function Auth(identity) {
  const service = {
    current: null,

    currentUser() {
      return service.current;
    },

    login() {
      return identity.request().then(user => run(() => {
        service.current = user;
        return user;
      }));
    },

    getCurrentUser() {
      if (service.current != null) {
        return resolve(service.current);
      }

      return identity.request().then(user => run(() => {
        service.current = user;
        return user;
      }));
    },

    updateCurrentUser() {
      return identity.$refresh().then(user => run(() => {
        service.current = user;
        return user;
      }));
    }
  };

  return service;
}

Auth.$inject = ['core.services.identity'];

export default Auth;
