(function ($) {
  $.fn.localtime = function() {
    return this.each(function() {
      var title, locale, format, date, formatted_date;

      title  = $(this).attr('title');
      locale = $(this).attr('locale') || 'en';
      format = $(this).attr('format') || 'DD MMM YYYY, H:mm';

      if (title) {
        date  = new Date(title);
        formatted_date = moment(date).locale(locale).format(format);
        $(this).text(formatted_date);
      }
    });
  };
}(jQuery));
