/**
 * @module Controllers.SpaceTemplates
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/space_templates/_edit.html';

/**
 * @class SpaceTemplateEdit
 *
 */
function Controller($, $scope, $location, $translate, $routeParams, SpaceTemplate, toast) {
  $scope.processing = false;
  $scope.spaceTemplate = {};

  SpaceTemplate.get($routeParams.spaceTemplateId)
    .then(spaceTemplate => run(() => $scope.spaceTemplate = spaceTemplate))
    .catch(() => toast.danger($translate.instant('CONTROLLERS.SPACE_TEMPLATE_EDIT.LOAD_ERROR_MESSAGE')));

  $scope.update = function() {
    $scope.processing = true;

    $scope.spaceTemplate.update().then(function() {
      $scope.processing = false;
      $location.path('/space_templates');
    }, function(response) {
      $scope.processing = false;

      var alertMessage = '';
      $.each(response.data.errors, function(index, message) {
        alertMessage += index + ': ' + message.join(', ') + '\n';
      });
      alert(alertMessage);
    });
  };
}

Controller.$inject = [
  '$',
  '$scope',
  '$location',
  '$translate',
  '$routeParams',
  'SpaceTemplate',
  'core.ui.toast'
];

export default {
  controller: Controller,
  template,
};

