
function UpgradeModalService($modal) {
  function show() {
    return $modal.open({
      template: '<upgrade-options-modal modal="modal"></upgrade-options-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
      }]
    });
  }

  return { show: show };
}

UpgradeModalService.inject = ['$modal'];

export default UpgradeModalService;
