/**
 * @module Core.Services
 *
 */
import isFunction from 'lodash/isFunction';
import noop from 'lodash/noop';

/**
 * @class PagerFactory
 *
 */
function factory($q, $timeout, Signal) {
  const defaults = {
    limit: 10,
    throttle: 5000
  };

  function create(load, options) {
    if (!isFunction(load)) {
      throw new TypeError('Expected load to be a function.');
    }

    const config = Object.assign({}, defaults, options);
    let pageRef = 1;
    let paging = false;
    let destroyed = false;
    let onPage = new Signal('core.services.pagerFactory');
    let hasMore = true;

    function $get(page) {
      const args = { page, limit: config.limit };

      let promise = load(args);
      if (!promise.then) {
        promise = $q.resolve(promise);
      }

      return promise
        .then(results => results || [])
        .then(results => {
          if (args.limit > results.length) {
            hasMore = false;
          }
          return results;
        });
    }

    function get() {
      return $get(pageRef);
    }

    function next() {
      if (!hasMore) {
        return $q.resolve([]);
      }

      if (!paging) {
        paging = true;
        let page = pageRef + 1;
        const limit = config.limit;

        $get(page).then(results => {
          onPage.dispatch(
            'core.services.pagerFactory',
            { page, limit, results, length: results.length }
          );

          if (results.length > 0) {
            pageRef = page;
          } else {
            return $timeout(noop, config.throttle, false);
          }
          return results;
        }).finally(() => paging = false);
      }
    }

    function destroy() {
      if (!destroyed) {
        onPage.clear();
      }
      destroyed = true;
    }

    return {
      get: get,
      next: next,
      destroy: destroy,
      onPage: onPage
    };
  }

  return create;
}

factory.$inject = ['$q', '$timeout', 'core.Signal'];

export default factory;

