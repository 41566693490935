/**
 * @module Controllers
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/_search.html';

/**
 * @class SearchPage
 *
 */
function Controller($scope, $location, Search, listLoader, listPaging) {
  $scope.searchObj = {
    value: '',
    replies: false
  };
  $scope.searchObj.value = '';
  $scope.searchObj.replies = false;

  $scope.searchResults = [];
  $scope.postParams = {};

  listLoader($scope, 'searchResults', loadSearchPromise, loadSearchHandler);
  listPaging($scope, Search.per_page);

  function loadSearchPromise() {
    const queryParams = {
      q: $scope.searchObj.value,
      search_replies: $scope.searchObj.replies,
      page: $scope.page,
      per_page: Search.per_page
    };

    return Search.query(queryParams);
  }

  function loadSearchHandler(results) {
    run(() => {
      $scope.searchResults = [ ...$scope.searchResults, ...results ];
    });
  }

  $scope.submitForm = function() {
    run(() => {
      $location.path('search');

      if($scope.searchObj.value){
        $location.search('q', $scope.searchObj.value);
      }

      if($scope.searchObj.replies){
        $location.search('search_replies', 'true');
      }else{
        $location.search('search_replies', null);
      }
    });
  };

  const getLocationInfo = () => {
    run(() => {
      const value = $location.search().q;
      if (value != null) {
        $scope.searchObj.value = value;
      } else {
        $scope.searchObj.value = '';
      }

      const replies = $location.search().search_replies ? true : false;
      $scope.searchObj.replies = replies;

      $scope.loadList();
    });
  };

  getLocationInfo();
}

Controller.$inject = [
  '$scope',
  '$location',
  'Search',
  'listLoader',
  'listPaging'
];

export default {
  controller: Controller,
  template
};

