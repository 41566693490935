/**
 * @module Directives
 *
 */
import { digest } from 'app/utils/runloop';

export function OnEnterKey() {
  function link(scope, element, attrs) {
    element.bind("keypress", function(event) {
      var enterKeyCode = 13;
      if (event.which === enterKeyCode) {
        // TODO:
        // isModifierKeyPressed is not defined
        //
        // if (isModifierKeyPressed(event)) {
        //   return false;
        // }
        digest(() => scope.$eval(attrs.onEnterKey));

        return event.preventDefault();
      }
    });
  }

  return {
    restrict: 'A',
    link: link
  };
}

export function FocusMe() {
  function link(scope, element, attrs) {
    if (attrs.focusMe === "true") {
      element.focus();
    }
  }

  return {
    restrict: 'A',
    link: link
  };
}

export function StopEvent() {
  function link(scope, element, attrs) {
    element.bind(attrs.stopEvent, function(e) {
      e.stopPropagation();
      return e.preventDefault();
    });
  }

  return {
    restrict: 'A',
    link: link
  };
}

export function NgPreventDefault($) {
  function link(scope, element /*, attrs*/) {
    $(element).click(function(e) {
      return e.preventDefault();
    });
  }

  return {
    link: link
  };
}
NgPreventDefault.$inject = ['$'];


export function NgValidate() {
  function link(scope, element, attrs) {
    var form = element.inheritedData('$formController');

    if (!form) { return; }

    scope.$watch(attrs.ngValidate, function(errors) {
      var key, typeIsArray, value, _results;
      form.$serverError = {};
      if (!errors) {
        form.$serverInvalid = false;
        return;
      }
      form.$serverInvalid = errors.length > 0;
      typeIsArray = Array.isArray || function(value) {
        return {}.toString.call(value) === '[object Array]';
      };
      _results = [];
      for (key in errors) {
        value = errors[key];
        if (value && typeIsArray(value)) {
          _results.push(form.$serverError[key] = {
            $invalid: true,
            message: value.join()
          });
        } else {
          _results.push(void 0);
        }
      }
      return _results;
    });
  }

  return {
    link: link
  };
}
