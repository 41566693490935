/**
 * @module Services
 *
 */

/**
 * @class AsyncQueue
 *
 */
export default function AsyncQueue() {
  function Queue() {
    this.promises = [];
    this.executing = false;
  }

  Queue.prototype.addPromise = function addPromise(promise, handler) {
    this.promises.push({ promise, handler });
  };

  Queue.prototype.getOldestPromise = function getOldestPromise() {
    return this.promises.shift();
  };

  Queue.prototype.exec = function exec() {
    if (this.executing) { return; }

    const ctx = this.getOldestPromise();

    if (!ctx) { return; }

    this.executing = true;
    return ctx.promise()
      .then(ctx.handler)
      .finally(() => {
        this.executing = false;
        this.exec();
      });
  };

  return Queue;
}
