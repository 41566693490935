/** @module directives/teams */
import template from 'ngapp/templates/views/teams/_members_add_to_channels_modal.html';

/**
 * @class TeamMemberAddToChannelsModal
 *
 */
function Controller($scope) {
  $scope.team = $scope.$props.team;
  $scope.members = $scope.$props.members;
  $scope.teamGroups = $scope.$props.teamGroups;
  $scope.closeText = $scope.$props.closeText;
  $scope.doneText = $scope.$props.doneText;
  $scope.onCancel = onCancel;
  $scope.onDone = onDone;

  function onCancel({ type }) {
    $scope.$props.modal.dismiss({ reason: type === 'cancel' ? 'back' : 'dismiss' });
  }

  function onDone(results) {
    $scope.$props.modal.close(results);
  }
}

Controller.$inject = [ '$scope' ];

export default {
  bindings: {
    modal: '<',
    members: '<',
    teamGroups: '<',
    doneText: '<',
    closeText: '<',
    team: '<',
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
