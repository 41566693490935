/**
 * @module Angular.Core.Services
 *
 */
import ACLFactory from './$acl';
import BulkMemberImportFactory from './$bulk_member_import_job';
import CacheProvider from './$cache';
import ClipboardFactory from './$clipboard';
import HttpFactory from './$http';
import $ResourceFactory from './$resource';
import SerializerFactory from './$serializer';
import UrlFactory from './$url';
import CollectionFactory from './activity/$Collection';
import IncomingInvitationCollectionFactory from './activity/$IncomingInvitationCollection';
import OutgoingInvitationCollectionFactory from './activity/$OutgoingInvitationCollection';
import OutgoingInvitationPagerFactory from './activity/$OutgoingInvitationPager';
import NotificationCollectionFactory from './activity/$NotificationCollection';
import ActivityCollectionFactory from './activity_collection';
import AvatarCropFactory from './avatar_crop';
import AvatarUploaderFactory from './avatar_uploader';
import BulkTeamGroupAddAllFactory from './bulk_team_group_add_all';
import BulkTeamInviteFactory from './bulk_team_invite';
import BulkInvitationAccept from './bulk_invitation_accept';
import InvitationCachePolicyFactory from './cache/$invitation_policy';
import TeamGroupCachePolicyFactory from './cache/$team_group_policy';
import TeamMemberCachePolicyFactory from './cache/$team_member_policy';
import TeamCachePolicyFactory from './cache/$team_policy';
import UserConnectionsPolicyFactory from './cache/$user_connections_policy';
import DraftProvider from './draft';
import TeamGroupMemberEventsFactory from './events/$team_group_member_events';
import IdentityFactory from './identity';
import IntegrationFactory from './integration';
import InvitationFactory from './invitation';
import MediaFactory from './media';
import AudioMediaFactory from './media/$audio';
import VideoMediaFactory from './media/$video';
import NotificationFactory from './notification';
import PagerFactory from './pager_factory';
import PlanFeatureClientFactory from './plan_feature/$client';
import PlanFeatureSpaceFactory from './plan_feature/$space';
import PlanFeatureTeamFactory from './plan_feature/$team';
import PlanFeaturePostFactory from './plan_feature/$post';
import PlanFeatureUserFactory from './plan_feature/$user';
import PlanFeatureFactory from './plan_feature';
import PostFactory from './post';
import PrivacyPolicyFactory from './privacy_policy';
import FacebookProviderFactory from './providers/$facebook';
import ReplyFactory from './reply';
import ResourceFactory from './resource';
import ResourceUploaderFactory from './resource_uploader';
import JsonSerializerFactory from './serializers/json';
import QsSerializerFactory from './serializers/qs';
import SheetsReaderFactory from './sheets/$reader';
import SheetsFactory from   './sheets/sheets';
import SpaceFactory from './space';
import SpaceMemberFactory from './space_member';
import StoreFactory from './store';
import SubscriptionFactory from './subscriptions';
import TagFactory from './tag';
import TeamFactory from './team';
import TeamCsvImportFactory from './team_csv_import';
import TeamGroupFactory from './team_group';
import TeamGroupMemberFactory from './team_group_member';
import TeamMemberFactory from './team_member';
import TemplateFactory from './template';
import ClientTrackingFactory from './tracking/$client';
import PaywallsTrackingFactory from './tracking/$paywalls';
import PostTrackingFactory from './tracking/$post';
import SpaceTrackingFactory from './tracking/$space';
import TeamTrackingFactory from './tracking/$team';
import TeamGroupTrackingFactory from './tracking/$team_group';
import TrialTrackingFactory from './tracking/$trial';
import UpgradeTrackingFactory from './tracking/$upgrades';
import UserTrackingFactory from './tracking/$user';
import TrackingFactory from './tracking';
import TrialFactory from './trial';
import UpgradeFactory from './upgrade';
import UploaderUploadFactory from './uploader/$upload';
import UploaderFactory from './uploader/uploader';
import UserFactory from './user';
import UserConnectionsFactory from './user_connections';
import PostViewsFactory from './tracking/$post_views';

export default function(__angular) {
  __angular.module('core.services')
    .factory('core.services.$acl', ACLFactory)
    .factory('core.services.$bulkJobPoller', BulkMemberImportFactory)
    .provider('core.services.$cache', CacheProvider)
    .factory('core.services.$clipboard', ClipboardFactory)
    .factory('core.services.$http', HttpFactory)
    .factory('core.services.$resource', $ResourceFactory)
    .factory('core.services.$serializer', SerializerFactory)
    .factory('core.services.$url', UrlFactory)
    .factory('core.services.activity.$Collection', CollectionFactory)
    .factory('core.services.activity.$IncomingInvitationCollection', IncomingInvitationCollectionFactory)
    .factory('core.services.activity.$OutgoingInvitationCollection', OutgoingInvitationCollectionFactory)
    .factory('outgoingInvitationPagerFactory', OutgoingInvitationPagerFactory)
    .factory('core.services.activity.$NotificationCollection', NotificationCollectionFactory)
    .factory('core.services.activityCollection', ActivityCollectionFactory)
    .factory('core.services.uploader.$upload', UploaderUploadFactory)
    .factory('core.services.uploader', UploaderFactory)
    .factory('core.services.avatarCrop', AvatarCropFactory)
    .factory('core.services.avatarUploader', AvatarUploaderFactory)
    .factory('core.services.bulkTeamGroupAddAll', BulkTeamGroupAddAllFactory)
    .factory('core.services.bulkTeamInvite', BulkTeamInviteFactory)
    .factory('core.services.bulkInvitationAccept', BulkInvitationAccept)
    .factory('core.services.cache.$invitationCachePolicyFactory', InvitationCachePolicyFactory)
    .factory('core.services.cache.$teamGroupCachePolicyFactory', TeamGroupCachePolicyFactory)
    .factory('core.services.cache.$teamMemberCachePolicyFactory', TeamMemberCachePolicyFactory)
    .factory('core.services.cache.$teamCachePolicyFactory', TeamCachePolicyFactory)
    .factory('core.services.cache.$userConnectionsPolicyFactory', UserConnectionsPolicyFactory)
    .provider('core.services.draft', DraftProvider)
    .factory('core.services.events.$teamGroupMemberEvents', TeamGroupMemberEventsFactory)
    .factory('core.services.identity', IdentityFactory)
    .factory('core.services.Integration', IntegrationFactory)
    .factory('core.services.Invitation', InvitationFactory)
    .factory('core.services.media', MediaFactory)
    .factory('core.services.$audio', AudioMediaFactory)
    .factory('core.services.$video', VideoMediaFactory)
    .factory('core.services.Notification', NotificationFactory)
    .factory('core.services.pagerFactory', PagerFactory)
    .factory('core.services.planFeature.$client', PlanFeatureClientFactory)
    .factory('core.services.planFeature.$space', PlanFeatureSpaceFactory)
    .factory('core.services.planFeature.$team', PlanFeatureTeamFactory)
    .factory('core.services.planFeature.$post', PlanFeaturePostFactory)
    .factory('core.services.planFeature.$user', PlanFeatureUserFactory)
    .factory('core.services.planFeature', PlanFeatureFactory)
    .factory('core.services.Post', PostFactory)
    .factory('core.services.PrivacyPolicy', PrivacyPolicyFactory)
    .factory('core.services.providers.$facebook', FacebookProviderFactory)
    .factory('core.services.Reply', ReplyFactory)
    .factory('core.services.Resource', ResourceFactory)
    .factory('core.services.resourceUploader', ResourceUploaderFactory)
    .factory('core.services.$serializer.json', JsonSerializerFactory)
    .factory('core.services.$serializer.qs', QsSerializerFactory)
    .factory('core.services.sheets.$reader', SheetsReaderFactory)
    .factory('core.services.sheets', SheetsFactory)
    .factory('core.services.Space', SpaceFactory)
    .factory('core.services.SpaceMember', SpaceMemberFactory)
    .factory('core.services.$store', StoreFactory)
    .factory('core.services.Subscriptions', SubscriptionFactory)
    .factory('core.services.Tag', TagFactory)
    .factory('core.services.Team', TeamFactory)
    .factory('core.services.teamCSVImport', TeamCsvImportFactory)
    .factory('core.services.TeamGroup', TeamGroupFactory)
    .factory('core.services.TeamGroupMember', TeamGroupMemberFactory)
    .factory('core.services.TeamMember', TeamMemberFactory)
    .factory('core.services.template', TemplateFactory)
    .factory('core.services.tracking.$client', ClientTrackingFactory)
    .factory('core.services.tracking.$paywalls', PaywallsTrackingFactory)
    .factory('core.services.tracking.$post', PostTrackingFactory)
    .factory('core.services.tracking.$space', SpaceTrackingFactory)
    .factory('core.services.tracking.$team', TeamTrackingFactory)
    .factory('core.services.tracking.$teamGroup', TeamGroupTrackingFactory)
    .factory('core.services.tracking.$trial', TrialTrackingFactory)
    .factory('core.services.tracking.$upgrades', UpgradeTrackingFactory)
    .factory('core.services.tracking.$user', UserTrackingFactory)
    .factory('core.services.tracking.$postViews', PostViewsFactory)
    .factory('core.services.tracking', TrackingFactory)
    .factory('core.services.Trial', TrialFactory)
    .factory('core.services.Upgrade', UpgradeFactory)
    .factory('core.services.User', UserFactory)
    .factory('core.services.UserConnections', UserConnectionsFactory);
}
