/**
 * @module Controllers
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/account/_connections.html';

/**
 * @class AccountConnectionsController
 *
 */
function AccountConnectionsController($scope, $translate, Integration, toast) {
  $scope.pending = true;
  $scope.connections = {
    facebook: { connected: false, disabled: false, name: 'facebook' }
  };

  function handleIntegrations(integrations) {
    run(() => {
      integrations.forEach(integration => {
        if ($scope.connections[integration.name] != null) {
          $scope.connections[integration.name].connected = true;
        }
      });
    });
    return integrations;
  }

  Integration.all()
    .then(handleIntegrations)
    .finally(() => run(() => $scope.pending = false));

  $scope.onClick = function(connection) {
    if (connection.connected) {
      disconnect(connection.name);
    } else {
      connect(connection.name);
    }
  };

  $scope.getConnectProviderClassNames = function(connection) {
    return {
      'connection-provider-facebook': connection.name === 'facebook'
    };
  };

  $scope.formatConnectionStatus = function(connection) {
    if (connection.connected) {
      return $translate.instant('CONNECTIONS.CONNECTED_STATUS');
    }
    return $translate.instant('CONNECTIONS.NOT_CONNECTED_STATUS');
  };


  function request(name, fn) {
    run(() => $scope.connections[name].disabled = true);
    return fn().finally(() => run(() => $scope.connections[name].disabled = false));
  }

  function connect(name) {
    return request(name, () => {
      return Integration.connect(name)
        .then(handleIntegrations)
        .catch(error => toast.danger(formatConnectionError(name, error.reason)));
    });
  }

  function disconnect(name) {
    return request(name, () => {
      return Integration.disconnect(name)
        .then(() => run(() => $scope.connections[name].connected = false))
        .catch(() => toast.danger(formatDisconnectionError(name)));
    });
  }

  function formatConnectionError(provider, error) {
    switch (provider) {
      case 'facebook':
        return (error === 'account_exists')
          ? $translate.instant('CONNECTIONS.FACEBOOK_ACCOUNT_EXISTS')
          : $translate.instant('CONNECTIONS.FACEBOOK_CONNECT_ERROR');
    }
  }

  function formatDisconnectionError(provider) {
    switch (provider) {
      case 'facebook':
        return $translate.instant('CONNECTIONS.FACEBOOK_DISCONNECT_ERROR');
    }
  }
}

AccountConnectionsController.$inject = [
  '$scope',
  '$translate',
  'core.services.Integration',
  'core.ui.toast'
];

export default {
  controller: AccountConnectionsController,
  template
};
