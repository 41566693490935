/**
 * @module Directives.Paywalls
 *
 */
import template from 'ngapp/templates/views/paywalls/_modal_header.html';

/**
 * @class ModalHeader
 *
 */
function Controller($scope, $translate) {
  $scope.title = $scope.$props.title || $translate.instant('PAYWALLS.HEADER.DEFAULT.TITLE');
}

Controller.$inject = ['$scope', '$translate'];

export default {
  bindings: {
    title: '@'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
