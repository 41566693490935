/**
 * @module Directives
 *
 */
import { digest } from 'app/utils/runloop';
import template from 'ngapp/templates/directives/_avatar_upload_button.html';

/**
 * @class AvatarUploadButton
 *
 */
function Controller($scope, $element, uid, upload) {
  $scope.id = uid.short();
  $scope.pending = false;
  $scope.disabled = false;
  $scope.$props.$onDestroy = $onDestroy;
  $scope.$props.$onChanges = $onChanges;

  var $file = $element.find('[type="file"]');
  $file.on('change', onAttachment);

  $element.on('touchend', () => {
    $file.click();
  });

  function onAttachment(e) {
    if (e.target.files.length > 0) {
      $scope.pending = true;
      upload(e.target.files[0], $scope.$props.type)
        .then(result =>
          $scope.$props.onUpload({ $avatar: {
            preview: result.preview,
            id: result.object.id,
            url: result.url
          }})
        )
        .finally(() => digest(() => $scope.pending = false));
    }
  }

  function $onChanges(props) {
    if (props.disabled != null) {
      $scope.disabled = props.disabled.currentValue;
    }

    if (props.id != null) {
      $scope.id = props.id.currentValue;
    }
  }

  function $onDestroy() {
    $file.off('change', onAttachment);
  }
}

Controller.$inject = [
  '$scope',
  '$element',
  'core.uid',
  'core.services.avatarUploader'
];

export default {
  bindings: {
    id: '@',
    type: '@',
    disabled: '<',
    onUpload: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props',
  transclude: true
};

