
import template from 'ngapp/templates/views/teams/_report_post_modal.html';

const POST_TRANSLATIONS = {
  title: 'TEAMS.REPORT_POST_MODAL.TITLE',
  body: 'TEAMS.REPORT_POST_MODAL.BODY',
  placeholder: 'TEAMS.REPORT_POST_MODAL.MESSAGE_PLACEHOLDER',
  cancel: 'TEAMS.REPORT_POST_MODAL.CANCEL_BUTTON',
  submit: 'TEAMS.REPORT_POST_MODAL.SUBMIT_BUTTON'
};

const REPLY_TRANSLATIONS = {
  title: 'TEAMS.REPORT_REPLY_MODAL.TITLE',
  body: 'TEAMS.REPORT_REPLY_MODAL.BODY',
  placeholder: 'TEAMS.REPORT_REPLY_MODAL.MESSAGE_PLACEHOLDER',
  cancel: 'TEAMS.REPORT_REPLY_MODAL.CANCEL_BUTTON',
  submit: 'TEAMS.REPORT_REPLY_MODAL.SUBMIT_BUTTON'
};

function Controller($scope) {
  $scope.post = $scope.$props.post;
  $scope.fields = { message: '' };
  $scope.translations = getTranslations($scope.post);
  $scope.onCancel = onCancel;
  $scope.onSubmit = onSubmit;

  function getTranslations(post) {
    if (post.isReply) {
      return REPLY_TRANSLATIONS;
    }

    return POST_TRANSLATIONS;
  }

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onSubmit(e) {
    e.preventDefault();
    $scope.$props.modal.close({ message: $scope.fields.message, post: $scope.$props.post });
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    modal: '<',
    post: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
