/**
 * @module Directives.TeamsGroups
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/teams_groups/_new_group_onboarding_modal.html';

/**
 * @class NewGroupOnboardingModal
 *
 */
function Controller($scope, tracking) {
  $scope.pending = false;
  $scope.group = $scope.$props.group;
  $scope.fields = {
    groupId: $scope.$props.group.id,
    selectedMemberIds: []
  };
  $scope.onSubmit = onSubmit;
  $scope.onSkipClick = onSkipClick;
  $scope.onMembersSelect = onMembersSelect;
  $scope.onInviteNewMembers = onInviteNewMembers;

  function onSkipClick() {
    $scope.$props.modal.close({
      requestMembers: false,
    });
  }

  function onSubmit(e) {
    e.preventDefault();
    run(() => {
      $scope.pending = true;
      $scope.$props.group.addMembersByIds($scope.fields.selectedMemberIds).then(members => {
        $scope.pending = false;
        tracking.teamGroups.membersAdded($scope.group, $scope.group.team, members.length);
        $scope.$props.modal.close({ requestMembers: false, groupMembers: members });
      });
    });
  }

  function onInviteNewMembers() {
    $scope.$props.modal.close({
      requestMembers: true,
      groupMembers: []
    });
  }

  function onMembersSelect(members) {
    run(() => {
      $scope.fields.selectedMemberIds = members != null && members.length
        ? members.map(item => item.id)
        : [];
    });
  }
}

Controller.$inject = ['$scope', 'core.services.tracking'];

export default {
  bindings: {
    modal: '<',
    group: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
