/**
 * @module Core.UI.Modal
 *
 */
import HashMap from 'app/utils/HashMap';

/**
 * @class $stack
 *
 */
function factory($q, $dom) {
  const modalMap = new HashMap();

  function top() {
    return modalMap.last() || null;
  }

  function push(id, modal) {
    modalMap.set(id, modal);
    return $dom.insert(modal.element).then(() => modal);
  }

  function remove(id) {
    if (modalMap.has(id)) {
      const modal = modalMap.get(id);
      modalMap.delete(id);
      return $dom.remove(modal.element).then(() => modal);
    }
    return $q.resolve(null);
  }

  $dom.clickOff(() => {
    const el = top();
    return el && el.element ? el.element : null;
  });

  return {
    push: push,
    remove: remove,
    get top() {
      return top();
    }
  };
}

factory.$inject = [
  '$q',
  'core.ui.modal.$dom',
];

export default factory;

