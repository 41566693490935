
function Transcope() {
  return {
    link: function($scope, $element, $attrs, controller, $transclude) {
      if (!$transclude) {
        throw new Error("Parent element is does not implement ngTransclude");
      }

      var innerScope = $scope.$new();

      $transclude(innerScope, function(clone) {
        $element.empty();
        $element.append(clone);
        $element.on('$destroy', function() {
          innerScope.$destroy();
        });
      });
    }
  };
}

export default Transcope;
