/**
 * legacy appp vendor includes
 *
 */
import 'jquery-ui';

import moment from 'moment';
if (!window.moment) {
  window.moment = moment;
}

import 'bootstrap/js/tooltip';
import 'bootstrap/js/popover';
import 'jquery.autolinker';
import 'urijs';
import 'angular';
import 'ngreact';
import 'angular-route';
import 'angular-animate';
import 'angular-sanitize';
import 'angular-resource';
import 'angularjs-rails-resource';
import 'angular-touch';
import 'angular-ui-sortable';
import 'angular-strap';
import 'angular-elastic';
import 'angular-translate';
import 'angular-translate-interpolation-messageformat';
import 'ng-infinite-scroll';
import 'ui-select';
import 'ui-select-infinity';
import 'media-element';
import 'media-element-player';
import 'localtime';
