/**
 * @module Directives
 *
 */
import template from 'ngapp/templates/directives/_selected_user.html';

/**
 * @class SelectedUser
 *
 */
export default function SelectedUser() {
  return {
    restrict: 'E',
    template,
    replace: true,
    scope: {
      'removeUser': '&onRemove',
      user: '='
    }
  };
}
