/**
 * @module Core.Vendor
 *
 */
import WaveSurferModule from 'wavesurfer';

/**
 * @class WaveSurfer
 *
 */
function factory() {
  return WaveSurferModule;
}

factory.$inject = [];

export default factory;
