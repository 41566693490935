
import assign from 'lodash/assign';

function factory($q, $uid, $compile, $stack, $events) {
  $events.onKeyboardDismiss.attach(function() {
    const modal = $stack.top;
    if (modal != null && modal.isOpen) {
      if (modal.options.keyboard) {
        dismiss(modal.id).then(() =>
          modal.result.reject({ reason: 'dismiss', code: 'keyboard_dismiss' })
        );
      }
    }
  });

  $events.onNavigationDismiss.attach((e) => {
    const modal = $stack.top;
    if (modal != null && modal.isOpen) {
      e.data.stopNavigation();
      dismiss(modal.id).then(() =>
        modal.result.reject({ reason: 'dismiss', code: 'navigation_dismiss' })
      );
    }
  });

  $events.onBackdropDismiss.attach(() => {
    const modal = $stack.top;
    if (modal != null && modal.isOpen) {
      if (!modal.options.static) {
        dismiss(modal.id).then(() =>
          modal.result.reject({ reason: 'dismiss', code: 'backdrop_dismiss' })
        );
      }
    }
  });

  function dismiss(id) {
    return $stack.remove(id).then(modal =>
      modal != null && modal.scope.$destroy()
    );
  }

  function compile(options, instance) {
    var locals = assign({}, options.resolve, { $modalInstance: instance });

    if (options.template != null) {
      return $compile.fromTemplate(options.template, options.controller, options.scope, locals);
    } else if (options.templateUrl != null) {
      return $compile.fromTemplateUrl(options.templateUrl, options.controller, options.scope, locals);
    }

    return $q.reject(new Error('No template provided.'));
  }

  function open(options) {
    if (options.template == null && options.templateUrl == null) {
      throw new Error('options.template or options.templateUrl must be provided.');
    }

    const id = $uid.short();
    const opened = $q.defer();
    const result = $q.defer();

    const instance = {
      opened: opened.promise,
      result: result.promise,
      close(value) {
        return dismiss(id).then(() => result.resolve(value));
      },
      dismiss(reason) {
        return dismiss(id).then(() => result.reject(reason));
      }
    };

    compile(options, instance)
      .then(component => $stack.push(id, { ...component, id, instance, options, result, isOpen: false }))
      .then(modal => {
        modal.element.on('click', '[dismiss="modal"]', () =>
          instance.dismiss({ reason: 'dismiss', code: 'attribute_dismiss' })
        );

        modal.scope.$on('$destroy', () =>
          instance.dismiss({ reason: 'dismiss', code: 'scope_destroy' })
        );

        modal.isOpen = true;

        return opened.resolve(modal);
      })
      .catch(e => result.reject(e));

    return instance;
  }

  return { open: open };
}

factory.$inject = [
  '$q',
  'core.uid',
  'core.ui.modal.$compile',
  'core.ui.modal.$stack',
  'core.ui.modal.$events'
];

export default factory;

