
function ClipboardFactory(Clipboard, $q) {
  function copy(text, event) {
    var cb = new Clipboard('.null', {
      text: function() {
        return text;
      }
    });

    var copied = $q(function(resolve, reject) {
      cb.on('success', function(e) {
        resolve(e.text);
      });

      cb.on('error', function(e) {
        reject(e);
      });

      cb.onClick(event);
    });

    copied.finally(function() {
      cb.destroy();
    });

    return copied;
  }

  return {
    copy: copy
  };
}

ClipboardFactory.$inject = ['clipboardJS', '$q'];

export default ClipboardFactory;
