/**
 * @module Directives.Invitations
 *
 */
import template from 'ngapp/templates/views/invitations/_phone_invitation_copy_link_modal.html';

/**
 * @class PhoneInviteCopyLinkModal
 *
 */
function Controller($scope, $q, $translate, $clipboard, toast) {
  $scope.pending = false;
  $scope.inviteLink = $scope.$props.inviteLink;
  $scope.onDone = onDone;
  $scope.onCopyClick = onCopyClick;

  function onDone() {
    $scope.$props.modal.dismiss();
  }

  function onCopyClick(e) {
    $q.resolve($clipboard.copy($scope.inviteLink, e))
      .then(() => toast.success($translate.instant('INVITATION_MODALS.COPY_LINK_MODAL.COPY_SUCCESS')))
      .catch(() => toast.danger($translate.instant('INVITATION_MODALS.COPY_LINK_MODAL.COPY_ERROR')));
  }
}

Controller.$inject = [
  '$scope',
  '$q',
  '$translate',
  'core.services.$clipboard',
  'core.ui.toast'
];

export default {
  bindings: {
    modal: '<',
    inviteLink: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
