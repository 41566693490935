/**
 * @module Directives.Library
 *
 */
import template from 'ngapp/templates/directives/media/_media_button.html';

/**
 * @class MediaButton
 *
 */
function MediaButtonController($scope, $modal) {
  $scope.onClick = onClick;

  function onClick() {
    $modal.open({
      template: '<media-modal modal="modal" accept="accept" on-select="onSelect(selection)" on-cancel="model.close()"></media-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
        $local.onSelect = onSelect;
        $local.accept = $scope.$props.accept;
      }]
    });
  }

  function onSelect(selection) {
    $scope.$props.onSelect({ selection: selection });
  }
}

MediaButtonController.$inject = ['$scope', '$modal'];

export default {
  bindings: {
    accept: '@',
    onSelect: '&'
  },
  template,
  controllerAs: '$props',
  controller: MediaButtonController
};
