
function Signal(target) {
  this._target = target;
  this._subscribers = [];
}

/**
  * Attaches a listener to signal.
  *
  * @param {function} listener
  * @returns {function} detach function
  */
Signal.prototype.attach = function attach(listener) {
  var signal = this;

  if (this._subscribers.indexOf(listener) === -1) {
    this._subscribers.push(listener);
  }

  return function detach() {
    signal.detach(listener);
  };
};

/**
  * Removes all subscribers.
  */
Signal.prototype.clear = function clear() {
  this._subscribers = [];
};

/**
  * Detaches listener from signal.
  *
  * @param {function} listener
  * @returns {this}
  */
Signal.prototype.detach = function detach(listener) {
  var index = this._subscribers.indexOf(listener);

  if (index > -1) {
    this._subscribers.splice(index, 1);
  }

  return this;
};

/**
  * Invokes this signal with a sender and event object.
  *
  * @param {*} sender
  * @param {*} object
  * @returns {this}
  */
Signal.prototype.dispatch = function dispatch(sender, object) {
  var event = {
    target: this._target,
    sender: sender,
    time: Date.now(),
    data: object
  };

  for (var i = 0; i < this._subscribers.length; i++) {
    this._subscribers[i](event);
  }

  return this;
};

export default function factory() {
  return Signal;
}
