/**
 * @module Controllers
 *
 */
import billingInfoTemplate from 'ngapp/templates/views/account/_billing_info.html';
import billingInfoEditTemplate from 'ngapp/templates/views/account/_billing_info_edit.html';

/**
 * @class AccountBillingInfoController
 *
 */
function Controller() {}

export const accountBillingInfo = {
  controller: Controller,
  template: billingInfoTemplate
};

export const accountBillingInfoEdit = {
  controller: Controller,
  template: billingInfoEditTemplate
};
