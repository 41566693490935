/**
 * @module Core.UI.Modal
 *
 */
import assign from 'lodash/assign';

/**
 * @class Modal
 *
 */
export default function provider() {
  const DEFAULT_OPTIONS = {
    keyboard: true,
    static: true,
    resolve: {}
  };

  function $get($manager) {
    function open(options) {
      return $manager.open(assign({}, DEFAULT_OPTIONS, options));
    }
    return { open: open };
  }

  $get.$inject = ['core.ui.modal.$manager'];

  return {
    $get: $get,
    options: DEFAULT_OPTIONS
  };
}
