
import template from 'ngapp/templates/views/feed_filters/_picker.html';

const NOW = new Date();

function Controller($scope, $translate) {
  var MONTH_OPTIONS = [
    { value: 0, label: $translate.instant('MONTHS.SHORT.JAN') },
    { value: 1, label: $translate.instant('MONTHS.SHORT.FEB') },
    { value: 2, label: $translate.instant('MONTHS.SHORT.MAR') },
    { value: 3, label: $translate.instant('MONTHS.SHORT.APR') },
    { value: 4, label: $translate.instant('MONTHS.SHORT.MAY') },
    { value: 5, label: $translate.instant('MONTHS.SHORT.JUN') },
    { value: 6, label: $translate.instant('MONTHS.SHORT.JUL') },
    { value: 7, label: $translate.instant('MONTHS.SHORT.AUG') },
    { value: 8, label: $translate.instant('MONTHS.SHORT.SEPT') },
    { value: 9, label: $translate.instant('MONTHS.SHORT.OCT') },
    { value: 10, label: $translate.instant('MONTHS.SHORT.NOV') },
    { value: 11, label: $translate.instant('MONTHS.SHORT.DEC') }
  ];

  $scope.model = MONTH_OPTIONS[NOW.getMonth()];
  $scope.options = MONTH_OPTIONS;
  $scope.onChange = onChange;
  $scope.$props.$onChanges = $onChanges;

  function onChange() {
    $scope.$props.onChange({ value: $scope.model.value });
  }

  function $onChanges(props) {
    if (props.year != null) {
      if (props.year.currentValue === NOW.getFullYear()) {
        $scope.options = MONTH_OPTIONS.slice(0, NOW.getMonth() + 1);
      } else {
        $scope.options = MONTH_OPTIONS;
      }

      if ($scope.model.value > $scope.options.length - 1) {
        $scope.model = MONTH_OPTIONS[0];
      }
    }

    if (props.value != null) {
      $scope.model = MONTH_OPTIONS[props.value.currentValue];
    }
  }
}

Controller.$inject = ['$scope', '$translate'];

export default {
  bindings: {
    year: '<?',
    value: '<?',
    onChange: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
