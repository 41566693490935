
import template from 'ngapp/templates/views/teams/_team_feed_reply.html';

function Controller($scope, Post, format, tracking, $element) {
  $scope.optionType = 'reply';
  $scope.editing = false;
  $scope.reply = $scope.$props.reply;
  $scope.formatMemberSummary = format.memberSummary;
  $scope.isDemo = $scope.$props.isDemo;
  $scope.onEdit = onEdit;
  $scope.onEditCancel = onEditCancel;
  $scope.onLike = onLike;
  $scope.onRemove = onRemove;
  $scope.onUpdate = onUpdate;
  $scope.onReport = onReport;
  tracking.postViews.createObserver($element.get(0).children[0], $scope.$props.reply, true);
  $scope.postIsReply = true;
  $scope.canCopy = $scope.reply.canCopy;

  function onEdit() {
    $scope.editing = true;
  }

  function onEditCancel() {
    $scope.editing = false;
  }

  function onLike(post) {
    $scope.$props.onLike({ post: post });
  }

  function onRemove() {
    $scope.$props.onRemove({ reply: $scope.$props.reply });
  }

  function onReport(e) {
    $scope.$props.onReport({ event: e });
  }

  function onUpdate(fields) {
    return Post.update($scope.reply, { details: fields.body, tags: fields.tags })
      .then(function(post) {
        $scope.editing = false;
        $scope.$props.onEdit({ post: post });
      });
  }
}

Controller.$inject = ['$scope','core.services.Post', 'format', 'core.services.tracking', '$element'];

export default {
  bindings: {
    reply: '<',
    isDemo: '<',
    onEdit: '&',
    onLike: '&',
    onRemove: '&',
    onReport: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
