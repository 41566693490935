import template from "ngapp/templates/views/teams/_team_feed_post.html";
import moment from "moment";

function Controller(
  $element,
  $scope,
  $filter,
  $location,
  Post,
  format,
  tracking
) {
  $scope.editing = false;
  $scope.post = $scope.$props.post;
  $scope.user = $scope.$props.user;
  $scope.pinnedTillFormatted = null;
  $scope.formatMemberSummary = format.memberSummary;
  $scope.onEdit = onEdit;
  $scope.onEditCancel = onEditCancel;
  $scope.onLike = onLike;
  $scope.onRemove = onRemove;
  $scope.onReport = onReport;
  $scope.onUpdate = onUpdate;
  $scope.onTogglePin = onTogglePin;
  $scope.postViews = $scope.post.postViewsCount;
  $scope.postViewers = $scope.post.postViews;
  $scope.canSeePostData = canSeePostData;
  tracking.postViews.createObserver($element, $scope.post);
  $scope.isTemplatePage = false;

  if ($scope.post.pinnedTill) {
    this.pinnedTillYear = moment($scope.post.pinnedTill).year();
    this.currentYear = moment().year();

    if (this.pinnedTillYear > this.currentYear) {
      $scope.pinnedTillFormatted = $filter("date")(
        $scope.post.pinnedTill,
        "mediumDate"
      );
      // $scope.pinnedTillFormatted = moment($scope.post.pinnedTill).format(
      //   "MMM D, YYYY"
      // );
    } else {
      // $scope.pinnedTillFormatted = $filter('date')($scope.post.pinnedTill, 'MMM d');
      $scope.pinnedTillFormatted = moment($scope.post.pinnedTill).format(
        "MMM D"
      );
    }
  }

  if (location.pathname.includes("space_templates")) {
    $scope.isTemplatePage = true;
  } else {
    $scope.isTemplatePage = false;
  }

  function onEdit() {
    $scope.editing = true;
  }

  function onEditCancel() {
    $scope.editing = false;
  }

  function onLike(post) {
    $scope.$props.onLike({
      post: post,
    });
  }

  function onReport(e) {
    $scope.$props.onReport({
      event: e,
    });
  }

  function onTogglePin() {
    $scope.$props.onTogglePin();
  }

  function onRemove() {
    $scope.$props.onRemove();
  }

  function onUpdate(fields) {
    return Post.update($scope.post, {
      details: fields.body,
      tags: fields.tags,
      scheduledAt: fields.scheduledAt,
    }).then(function (post) {
      $scope.editing = false;
      $scope.$props.onEdit({
        post: post,
      });
    });
  }

  function canSeePostData() {
    if ($scope.post.permissions) {
      return (
        $scope.post.permissions.includes("access_views") &&
        $scope.post.permissions.includes("access_likes")
      );
    }

    return false;
  }
}

Controller.$inject = [
  "$element",
  "$scope",
  "$location",
  "$filter",
  "core.services.Post",
  "format",
  "core.services.tracking",
];

export default {
  bindings: {
    post: "<",
    user: "<",
    onEdit: "&",
    onLike: "&",
    onRemove: "&",
    onReport: "&",
    onTogglePin: "&",
  },
  template,
  controller: Controller,
  controllerAs: "$props",
};
