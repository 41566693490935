/**
 * @module Core.Services
 *
 */

/**
 * @class Identity
 *
 */
function factory($q, $root, $property, uid, User) {
  var identity = {};

  /**
    * The current identity id.
    *
    * @type {string}
    */
  $property.get(identity, 'id', function() {
    if ($root.user) {
      return $root.user.id;
    }
    return '00000000000000000000000000000000';
  });

  /**
    * The digested identity id.
    *
    * @type {string}
    */
  $property.get(identity, '$id', function() {
    return uid.digest(this.id);
  });

  /**
    * Return pending user request.
    *
    * @type {object}
    */
  identity.request = function request() {
    if($root.user == null) {
      return identity.$refresh();
    }
    return $q.resolve($root.user);
  };

  identity.setUser = function(user) {
    $root.user = user;
    return $root.user;
  };

  identity.$refresh = function $refresh() {
    return User.me().then(me =>
      identity.setUser(me)
    );
  };

  $root.$on('userUpdated', (event, user) => {
    identity.setUser(user);
  });

  return identity;
}

factory.$inject = [
  '$q',
  '$rootScope',
  'core.$property',
  'core.uid',
  'core.services.User'
];

export default factory;
