/**
 * @module Directives.Teams
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/teams/_csv_select_file_modal.html';

/**
 * @class TeamCsvSelectFileModal
 *
 */
function Controller($scope, $element, features, sheets) {
  $scope.draggable = features.dragAndDropFiles;
  $scope.dragging = false;
  $scope.error = null;
  $scope.onCancel = onCancel;
  $scope.$props.$onDestroy = $onDestroy;

  const $dropzone = $element.find('.upload-dropzone');
  const $file = $dropzone.find('[type=file]');

  $file.on('change', e => onFiles(e.target.files));

  $dropzone
    .on('dragenter', e => onDragEnter(e))
    .on('drop dragleave', e => onDragLeave(e))
    .on('drop', e => onFiles(e.originalEvent.dataTransfer.files))
    .on('dragenter dragover dragleave drop', e => {
      e.preventDefault();
      e.stopPropagation();
    });

  function onFiles(files) {
    $scope.error = null;
    sheets.read(files[0])
      .then((wb) => run(() => {
        if (wb.length === 0) {
          $scope.error = 'empty';
        } else {
          $scope.$props.modal.close({ workbook: wb });
        }
      }))
      .catch(() => run(() => $scope.error = 'invalid_type'));
  }

  function onDragEnter() {
    run(() => $scope.dragging = true);
  }

  function onDragLeave(e) {
    if (!$dropzone.is(e.relatedTarget) && $dropzone.has(e.relatedTarget).length === 0) {
      run(() => $scope.dragging = false);
    }
  }

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function $onDestroy() {
    $file.off('change');
    $dropzone.off('dragenter dragover  dragleave drop');
  }
}

Controller.$inject = ['$scope', '$element', 'core.features', 'core.services.sheets'];

export default {
  bindings: {
    modal: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
