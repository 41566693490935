/**
 * @module Core.UI.Toast
 *
 */

/**
 * @class Toast
 *
 */
export default function provider() {
  const options = { timeout: 4000, parent: 'body' };

  function $get($injector, $template, $compile, $root, $document, $controller) {
    const $scope = $root.$new();
    const controller = $injector.invoke($controller, null, { $scope: $scope, options: options });
    const container = $document.find(options.parent).first();

    $template('core.ui.toast')
      .then($compile)
      .then($link => container.append($link($scope)));

    function toast(alert, level) {
      controller.push({
        alert: alert,
        level: level
      });
    }

    toast.danger = function danger(alert) {
      toast(alert, 'danger');
    };

    toast.info = function info(alert) {
      toast(alert, 'info');
    };

    toast.success = function success(alert) {
      toast(alert, 'success');
    };

    return toast;
  }

  $get.$inject = [
    '$injector',
    '$templateRequest',
    '$compile',
    '$rootScope',
    '$document',
    'core.ui.toast.$controller'
  ];

  return {
    $get: $get,
    options: options
  };
}

