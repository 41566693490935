/**
 * @module Controllers.Spaces
 *
 */
import each from 'lodash/each';
import { run } from 'app/utils/runloop';
import { uuid } from 'app/utils/uuid';
import { spaceCreated } from 'app/utils/analytics-helpers';
import { AnalyticsService } from 'app/services';
import { findJack } from 'app/utils/appcues';
import templateNewSingle from 'ngapp/templates/views/spaces/_new_single.html';
import templateNewMultiple from 'ngapp/templates/views/spaces/_new_multiple.html';

/**
 * @class SpaceNew
 *
 */
function Controller(
  $scope,
  $rootScope,
  $interpolate,
  $location,
  $translate,
  SpaceForm,
  SpaceTemplate,
  $routeParams,
  SpaceImport,
  $timeout,
  toast,
  identity,
  Segment,
  teamCSVImportModals
) {
  // NOTE:
  // csv upload access = /spaces/new/multiple/true?adminCSV=true

  spaceCreated('Started', { origin: 'list' });

  const isMultipleSpaceCreation = !!$routeParams.multiple;
  $scope.adminCSV = $routeParams.adminCSV;
  $scope.selectCsv = selectCsv;
  $scope.jacksjourney = $routeParams.jacksjourney != null ? true : false;

  $scope.form = new SpaceForm($scope);
  $scope.form.createFor = (isMultipleSpaceCreation) ? 0 : 1;

  $scope.currentStep = 1;
  $scope.btnsDisabled = false;
  $scope.progress = 0;
  $scope.skipInvites = false;

  // flag set when Jumping Jack is in athletes list
  $scope.isAppcuesDemo = false;

  // flag to switch between email and phone invite
  $scope.isPhoneInvite = false;
  $scope.phoneInvite = {
    name: null,
    phone: null,
    phoneCountry: null,
    phoneNormalized: null,
    error: {}
  };

  $scope.spaceTemplates = [];
  $scope.segments = [];
  $scope.selectValue = null;

  Segment.query().then((segments) => {
    run(() => $scope.segments.push.apply($scope.segments, segments));
  });

  identity.request().then((user) => {
    run(() => {
      $scope.authUser = user;
      setUserSport(user.sport, false);
      setDefaultPhoneCountry(user.phoneCountry);
    });
  });

  SpaceTemplate.query().then(spaceTemplates => {
    run(() => {
      $scope.spaceTemplates = spaceTemplates;
      $scope.templateOptions = spaceTemplates
        .map(item => ({ label: item.name, value: item.id, data: item }))
        .sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0);
    });
  });

  const setDefaultPhoneCountry = (phoneCountry) => {
    if (phoneCountry) {
      $scope.phoneInvite.phoneCountry = phoneCountry.toLowerCase();
    } else {
      $scope.phoneInvite.phoneCountry = 'us';
    }
  };

  const setUserSport = (sport, isCustom) => {
    if (sport && isCustom) {
      $scope.form.sport.value = 'Custom';
      $scope.form.otherSport.value = sport;
    } else {
      $scope.form.sport.value = sport;
      delete $scope.form.otherSport.value;
    }
    $scope.selectValue = sport;
  };

  $scope.onPhoneInputChange = (value, data, event, formattedValue) => {
    $scope.phoneInvite.error.phone = null;

    const phoneNormalized = formattedValue;
    const phone = value.substring(data.dialCode.length);
    const phoneCountry = data.countryCode.toUpperCase();

    $scope.phoneInvite.phone = phone;
    $scope.phoneInvite.phoneCountry = phoneCountry;
    $scope.phoneInvite.phoneNormalized = phoneNormalized;
  };

  $scope.onAddAthleteWithPhone = (event) => {
    event.preventDefault();

    if ($scope.phoneInvite.name == null) {
      $scope.phoneInvite.error.name = true;
      return;
    }

    if ($scope.phoneInvite.phone == null) {
      $scope.phoneInvite.error.phone = true;
      return;
    }

    let value = {
      uuid: uuid(),
      model: {
        name: $scope.phoneInvite.name,
        displayName: $scope.phoneInvite.name,
        phone: $scope.phoneInvite.phone,
        phoneCountry: $scope.phoneInvite.phoneCountry,
        phoneNormalized: $scope.phoneInvite.phoneNormalized
      }
    };

    if (value.model.phone) {
      $scope.form.addAthlete(value.model);
    }
  };

  $scope.toggleIsPhoneInvite = () => {
    $scope.isPhoneInvite = !$scope.isPhoneInvite;
  };

  $scope.onCreateNewCoach = (value) => {
    return $scope.form.onNewUserClick('coaches', value);
  };

  $scope.onCreateNewAthlete = (value) => {
    return $scope.form.onNewUserClick('athletes', value);
  };

  $scope.onCreateNewContributor = (value) => {
    return $scope.form.onNewUserClick('contributors', value);
  };

  const filterAdd = (value) => {
    if (Array.isArray(value)) {
      return value.map(item => item.model != null ? item.model : item);
    } else {
      return value && value.model ? value.model : value;
    }
  };

  $scope.onAddCoach = (value) => {
    const user = filterAdd(value);
    $scope.form.setUserList('coaches', user);
  };

  $scope.onAddAthlete = (value) => {
    const user = filterAdd(value);
    $scope.form.setUserList('athletes', user);
  };

  $scope.onAddContributor = (value) => {
    const user = filterAdd(value);
    $scope.form.setUserList('contributors', user);
  };

  $scope.getAddAthleteMessage = () => {
    return $translate.instant('SPACES.ADD_NEW_ATHLETE').toString();
  };

  $scope.getAddCoachMessage = () => {
    return $translate.instant('SPACES.ADD_NEW_COACH').toString();
  };

  $scope.getAddMemberMessage = () => {
    return $translate.instant('SPACES.ADD_EXTRA_MEMBER').toString();
  };

  $scope.getAddFanMessage = () => {
    return $translate.instant('SPACES.ADD_NEW_FAN').toString();
  };

  $scope.templateOptions = null;
  $scope.selectedTemplate = null;
  $scope.form.coaches = [];
  $scope.onSelectTemplate = (value) => {
    if (value) {
      $scope.selectedTemplate = value;
      $scope.form.spaceTemplate = value.data;
    } else {
      $scope.selectedTemplate = undefined;
      $scope.form.spaceTemplate = undefined;
    }
  };

  if($routeParams.import_contacts === 'true') {
    if($rootScope.transmittedContacts) {
      $scope.form.athletes = $rootScope.transmittedContacts;
    }
  }

  function selectCsv(role) {
    teamCSVImportModals.selectFile().result.then((result) => {
      var data = result.workbook.sheets[0].data;
      if (data.length > 0) {
        populateFromCsv(data, role);
      }
    });
  }

  function populateFromCsv(data, role) {
    each(data, function (row) {
      if (role === 'players') {
        $scope.form.addAthlete({
          avatar: null,
          name: row[0],
          displayName: row[0],
          email: row[1]
        });
      } else {
        $scope.form.addCoach({
          avatar: null,
          name: row[0],
          displayName: row[0],
          email: row[1]
        });
      }
    });
  }

  $scope.nextStep = function() {
    $scope.btnsDisabled = true;
    const valid = $scope.form.validate();

    if (!valid) {
      $scope.btnsDisabled = false;
      return;
    }

    if ($scope.form.contributors.length) {
      spaceCreated('Fans Invited', {
        count: $scope.form.contributors.length
      });
    }

    if ($scope.form.coaches && $scope.form.coaches.length) {
      spaceCreated('Coaches Invited', {
        count: $scope.form.contributors.length
      });
    }

    $scope.currentStep += 1;
    $scope.skipInvites = false;

    $scope.btnsDisabled = false;
  };

  $scope.back = function() {
    $scope.currentStep -= 1;
  };

  $scope.save = function() {
    if (findJack($scope.form.athletes, 'email') != null) {
      $scope.isAppcuesDemo = true;
    }
    return $scope.form.save(athleteSpaceCreatedHandler);
  };

  const athleteSpaceCreatedHandler = function(spaceImport) {
    let members = 1;
    if (!spaceImport.createdForSelf) {
      members = 0 + spaceImport.coaches.length + spaceImport.athletes.length + spaceImport.contributors.length;
    }

    // track space created for jack or space created in general
    const trackMessage = $scope.isAppcuesDemo
      ? 'Space Created for Jumping Jack'
      : 'TrainingSpace Created';

    AnalyticsService.track(trackMessage, {
      hasCoach: spaceImport.coaches.length > 0,
      isAthleteCreator: spaceImport.createForSelf,
      memberCount: members,
      segmentCount: spaceImport.segmentIds.length,
      sport: spaceImport.sport
    }, () => {
      if (spaceImport.createdCount == 1) {
        toast.success($translate.instant('CONTROLLERS.SPACE_NEW.CREATE_SUCCESS_MESSAGE'));
        $location.path('/spaces/' + spaceImport.spaceId).search({ showModal: $scope.phoneInvite.phoneNormalized !== null });
      } else {
        $scope.pooling = true;
        $scope.interval = null;
        const refreshProgress = () => {
          SpaceImport.get(spaceImport.spaceImportId).then(function(spaceData) {
            $scope.progress = parseInt(spaceData.status.progress);
            if (spaceData.status.progress === 100) {
              return $timeout(() => {
                toast.success($interpolate('{{status.success}} TrainingSpaces were successfully created.')(spaceData));
                $location.path('/spaces');
              }, 1000);
            } else {
              $scope.interval = $timeout(refreshProgress, 1000);
            }
          });
        };
        $scope.interval = $timeout(refreshProgress, 1000);
      }
    });
  };

  $scope.singleAthlete = () => {
    return $scope.form.athletes[0] || {};
  };

  $scope.onChange = (value, isCustom) => {
    const label = value && value.label ? value.label : '';
    setUserSport(label, isCustom);
    $scope.inputValue = label;
    if (label) {
      spaceCreated('Sport Picked', { type: label });
    }
  };

  $scope.cancel = ($event) => {
    $event.preventDefault();
    spaceCreated('Cancelled');
    $location.path('/spaces');
  };

  $scope.$watch('form.invalid', (invalid) => {
    if (!invalid) { return; }

    if(isMultipleSpaceCreation) {
      $scope.currentStep = 1;
    } else {
      if($scope.singleAthlete().error || $scope.form.sport.error || $scope.form.otherSport.error) {
        $scope.currentStep = 1;
      } else {
        $scope.currentStep = 2;
      }
    }
  });
}

Controller.$inject = [
  '$scope',
  '$rootScope',
  '$interpolate',
  '$location',
  '$translate',
  'SpaceForm',
  'SpaceTemplate',
  '$routeParams',
  'SpaceImport',
  '$timeout',
  'core.ui.toast',
  'core.services.identity',
  'Segment',
  'teamCSVImportModals'
];

export const spaceNew = {
  controller: Controller,
  template: templateNewSingle
};

export const spaceNewMultiple = {
  controller: Controller,
  template: templateNewMultiple
};
