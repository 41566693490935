/** @module directives/teams */

/**
 * @class TeamEmailInviteFlow
 *
 */
function TeamEmailInviteFlow($translate, TeamMember, modals, teamModal, TeamGroupMember) {
  function flow(team) {
    return transition({ team }, { type: 'EMAIL_FORM' });
  }

  function transition(state, action) {
    switch (action.type) {
      case 'EMAIL_FORM':
        return displayForm(state);
      case 'SELECT_GROUPS':
        return displayGroups(state);
      case 'CONFIRM_INVITES':
        return importConfirm(state);
      case 'PROGRESS':
        return displayProgress(state);
      case 'SUCCESS':
        return displaySuccess(state);

      default:
        return Promise.resolve(state);
    }
  }

  const setState = (state, newState) => {
    return Object.assign({}, state, newState);
  };

  const handleBack = (e, callback) => {
    if (e != null && e.reason === 'back') {
      callback();
    }
    return e;
  };

  function displayForm(state) {
    return modals.emailInviteForm(state.members).result
      .then(form => transition(setState(state, { members: form.members }), { type: 'SELECT_GROUPS' }));
  }

  function displayGroups(state) {
    const closeText = $translate.instant('TEAMS.SELECT_GROUPS.BACK_BUTTON').toString();

    return teamModal.addMembersToChannels(state.team, state.members, state.groups, null, closeText).result
      .then(result => transition(setState(state, { groups: result.teamGroups }), { type: 'CONFIRM_INVITES' }))
      .catch((e) => handleBack(e, () => transition(state, { type: 'EMAIL_FORM' })));
  }

  function importConfirm(state) {
    return teamModal.importConfirm(state.team, state.members, state.groups).result
      .then(() => transition(setState(state, { task: TeamMember.createMany(state.team.id, state.members, state.groups.map(model => model.id)) }), { type: 'PROGRESS' }))
      .catch((e) => handleBack(e, () => transition(state, { type: 'SELECT_GROUPS' })));
  }

  function displayProgress(state) {
    const progressModal = modals.importProgress();

    state.task.result.then(result => {
      progressModal.close(result);
    });

    return progressModal.result
      .then(result => transition(setState(state, { status: result.status }), { type: 'SUCCESS' }));
  }

  function displaySuccess(state) {
    const memberIds = state.members.map(m => m.id || m.email);
    state.groups.forEach((group) => {
      TeamGroupMember.events.onMembersAdded.dispatch(TeamGroupMember, { groupId: group.id, memberIds: memberIds });
    });

    return modals.importSuccess(state.status).result;
  }

  return flow;
}

TeamEmailInviteFlow.$inject = [
  '$translate',
  'core.services.TeamMember',
  'teamMemberImportModals',
  'teamModal',
  'core.services.TeamGroupMember'
];

export default TeamEmailInviteFlow;
