/**
 * @module Directives
 *
 */

/**
 * @class FormErrors
 *
 */
function FormErrors($, $parse) {
  function link($scope, $element, $attributes) {
    $scope.$watch($parse($attributes.formErrors), processErrors);

    function processErrors(errors) {
      clearValidations();

      if (errors != null) {
        onErrors(errors);
      }
    }

    function onErrors(errors) {
      if (errors.base) {
        $('#base_errors').text(errors.base.join()).addClass('error');
      }

      $element.find('.control-group').each(function() {
        var $this = $(this);
        var name = $this.find('input').attr('name');

        if (name != null && errors[name] != null) {
          $this.addClass('error');
          $this.append($('<span class="helper-text error js-form-error"></span>').text(errors[name]));
        }
      });
    }

    function clearValidations() {
      var $controls = $element.find('.control-group');
      $controls.removeClass('error');
      $controls.find('.js-form-error').remove();
    }
  }

  return {
    restrict: 'EAC',
    scope: false,
    link: link
  };
}

FormErrors.$inject = ['$', '$parse'];

export default FormErrors;
