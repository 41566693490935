/**
 * @module Controllers
 *
 */
import { run } from 'app/utils/runloop';
import { spaceEdited } from 'app/utils/analytics-helpers';

/**
 * @class SpaceInvitations
 *
 */
function Controller(
  $,
  $scope,
  identity,
  $translate,
  toast,
  $routeParams,
  SpaceInvitation,
  invitationModal
) {
  $scope.spaceId = $routeParams.spaceId;
  $scope.showForm = false;
  $scope.processing = false;
  $scope.memberRoleOptions = [];
  $scope.selectedRole = null;
  $scope.memberRoleOptions = [
    { value: "coach", label: $translate.instant('COACH').toString() },
    { value: "contributor", label: $translate.instant('CONTRIBUTOR').toString() },
  ];
  $scope.isPhoneInvite = false;
  $scope.defaultPhoneCountry = 'us';

  identity.request().then((user) => {
    run(() => {
      if (user.phoneCountry) {
        $scope.defaultPhoneCountry = user.phoneCountry.toLowerCase();
      }
    });
  });

  if ($scope.newInvite && $scope.newInvite.role) {
    $scope.selectedRole = $scope.memberRoleOptions.find(item => item.value === $scope.newInvite.role);
  }

  $scope.toggleIsPhoneInvite = () => {
    $scope.user = null;
    $scope.errors = {};
    $scope.isPhoneInvite = !$scope.isPhoneInvite;
  };

  $scope.onPhoneInputChange = function(value, data) {
    const phone = value.substring(data.dialCode.length);
    const phoneCountry = data.countryCode.toUpperCase();

    $scope.newInvite.phone = phone;
    $scope.newInvite.phoneCountry = phoneCountry;
  };

  $scope.onSelectRole = (value) => {
    $scope.selectedRole = value;

    if ($scope.selectedRole !== null && $scope.selectedRole.length !== 0) {
      $scope.newInvite.role = $scope.selectedRole.value;
    }
  };

  $scope.newMember = () => {
    run(() => {
      if ($scope.space.planCanManageMembers()) {
        $scope.newInvite = new SpaceInvitation();
        $scope.newInvite.role = 'coach';
        $scope.showForm = true;
        $('.panel').removeClass('hide');
        $scope.user = null;
        $scope.focused = true;
        if ($scope.memberRoleOptions && $scope.memberRoleOptions.length) {
          $scope.selectedRole = $scope.memberRoleOptions.find(item => item.value === $scope.newInvite.role);
        }
      }
    });
  };

  $scope.setUser = (value) => {
    if (value != null && value.length) {
      const user = value[0].model;
      run(() => {
        $scope.user = user;
        if (!$scope.newInvite) {
          $scope.newInvite = new SpaceInvitation();
        }
        $scope.newInvite.email = user.email;
        $scope.newInvite.phone = user.phone;
        $scope.newInvite.phoneCountry = user.phoneCountry;
        $scope.newInvite.name = user.name;
        $scope.focused = false;
      });
    }
  };

  $scope.removeUser = () => {
    $scope.user = null;
    $scope.focused = true;
    $scope.errors = {};
  };

  $scope.hideForm = () => {
    $scope.errors = {};
    $scope.showForm = false;
  };

  $scope.create = () => {
    if (!$scope.newInvite) {
      $scope.newInvite = new SpaceInvitation();
    }
    $scope.newInvite.spaceId = $scope.spaceId;

    spaceEdited('Members Invited', {
      coachCount: '?',
      fanCount: '?'
    });

    $scope.processing = true;

    $scope.newInvite.save()
      .then((invitation) => run(() => {
        $scope.hideForm();
        $scope.$parent.invitations.push($scope.newInvite);
        toast.success($translate.instant('CONTROLLERS.SPACE_INVITATIONS.CREATE_SUCCESS_MESSAGE'));

        if (invitation.phoneNormalized) {
          invitationModal.copyPhoneInviteLink(invitation.shortInvitationUrl);
          let phoneInput = $('.react-tel-input > input');
          let phoneNormalized = phoneInput.val();

          if (phoneNormalized) {
            phoneInput.val(phoneNormalized.replaceAll(/\s/g, '').replace($scope.newInvite.phone, ''));
          }
        }
      }))
      .catch((response) => run(() => $scope.errors = response.data.errors))
      .finally(() => run(() => $scope.processing = false ));
  };
}

Controller.$inject = [
  '$',
  '$scope',
  'core.services.identity',
  '$translate',
  'core.ui.toast',
  '$routeParams',
  'SpaceInvitation',
  'invitationModal'
];

export default {
  name: 'SpaceInvitationsCtrl',
  controller: Controller
};
