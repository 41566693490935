/**
 * @module Directives.Library
 *
 */
import template from 'ngapp/templates/directives/media/_media_modal.html';

/**
 * @class MediaModal
 *
 */
function MediaModalController($scope, media) {
  $scope.tab = 'upload';
  $scope.selection = [];
  $scope.accept = $scope.$props.accept;
  $scope.onCancel = onCancel;
  $scope.onNavigate = onNavigate;
  $scope.onSelect = onSelect;
  $scope.onConfirm = onConfirm;
  $scope.onUpload = onUpload;

  function onCancel() {
    $scope.$props.modal.close();
  }

  function onNavigate(tab) {
    $scope.tab = tab;
  }

  function onSelect(selection) {
    $scope.selection = selection;
  }

  function onConfirm() {
    $scope.$props.onSelect({ selection: $scope.selection });
    $scope.$props.modal.close();
  }

  function onUpload(resource) {
    $scope.$props.onSelect({ selection: [media.toAttachment(resource)] });
    $scope.$props.modal.close();
  }
}

MediaModalController.$inject = ['$scope', 'mediaHelpers'];

export default {
  bindings: {
    'accept': '<',
    'modal': '<',
    'onSelect': '&'
  },
  template,
  controllerAs: '$props',
  controller: MediaModalController
};
