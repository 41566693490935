/**
 * @module Directives.Teams
 *
 */
import { isAuthenticated } from 'app/utils/auth';
import template from 'ngapp/templates/views/teams/_team_post_edit_control.html';

const POST_LOCALIZATION_KEYS = {
  unpin: {
    confirmation: 'TEAMS.FEED_CONTROLS.UNPIN_POST_CONFIRMATION'
  },
  delete: {
    confirmation: 'TEAMS.FEED_CONTROLS.DELETE_POST_CONFIRMATION'
  }
};

const REPLY_LOCALIZATION_KEYS = {
  delete: {
    confirmation: 'TEAMS.FEED_CONTROLS.DELETE_REPLY_CONFIRMATION'
  }
};

function Controller($scope, $translate, $location, confirm, teamModal, paywallModal, togglePinModalService, identity, planFeature) {
  $scope.post = $scope.$props.post;
  $scope.postIsReply = $scope.$props.postIsReply;
  $scope.user = null;
  $scope.localization = getLocalization($scope.post, $scope.postIsReply);
  $scope.onTogglePin= onTogglePin;
  $scope.onEdit= onEdit;
  $scope.onRemove = onRemove;
  $scope.onReport = onReport;
  $scope.isMainFeed = /^\/$/.test($location.path());
  $scope.onCopy = onCopy;
  $scope.onMove = onMove;
  $scope.canCopy = $scope.post.canCopy;
  $scope.canMove = $scope.post.canMove;
  $scope.canAddToRecruit = $scope.post.canAddToRecruit;

  if (isAuthenticated()) {
    identity.request().then((user) => $scope.user = user);
  }

  function getLocalization(post, isReply) {
    if (post && isReply) {
      return REPLY_LOCALIZATION_KEYS;
    }
    return POST_LOCALIZATION_KEYS;
  }

  function onEdit() {
    $scope.$props.onEdit({ post: $scope.post });
  }
  function onTogglePin() {
    if (!planFeature.post.canPin($scope.post)) {
      return paywallModal.pinnedPosts();
    }

    if (!$scope.post.can.pin) {
      return togglePinModalService.togglePinNotAllowed($scope.post).result;
    }

    togglePinModalService.togglePin($scope.post).result.then(params => {
      $scope.post.pinnedTill = params.pinnedTill;
      $scope.$props.onTogglePin();
    });
  }

  function onCopy() {
    $location.path(`/copy/${$scope.post.id}`);
  }

  function onMove() {
    $location.path(`/move/${$scope.post.id}`);
  }

  function onRemove() {
    confirm({ body: $translate.instant($scope.localization.delete.confirmation) })
      .then(function(result) {
        if (result.confirmed) {
          $scope.$props.onRemove({ post: $scope.post });
        }
      });
  }

  function onReport() {
    teamModal.reportPost($scope.post).result
      .then(function(e) {
        $scope.$props.onReport({ event: e });
      });
  }
}

Controller.$inject = [
  '$scope',
  '$translate',
  '$location',
  'confirm',
  'teamModal',
  'paywallModal',
  'togglePinModalService',
  'core.services.identity',
  'core.services.planFeature'
];

export default {
  bindings: {
    post: '<',
    postIsReply: '<',
    onEdit: '&',
    onRemove: '&',
    onTogglePin: '&',
    onReport: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
