/** @module resource */
import { buildUrl } from 'app/utils/request';

function SpaceInvitationFactory(Invitation, $translate) {
  // SpaceInvitation extends Invitation
  function SpaceInvitation() {
    SpaceInvitation.__super__.constructor.apply(this, arguments);
  }

  Invitation.extendTo(SpaceInvitation);

  SpaceInvitation.configure({
    url: buildUrl('spaces/{{spaceId}}/invitations/{{id}}', {}, 1),
    name: 'invitation'
  });

  SpaceInvitation.per_page = 20;

  SpaceInvitation.rolesWithoutAthlete = [
    { value: 'coach', name: $translate.instant('COACH').toString() },
    { value: 'contributor', name: $translate.instant('CONTRIBUTOR').toString() }
  ];

  SpaceInvitation.roles = [].concat(SpaceInvitation.rolesWithoutAthlete, [
    {value: 'athlete', name: $translate.instant('ATHLETE').toString()}
  ]);

  SpaceInvitation.prototype.update = function() {
    return this.$put(buildUrl('invitations/', {}, 1.2) + this.id, this);
  };

  return SpaceInvitation;
}

SpaceInvitationFactory.$inject = ['Invitation', '$translate'];

export default SpaceInvitationFactory;
