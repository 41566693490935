/**
 * @module Directives.Posts
 *
 */
import template from 'ngapp/templates/views/posts/_scheduled_post_modal.html';
import moment from 'moment';

/**
 * @class TogglePinModal
 *
 */
function Controller($scope) {
  $scope.post = $scope.$props.post;
  $scope.location = $scope.$props.location;
  $scope.scheduledDate = moment($scope.post.scheduledAt).format("MMMM D, YYYY");
  $scope.scheduledTime = moment($scope.post.scheduledAt).format("hh:mm a");
  $scope.onOk = onOk;

  function onOk() {
    $scope.$props.modal.dismiss();
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    modal: '<',
    location: '<',
    post: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
