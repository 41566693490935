/** @module controllers/teams */

import { all } from 'rsvp';
import { run } from 'app/utils/runloop';
import uniqBy from 'lodash/uniqBy';
import reject from 'lodash/reject';
import template from 'ngapp/templates/views/teams/_timeline.html';

/**
 * @class TimelineController
 *
 */
function Controller(
  $root,
  $scope,
  $params,
  $location,
  Post,
  planFeature,
  identity,
  tracking,
  teamWrapModel,
  createPager,
  TeamGroup,
  paywallModal,
  scheduledPostModal
) {
  $scope.canPostMedia = false;
  $scope.canSchedulePosts = false;
  $scope.teamId = $params.id;
  $scope.draftId = 'draft:teams/' + $scope.teamId + '/post';
  $scope.pending = true;
  $scope.feed = [];
  $scope.myGroups = null;
  $scope.joinableGroups = null;
  $scope.team = null;
  $scope.user = null;
  $scope.onEdit = onEdit;
  $scope.onLike = onLike;
  $scope.onScheduleClick = onScheduleClick;
  $scope.onMediaLockedClick = onMediaLockedClick;
  $scope.onPost = onPost;
  $scope.onRemove = onRemove;
  $scope.onReply = onReply;
  $scope.onReport = onReport;
  $scope.onTogglePin = onTogglePin;
  $scope.onScroll = onScroll;
  $scope.onFilterChange = onFilterChange;
  $scope.onJoin = onJoin;

  function onPage(e) {
    run(() => {
      if (e != null && e.data != null && e.data.results != null) {
        const data = e.data.results || [];
        $scope.feed = uniqBy([ ...$scope.feed, ...data ], 'id');
      }
    });
  }

  function loadTeam(teamId) {
    return teamWrapModel.find(teamId).then(data => run(() => {
      $scope.team = data.team;
      $scope.canPostMedia = planFeature.team.canAddTeamMedia(data.team);
      $scope.myGroups = data.myGroups;
      $scope.joinableGroups = data.joinableGroups;
      return data;
    }));
  }

  $scope.reloadGroups = () => {
    return teamWrapModel.reload().then(data => run(() => {
      $scope.team = data.team;
      $scope.canPostMedia = planFeature.team.canAddTeamMedia(data.team);
      $scope.myGroups = data.myGroups;
      $scope.joinableGroups = data.joinableGroups;
      return data;
    }));
  };

  let pager = createPager($scope.teamId);
  loadData(pager);

  function loadData(pager) {
    pager.onPage.attach(onPage);

    all([ pager.get(), identity.request(), loadTeam($scope.teamId) ]).then(([ feed, user ]) => run(() => {
      $scope.feed = feed;
      $scope.user = user;
      $scope.canSchedulePosts = planFeature.user.canSchedulePosts(user);
      $scope.pending = false;
    }));
  }

  function onEdit(post) {
    tracking.teams.postEdited($scope.team, post);
  }

  function onLike(post) {
    tracking.teams.postLiked($scope.team, post);
  }

  function onMediaLockedClick() {
    if($scope.team.isOwnedByUser($scope.user)) {
      $scope.pending = true;
      paywallModal.postMedia().result
        .then(() => loadTeam($scope.teamId))
        .finally(() => run(() => $scope.pending = false));
    } else {
      paywallModal.postMediaOtherOwner();
    }
  }

  function onScheduleClick() {
    paywallModal.proUpgrade();
  }

  function onPost(fields) {
    return Post.post($scope.teamId, fields).then(post => {
      run(() => {
        tracking.teams.postCreated($scope.team, post);

        $root.$broadcast('postCreated');

        // $scope.feed = [post].concat($scope.feed);
        loadData(pager);

        if (post.scheduledAt !== null) {
          scheduledPostModal.showScheduledPostModal(post, $scope.team).result;
        }
      });
    });
  }

  function onReply(reply) {
    tracking.teams.postCreated($scope.team, reply);
  }

  function onRemove(post) {
    tracking.teams.postDeleted($scope.team, post);
    if (!post.isReply) {
      $scope.feed = reject($scope.feed, { id: post.id });
    }
  }

  function onReport(e) {
    tracking.teams.postReported($scope.team, e.post);
    onRemove(e.post);
  }

  function onTogglePin(post) {
    $scope.pending = true;

    post.toggle_pin().then(function() {
      loadData(pager);
    });
  }

  function onScroll() {
    pager.next();
  }

  function onFilterChange(filters) {
    pager.destroy();
    pager = createPager($scope.teamId, filters);
    pager.onPage.attach(onPage);
    $scope.pending = true;

    pager.get().then(feed => run(() => {
      $scope.feed = feed;
      $scope.pending = false;
    }));
  }

  function onJoin(group) {
    $scope.pending = true;
    TeamGroup.join(group).then(() =>
      loadTeam(group.team.id).then(() => run(() => {
        $scope.pending = false;
        $location.path(group.timelineUrl);
      }))
    );
  }
}

Controller.$inject = [
  '$rootScope',
  '$scope',
  '$routeParams',
  '$location',
  'core.services.Post',
  'core.services.planFeature',
  'core.services.identity',
  'core.services.tracking',
  'teamWrapModel',
  'teamFeedPagerFactory',
  'core.services.TeamGroup',
  'paywallModal',
  'scheduledPostModal'
];

export default {
  controller: Controller,
  template
};
