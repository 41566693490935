/**
 * @module Directives.TeamsGroups
 *
 */
import group_success_icon_png from 'app/images/group-success-icon.png';
import template from 'ngapp/templates/views/teams_groups/_group_created_modal.html';

/**
 * @class GroupCreatedModal
 *
 */
function Controller($scope) {
  $scope.group = $scope.$props.group;
  $scope.onAddMembers = onAddMembers;
  $scope.onAddAllMembers = onAddAllMembers;
  $scope.onDone = onDone;
  $scope.group_success_icon_png = group_success_icon_png;

  function onAddMembers() {
    $scope.$props.modal.close({ group: $scope.group, addAllMembers: false });
  }

  function onAddAllMembers () {
    $scope.$props.modal.close({ group: $scope.group, addAllMembers: true });
  }

  function onDone() {
    $scope.$props.modal.dismiss();
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    modal: '<',
    group: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
