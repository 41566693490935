/**
 * @module Directives.Spaces
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/spaces/_spaces_list.html';
import null_space from 'app/images/null-training-space.png';

/**
 * @class SpacesList
 *
 */
function Controller($scope) {
  $scope.canCreateSpaces = $scope.$props.canCreateSpaces;
  $scope.spaces = $scope.$props.spaces;
  $scope.filteredSpaces = $scope.$props.spaces;
  $scope.empty = $scope.spaces.length === 0;
  $scope.query = '';
  $scope.onNewSpaceClick = onNewSpaceClick;
  $scope.onUpgradeClick = onUpgradeClick;
  $scope.onFilter = onFilter;
  $scope.null_space = null_space;

  function onNewSpaceClick() {
    $scope.$props.onNewSpaceClick();
  }

  function onUpgradeClick() {
    $scope.$props.onUpgradeClick();
  }

  function onFilter(query) {
    run(() => {
      $scope.query = query;

      let spaces = [];
      if ($scope.spaces != null && Array.isArray($scope.spaces) && $scope.spaces.length) {
        spaces = $scope.spaces;
      }

      $scope.filteredSpaces = spaces.filter(space =>
        normalizeName(space.fullName).includes(normalizeName(query))
      );
    });
  }
}

const normalizeName = (name) => {
  if (name && name.length) {
    return name.toLowerCase();
  }
  return '';
};

Controller.$inject = ['$scope'];

export default {
  bindings: {
    spaces: '<',
    canCreateSpaces: '<',
    onNewSpaceClick: '&',
    onUpgradeClick: '&',
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
