/**
 * @module Directives.Teams
 *
 */
import defaultTo from 'lodash/defaultTo';
import { uniq } from 'app/utils/array';
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/teams/_post_form.html';

/**
 * @class TeamPostForm
 *
 */
function Controller($q, $scope, $translate, draft, Tag, mediaHelpers) {
  $scope.user = $scope.$props.user;
  $scope.errors = {};
  $scope.isReply = $scope.$props.parent != null;
  $scope.pending = false;
  $scope.canPost = $scope.$props.canPost;
  $scope.canPostMedia = $scope.$props.canPostMedia;
  $scope.canSchedulePosts = $scope.$props.canSchedulePosts;
  $scope.isDemo = $scope.$props.isDemo;
  $scope.disableTags = defaultTo($scope.$props.disableTags, false);
  $scope.placeholder = formatPlaceholder($scope.$props.name);
  $scope.draft = draft.retrieve($scope.$props.draftId) || createDraft();
  $scope.createAttachmentCSS = createAttachmentCSS;
  $scope.createMediaSpriteClassName = createMediaSpriteClassName;
  $scope.formatPostButtonName = formatPostButtonName;
  $scope.onAttachment = onAttachment;
  $scope.onCancelClick = onCancelClick;
  $scope.onFormTextFocus = onFormTextFocus;
  $scope.onMediaLockedClick = onMediaLockedClick;
  $scope.onRemoveAttachment = onRemoveAttachment;
  $scope.onSubmit = onSubmit;
  $scope.onUpgradeToPostClick = onUpgradeToPostClick;
  $scope.onScheduleClick = onScheduleClick;
  $scope.onScheduledAtChange = onScheduledAtChange;

  // Clean up draft's scheduledAt as it may be outdated
  if ($scope.draft.fields.scheduledAt) {
    $scope.draft.fields.scheduledAt = null;
  }

  $scope.$watch('draft', () => {
    draft.save($scope.$props.draftId, $scope.draft);
  }, true);

  $scope.onUpdateTags = (tags) => {
    tags = tags != null ? tags : [];
    const values = tags.map(item => item.name);
    const curTagsAdded = ($scope.draft.inputTags || []).slice(0).filter(item => values.indexOf(item) !== -1);
    const currentTags = ($scope.draft.fields.tags || []).slice(0).filter(item => values.indexOf(item) !== -1);
    const newTags = (values || []).slice(0).filter(item => currentTags.indexOf(item) === -1);

    $scope.draft.inputTags = uniq([ ...curTagsAdded, ...newTags ]);
    $scope.draft.fields.tags = uniq([ ...currentTags, ...newTags ]);
  };

  function createDraft() {
    return {
      fields: {
        details: '',
        resourceIds: [],
        tags: [],
        scheduledAt: null
      },
      attachments: [],
      inputTags: [],
      collapsed: true
    };
  }

  function reset() {
    run(() => {
      $scope.errors = {};
      $scope.pending = false;
      $scope.draft = createDraft();
    });
  }

  function formatPlaceholder(name) {
    if (name == null) {
      return $translate.instant('TEAMS.POST_FORM.TEXT_PLACEHOLDER');
    }
    return $translate.instant('TEAMS.POST_FORM.CUSTOM_TEXT_PLACEHOLDER', { name: name });
  }

  function formatPostButtonName() {
    if ($scope.$props.parent == null) {
      return $translate.instant('TEAMS.POST_FORM.POST_BUTTON');
    }
    return $translate.instant('TEAMS.POST_FORM.REPLY_BUTTON');
  }

  function validate(fields) {
    var result = { errors: {}, isValid: true };

    if (fields.resourceIds.length === 0 && fields.details.trim() === '') {
      result.errors.details = 'required';
      result.isValid = false;
    }

    if (fields.scheduledAt !== null && new Date(fields.scheduledAt) < new Date) {
      result.errors.scheduledAt = 'invalid';
      result.isValid = false;
    }

    return result;
  }

  function createAttachmentCSS(attachment) {
    const style = {};
    if (attachment != null && attachment.thumbnail != null) {
      style['background-image'] = `url(${attachment.thumbnail})`;
    }
    return style;
  }

  function createMediaSpriteClassName(attachment) {
    return mediaHelpers.getSpriteClassName(attachment.url);
  }

  function onScheduledAtChange(data) {
    $scope.draft.fields.scheduledAt = new Date(data).toISOString();
  }

  function onFormTextFocus() {
    run(() => $scope.draft.collapsed = false);
  }

  function onCancelClick() {
    reset();
  }

  function onMediaLockedClick() {
    run(() => $scope.$props.onMediaLockedClick());
  }

  function onUpgradeToPostClick() {
    run(() => $scope.$props.onUpgradeToPostClick());
  }

  function onScheduleClick() {
    run(() => $scope.$props.onScheduleClick());
  }

  function onSubmit(e) {
    e.preventDefault();
    const validationResult = validate($scope.draft.fields);
    if (validationResult.isValid) {
      $scope.pending = true;
      $q.resolve($scope.$props.submit($scope.draft))
        .then(() => reset())
        .finally(() => run(() => $scope.pending = false));
    }
    $scope.errors = validationResult.errors;
  }

  const getTags = (attachments) => {
    return uniq([].concat(...attachments.map(item => item.tags)));
  };

  function onAttachment(attachments) {
    run(() => {
      $scope.draft.attachments = attachments;
      $scope.draft.fields.resourceIds = attachments.map(item => item.id);

      const newTags = getTags(attachments);
      const inputTags = ($scope.draft.inputTags || []).slice(0);
      $scope.draft.fields.tags = uniq([ ...inputTags, ...newTags ]);
    });
  }

  function onRemoveAttachment(attachment) {
    run(() => {
      const attachments = ($scope.draft.attachments || []).slice(0).filter(item => item.id !== attachment.id);
      $scope.draft.attachments = (attachments || []).slice(0);
      $scope.draft.fields.resourceIds = attachments.map(item => item.id);

      const attachmentTags = getTags(attachments);
      const inputTags = ($scope.draft.inputTags || []).slice(0);

      $scope.draft.fields.tags = uniq([ ...inputTags, ...attachmentTags ]);
    });
  }
}

Controller.$inject = [ '$q', '$scope', '$translate', 'core.services.draft', 'core.services.Tag', 'mediaHelpers' ];

export default {
  bindings: {
    disableTags: '<',
    draftId: '<',
    canPost: '<',
    canPostMedia: '<',
    canSchedulePosts: '<',
    isDemo: '<',
    name: '@',
    parent: '<',
    user: '<',
    onMediaLockedClick: '&',
    onUpgradeToPostClick: '&',
    onScheduleClick: '&',
    submit: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
