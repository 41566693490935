/**
 * @module Core.Services
 *
 */
import { resolve, reject } from 'rsvp';
import {
  fetchGet,
  fetchPut,
  parseChanges,
  bindAngularResource
} from 'app/utils/request';

/**
 * @class User
 *
 */
function factory($property, $resource, planFeature, sharedUserService) {
  var User = $resource({
    url: '/api/v2/account',
    name: 'user'
  });

  User._query = User.query;
  User.query = function(params={}) {
    return fetchGet('account', params, { version: 1 }).then(model => {
      return bindAngularResource(model, this);
    });
  };

  User.me = function me() {
    return User.query().then(model => {
      sharedUserService.updateUser(model);
      return model;
    });
  };

  User.prototype.put = function(data) {
    const user = parseChanges(this, data);
    if (!Object.keys(data || {}).length) {
      return resolve(this);
    }
    return fetchPut('account', { user }, { version: 1 }).then(() => User.me());
  };

  User.prototype.resetPassword = function(current_password, password, password_confirmation) {
    current_password = current_password.trim();
    password = password.trim();
    password_confirmation = password_confirmation.trim();

    let errs = {};
    if (!current_password.length) {
      errs['current_password'] = ["required"];
    }

    if (!password.length) {
      errs['password'] = ['required'];
    }

    if (password !== password_confirmation) {
      errs['password_confirmation'] = ['mismatch'];
    }

    if (Object.keys(errs || {}).length) {
      return reject({ originalErrors: errs });
    }

    const user = {
      current_password,
      password,
      password_confirmation
    };

    return fetchPut('account/password', { user }).then(() => User.me());
  };

  User.prototype.hasCoachPlus = function () {
    return planFeature.user.hasCoachPlus(this);
  };

  User.prototype.hasCreatedTeam = function hasCreatedTeam() {
    return this.createdTeamsCount && this.createdTeamsCount > 0;
  };

  return User;
}

factory.$inject = [
  'core.$property',
  'core.services.$resource',
  'core.services.planFeature',
  'sharedUserService'
];

export default factory;
