/**
 * @module Angular.Controllers
 *
 */
import accountSettingsDropdown from './account_settings_dropdown_controller';
import recurlyBillingInfo from './billing/recurly_billing_info';
import bulkPostSpaces from './bulk_post_spaces';
import postCreator from './post_creator';
import postInner from './posts/post_inner';
import replies from './replies';
import spaceInvitation from './space_invitations';
import spaceMembershipsInvitations from './space_memberships/space_memberships_invitations';
import spaceNotifications from './space_notifications';
import spaceFeed from './spaces/space_feed';

// import './billing/stripe_billing_info';
// import './billing/stripe_billing_info_edit';

export default function (__angular) {
  __angular.module('controllers')
    .controller(accountSettingsDropdown.name, accountSettingsDropdown.controller)
    .controller(recurlyBillingInfo.name, recurlyBillingInfo.controller)
    .controller(bulkPostSpaces.name, bulkPostSpaces.controller)
    .controller(postCreator.name, postCreator.controller)
    .controller(postInner.name, postInner.controller)
    .controller(replies.name, replies.controller)
    .controller(spaceInvitation.name, spaceInvitation.controller)
    .controller(spaceMembershipsInvitations.name, spaceMembershipsInvitations.controller)
    .controller(spaceNotifications.name, spaceNotifications.controller)
    .controller(spaceFeed.name, spaceFeed.controller);
}
