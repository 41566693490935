/**
 * @module Controllers
 *
 */
import null_tags from 'app/images/null-tags.png';
import template from 'ngapp/templates/views/tags/_list.html';

// function TagsController($scope, $q, $location, listLoader, UserTag, identity, planFeature) {
  function TagsController($scope, $q, $location) {
  $scope.null_tags = null_tags;
  $scope.tags = [];
  $scope.pending = true;
  $scope.canCreateTags = false;
  $scope.onCreateTagsClick = onCreateTagsClick;

  // listLoader($scope, 'tags', listLoaderPromise, listLoaderHandler);
  // $scope.loadList();

  // function listLoaderPromise() {
  //   return $q.all([identity.request(), UserTag.query()]);
  // }

  // function listLoaderHandler(results) {
  //   $scope.canCreateTags = planFeature.user.canCreateTags(results[0]);
  //   $scope.tags = results[1];
  //   $scope.pending = false;
  // }

  function onCreateTagsClick() {
    $location.path('/account/tags/new');
  }
}

TagsController.$inject = [
  '$scope',
  '$q',
  '$location',
  'listLoader',
  'UserTag',
  'core.services.identity',
  'core.services.planFeature'
];

export default {
  controller: TagsController,
  template
};

