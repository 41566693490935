/**
 * @module Directives
 *
 */

/**
 * @class Sticky
 *
 */
function Sticky($) {
  function px(v) {
    return v + 'px';
  }

  function link($scope, $element) {
    const $container = $('.site-content');
    const $inner = $container.find('.site-content-inner');
    const $parent = $element.closest('.sticky-parent');
    let stuck = false;

    $container[0].addEventListener('scroll', onScroll);
    $scope.$on('$destroy', () => {
      $container[0].removeEventListener('scroll', onScroll);
    });

    function onScroll() {
      const padding = $inner[0].offsetTop - $container[0].offsetTop;

      if ($parent.offset().top - padding - $container[0].offsetTop <= 0) {
        if (!stuck) {
          $element[0].classList.add('stuck');
          stuck = true;
        }

        $element[0].style.top = px($container[0].scrollTop - $parent[0].offsetTop);
      } else if (stuck) {
        $element[0].classList.remove('stuck');
        $element[0].style.top = "";
        stuck = false;
      }
    }
  }

  return {
    restrict: 'C',
    link: link,
    scope: false
  };
}

Sticky.$inject = ['jQuery'];

export default Sticky;
