/**
 * @module Core.UI.Animations
 *
 */

/**
 * @class EnterLeft
 *
 */
function animation($css) {
  function enter(element, done) {
    return $css(element, {
      from: { opacity: 0, transform: 'translate3d(-100%, 0 ,0)' },
      to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
      duration: 0.2,
      easing: 'ease-out'
    })
    .start()
    .then(done);
  }

  function leave(element, done) {
    done();
  }

  return { leave: leave, enter: enter };
}

animation.$inject = ['$animateCss'];

export default animation;
