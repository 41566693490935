
function BulkPostSpacesController($scope, PostSpace) {
  $scope.spaces = [];
  $scope.dropdown = {
    isOpen: false
  };

  $scope.init = function(postId) {
    $scope.postId = postId;
  };

  $scope.$watch('dropdown.isOpen', function(isOpen) {
    if (isOpen && $scope.spaces.length == 0) {
      PostSpace.getSpaces($scope.postId).then(function(spaces) {
        $scope.spaces = spaces;
      });
    }
  });
}

BulkPostSpacesController.$inject = [
  '$scope',
  'PostSpace'
];

export default {
  name: 'BulkPostSpacesCtrl',
  controller: BulkPostSpacesController
};
