/** @module directives/teams */
import reject from 'lodash/reject';
import { isBlank } from 'app/utils';
import { digest, run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/teams/_member_email_invite_modal.html';

/**
 * @class TeamMemberEmailInviteModal
 *
 */
function Controller($timeout, $element, $scope, validators) {
  $scope.fields = { members: [] };
  $scope.errors = { members: [] };
  $scope.onAddMoreRows = onAddMoreRows;
  $scope.onCancel = onCancel;
  $scope.onSubmit = onSubmit;
  $scope.$onDestroy = $onDestroy;

  function member(memberModel={}) {
    return {
      name: '',
      email: '',
      role: 'player',
      position: '',
      number: '',
      ...memberModel
    };
  }

  // setup empty member list or insert provided members in form
  const memberProps = ($scope.$props.members || []).map(m => member(m));
  const defaultMembers = Array(3).fill(0).map(() => member());
  const postLength = 3 - memberProps.length;
  const postfill = defaultMembers.slice(0, postLength > 0 ? postLength : 0);
  $scope.fields.members = [
    ...memberProps, // add passed in members
    ...postfill // post fill keeps at least 3 member slots if less than 3 $props.members are provided
  ];

  function validate(form) {
    const result = { valid: true, members: [] };
    const emails = {};
    for (let i = 0; i < form.members.length; i++) {
      const member = form.members[i];
      const email = member.email.toLowerCase();
      if (validators.isBlank(member.name) && validators.isBlank(member.email)) {
        result.members.push(null);
      } else if (!validators.isEmail(member.email)) {
        result.members.push('invalid_email');
        result.valid = false;
      } else if (emails[email]) {
        result.members.push('duplicate_email');
        result.valid = false;
      } else {
        emails[email] = true;
        result.members.push(null);
      }
    }
    return result;
  }

  function hasErrors() {
    const validationResult = validate($scope.fields);
    if (!validationResult.valid) {
      $scope.errors = { members: validationResult.members };
    } else {
      $scope.errors = { members: [] };
    }
    digest();
    return !validationResult.valid;
  }

  function onKeydown(e) {
    if (e.target.nodeName === 'INPUT') {
      const row = parseInt(e.currentTarget.dataset.row);
      const field = e.target.name;

      run(() => {
        if (e.key === 'ArrowUp') {
          if (row > 0) {
            $timeout(() =>
              $element.find('[data-row=' + (row - 1) + '] [name="' + field + '"]').focus()
            );
          }
        } else if (e.key === 'ArrowDown') {
          if (row === $scope.fields.members.length - 1) {
            $scope.fields.members.push(member());
          }
          $timeout(() =>
            $element.find('[data-row=' + (row + 1) + '] [name="' + field + '"]').focus()
          );
        }
      });
    }
  }

  $element.on('keydown', '.member-invite-roster-control-group', onKeydown);

  function onAddMoreRows() {
    $scope.fields.members.push(member());
  }

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onBlur(e) {
    if (e.target.nodeName === 'INPUT') {
      hasErrors();
    }
  }
  $element.on('blur', '.member-invite-roster-control-group', onBlur);

  function onSubmit(e) {
    e.preventDefault();
    if (!hasErrors()) {
      const members = reject($scope.fields.members, (m) => isBlank(m.email));
      if (members.length > 0) {
        $scope.$props.modal.close({ members: members });
      }
    }
  }

  function $onDestroy() {
    $element.off('keydown', '.member-invite-roster-control-group', onKeydown);
    $element.off('blur', '.member-invite-roster-control-group', onBlur);
  }
}

Controller.$inject = [ '$timeout', '$element', '$scope', 'core.validate' ];

export default {
  bindings: {
    modal: '<',
    members: '<',
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
