/**
 * @module Controllers.Spaces
 *
 */

 import { all } from 'rsvp';
 import { run } from 'app/utils/runloop';
 import reject from 'lodash/reject';
 import uniqBy from 'lodash/uniqBy';
 import template from 'ngapp/templates/views/spaces/_space_scheduled_posts.html';

/**
 * @class SpaceScheduledPosts
 *
 */
function Controller(
  $scope,
  $params,
  identity,
  Post,
  Space,
  createPager
) {
  $scope.spaceId = $params.spaceId;
  $scope.space = {};
  $scope.user = {};
  $scope.scheduledPosts = [];
  $scope.pending = true;
  $scope.onRemove = onRemove;
  $scope.onScroll = onScroll;
  $scope.hasScheduledPosts = hasScheduledPosts;

  function onPage(e) {
    run(() => {
      if (e != null && e.data != null && e.data.results != null) {
        const data = e.data.results;
        $scope.scheduledPosts = uniqBy([ ...$scope.scheduledPosts, ...data ], 'id');
      }
    });
  }

  let pager = createPager({ spaceId: $scope.spaceId });
  loadData(pager);

  function loadData(pager) {
    pager.onPage.attach(onPage);

    all([
      pager.get(),
      identity.request(),
      Space.get($scope.spaceId, { 'options[include]': ['invitations_info', 'user_settings'] })
    ]).then(([
      scheduledPosts,
      user,
      space
    ]) => run(() => {
      $scope.scheduledPosts = scheduledPosts;
      $scope.user = user;
      $scope.space = space;
      $scope.pending = false;
    }));
  }

  function onRemove(post) {
    run(() => { $scope.scheduledPosts = reject($scope.scheduledPosts, { id: post.id }); });
  }

  function hasScheduledPosts() {
    $scope.scheduledPosts.length > 0;
  }

  function onScroll() {
    pager.next();
  }
}

Controller.$inject = [
  '$scope',
  '$routeParams',
  'core.services.identity',
  'core.services.Post',
  'core.services.Space',
  'scheduleFeedPagerFactory'
];

export default {
  controller: Controller,
  template
};
