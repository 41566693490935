/**
 * @module Controllers
 *
 */
// import {
//   run
// } from 'app/utils/runloop';
import template from 'ngapp/templates/views/resources/_list.html';
// import null_media_png from 'app/images/null-media.png';
// import doc_image from 'app/images/ico_doc.png';
// import xls_image from 'app/images/ico_xls.png';
// import pdf_image from 'app/images/ico_pdf.png';
// import ppt_image from 'app/images/ico_ppt.png';
// import play_image from 'app/images/play_btn.svg';
// import audio_image from 'app/images/ico_audio.png';

/**
 * @class Resources
 *
 */
// function Controller($scope, $q, $location, $translate, identity, planFeature, ResourceTag, paywallModal, pagerFactory, time, $interval) {
    function Controller() {
  // $scope.MIN_YEAR_VALUE = pagerFactory.MIN_DATE_VALUE.getFullYear();
  // $scope.filters = createFilters();
  // $scope.dirty = false;
  // $scope.resources = [];
  // $scope.pending = true;
  // $scope.canAddMedia = true;
  // $scope.hasCoachPlus = false;
  // $scope.resourceTypes = createResourceTypeOptions();
  // $scope.onAddMediaClick = onAddMediaClick;
  // $scope.onUpgradeClick = onUpgradeClick;
  // $scope.onScroll = onScroll;
  // $scope.onFilterChange = onFilterChange;
  // $scope.null_media_png = null_media_png;

  // $scope.doc_image = doc_image;
  // $scope.xls_image = xls_image;
  // $scope.pdf_image = pdf_image;
  // $scope.ppt_image = ppt_image;
  // $scope.play_image = play_image;
  // $scope.audio_image = audio_image;

  // const userReq = identity.request().then((user) => {
  //   run(() => {
  //     $scope.canAddMedia = planFeature.user.canAddMedia(user);
  //     $scope.hasCoachPlus = planFeature.user.hasCoachPlus(user);
  //   });
  // });
  // let pager = pagerFactory.create($scope.filters);
  // let pagerForPolling = pagerFactory.create($scope.filters);
  // let currentIndex = 0;
  // let pollingPromise;

  // $q.all([pager.get(), ResourceTag.query(), userReq]).then(([resources, tags]) => {
  //   run(() => {
  //     $scope.availableTags = tags.map(item => item.name);
  //     $scope.resources = resources;
  //     $scope.pending = false;

  //     processResources();
  //   });
  // });

  // function processResources() {
  //   if (currentIndex >= $scope.resources.length) {
  //     $interval.cancel(pollingPromise);
  //     return;
  //   }

  //   const currentResource = $scope.resources[currentIndex];

  //   if (currentResource && currentResource.processed) {
  //     currentIndex++;
  //     processResources();
  //   } else {
  //     pollingPromise = $interval(() => {
  //       $q.all([pagerForPolling.get(), ResourceTag.query(), userReq]).then(([updatedResources]) => {
  //         const updatedResource = updatedResources[currentIndex];
  //         if (updatedResource && updatedResource.processed) {
  //           $interval.cancel(pollingPromise);
  //           $scope.resources[currentIndex] = updatedResource;
  //           $scope.pending = false;
  //           currentIndex++;
  //           processResources();
  //         }
  //       });
  //     }, 8000);
  //   }
  // }

  // function onPage(e) {
  //   run(() => {
  //     $scope.resources = [...$scope.resources, ...e.data.results];
  //   });
  // }
  // pager.onPage.attach(onPage);
  // pagerForPolling.onPage.attach(onPage);

  // function onScroll() {
  //   pager.next();
  // }

  // function onAddMediaClick() {
  //   $location.path('/resources/new');
  // }

  // function onUpgradeClick() {
  //   paywallModal.library();
  // }

  // function onFilterChange(filters) {
  //   $scope.filters = filters;
  //   pager.destroy();
  //   pager = pagerFactory.create(filters);
  //   pager.onPage.attach(onPage);
  //   $scope.pending = true;
  //   currentIndex = 0;
    
  //   $q.all([pager.get(), ResourceTag.query(), userReq]).then(([resources, tags]) => {
  //     run(() => {
  //       $scope.availableTags = tags.map(item => item.name);
  //       $scope.resources = resources;
  //       $scope.pending = false;
  //     });
  //   });
  // }

  // function createFilters() {
  //   const date = new Date();
  //   const month = date.getMonth();
  //   const year = date.getFullYear();

  //   return {
  //     type: ['image', 'video', 'note', 'audio', 'datafile'],
  //     tags: [],
  //     orderBy: 'created_at',
  //     from: (new Date(2012, 0)).toISOString(),
  //     to: time.ceil(new Date(year, month), 'month').toISOString()
  //   };
  // }

  // function createResourceTypeOptions() {
  //   return [{
  //       value: 'image',
  //       label: $translate.instant('FEED_FILTER.RESOURCE_TYPES.IMAGES')
  //     },
  //     {
  //       value: 'video',
  //       label: $translate.instant('FEED_FILTER.RESOURCE_TYPES.VIDEO')
  //     },
  //     {
  //       value: 'audio',
  //       label: $translate.instant('FEED_FILTER.RESOURCE_TYPES.AUDIO')
  //     },
  //     {
  //       value: 'datafile',
  //       label: $translate.instant('FEED_FILTER.RESOURCE_TYPES.DOCUMENTS')
  //     },
  //   ];
  // }

  // $scope.$on("$destroy", function () {
  //   if (angular.isDefined(pollingPromise)) {
  //     $interval.cancel(pollingPromise);
  //   }
  // });
}

Controller.$inject = [
  '$scope',
  '$q',
  '$location',
  '$translate',
  'core.services.identity',
  'core.services.planFeature',
  'ResourceTag',
  'paywallModal',
  'resourcePagerFactory',
  'core.time',
  '$interval',
];

export default {
  controller: Controller,
  template
};
