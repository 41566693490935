/**
 * @module Directives.Library
 *
 */
import template from 'ngapp/templates/directives/media/_media_button_locked.html';

/**
 * @class MediaButtonLocked
 *
 */
function Controller($scope) {
  $scope.onClick = onClick;

  function onClick() {
    $scope.$props.onClick();
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    onClick: '&'
  },
  template,
  controllerAs: '$props',
  controller: Controller
};
