/**
 * @module Core.Services.Tracking
 *
 */

/**
 * @class $upgrade
 *
 */
function factory($client, $paywalls) {
  const upgrades = Object.create($paywalls);

  upgrades._optionSelected = function(feature, option) {
    $client.track('Upgrade Option Selected', {
      Feature: feature,
      Source: 'Web',
      Option: option,
    });
  };

  upgrades.shown = function(feature) {
    $client.track('Upgrade Options Shown', {
      Feature: feature,
      Source: 'Web'
    });
  };

  upgrades.freeSelected = function(feature) {
    upgrades._optionSelected(feature, 'Continue for Free');
  };

  return {
    shown: upgrades.shown,
    trialSelected: upgrades.trialSelected,
    upgradeSelected: upgrades.upgradeSelected,
    freeSelected: upgrades.freeSelected
  };
}

factory.$inject = ['core.services.tracking.$client', 'core.services.tracking.$paywalls'];

export default factory;
