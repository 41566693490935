
export default function toTimezoneOffsetString() {
  return function (timezoneOffset) {
    var decimal = timezoneOffset / 3600;
    var sign = decimal < 0 ? '-' : '+';
    var hour = Math.floor(Math.abs(decimal));
    var min = Math.floor((Math.abs(decimal) * 60) % 60);

    return sign + (hour < 10 ? '0' : '') + hour + ':' + (min < 10 ? '0' : '') + min;
  };
}
