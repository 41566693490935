/**
 * @module ReactDirectives
 *
 */
import React from 'react';
import ProvideWrapper from 'app/components/ProvideWrapper';

/**
 * React Directives go here
 */
import Spaces from 'app/routes/Spaces';
import UpgradePlus from 'app/routes/UpgradePlus';
import UserAvatar from 'app/components/UserAvatar';
import TeamAvatar from 'app/components/TeamAvatar';
import Benefits from 'app/routes/Benefits';
import AddMembersToChannels from 'app/components/modals/AddMembersToChannels';
import { Store } from 'app/components/Wrappers';
import { Dots, Spinner } from 'app/components/Loading';
import { Edit, View } from 'app/components/subscription/BillingInfo';
import SelectBox, { DaySelect, MonthSelect, SportSelect, UserSelect, TagSelect, YearSelect } from 'app/components/inputs/SelectBox';
import CalendlyModal from 'app/components/modals/CalendlyModal';
import DemoPost from 'app/components/modals/DemoPost';
import Support from 'app/components/paywalls/Support';
import ScheduledAtPicker from 'app/components/ScheduledPosts/ScheduledAtPicker';
import ScheduleFilter from 'app/components/ScheduledPosts/Filter';
import ScheduleCalendar from 'app/components/ScheduledPosts/Calendar';
import ResendButton from 'app/components/PhoneVerification/ResendButton';
import PhoneInput from 'react-phone-input-2';
import ReactDatepicker from 'react-datepicker';
import 'react-phone-input-2/lib/style.css';
import Datafile from "app/components/Datafile";
import ContentCard from 'app/components/ContentCard';
import PostViewsModal from '../components/PostViewsModal/index';
import LikesViewsModal from 'app/components/LikesViewsModal';
import PostViews from 'app/components/PostViewsCountedView';
import LikesText from 'app/components/LikesText';
import CopyMove from 'app/components/Button/CopyMove/';
import CopyMovePost from 'app/components/CopyMovePost';
import DefiningPopup from 'app/components/Popup/DefiningPopup';
import GettingStarted from 'app/routes/GettingStarted';
import GettingStartedProgress from 'app/components/GettingStarted/GettingStartedProgress';
import SpaceUserSelect from 'app/components/inputs/SelectBox/SpaceUserSelect';
import ScheduleBtn from 'app/components/Button/ScheduleBtn';
import ScheduledAlert from 'app/components/ScheduledAlert';
import ApplyTemplateDropdown from 'app/components/Button/ApplyTemplateDropdown';
import TemplateAppliedTo from 'app/components/TemplateAppliedTo';
import TemplateAppliedList from 'app/components/TemplateAppliedTo/TemplateAppliedList';
import AddToRecruitProfile from 'app/components/Button/AddToRecruitProfile';
import SmartListPostingTitle from 'app/components/SmartListPostingForm/SmartListPostingTitle';
import Tags from 'app/routes/Tags';
import AddTag from 'app/routes/Tags/AddTag';
import EditTag from 'app/routes/Tags/EditTag';
import DeleteTag from 'app/routes/Tags/DeleteTag';
import Resources from 'app/routes/Resources';
import CreateNewResource from 'app/routes/Resources/CreateNewResource';
import EditResource from '../routes/Resources/EditResource';
import CreatePostWithMedia from '../routes/Resources/CreatePostWithMedia';
import Segments from 'app/routes/Segments';
import SegmentSpaces from 'app/routes/Segments/SegmentSpaces';
import ManageSpaces from 'app/routes/Segments/ManageSpaces';
import EditSegment from 'app/routes/Segments/EditSegment';
import InviteToSegment from 'app/routes/Segments/InviteToSegment';
// import Sidebar from 'app/components/Sidebar';
import LocationDropDown from '../components/LocationsDropdown';

/**
 * Creates a directive wrapped with the provider
 */
const createDirective = (Component, propNames, config, injectableProps) => {
  const Container = (props) => (
    <ProvideWrapper>
      <Component {...props} />
    </ProvideWrapper>
  );
  const ReactDirectiveContainer = (reactDirective) => (
    reactDirective(Container, propNames, config, injectableProps)
  );
  ReactDirectiveContainer.$inject = ['reactDirective'];
  return ReactDirectiveContainer;
};

const SELECT_PROPS = [
  'className', 'value', 'defaultValue', 'inputValue', 'defaultInputValue',
  'isDisabled', 'isSearchable', 'isCreatable', 'isClearable', 'menuIsOpen',
  'blurInputOnSelect', 'menuPlacement', 'noOptionsMessage', 'hideSelectedOptions',
  'onChange',
];

/**
 * eported directives
 */
export default function(__angular) {
  __angular.module('app')
    .directive('reactAddMembersToChannels', createDirective(AddMembersToChannels, [ 'show', 'team', 'members', 'teamGroups', 'onClose', 'onDone', 'doneText', 'closeText' ]))
    .directive('reduxStore', createDirective(Store, [ 'actions', 'stateChange', 'noAuth', 'wait' ]))
    .directive('reactSpaces', createDirective(Spaces, [ 'activeTab', 'jacksjourney', 'selectedSmartList' ]))
    .directive('reactUpgradePlus', createDirective(UpgradePlus))
    .directive('reactBenefits', createDirective(Benefits))
    .directive('reactCalendlyModal', createDirective(CalendlyModal, [ 'isOpen', 'onClose' ]))
    .directive('reactDemoPost', createDirective(DemoPost, [ 'isOpen', 'onClose', 'type' ]))
    .directive('reactSupport', createDirective(Support, [ 'className', 'disabled', 'onChat', 'onSchedule', 'feature' ]))
    .directive('reactUserAvatar', createDirective(UserAvatar, [ 'className', 'srcName', 'altName', 'sizeWidth', 'sizeHeight', 'kind', 'userName' ]))
    .directive('reactTeamAvatar', createDirective(TeamAvatar, [ 'className', 'srcName', 'altName', 'sizeWidth', 'sizeHeight' ]))
    .directive('reactLoadingSpinner', createDirective(Spinner))
    .directive('reactLoadingDots', createDirective(Dots))
    .directive('reactSelectBox', createDirective(SelectBox, [ ...SELECT_PROPS, 'placeholder', 'options' ]))
    .directive('reactSportSelect', createDirective(SportSelect, SELECT_PROPS))
    .directive('reactUserSelect', createDirective(UserSelect, [ ...SELECT_PROPS, 'onCreate', 'ignore', 'hideSelected', 'includeJack', 'excludeOptions' ]))
    .directive('reactSpaceUserSelect', createDirective(SpaceUserSelect, [ ...SELECT_PROPS, 'addAthleteBtn', 'onCreate', 'ignore', 'hideSelected', 'includeJack', 'excludeOptions' ]))
    .directive('reactTagSelect', createDirective(TagSelect, [ ...SELECT_PROPS, 'ignore' ]))
    .directive('reactDaySelect', createDirective(DaySelect, [ ...SELECT_PROPS, "maxDate", "minDate", 'year', 'month', 'inputValue', 'value' ]))
    .directive('reactMonthSelect', createDirective(MonthSelect, [ ...SELECT_PROPS, "maxDate", "minDate" ]))
    .directive('reactYearSelect', createDirective(YearSelect, [ ...SELECT_PROPS, "maxDate", "minDate" ]))
    .directive('reactScheduledAtPicker', createDirective(ScheduledAtPicker, ["value", "onChange"]))
    .directive('reactScheduleFilter', createDirective(ScheduleFilter, ["selectedCalendarDate", "onSelectedCalendarDateRemove", "onSearchSubmit", "onReset", "onSubmit"]))
    .directive('reactScheduleCalendar', createDirective(ScheduleCalendar, ["scheduledPostsCount", "onChange"]))
    .directive('reactPhoneInput', createDirective(PhoneInput, [ 'country', 'value', 'countryCodeEditable', 'onChange', 'inputStyle', 'inputProps', 'disabled' ]))
    .directive('reactDatepicker', createDirective(ReactDatepicker, [ 'inline', 'monthsShown', 'wrapperClassName' ]))
    .directive('reactResendButton', createDirective(ResendButton, [ 'phone', 'phoneCountry' ]))
    .directive('reactStripeBillingInfo', createDirective(View))
    .directive('reactStripeBillingInfoEdit', createDirective(Edit))
    .directive('reactDatafile', createDirective(Datafile, ['type', 'title']))
    .directive('reactContentCard', createDirective(ContentCard, ['img', 'icon', 'title', 'subtitle', 'hasContextMenu']))
    .directive('reactPostViewsModal', createDirective(PostViewsModal, ['isDemoSpace']))
    .directive('reactLikesViewsModal', createDirective(LikesViewsModal))
    .directive('reactLikesText', createDirective(LikesText, ['likes', 'postId']))
    .directive('reactPostViews', createDirective(PostViews, ['isDemoSpace', 'viewsCount', 'viewers', 'postId', 'spaceId', 'canSeePostData', 'planPermissions', 'CoachKind']))
    .directive('reactCopyMove', createDirective(CopyMove, ['text']))
    .directive('reactCopyMovePost', createDirective(CopyMovePost, ['isMove']))
    .directive('reactDefiningPopup', createDirective(DefiningPopup, [ 'content' ]))
    .directive('reactGettingStarted', createDirective(GettingStarted, []))
    .directive('reactGettingStartedProgress', createDirective(GettingStartedProgress, []))
    .directive('reactScheduleBtn', createDirective(ScheduleBtn, ['onScheduleChange', 'scheduledAt', 'scheduledTime']))
    .directive('reactScheduledAlert', createDirective(ScheduledAlert, ['scheduledIn']))
    .directive('reactApplyTemplateDropdown', createDirective(ApplyTemplateDropdown, ['canApplyTemplate', 'templateId', 'templateName']))
    .directive('reactTemplateAppliedTo', createDirective(TemplateAppliedTo, ['activeTab', 'tabHandler']))
    .directive('reactTemplateAppliedList', createDirective(TemplateAppliedList, ['templateId']))
    .directive('reactAddToRecruitProfile', createDirective(AddToRecruitProfile, ['postId']))
    .directive('reactSmartListPostingTitle', createDirective(SmartListPostingTitle))
    .directive('reactTags', createDirective(Tags))
    .directive('reactAddTag', createDirective(AddTag, ['changeToTagsUrl']))
    .directive('reactEditTag', createDirective(EditTag, ['changeToTagsUrl', 'tagId']))
    .directive('reactDeleteTag', createDirective(DeleteTag, ['changeToTagsUrl', 'tagId']))
    .directive('reactResources', createDirective(Resources))
    .directive('reactCreateNewResource', createDirective(CreateNewResource))
    .directive('reactEditResource', createDirective(EditResource, ['resourceId']))
    .directive('reactNewResource', createDirective(CreatePostWithMedia))
    .directive('reactSegments', createDirective(Segments))
    .directive('reactSegmentSpaces', createDirective(SegmentSpaces))
    .directive('reactManageSpaces', createDirective(ManageSpaces, ['resourceId']))
    .directive('reactEditSegment', createDirective(EditSegment, ['resourceId']))
    .directive('reactInviteToSegment', createDirective(InviteToSegment, ['resourceId']))
    .directive('reactLocationDropDown', createDirective(LocationDropDown, ['onCheckboxChange', 'classNames']));
    // .directive('reactSmartListPostingForm', createDirective(SmartListPostingForm, []));
    // .directive('reactSidebar', createDirective(Sidebar, ['user', 'onLogOut']));
}
