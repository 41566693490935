/**
 * @module Core.Services.Tracking
 *
 */

/**
 * @class $post
 *
 */
function factory() {
  function extendEventData(event, post) {
    const postData = {
      'Post Is Reply': post.isReply,
      'Post Id': post.id,
      'Post Tag Count': (post.tags || []).length,
      'Post Type': post.type
    };

    return Object.assign({}, event, postData);
  }

  return {
    extendEventData: extendEventData
  };
}

factory.$inject = [];

export default factory;
