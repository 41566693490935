/**
 * @module Directives.Library
 *
 */
import size from 'lodash/size';
import { digest } from 'app/utils/runloop';
import template from 'ngapp/templates/directives/media/_media_picker.html';
import null_media_png from 'app/images/null-media.png';

const TYPE_TRANSLATION_KEYS = {
  'video': 'MEDIA.TYPES.VIDEO',
  'audio': 'MEDIA.TYPES.AUDIO',
  'image': 'MEDIA.TYPES.IMAGES',
  'datafile': 'MEDIA.TYPES.DOCUMENTS'
};

/**
 * @class MediaPicker
 *
 */
function MediaPicker($q, $scope, $translate, mediaHelpers, pagerFactory, ResourceTag) {
  $scope.pending = true;
  $scope.media = { resources: [], options: {} };
  $scope.tags = [];
  $scope.filters = {
    tags: [],
    type: {
      video: true,
      audio: true,
      image: true,
      datafile: true
    }
  };
  $scope.hasPreviewImage = hasPreviewImage;
  $scope.createMediaItemCSS = mediaHelpers.createMediaItemCSS;
  $scope.createMediaSpriteClassName = mediaHelpers.createMediaSpriteClassName;
  $scope.createMediaItemIconClassName = mediaHelpers.createMediaItemIconClassName;
  $scope.createMediaItemClassNames = mediaHelpers.createMediaItemClassNames;
  $scope.formatTypeOptionLabel = formatTypeOptionLabel;
  $scope.formatTypeFilterLabel = formatTypeFilterLabel;
  $scope.onSelect = onSelect;
  $scope.onScroll = onScroll;
  $scope.null_media_png = null_media_png;

  var pager = pagerFactory.create($scope.filters);
  var tagsRequest = ResourceTag.query();

  $q.all([tagsRequest, pager.get()])
    .then(([ tags, filters ]) => digest(() => {
      $scope.tags = tags.map(item => item.name);
      $scope.media.resources = filters;
      $scope.media.options = parseOptions(filters);
    }))
    .finally(() => digest(() => {
      $scope.pending = false;
    }));

  pager.onPage.attach(onPage);

  $scope.$watch('filters', function(current, previous) {
    if (!angular.equals(current, previous)) {
      onFilter();
    }
  }, true);

  function parseOptions(resources) {
    var options = {};

    for (var i = 0; i < resources.length; i++) {
      var resource = resources[i];
      var id = resource.id;
      var option = {
        id: id,
        selected: false,
        resource: resource
      };

      if ($scope.media.options[id]) {
        option.selected = $scope.media.options[id].selected;
      }

      options[id] = option;
    }

    return options;
  }

  function hasPreviewImage(resource) {
    return mediaHelpers.getPreviewUrl(resource) != null;
  }

  function formatTypeOptionLabel(type) {
    return $translate.instant(TYPE_TRANSLATION_KEYS[type]);
  }

  function formatTypeFilterLabel(filters) {
    var activeFilters = Object.keys(filters || {});
    var activeFiltersCount = activeFilters.length;

    if (activeFiltersCount === size(filters)) {
      return $translate.instant('MEDIA.PICKER.FILTERING_ALL_TYPES');
    } else if (activeFiltersCount === 0) {
      return $translate.instant('MEDIA.PICKER.FILTERING_NO_TYPES');
    } else if (activeFiltersCount === 1) {
      return $translate.instant(TYPE_TRANSLATION_KEYS[
        activeFilters[0]
      ]);
    }

    return $translate.instant('MEDIA.PICKER.FILTERING_MANY_TYPES', {
      count: activeFiltersCount
    }, 'messageformat');
  }

  function onPage(e) {
    $scope.media.resources = $scope.media.resources.concat(e.data.results);
    $scope.media.options = Object.assign({}, $scope.media.options, parseOptions(e.data.results));
    digest();
  }

  function onSelect(value) {
    var selection = [];

    for (var id in $scope.media.options) {
      var option = $scope.media.options[id];

      if (id === value.id) {
        $scope.media.options[id].selected = !value.selected;
      } else if (!$scope.$props.multiple) {
        $scope.media.options[id].selected = false;
      }

      if (option.selected) {
        selection.push(mediaHelpers.toAttachment(option.resource));
      }
    }

    $scope.$props.onSelect({ selection: selection });
  }

  function onScroll() {
    pager.next();
  }

  function onFilter() {
    pager.destroy();
    pager = pagerFactory.create($scope.filters);

    pager.onPage.attach(onPage);
    pager.get().then(resources => digest(() => {
      $scope.media.resources = resources;
      $scope.media.options = parseOptions(resources);
    }));
  }
}

MediaPicker.$inject = [
  '$q',
  '$scope',
  '$translate',
  'mediaHelpers',
  'resourcePagerFactory',
  'ResourceTag'
];

export default {
  bindings: {
    multiple: '<',
    onSelect: '&'
  },
  template,
  controllerAs: '$props',
  controller: MediaPicker
};
