
function PolicyModalService($modal) {
  function show() {
    return $modal.open({
      keyboard: false,
      backdrop: 'static',
      template: '<policy-acceptance-modal modal="modal"></policy-acceptance-modal>',
      controller: ['$modalInstance', '$scope', function(modal, $local) {
        $local.modal = modal;
      }]
    });
  }

  return { show: show };
}

PolicyModalService.inject = ['$modal'];

export default PolicyModalService;
