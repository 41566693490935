
import template from 'ngapp/templates/views/spaces/_new_space_button.html';

function Controller($scope) {
  $scope.onClick = onClick;

  function onClick() {
    $scope.$props.onNewSpaceClick();
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    onNewSpaceClick: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
