/**
 * @module Core.Services
 *
 */

/**
 * @class PrivacyPolicy
 *
 */
function factory($q, $resource) {
  const PrivacyPolicy = $resource({
    url: '/api/v2/privacy',
    name: 'privacy_policy'
  });

  /**
    * Checks the user's last acceptance of the privacy policy.
    *
    * @returns {Promise}
    */
  PrivacyPolicy.fetch = function fetch() {
    return PrivacyPolicy.get();
  };

  /**
    * Accepts the current privacy policy.
    *
    * @returns {Promise}
    */
  PrivacyPolicy.accept = function accept() {
    return PrivacyPolicy.$post(PrivacyPolicy.$url(), null, { rootWrapping: false });
  };

  return PrivacyPolicy;
}

factory.$inject = ['$q', 'core.services.$resource'];

export default factory;
