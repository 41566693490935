/**
 * @module Core.Services.Sheets
 *
 */
import { Promise } from 'rsvp';
import { isEmpty } from 'app/utils';
import { readAsArrayBuffer } from 'app/utils/file';
import papaparse from 'papaparse';
import xlsx from 'xlsx';

/**
 * @class $reader
 *
 */
export default {

  /**
   * @method xls
   */
  xls(blob) {
    return readAsArrayBuffer(blob).then(buffer => {
      const sheets = parseSheets(xlsx, buffer);
      return normalizeResponse('xlsx', blob, sheets, sheets.length);
    });
  },

  /**
   * @method csv
   */
  csv(blob) {
    return papaParsePromise(blob).then(data => {
      let sheets = [];
      let columns = 0;
      if (data[0] != null) {
        columns = data[0].length;
        sheets = [ normalizeSheet(0, '', data, columns) ];
      }
      return normalizeResponse('csv', blob, sheets, sheets.length);
    });
  }
};


/**
 * helper methods
 *
 */

const convert = function(xls, ts) {
  return xls.utils.sheet_to_json(ts, { header: 1 });
};

const normalizeSheet = function(ordinal, name, data, columns) {
  return { ordinal, name, data, columns };
};

const normalizeResponse = function(type, blob, sheets, length) {
  return { type, blob, sheets, length };
};


const parseSheets = function(xls, buffer) {
  const data = new window.Uint8Array(buffer);
  const table = xls.read(data, { type: 'array' });

  return table.SheetNames
    .map((name, index) => {
      const data = convert(xls, table.Sheets[name]);
      const cols = !isEmpty(data[0]) ? data[0].length : 0;
      return normalizeSheet(index, name, data, cols);
    })
    .filter(sheet => sheet.data.length > 0);
};

const papaParsePromise = function(blob) {
  return new Promise((resolve, reject) => {
    return papaparse.parse(blob, {
      complete: (result) => resolve(result.data),
      error: (error) => reject(error)
    });
  });
};

