/**
 * @module Directives
 *
 */
import template from 'ngapp/templates/directives/_name_with_email_input.html';

/**
 * @class NameWithEmailInput
 *
 */
export default function NameWithEmailInput() {
  function link(scope) {
    scope.showRemoveButton = function() {
      return (scope.index && scope.index > 0);
    };
  }

  return {
    restrict: 'E',
    template,
    replace: true,
    scope: {
      entity: '=',
      index: '@',
      name: '@',
      placeholder: '@',
      onRemove: '&',
      onEnter: '&'
    },
    link: link
  };
}
