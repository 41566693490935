/**
 * @module Directives.Teams
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/teams/_team_post_media.html';

/**
 * @class TeamPostMedia
 *
 */
function Controller(moment, $scope, $element, $timeout, media) {
  const resource = $scope.$props.resource;
  let playerId = null;

  $scope.remaining = '';
  $scope.isPlaying = false;
  $scope.resource = resource;
  $scope.onAudioClick = onAudioClick;
  $scope.$props.$onDestroy = $onDestroy;
  $scope.$props.$postLink = $postLink;

  function onAudioClick () {
    $scope.isPlaying = media.togglePlayer(playerId, resource.type);
  }

  function onStatusUpdate (status) {
    run(() => {
      $scope.isPlaying = status.playing;
      $scope.remaining = moment.utc(1000 * status.remaining).format('m:ss');
    });
  }

  function $onDestroy () {
    if (playerId != null) {
      media.destroyPlayer(playerId, resource.type);
    }
  }

  function $postLink () {
    var timeout = $timeout(function() {
      if (!$scope.resource.processedWithError) {
        if ($scope.resource.processed || $scope.resource.type === 'audio') {
          if (playerId == null) {
            var element = $element.find($scope.resource.type === 'video' ? 'video' : '.audio')[0];
            playerId = media.createPlayer($scope.resource, element, onStatusUpdate);
          }
        }
      }
    });

    timeout.then(function() {
      $timeout.cancel(timeout);
    });
  }
}

Controller.$inject = ['moment', '$scope', '$element', '$timeout', 'core.services.media'];

export default {
  bindings: {
    resource: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
