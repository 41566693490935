/**
 * @module Interceptors
 *
 */
import { errorLogger } from 'app/utils';

/**
 * @class http401ErrorInterceptor
 *
 */
function http401ErrorInterceptor($q, $rootScope) {
  return {
    responseError: function(response) {
      if (response.status === 401) {
        if (response.data.error === 'unverified_request') {
          if ($rootScope.user) {
            errorLogger(
              'Unauthorized (Unverified request)',
              { severity: 'info', user: { id: $rootScope.user.id }}
            );
          }
        }
        //window.location.href = '/signin';
      }
      return $q.reject(response);
    }
  };
}

http401ErrorInterceptor.$inject = ['$q', '$rootScope'];

export default http401ErrorInterceptor;
