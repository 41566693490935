/**
 * @module Directives.BulkPost
 *
 */
import map from 'lodash/map';

/**
 * @class BulkPostLocationFactory
 *
 */
function BulkPostLocationFactory(BulkPostLocation, GroupLocation, SegmentLocation, SpaceLocation, SmartListLocation, TeamLocation) {
  function create(destination) {
    var location = null;
    switch (destination.object) {
      case group():
        location = new GroupLocation(destination);
        break;
      case segment():
        location = new SegmentLocation(destination);
        break;
      case space():
        location = new SpaceLocation(destination);
        break;
      case smartList():
        location = new SmartListLocation(destination);
        break;
      case team():
        location = new TeamLocation(destination);
        break;
      default:
        var apiKeys = map(BulkPostLocation.KINDS, 'API_KEY').join(', ');
        throw new Error('The destination object kind of \'' + destination.object +
          '\' is not supported: ' + apiKeys + '.');
    }

    return location;
  }

  function group() {
    return kinds().GROUP.API_KEY;
  }

  function kinds() {
    return BulkPostLocation.KINDS;
  }

  function segment() {
    return kinds().SEGMENT.API_KEY;
  }

  function space() {
    return kinds().SPACE.API_KEY;
  }

  function smartList() {
    return kinds().SMART_LIST.API_KEY;
  }

  function team() {
    return kinds().TEAM.API_KEY;
  }

  return {
    create: create,
    group: group,
    kinds: kinds,
    segment: segment,
    space: space,
    smartList: smartList,
    team: team
  };
}

BulkPostLocationFactory.$inject = [
  'bulk_post.locations.$bulkPostLocation',
  'bulk_post.locations.groupLocation',
  'bulk_post.locations.segmentLocation',
  'bulk_post.locations.spaceLocation',
  'bulk_post.locations.smartListLocation',
  'bulk_post.locations.teamLocation'
];

export default BulkPostLocationFactory;
