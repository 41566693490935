
function factory($paywalls, $space, $team, $teamGroup, $trial, $upgrades, $user, $postViews) {
  return {
    paywalls: $paywalls,
    spaces: $space,
    teams: $team,
    teamGroups: $teamGroup,
    trial: $trial,
    upgrades: $upgrades,
    user: $user,
    postViews: $postViews,
  };
}

factory.$inject = [
  'core.services.tracking.$paywalls',
  'core.services.tracking.$space',
  'core.services.tracking.$team',
  'core.services.tracking.$teamGroup',
  'core.services.tracking.$trial',
  'core.services.tracking.$upgrades',
  'core.services.tracking.$user',
  'core.services.tracking.$postViews'
];

export default factory;
