
import template from 'ngapp/templates/views/teams/_team_notification_settings_modal.html';

function Controller($scope, $translate, toast, TeamMember) {
  $scope.pending = true;
  $scope.team = $scope.$props.team;
  $scope.member = null;
  $scope.fields = {};
  $scope.onCancel = onCancel;
  $scope.onSubmit = onSubmit;

  TeamMember.me($scope.team.id)
    .then(function(member) {
      $scope.member = member;
      $scope.fields = {
        emailNotificationsEnabled: $scope.member.emailNotificationsEnabled,
        pushNotificationsEnabled: $scope.member.pushNotificationsEnabled
      };

      $scope.pending = false;
    });

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onSubmit(e) {
    e.preventDefault();

    $scope.pending = true;

    TeamMember.update($scope.member, $scope.fields)
      .then(function () {
        toast.success($translate.instant('TEAMS.SETTINGS.SAVE_SUCCESS_MESSAGE'));
        $scope.$props.modal.dismiss();
      })
      .finally(function () {
        $scope.pending = false;
      });
  }
}

Controller.$inject = [ '$scope', '$translate', 'core.ui.toast', 'core.services.TeamMember' ];

export default {
  bindings: {
    modal: '<',
    team: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
