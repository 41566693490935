/**
 * @module Core.Services
 *
 */
import { digest } from 'app/utils/runloop';

/**
 * @class SharedUser
 *
 */
function factory($root) {
  return {
    user: $root.user,
    updateUser(user) {
      this.user = user;
      $root.$broadcast('userUpdated', this.user);
      digest();
      return user;
    }
  };
}

factory.$inject = ['$rootScope'];

export default factory;
