/**
 * @module Core.Services.Media
 *
 */
import assign from 'lodash/assign';
import has from 'lodash/has';
import unset from 'lodash/unset';
import forOwn from 'lodash/forOwn';

const options = {
  controls: true,
  aspectRatio: '16:9',
  autoplay: false,
  preload: 'auto'
};

/**
 * @class $media
 *
 */
function factory($videojs, mediaHelpers) {
  function VideoService($vjs) {
    this._$videojs = $vjs;
    this._players = {};
  }

  VideoService.prototype.createPlayer = function createPlayer(resource, element, onPlay) {
    var player = this._$videojs(element, assign({}, options, createOptions(resource)));
    var playerId = player.id();

    player.on('play', $onPlay);
    this._players[playerId] = playerId;

    function $onPlay() {
      onPlay(playerId);
    }

    // var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // if (isMobile) {
    //   player.ready(function () {
    //     var controlBar = player.getChild('ControlBar');
    //     if (controlBar) {
    //       var pipButton = controlBar.getChild('PictureInPictureToggle');
    //       if (pipButton) {
    //         pipButton.hide();
    //       }
    //     }
    //   });
    // }
    
    return playerId;
  };

  VideoService.prototype.destroyPlayer = function destroyPlayer(playerId) {
    var success = has(this._players, playerId);

    if (success) {
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture();
      }
      this._$videojs(playerId).dispose();
      unset(this._players, playerId);
    }

    return success;
  };

  VideoService.prototype.stopPlayers = function stopPlayers(playerId) {
    var that = this;

    forOwn(this._players, function (value, key) {
      if (key !== playerId) {
        that._$videojs(key).pause();
      }
    });
  };

  VideoService.prototype.togglePlayer = function togglePlayer(playerId) {
    var success = has(this._players, playerId);
    var playing = false;

    if (success) {
      var player = this._$videojs(playerId);
      playing = player.isPlaying();

      if (playing) {
        player.pause();
      } else {
        player.play();
      }
    }

    return success && !playing;
  };

  function createOptions(resource) {
    if (resource.processed) {
      return {
        poster: mediaHelpers.getPreviewUrl(resource) || resource.smallThumbnailUrl,
        sources: mediaHelpers.getVideoUrls(resource)
      };
    } else {
      throw new Error("Cannot create a video player for a resource that is not processed.");
    }
  }

  return new VideoService($videojs);
}

factory.$inject = ['videojs', 'mediaHelpers'];

export default factory;
