/**
 * @module Directives.BulkPost
 *
 */
import {
  all
} from 'rsvp';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import feedPostIcon from "app/images/smart-list/feed-post-icon.svg";
import fifteenDaysIcon from "app/images/smart-list/15-calendar-icon.svg";
import thirtyDaysIcon from "app/images/smart-list/30-calendar-icon.svg";
import sixtyDaysIcon from "app/images/smart-list/60-plus-calendar-icon.svg";

import {
  run
} from 'app/utils/runloop';
import template from 'ngapp/templates/views/bulk_post/_bulk_post_selector.html';

/**
 * @class BulkPostSelector
 *
 */
function Controller($scope, $rootScope, Post, Segment, SmartList, paywallModal, helpers) {
  $scope.pending = true;
  $scope.hasDestinations = false;
  $scope.hasSpaces = false;
  $scope.hasSmartLists = false;
  $scope.hasTeamsOrGroups = false;
  $scope.locations = null;
  $scope.segments = null;
  $scope.smartLists = null;
  $scope.spaces = null;
  $scope.teamsWithGroups = null;
  $scope.selectedLocations = $scope.$props.selectedLocations || [];
  $scope.mediaPostPending = $scope.$props.mediaPostPending;
  $scope.onCheckboxChange = onCheckboxChange;
  $scope.onLockedClick = onLockedClick;
  $scope.$props.$onChanges = $onChanges;
  $scope.user = $rootScope.user;
  $scope.isSmartList = window.location.pathname.includes('smart_list');
  $scope.url = window.location.pathname;
  $scope.smartListId = '';

  // icons
  $scope.feedPostIcon = feedPostIcon;
  $scope.fifteenDaysIcon = fifteenDaysIcon;
  $scope.thirtyDaysIcon = thirtyDaysIcon;
  $scope.sixtyDaysIcon = sixtyDaysIcon;

  all([
    Post.destinations(),
    Segment.query(),
    SmartList.query()
  ]).then(([destinations, segments, smartLists]) => {
    if ($scope.isSmartList) {
      const url = $scope.url.split('/');
      const selectedLocationId = url[url.length - 1];
      $scope.smartListId = selectedLocationId;
      smartLists.forEach(smartList => {
        if (smartList.id === $scope.smartListId) {
          $scope.selectedLocations.push(smartList);
        }
      });


    }
    // digest();
    run(() => {
      destinations = destinations
        .filter(item =>
          item.permissions && item.permissions.indexOf('post') !== -1 ||
          item.planPermissions && item.planPermissions.indexOf('post') !== -1
        )
        .map(item => {
          if (item.object === 'segment') {
            const segment = segments.find(s => s.id === item.id);
            if (segment != null) {
              item.spaceIds = segment.spaceIds;
            } else {
              item.spaceIds = [];
            }
          } else if (item.object === 'smart_list') {
            const smartList = smartLists.find(s => s.id === item.id);
            if (smartList != null) {
              item.spaceIds = smartList.spaceIds;
              if (item.name.includes("15")) {
                item.icon = $scope.fifteenDaysIcon;
              } else if (item.name.includes("30")) {
                item.icon = $scope.thirtyDaysIcon;
              } else if (item.name.includes("60+")) {
                item.icon = $scope.sixtyDaysIcon;
              } else if (item.name.includes("Unanswered")) {
                item.icon = $scope.feedPostIcon;
              }
            } else {
              item.spaceIds = [];
            }
          }
          return item;
        });


      $scope.locations = helpers.resolveLocations(destinations);
      toggleMediaPosting($scope.mediaPostPending);

      $scope.segments = helpers.getSegments($scope.locations);
      $scope.spaces = helpers.getSpaces($scope.locations);
      $scope.smartLists = helpers.getSmartLists($scope.locations);
      $scope.teamsWithGroups = helpers.getTeamsWithGroups($scope.locations);

      $scope.hasSegments = $scope.segments.length > 0;
      $scope.hasSmartLists = $scope.smartLists.length > 0;
      $scope.hasSpaces = $scope.spaces.length > 0;
      $scope.hasTeamsOrGroups = $scope.teamsWithGroups.length > 0;
      $scope.hasDestinations = !isEmpty(destinations);

      $scope.pending = false;

      // search
      $scope.searchText = '';

      $scope.filterDestinations = function () {
        const searchText = $scope.searchText.toLowerCase();
        $scope.locations = destinations.filter(item => {
          const name = item.name.toLowerCase();
          return name.includes(searchText);
        });
        toggleMediaPosting($scope.mediaPostPending);
        $scope.spaces = helpers.getSpaces($scope.locations);
        $scope.smartLists = helpers.getSmartLists($scope.locations);
        $scope.segments = helpers.getSegments($scope.locations);
        $scope.teamsWithGroups = helpers.getTeamsWithGroups($scope.locations);
        $scope.hasSmartLists = $scope.smartLists.length > 0;
        $scope.hasSegments = $scope.segments.length > 0;
        $scope.hasSpaces = $scope.spaces.length > 0;
        $scope.hasTeamsOrGroups = $scope.teamsWithGroups.length > 0;

      };
    });
  });
  
  function onCheckboxChange(location) {
    if (!location.selected) {
      remove($scope.selectedLocations, {
        id: location.id
      });
    } else {
      if (isNil(find($scope.selectedLocations, {
        id: location.id
      }))) {
        $scope.selectedLocations.push(location);
      }
    }
    updateParentMediaPosting();
  }

  function onLockedClick(location) {
    if (!isNil(location.paywallModalName)) {
      // paywallModal[location.paywallModalName]();
      paywallModal.proUpgrade();
    }
  }

  function $onChanges(props) {
    if (props.mediaPostPending) {
      toggleMediaPosting(!isNil(props.mediaPostPending.currentValue));
    }
  }

  function toggleMediaPosting(mediaPostPending) {
    $scope.locations = helpers.toggleMediaPosting($scope.locations, mediaPostPending);
  }

  function updateParentMediaPosting() {
    $scope.$props.updateMediaPosting({
      value: helpers.hasUnpostableMediaSelection($scope.selectedLocations)
    });
  }

}

Controller.$inject = ['$scope', '$rootScope', 'Post', 'Segment', 'SmartList', 'paywallModal', 'bulk_post.bulkPostHelpers'];

export default {
  bindings: {
    selectedLocations: '<',
    mediaPostPending: '<',
    updateMediaPosting: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
