/**
 * @module Controllers
 *
 */
// import { uniq } from 'app/utils/array';
// import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/resources/_edit.html';

/**
 * @class ResourceEdit
 *
 */
// todo: if needed, add  'Resource, toast' props in ResourceEditController
function ResourceEditController($scope, $translate, $location, $routeParams) {
  $scope.resourceId = $routeParams.resourceId;
  // $scope.processing = true;
  // Resource.get($routeParams.resourceId).then(resource => run(() => {
  //   $scope.resource = resource;
  //   $scope.processing = false;
  // }));


  // $scope.save = function() {
  //   $scope.processing = true;
  //   $scope.resource.update()
  //     .then(() => {
  //       $location.path('/resources');
  //       toast.success($translate.instant('CONTROLLERS.RESOURCE_EDIT.SAVE_SUCCESS_MESSAGE'));
  //     })
  //     .catch(() => toast.danger($translate.instant('CONTROLLERS.RESOURCE_EDIT.SAVE_ERROR_MESSAGE')))
  //     .finally(() => run(() => $scope.processing = false));
  // };

  // $scope.delete = function() {
  //   $scope.processing = true;
  //   $scope.resource.delete()
  //     .then(() => {
  //       $location.path('/resources');
  //       toast.success($translate('CONTROLLERS.RESOURCE_EDIT.DELETE_SUCCESS_MESSAGE'));
  //     })
  //     .catch(() => toast.danger($translate('CONTROLLERS.RESOURCE_EDIT.DELETE_ERROR_MESSAGE')))
  //     .finally(() => run(() => $scope.processing = false));
  // };

  // $scope.onUpdateTags = (tags) => {
  //   run(() => {
  //     tags = tags != null ? tags : [];
  //     const values = tags.map(item => item.name);
  //     const curTagsAdded = ($scope.inputTags || []).slice(0).filter(item => values.indexOf(item) !== -1);
  //     const currentTags = ($scope.resource.tags || []).slice(0).filter(item => values.indexOf(item) !== -1);
  //     const newTags = (values || []).slice(0).filter(item => currentTags.indexOf(item) === -1);

  //     $scope.inputTags = uniq([ ...curTagsAdded, ...newTags ]);
  //     $scope.resource.tags = uniq([ ...currentTags, ...newTags ]);
  //   });
  // };
}

ResourceEditController.$inject = [
  '$scope',
  '$translate',
  '$location',
  '$routeParams',
  'Resource',
  'core.ui.toast'
];

export default {
  controller: ResourceEditController,
  template
};
