/**
 * @module Directive.Teams
 *
 */

/**
 * @class TeamCreationFlow
 *
 */
function TeamCreationFlow($location, planFeature, teamModal, teamInviteFlow, identity, upgradeModal) {
  function TeamCreationFlow() {
    this.team = null;
  }

  TeamCreationFlow.prototype.start = function start() {
    var self = this;

    return self._createTeam()
      .then(function(e) {
        return self._teamCreated(e.team);
      })
      .then(function() {
        return self._teamInvite(self.team);
      })
      .finally(function() {
        if (self.team !== null) {
          return self._showUpgradeModal();
        }
      });
  };

  TeamCreationFlow.prototype._createTeam = function _createTeam() {
    var self = this;
    var createTeamRequest = teamModal.createTeam().result;

    createTeamRequest.then(function (e) {
      self.team = e.team;
      $location.path(e.team.timelineUrl);
    });

    return createTeamRequest;
  };

  TeamCreationFlow.prototype._teamInvite = function _teamInvite(team) {
    return teamInviteFlow(team);
  };

  TeamCreationFlow.prototype._teamCreated = function _teamCreated(team) {
    return teamModal.created(team).result;
  };

  TeamCreationFlow.prototype._showUpgradeModal = function _showUpgradeModal() {
    return identity.request()
      .then(function(user) {
        if (!user.hasCreatedTeam()) {
          if (!planFeature.user.hasCoachPlus(user)) {
            return upgradeModal.show().result;
          }
        }
      });
  };

  return function createTeam() {
    return new TeamCreationFlow().start();
  };
}

TeamCreationFlow.$inject = [
  '$location',
  'core.services.planFeature',
  'teamModal',
  'teamInviteFlow',
  'core.services.identity',
  'upgradeModal'
];

export default TeamCreationFlow;
