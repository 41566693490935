/** @module resources */

/**
 * @class SpaceTemplate
 *
 */
function SpaceTemplate($resource) {
  var resource = $resource({
    url: 'api/v1/space_templates',
    name: 'space_template'
  });

  resource.prototype.addMember = function(member) {
    this.members.push(member);
    this.memberIds.push(member.id);
    return this.save();
  };

  resource.prototype.removeMember = function(member) {
    const memberList = this.members.slice(0);
    const memberIds = this.memberIds.slice(0);

    Array.removeObj(memberList, member);
    Array.removeObj(memberIds, member.id);

    this.members = memberList.slice(0);
    this.memberIds = memberIds.slice(0);
    return this.save();
  };

  resource.prototype.planCanEdit = function() {
    return (this.planPermissions.indexOf('edit') !== -1);
  };

  resource.prototype.planCanManagePosts = function() {
    return (this.planPermissions.indexOf('manage_posts') !== -1);
  };

  resource.prototype.planCanManageMembers = function() {
    return (this.planPermissions.indexOf('manage_members') !== -1);
  };

  resource.prototype.planCanDelete = function() {
    return (this.planPermissions.indexOf('delete') !== -1);
  };

  return resource;
}

SpaceTemplate.$inject = ['core.services.$resource'];

export default SpaceTemplate;
