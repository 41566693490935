/**
 * @module Controllers
 *
 */
import { run } from 'app/utils/runloop';
import zipObject from 'lodash/zipObject';
import map from 'lodash/map';
import constant from 'lodash/constant';
import modalTemplate from 'ngapp/templates/modals/_cancel_coach_plus_modal.html';
import template from 'ngapp/templates/views/account/_subscriptions.html';

/**
 * @class AccountSubscription
 *
 */
function Controller($scope, $window, $translate, toast, $modal, Subscriptions, Upgrade, Trial) {
  $scope.pending = true;
  $scope.subscriptions = [];
  $scope.waiting = {};
  $scope.onCancelClick = onCancelClick;
  $scope.onRenewalChange = onRenewalChange;
  $scope.onUpgradeClick = onUpgradeClick;
  $scope.startTrial = startTrial;
  $scope.upgradeNow = upgradeNow;

  Subscriptions.query().then(subscriptions => run(() => {
    $scope.pending = false;
    $scope.subscriptions = subscriptions;
    $scope.waiting = zipObject(map(subscriptions, '_id'), map(subscriptions, constant(false)));
  }));

  function onCancelClick(subscription) {
    $modal.open({
      template: modalTemplate,
      controller: ['$scope', '$modalInstance', function ($scope, $modal) {
        $scope.copy = createSummaryCopy(subscription);
        $scope.close = () => $modal.close();
      }]
    });
  }

  function onRenewalChange(subscription) {
    $scope.waiting[subscription._id] = true;

    subscription
      .saveAutoRenewalStatus(subscription.isAutoRenewing)
      .then(() => onUpdateSuccess(subscription))
      .catch(() => onUpdateFailure(subscription))
      .finally(() => run(() => $scope.waiting[subscription._id] = false));
  }

  function onUpgradeClick(subscription) {
    $window.location = subscription.getUpgradeUrl();
  }

  function startTrial() {
    Trial.start().then(() =>
      Trial.started()
    );
  }

  function upgradeNow() {
    Upgrade.upgrade();
  }

  function onUpdateFailure(subscription) {
    return toast.danger($translate.instant('ACCOUNT.SUBSCRIPTIONS.UPDATE_ERROR_MESSAGE', { name: subscription.planName }));
  }

  function onUpdateSuccess(subscription) {
    return toast.success($translate.instant('ACCOUNT.SUBSCRIPTIONS.UPDATE_SUCCESS_MESSAGE', { name: subscription.planName }));
  }

  function createSummaryCopy(subscription) {
    switch (subscription.source) {
      case 'AppStore':
        return { summary: 'MODALS.CANCEL_COACH_PLUS.APP_STORE_INFO', showSupportContact: true };
      case 'PlayStore':
        return { summary: 'MODALS.CANCEL_COACH_PLUS.PLAY_STORE_INFO', showSupportContact: true };
      default:
        return { summary: 'MODALS.CANCEL_COACH_PLUS.DEFAULT_INFO', showSupportContact: false };
    }
  }
}

Controller.$inject = [
  '$scope',
  '$window',
  '$translate',
  'core.ui.toast',
  '$modal',
  'Subscriptions',
  'core.services.Upgrade',
  'core.services.Trial'
];

export default {
  controller: Controller,
  template,
};

