
import template from 'ngapp/templates/views/segments/_list.html';
// import null_segment from 'app/images/null-lists.png';

// function SegmentsController ($scope, $q, $translate, listLoader, Segment, paywallModal, segmentModalService, identity, planFeature) {
//   $scope.pending = true;
//   $scope.canCreateSegments = false;
//   $scope.hasCoachPlus = false;
//   $scope.segments = [];
//   $scope.onCreateSegmentsClick = onCreateSegmentsClick;
//   $scope.onRemoveClick = onRemoveClick;
//   $scope.onUpgradeClick = onUpgradeClick;
//   $scope.null_segment = null_segment;

//   listLoader($scope, 'segments', listLoaderPromise, listLoaderHandler);
//   $scope.loadList();

//   function listLoaderPromise() {
//     return $q.all([identity.request(), Segment.query()]);
//   }

//   function listLoaderHandler(results) {
//     var user = results[0];
//     $scope.canCreateSegments = planFeature.user.canCreateSegments(user);
//     $scope.hasCoachPlus = planFeature.user.hasCoachPlus(user);

//     $scope.segments.push.apply($scope.segments, results[1]);

//     $scope.pending = false;
//   }

//   function onCreateSegmentsClick() {
//     segmentModalService.createSegment();
//   }

//   function onRemoveClick(segment) {
//     segment.remove()
//       .then(
//         function() {
//           var index = $scope.segments.indexOf(segment);
//           $scope.segments.splice(index, 1);
//         },
//         function() {
//           $translate('CONTROLLERS.SEGMENTS.LOAD_TS_ERROR_MESSAGE')
//             .then(function(translation) {
//               alert(translation);
//             });
//         }
//       );
//   }

//   function onUpgradeClick() {
//     paywallModal.segments();
//   }
// }

// SegmentsController.$inject = [
//   '$scope',
//   '$q',
//   '$translate',
//   'listLoader',
//   'Segment',
//   'paywallModal',
//   'segmentModalService',
//   'core.services.identity',
//   'core.services.planFeature'
// ];

export default {
  // controller: SegmentsController,
  template
};

