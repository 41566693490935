/**
 * @module Directives.Activity
 *
 */
import each from 'lodash/each';
import template from 'ngapp/templates/views/activity/_incoming_invitation_list.html';

/**
 * @class IncomingInvitationList
 *
 */
function Controller($scope, time) {
  $scope.invitations = partition([]);
  $scope.onAccept = onAccept;
  $scope.onDecline = onDecline;
  $scope.$props.$onChanges = $onChanges;

  function partition(invitations) {
    var buckets = {
      today: [],
      week: [],
      month: [],
      rest: []
    };

    each(invitations, function (n) {
      var span = time.span(n.createdAt,  time.truncate(Date.now()));

      if (span.months > 0) {
        buckets.rest.push(n);
      } else if (span.days > 7) {
        buckets.month.push(n);
      } else if (span.days > 0) {
        buckets.week.push(n);
      } else {
        buckets.today.push(n);
      }
    });

    return buckets;
  }

  function onAccept(invitation) {
    $scope.$props.onAccept({ invitation: invitation });
  }

  function onDecline(invitation) {
    $scope.$props.onDecline({ invitation: invitation });
  }

  function $onChanges(props) {
    if (props.invitations != null) {
      $scope.invitations = partition(props.invitations.currentValue);
    }
  }
}

Controller.$inject = ['$scope', 'core.time'];

export default {
  bindings: {
    invitations: '<',
    onAccept: '&',
    onDecline: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
