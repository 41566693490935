/**
 * @module Controllers
 *
 */
import template from 'ngapp/templates/views/teams/_teams.html';

/**
 * @class Teams
 *
 */
function TeamsController() {}

TeamsController.$inject = [];

export default {
  controller: TeamsController,
  template
};

