/**
 * @module Core.Services
 *
 */
import { digest } from 'app/utils/runloop';

/**
 * @class Trial
 *
 */
function factory($location, $window, $url, identity, tracking, Subscriptions) {
  function _canStartTrial(user = {}) {
    const noStatus = user.subscription != null && user.subscription.status === 'none';
    const hasTrialStarted = !(user.plan && user.plan.trialStartedAt != null);
    return !_hasTrialSub(user) && noStatus && hasTrialStarted;
  }

  function _trialDaysRemaining(user = {}) {
    return _hasTrialSub(user) && user.plan != null && user.plan.trialDaysLeft > 0
      ? user.plan.trialDaysLeft
      : 0;
  }

  function _hasTrialSub(user = {}) {
    return user.hasTrialSubscription;
  }

  function _trialStatus(user = {}) {
    return {
      canStartTrial: _canStartTrial(user),
      isInTrial: _hasTrialSub(user),
      trialDaysRemaining: _trialDaysRemaining(user)
    };
  }

  function start() {
    return identity.request().then(user => {
      if(_canStartTrial(user)) {
        return Subscriptions.startTrial().then(() => {
          tracking.trial.started(user);
          status();
        });
      } else {
        throw new Error('Cannot start a trial for the current user.');
      }
    });
  }

  function started () {
    $window.location = $url.build($location.path(), { trialStarted: true });
    digest();
  }

  function status () {
    return identity.$refresh().then(user => _trialStatus(user));
  }

  return { start, started, status };
}

factory.$inject = [
  '$location',
  '$window',
  'core.services.$url',
  'core.services.identity',
  'core.services.tracking',
  'core.services.Subscriptions'
];

export default factory;

