/**
 * @module Directives.UpgradeModal
 *
 */
import { digest } from 'app/utils/runloop';
import coach_now_plus_grey_2x_png from 'app/images/coach-now-plus-grey@2x.png';
import template from 'ngapp/templates/views/upgrade_modal/_upgrade_options_modal.html';

const FEATURE = 'Team Creation';

/**
 * @class UpgradeOptionsModal
 *
 */
function Controller($q, $scope, tracking, identity, Trial, Upgrade) {
  $scope.pending = true;
  $scope.showTrial = false;
  $scope.onTrialClick = onTrialClick;
  $scope.onUpgradeClick = onUpgradeClick;
  $scope.onContinueClick = onContinueClick;
  $scope.coach_now_plus_grey_2x_png = coach_now_plus_grey_2x_png;

  tracking.upgrades.shown(FEATURE);

  const idRequest = identity.$refresh();

  const trialRequest = Trial.status().then(status => {
    digest(() => {
      $scope.showTrial = status != null && status.canStartTrial;
    });
  });

  $q.all([idRequest, trialRequest]).finally(() => {
    $scope.pending = false;
    digest();
  });

  function onTrialClick() {
    $scope.pending = true;
    tracking.upgrades.trialSelected(FEATURE);
    Trial.start().then(() => {
      Trial.started();
      $scope.$props.modal.dismiss();
      digest();
    });
  }

  function onUpgradeClick() {
    $scope.pending = true;
    tracking.upgrades.upgradeSelected(FEATURE);
    Upgrade.upgrade();
  }

  function onContinueClick() {
    tracking.upgrades.freeSelected(FEATURE);
    $scope.$props.modal.dismiss();
  }
}

Controller.$inject = ['$q', '$scope', 'core.services.tracking', 'core.services.identity', 'core.services.Trial', 'core.services.Upgrade'];

export default {
  bindings: {
    modal: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
