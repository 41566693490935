/**
 * @module Core.Services
 *
 */
import $reader from './$reader';

/**
 * @class Sheets
 *
 */
function factory($q) {
  function read(file) {
    switch (file.type) {
      case 'text/csv':
        return $reader.csv(file);
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return $reader.xls(file);
      default:
        return $q.reject(new Error('Unknown sheet type "' + file.type + '".'));
    }
  }
  return {
    read: read
  };
}

factory.$inject = ['$q'];

export default factory;
