/** @module directives/teams */
import map from 'lodash/map';
import fromPairs from 'lodash/fromPairs';
import assign from 'lodash/assign';
import reject from 'lodash/reject';
import { isBlank } from 'app/utils';
import template from 'ngapp/templates/views/teams/_csv_attribute_selector_modal.html';

var EMPTY_SHEET = { name: '', data: [] };
var EMPTY_COLUMNS = [
  { label: 'TEAMS.CSV_ATTRIBUTE_SELECTOR.EMAIL_LABEL', for: 'email', preview: '', empty: true, index: -1 },
  { label: 'TEAMS.CSV_ATTRIBUTE_SELECTOR.NAME_LABEL', for: 'name', preview: '', empty: true, index: -1 },
  { label: 'TEAMS.CSV_ATTRIBUTE_SELECTOR.POSITION_LABEL', for: 'position',  preview: '', empty: true, index: -1 },
  { label: 'TEAMS.CSV_ATTRIBUTE_SELECTOR.JERSEY_LABEL', for: 'jersey', preview: '', empty: true, index: -1 }
];

/**
 * @class TeamCSVAttributeSelectorModal
 *
 */
function Controller($scope) {
  $scope.sheet = EMPTY_SHEET;
  $scope.columns = EMPTY_COLUMNS;
  $scope.fields = {};
  $scope.errors = {};
  $scope.onSubmit = onSubmit;
  $scope.onCancel = onCancel;
  $scope.onSelectColumn = onSelectColumn;
  $scope.onClearColumn = onClearColumn;
  $scope.$props.$onChanges = $onChanges;

  function onCancel() {
    $scope.$props.modal.dismiss({});
  }

  function onSubmit() {
    $scope.errors = {};

    var email = find($scope.columns, { for: 'email' });

    if (email.empty) {
      $scope.errors.email = 'TEAMS.CSV_ATTRIBUTE_SELECTOR.EMAIL_REQUIRED_ERROR';
    } else {
      $scope.$props.modal.close({
        type: 'IMPORT_USERS',
        columns:serializeColumns()
      });
    }
  }

  function onClearColumn(column) {
    column.empty = true;
    column.preview = '';
    column.index = -1;
  }

  function onSelectColumn(column) {
    $scope.$props.modal.close({
      type: 'UPDATE_COLUMN',
      for: column.for,
      columns: serializeColumns()
    });
  }

  function serializeColumns() {
    var columns = map($scope.columns, function(c) {
      return [c.for, c.index];
    });

    return fromPairs(columns);
  }

  function $onChanges(props) {
    if (props.sheet != null) {
      $scope.sheet = props.sheet.currentValue || EMPTY_SHEET;
    }

    if (props.columns != null) {
      var columns = props.columns.currentValue || {};

      $scope.columns = map(EMPTY_COLUMNS, function(column) {
        var index = columns[column.for];

        if (index != null && index >= 0) {
          var data = map($scope.sheet.data, function (row) {
            return row[index];
          });

          return assign({}, column, { preview: reject(data, isBlank).join(', '), empty: false, index: index });
        }

        return column;
      });
    }
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    modal: '<',
    columns: '<',
    sheet: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
