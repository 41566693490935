
function factory($audio, $video) {
  function MediaService($audio, $video) {
    this._$audio = $audio;
    this._$video = $video;
  }

  MediaService.prototype.createPlayer = function createPlayer(resource, element, onStatusUpdate) {
    return this.$getService(resource.type).createPlayer(resource, element, this.$onPlay.bind(this), onStatusUpdate);
  };

  MediaService.prototype.destroyPlayer = function destroyPlayer(playerId, type) {
    return this.$getService(type).destroyPlayer(playerId);
  };

  MediaService.prototype.togglePlayer = function togglePlayer(playerId, type) {
    return this.$getService(type).togglePlayer(playerId);
  };

  MediaService.prototype.$getService = function $getService(type) {
    switch (type) {
      case 'video':
        return this._$video;
      case 'audio':
        return this._$audio;
      default:
        throw new Error('Cannot get media service for resource of type ' + type + '.');
    }
  };

  MediaService.prototype.$onPlay = function $onPlay(playerId) {
    this._$video.stopPlayers(playerId);
    this._$audio.stopPlayers(playerId);
  };

  return new MediaService($audio, $video);
}

factory.$inject = ['core.services.$audio', 'core.services.$video'];

export default factory;

