
export default function Video() {
  function link(scope, element, attrs) {
    attrs.$observe(['poster', 'width', 'height'], function() {
      element.mediaelementplayer();
    });
  }

  return {
    restrict: 'E',
    link: link
  };
}
