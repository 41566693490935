/**
 * @module Controllers
 *
 */

/**
 * @class AccountSettingsDropdownController
 *
 */
function Controller($scope, $root) {
  $scope.user = $root.user;

  $root.$on('userUpdated', (event, user) => {
    $scope.user = user;
  });
}

Controller.$inject = [
  '$scope',
  '$rootScope'
];

export default {
  name: 'AccountSettingsDropdownController',
  controller: Controller
};
