/**
 * @module Services
 *
 */
import isEqual from 'lodash/isEqual';
import { run } from 'app/utils/runloop';

/**
 * @class ListFilter
 *
 */
function ListFilter($timeout) {
  return function(scope, initialFilterParams, itemsName) {
    scope.filterCriteria = {};
    const splitItemsName = itemsName.split(",");
    let filterTimeout;

    function setInitialFilterParams() {
      angular.copy(initialFilterParams, scope.filterCriteria);
      run(() => scope.page = 1);
    }

    function filterList() {
      resetList();
      scope.loadList(resetList);
    }

    function resetList() {
      run(() => {
        scope.page = 1;
        splitItemsName.forEach(name => scope[name] = []);
        scope.allLoaded = false;
      });
    }

    function initFilter() {
      setInitialFilterParams();
      scope.$watch('filterCriteria', (newVal, oldVal) => {
        if (isEqual(newVal, oldVal)) { return; }
        if (filterTimeout) {
          $timeout.cancel(filterTimeout);
        }
        filterTimeout = $timeout(filterList, 300);
      }, true);
    }

    initFilter();

    scope.resetFilter = () => {
      setInitialFilterParams();
      resetList();
    };

    scope.isFiltered = () => {
      return !isEqual(initialFilterParams, scope.filterCriteria);
    };
  };
}

ListFilter.$inject = ['$timeout'];

export default ListFilter;
