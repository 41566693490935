
function factory($client) {
  function canPin(post) {
    return $client.hasPlanPermission(post, 'pin');
  }

  return {
    canPin: canPin
  };
}

factory.$inject = ['core.services.planFeature.$client'];

export default factory;
