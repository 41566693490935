/**
 * @module Directives.Activity
 *
 */
import template from 'ngapp/templates/views/activity/_incoming_invitation.html';

/**
 * @class IncomingInvitations
 *
 */
function Controller($scope, $translate) {
  $scope.invitation = {};
  $scope.formatSummary = formatSummary;
  $scope.formatAcceptButton = formatAcceptButton;
  $scope.onAcceptClick = onAcceptClick;
  $scope.onDeclineClick = onDeclineClick;
  $scope.$props.$onChanges = $onChanges;

  function formatSummary(invite) {
    if (invite.object === 'space_invitation') {
      return $translate.instant('ACTIVITY.INVITATION.SPACE', {
        inviter: invite.inviterNameShort,
        space: invite.spaceName,
        owner: invite.spaceAthleteName
      });
    }

    return $translate.instant('ACTIVITY.INVITATION.TEAM', { inviter: invite.inviterNameShort, team: invite.teamName });
  }

  function formatAcceptButton(invite) {
    if (invite.object === 'space_invitation') {
      return $translate.instant('ACTIVITY.INVITATION.JOIN_SPACE_BUTTON');
    }

    return $translate.instant('ACTIVITY.INVITATION.JOIN_TEAM_BUTTON');
  }

  function onAcceptClick() {
    $scope.$props.onAccept({ invitation: $scope.invitation });
  }

  function onDeclineClick() {
    $scope.$props.onDecline({ invitation: $scope.invitation });
  }

  function $onChanges(props) {
    if (props.invitation != null) {
      $scope.invitation = props.invitation.currentValue;
    }
  }
}

Controller.$inject = ['$scope', '$translate'];

export default {
  bindings: {
    invitation: '<',
    onAccept: '&',
    onDecline: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
