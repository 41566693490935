/**
 * @module Core.Services.Tracking
 *
 */

/**
 * @class $user
 *
 */
function factory($client) {
  function connectedOAuthProvider(provider) {
    let trackingProviderName = 'Unknown Provider';

    switch (provider) {
      case 'facebook':
        trackingProviderName = 'Facebook';
        break;
      default:
        throw new Error('Unknown integration provider "' + provider + '".');
    }

    $client.track('Connected ' + trackingProviderName);
  }

  return {
    connectedOAuthProvider: connectedOAuthProvider
  };
}

factory.$inject = ['core.services.tracking.$client'];

export default factory;
