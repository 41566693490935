/**
 * @module Directives.Account
 *
 */

/**
 * @class AccountModalServices
 *
 */
function AccountModal($modal) {
  function accountPhoneVerificationLink(user) {
    return $modal.open({
      template: '<account-phone-verification-modal user="user" modal="modal"></account-phone-verification-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
        $local.user = user;
      }]
    });
  }

  return {
    accountPhoneVerificationLink: accountPhoneVerificationLink
  };
}

AccountModal.inject = ['$modal'];

export default AccountModal;
