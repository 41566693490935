
import lodash from 'lodash/lodash';

function factory() {
  return lodash;
}

factory.$inject = [];

export default factory;
