/**
 * @module Directives.TeamsGroups
 *
 */

/**
 * @class GroupModalService
 *
 */
function GroupModalService($modal) {
  return {
    onboarding(group) {
      function controller($scope, $modalInstance) {
        $scope.group = group;
        $scope.modal = $modalInstance;
      }
      controller.$inject = [ '$scope', '$modalInstance' ];

      return $modal.open({
        controller,
        template: `
          <new-group-onboarding-modal
            modal="modal"
            group="group"
          ></new-group-onboarding-modal>
        `
      });
    },

    newGroup(teamId) {
      function controller($scope, $modalInstance) {
        $scope.teamId = teamId;
        $scope.modal = $modalInstance;
      }
      controller.$inject = [ '$scope', '$modalInstance' ];

      return $modal.open({
        controller,
        template: `
          <new-group-modal
            modal="modal"
            team-id="teamId"
          ></new-group-modal>
        `
      });
    },

    groupCreated(group) {
      function controller($scope, $modalInstance) {
        $scope.group = group;
        $scope.modal = $modalInstance;
      }
      controller.$inject = [ '$scope', '$modalInstance' ];

      return $modal.open({
        controller,
        template: `
          <group-created-modal
            modal="modal"
            group="group"
          ></group-created-modal>
        `
      });
    },

    groupSettings(group) {
      function controller($scope, $modalInstance) {
        $scope.group = group;
        $scope.modal = $modalInstance;
      }
      controller.$inject = [ '$scope', '$modalInstance' ];

      return $modal.open({
        controller,
        template: `
          <group-settings-modal
            modal="modal"
            group="group"
          ></group-settings-modal>
        `
      });
    },

    bulkMemberAddProgress(job) {
      function controller($scope, $modalInstance) {
        $scope.modal = $modalInstance;
        $scope.$on('$destroy', () => job.cancel());
      }
      controller.$inject = [ '$scope', '$modalInstance' ];

      return $modal.open({
        controller,
        keyboard: false,
        template: `
          <group-bulk-member-add-progress-modal
            modal="modal"
          ></group-bulk-member-add-progress-modal>
        `
      });
    }
  };
}

GroupModalService.inject = ['$modal'];

export default GroupModalService;
