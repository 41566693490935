/**
 * @module Directives.TeamsGroups
 *
 */

/**
 * @class NewGroupFlow
 *
 */
function factory($location, $translate, toast, addGroupMembersFlow, groupModal, tracking, TeamGroup) {
  function NewGroupFlow(team) {
    this._team = team;
  }

  NewGroupFlow.prototype.start = function start() {
    return this._createGroup(this._team)
      .then(e => {
        $location.path(e.group.timelineUrl);
        if (e.group.groupType === 'auto_join') {
          return this._addAllTeamMembers(e.group).then(() => {
            toast.success($translate.instant('TEAM_CHANNELS.NEW_CHANNEL_FLOW.ALL_TEAM_MEMBERS_ADDED_STATUS'));
            return e;
          });
        } else {
          return this._groupCreated(e.group);
        }
      })
      .then(e => {
        if (e.addAllMembers) {
          return this._addAllTeamMembers(e.group).then(() => {
            $location.path(e.group.membersUrl);
            toast.success($translate.instant('TEAM_CHANNELS.NEW_CHANNEL_FLOW.ALL_TEAM_MEMBERS_ADDED_STATUS'));
            return e;
          });
        }

        if (e.group.groupType !== 'auto_join') {
          return this._addGroupMembers(e);
        } else {
          return e;
        }
      })
      .then(e => ({ teamId: this._team.id, group: e.group }));
  };

  NewGroupFlow.prototype._createGroup = function _createGroup(team) {
    return groupModal.newGroup(team.id).result.then(e => {
      tracking.teamGroups.created(e.group, team);
      $location.path(e.group.timelineUrl);
      return e;
    });
  };

  NewGroupFlow.prototype._groupCreated = function _groupCreated(group) {
    return groupModal.groupCreated(group).result;
  };

  NewGroupFlow.prototype._addGroupMembers = function _addGroupMembers(e) {
    return addGroupMembersFlow(e.group).then(() => {
      $location.path(e.group.membersUrl);
      return e;
    });
  };

  NewGroupFlow.prototype._addAllTeamMembers = function _addAllTeamMembers(group) {
    const job = TeamGroup.addAllTeamMembers(group);
    const modal = groupModal.bulkMemberAddProgress(job);
    return job.result
      .then(() => (modal.close(), group));
  };

  return function newGroupFlow(team) {
    return new NewGroupFlow(team).start();
  };
}

factory.$inject = [
  '$location',
  '$translate',
  'core.ui.toast',
  'addGroupMembersFlow',
  'groupModal',
  'core.services.tracking',
  'core.services.TeamGroup'
];

export default factory;

