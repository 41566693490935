/**
 * @module Services.Forms
 *
 */
import map from 'lodash/map';
import without from 'lodash/without';
import { capitalize } from 'app/utils/strings';

const USER_TYPES = ['athletes', 'contributors', 'coaches'];

/**
 * @class SpaceForm
 *
 */
function SpaceForm($, $q, SpaceImport, TeamSpace, FormErrors, Auth, identity, spaceModal) {
  return function($scope) {
    this.formErrors = new FormErrors(this);

    this.addAthlete = (user) => this.addUser('athletes', user);
    this.addContributor = (user) => this.addUser('contributors', user);
    this.addCoach = (user) => this.addUser('coaches', user);

    $scope.$watch('form.spaceTemplate', (template, oldTemplate) => {
      if ((template == oldTemplate) || (!template)) { return; }
      this.coaches = template.members;
    });

    identity.request().then(user => this.currentUser = user);

    this.createFor = 1;
    this.isForOtherAthlete = (() => this.createFor == 0);
    this.isForSelf = (() => this.createFor == 1);
    this.isForTeam = (() => this.createFor == 2);

    this.all = [];
    this.athletes = [];
    this.coaches = [];
    this.contributors = [];

    this.athleteErrors = {};
    this.sport = {};
    this.teamId = {};
    this.otherSport = {};

    this.spaceTemplate = {};
    this.segmentIds = [];

    this.onNewUserClick = (type, value) => {
      return spaceModal.invite(this.all, value).result.then(user => {
        this.addUser(type, user);
        return user;
      });
    };

    this.onSegmentSelected = (selected) => {
      this.segmentIds = map(selected || [], segment => segment.id);
    };

    this.setUserList = (type, list) => {
      this[type] = list;
      this.all = [].concat(...USER_TYPES.map(key => this[key]));
    };

    this.addUser = (type, user) => {
      this[type].push(user);
      this.all.push(user);
    };

    this.removeUser = (type, user) => {
      this[type] = without(this[type], user);
      this.all = [].concat(...USER_TYPES.map(key => this[key]));
    };

    this.resetErrors = () => {
      this.formErrors.reset();
      this.invalid = false;
    };

    this.save = (successHandler) => {
      if (this.isForTeam()) {
        return saveTeamSpace(successHandler);
      }
      return saveAthleteSpace(successHandler);
    };

    const saveAthleteSpace = (successHandler) => {
      const spaceImport = new SpaceImport();
      this.saving = true;
      this.successHandler = successHandler;
      this.resetErrors();

      spaceImport.sport = this.sport.value;
      if(this.spaceTemplate && this.spaceTemplate.id) {
        spaceImport.spaceTemplateId = this.spaceTemplate.id;
      }
      spaceImport.sportOther = this.otherSport.value;
      spaceImport.athletes = usersFieldsForSubmit(this.athletes);
      spaceImport.createForSelf = this.isForSelf();
      spaceImport.segmentIds = this.segmentIds;
      spaceImport.coaches = usersFieldsForSubmit(this.coaches);
      spaceImport.contributors = usersFieldsForSubmit(this.contributors);

      return spaceImport.save()
        .then(successfulSpaceSave)
        .catch(failingSpaceSave);
    };

    const saveTeamSpace = (successHandler) => {
      const teamSpace = new TeamSpace();
      this.successHandler = successHandler;
      this.resetErrors();

      teamSpace.teamId = this.teamId.value;
      if(!teamSpace.teamId) {
        this.invalid = true;
        handleValidationErrors({ teamId: ['Group is required'] });
        return $q.reject({ teamId: ['Group is required'] });
      }

      this.saving = true;

      if(otherSportSelected()) {
        teamSpace.sport = this.otherSport.value;
      } else {
        teamSpace.sport = this.sport.value;
      }
      teamSpace.spaceTemplateId = this.spaceTemplate.id;
      teamSpace.segmentIds = this.segmentIds;

      return teamSpace.save()
        .then(successfulSpaceSave)
        .catch(failingSpaceSave);
    };

    const otherSportSelected = () => this.sport.value === 'Custom';

    const successfulSpaceSave = (data) => {
      this.saving = false;
      if (this.successHandler) {
        this.successHandler(data);
        Auth.updateCurrentUser();
      }
    };

    const failingSpaceSave = (response) => {
      if (response.status === 422) {
        this.invalid = true;
        if(response.data) {
          if (response.data.errors) {
            if (response.data.errors.athletes) {
              handleUserErrors('athletes', response.data.errors.athletes);
            }

            if (response.data.errors.coaches) {
              handleUserErrors('coaches', response.data.errors.coaches);
            }

            handleValidationErrors(response.data.errors);
          } else {
            handleValidationErrors(response.data);
          }
        } else {
          handleValidationErrors(response);
        }
      }
      this.saving = false;
    };

    const handleValidationErrors = (data) => this.formErrors.apply(data);

    const handleUserErrors = (type, list) => {
      const container = $(`.${type}-selector > .${type}`);
      let elements;
      if (container && container.length) {
        elements = container.find('li');
      }

      if (elements && elements.length) {
        list.forEach(item => {
          const el = $(elements[item.index]);
          const curErrs = el.find('.user-select-error');
          if (curErrs && curErrs.length) {
            curErrs.remove();
            el.removeClass('error');
          }

          const errorKey = Object.keys(item.errors)[0];
          const error = item.errors[errorKey];
          el.addClass('error');
          el.append(
            $(`<span class="helper-text error js-form-error user-select-error">${capitalize(errorKey)} ${error}</span>`)
          );
        });
      }
    };

    const usersFieldsForSubmit = (users) => {
      return map(users, user => ({
        phone: user.phone,
        phone_country: user.phoneCountry,
        email: user.email,
        name: user.name,
        user_id: (user.id || user._id)
      }));
    };

    this.validate = () => {
      this.resetErrors();
      let success = true;
      if (!this.sport.value) {
        handleValidationErrors({ sport: ['required'] });
        success = false;
      }
      if (this.isForOtherAthlete() && this.athletes.length === 0) {
        handleValidationErrors({ athleteErrors: ['required'] });
        success = false;
      }
      return success;
    };
  };
}

SpaceForm.$inject = [
  '$',
  '$q',
  'SpaceImport',
  'TeamSpace',
  'FormErrors',
  'Auth',
  'core.services.identity',
  'spaceModal'
];

export default SpaceForm;
