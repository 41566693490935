
import assign from 'lodash/assign';

export default function(__angular) {
  __angular.module('app').run(bootstrap);

  function bootstrap($window, diagnostics) {
    if ($window.Edufii == null) {
      $window.Edufii = {};
    }

    assign($window.Edufii, {
      submitDiagnostics: diagnostics.submitDiagnostics
    });
  }

  bootstrap.$inject = ['$window', 'diagnostics'];
}
