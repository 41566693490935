/**
 * @module Directives
 *
 */
import template from 'ngapp/templates/directives/_null_result.html';

/**
 * @class NullResult
 *
 */
export default function nullResult() {
  return {
    restrict: 'E',
    scope: {
      banner: '<',
      hero: '@',
      title: '@',
      summary: '@'
    },
    replace: true,
    template
  };
}
