
export default function factory() {
  var MS_IN_MONTH = 2629746000;
  var MS_IN_DAY = 86400000;
  var MS_IN_HOUR = 3600000;
  var MS_IN_MINUTE = 60000;
  var MS_IN_SECOND = 1000;

  function isDate(value) {
    return value instanceof Date;
  }

  function ms(value) {
    if (typeof value === 'string') {
      return Date.parse(value);
    } else if (typeof value === 'number') {
      return value;
    } else if (isDate(value)) {
      return value.getTime();
    }

    return 0;
  }

  /**
    * Calculates timespan.
    *
    * @param {*} from
    * @param {*} to
    * @returns {object}
    */
  function span(from, to) {
    var delta =  Math.abs(ms(to) - ms(from));
    var result = {
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      mili: 0
    };

    result.months = Math.floor(delta / MS_IN_MONTH);
    delta -= result.months * MS_IN_MONTH;

    result.days = Math.floor(delta / MS_IN_DAY);
    delta -= result.days * MS_IN_DAY;

    result.hours = Math.floor(delta / MS_IN_HOUR);
    delta -= result.hours * MS_IN_HOUR;

    result.minutes = Math.floor(delta / MS_IN_MINUTE);
    delta -= result.minutes * MS_IN_MINUTE;

    result.seconds = Math.floor(delta / MS_IN_SECOND);
    delta -= result.seconds * MS_IN_SECOND;

    result.mili = delta;

    return result;
  }

  /**
    * Truncate a dates time to 00:00:00
    *
    * @param {*} value
    * @returns {Date}
    */
  function truncate(value) {
    var date = new Date(ms(value));
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
  }

  /**
    * Rounds a date up to the given field before roll over.
    *
    * @param {date} date
    * @param {string} field
    * @returns {date}
    */
  function ceil(date, field) {
    if (date == null) {
      return new Date(8640000000000000);
    }

    switch (field) {
      case 'year':
        return new Date(date.getFullYear() + 1, 0, 0, 24, 0, 0, -1);
      case 'month':
        return new Date(date.getFullYear(), date.getMonth() + 1, 0, 24, 0, 0, -1);
      case 'day':
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0, -1);
      case 'hour':
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours() + 1, 0, 0, -1);
      case 'minute':
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes() + 1, 0, -1);
      case 'seconds':
        return new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds() + 1,
          -1
        );
    }

    return date;
  }

  return {
    span: span,
    truncate: truncate,
    ceil: ceil
  };
}
