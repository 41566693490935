/** @module directives/teams */
import mail_success_icon_png from 'app/images/mail-success-icon.png';
import template from 'ngapp/templates/views/teams/_member_import_success_modal.html';

/**
 * @class TeamMemberImportSuccessModal
 *
 */
function Controller($scope) {
  $scope.sent = 0;
  $scope.onDoneClicked = onDoneClicked;
  $scope.onAddMoreChannels = onAddMoreChannels;
  $scope.$props.$onChanges = $onChanges;
  $scope.mail_success_icon_png = mail_success_icon_png;

  function onDoneClicked() {
    $scope.$props.modal.close();
  }

  function onAddMoreChannels() {
    $scope.$props.modal.close({ addMoreChannels: true });
  }

  function $onChanges(props) {
    if (props.status != null) {
      $scope.sent = props.status.currentValue.loaded;
    }
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    modal: '<',
    status: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
