/**
 * @module Controllers.Spaces
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/spaces/_invitation_edit.html';

/**
 * @class SpaceInvitationEdit
 *
 */
function Controller($scope, $translate, Space, SpaceInvitation, toast, $routeParams, $location) {
  $scope.invitation = {};
  $scope.processing = false;
  $scope.inviteLoaded = false;
  $scope.spaceId = $routeParams.spaceId;
  $scope.spaceInvitationId = $routeParams.invitationId;
  $scope.roles = SpaceInvitation.rolesWithoutAthlete;

  Space.get($scope.spaceId, {'options[include]': ['invitations_info', 'user_settings']})
    .then(space => run(() => $scope.space = space));

  SpaceInvitation.get({id: $scope.spaceInvitationId, spaceId: $scope.spaceId })
    .then((invitation) => {
      $scope.invitation = new SpaceInvitation(invitation);
      $scope.inviteLoaded = true;
    });

  $scope.update = function() {
    if ($scope.invitation.planCanManage()) {
      $scope.processing = true;
      $scope.invitation.update()
        .then(() => {
          toast.success($translate.instant('CONTROLLERS.SPACE_INVITATION_EDIT.UPDATE_SUCCESS_MESSAGE'));
          $location.path('/spaces/' + $scope.spaceId + '/members');
        })
        .catch((response) => run(() => $scope.errors = response.data.errors))
        .finally(() => run(() => $scope.processing = false));
    }
  };

  $scope.onPhoneInputChange = function(value, data) {
    const phone = value.substring(data.dialCode.length);
    const phoneCountry = data.countryCode.toUpperCase();

    $scope.invitation.phone = phone;
    $scope.invitation.phoneCountry = phoneCountry;
  };
}

Controller.$inject = [
  '$scope',
  '$translate',
  'Space',
  'SpaceInvitation',
  'core.ui.toast',
  '$routeParams',
  '$location'
];

export default {
  controller: Controller,
  template
};
