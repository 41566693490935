
function animation($css) {
  // function px(value) {
  //   return value + 'px';
  // }

  function enter(element, done) {
    return $css(element, {
      from: { transform: 'translateX(120%)' },
      to: { transform: 'translateX(120%)' },
      duration: 0.1
    })
    .start()
    .then(function() {
      $css(element, {
        from: { opacity: 0, transform: 'translateX(120%)' },
        to: { opacity: 1, transform: 'translateX(0)' },
        duration: 0.3,
        easing: 'ease-out'
      }).start();
    })
    .then(done);
  }

  function leave(element, done) {
    return $css(element, {
      from: { opacity: 1, transform: 'translateX(0)' },
      to: { opacity: 0, transform: 'translateX(120%)' },
      duration: 0.3,
      easing: 'ease-out'
    })
    .start()
    .then(done);
  }

  return { enter: enter, leave: leave };
}

animation.$inject = ['$animateCss'];

export default animation;
