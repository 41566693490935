/** @module legacy/app */
import { isAuthenticated } from 'app/utils/auth';
import { AnalyticsService } from 'app/services';
import { run } from 'app/utils/runloop';
import { getUnseen, markAllSeen } from 'app/actions/Notification';
import { ngState } from 'app/utils/bridge';
import RSVP from 'rsvp';
import preloadTemplates from 'ngapp/templates';
import settings from 'config/environment';

const APP_INIT = [
  'ngRoute', 'ngAnimate', 'ngSanitize', 'ngResource', 'services', 'controllers',
  'directives', '$strap.directives', 'ui.select', 'ngTouch', 'filters', 'interceptors',
  'monospaced.elastic', 'ui-select-infinity', 'pascalprecht.translate', 'core', 'react' // add react to angular
];

const APP_INJECT = [
  '$q', '$window', '$location', '$rootScope', '$routeParams','$translate',
  'core.services.identity', 'policyModal', 'paywallModal',
  'core.services.tracking', 'core.ui.toast', '$templateCache'
];

const POLL_TIMER = 10000; // 60 seconds
const POST_VIEWS_POLL_INTERVAL = 5000; // 5 seconds

/**
 * @class Application
 *
 */
export default function(__angular) {
  __angular.module('services', ['rails']);
  __angular.module('controllers', []);
  __angular.module('interceptors', []);
  __angular.module('directives', ['ui.sortable', 'infinite-scroll']);
  __angular.module('filters', []);
  __angular.module('infinite-scroll').value('THROTTLE_MILLISECONDS', 250);

  __angular.module('app', APP_INIT)
    .run([ ...APP_INJECT, Application ]);

  function Application(
    $q,
    $window,
    $location,
    $root,
    $params,
    $translate,
    identity,
    policyModal,
    paywallModal,
    tracking,
    toast,
    $templateCache
  ) {
    // preload templates into template cache
    preloadTemplates($templateCache);

    $root.totalNotifs = 0;
    $root.pending = true;
    $root.settings = settings;
    $root.currentLocale = 'en';
    $root.defaultTranslationData = {
      supportEmail: settings.SUPPORT_EMAIL,
      appName: 'CoachNow',
      basePlanName: 'CoachNow+'
    };

    const [state, setState] = ngState();
    setState({ ...state, root: $root, identity, qProvider: $q });

    /**
     * checks for notifications in POLL_TIMER intervals
     *
     * @method pollNotifications
     */
    const pollNotifications = () => {
      return getUnseen().then((counts) => run(() => {
        if ($root.notificationCounts == null) {
          // initial load broadcast count as prev count
          $root.$broadcast('onNotification', counts, counts);
          $root.notificationCounts = counts;
        } else if ($root.notificationCounts.total !== counts.total) {
          // updated notifs
          $root.$broadcast('onNotification', counts, $root.notificationCounts);
          $root.notificationCounts = counts;
        }
        setTimeout(() => pollNotifications(), POLL_TIMER);
      }));
    };

    const pollPostViews = () => {
      return new Promise(() => {
        tracking.postViews.sendAndClearPostViews();
        setTimeout(() => pollPostViews(), POST_VIEWS_POLL_INTERVAL);
      });
    };

    $root.markAllNotificationsSeen = () => {
      markAllSeen().then(() => run(() => {
        const prevNotifs = $root.notificationCounts;
        $root.notificationCounts = {
          notifications: 0,
          invitations: prevNotifs.invitations,
          total: prevNotifs.invitations
        };
        // broadcast count as prev count so no fetch will be triggered
        $root.$broadcast('onNotification', $root.notificationCounts, $root.notificationCounts);
      }));
    };

    const loadAppData = () => {
      const identifyPromise = identity.request();

      RSVP.all([identifyPromise, $translate.use($root.currentLocale)])
        .then(() =>
          run(() => {
            if (!($root.user && $root.user.ppTosCurrent)) {
              policyModal.show();
            }

            if ($params.trialStarted) {
              toast.success($translate.instant('PAYWALLS.TRIAL_STARTED'));
            }

            if ($params.firstUse) {
              // handle first use here
            }

            // start polling for notification updates
            pollNotifications();
            pollPostViews();
          })
        )
        .finally(() => run(() => $root.pending = false));
    };

    if (isAuthenticated()) {
      loadAppData();
    } else {
      $translate.use($root.currentLocale).then(() => {
        $root.pending = false;
      });
    }

    $root.$on('upgradePlus', () => {
      paywallModal.explicit();
    });

    // route change event on $rootScope
    $root.$on('$routeChangeStart', () => {
      AnalyticsService.routeChangeStarted();
      AnalyticsService.page();
    });

    $root.$on('$routeChangeSuccess', () => {
      $window.dispatchEvent(new CustomEvent('navigate', { detail: $location.path() }));
      AnalyticsService.routeChangeDone();
    });
  }
}


function CustomEvent(type, options) {
  if (options == null) {
    options = {
      bubbles: false,
      cancelable: false,
      detail: undefined
    };
  }

  var event = document.createEvent('CustomEvent');
  event.initCustomEvent(type, options.bubbles, options.cancelable, options.detail);

  return event;
}

CustomEvent.prototype = window.Event.prototype;
