/**
 * @module Controllers.SpaceMemberships
 *
 */
import { run } from 'app/utils/runloop';
import { AuthService } from 'app/services';
import { fetchDemoSpaceAction } from 'app/actions/DemoSpace';
import template from 'ngapp/templates/views/space_memberships/_demo_list.html';

/**
 * @class SpaceMemberships
 *
 */
function Controller($scope, $params) {
  $scope.demoSpace = null;
  $scope.space = null;
  $scope.members = [];
  $scope.pending = true;
  $scope.filterName = '';
  $scope.hasData = () => $scope.members.length > 0;
  $scope.isFiltered = () => $scope.filterName.length > 0;

  if (AuthService.isAuthenticated) {
    AuthService.getAuthUser().then(user => run(() => {
      $scope.currentUser = user;
    }));
  }

  const setData = (state) => run(() => {
    if (state != null && state.data != null) {
      const data = state.data.get($params.spaceId);
      $scope.demoSpace = data;
      $scope.space = data.space;
      $scope.members = data.members;
      $scope.pending = state.pending;
    }
  });

  $scope.actions = { demoSpace: () => fetchDemoSpaceAction($params.spaceId) };
  $scope.onStateChange = ({ demoSpace }) => setData(demoSpace);

  $scope.onFilterChange = (filterName) => {
    $scope.filterName = filterName;
    if ($scope.demoSpace != null && $scope.demoSpace.members != null && $scope.demoSpace.members.length) {
      if (filterName && filterName.length) {
        const reg = new RegExp(filterName, 'i');
        $scope.members = $scope.demoSpace.members.filter(model => {
          return reg.test(model.name);
        });
      } else {
        $scope.members = $scope.demoSpace.members;
      }
    }
  };


  $scope.canChangeAthlete = (member) => {
    return ($scope.space.id && member.canBecomeAthlete() && $scope.space.can.makeAthlete);
  };

  $scope.isSpaceAthlete = (user) => {
    return ($scope.space.id && $scope.space.isForAthlete && $scope.space.athleteId === user.id);
  };

  $scope.isSpaceCoach = (user) => {
    return (user.spaceRole === 'coach');
  };

  $scope.isTeamOwner = (user) => {
    return ($scope.space.id && $scope.space.isForTeam && $scope.space.team.billingUserId === user.id);
  };

  $scope.isTeamManager = (user) => {
    return ($scope.space.id && $scope.space.isForTeam && user.teamRole === 'admin');
  };

  $scope.makeAthlete = (member) => {
    return member;
  };

  $scope.removeMember = (member) => {
    return member;
  };

  $scope.leave = (member) => {
    return member;
  };
}

Controller.$inject = [
  '$scope',
  '$routeParams',
];

export default {
  controller: Controller,
  template
};
