/**
 * @module Core.Services.Tracking
 *
 */
import get from 'lodash/get';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

/**
 * @class $team
 *
 */
function factory($client, $post) {
  function extendEventData(event, team) {
    const teamData = {
      'Team Id': team.id,
      'Team Name': team.name,
      'Team Sport': team.sport
    };

    return Object.assign({}, event, teamData);
  }

  function resolveMemberFilter(filters) {
    if (filters != null && filters.roles != null) {
      // TODO:
      // replace keys with Object.keys
      const allRoles = keys(filters.roles);
      const selectedRoles = keys(pickBy(filters.roles, identity));
      const hasQuery = filters.query != null && filters.query.length > 0;
      const hasRoleFilter = allRoles.length != selectedRoles.length;

      if (hasQuery || hasRoleFilter) {
        return {
          'Has Query': hasQuery,
          'Has Role Filter': hasRoleFilter,
          'Roles': hasRoleFilter ? selectedRoles : null
        };
      }
    }
    return null;
  }

  function teamSettingsEventData(team) {
    const settingsData = {
      'Email Notifications Enabled': get(team.userSettings, 'emailNotificationsEnabled', false),
      'Has Logo': team.logo != null && team.logo.indexOf('default') < 0,
      'Push Notifications Enabled': get(team.userSettings, 'pushNotificationsEnabled', false)
    };
    return extendEventData(settingsData, team);
  }

  function created(team) {
    $client.track('Team Created', teamSettingsEventData(team));
  }

  function deleted(team) {
    $client.track('Team Deleted', extendEventData({}, team));
  }

  function memberDeleted(team, deleteSource, role) {
    const deleteData = {
      'Role': role,
      'Source': deleteSource
    };
    $client.track('Team Member Deleted', extendEventData(deleteData, team));
  }

  function memberInvited(team, inviteSource, role) {
    const inviteData = {
      'Role': role,
      'Source': inviteSource
    };
    $client.track('Team Member Invited', extendEventData(inviteData, team));
  }

  function membersFiltered(team, filters) {
    const resolvedFilters = resolveMemberFilter(filters);
    if (resolvedFilters != null) {
      $client.track('Team Members Filtered', extendEventData(resolvedFilters, team));
    }
  }

  function postCreated(team, post) {
    $client.track('Team Post Created', extendEventData($post.extendEventData({}, post), team));
  }

  function postDeleted(team, post) {
    $client.track('Team Post Deleted', extendEventData($post.extendEventData({}, post), team));
  }

  function postEdited(team, post) {
    $client.track('Team Post Edited', extendEventData($post.extendEventData({}, post), team));
  }

  function postLiked(team, post) {
    const eventName = post.liked ? 'Team Post Liked' : 'Team Post Unliked';
    $client.track(eventName, extendEventData($post.extendEventData({}, post), team));
  }

  function postReported(team, post) {
    $client.track('Team Post Reported', extendEventData($post.extendEventData({}, post), team));
  }

  function updated(team) {
    $client.track('Team Updated', teamSettingsEventData(team));
  }

  return {
    created: created,
    deleted: deleted,
    extendEventData: extendEventData,
    memberDeleted: memberDeleted,
    memberInvited: memberInvited,
    membersFiltered: membersFiltered,
    postCreated: postCreated,
    postDeleted: postDeleted,
    postEdited: postEdited,
    postLiked: postLiked,
    postReported: postReported,
    resolveMemberFilter: resolveMemberFilter,
    updated: updated
  };
}

factory.$inject = ['core.services.tracking.$client', 'core.services.tracking.$post'];

export default factory;

