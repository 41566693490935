/**
 * @module Controllers.Spaces
 *
 */
import template from 'ngapp/templates/views/spaces/_spaces.html';

/**
 * @class Spaces
 *
 */
function Controller($scope, $location, $routeParams) {
  $scope.jacksjourney = $routeParams.jacksjourney === 'true';
  $scope.activeTab = $location.$$path === '/spaces/demo' ? 1 : 0;
}

Controller.$inject = [
  '$scope',
  '$location',
  '$routeParams'
];

export default {
  controller: Controller,
  template
};

