/**
 * @module Core.Vendor
 *
 */
import { FacebookService } from 'app/services';

/**
 * @class Facebook
 *
 */
function factory() {
  return FacebookService;
}

factory.$inject = [];

export default factory;
