/**
 * @module Directives
 *
 */
import template from 'ngapp/templates/directives/_coach_plus_upgrade_alert.html';

/**
 * @class CoachPlusUpgradeAlert
 *
 */
function CoachPlusUpgradeAlert($rootScope) {
  function link(scope) {
    var upgrade = $rootScope.user.upgrade;

    if (upgrade) {
      scope.actions = upgrade.actions;
      scope.body = upgrade.body.replace(/%ACTION%/, scope.actionText);
    }
  }

  return {
    restrict: 'E',
    template,
    scope: {
      actionText: '='
    },
    link: link
  };
}

CoachPlusUpgradeAlert.$inject = ['$rootScope'];

export default CoachPlusUpgradeAlert;
