/**
 * @module Angular.Directives
 *
 */
import accountWrap from './account/account_wrap';
import AccountModal from './account/account_modal_service';
import accountPhoneVerificationModal from './account/account_phone_verification_modal';
import ActiveReply from './active-reply';
import ActiveWhen from './active-when';
import activityWrap from './activity/activity_wrap';
import activityDropdown from './activity/dropdown';
import incomingInvitation from './activity/incoming_invitation';
import incomingInvitationList from './activity/incoming_invitation_list';
import invitationSummary from './activity/invitation_summary';
import outgoingInvitation from './activity/outgoing_invitation';
import outgoingInvitationList from './activity/outgoing_invitation_list';
import notification from './activity/notification';
import notificationList from './activity/notification_list';
import notificationActivitySummary from './activity/notification_summary';
import AppPrompt from './app_promt';
import AudioPlayer from './audio-player';
import Audio from './audio';
import avatarUploadButton from './avatar_upload_button';
import BulkPostHelpers from './bulk_post/bulk_post_helpers';
import BulkPostLocationFactory from './bulk_post/bulk_post_location_factory';
import bulkPostSelector from './bulk_post/bulk_post_selector';
import $bulkPostLocation from './bulk_post/locations/$bulk_post_location';
import groupLocation from './bulk_post/locations/group_location';
import segmentLocation from './bulk_post/locations/segment_location';
import spaceLocation from './bulk_post/locations/space_location';
import smartListLocation from './bulk_post/locations/smart_lists_location';
import teamLocation from './bulk_post/locations/team_location';
import CoachPlusUpgradeAlert from './coach_plus_upgrade_alert';
import { NgValidate, NgPreventDefault, StopEvent, OnEnterKey, FocusMe } from './common_directives';
import Datafile from './datafile';
import EdufiiImage from './edufii_image';
import Emojify from './emojify';
import filters from './feed_filters/filters';
import monthPicker from './feed_filters/month_picker';
import yearPicker from './feed_filters/year_picker';
import filePicker from './file_picker';
import Focus from './focus';
import FormErrors from './form_errors';
import FormatText from './format_text';
import HoverClass from './hover_class';
import IntlTel from './intl_tel';
import InvitationModal from './invitations/invitation_modal_service';
import phoneInvitationCopyLinkModal from './invitations/phone_invitation_copy_link_modal';
import spaceCreatedPhoneInvitationCopyLinkModal from './invitations/space_created_phone_invitation_copy_link_modal';
import mediaButton from './library/media_button';
import mediaButtonLocked from './library/media_button_locked';
import mediaHelpers from './library/media_helpers';
import mediaModal from './library/media_modal';
import mediaPicker from './library/media_picker';
import LinkTo from './link_to';
import loading from './loading';
import Localtime from './localtime';
import NameWithEmailInput from './name_with_email_input';
import NgMultiple from './ng-multiple';
import NgReallyClick from './ng-really';
import NullResult from './null_result';
import pager from './pager';
import panelLoader from './panel_loader';
import paywallsExplicitModal from './paywalls/explicit_modal';
import paywallsLibraryModal from './paywalls/library_modal';
import paywallsModalBody from './paywalls/modal_body';
import paywallsModalFooter from './paywalls/modal_footer';
import paywallsModalHeader from './paywalls/modal_header';
import PaywallModal from './paywalls/paywall_modal_service';
import paywallsPostMediaModal from './paywalls/post_media_modal';
import paywallsPostMediaOtherOwnerModal from './paywalls/post_media_other_owner_modal';
import paywallsSegmentsModal from './paywalls/segments_modal';
import paywallsSpaceLimitModal from './paywalls/space_limit_modal';
import paywallsSpaceLimitReadOnlyModal from './paywalls/space_limit_read_only';
import paywallsTemplatesModal from './paywalls/templates_modal';
import paywallsPinnedPostsModal from './paywalls/pinned_posts_modal';
import paywallsProUpgradeModal from './paywalls/pro_upgrade_modal';
import PostHelpers from './post_helpers';
import PostingForm from './posting_form';
import Readmore from './readmore';
import RepliesPagerFactory from './replies_pager_factory';
import ResourceTags from './resource_tags';
import Scrollinside from './scrollinside';
import newSegmentModal from './segments/new_segment_modal';
import SegmentModalService from './segments/segment_modal_service';
import segmentsList from './segments_list';
import SelectedUser from './selected_user';
import selectedUsers from './selected_users';
import ServerValidation from './server_validation';
import simpleTypeahead from './simple_typeahead';
import siteWrap from './site_wrap';
import publicWrap from './public_wrap';
import newTemplateModal from './space_templates/new_space_template_modal';
import SpaceTemplateModal from './space_templates/space_template_modal_service';
import inviteSpaceUserModal from './spaces/invite_space_user_modal';
import newSpaceButton from './spaces/new_space_button';
import SpaceFeedPagerFactory from './spaces/space_feed_pager_factory';
import spaceHeader from './spaces/space_header';
import SpaceModal from './spaces/space_modal_service';
import spaceNav from './spaces/space_nav';
import spaceNotificationSettingsModal from './spaces/space_notification_settings_modal';
import spaceSettingsMenu from './spaces/space_settings_menu';
import spaceSettingsModal from './spaces/space_settings_modal';
import transferSpaceCompleteModal from './spaces/transfer_space_complete_modal';
import TransferSpaceFlow from './spaces/transfer_space_flow';
import transferSpaceModal from './spaces/transfer_space_modal';
import TransferSpaceModal from './spaces/transfer_space_modal_service';
import spacesList from './spaces/spaces_list';
import Sticky from './sticky';
import TagIt from './tag_it';
import TeamInvitation from './team_invitation';
import teamsLoader from './teams';
import teamsGroupsLoader from './teams_groups';
import togglePinModal from './posts/toggle_pin_modal';
import togglePinNotAllowedModal from './posts/toggle_pin_not_allowed_modal';
import TogglePinModalService from './posts/toggle_pin_modal_service';
import scheduledPostModal from './posts/scheduled_post_modal';
import ScheduledPostModal from './posts/scheduled_post_modal_service';
import ScheduleFeedPagerFactory from './posts/schedule_feed_pager_factory';
import Transcope from './transcope';
import UpgradeModalService from './upgrade_modal/upgrade_modal_service';
import upgradeOptionsModal from './upgrade_modal/upgrade_options_modal';
import uploadButton from './upload/button';
import uploadModal from './upload/modal';
import uploadDropzone from './upload/pages/dropzone';
import uploadForm from './upload/pages/form';
import uploadProgress from './upload/pages/progress';
import ValidateEmail from './validate_email';
import VideoPlayer from './video-player';
import Video from './video';

export default function(__angular) {
  teamsLoader(__angular);
  teamsGroupsLoader(__angular);

  // components
  __angular.module('directives')
    .component('accountWrap', accountWrap)
    .component('accountPhoneVerificationModal', accountPhoneVerificationModal)
    .component('activityWrap', activityWrap)
    .component('activityDropdown', activityDropdown)
    .component('incomingInvitation', incomingInvitation)
    .component('incomingInvitationList', incomingInvitationList)
    .component('invitationActivitySummary', invitationSummary)
    .component('outgoingInvitation', outgoingInvitation)
    .component('outgoingInvitationList', outgoingInvitationList)
    .component('notification', notification)
    .component('notificationList', notificationList)
    .component('notificationActivitySummary', notificationActivitySummary)
    .component('avatarUploadButton', avatarUploadButton)
    .component('bulkPostSelector', bulkPostSelector)
    .component('filters', filters)
    .component('monthPicker', monthPicker)
    .component('yearPicker', yearPicker)
    .component('filePicker', filePicker)
    .component('mediaButton', mediaButton)
    .component('mediaButtonLocked', mediaButtonLocked)
    .component('mediaModal', mediaModal)
    .component('mediaPicker', mediaPicker)
    .component('loading', loading)
    .component('pager', pager)
    .component('panelLoader', panelLoader)
    .component('paywallsExplicitModal', paywallsExplicitModal)
    .component('paywallsLibraryModal', paywallsLibraryModal)
    .component('paywallsModalBody', paywallsModalBody)
    .component('paywallsModalFooter', paywallsModalFooter)
    .component('paywallsModalHeader', paywallsModalHeader)
    .component('paywallsPostMediaModal', paywallsPostMediaModal)
    .component('paywallsPostMediaOtherOwnerModal', paywallsPostMediaOtherOwnerModal)
    .component('paywallsSegmentsModal', paywallsSegmentsModal)
    .component('paywallsSpaceLimitModal', paywallsSpaceLimitModal)
    .component('paywallsSpaceLimitReadOnlyModal', paywallsSpaceLimitReadOnlyModal)
    .component('paywallsTemplatesModal', paywallsTemplatesModal)
    .component('paywallsPinnedPostsModal', paywallsPinnedPostsModal)
    .component('paywallsProUpgradeModal', paywallsProUpgradeModal)
    .component('phoneInvitationCopyLinkModal', phoneInvitationCopyLinkModal)
    .component('spaceCreatedPhoneInvitationCopyLinkModal', spaceCreatedPhoneInvitationCopyLinkModal)
    .component('scheduledPostModal', scheduledPostModal)
    .component('togglePinModal', togglePinModal)
    .component('togglePinNotAllowedModal', togglePinNotAllowedModal)
    .component('newSegmentModal', newSegmentModal)
    .component('segmentsList', segmentsList)
    .component('selectedUsers', selectedUsers)
    .component('simpleTypeahead', simpleTypeahead)
    .component('siteWrap', siteWrap)
    .component('publicWrap', publicWrap)
    .component('newTemplateModal', newTemplateModal)
    .component('inviteSpaceUserModal', inviteSpaceUserModal)
    .component('newSpaceButton', newSpaceButton)
    .component('spaceHeader', spaceHeader)
    .component('spaceNav', spaceNav)
    .component('spaceNotificationSettingsModal', spaceNotificationSettingsModal)
    .component('spaceSettingsMenu', spaceSettingsMenu)
    .component('spaceSettingsModal', spaceSettingsModal)
    .component('spacesList', spacesList)
    .component('transferSpaceCompleteModal', transferSpaceCompleteModal)
    .component('transferSpaceModal', transferSpaceModal)
    .component('upgradeOptionsModal', upgradeOptionsModal)
    .component('uploadButton', uploadButton)
    .component('uploadModal', uploadModal)
    .component('uploadDropzone', uploadDropzone)
    .component('uploadForm', uploadForm)
    .component('uploadProgress', uploadProgress);

  // directives
  __angular.module('directives')
    .directive('activeReply', ActiveReply)
    .directive('activeWhen', ActiveWhen)
    .directive('appPromt', AppPrompt)
    .directive('audioPlayer', AudioPlayer)
    .directive('audio', Audio)
    .directive('validateEmail', ValidateEmail)
    .directive('videoPlayer', VideoPlayer)
    .directive('coachPlusUpgradeAlert', CoachPlusUpgradeAlert)
    .directive('ngPreventDefault', NgPreventDefault)
    .directive('ngValidate', NgValidate)
    .directive('stopEvent', StopEvent)
    .directive('onEnterKey', OnEnterKey)
    .directive('focusMe', FocusMe)
    .directive('datafile', Datafile)
    .directive('edufiiImage', EdufiiImage)
    .directive('emojify', Emojify)
    .directive('focus', Focus)
    .directive('formErrors', FormErrors)
    .directive('formatText', FormatText)
    .directive('hoverClass', HoverClass)
    .directive('intlTel', IntlTel)
    .directive('linkTo', LinkTo)
    .directive('localtime', Localtime)
    .directive('nameWithEmailInput', NameWithEmailInput)
    .directive('ngMultiple', NgMultiple)
    .directive('ngReallyClick', NgReallyClick)
    .directive('nullResult', NullResult)
    .directive('postingForm', PostingForm)
    .directive('readmore', Readmore)
    .directive('resourceTags', ResourceTags)
    .directive('scrollinside', Scrollinside)
    .directive('selectedUser', SelectedUser)
    .directive('serverValidation', ServerValidation)
    .directive('sticky', Sticky)
    .directive('tagIt', TagIt)
    .directive('teamInvitation', TeamInvitation)
    .directive('transcope', Transcope)
    .directive('video', Video);

  // factories
  __angular.module('directives')
    .factory('accountModal', AccountModal)
    .factory('bulk_post.bulkPostHelpers', BulkPostHelpers)
    .factory('bulk_post.bulkPostLocationFactory', BulkPostLocationFactory)
    .factory('bulk_post.locations.$bulkPostLocation', $bulkPostLocation)
    .factory('bulk_post.locations.groupLocation', groupLocation)
    .factory('bulk_post.locations.segmentLocation', segmentLocation)
    .factory('bulk_post.locations.spaceLocation', spaceLocation)
    .factory('bulk_post.locations.smartListLocation', smartListLocation)
    .factory('bulk_post.locations.teamLocation', teamLocation)
    .factory('invitationModal', InvitationModal)
    .factory('paywallModal', PaywallModal)
    .factory('postHelpers', PostHelpers)
    .factory('togglePinModalService', TogglePinModalService)
    .factory('scheduledPostModal', ScheduledPostModal)
    .factory('scheduleFeedPagerFactory', ScheduleFeedPagerFactory)
    .factory('repliesPagerFactory', RepliesPagerFactory)
    .factory('segmentModalService', SegmentModalService)
    .factory('spaceTemplateModal', SpaceTemplateModal)
    .factory('spaceFeedPagerFactory', SpaceFeedPagerFactory)
    .factory('spaceModal', SpaceModal)
    .factory('transferSpaceFlow', TransferSpaceFlow)
    .factory('transferSpaceModal', TransferSpaceModal)
    .factory('upgradeModal', UpgradeModalService)
    .factory('mediaHelpers', mediaHelpers);
}
