
import toPlainObject from 'lodash/toPlainObject';
import identity from 'lodash/identity';
import constant from 'lodash/constant';
import filter from 'lodash/filter';
import { isBlank } from 'app/utils';
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/directives/_segments_list.html';

function Controller($scope, $element, format) {
  $scope.helpText = $scope.$props.helpText;
  $scope.query = '';
  $scope.segments = {
    value: toPlainObject($scope.$props.segments, identity, constant(true)),
    all: $scope.$props.segments,
    filtered: __filter($scope.$props.segments, $scope.query)
  };

  $scope.formatDescription = formatDescription;
  $scope.onQuery = onQuery;
  $scope.onSelect = onSelect;
  $scope.$props.$onDestroy = $onDestroy;

  var $container = $element.find('.segments-list');
  var $input = $container.find('.segments-list-input');

  $container.on('focusout', onBlur);

  function __filter(segments, query) {
    return filter(segments, function (segment) {
      return isBlank(query) || segment.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
    });
  }

  function formatDescription(space) {
    return format.segmentDescription(space);
  }

  function onBlur(e) {
    var focusLost = false;

    if (e.originalEvent.relatedTarget == null) {
      focusLost = true;
    } else if(!$element[0].contains(e.originalEvent.relatedTarget)) {
      focusLost = true;
    }

    if (focusLost) {
      run(() => {
        $scope.query = '';
        onQuery();
      });
    } else {
      $input.focus();
    }
  }

  function $onDestroy() {
    $container.off('focusout', onBlur);
  }

  function onQuery() {
    $scope.segments.filtered = __filter($scope.segments.all, $scope.query);
  }

  function onSelect(selected) {
    var segments = filter($scope.segments.all, function (segment) {
      return selected[segment.id];
    });

    $scope.$props.onSegmentSelected({ selected: segments });
  }
}

Controller.$inject = [ '$scope', '$element', 'format'];

export default {
  bindings: {
    segments: '<',
    onSegmentSelected: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
