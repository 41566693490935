/**
 * @module Directives.Teams
 *
 */

/**
 * @class TeamFeedPagerFactory
 *
 */
function factory(Post, createPager) {
  const settings = {
    perPage: 16,
    throttle: 10000
  };

  return function create(id, filters) {
    function load(request) {
      const query = Object.assign({}, filters, {
        page: request.page,
        perPage: settings.perPage,
        replyLimit: Post.repliesPerPost
      });

      return Post.queryTeamFeed(id, query);
    }

    return createPager(load, settings);
  };
}

factory.$inject = ['core.services.Post', 'core.services.pagerFactory'];

export default factory;
