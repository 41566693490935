
import template from 'ngapp/templates/views/teams/_team_settings_menu.html';

function Controller(
  $scope,
  $translate,
  $location,
  confirm,
  teamModal,
  toast,
  Team,
  transferTeamFlow
) {
  $scope.team = $scope.$props.team;
  $scope.member = null;
  $scope.onTeamSettingsClick = onTeamSettingsClick;
  $scope.onMemberSettingsClick = onMemberSettingsClick;
  $scope.onLeaveTeamClick = onLeaveTeamClick;

  function onTeamSettingsClick() {
    teamModal.editSettings($scope.team).result
      .then(function (result) {
        if (result.action === 'transfer') {
          return transferTeamFlow($scope.team);
        }
      });
  }

  function onMemberSettingsClick() {
    teamModal.notificationSettings($scope.team);
  }

  function onLeaveTeamClick() {
    confirm({
      body: $translate.instant('TEAMS.SETTINGS.LEAVE_CONFIRMATION', { name: $scope.team.name })
    })
    .then(function(result) {
      if (result.confirmed) {
        Team.leave($scope.team.id)
          .then(function () {
            toast.info($translate.instant('TEAMS.SETTINGS.LEFT_MESSAGE', { name: $scope.team.name }));
            $location.path('/groups');
          });
      }
    });
  }
}

Controller.$inject = [
  '$scope',
  '$translate',
  '$location',
  'confirm',
  'teamModal',
  'core.ui.toast',
  'core.services.Team',
  'transferTeamFlow'
];

export default {
  bindings: {
    team: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
