
function NullPromise($q) {
  var service = {
    promise: function() {
      var deferred = $q.defer(),
          promise  = deferred.promise;

      deferred.resolve(null);

      return promise;
    }
  };

  return service;
}

NullPromise.$inject = ['$q'];

export default NullPromise;
