/**
 * @module Core
 *
 */
import template from 'lodash/template';
import extend from 'lodash/extend';
import config from 'config/environment';

/**
 * @class LoggerProvider
 *
 */
export default function LoggerProvider() {
  var options = {
    noisy: config.ENVIRONMENT === 'development',
    format: '[${time.toISOString()}]:${id} ${name} ${level} ${JSON.stringify(message)}'
  };

  function $get($console, $uid) {
    var levels = {
      LOG: 'LOG',
      INFO: 'INFO',
      WARNING: 'WARNING',
      ERROR: 'ERROR',
    };

    function Logger(name) {
      this._name = name || '-';
      this._id = $uid.short();
      this._format = template(options.format);
    }

    /**
      * Collection of all logged objects.
      */
    Logger.entries = [];

    /**
      * Logs value with LOG level.
      *
      * @param {*} message
      * @param {*} data
      */
    Logger.prototype.log = function info(message, data) {
      this._emit(levels.LOG, message, data);
    };

    /**
      * Logs value with INFO level.
      *
      * @param {*} message
      * @param {*} data
      */
    Logger.prototype.info = function info(message, data) {
      this._emit(levels.INFO, message, data);
    };

    /**
      * Logs value with WARNING level.
      *
      * @param {*} message
      * @param {*} data
      */
    Logger.prototype.warning = function info(message, data) {
      this._emit(levels.WARNING, message, data);
    };

    /**
      * Logs value with ERROR level.
      *
      * @param {*} message
      * @param {*} data
      */
    Logger.prototype.error = function info(message, data) {
      if (options.enabled) {
        this._emit(levels.ERROR, message, data);
      }
    };

    /**
      * Creates a new entry, then emits it via default console service.
      *
      * @param {*} level
      * @param {*} message
      * @param {*} data
      */
    Logger.prototype._emit = function _emit(level, message, data) {
      var entry = {
        time: new Date(),
        level: level,
        message: template(message)(data),
        data: data,
        name: this._name,
        id: this._id
      };

      var messages = [this._format(extend({}, entry, data))];

      if (data != null) {
        messages.push(data);
      }

      Logger.entries.push(entry);

      if (options.noisy) {
        this._print(level, messages);
      }
    };

    /**
      * Prints out a messages with a log level.
      *
      * @param {string} level
      * @param {*} message
      */
    Logger.prototype._print = function _print(level, messages) {
      switch (level) {
        case levels.LOG:
          $console.log.apply($console, messages);
          break;
        case levels.INFO:
          $console.info.apply($console, messages);
          break;
        case levels.WARNING:
          $console.warning.apply($console, messages);
          break;
        case levels.ERROR:
          $console.error.apply($console, messages);
          break;
      }
    };

    function factory(name) {
      return new Logger(name);
    }

    /**
      * Returns all accumulated log entries.
      *
      * @returns {[]}
      */
    factory.entries = function entries() {
      return Logger.entries.slice(0);
    };

    return factory;
  }

  $get.$inject = ['$log', 'core.uid'];

  return {
    options: options,
    $get: $get
  };
}
