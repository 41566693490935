/**
 * @module Controllers
 *
 */
import template from 'ngapp/templates/views/account/_edit.html';
import { run } from 'app/utils/runloop';

/**
 * @class AccountEditController
 *
 */
function AccountEditController(
  $root,
  $scope,
  $translate,
  Timezone,
  toast,
  accountModal
) {
  $scope.avatarPreviewUrl = null;
  $scope.processing = false;
  $scope.genders = [
    { name: 'MALE', value: 'male' },
    { name: 'FEMALE', value: 'female' }
  ];

  const setUser = function(user) {
    $scope.user = { ...user };

    if (user !== null && user !== undefined) {
      $scope.user.kind = user.kind || 'other';
      $scope.user.phoneVerified = user.phoneVerified;
      $scope.user.phoneNormalized = user.phoneNormalized;
    }
  };
  setUser($root.user);

  $root.$on('userUpdated', (event, user) => setUser(user));

  $scope.onAvatarChange = function($avatar) {
    $scope.avatarPreviewUrl = $avatar.preview;
    if ($scope.user != null) {
      $scope.user.avatarId = $avatar.id;
      $scope.user.avatar = $avatar.url;
    }
  };

  Timezone.query().then((timezones) => {
    $scope.timezones = timezones;
  });

  $scope.onSubmit = function(event) {
    event.preventDefault();
    $scope.processing = true;
    $scope.validationErrors = null;

    $root.user.put($scope.user)
      .then(() => toast.success($translate.instant('CONTROLLERS.ACCOUNT_EDIT.UPDATE_SUCCESS_MESSAGE')))
      .catch(response => run(() => $scope.validationErrors = response.originalErrors))
      .finally(() => run(() => $scope.processing = false));
  };

  $scope.onPhoneInputClick = function() {
    accountModal.accountPhoneVerificationLink($scope.user);
  };
}

AccountEditController.$inject = [
  '$rootScope',
  '$scope',
  '$translate',
  'Timezone',
  'core.ui.toast',
  'accountModal'
];

export default {
  controller: AccountEditController,
  template
};
