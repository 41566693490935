/**
 * @module Controllers.SegmentSpaces
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/segment_spaces/_edit.html';

/**
 * @class SegmentSpacesEdit
 *
 */
function Controller($scope, $location, listLoader, $filter, $routeParams, Segment, Space) {
  $scope.segment = {};
  $scope.spaces = [];
  $scope.processing = false;
  $scope.resourceId = $routeParams.segmentId;

  const loadSegmentSpaces = () => {
    return Segment.get($routeParams.segmentId).then(segment => {
      run(() => $scope.segment = segment);
      return Space.query({ 'options[include]': ['segment_names'], 'type': 'athlete' });
    });
  };

  const checkSegmentSpaces = (segment, spaces) => spaces.forEach(space => {
    if (segment.spaceIds.indexOf(space.id) != -1) {
      space.checked = true;
    }
  });

  const onSpacesLoaded = (spaces) => {
    run(() => {
      $scope.spaces = spaces;
      checkSegmentSpaces($scope.segment, $scope.spaces);
    });
  };

  listLoader($scope, 'spaces', loadSegmentSpaces, onSpacesLoaded);

  $scope.loadList();

  $scope.selectAll = () => {
    run(() => {
      $scope.spaces.forEach(space => {
        space.checked = true;
      });

      $scope.updateSelectedSpaces();
    });
  };

  $scope.updateSelectedSpaces = () => {
    run(() => {
      $scope.segment.spaceIds = $scope.getSelectedSpaces();
    });
  };

  $scope.getSelectedSpaces = () => {
    return $filter('filter')($scope.spaces, { checked: true }).map(space => space.id);
  };

  $scope.update = () => {
    $scope.processing = true;
    $scope.startLoading();

    $scope.segment.update()
      .then(segment => run(() => {
        $scope.processing = false;
        $scope.finishLoading();
        $location.path('/lists/' + segment.id + '/spaces');
      }))
      .catch(() => run(() => {
        $scope.processing = false;
        $scope.finishLoading();
        alert('error');
      }));
  };

  $scope.otherSegmentsNames = (space) => {
    return space.segmentNames
      .filter(segmentName => segmentName !== $scope.segment.name)
      .join(', ');
  };
}

Controller.$inject = [
  '$scope',
  '$location',
  'listLoader',
  '$filter',
  '$routeParams',
  'Segment',
  'Space'
];

export default {
  controller: Controller,
  template
};
