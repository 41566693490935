import {
  run
} from 'app/utils/runloop';
import template from 'ngapp/templates/views/teams/_new_team_button.html';

function Controller($scope, teamCreationFlow, identity, paywallModal, planFeature) {
  $scope.onClick = onClick;
  $scope.user = null;
  $scope.canCreateTeams = null;

  identity.request().then(user =>
    run(() => {
      $scope.user = user;
      $scope.canCreateTeams = planFeature.user.canCreateTeams(user);
    })
  );

  function onClick() {
    if ($scope.canCreateTeams) {
      return teamCreationFlow();
    }
    return paywallModal.proUpgrade();
  }
}

Controller.$inject = ['$scope', 'teamCreationFlow', 'core.services.identity', 'paywallModal', 'core.services.planFeature'];

export default {
  template,
  controller: Controller,
  controllerAs: '$props'
};
