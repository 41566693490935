/**
 * @module Directives.Activity
 *
 */
import includes from 'lodash/includes';
import template from 'ngapp/templates/views/activity/_outgoing_invitation.html';

/**
 * @class OutgoingInvitation
 *
 */
function Controller($scope, $translate) {
  $scope.invitation = {};
  $scope.name = '';
  $scope.formatSpaceTitle = formatSpaceTitle;
  $scope.formatTeamTitle = formatTeamTitle;
  $scope.formatAcceptButton = formatAcceptButton;
  $scope.onResend = onResend;
  $scope.onCancel = onCancel;
  $scope.canCancel = canCancel;
  $scope.$props.$onChanges = $onChanges;

  function canCancel(invitation) {
    return invitation.permissions && includes(invitation.permissions, 'delete');
  }

  function formatSpaceTitle(name, invitation) {
    return $translate.instant('ACTIVITY.OUTGOING.SPACE',
    { name: name, athlete: invitation.spaceAthleteNameShort, space: invitation.spaceName });
  }

  function formatTeamTitle(name, invitation) {
    return $translate.instant('ACTIVITY.OUTGOING.TEAM', { name: name, team: invitation.teamName });
  }

  function formatAcceptButton(invite) {
    if (invite.object === 'space_invitation') {
      return $translate.instant('ACTIVITY.INVITATION.JOIN_SPACE_BUTTON');
    }

    return $translate.instant('ACTIVITY.INVITATION.JOIN_TEAM_BUTTON');
  }

  function onResend() {
    $scope.$props.onResend({
      invitation: $scope.invitation
    });
  }

  function onCancel() {
    $scope.$props.onCancel({
      invitation: $scope.invitation
    });
  }

  function $onChanges(props) {
    if (props.invitation != null) {
      $scope.invitation = props.invitation.currentValue;
      $scope.name = $scope.invitation.nameShort || $scope.invitation.email;
    }
  }
}

Controller.$inject = ['$scope', '$translate'];

export default {
  bindings: {
    invitation: '<',
    onResend: '&',
    onCancel: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
