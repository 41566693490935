/**
 * @module Directives.SpaceTemplates
 *
 */
import template from 'ngapp/templates/views/space_templates/_new_space_template_modal.html';

/**
 * @class NewSpaceTemplateModal
 *
 */
function Controller($scope, $location, validation, SpaceTemplate) {
  $scope.fields = { name: '' };
  $scope.errors = {};
  $scope.pending = false;
  $scope.onCancel = onCancel;
  $scope.onSubmit = onSubmit;

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onSubmit(e) {
    e.preventDefault();
    const validationResult = validate($scope.fields);
    if (validationResult.isValid) {
      $scope.pending = true;
      new SpaceTemplate($scope.fields)
        .create()
        .then((spaceTemplate) => {
          $scope.$props.modal.close({ template: spaceTemplate });
          $location.path('/space_templates/' + spaceTemplate.id + '/posts');
        })
        .finally(() => $scope.pending = false);
    }
    $scope.errors = validationResult.errors;
  }

  function validate(fields) {
    var results = {
      errors: {},
      isValid: true
    };

    if (validation.isEmpty(fields.name)) {
      results.errors.name = 'required';
      results.isValid = false;
    }

    return results;
  }
}

Controller.$inject = [
  '$scope',
  '$location',
  'core.validate',
  'SpaceTemplate'
];

export default {
  bindings: {
    modal: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
