/**
 * @module Directives.Account
 *
 */
import { run } from 'app/utils/runloop';
import PhoneVerificationService from 'app/services/phone_verification';
import AccountService from 'app/services/account';
import template from 'ngapp/templates/views/account/_phone_verification_link_modal.html';
import checkmark_green_png from 'app/images/checkmark-green.png';

/**
 * @class AccountPhoneVerificationLinkModal
 *
 */
function Controller($scope, sharedUserService) {
  $scope.user = $scope.$props.user;
  $scope.checkmark_green_png = checkmark_green_png;
  $scope.pending = false;
  $scope.verification = null;
  $scope.defaultPhoneCountry = null;
  $scope.step = 1;
  $scope.phoneData = {
    phone: null,
    phoneCountry: null,
    phoneNormalized: null,
    code: null
  };
  $scope.errors = {};
  $scope.onPhoneInputChange = onPhoneInputChange;
  $scope.onSendConfirmationCode = onSendConfirmationCode;
  $scope.onConfirmCode = onConfirmCode;
  $scope.onTryAnotherNumber = onTryAnotherNumber;
  $scope.onDone = onDone;

  // Set default phone country
  if ($scope.user && $scope.user.phoneCountry) {
    $scope.defaultPhoneCountry = $scope.user.phoneCountry.toLowerCase();
  } else {
    $scope.defaultPhoneCountry = 'us';
  }

  function onPhoneInputChange(value, data, event, formattedValue) {
    const phoneNormalized = formattedValue;
    const phone = value.substring(data.dialCode.length);
    const phoneCountry = data.countryCode.toUpperCase();

    $scope.phoneData.phone = phone;
    $scope.phoneData.phoneCountry = phoneCountry;
    $scope.phoneData.phoneNormalized = phoneNormalized;
  }

  function onSendConfirmationCode() {
    $scope.pending = true;
    $scope.errors = {};

    PhoneVerificationService.verify($scope.phoneData.phone, $scope.phoneData.phoneCountry)
      .then((verification) => run(() => {
        $scope.verification = verification;
        $scope.pending = false;
        $scope.step = 2;
      }))
      .catch((error) => run(() => {
        $scope.pending = false;
        $scope.errors.invalidPhone = error;
      }));
  }

  function onConfirmCode() {
    $scope.pending = true;
    $scope.errors = {};

    PhoneVerificationService.confirm($scope.verification.id, $scope.phoneData.code)
      .then(() => {
        AccountService.updatePhone($scope.verification.token)
          .then(() => run(() => {
            $scope.pending = false;
            $scope.step = 3;

            $scope.user.phoneVerified = true;
            $scope.user.phoneNormalized = $scope.phoneData.phoneNormalized;
            sharedUserService.updateUser($scope.user);
          }))
          .catch((error) => run(() => {
            $scope.pending = false;
            $scope.errors.invalidVerification = error;
          }));
      })
      .catch((error) => run(() => {
        $scope.pending = false;
        $scope.errors.invalidVerificationCode = error;
      }));
  }

  function onTryAnotherNumber() {
    $scope.phoneData = {
      phone: null,
      phoneCountry: null,
      phoneNormalized: null,
      code: null
    };
    $scope.step = 1;
  }

  function onDone() {
    $scope.$props.modal.dismiss();
  }
}

Controller.$inject = [
  '$scope',
  'sharedUserService'
];

export default {
  bindings: {
    modal: '<',
    user: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
