/**
 * @module Directives
 *
 */
import range from 'lodash/range';
import template from 'ngapp/templates/directives/_pager.html';

/**
 * @class Pager
 *
 */
function Controller($scope) {
  $scope.page = 1;
  $scope.pages = 1;
  $scope.options = createOptionRanges($scope.page, $scope.pages);
  $scope.onSelect = onSelect;
  $scope.$props.$onChanges = $onChanges;

  function onSelect(page) {
    $scope.$props.onChange({ page: page });
  }

  function createOptionRanges(page, pages) {
    var left = [];
    var middle = [];
    var right = [];

    if (pages <= 5) {
      left = range(1, pages + 1);
    } else if (page < 4) {
      left = range(1, 6);
      right = [pages];
    } else if (page >= 4 && page < pages - 2) {
      left = [1];
      middle = [page - 1, page, page + 1];
      right = [pages];
    } else {
      left = [1];
      right = range(pages - 4, pages + 1);
    }

    return {
      left: left,
      middle: middle,
      right: right
    };
  }

  function $onChanges(props) {
    var previous = {
      page: $scope.page,
      pages: $scope.pages
    };

    if (props.page != null) {
      $scope.page = props.page.currentValue;
    }

    if (props.pages != null) {
      $scope.pages = props.pages.currentValue;
    }

    if ($scope.page !== previous.page || $scope.pages !== previous.pages) {
      $scope.options = createOptionRanges($scope.page, $scope.pages);
    }
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    page: '<',
    pages: '<',
    onChange: '&'
  },
  controller: Controller,
  controllerAs: '$props',
  template,
};

