
import template from 'ngapp/templates/directives/_selected_users.html';

function Controller($scope, identity) {
  $scope.hideRemove = $scope.$props.hideRemove;
  $scope.users = $scope.$props.users;
  $scope.isSelf = isSelf;
  $scope.onRemoveUserClick = onRemoveUserClick;
  $scope.$props.$onChanges = $onChanges;

  function $onChanges(props) {
    if (props.users != null) {
      $scope.users = props.users.currentValue;
    }
  }

  identity.request().then(function(user) {
    $scope.currentUser = user;
  });

  function onRemoveUserClick(user, event) {
    $scope.$props.onRemoveUserClick({ user: user });
    event.stopPropagation();
  }

  function isSelf(user) {
    return $scope.currentUser != null && $scope.currentUser.id === user.id;
  }
}

Controller.$inject = ['$scope', 'core.services.identity'];

export default {
  bindings: {
    hideRemove: '<',
    users: '<',
    onRemoveUserClick: '&'
  },
  controller: Controller,
  controllerAs: '$props',
  template
};
