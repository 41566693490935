/**
 * @module Directives.Spaces
 *
 */
import template from 'ngapp/templates/views/spaces/_space_settings_menu.html';

/**
 * @class SpaceSettingsMenu
 *
 */
function Controller(
  $scope,
  $location,
  $translate,
  planFeature,
  identity,
  SpaceMember,
  toast,
  confirm,
  spaceModal,
  transferSpaceFlow
) {
  $scope.space = $scope.$props.space;
  $scope.canLeave = $scope.space.can.leave && planFeature.space.canLeave($scope.space);
  $scope.hasSpaceSettingsAccess = planFeature.space.hasSpaceSettingsAccess($scope.space);
  $scope.onSpaceSettingsClick = onSpaceSettingsClick;
  $scope.onNotificationSettingsClick = onNotificationSettingsClick;
  $scope.onLeaveSpaceClick = onLeaveSpaceClick;

  function onSpaceSettingsClick() {
    spaceModal.editSettings($scope.space).result
      .then(function (result) {
        if (result && result.action === 'transfer') {
          return transferSpaceFlow($scope.space);
        }
      });
  }

  function onNotificationSettingsClick() {
    spaceModal.notificationSettings($scope.space);
  }

  function onLeaveSpaceClick() {
    var name = $scope.space.fullName;

    confirm({
      body: $translate.instant('SPACES.SETTINGS.LEAVE_CONFIRMATION', { name: name })
    })
    .then(function(result) {
      if (result.confirmed) {
        identity.request()
          .then(function(user) {
            return SpaceMember.remove(user, $scope.space.id);
          })
          .then(function() {
            return identity.$refresh();
          })
          .then(function() {
            toast.info($translate.instant('SPACES.SETTINGS.LEFT_MESSAGE', { name: name }));
            $location.path('/spaces');
          });
      }
    });
  }
}

Controller.$inject = [
  '$scope',
  '$location',
  '$translate',
  'core.services.planFeature',
  'core.services.identity',
  'core.services.SpaceMember',
  'core.ui.toast',
  'confirm',
  'spaceModal',
  'transferSpaceFlow'
];

export default {
  bindings: {
    space: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
