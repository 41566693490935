/** @module core/services */

/**
 * @class TeamGroupMember
 *
 */
function factory($resource, $teamGroupMemberEvents) {
  var TeamGroupMember = $resource({
    url: '/api/v2/team_groups/{{teamGroupId}}/memberships',
    name: 'teamGroupMember'
  }, [
    $resource.acl({
      delete: 'delete'
    })
  ]);

  TeamGroupMember.events = $teamGroupMemberEvents;

  /**
    * Creates group members for a team group by user ids.
    *
    * @param {string} groupId
    * @param {string[]} memberIds
    * @returns {Promise}
    */
  TeamGroupMember.createFromMemberIds = function createFromMemberIds(groupId, memberIds) {
    var request = this.$post(
      this.$url({ teamGroupId: groupId }),
      { memberIds: memberIds },
      { rootWrapping: false }
    );

    request.then(function() {
      TeamGroupMember.events.onMembersAdded.dispatch(TeamGroupMember, { groupId: groupId, memberIds: memberIds });
    });

    return request;
  };

  /**
    * Returns group membership for the current identity.
    *
    * @param {string} groupId
    * @returns {Promise}
    */
  TeamGroupMember.me = function me(groupId) {
    return this.$get(this.$url({ teamGroupId: groupId }, 'me'));
  };

  /**
    * Leaves a group.
    *
    * @param {string} groupId
    * @returns {Promise}
    */
  TeamGroupMember.leave = function leave(groupId) {
    return TeamGroupMember.me(groupId)
      .then(function (member) {
        return member.remove();
      });
  };

  /**
    * Removes the team member from group.
    *
    * @returns {Promise}
    */
  TeamGroupMember.prototype.remove = function() {
    var self = this;

    return this.$delete(this.$url(this.id))
      .then(function() {
        TeamGroupMember.events.onMemberRemoved.dispatch(TeamGroupMember, { groupId: self.teamGroupId, memberId: self.id });
      });
  };

  return TeamGroupMember;
}

factory.$inject = [
  'core.services.$resource',
  'core.services.events.$teamGroupMemberEvents'
];

export default factory;
