/** @module core/services */
import map from 'lodash/map';
import assign from 'lodash/assign';
import camelCase from 'lodash/camelCase';
import fromPairs from 'lodash/fromPairs';

const DEFAULT_SERVER_ERROR = 'unknown';
const SERVER_ERROR_TO_INVARIANT = {
  userId: { 'has already been invited to this Group': 'already_invited' },
  email: { 'has already been invited to this Group': 'already_invited' }
};

/**
 * @class TeamMember
 *
 */
function factory($q, $property, $resource, bulkTeamInvite, $createTeamMemberCachePolicy) {
  const info = { url: '/api/v2/teams/{{teamId}}/memberships/{{id}}', name: 'membership' };
  const options = [ $resource.acl({ manage: 'manage' }) ];
  const TeamMember = $resource(info, options);

  TeamMember.$cachePolicy = $createTeamMemberCachePolicy(TeamMember);

  TeamMember.create = function create(invite) {
    return new TeamMember(invite).save()
      .catch(e => handleCreateError(e, $q));
  };

  TeamMember.createMany = function(teamId, members, groupIds) {
    return bulkTeamInvite.create(teamId, members, groupIds);
  };

  TeamMember.me = function me(teamId) {
    return TeamMember.$get(TeamMember.$url({ teamId }, 'me'));
  };

  TeamMember.remove = function(teamId, id) {
    return TeamMember.$delete(TeamMember.$url({ id, teamId }))
      .then(() => TeamMember.$cachePolicy.onRemove(teamId));
  };

  TeamMember.update = function(member, properties) {
    member = assign(member, properties);
    if (member.nickname == '') {
      member.nickname = null;
    }
    return member.$put(
      TeamMember.$url({ teamId: member.teamId }, member.id)
    );
  };

  $property.get(TeamMember.prototype, 'displayName', function() { return this.name || this.nickname; });

  return TeamMember;
}

function handleCreateError(err, $q) {
  if (err.status === 422) {
    const fieldErrorKeyValues = map(err.data.errors, (codes, field) => {
      const key = camelCase(field);
      let value = DEFAULT_SERVER_ERROR;
      if (SERVER_ERROR_TO_INVARIANT[key] != null) {
        const serverErrorCode = codes.find(code => SERVER_ERROR_TO_INVARIANT[key][code]);
        if (serverErrorCode != null) {
          value = SERVER_ERROR_TO_INVARIANT[key][serverErrorCode];
        }
      }
      return [key, value];
    });
    return $q.reject(fromPairs(fieldErrorKeyValues));
  }
  return $q.reject({ $service: DEFAULT_SERVER_ERROR });
}

factory.$inject = [
  '$q',
  'core.$property',
  'core.services.$resource',
  'core.services.bulkTeamInvite',
  'core.services.cache.$teamMemberCachePolicyFactory'
];

export default factory;
