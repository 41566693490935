/**
 * @module Controllers
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/activity/_invitations.html';

/**
 * @class Invitations
 *
 */
function Controller(
  $q,
  $scope,
  $translate,
  toast,
  activityCollection,
  Invitation
) {
  $scope.pending = true;
  $scope.incomingInvitations = [];
  $scope.outgoingInvitations = [];
  $scope.outgoingCount = 0;
  $scope.active = 'incoming';
  $scope.noVisibleInvitations = noVisibleInvitations;
  $scope.shouldShowIncoming = shouldShowIncoming;
  $scope.onTabChange = onTabChange;
  $scope.onAccept = onAccept;
  $scope.onDecline = onDecline;
  $scope.onResend = onResend;
  $scope.onCancel = onCancel;
  $scope.onScroll = onScroll;
  $scope.pager = null;

  let unsubscribeFromIncomingInvitationActivity = null;
  let unsubscribeFromOutgoingInvitationActivity = null;

  const activityQuery = activityCollection.query();
  const invitationQuery = Invitation.outgoingCount();

  $q.all([activityQuery, invitationQuery]).then(([ _activity, _invitation ]) => run(() => {
    unsubscribeFromIncomingInvitationActivity = _activity.invitations.onChange.attach(onIncomingNotificationActivity);
    unsubscribeFromOutgoingInvitationActivity = _activity.outgoing.onChange.attach(onOutgoingNotificationActivity);
    $scope.incomingInvitations = _activity.invitations.values;
    $scope.outgoingInvitations = _activity.outgoing.values;
    $scope.pager = _activity.outgoing.pager;
    $scope.outgoingCount = _invitation;
    $scope.pending = false;
  }));

  $scope.$on('$destroy', () => {
    if (unsubscribeFromIncomingInvitationActivity != null) {
      unsubscribeFromIncomingInvitationActivity();
    }
    if (unsubscribeFromOutgoingInvitationActivity != null) {
      unsubscribeFromOutgoingInvitationActivity();
    }
  });

  function onIncomingNotificationActivity(e) {
    $scope.incomingInvitations = e.data.values;
    $scope.pending = e.data.pending;
  }

  function onOutgoingNotificationActivity(e) {
    $scope.outgoingInvitations = e.data.values;
    $scope.pending = e.data.pending;
  }

  function onScroll() {
    if ($scope.pager != null) {
      $scope.pager.next();
    }
  }

  function shouldShowIncoming() {
    return $scope.active === 'incoming' && $scope.incomingInvitations.length > 0;
  }

  function noVisibleInvitations() {
    return $scope.active === 'incoming' && $scope.incomingInvitations.length === 0 ||
      $scope.active === 'outgoing' && $scope.outgoingInvitations.length === 0;
  }

  function onTabChange(tab) {
    $scope.active = tab;
  }

  function onAccept(invitation) {
    activityCollection.query().then((e) => {
      return e.invitations.accept(invitation.id);
    });
  }

  function onDecline(invitation) {
    activityCollection.query().then((e) => {
      return e.invitations.decline(invitation.id);
    });
  }

  function onResend(invitation) {
    activityCollection.query().then((e) => {
      return e.outgoing.resend(invitation.id)
        .then(() => {
          toast.success($translate.instant('CONTROLLERS.INVITATIONS.INVITE_SENT_SUCCESS_MESSAGE', { email: invitation.email }));
        })
        .catch(() => toast.danger($translate.instant('CONTROLLERS.INVITATIONS.INVITE_RESEND_ERROR_MESSAGE')));
    });
  }

  function onCancel(invitation) {
    activityCollection.query().then((e) =>
      e.outgoing.cancel(invitation).then(() =>
        Invitation.outgoingCount().then((e_in) =>
          ($scope.outgoingCount = e_in, e)
        )
      )
    );
  }
}

Controller.$inject = [
  '$q',
  '$scope',
  '$translate',
  'core.ui.toast',
  'core.services.activityCollection',
  'core.services.Invitation'
];

export default {
  controller: Controller,
  template
};
