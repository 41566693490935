/**
 * @module Directives.Teams
 *
 */
import template from 'ngapp/templates/views/teams/_team_nav.html';

/**
 * @class TeamNav
 *
 */
function Controller($scope, $location) {
  $scope.team = null; // $scope.$props.team;
  $scope.active = getActiveTab($scope.team);

  function getActiveTab(team) {
    if (team == null) {
      return '';
    }

    switch ($location.path()) {
      case team.timelineUrl:
        return 'timeline';
      case team.membersUrl:
        return 'members';
    }
  }

  $scope.changePath = function(url) {
    $location.path(url);
    $location.replace();
  };

  $scope.$props.$onChanges = function(props) {
    if (props.team != null) {
      $scope.team = props.team.currentValue;
      $scope.active = getActiveTab($scope.team);
    }
  };
}

Controller.$inject = ['$scope', '$location'];

export default {
  bindings: {
    team: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};

