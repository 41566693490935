
function SpaceNotificationsController($scope) {
  $scope.isUpdatingSettings = false;

  $scope.toggleNotifications = function(type) {
    $scope.isUpdatingSettings = true;

    $scope.space.userSettings[type + 'NotificationsEnabled'] = !$scope.space.userSettings[type + 'NotificationsEnabled'];

    $scope.space.userSettings.update()['finally'](function() {
      $scope.isUpdatingSettings = false;
    });
  };
}

SpaceNotificationsController.$inject = [ '$scope' ];

export default {
  name: 'SpaceNotificationsCtrl',
  controller: SpaceNotificationsController
};
