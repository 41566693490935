/**
 * @module Core.UI.Modal
 *
 */
import assign from 'lodash/assign';

/**
 * @class $compile
 *
 */
function factory($, $q, $root, $compile, $controller, $template) {
  function fromTemplate(template, controller, $scope, locals) {
    const scope = ($scope || $root).$new();
    const $element = $(template);
    const linkFn = $compile($element);

    const controllerLocals = assign({ $scope: scope, $element: $element }, locals);
    if (controller != null) {
      $controller(controller, controllerLocals);
    }

    const element = linkFn(scope);
    return $q.resolve({ element, scope });
  }

  function fromTemplateUrl(url, controller, $scope, locals) {
    return $template(url).then(template =>
      fromTemplate(template, controller, $scope, locals)
    );
  }

  return {
    fromTemplate: fromTemplate,
    fromTemplateUrl: fromTemplateUrl
  };
}

factory.$inject = ['$', '$q', '$rootScope', '$compile', '$controller', '$templateRequest'];

export default factory;
