/**
 * @module Core.Services.Uploader
 *
 */

/**
 * @class Uploader
 *
 */
function factory($upload) {
  function getResourceType(type) {
    if (type === null || type === undefined) {
      return undefined;
    }

    if (typeof type === 'string') {
      return type;
    }

    return getResourceType(type.credentials || type.type);
  }

  return {
    blob(blob, name, type, resourceType) {
      return $upload(blob, name, type, getResourceType(resourceType));
    },

    file(file, resourceType) {
      return $upload(file, file.name, file.type, getResourceType(resourceType));
    }
  };
}

factory.$inject = ['core.services.uploader.$upload'];

export default factory;
