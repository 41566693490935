/** @module directives/teams */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/teams/_team_wrap.html';

/**
 * @class TeamWrap
 *
 */
function Controller($, $element, $timeout, $scope, $location, TeamGroupMember, newGroupFlow) {
  $scope.pending = true;
  $scope.active = $scope.$props.active;
  $scope.myGroups = $scope.$props.myGroups;
  $scope.joinableGroups = $scope.$props.joinableGroups;
  $scope.reloadGroups = $scope.$props.reloadGroups;
  $scope.team = $scope.$props.team;
  $scope.isActive = isActive;
  $scope.onNewGroupClick = onNewGroupClick;
  $scope.onJoin = onJoin;
  $scope.$props.$onChanges = $onChanges;
  $scope.$props.$onDestroy = $onDestroy;

  // handles weird scrollbar that shows up
  // and never dissapears
  $scope.handleScrollBar = (e) => {
    $(e.currentTarget).css({ overflow: 'hidden' });
    $timeout(() => {
      $(e.currentTarget).css({ overflow: '' });
    }, 100);
  };

  $scope.$watch('pending', pending => {
    if (!pending) {
      scrollNav();
    }
  });

  const getGroups = () => {
    return [ ...($scope.myGroups || []), ...($scope.joinableGroups || []) ];
  };

  const unsubscribeFromGroupMembersAdded = TeamGroupMember.events.onMembersAdded.attach(e => run(() => {
    if ($scope.reloadGroups) {
      $scope.reloadGroups();
    } else {
      const groups = getGroups();
      const group = groups.find(item => item.id === e.data.groupId);
      if (group != null) {
        group.membersCount += e.data.memberIds.length;
      }
    }
  }));

  const unsubscribeFromGroupMemberRemoved = TeamGroupMember.events.onMemberRemoved.attach(e => run(() => {
    if ($scope.reloadGroups) {
      $scope.reloadGroups();
    } else {
      const groups = getGroups();
      const group = groups.find(item => item.id === e.data.groupId);
      if (group != null) {
        group.membersCount -= 1;
      }
    }
  }));

  function isActive(id) {
    if (id != null) {
      return $scope.$props.active === id;
    }
    return false;
  }

  function onNewGroupClick() {
    newGroupFlow($scope.$props.team).then(result => {
      run(() => $scope.myGroups.push(result.group));
    });
  }

  function scrollNav() {
    $timeout(() => {
      const $horizontal = $element.find('.team-group-tabs.team-group-horizontal-tabs');
      const $vertical = $element.find('.team-group-list-body.scroll');

      let $active = $horizontal.find('.active');
      if ($active.length > 0) {
        $horizontal[0].scroll($active[0].offsetLeft - $horizontal[0].offsetLeft - $active[0].clientWidth, 0);
        $timeout(() => $horizontal.css({ overflow: '', visibility: '' }), 1);
      }

      $active = $vertical.find('.active');
      if ($active.length > 0) {
        const scroll = $active[0].offsetTop - $vertical[0].offsetTop;
        $vertical[0].scroll(0, scroll);
      }
    });
  }

  function onJoin(group) {
    $scope.$props.onJoin({ group });
  }

  $scope.changeGroup = function(url) {
    $location.path(url);
    $location.replace();
  };

  function $onChanges(props) {
    if (props.team) {
      $scope.team = props.team.currentValue;
    }

    if (props.myGroups) {
      $scope.myGroups = props.myGroups.currentValue;
    }

    if (props.joinableGroups) {
      $scope.joinableGroups = props.joinableGroups.currentValue;
    }

    if (props.active) {
      $scope.active = props.active.currentValue;
    }

    if ($scope.team != null && $scope.myGroups != null
      && $scope.joinableGroups != null && $scope.active != null) {
      $scope.pending = false;
    }
  }

  function $onDestroy() {
    unsubscribeFromGroupMembersAdded();
    unsubscribeFromGroupMemberRemoved();
  }
}

Controller.$inject = [
  '$',
  '$element',
  '$timeout',
  '$scope',
  '$location',
  'core.services.TeamGroupMember',
  'newGroupFlow'
];

export default {
  bindings: {
    team: '<',
    myGroups: '<',
    joinableGroups: '<',
    reloadGroups: '<',
    active: '<',
    onJoin: '&'
  },
  transclude: true,
  template,
  controller: Controller,
  controllerAs: '$props'
};
