/**
 * @module Core.Services.PlanFeature
 *
 */

function factory($client) {
  function canSchedulePosts(user) {
    return $client.hasPlanPermission(user, 'access_scheduled_posts');
  }

  function canAddMedia(user) {
    return $client.hasPlanPermission(user, 'upload_to_library');
  }

  function canCreateSegments(user) {
    return $client.hasPlanPermission(user, 'create_segments');
  }

  function canCreateSpaces(user) {
    return $client.hasPlanPermission(user, 'create_spaces');
  }

  function canCreateTeams(user) {
    return $client.hasPlanPermission(user, 'create_teams');
  }

  function canCreateTags(user) {
    return $client.hasPlanPermission(user, 'create_tags');
  }

  function canCreateTemplates(user) {
    return $client.hasPlanPermission(user, 'create_templates');
  }

  function hasCoachPlus(user) {
    return user.subscription.status === 'active' || user.subscription.status === 'trial';
  }

  return {
    canSchedulePosts: canSchedulePosts,
    canAddMedia: canAddMedia,
    canCreateSegments: canCreateSegments,
    canCreateSpaces: canCreateSpaces,
    canCreateTeams: canCreateTeams,
    canCreateTags: canCreateTags,
    canCreateTemplates: canCreateTemplates,
    hasCoachPlus: hasCoachPlus
  };
}

factory.$inject = ['core.services.planFeature.$client'];

export default factory;
