/**
 * @module Directives.Posts
 *
 */
import template from 'ngapp/templates/views/posts/_toggle_pin_not_allowed_modal.html';

/**
 * @class TogglePinNotAllowedModal
 *
 */
function Controller($scope) {
  $scope.post = $scope.$props.post;
  $scope.onCancel = onCancel;

  function onCancel() {
    $scope.$props.modal.dismiss();
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    modal: '<',
    post: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
