/** @module resources */
import assign from 'lodash/assign';

function SpaceUserSettingsFactory($resource) {
  var SpaceUserSettings = $resource({
    url: 'api/v1/spaces/{{spaceId}}/user_settings',
    name: 'user_settings'
  });

  SpaceUserSettings.update = function(userSettings, props) {
    userSettings = assign(userSettings, props);
    return userSettings.$put(SpaceUserSettings.$url({ spaceId: userSettings.spaceId }), userSettings);
  };

  return SpaceUserSettings;
}

SpaceUserSettingsFactory.$inject = ['core.services.$resource'];

export default SpaceUserSettingsFactory;
