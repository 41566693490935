/**
 * @module Core.Services
 *
 */

/**
 * all client exports need to be gone after bulkpost stuff gets reworked. All subscription sensitive detection should be encapsulated.
 *
 * @class PlanFeatures
 */
function factory($client, $space, $team, $post, $user) {
  return {
    hasPermission: $client.hasPermission,
    hasPlanPermission: $client.hasPlanPermission,
    hasUnserializedPlanPermission: $client.hasUnserializedPlanPermission,
    space: $space,
    team: $team,
    post: $post,
    user: $user
  };
}

factory.$inject = [
  'core.services.planFeature.$client',
  'core.services.planFeature.$space',
  'core.services.planFeature.$team',
  'core.services.planFeature.$post',
  'core.services.planFeature.$user'
];

export default factory;
