
import debounce from 'lodash/debounce';

export default function provider() {
  var options = {
    debounce: 200
  };

  function $get($window, $features) {
    var handlers = [];
    var handleResize = debounce(onResize, options.debounce);

    if ($features.passiveListening) {
      $window.addEventListener('resize', handleResize, { passive: true });
    } else {
      $window.addEventListener('resize', handleResize, false);
    }

    function onResize(e) {
      for (var index = 0; index < handlers.length; index++) {
        try {
          handlers[index](e);
        } catch (ex) {
          setTimeout(function() { // jshint ignore:line
            throw ex;
          }, 0);
        }
      }
    }

    return function resize(handler) {
      if (handler == null) {
        throw new Error('Expected function.');
      }

      if (handlers.indexOf(handler) === -1) {
        handlers.push(handler);
      }

      return function unsubscribe() {
        handlers = handlers.filter(function (h) {
          return h !== handler;
        });
      };
    };
  }

  $get.$inject = ['$window', 'core.features'];

  return {
    options: options,
    $get: $get
  };
}
