
import template from 'ngapp/templates/views/teams/_members_header.html';

function Controller($scope) {
  $scope.tab = $scope.$props.activeTab;
  $scope.team = $scope.$props.team;
  $scope.onTabChange = onTabChange;
  $scope.onInvitesSent = onInvitesSent;
  $scope.$props.$onChanges = $onChanges;

  function onInvitesSent() {
    $scope.$props.onInvitesSent();
  }

  function onTabChange(tab) {
    $scope.$props.onTabChange({
      tab: tab
    });
  }

  function $onChanges(props) {
    if (props.team != null) {
      $scope.team = props.team.currentValue;
    }

    if (props.activeTab != null) {
      $scope.tab = props.activeTab.currentValue;
    }
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    team: '<',
    activeTab: '<',
    onTabChange: '&',
    onInvitesSent: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
