/**
 * @module Controllers.SpaceTemplates
 *
 */
import { run } from 'app/utils/runloop';
import null_template from 'app/images/null-template.png';
import template from 'ngapp/templates/views/space_templates/_list.html';

/**
 * @class SpaceTemplates
 *
 */
function Controller($scope, $q, $translate, listLoader, SpaceTemplate, spaceTemplateModal, paywallModal, identity, planFeature, toast) {
  $scope.spaceTemplates = [];
  $scope.pending = true;
  $scope.canCreateTemplates = false;
  $scope.hasCoachPlus = false;
  $scope.onCreateTemplatesClick = onCreateTemplatesClick;
  $scope.onRemoveClick = onRemoveClick;
  $scope.onUpgradeClick = onUpgradeClick;
  $scope.null_template = null_template;

  listLoader($scope, 'spaceTemplates', listLoaderPromise, listLoaderHandler);
  $scope.loadList();

  function listLoaderPromise() {
    return $q.all([identity.request(), SpaceTemplate.query()]);
  }

  function listLoaderHandler(results) {
    run(() => {
      const user = results[0];
      $scope.canCreateTemplates = planFeature.user.canCreateTemplates(user);
      $scope.hasCoachPlus = planFeature.user.hasCoachPlus(user);
      $scope.spaceTemplates.push.apply($scope.spaceTemplates, results[1]);
      $scope.pending = false;
    });
  }

  function onCreateTemplatesClick() {
    spaceTemplateModal.newTemplate();
  }

  function onRemoveClick(spaceTemplate) {
    spaceTemplate.remove()
      .then(() => {
        const index = $scope.spaceTemplates.indexOf(spaceTemplate);
        $scope.spaceTemplates.splice(index, 1);
      })
      .catch(() => toast.danger($translate.instant('CONTROLLERS.SPACE_TEMPLATES.DELETE_ERROR_MESSAGE')));
  }

  function onUpgradeClick() {
    paywallModal.templates();
  }
}

Controller.$inject = [
  '$scope',
  '$q',
  '$translate',
  'listLoader',
  'SpaceTemplate',
  'spaceTemplateModal',
  'paywallModal',
  'core.services.identity',
  'core.services.planFeature',
  'core.ui.toast'
];

export default {
  controller: Controller,
  template
};
