import { fetchPost } from "app/utils/request";

function PostViews() {

  PostViews.sendPostViews = function(post_views) {
    return fetchPost('post_views', { post_views }, { version: 1 });
  };

  PostViews.sendContentView = function(model) {
    return fetchPost('post_views', { post_views: [model] }, { version: 1 });
  };

  return PostViews;
}

PostViews.$inject = [];

export default PostViews;
