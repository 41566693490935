// TODO: Remove this after feed angular transition done

jQuery(function($) {
  function linkify(text, options) {
    this.options = {twitter: false};
    this.options = $.extend(this.options, options);

    return Autolinker.link(text, this.options);
  };

  $.fn.autolink = function(){
    return this.each(function(){
      $(this).html(linkify($(this).html()));
    });
  };
});
