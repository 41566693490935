
export default function EmitterFactory() {
  function EventEmitter() {
    this._subscribers = Object.create(null);
  }

  /**
    * Removes all attached listeners.
    *
    * @returns {EventEmitter}
    */
  EventEmitter.prototype.clear = function clear() {
    this._subscribers = Object.create(null);
    return this;
  };

  /**
    * Attaches a listener to an event.
    *
    * @param {string} event
    * @param {function} callback
    * @returns {EventEmitter}
    */
  EventEmitter.prototype.on = function on(event, callback) {
    if (typeof callback === 'function') {
      if (this._subscribers[event] == null) {
        this._subscribers[event] = [callback];
      } else {
        this._subscribers[event].push(callback);
      }
    }

    return this;
  };

  /**
    * Removes an listener from an event. If not callback is provided, all events are removed.
    *
    * @param {string} event
    * @param {function?} callback
    * @returns {EventEmitter}
    */
  EventEmitter.prototype.off = function off(event, callback) {
    if (this._subscribers[event] != null) {
      if (callback == null) {
        this._subscribers[event] = [];
      } else {
        this._subscribers[event] = this._subscribers[event].filter(function (cb) {
          return cb !== callback;
        });
      }
    }

    return this;
  };

  /**
    * Notifies all listeners of an event.
    *
    * @param {{ type: string }} event
    * @returns {EventEmitter}
    */
  EventEmitter.prototype.emit = function emit(event) {
    if (event != null) {
      var subscribers = this._subscribers[event.type];

      if (subscribers != null) {
        subscribers.forEach(function (subscriber) {
          subscriber(event);
        });
      }
    }

    return this;
  };

  return EventEmitter;
}
