/** @module directives/teams_groups */

/**
 * @class AddGroupMembersFlow
 *
 */
function AddGroupMembersFlowFactory($q, teamInviteFlow, groupModal) {
  function AddGroupMembersFlow(group) {
    this._group = group;
  }

  AddGroupMembersFlow.prototype.start = function start() {
    return groupModal.onboarding(this._group).result.then((e) => {
      if (e.requestMembers) {
        return this._requestMembers();
      }
      return $q.resolve(e.groupMembers);
    });
  };

  AddGroupMembersFlow.prototype._requestMembers = function _requestMembers() {
    return teamInviteFlow(this._group.team)
      .finally(() => this.start());
  };

  return function addGroupMembersFlow(group) {
    return new AddGroupMembersFlow(group).start();
  };
}

AddGroupMembersFlowFactory.$inject = ['$q', 'teamInviteFlow', 'groupModal'];

export default AddGroupMembersFlowFactory;
