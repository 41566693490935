/**
 * @module Directives.TeamsGroups
 *
 */
import { run } from 'app/utils/runloop';
import filter from 'lodash/filter';
import assign from 'lodash/assign';
import template from 'ngapp/templates/views/teams_groups/_group_member_select.html';

/**
 * @class GroupMemberSelect
 *
 */
function controller($scope, createPager, format) {
  $scope.disabled = $scope.$props.disabled || false;
  $scope.pending = true;
  $scope.members = { value: {}, paged: [], all: [] };
  $scope.query = '';
  $scope.filters = {};
  $scope.formatMemberSummary = format.memberSummary;
  $scope.onSelect = onSelect;
  $scope.onQuery = onQuery;
  $scope.onInviteButtonClick = onInviteButtonClick;
  $scope.onScroll = onScroll;
  $scope.$props.$onChanges = $onChanges;

  let pager = null;

  recreatePager().finally(() => run(() => $scope.pending = false));

  function onSelect(selected) {
    // update all list with new paged results
    const members = $scope.members.all;
    const newMembers = $scope.members.paged.filter(a => members.find(b => a.id === b.id) == null);
    $scope.members.all = [ ...members, ...newMembers ];

    const value = filter($scope.members.all, (member) => selected[member.id]);

    $scope.$props.onSelect({ value });
  }

  function onQuery(query) {
    $scope.filters.query = query;
    $scope.pending = true;
    recreatePager().finally(() =>
      run(() => $scope.pending = false)
    );
  }

  function onInviteButtonClick() {
    $scope.$props.onInvite();
  }

  function $onChanges(props) {
    if (props.disabled != null) {
      $scope.disabled = props.disabled.currentValue;
    }
  }

  function onScroll() {
    pager.next();
  }

  function recreatePager() {
    if (pager != null) {
      pager.destroy();
    }
    pager = createPager($scope.$props.groupId, assign($scope.filters));
    pager.onPage.attach((e) => run(() => {
      $scope.members.paged = $scope.members.paged.concat(e.data.results);
    }));
    return pager.get().then(members => {
      run(() => $scope.members.paged = members);
      return members;
    });
  }
}

controller.$inject = [ '$scope', 'memberForSelectPagerFactory', 'format' ];

export default {
  bindings: {
    groupId: '<',
    disabled: '<',
    onSelect: '&',
    onInvite: '&'
  },
  template,
  controller,
  controllerAs: '$props'
};
