
export default function ServerValidation() {
  function link(scope, element, attrs, ctrl) {
    scope.$watch(attrs.ngModel, function() {
      if (ctrl.$valid) { return; }
      ctrl.$setValidity('server', true);
    });
  }

  return {
    restrict: 'A',
    require: 'ngModel',
    link: link
  };
}
