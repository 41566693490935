
function Focus($timeout) {
  function link(scope, element, attrs) {
    attrs.$observe('focus', function(newValue){
      if (newValue === 'true') {
        $timeout(function() {
          element[0].focus();
        });
      }
    });
  }

  return {
    link: link
  };
}

Focus.$inject = ['$timeout'];

export default Focus;
