/**
 * @module Core.Services
 *
 */
import assign from 'lodash/assign';

/**
 * @class Space
 *
 */
function factory($property, $resource, railsSerializer) {
  var Space = $resource({
    url: '/api/v2/spaces',
    name: 'space',
    serializer: railsSerializer(function() {
      this.resource('userSettings', 'SpaceUserSettings');
    })
  }, [
    $resource.acl({
      delete: 'delete',
      edit: 'edit',
      leave: 'leave',
      makeAthlete: 'make_athlete',
      transfer: 'transfer_ownership'
    })
  ]);

  Space.transfer = function transfer(id, email) {
    const request = Space.$post(Space.$url(id, 'transfer_ownership'), { membership: { email: email }}, { rootWrapping: false })
      .then(() => ({}));
    return request;
  };

  Space.prototype.isOwnedByMember = function isOwnedByMember(member) {
    if (member != null) {
      return this.ownerId === member.id;
    }
    return false;
  };

  Space.update = function(space, props) {
    space = assign(space, props);
    return space.save();
  };

  Space.remove = function(space) {
    return Space.$delete(Space.$url({ id: space.id }));
  };

  $property.get(Space.prototype, 'isForAthlete', function() {
    return (this.type === 'athlete');
  });

  $property.get(Space.prototype, 'isForTeam', function() {
    return (this.type === 'team');
  });

  $property.get(Space.prototype, 'membersUrl', function() {
    return '/spaces/' + this.id + '/members';
  });

  $property.get(Space.prototype, 'scheduledPostsUrl', function() {
    return '/spaces/' + this.id + '/schedule';
  });

  $property.get(Space.prototype, 'scheduledPostsQueryParams', function() {
    return { spaceId: this.id };
  });

  $property.get(Space.prototype, 'timelineUrl', function() {
    return '/spaces/' + this.id;
  });

  $property.get(Space.prototype, 'caption', function() {
    return 'Space';
  });

  return Space;
}

factory.$inject = ['core.$property', 'core.services.$resource', 'railsSerializer'];

export default factory;
