/**
 * @module Directives.Teams
 *
 */
import some from 'lodash/some';
import unionBy from 'lodash/unionBy';
import { run } from 'app/utils/runloop';
import moment from 'moment';
import template from 'ngapp/templates/views/teams/_team_feed_thread.html';

/**
 * @class TeamFeedThread
 *
 */
function Controller($scope, $q, Post, createRepliesPager, toast, $translate, identity) {
  $scope.canPost = $scope.$props.canPost;
  $scope.canPostMedia = $scope.$props.canPostMedia;
  $scope.draftId = 'draft:post/' + $scope.$props.post.id + '/reply';
  $scope.post = $scope.$props.post;
  $scope.replies = $scope.post.replies;
  $scope.user = null;
  $scope.team = $scope.$props.team;
  $scope.isDemo = $scope.$props.isDemo;
  $scope.scheduledDate = scheduledDate;
  $scope.scheduledTime = scheduledTime;
  $scope.onEdit = onEdit;
  $scope.onLike = onLike;
  $scope.onMediaLockedClick = onMediaLockedClick;
  $scope.onUpgradeToPostClick = onUpgradeToPostClick;
  $scope.onRemove = onRemove;
  $scope.onReply = onReply;
  $scope.onReport = onReport;
  $scope.onTogglePin = onTogglePin;
  $scope.onMore = onMore;
  $scope.canReply = false;

  if ($scope.team != null && $scope.team != undefined) {
    $scope.canReply = $scope.team.can.reply && $scope.post.can.reply;
  } else {
    $scope.canReply = $scope.post.can.reply;
  }

  let pager = null;

  identity.request().then(user => run(() => {
    $scope.user = user;
  }));

  function onPage(e) {
    run(() => {
      $scope.replies = unionBy(e.data.results, $scope.replies, 'id');
    });
  }

  function onMore() {
    if (pager == null) {
      pager = createRepliesPager($scope.post.id, { cursor: $scope.post.replies[0].cursor });

      pager.get().then(results => run(() => {
        $scope.replies = unionBy(results, $scope.replies, 'id');
      }));

      pager.onPage.attach(onPage);
    } else {
      pager.next();
    }    
  }

  function scheduledDate() {
    return moment($scope.post.scheduledAt).format("MMMM D, YYYY");
  }

  function scheduledTime() {
    return moment($scope.post.scheduledAt).format("hh:mm a");
  }

  function onEdit(post) {
    $scope.$props.onEdit({ post });
  }

  function onLike(post) {
    $scope.$props.onLike({ post });
  }

  function onTogglePin() {
    $scope.$props.onTogglePin();
  }

  function onMediaLockedClick() {
    $scope.$props.onMediaLockedClick();
  }

  function onUpgradeToPostClick() {
    $scope.$props.onUpgradeToPostClick();
  }

  function onRemove(post) {
    const request = post.isReply
      ? $scope.post.removeReply(post.id)
      : Post.remove(post.id);

    $q.resolve(request).then(() => run(() => {
      $scope.$props.onRemove({ post });
    }));
  }

  function onReply(fields) {
    $scope.post.reply(fields).then(reply => run(() => {
      $scope.$props.onReply({ reply });
      if ($scope.replies === null || $scope.replies === undefined) {
        $scope.replies = [ reply ];
      } else if (!some($scope.replies, { id: reply.id })) {
        $scope.replies.push(reply);
      }
    }));
  }

  function onReport(event) {
    const request = event.post.isReply
      ? $scope.post.reportReply(event.post.id, event.message)
      : Post.report(event.post.id, event.message);

    $q.resolve(request).then(() => run(() => {
      toast.success($translate.instant('TEAMS.REPORT_POST_MODAL.SUCCESS_TOAST'));
      $scope.$props.onReport({ event });
    }));
  }
}

Controller.$inject = [
  '$scope',
  '$q',
  'core.services.Post',
  'repliesPagerFactory',
  'core.ui.toast',
  '$translate',
  'core.services.identity'
];

export default {
  bindings: {
    canPost: '<',
    canPostMedia: '<',
    post: '<',
    user: '<',
    team: '<',
    isDemo: '<',
    onEdit: '&',
    onLike: '&',
    onMediaLockedClick: '&',
    onUpgradeToPostClick: '&',
    onRemove: '&',
    onReply: '&',
    onReport: '&',
    onTogglePin: '&',
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
