/**
 * @module Directives.Segments
 *
 */
import template from 'ngapp/templates/views/segments/_new_segment_modal.html';

/**
 * @class NewSegmentModal
 *
 */
function Controller($scope, $location, validation, Segment) {
  $scope.fields = {
    name: ''
  };
  $scope.errors = {};
  $scope.pending = false;
  $scope.onCancel = onCancel;
  $scope.onSubmit = onSubmit;

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onSubmit(e) {
    e.preventDefault();

    var validationResult = validate($scope.fields);

    if (validationResult.isValid) {
      $scope.pending = true;

      new Segment($scope.fields).create()
        .then(function (segment) {
          $location.path('/lists/' + segment.id + '/spaces/edit');
          $scope.$props.modal.close({
            segment: segment
          });
        })
        .finally(function () {
          $scope.pending = false;
        });
    }

    $scope.errors = validationResult.errors;
  }

  function validate(fields) {
    var results = {
      errors: {},
      isValid: true
    };

    if (validation.isEmpty(fields.name)) {
      results.errors.name = 'required';
      results.isValid = false;
    }

    return results;
  }
}

Controller.$inject = ['$scope', '$location', 'core.validate', 'Segment'];

export default {
  bindings: {
    modal: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
