
import template from 'ngapp/templates/views/teams_groups/_group_add_members_button.html';

function Controller($scope, addGroupMembersFlow) {
  $scope.onClick = onClick;

  function onClick() {
    addGroupMembersFlow($scope.$props.group)
      .then(function (groupMembers) {
        $scope.$props.onAddMembers({
          groupMembers: groupMembers
        });
      });
  }
}

Controller.$inject = ['$scope', 'addGroupMembersFlow'];

export default {
  bindings: {
    group: '<',
    onAddMembers: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
