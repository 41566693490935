
import assign from 'lodash/assign';

function SpaceFeedPagerFactory(Post, createPager) {
  var settings = {
    limit: 16,
    throttle: 10000
  };

  return function create(id, filters) {
    function load(request) {
      return Post.querySpaceFeed(
        id,
        request.page,
        request.limit,
        assign({}, settings, filters, {
          replyLimit: Post.repliesPerPost
        })
      );
    }

    return createPager(load, settings);
  };
}

SpaceFeedPagerFactory.$inject = ['core.services.Post', 'core.services.pagerFactory'];

export default SpaceFeedPagerFactory;
