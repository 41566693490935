
export default function PollFactory() {
  var options = {
    interval: 1000
  };

  function $get($q, $timeout) {
    function poll(fn, signal, interval) {
      return $q
        .when(fn())
        .then(function (value) {
          if (signal(value)) {
            return $q.resolve(value);
          }

          return $timeout(function () {
            return poll(fn, signal, interval);
          }, interval || options.interval, false);
        });
    }

    return poll;
  }

  $get.$inject = ['$q', '$timeout'];

  return {
    options: options,
    $get: $get
  };
}
