
import some from 'lodash/some';
import find from 'lodash/find';
import template from 'ngapp/templates/views/teams/_transfer_team_modal.html';

function Controller($scope, TeamMember, Team) {
  $scope.team = $scope.$props.team;
  $scope.coaches = [];
  $scope.fields = {
    email: '',
    teamName: ''
  };
  $scope.errors = {};
  $scope.pending = true;
  $scope.submitting = false;
  $scope.onCancel = onCancel;
  $scope.onSubmit = onSubmit;

  TeamMember.get({ teamId: $scope.team.id }, { type: 'coach' })
    .then(function (members) {
      $scope.coaches = members;
      $scope.pending = false;
    });

  function isCoachEmail(email) {
    return some($scope.coaches, { email: email });
  }

  function isTeamName(name) {
    return name === $scope.team.name;
  }

  function isOwner(email) {
    var coach = find($scope.coaches, { email: email });

    if (coach != null) {
      return $scope.$props.team.isOwnedByMember(coach);
    }

    return false;
  }

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onSubmit(e) {
    e.preventDefault();

    var validationResult = validate($scope.fields);

    if (validationResult.isValid) {
      $scope.submitting = true;
      $scope.pending = true;

      Team.transfer($scope.team.id, $scope.fields.email)
        .then(function() {
          $scope.$props.modal.close({
            coach: find($scope.coaches, { email: $scope.fields.email })
          });
        });
    }

    $scope.errors = validationResult.errors;
  }

  function validate(fields) {
    var results = {
      errors: {},
      isValid: true
    };

    if (!isCoachEmail(fields.email)) {
      results.isValid = false;
      results.errors.email = 'invalid_member_email';
    } else if(isOwner(fields.email)) {
      results.isValid = false;
      results.errors.email = 'already_owner';
    }

    if (!isTeamName(fields.teamName)) {
      results.isValid = false;
      results.errors.teamName = 'team_name_mismatch';
    }

    return results;
  }
}

Controller.$inject = [ '$scope', 'core.services.TeamMember', 'core.services.Team' ];

export default {
  bindings: {
    modal: '<',
    team: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
