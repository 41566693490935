/** @module core/services */
import { fetchPost, templateUrl, bindAngularResource } from 'app/utils/request';

/**
 * @class Invitation
 *
 */
function factory($location, $resource, $createInvitationCachePolicyFactory, bulkInvitationAccept) {
  const Invitation = $resource({
    url: '/api/v2/invitations',
    name: 'invitation'
  });

  const TeamInvitation = $resource({
    url: '/api/v2/teams/{{teamId}}/memberships/{{membershipId}}',
    name: 'teamInvitation'
  });

  const SpaceInvitation = $resource({
    url: '/api/v1/spaces/{{spaceId}}/invitations/{{invitationId}}',
    name: 'spaceInvitation'
  });

  const URL = {
    resend: templateUrl('invitations/{id}/resend')
  };

  Invitation.$cachePolicy = $createInvitationCachePolicyFactory();

  Invitation.all = function all() {
    return Invitation.query();
  };

  Invitation.outgoing = function outgoing(filters) {
    return Invitation.query(Object.assign({ direction: 'outgoing' }, filters));
  };

  Invitation.outgoingCount = function outgoingCount() {
    return Invitation.$get(Invitation.$url() + '/count', { direction: 'outgoing' });
  };

  Invitation.resend = function resend(id) {
    return fetchPost(URL.resend({id: id}))
      .then(data => bindAngularResource(data, this));
  };

  Invitation.accept = function accept(id) {
    return Invitation.$post(Invitation.$url(id, 'accept')).then(resp => {
      Invitation.$cachePolicy.onAccept();
      return resp;
    });
  };

  Invitation.acceptAll = function acceptAll() {
    return bulkInvitationAccept.create();
  };

  Invitation.decline = function decline(id) {
    return Invitation.$post(Invitation.$url(id, 'decline'));
  };

  Invitation.cancel = function cancel({ object, id, teamId, spaceId, membershipId }) {
    switch (object) {
      case 'team_invitation':
        return TeamInvitation.$delete(TeamInvitation.$url({ teamId, membershipId }));
      case 'space_invitation':
        return SpaceInvitation.$delete(SpaceInvitation.$url({ spaceId, invitationId: id }));
    }
  };

  Invitation.prototype.goto = function goto() {
    if (this.object === 'team_invitation') {
      $location.path('/groups/' + this.teamId);
    } else if (this.object === 'space_invitation') {
      $location.path('/spaces/' + this.spaceId);
    }
  };

  return Invitation;
}

factory.$inject = [
  '$location',
  'core.services.$resource',
  'core.services.cache.$invitationCachePolicyFactory',
  'core.services.bulkInvitationAccept'
];

export default factory;
