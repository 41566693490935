
import template from 'ngapp/templates/views/teams_groups/_new_group_button.html';

function Controller($scope, newGroupFlow) {
  $scope.onClick = onClick;

  function onClick() {
    newGroupFlow($scope.$props.team)
      .then(function(e) {
        $scope.$props.onCreate({ group: e.group });
      });
  }
}

Controller.$inject = ['$scope', 'newGroupFlow'];

export default {
  bindings: {
    team: '<',
    onCreate: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
