/**
 * @module Core.Services.PlanFeature
 *
 */
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import { deprecate } from 'app/utils/debug';

/**
 * @class $client
 *
 */
function factory() {
  function hasPermission(resource, value) {
    return includes(pickAsArray(resource, 'permissions'), value);
  }

  function hasPlanPermission(resource, value) {
    let hasPermission = includes(pickAsArray(resource, 'planPermissions'), value);
    if (!hasPermission) {
      hasPermission = includes(pickAsArray(resource, 'plan_permissions'), value);
      if (hasPermission) {
        deprecate(`hasPlanPermission requires serialized data. Migrate <resource:${resource.object}> to new format.`, '2.0.0');
      }
    }
    return hasPermission;
  }

  // this needs to go after bulk posts gets reworked.
  function hasUnserializedPlanPermission(resource, value) {
    deprecate('hasUnserializedPlanPermission is deprecated, user hasPlanPermission instead', '2.0.0');
    return includes(pickAsArray(resource, 'plan_permissions'), value);
  }

  function pickAsArray(object, key) {
    var value = [];

    if (object != null) {
      if (isArray(object[key])) {
        value = object[key];
      }
    }

    return value;
  }

  return {
    hasPermission: hasPermission,
    hasPlanPermission: hasPlanPermission,
    hasUnserializedPlanPermission: hasUnserializedPlanPermission
  };
}

factory.$inject = [];

export default factory;
