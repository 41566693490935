/** @module directives/teams */
import template from 'ngapp/templates/views/teams/_team_member_invite_modal.html';

/**
 * @class TeamMemberInviteModal
 *
 */
function Controller($scope) {
  $scope.pending = false;
  $scope.onCancel = onCancel;
  $scope.onInviteByEmailClicked = onInviteByEmailClicked;
  $scope.onInviteByLinkClicked = onInviteByLinkClicked;
  $scope.onImportFileClicked = onImportFileClicked;

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onInviteByEmailClicked() {
    $scope.$props.modal.close({ type: 'email' });
  }

  function onInviteByLinkClicked() {
    $scope.$props.modal.close({ type: 'link' });
  }

  function onImportFileClicked() {
    $scope.$props.modal.close({ type: 'csv' });
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    modal: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
