
function factory($client) {
  function canAddTeamMedia(team) {
    return $client.hasPlanPermission(team, 'post_media');
  }

  function canManageGroupControls(team) {
    return $client.hasPlanPermission(team, 'manage_group_controls');
  }

  return {
    canAddTeamMedia: canAddTeamMedia,
    canManageGroupControls: canManageGroupControls
  };
}

factory.$inject = ['core.services.planFeature.$client'];

export default factory;
