/**
 * @module Core.Services
 *
 */
import { isNone } from 'app/utils';
import { deserialize } from 'app/utils/request/model';
import { buildUrl, buildQuery, getQuerySymbol } from 'app/utils/request/url';
import { setHeaders } from 'app/utils/request/createRequest';
import { getCacheKey } from 'app/utils/auth';
import env from 'config/environment';

/**
 * @class Resource
 *
 */
function factory($property, $acl, resource) {
  /**
    * Creates custom rails resource factory.
    *
    * @param {object} config
    * @param {function[]} extensions
    * @returns {Resource}
    */
  function $resource(config, extensions) {
    if (config.url) {
      const [ version, endpoint ] = config.url.replace(/^\/?api\/v([0-9.]+)\/([\s\S]*)/, '$1 $2').split(' ');
      config.url = buildUrl(endpoint, {}, version);
    }

    const $config = Object.assign(config || {}, { skipRequestProcessing: true });
    const $extensions = extensions || [];
    const Resource = resource($config);

    Resource._$http = Resource.$http;
    Resource.$http = function (httpConfig, context, resourceConfigOverrides) {
      if (httpConfig.params && typeof httpConfig.params === 'string') {
        httpConfig.paramSerializer = () => httpConfig.params;
      }

      if (env.INCLUDE_SERVICE_WORKER) {
        if (httpConfig.method === 'get') {
          if (httpConfig.params && typeof httpConfig.params === 'object') {
            httpConfig.params = buildQuery(deserialize(httpConfig.params));
            httpConfig.paramSerializer = () => httpConfig.params;
          }

          if (httpConfig.params && typeof httpConfig.params === 'string') {
            httpConfig.params += `&_ca=${getCacheKey()}`;
          } else {
            httpConfig.url += `${getQuerySymbol(httpConfig.url)}_ca=${getCacheKey()}`;
          }
        }
      }

      return Resource._$http(httpConfig, context, resourceConfigOverrides);
    };

    Resource.processParameters = function(queryParams) {
      if (isNone(queryParams)) {
        return "";
      }
      return buildQuery(deserialize(queryParams));
    };

    Resource.config.httpConfig.headers = setHeaders(Resource.config.httpConfig.headers, config.noAuth);
    Resource.config.httpConfig.withCredentials = false;

    $extensions.forEach(function (fn) {
      fn(Resource);
    });

    return Resource;
  }

  /**
    * Acl extensions, that gives a resource ACL support as a 'can' property.
    *
    * @param {object} actions
    * @returns {function}
    */
  $resource.acl = function acl(actions) {
    return function extend(Resource) {
      Resource.prototype.$$can = null;

      $property.get(Resource.prototype, 'can', function() {
        if (this.$$can == null) {
          this.$$can = $acl.interpolate(actions, this.permissions || []);
        }

        return this.$$can;
      });
    };
  };

  return $resource;
}

factory.$inject = [ 'core.$property', 'core.services.$acl', 'railsResourceFactory' ];

export default factory;
