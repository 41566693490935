
import CollapseOutAnimation from './animations/collapse_out';
import EnterLeftAnimation from './animations/enter_left';
import EnterRightAnimation from './animations/enter_right';
import ExplodeAnimation from './animations/explode';
import FadeInAnimation from './animations/fade_in';
import ImplodeAnimation from './animations/implode';
import AnimateDropdownFactory from './dropdown/$animate';
import ToggleDropdown from './dropdown/toggle';
import AnimateModalFactory from './modal/$animate';
import CompileModalFactory from './modal/$compile';
import DomModalFactory from './modal/$dom';
import EventsModalFactory from './modal/$events';
import ManagerModalFactory from './modal/$manager';
import StackModalFactory from './modal/$stack';
import ModalProvider from './modal/modal';
import pills from './pills';
import ResizeProvider from './resize';
import ToastAnimation from './toast/animate';
import ToastController from './toast/controller';
import ToastProvider from './toast/toast';

export default function(__angular) {
  __angular.module('core.ui')
    .animation('.collapse-out', CollapseOutAnimation)
    .animation('.enter-left', EnterLeftAnimation)
    .animation('.enter-right', EnterRightAnimation)
    .animation('.explode', ExplodeAnimation)
    .animation('.fade-in', FadeInAnimation)
    .animation('.implode', ImplodeAnimation)
    .animation('.toast', ToastAnimation);

  __angular.module('core.ui')
    .factory('core.ui.dropdown.$animate', AnimateDropdownFactory)
    .factory('core.ui.modal.$animate', AnimateModalFactory)
    .factory('core.ui.modal.$compile', CompileModalFactory)
    .factory('core.ui.modal.$dom', DomModalFactory)
    .factory('core.ui.modal.$events', EventsModalFactory)
    .factory('core.ui.modal.$manager', ManagerModalFactory)
    .factory('core.ui.modal.$stack', StackModalFactory);

  __angular.module('core.ui')
    .directive('dropdownToggle', ToggleDropdown);

  __angular.module('core.ui')
    .provider('$modal', ModalProvider)
    .provider('core.ui.toast', ToastProvider)
    .provider('core.ui.resize', ResizeProvider);

  __angular.module('core.ui')
    .component('pills', pills);

  __angular.module('core.ui')
    .value('core.ui.toast.$controller', ToastController);
}
