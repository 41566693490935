/**
 * @module Directives.TeamsGroups
 *
 */

/**
 * @class GroupFeedPagerFactory
 *
 */
function factory(Post, createPager) {
  const settings = {
    limit: 20,
    throttle: 10000
  };

  function serialize(filters) {
    const result = {
      types: [],
      orderBy: ""
    };
    if (filters == null) {
      return result;
    }
    return Object.assign({}, result, filters);
  }

  return function create(id, filters) {
    function load(request) {
      const query = Object.assign({}, serialize(filters), {
        page: request.page,
        perPage: request.limit,
        replyLimit: Post.repliesPerPost
      });

      return Post.queryGroupFeed(id, query);
    }

    return createPager(load, settings);
  };
}

factory.$inject = ['core.services.Post', 'core.services.pagerFactory'];

export default factory;
