/**
 * @module Core.Services
 *
 */

/**
 * @class SpaceMember
 *
 */
function factory($resource) {
  const SpaceMember = $resource({
    url: '/api/v2/spaces/{{spaceId}}/members/{{id}}',
    name: 'member'
  }, [
    $resource.acl({
      becomeAthlete: 'become_athlete',
      beDeleted: 'be_deleted',
      beManaged: 'be_managed'
    })
  ]);

  SpaceMember.per_page = 20;

  SpaceMember.remove = function(user, spaceId) {
    var member = new SpaceMember(user);
    member.spaceId = spaceId;

    return member.remove();
  };

  return SpaceMember;
}

factory.$inject = ['core.services.$resource'];

export default factory;
