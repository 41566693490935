/**
 * @module Directives
 *
 */
import template from 'ngapp/templates/views/teams/_team_header.html';

/**
 * @class TeamHeader
 *
 */
function Controller($scope) {
  $scope.team = null;

  $scope.$props.$onChanges = function(props) {
    if (props.team != null) {
      $scope.team = props.team.currentValue;
    }
  };
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    team: '<',
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
