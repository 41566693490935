/**
 * @module Core.UI.Modal
 *
 */
import { run } from 'app/utils/runloop';

/***/
const elementId = 'legacy-modal-dialog';

/**
 * @class $dom
 *
 */
function factory($, $q, $document, $animate) {
  const $body = $document.find('body');
  const $page = $body.find('.site-wrap');
  let active = false;

  let $container = $document.find('#' + elementId);
  if (!($container && $container.length)) {
    $container = $(`
      <div id="${elementId}" class="modal-window" tabindex="0">
        <div class="modal-backdrop"></div>
      </div>
    `);
    $body.append($container);
  }

  const $backdrop = $container.find('.modal-backdrop');

  $body.off('focusin.modal');
  $body.on('focusin.modal', (e) => run(() => {
    if (active) {
      if (!$container[0].contains(e.target)) {
        setTimeout(() => $container.focus());
      }
    }
  }));

  function insert($el) {
    if ($document[0].activeElement != null) {
      $document[0].activeElement.blur();
    }
    active = true;
    $container.show();
    $page.attr('aria-hidden', true);

    return $q.all([
      $animate.insert($el, $container),
      $animate.show($backdrop)
    ]).then(() => run(() => {
      $container.focus();
    }));
  }

  function remove($el) {
    active = false;

    return $q.all([
      $animate.remove($el),
      $animate.hide($backdrop)
    ]).then(() => run(() => {
      $container.hide();
      $page.attr('aria-hidden', false);
    }));
  }

  function clickOff(getElementCallback) {
    $backdrop.on('click.modal', () => {
      const $el = getElementCallback();

      return $q.all([
        $el != null ? $animate.remove($el) : null,
        $animate.hide($backdrop)
      ]).then(() => run(() => {
        $container.hide();
        $page.attr('aria-hidden', false);
      }));
    });
  }

  return { insert, remove, clickOff };
}

factory.$inject = ['jQuery', '$q', '$document', 'core.ui.modal.$animate'];

export default factory;

