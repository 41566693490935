/** @module resources */
import { fetchGet } from 'app/utils/request';

function Sports() {
  this.all = function() {
    return fetchGet('sports', {}, { version: 1 });
  };
  return this;
}

Sports.$inject = [];

export default Sports;
