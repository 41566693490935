/**
 * @module Directives.Paywalls
 *
 */
import { isBlank } from 'app/utils';
import template from 'ngapp/templates/views/paywalls/_modal_body.html';

/**
 * @class ModalBody
 *
 */
function Controller($scope) {
  $scope.icon = $scope.$props.icon;
  $scope.iconSmall = $scope.$props.iconSmall;
  $scope.title = isBlank($scope.$props.title) ? null : $scope.$props.title;
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    icon: '@',
    iconSmall: '<',
    title: '@'
  },
  template,
  controller: Controller,
  controllerAs: '$props',
  transclude: true
};
