/**
 * @module Directives
 *
 */
import template from 'ngapp/templates/directives/_team_invitation.html';

/**
 * @class TeamInvitation
 *
 */
function TeamInvitation($location) {
  function link(scope, element, attrs) { // eslint-disable-line no-unused-vars
    scope.visit = function() {
      $location.path('/groups/' + scope.invitation.teamId);
    };
  }

  return {
    template,
    replace: true,
    restrict: 'E',
    scope: {
      invitation: '='
    },
    link: link
  };
}

TeamInvitation.$inject = ['$location'];

export default TeamInvitation;
