/**
 * @module Directives
 *
 */
import template from 'ngapp/templates/views/_public-wrap.html';
import edufii_logo from 'app/images/edufii-logo.png';
import config from 'config/environment';

/**
 * @class SiteWrap
 *
 */
function Controller($root, $scope) {
  $scope.supportUrl = config.SUPPORT_URL;
  $scope.edufii_logo = edufii_logo;
  $scope.currentYear = config.CURRENT_YEAR;

  $scope.navigateHome = () => {
    window.location = '/';
  };

  const search = window.location.search;
  $scope.hideNav = /(\?|&)frame/.test(search);
}

Controller.$inject = [
  '$rootScope',
  '$scope',
];

export default {
  template,
  controller: Controller,
  transclude: true
};
