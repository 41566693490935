
function TransferTeamFlowFactory($location, transferTeamModal) {
  function TransferTeamFlow(team) {
    this._team = team;
  }

  TransferTeamFlow.prototype.start = function start() {
    var self = this;

    return transferTeamModal.transferOwnership(self._team).result
      .then(function (e) {
        $location.path('/groups');
        return self._showConfirmation(e.coach);
      });
  };

  TransferTeamFlow.prototype._showConfirmation = function _showConfirmation(coach) {
    return transferTeamModal.transferComplete(coach);
  };

  return function start(team) {
    return new TransferTeamFlow(team).start();
  };
}

TransferTeamFlowFactory.inject = ['$location', 'transferTeamModal'];

export default TransferTeamFlowFactory;
