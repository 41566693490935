/**
 * @module Core.Services
 *
 */
import debounce from 'lodash/debounce';

/**
 * @class Draft
 *
 */
export default function provider() {
  var options = {
    key: 'drafts',
    syncDelay: 500
  };

  function $get($store) {
    function DraftService(options) {
      this._options = options;
      this._collection = $store.getOrDefault(this._options.key, {});
      this._sync = debounce(this._sync.bind(this), this._options.syncDelay);
    }

    /**
      * Returns a draft from the collection.
      *
      * @param {string} key
      * @returns {*}
      */
    DraftService.prototype.retrieve = function retrieve(key) {
      if (this._collection[key] == null) {
        this.save(key, null);
      }

      return this._collection[key].value;
    };

    /**
      * Saves a draft.
      *
      * @param {string} key
      * @param {*} value
      */
    DraftService.prototype.save = function save(key, value) {
      if (this._collection[key] == null) {
        this._collection[key] = { key: key, value: value };
      } else {
        this._collection[key].value = value;
      }

      this._sync();
    };

    /**
      * Syncs the draft to the backing store.
      */
    DraftService.prototype._sync = function _sync() {
      $store.put(this._options.key, this._collection);
    };

    return new DraftService(options);
  }

  $get.$inject = ['core.services.$store'];

  return {
    options: options,
    $get: $get
  };
}
