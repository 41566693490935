
function ClientHelper($, $window) {
  this.tablet = function() {
    return $($window).width() < 1024;
  };

  this.smartphone = function() {
    return $($window).width() < 768;
  };

  return this;
}

ClientHelper.$inject = ['$', '$window'];

export default ClientHelper;
