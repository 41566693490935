/**
 * @module Core.UI.Modal
 *
 */

/**
 * @class $animate
 *
 */
function factory($css) {
  function insert($el, $parent) {
    $parent.append($el);

    return $css($el, {
      from: { display: 'block', opacity: 0, transform: 'translateY(-100%)' },
      to: { opacity: 1, transform: 'translateY(0)' },
      duration: 0.2,
      easing: 'ease-in'
    })
    .start();
  }

  function remove($el) {
    return $css($el, {
      from: { display: 'block', opacity: 1, transform: 'translateY(0)' },
      to: { opacity: 0, transform: 'translateY(-100%)' },
      duration: 0.25,
      easing: 'ease-out'
    })
    .start()
    .then(() => $el.remove());
  }

  function show($el) {
    return $css($el, {
      from: { opacity: 0 },
      to: { opacity: 1 },
      duration: 0.2,
      easing: 'ease-in'
    })
    .start();
  }

  function hide($el) {
    return $css($el, {
      from: { opacity: 1 },
      to: { opacity: 0 },
      duration: 0.25,
      easing: 'ease-out'
    })
    .start();
  }

  return {
    insert: insert,
    remove: remove,
    hide: hide,
    show: show
  };
}

factory.$inject = ['$animateCss'];

export default factory;
