/**
 * @module Directives.Library
 *
 */
import { isBlank } from 'app/utils';
import { getType } from 'app/utils/file';

/**
 * @class MediaHelpers
 *
 */
function mediaHelpers() {

  function createMediaItemCSS(resource) {
    var url = getPreviewUrl(resource);

    return {
      'background-image': 'url(' + url + ')'
    };
  }

  function createMediaSpriteClassName(resource) {
    return getSpriteClassName(resource.source);
  }

  function createMediaItemIconClassName(resource) {
    return getIconClassName(resource.type);
  }

  function createMediaItemClassNames(attachment, selected) {
    return [{
      'selected': selected
    },
    getTypeClassName(attachment.source || attachment.originalUrl || attachment.url)
    ];
  }

  function getAudioUrl(resource) {
    if (resource == null) {
      return null;
    }

    var url = null;

    if (resource.type === 'audio') {
      if (resource.versions != null) {
        if (!isBlank(resource.versions.mp3)) {
          url = resource.versions.mp3.url;
        }
      }
    }

    return url || resource.url;
  }

  /**
    * Computes a media spirte className based on filename.
    *
    * @param {string} filename
    * @returns {string}
    */
  function getSpriteClassName(filename) {
    switch (getType(filename)) {
      case 'doc':
        return 'media-sprite-doc';
      case 'xls':
        return 'media-sprite-xls';
      case 'ppt':
        return 'media-sprite-ppt';
      case 'pdf':
        return 'media-sprite-pdf';
      case 'video':
        return 'media-sprite-vid';
      case 'audio':
        return 'media-sprite-mic';
      case 'image':
        return 'media-sprite-img';
    }

    return '';
  }

  /**
    * Computes a media icon className based on type.
    *
    * @param {string} type
    * @returns {string}
    */
  function getIconClassName(type) {
    switch (type) {
      case 'video':
        return 'ico-video';
      case 'audio':
        return 'ico-microphone';
      case 'image':
        return 'ico-camera';
      case 'datafile':
        return 'ico-document';
    }

    return '';
  }

  function getTypeClassName(filename) {
    switch (getType(filename)) {
      case 'doc':
        return 'media-type-doc';
      case 'xls':
        return 'media-type-xls';
      case 'ppt':
        return 'media-type-ppt';
      case 'pdf':
        return 'media-type-pdf';
      case 'video':
        return 'media-type-video';
      case 'audio':
        return 'media-type-audio';
      case 'image':
        return 'media-type-image';
    }
  }

  function getPreviewUrl(resource) {
    if (resource == null) {
      return null;
    }

    var url = null;

    if (resource.type === 'video') {
      if (resource.thumbnails != null) {
        if (!isBlank(resource.thumbnails.default)) {
          url = resource.thumbnails.default;
        }
      }
    } else if (resource.type === 'image') {
      if (!isBlank(resource.mediumUrl)) {
        url = resource.mediumUrl;
      } else {
        url = resource.originalUrl;
      }
    }

    return url;
  }

  function getVideoUrls(resource) {
    if (resource == null) {
      return null;
    }

    var result = [];

    if (resource.type === 'video') {
      if (resource.versions != null) {
        if (!isBlank(resource.versions.default)) {
          result.push(resource.versions.default.url);
        }
      }
    }

    if (result.length === 0) {
      result.push(resource.originalUrl);
    }

    return result;
  }

  function toAttachment(resource) {
    return {
      id: resource.id,
      url: resource.source,
      type: resource.type,
      tags: resource.tags,
      thumbnail: getPreviewUrl(resource),
      filename: resource.filename || '',
      resource: resource
    };
  }

  return {
    createMediaItemCSS: createMediaItemCSS,
    createMediaSpriteClassName: createMediaSpriteClassName,
    createMediaItemIconClassName: createMediaItemIconClassName,
    createMediaItemClassNames: createMediaItemClassNames,
    getAudioUrl: getAudioUrl,
    getSpriteClassName: getSpriteClassName,
    getIconClassName: getIconClassName,
    getTypeClassName: getTypeClassName,
    getPreviewUrl: getPreviewUrl,
    getVideoUrls: getVideoUrls,
    toAttachment: toAttachment
  };
}

mediaHelpers.$inject = [];

export default mediaHelpers;
