/** @module core/services */
import assign from 'lodash/assign';

/**
 * @class TeamGroup
 *
 */
function factory($property, $resource, $cache, TeamGroupMember, TeamMember, $bulkTeamGroupAddAll, $createTeamGroupCachePolicy) {
  const TeamGroup = $resource({
    url: '/api/v2/team_groups',
    name: 'team_group',
    httpConfig: {
      cache: $cache
    }
  }, [
    $resource.acl({
      delete: 'delete',
      edit: 'edit',
      manageMembers: 'manage_members',
      post: 'post',
      leave: 'leave',
      viewMembers: 'view_members'
    })
  ]);

  TeamGroup.$cachePolicy = $createTeamGroupCachePolicy(TeamGroup);

  TeamGroup.all = function all(teamId) {
    return TeamGroup.query({ teamId });
  };

  TeamGroup.reload = function reload(teamId) {
    TeamGroup.$cachePolicy.onRemoveAll(teamId);
    return TeamGroup.all(teamId);
  };

  TeamGroup.joinable = function joinable(teamId) {
    return TeamGroup.$get(TeamGroup.$url({}, '/joinable'), { teamId });
  };

  TeamGroup.reloadJoinable = function joinable(teamId) {
    TeamGroup.$cachePolicy.onRemoveAll(teamId);
    return TeamGroup.joinable(teamId);
  };

  TeamGroup.findNonMembers = function findNonMembers(id) {
    return TeamMember.$get(TeamGroup.$url({ id: id }, '/members_for_select'));
  };

  TeamGroup.create = function create(properties) {
    const request = TeamGroup.$post(TeamGroup.$url(), properties);
    request.then((group) => {
      TeamGroup.$cachePolicy.onCreate(group.id, group.team.id);
    });
    return request;
  };

  TeamGroup.update = function update(group, properties) {
    const entity = assign(group, properties);
    const request = entity.update();
    request.then(() => {
      TeamGroup.$cachePolicy.onUpdate(group.id, group.team.id);
    });
    return request;
  };

  TeamGroup.remove = function remove(group) {
    const request = group.$delete(TeamGroup.$url({ id: group.id }));
    request.then(() => {
      TeamGroup.$cachePolicy.onRemove(group.id, group.team.id);
    });
    return request;
  };

  TeamGroup.leave = function leave(group) {
    const leaveRequest = TeamGroupMember.leave(group.id);
    leaveRequest.then(() => {
      TeamGroup.$cachePolicy.onUpdate(group.id, group.team.id);
    });
    return leaveRequest;
  };

  TeamGroup.join = function join(group) {
    const joinRequest = TeamGroup.$post(TeamGroup.$url({ id: group.id }, '/join'));
    joinRequest.then(() => {
      TeamGroup.$cachePolicy.onUpdate(group.id, group.team.id);
    });
    return joinRequest;
  };

  TeamGroup.addAllTeamMembers = function addAllTeamMembers(group) {
    const job = $bulkTeamGroupAddAll.add(group.id);
    job.result.then(() => {
      TeamGroup.$cachePolicy.onUpdate(group.id, group.team.id);
    });
    return job;
  };

  TeamGroup.prototype.addMembersByIds = function addMembersByIds(ids) {
    const request = TeamGroupMember.createFromMemberIds(this.id, ids);
    request.then(() => {
      TeamGroup.$cachePolicy.onUpdate(this.id, this.team.id);
    });
    return request;
  };

  $property.get(TeamGroup.prototype, 'scheduledPostsUrl', function() {
    return '/schedule';
  });

  $property.get(TeamGroup.prototype, 'scheduledPostsQueryParams', function() {
    return { teamGroupId: this.id };
  });

  $property.get(TeamGroup.prototype, 'timelineUrl', function() {
    return '/groups/' + this.team.id + '/channels/' + this.id;
  });

  $property.get(TeamGroup.prototype, 'caption', function() {
    return 'Channel';
  });

  $property.get(TeamGroup.prototype, 'membersUrl', function() {
    return '/groups/' + this.team.id + '/channels/' + this.id + '/members';
  });

  $property.get(TeamGroup.prototype, 'teamUrl', function() {
    return '/groups/' + this.team.id;
  });

  $property.get(TeamGroup.prototype, 'search', function() {
    return this.name.toLowerCase();
  });

  return TeamGroup;
}

factory.$inject = [
  'core.$property',
  'core.services.$resource',
  'core.services.$cache',
  'core.services.TeamGroupMember',
  'core.services.TeamMember',
  'core.services.bulkTeamGroupAddAll',
  'core.services.cache.$teamGroupCachePolicyFactory'
];

export default factory;
