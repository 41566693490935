/**
 * @module Directives.Spaces
 *
 */
import template from 'ngapp/templates/views/spaces/_space_header.html';

/**
 * @class SpaceHeader
 *
 */
function Controller($scope) {
  $scope.space = $scope.$props.space;
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    space: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
