/**
 * @module Directives.Invitations
 *
 */

/**
 * @class InvitationModalServices
 *
 */
function InvitationModal($modal) {
  function spaceCreatedCopyPhoneInviteLink(inviteeName, inviteLink) {
    return $modal.open({
      template: '<space-created-phone-invitation-copy-link-modal invitee-name="inviteeName" invite-link="inviteLink" modal="modal"></space-created-phone-invitation-copy-link-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
        $local.inviteeName = inviteeName;
        $local.inviteLink = inviteLink;
      }]
    });
  }

  function copyPhoneInviteLink(inviteLink) {
    return $modal.open({
      template: '<phone-invitation-copy-link-modal invite-link="inviteLink" modal="modal"></phone-invitation-copy-link-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
        $local.inviteLink = inviteLink;
      }]
    });
  }

  return {
    spaceCreatedCopyPhoneInviteLink: spaceCreatedCopyPhoneInviteLink,
    copyPhoneInviteLink: copyPhoneInviteLink
  };
}

InvitationModal.inject = ['$modal'];

export default InvitationModal;
