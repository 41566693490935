
import FastClick from 'fastclick';

String.prototype.pluralize = function(count, plural) {
  if (plural == null)
    plural = this + 's';

  return (count == 1 ? this : plural);
};

Array.removeObj = function(array, value) {
  var index = array.indexOf(value);
  if (index >=0)
    array.splice(index, 1);
  return value;
};

Array.findObj = function(array, detectFunc) {
  var foundValue = null;

  array.forEach(function(item) {
    if (foundValue) return;

    if (detectFunc(item)) {
      foundValue = item;
    }
  });

  return foundValue;
};

window.$(function(){
  FastClick.attach(document.body);

  window.$('.open-tooltip').tooltip();
  window.$('.open-popover').popover();

  window.$('.details, .reply-details').autolink();

  window.$("a.fancybox").fancybox({
    closeClick: true,
    helpers: {
      overlay: {
        locked: false
      }
    }
  });

  window.$('body').on('contextmenu', '[data-no-contextmenu]', function(e) {
    e.preventDefault();
    e.stopPropagation();
  });

  window.$('.select_all_contacts input').click(function() {
    if (window.$(this).prop( "checked" )) {
      window.$(".contacts_list input[type=checkbox]").prop('checked', 'checked');
    } else {
      window.$(".contacts_list input[type=checkbox]").prop('checked', '');
    }
  });

  window.$('.select_all').click(function() {
    if (window.$(this).prop( "checked" )) {
      window.$(".select_all_item").prop('checked', 'checked');
    } else {
      window.$(".select_all_item").prop('checked', '');
    }
  });
});

export function createVideoPlayer(video, playerElement) {
  var sources = [ { src: video.mp4Url, type: 'video/mp4' } ];

  return new window.MediaElementPlayer(playerElement, {
    videoHeight: video.height,
    videoWidth: video.width,
    type: ['video/mp4'],
    success(mediaElement /*, domObject*/) {
      mediaElement.setSrc(sources);
      mediaElement.load();
      mediaElement.play();
    }
  });
}
