/**
 * @module Core.Services.Providers
 *
 */

/**
 * @class $facebook
 *
 */
function factory($q, fb) {
  /**
    * Retrieve token from facebook auth.
    *
    * @returns {Promise}
    */
  function login() {
    return fb.authenticate().then(response => {
      if (response.status === 'connected') {
        return response.authResponse.accessToken;
      } else {
        return $q.reject(new Error('Authentication failed.'));
      }
    });
  }

  /**
    * Destroys current facebook session.
    *
    * @returns {Promise}
    */
  function logout() {
    return fb.invalidate();
  }

  return {
    login: login,
    logout: logout
  };
}

factory.$inject = ['$q', 'facebook'];

export default factory;
