import template from 'ngapp/templates/views/tags/_new.html';

// function TagNewController($scope, $translate, $location, UserTag) {
  function TagNewController($scope, $translate, $location) {
  $scope.processing = false;
  $scope.tag = {
    name: ''
  };
  $scope.isValid = true;

  $scope.TagsUrl = function () {
    $location.path('/account/tags');
  };

  // $scope.add = function () {
  //   $scope.processing = true;
  //   const name = $scope.tag.name.trim();
  //   if (!(name && name.length)) {
  //     $scope.isValid = false;
  //     $scope.processing = false;
  //   } else {
  //     new UserTag({
  //         name
  //       })
  //       .create()
  //       .then(() => {
  //         $scope.processing = false;
  //         $location.path('/account/tags');
  //       })
  //       .catch(error => {
  //         $scope.processing = false;
  //         if (error.status === 422) {
  //           alert(error.data);
  //         } else {
  //           $translate('CONTROLLERS.TAGS_NEW.ADD_ERROR_MESSAGE').then(alert);
  //         }
  //       });
  //   }
  // };
}

TagNewController.$inject = [
  '$scope',
  '$translate',
  '$location',
  'UserTag'
];

export default {
  controller: TagNewController,
  template
};
