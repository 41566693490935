
import has from 'lodash/has';
import assign from 'lodash/assign';

export default function EmojiProvider() {
  const defaults = {
    library: {
      'smirk': '😏',
      'thumbsup': '👍'
    }
  };

  function $get() {
    var EMOJI_MATCH = /:([a-zA-Z0-9-_]+):/g;

    /**
      * @param {object} options
      */
    function EmojiService(options) {
      this._library = options.library || {};
    }

    /**
      * Filters a string, replacing all emoji directive with their matching emoji values.
      *
      * @param {string} string
      * @returns {string}
      */
    EmojiService.prototype.filter = function(string) {
      var library = this._library;

      if (string != null) {
        return string.replace(EMOJI_MATCH, function(match, key) {
          if (has(library, key)) {
            return library[key];
          }

          return match;
        });
      }

      return '';
    };

    return new EmojiService(assign({}, defaults));
  }

  $get.$inject = [];

  return {
    defaults: defaults,
    $get: $get
  };
}
