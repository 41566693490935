/**
 * @module Directives.Teams
 *
 */
import debounce from 'lodash/debounce';
import template from 'ngapp/templates/views/teams/_members_list.html';

/**
 * @class TeamMembersList
 *
 */
function Controller($scope, $translate, format, confirm, teamModal, toast, tracking, TeamMember, Invitation) {
  $scope.team = null;
  $scope.members = [];
  $scope.pending = true;
  $scope.canManageMember = canManageMember;
  $scope.formatMemberSummary = formatMemberSummary;
  $scope.onResend = onResend;
  $scope.onEdit = onEdit;
  $scope.onRemove = onRemove;
  $scope.onFilter = debounce(onFilter, 400);
  $scope.onScroll = onScroll;
  $scope.$props.$onChanges = $onChanges;
  $scope.filters = {
    query: '',
    roles: {
      coach: true,
      player: true,
      viewer: true
    }
  };

  const ERROR_TO_LOCALIZATION_KEYS = {
    spam: 'TEAMS.MEMBER_LIST.RESEND_ERRORS.SPAM',
    unknown: 'TEAMS.MEMBER_LIST.RESEND_ERRORS.GENERAL_ERROR'
  };

  function canManageMember(member) {
    return $scope.team.can.manage && !$scope.team.isOwnedByMember(member);
  }

  function onResend(member) {
    Invitation.resend(member.invitationId)
      .then(() => toast.success($translate.instant('TEAMS.MEMBER_LIST.RESEND_SUCCESS')))
      .cantch(error => toast.danger($translate.instant(ERROR_TO_LOCALIZATION_KEYS[error.error])));
  }

  function onEdit(member) {
    teamModal.editMember(member);
  }

  function onRemove(member) {
    const body = $translate.instant('TEAMS.MEMBER_LIST.REMOVE_CONFIRMATION', { name: member.name });
    confirm({ body }).then(result => {
      if (result.confirmed) {
        return remove(member);
      }
    });
  }

  function onScroll() {
    $scope.$props.onPage();
  }

  function formatMemberSummary(member) {
    return format.memberSummary(member, $scope.team.isOwnedByMember(member));
  }

  function remove(member) {
    return TeamMember.remove(member.teamId, member.id).then(() => {
      tracking.teams.memberDeleted($scope.team, member.status, member.type);
      toast.success($translate.instant('TEAMS.MEMBER_LIST.REMOVE_SUCCESS', { name: member.name }));
      $scope.$props.onRemove({ member: member });
    });
  }

  function onFilter() {
    $scope.$props.onFilter({ filters: $scope.filters });
  }

  function $onChanges(props) {
    if (props.members != null) {
      $scope.members = props.members.currentValue;
    }

    if (props.team != null) {
      $scope.team = props.team.currentValue;
    }

    if (props.pending != null) {
      $scope.pending = props.pending.currentValue;
    }
  }
}

Controller.$inject = [
  '$scope',
  '$translate',
  'format',
  'confirm',
  'teamModal',
  'core.ui.toast',
  'core.services.tracking',
  'core.services.TeamMember',
  'core.services.Invitation'
];

export default {
  bindings: {
    team: '<',
    members: '<',
    pending: '<',
    onRemove: '&',
    onFilter: '&',
    onPage: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
