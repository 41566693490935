/**
 * @module Filters
 *
 */
const DEFAULT_SHORT_FORMAT = 'MMM d';
const DEFAULT_SHORT_FORMAT_WITH_YEAR = 'MMM d yyyy';

/**
 * @class Elapsed
 *
 */
function elapsed(date, $translate, time) {
  return function filter(value) {
    var now = new Date;
    var span = time.span(value, now);
    var currentMonth = now.getMonth();

    if (span.months > currentMonth) {
      return date(value, DEFAULT_SHORT_FORMAT_WITH_YEAR);
    } else if (span.months > 0 && span.months < currentMonth) {
      return date(value, DEFAULT_SHORT_FORMAT);
    } else if (span.days > 0) {
      if (span.days >= 7) {
        return $translate.instant('ELAPSED_TIME.WEEKS', { weeks: Math.floor(span.days / 7) }, 'messageformat');
      }

      return $translate.instant('ELAPSED_TIME.DAYS', { days: span.days }, 'messageformat');
    } else if (span.hours > 0) {
      return $translate.instant('ELAPSED_TIME.HOURS', { hours: span.hours }, 'messageformat');
    } else if (span.minutes > 0) {
      return $translate.instant('ELAPSED_TIME.MINUTES', { minutes: span.minutes }, 'messageformat');
    }

    return $translate.instant('ELAPSED_TIME.SECONDS', { seconds: span.seconds }, 'messageformat');
  };
}

elapsed.$inject = ['dateFilter', '$translate', 'core.time'];

export default elapsed;
