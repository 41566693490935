
import assign from 'lodash/assign';
import has from 'lodash/has';
import unset from 'lodash/unset';
import forOwn from 'lodash/forOwn';

const options = {
  waveColor: '#DDDDDD',
  progressColor: '#E54500',
  barWidth: 1.6,
  loopSelection: false,
  barGap: 3,
  barHeight: 4,
  cursorWidth: 0,
  height: 32,
  responsive: true,
};

function factory($uid, mediaHelpers, $wavesurfer) {
  function AudioService($uid, $wavesurfer) {
    this._players = {};
    this._$uid = $uid;
    this._$wavesurfer = $wavesurfer;
  }

  AudioService.prototype.createPlayer = function createPlayer(resource, element, onPlay, onStatusUpdate) {
    var playerId = this._$uid.short();
    var player = this._$wavesurfer.create(assign({}, options, { container: element }));
    var firstRun = true;

    player.on('audioprocess', $updateStatus);
    player.on('finish', $updateStatus);
    player.on('pause', $updateStatus);
    player.on('play', $onPlay);
    player.on('ready', $isPlayerReady);
    player.on('interaction', $updateStatus);

    this._players[playerId] = player;

    function $isPlayerReady(time) {
      player.play();
      $updateStatus(time);
    }

    function $updateStatus(time) {
      if (firstRun) {
        // player.play();
        player.load(mediaHelpers.getAudioUrl(resource));
        firstRun = false;
      }


      time = isNaN(time) ? player.getCurrentTime() : time;

      onStatusUpdate({
        remaining: player.getDuration() - (isNaN(time) ? 0 : time),
        playing: player.isPlaying(),
        isReady: player.isReady,
      });
    }

    function $onPlay() {
      onPlay(playerId);
    }

    return playerId;
  };

  AudioService.prototype.destroyPlayer = function destroyPlayer(playerId) {
    var success = has(this._players, playerId);

    if (success) {
      this._players[playerId].destroy();
      unset(this._players, playerId);
    }

    return success;
  };

  AudioService.prototype.stopPlayers = function stopPlayers(playerId) {
    var that = this;

    forOwn(this._players, function (value, key) {
      if (key !== playerId) {
        that._players[key].pause();
      }
    });
  };

  AudioService.prototype.togglePlayer = function togglePlayer(playerId) {
    var success = has(this._players, playerId);
    var playing = false;

    if (success) {
      var player = this._players[playerId];
      playing = player.isPlaying();

      if (playing) {
        player.pause();
      } else {
        player.play();
      }
    }

    return success && !playing;
  };

  return new AudioService($uid, $wavesurfer);
}

factory.$inject = ['core.uid', 'mediaHelpers', 'wavesurfer'];

export default factory;

