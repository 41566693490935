/**
 * @module Directives.TeamsGroups
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/teams_groups/_new_group_modal.html';

/**
 * @class NewGroupModal
 *
 */
function Controller($scope, validation, templateService, TeamGroup) {
  $scope.fields = {
    teamId: $scope.$props.teamId,
    spaceTemplateId: null,
    name: '',
    groupType: 'private',
    viewOnly: false
  };
  $scope.templates = [];
  $scope.errors = {};
  $scope.pending = true;
  $scope.onCancel = onCancel;
  $scope.onSubmit = onSubmit;

  $scope.templateOptions = null;
  $scope.selectedTemplate = null;
  $scope.onSelectTemplate = (value) => {
    $scope.selectedTemplate = value;
    if (value != null) {
      $scope.fields.spaceTemplateId = value.data.id;
    }
  };

  templateService.all().then(templateModels => {
    run(() => {
      $scope.templates = templateModels;
      $scope.templateOptions = templateModels
        .map(item => ({ label: item.name, value: item.id, data: item }))
        .sort((a, b) => a.label < b.label ? -1 : a.label > b.label ? 1 : 0);
      $scope.pending = false;
    });
  });

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onSubmit(e) {
    e.preventDefault();
    const validationResult = validate($scope.fields);
    if (validationResult.isValid) {
      $scope.pending = true;

      TeamGroup
        .create($scope.fields)
        .then(group => $scope.$props.modal.close({ group: group }))
        .finally(() => $scope.pending = false);
    }
    $scope.errors = validationResult.errors;
  }

  function validate(fields) {
    const results = { errors: {}, isValid: true };
    if (validation.isEmpty(fields.name)) {
      results.errors.name = 'required';
      results.isValid = false;
    }
    return results;
  }
}

Controller.$inject = ['$scope', 'core.validate', 'core.services.template', 'core.services.TeamGroup'];

export default {
  bindings: {
    modal: '<',
    teamId: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
