/**
 * @module Directives.Spaces
 *
 */
import template from 'ngapp/templates/views/spaces/_space_nav.html';

/**
 * @class SpaceNav
 *
 */
function Controller(
  $scope,
  $location,
  paywallModal,
  planFeature
) {
  $scope.space = $scope.$props.space;
  $scope.user = $scope.$props.user;
  $scope.active = getActiveTab($scope.space);
  $scope.canSchedulePosts = canSchedulePosts;
  $scope.onScheduledPostsClick = onScheduledPostsClick;
  $scope.onScheduledClick = onScheduledClick;
  $scope.showDemoPostModal = false;
  $scope.demoPostType = '';
  
  function onScheduledPostsClick(event) {
    if (event != null) {
      event.stopPropagation();
    }

    paywallModal.proUpgrade();
  }

  function canSchedulePosts() {
     if ($scope.user) {
       return planFeature.user.canSchedulePosts($scope.user);
     } else {
       return false;
     }
  }

  function onScheduledClick() {
    $scope.showDemoPostModal = true;
    $scope.demoPostType = 'schedule';
  }

  $scope.closeDemoPost = () => {
    $scope.showDemoPostModal = false;
  };

  function getActiveTab(space) {
    switch ($location.path()) {
      case space.timelineUrl:
        return 'timeline';
      case space.scheduledPostsUrl:
        return 'scheduled_posts';
      case space.membersUrl:
        return 'members';
    }
  }
}

Controller.$inject = [
  '$scope',
  '$location',
  'paywallModal',
  'core.services.planFeature'
];

export default {
  bindings: {
    space: '<',
    user: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
