/** @module resources */

function SearchFactory($resource, railsSerializer) {
  var Search = $resource({
    url: 'api/v1/search',
    name: 'search',
    serializer: railsSerializer(function () {
      this.resource('replies', 'Post');
      this.resource('spaces', 'Space');
      this.resource('datafile', 'Resource');
    })}
  );

  Search.getWithSpaces = function(id) {
    return Search.query({ 'options[include]': ['spaces'] }, { id: id });
  };

  Search.per_page = 10;

  Search.prototype.isPost = function(){
    return (
      this.object == 'note' ||
      this.object == 'post' ||
      this.object == 'image' ||
      this.object == 'audio' ||
      this.object == 'video' ||
      this.object == 'datafile'
    );
  };

  Search.prototype.isSpace = function(){
    return (this.object == 'team_space' || this.object == 'athlete_space');
  };

  Search.prototype.isTeam = function(){
    return (this.object == 'team');
  };

  Search.prototype.isTeamGroup = function(){
    return (this.object == 'team_group');
  };

  Search.prototype.isMediaPost = function(){
    return (this.object === 'note' || this.object == 'image' || this.object == 'audio' || this.object == 'video' || this.object == 'datafile');
  };

  Search.prototype.isImage = function () {
    return (this.object == 'image');
  };

  Search.prototype.isNote = function () {
    return (this.object == 'note');
  };

  Search.prototype.isDatafile = function () {
    return (this.object == 'datafile');
  };

  Search.prototype.isAudio = function () {
    return (this.object == 'audio');
  };

  Search.prototype.isVideo = function () {
    return (this.object == 'video');
  };

  Search.prototype.canTag = function () {
    return (this.permissions.indexOf('tag') != -1);
  };

  return Search;
}

SearchFactory.$inject = ['core.services.$resource', 'railsSerializer'];

export default SearchFactory;
