/**
 * @module Directives.Spaces
 *
 */
import find from 'lodash/find';
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/spaces/_invite_space_user_modal.html';

/**
 * @class InviteSpaceUserModal
 *
 */
function Controller($scope, validate, $document) {
  $scope.currentUsers = $scope.$props.users || [];
  $scope.errors = {};
  $scope.pending = false;
  $scope.fields = {
    name: '',
    email: '',
  };
  $scope.onCancel = onCancel;
  $scope.onSubmit = onSubmit;
  $scope.$props.$onChanges = $onChanges;

  const setInputs = (label) => {
    run(() => {
      if (label != null) {
        if (label.indexOf('@') !== -1) {
          $scope.fields.email = label;
          if (/^.+@.+\..+$/.test(label)) {
            focusOnOpen('#invite-name-field');
          } else {
            focusOnOpen('#invite-email-field');
          }
        } else {
          $scope.fields.name = label;
          if (/^.+\s...+$/.test(label)) {
            focusOnOpen('#invite-email-field');
          } else {
            focusOnOpen('#invite-name-field');
          }
        }
      }
    });
  };

  const focusOnOpen = (selector, ct=0) => {
    if (selector != null) {
      const input = $document.find(selector);

      let done = false;
      if (input && input.length) {
        if (input.is(':visible')) {
          if (input.is(':focus')) {
            done = true;
          } else {
            input.trigger('focus');
          }
        }
      }

      if (!done && ct < 100) {
        setTimeout(() => focusOnOpen(selector, ct+1), 200);
      }
    }
  };

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onSubmit(e) {
    e.preventDefault();

    $scope.pending = true;

    var validationResults = _validateForm($scope.fields);

    if (validationResults.isValid) {
      var name = $scope.fields.name.trim();

      $scope.$props.modal.close({
        avatar: null,
        name: name,
        displayName: name,
        email: $scope.fields.email.trim().toLowerCase()
      });
    } else {
      $scope.errors = validationResults.errors;
    }

    $scope.pending = false;
  }

  function _isInUserCollection(email) {
    var found = find($scope.currentUsers, function(user) {
      return user.email.trim().toLowerCase() === email.trim().toLowerCase();
    });

    return found != null;
  }

  function _validateForm(form) {
    var result = { errors: {}, isValid: true };

    if (validate.isEmpty(form.name)) {
      result.errors.name = 'required';
      result.isValid = false;
    }

    if (validate.isEmpty(form.email)) {
      result.errors.email = 'required';
      result.isValid = false;
    } else if (!validate.isEmail(form.email)) {
      result.errors.email = 'invalid';
      result.isValid = false;
    } else if (_isInUserCollection(form.email)) {
      result.errors.email = 'already_invited';
      result.isValid = false;
    }

    return result;
  }

  function $onChanges($props) {
    if ($props.label != null) {
      setInputs($props.label.currentValue);
    }
  }
}

Controller.$inject = ['$scope', 'core.validate', '$document'];

export default {
  bindings: {
    users: '<',
    label: '<',
    modal: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
