
import template from 'ngapp/templates/directives/_upload_modal.html';

function Controller($scope) {
  $scope.accept = '';
  $scope.onCancel = onCancel;
  $scope.onUpload = onUpload;
  $scope.$props.$onChanges = $onChanges;

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onUpload(resource) {
    $scope.$props.modal.close(resource);
  }

  function $onChanges(props) {
    if (props.accept != null) {
      $scope.accept = props.accept.currentValue;
    }
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    modal: '<',
    accept: '@'
  },
  template,
  controllerAs: '$props',
  controller: Controller
};
