/**
 * @module Controllers.Posts
 *
 */
import { postEdited, postReported } from 'app/utils/analytics-helpers/posts';

/**
 * @class PostInnerController
 *
 */
function PostInnerController(
  $,
  $q,
  $element,
  toast,
  $scope,
  $modal,
  $translate,
  tracking,
  Post,
  format,
  postHelpers
) {
  $scope.editingTags = false;
  $scope.editingDetails = false;
  $scope.reportMessage = "";
  $scope.formatPostLocation = formatPostLocation;
  $scope.getLocationUri = postHelpers.getLocationUri;
  $scope.formatPosterSummary = formatPosterSummary;
  $scope.postViews = $scope.post.postViewsCount;
  $scope.postViewers = $scope.post.postViews;
  $scope.canSeePostData = canSeePostData;
  tracking.postViews.createObserver($element, $scope.post);

  $scope.editTags = function() {
    if ($scope.post.can.tag) {
      $scope.editingTags = true;
    }
    postEdited("Tags Started", $scope.post);
  };

  $scope.editDetails = function() {
    if ($scope.post.can.edit) {
      $scope.editing = true;
    }
    postEdited("Started", $scope.post);
  };

  $scope.onEditCancel = function onEditCancel() {
    $scope.editing = false;
  };

  $scope.onPostUpdate = function onPostUpdate(fields) {
    return Post.update($scope.post, { details: fields.body, tags: fields.tags })
      .then(function(post) {
        $scope.editing = false;
        $scope.post = post;
      });
  };

  $scope.report = function(event) {
    const request = Post.report(event.post.id, event.message);

    const index = $scope.posts.indexOf(event.post);
    $scope.posts.splice(index, 1);

    $q.resolve(request).then(() => {
      toast.success($translate.instant('TEAMS.REPORT_POST_MODAL.SUCCESS_TOAST'));
    });

    if (event.post.team != null) {
      tracking.teams.postReported(event.post.team, event.post);
    } else if (event.post.teamGroup != null) {
      tracking.teamGroups.postReported(event.post.teamGroup, event.post.teamGroup.team, event.post);
    } else {
      postReported(event.post);
    }
  };

  function formatPostLocation(post) {
    if (post.teamGroup != null) {
      return $translate.instant('POSTS.POST_INNER.TEAM_CHANNEL_LOCATION', {
        team: post.teamGroup.team.name,
        channel:  post.teamGroup.name
      });
    } else if (post.team != null) {
      return $translate.instant('POSTS.POST_INNER.TEAM_LOCATION', {
        team: post.team.name
      });
    } else if (post.space != null) {
      return $translate.instant('POSTS.POST_INNER.SPACE_LOCATION', {
        space: post.space.athleteName,
        sport:  post.space.sport
      });
    } else if (post.isBulk) {
      return $translate.instant('POSTS.POST_INNER.BULK_LOCATION', {
        count: post.spacesCount
      }, 'messageformat');
    }

    return '';
  }

  function formatPosterSummary(post) {
    if (post.teamMembership != null) {
      return format.memberSummary(post.teamMembership);
    }

    return '';
  }

  function canSeePostData() {
    if($scope.post.permissions) {
      return $scope.post.permissions.includes('access_views') && $scope.post.permissions.includes('access_likes');
    }

    return false;
  }
}

PostInnerController.$inject = [
  '$',
  '$q',
  '$element',
  'core.ui.toast',
  '$scope',
  '$modal',
  '$translate',
  'core.services.tracking',
  'core.services.Post',
  'format',
  'postHelpers'
];

export default {
  name: 'PostInnerCtrl',
  controller: PostInnerController
};

