/**
 * @module Core.Services
 *
 */

/**
 * @class BulkMemberImportFactory
 * @name $bulkJobPoller
 *
 */
function factory($q, $poll) {
  const POLL_THROTTLE_TIMEOUT = 2000;

  function enqueue(start, checkStatus) {
    const task = $q.defer();
    let canceled = false;

    const createPoll = status => $poll(
      () => checkStatus(status.id),
      (progress) => canceled ? true : progress.status !== 'in_progress',
      POLL_THROTTLE_TIMEOUT
    );

    const createResponse = status => {
      const success = !canceled && status.status !== 'success' ? false : true;
      return task.resolve({ canceled, success, status });
    };

    // start bulk import job
    start().then(createPoll).then(createResponse);
    return {
      result: task.promise,
      cancel: () => {
        canceled = true;
      }
    };
  }
  return { enqueue };
}

factory.$inject = ['$q', 'core.$poll'];

export default factory;
