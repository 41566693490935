
function factory($cache) {
  return function create(UserConnections) {
    function onUpdate() {
      $cache.remove(UserConnections.$url());
    }

    return {
      onUpdate: onUpdate
    };
  };
}

factory.$inject = ['core.services.$cache'];

export default factory;
