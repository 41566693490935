/**
 * @module Controllers.SpaceMemberships
 *
 */
import { run } from 'app/utils/runloop';
import athlete_invitation_delete_modal_template from 'ngapp/templates/modals/_athlete_invitation_delete_modal.html';
import failed_invitation_modal_template from 'ngapp/templates/modals/_failed_invitation_modal.html';

/**
 * @class SpaceMembershipsInvitations
 *
 */
function Controller(
  $scope,
  $routeParams,
  $location,
  $translate,
  $modal,
  $route,
  toast,
  Space,
  SpaceInvitation,
  InvitationPresenter,
  listLoader,
  Invitation,
  invitationModal
) {
  $scope.invitations = [];
  listLoader($scope, 'invitations', loadInvitesPromise, loadInvitesHandler);
  $scope.spaceId = $routeParams.spaceId;
  $scope.space = {};
  Space.get($scope.spaceId, {'options[include]': ['team_info']}).then((space) => run(() => {
    $scope.space = space;
  }));

  function loadInvitesPromise() {
    $scope.startLoading();
    var queryParams = {
      page: $scope.page,
      per_page: SpaceInvitation.per_page
    };
    angular.extend(queryParams, $scope.filterCriteria);
    return SpaceInvitation.query(queryParams, { spaceId: $scope.spaceId });
  }

  function loadInvitesHandler(invitations) {
    invitations.forEach(function(invitation) {
      $scope.invitations.push(invitation);
    });
  }

  $scope.resendInvite = function(invite) {
    if (invite.planCanManage()) {
      Invitation.resend(invite.id)
        .then(() => {
          toast.success($translate.instant('CONTROLLERS.INVITATIONS.INVITE_SENT_SUCCESS_MESSAGE', { email: invite.email }));
        })
        .catch(() => toast.danger($translate.instant('CONTROLLERS.INVITATIONS.INVITE_RESEND_ERROR_MESSAGE')));

      $route.reload();
    }
  };

  $scope.deleteInvite = function(invite) {
    if (invite.planCanManage()) {
      Invitation.cancel(invite).then(function() {
        Array.removeObj($scope.invitations, invite);
      });
    }
  };

  $scope.isShowMenu = function(invite) {
    return (invite.canEdit() || invite.canResend() || invite.canDelete());
  };

  $scope.deleteSpace = function() {
    if ($scope.space.planCanDelete()) {
      $scope.space.remove().then(function() {
        $location.path('/feed');
        $scope.athleteInvitationDeleteModal.close();
      }, function() {
        $translate('CONTROLLERS.SPACE_MEMBERSHIPS_INVITATIONS.DELETE_SPACE_ERROR_MESSAGE').then(alert);
      });
    }
  };

  $scope.redirectToEditInvite = function() {
    if ($scope.currentInvite.planCanManage()) {
      $location.path('/spaces/' + $scope.spaceId + '/invitations/' + $scope.currentInvite.id + '/edit');
      $scope.athleteInvitationDeleteModal.close();
    }
  };

  $scope.openAthleteInvitationDeleteModal = function(invite) {
    $scope.currentInvite = invite;
    $scope.athleteInvitationDeleteModal = $modal.open({
      template: athlete_invitation_delete_modal_template, //templateUrl: 'modal.athleteInvitationDeleteModal',
      scope: $scope
    });
  };

  $scope.openFailedInvitationModal = function() {
    $scope.failedInvitationModal = $modal.open({
      template: failed_invitation_modal_template, //templateUrl: 'modal.failedInvitationModal',
      scope: $scope
    });
  };

  $scope.openPhoneInviteCopyLinkModal = function(invitation) {
    invitationModal.copyPhoneInviteLink(invitation.shortInvitationUrl);
  };

  $scope.loadList();
}

Controller.$inject = [
  '$scope',
  '$routeParams',
  '$location',
  '$translate',
  '$modal',
  '$route',
  'core.ui.toast',
  'Space',
  'SpaceInvitation',
  'InvitationPresenter',
  'listLoader',
  'core.services.Invitation',
  'invitationModal'
];

export default {
  name: 'SpaceMembershipsInvitationsCtrl',
  controller: Controller
};
