/**
 * @module Directives.Teams
 *
 */
import map from 'lodash/map';
import {
  run
} from 'app/utils/runloop';
import {
  isBlank
} from 'app/utils';
import template from 'ngapp/templates/views/teams/_team_post_edit_form.html';

/**
 * @class TeamPostEditForm
 *
 */
function Controller($q, $scope, Tag) {
  $scope.pending = false;
  $scope.post = $scope.$props.post;
  $scope.fields = {
    body: '',
    scheduledAt: null,
    tags: [],
    resources: []
  };
  $scope.disableTags = $scope.$props.disableTags;
  $scope.errors = {};
  $scope.tags = [];
  $scope.onScheduledAtChange = onScheduledAtChange;
  $scope.onCancel = onCancel;
  $scope.onSubmit = onSubmit;
  $scope.$props.$onChanges = $onChanges;
  $scope.user = $scope.$props.user;
  $scope.onScheduleChange = onScheduleChange;
  $scope.scheduledValue = $scope.post.scheduledIn;
  $scope.scheduledTime = 'Day';

  Tag.all().then(tags => run(() => {
    $scope.tags = map(tags, 'name');
  }));

  function reset() {
    $scope.errors = {};
    $scope.pending = false;
    $scope.scheduledValue = null;
    $scope.scheduledTime = 'Day';
    $scope.generatedScheduledValue = null;
  }

  function validate(fields) {
    var result = {
      errors: {},
      isValid: true
    };

    if (fields.resources.length === 0 && isBlank(fields.body)) {
      result.errors.body = 'required';
      result.isValid = false;
    }

    if (isBlank($scope.post.targetCode) && new Date(fields.scheduledAt) < new Date()) {
      result.errors.scheduledAt = 'invalid';
      result.isValid = false;
    }

    return result;
  }

  function onScheduledAtChange(data) {
    $scope.fields.scheduledAt = new Date(data).toISOString();
  }

  function onCancel() {
    reset();
    $scope.$props.onCancel();
  }

  if ($scope.scheduledValue < 24) {
    $scope.scheduledTime = "Hour";
    $scope.scheduledValue = $scope.post.scheduledIn;
  } else if ($scope.scheduledValue >= 24 && $scope.scheduledValue < 168) {
    $scope.scheduledTime = "Day";
    $scope.scheduledValue = $scope.post.scheduledIn / 24;
  } else if ($scope.scheduledValue >= 168) {
    $scope.scheduledTime = "Week";
    $scope.scheduledValue = $scope.post.scheduledIn / 7 / 24;
  } else {
    return null;
  }

  function onScheduleChange(pickedAt, pickedTime) {
    $scope.scheduledValue = pickedAt;
    $scope.scheduledTime = pickedTime.slice(0, -1);
    if (pickedTime === 'Days') {
      $scope.post.scheduledIn = pickedAt * 24;
    } else if (pickedTime === 'Weeks') {
      $scope.post.scheduledIn = pickedAt * 7 * 24;
    } else {
      $scope.post.scheduledIn = pickedAt;
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    $scope.post.scheduledIn;
    var validationResult = validate($scope.fields);

    if (validationResult.isValid) {
      $scope.pending = true;

      $q
        .resolve($scope.$props.onUpdate({
          fields: $scope.fields
        }))
        .then(function () {
          reset();
        })
        .finally(function () {
          $scope.pending = false;
          $scope.$emit('loadListEvent');
        });
    }

    $scope.errors = validationResult.errors;
  }

  function $onChanges(props) {

    if (props.body !== null) {
      $scope.fields.body = props.body.currentValue;
    }

    if (props.scheduledAt !== null) {
      $scope.fields.scheduledAt = props.scheduledAt.currentValue;
    }

    if (props.scheduledIn !== null && props.scheduledIn !== undefined) {
      $scope.fields.scheduledIn = props.scheduledIn.currentValue;
    }    

    if (props.tags !== null) {
      $scope.fields.tags = (props.tags.currentValue || []).slice();
    }

    if (props.resources !== null) {
      $scope.fields.resources = (props.resources.currentValue || []).slice();
    }
  }
}

Controller.$inject = ['$q', '$scope', 'core.services.Tag', '$rootScope'];

export default {
  bindings: {
    post: '=',
    disableTags: '<',
    body: '<',
    tags: '<',
    scheduledAt: '<',
    resources: '<',
    onCancel: '&',
    onUpdate: '&',
    submit: '&',
    user: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
