/**
 * @module Directives
 *
 */
import { digest } from 'app/utils/runloop';

/**
 * A generic confirmation for risky actions.
 * Usage: Add attributes: ng-really-message="Are you sure"? ng-really-click="takeAction()" function
 */
export default function NgReallyClick() {
  function link(scope, element, attrs) {
    element.bind('click', function() {
      var message = attrs.ngReallyMessage;
      if (message && confirm(message)) {
        digest(() => scope.$apply(attrs.ngReallyClick));
      }
    });
  }

  return {
    restrict: 'A',
    link: link
  };
}
