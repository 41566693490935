/** @module resources */

function UserBillingInfoFactory($resource) {
  var UserBillingInfo = $resource({
    url: 'api/v1/account/billing_info',
    name: 'billing_info'
  });

  return UserBillingInfo;
}

UserBillingInfoFactory.$inject = ['core.services.$resource'];

export default UserBillingInfoFactory;
