
import template from 'ngapp/templates/views/spaces/_space_notification_settings_modal.html';

function Controller($scope, $translate, SpaceUserSettings, toast) {
  $scope.pending = false;
  $scope.space = $scope.$props.space;
  $scope.fields = {
    emailNotificationsEnabled: $scope.space.userSettings.emailNotificationsEnabled,
    pushNotificationsEnabled: $scope.space.userSettings.pushNotificationsEnabled
  };
  $scope.onCancel = onCancel;
  $scope.onSubmit = onSubmit;

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onSubmit(e) {
    e.preventDefault();

    $scope.pending = true;

    SpaceUserSettings.update($scope.space.userSettings, $scope.fields)
      .then(function () {
        toast.success($translate.instant('SPACES.SETTINGS.SAVE_SUCCESS_MESSAGE'));
        $scope.$props.modal.dismiss();
      })
      .finally(function () {
        $scope.pending = false;
      });
  }
}

Controller.$inject = ['$scope', '$translate', 'SpaceUserSettings', 'core.ui.toast'];

export default {
  bindings: {
    modal: '<',
    space: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
