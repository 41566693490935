/**
 * @module Controllers
 *
 */
import { run } from 'app/utils/runloop';
import { postDeleted } from 'app/utils/analytics-helpers/posts';
import null_feed_png from 'app/images/null-feed.png';
import template from 'ngapp/templates/views/_feed.html';

/**
 * @class Feed
 *
 */
function Controller($scope, $translate, Post, FeedFilter, listLoader, listFilter, listPaging) {
  $scope.posts = [];
  $scope.members = [];
  $scope.authors = [];
  $scope.spaces  = [];
  $scope.postParams = {};
  $scope.authorsPage = 0;
  $scope.spacesPage = 0;
  $scope.loadSpaces = loadSpaces;
  $scope.loadAuthors = loadAuthors;
  $scope.remove = remove;
  $scope.groupingOption = groupingOption;
  $scope.showPostLocation = true;
  $scope.null_feed_png = null_feed_png;

  listLoader($scope, 'posts', loadPostsPromise, loadPostsHandler);
  listPaging($scope, Post.perPage);
  listFilter($scope, {}, 'posts');

  $scope.loadAuthors();
  $scope.loadSpaces();
  $scope.loadList();

  $scope.submitFilter = (filters) => {
    if (filters != null) {
      $scope.filterCriteria = filters;
    } else {
      $scope.filterCriteria = {};
    }
  };

  function loadPostsPromise() {
    const queryParams = {
      options: { include: ['replies', 'space'] },
      skip: (($scope.page - 1) * Post.perPage),
      limit: Post.perPage,
      replyLimit: Post.repliesPerPost,
      ...$scope.filterCriteria
    };
    return Post.query(queryParams);
  }

  function loadPostsHandler(posts) {
    run(() => {
      if (posts != null) {
        const data = Array.isArray(posts) ? posts : [ posts ];
        $scope.posts = [ ...$scope.posts, ...data ];
      }
    });
  }

  function loadSpaces() {
    if ($scope.loadingSpaces === true) { return; }
    $scope.spacesPage += 1;
    $scope.loadingSpaces = true;

    FeedFilter.spaces({ page: $scope.spacesPage, per_page: 50 }).then(response => {
      run(() => {
        if (response && response.data != null) {
          const data = Array.isArray(response.data) ? response.data : [ response.data ];
          $scope.spaces = [ ...$scope.spaces, ...data ];
        }
        $scope.loadingSpaces = false;
      });
    });
  }

  function loadAuthors() {
    if ($scope.loadingAuthors === true) { return; }

    $scope.authorsPage += 1;
    $scope.loadingAuthors = true;

    FeedFilter.authors({ page: $scope.authorsPage, per_page: 50 }).then(response => {
      run(() => {
        if (response && response.data != null) {
          const data = Array.isArray(response.data) ? response.data : [ response.data ];
          $scope.authors = [ ...$scope.authors, ...data.map(item => ({ label: item.display_name, value: item.id })) ];
        }
        $scope.loadingAuthors = false;
      });
    });
  }

  function remove(post) {
    const index = $scope.posts.indexOf(post);
    $scope.posts.splice(index, 1);

    postDeleted(post);

    post.remove().catch(error => {
      $scope.posts.push(post);
      $translate('CONTROLLERS.FEED.POST_REMOVE_DEFAULT_ERROR_MESSAGE')
        .then(translation => alert(`${translation}: ${error.statusText}`));
    });
  }

  function groupingOption(space) {
    return space.short_full_name[0].toUpperCase();
  }
}

Controller.$inject = [
  '$scope',
  '$translate',
  'core.services.Post',
  'FeedFilter',
  'listLoader',
  'listFilter',
  'listPaging',
  'core.services.tracking'
];

export default {
  controller: Controller,
  template
};
