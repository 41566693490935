
/**
 * Angular app core imports
 *
 */
import servicesLoader from './services';
import uiLoader from './ui';
import vendorLoader from './vendor';

import EmitterFactory from './emitter';
import EmojiProvider from './emoji';
import PropertyFactory from './$property';
import PollProvider from './$poll';
import FeaturesFactory from './features';
import LoggerProvider from './logger';
import QSFactory from './qs';
import SignalFactory from './signal';
import TimeFactory from './time';

import UIDFactory from './uid';
import ValidateFactory from './validate';


export default function(__angular) {
  __angular.module('core.extensions', []);
  __angular.module('core.vendor', []);
  __angular.module('core.services', []);
  __angular.module('core.ui', []);
  __angular.module('core', ['core.vendor', 'core.extensions', 'core.services', 'core.ui']);

  vendorLoader(__angular);
  servicesLoader(__angular);

  __angular.module('core.services')
    .provider('core.$poll', PollProvider);

  __angular.module('core')
    .factory('core.$property', PropertyFactory)
    .factory('core.Emitter', EmitterFactory)
    .provider('core.emoji', EmojiProvider)
    .factory('core.features', FeaturesFactory)
    .provider('core.loggerFactory', LoggerProvider)
    .factory('core.qs', QSFactory)
    .factory('core.Signal', SignalFactory)
    .factory('core.time', TimeFactory)
    .factory('core.uid', UIDFactory)
    .factory('core.validate', ValidateFactory);

  uiLoader(__angular);
}
