/** @module resource */
import { isDoc, isPdf, isXls, isPpt } from 'app/utils/file/getType';

/**
 * @class Resource
 *
 */
function Resource($resource) {
  var ResourceFactory = $resource({
    url: 'api/v1/resources',
    name: 'resource'
  });

  ResourceFactory.per_page = 20;

  ResourceFactory.prototype.isDoc = function() {
    return isDoc(this.filename);
  };

  ResourceFactory.prototype.isPdf = function() {
    return isPdf(this.filename);
  };

  ResourceFactory.prototype.isXls = function() {
    return isXls(this.filename);
  };

  ResourceFactory.prototype.isPpt = function() {
    return isPpt(this.filename);
  };

  return ResourceFactory;
}

Resource.$inject = ['core.services.$resource'];

export default Resource;
