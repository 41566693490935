
import assign from 'lodash/assign';
import { isBlank } from 'app/utils';

function factory($q, $location, $resource) {
  var Notification = $resource({
    url: '/api/v1/notifications',
    name: 'notification'
  });

  Notification.all = function all(query) {
    var $httpConfig = assign({ method: 'get', url: Notification.$url() }, Notification.getHttpConfig(query));
    var resourceConfig = { fullResponse: true };

    return Notification.$http($httpConfig, null, resourceConfig)
      .then(function (response) {
        return {
          notifications: response.data,
          unread: response.originalData.unread_notifications_count
        };
      });
  };

  Notification.read = function read(id) {
    return Notification.$post(Notification.$url(id, 'read'));
  };

  Notification.readAll = function readAll() {
    return Notification.$post(Notification.$url('read'));
  };

  Notification.prototype.goto = function goto() {
    if (!isBlank(this.targetUrl)) {
      $location.path(this.targetUrl);
    }
  };

  return Notification;
}

factory.$inject = [
  '$q',
  '$location',
  'core.services.$resource'
];

export default factory;
