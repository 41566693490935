/** @module controllers/teams_groups */

import { all } from 'rsvp';
import { run } from 'app/utils/runloop';
import find from 'lodash/find';
import reject from 'lodash/reject';
import uniqBy from 'lodash/uniqBy';
import template from 'ngapp/templates/views/teams_groups/_timeline.html';

/**
 * @class TimelineController
 *
 */
function Controller(
  $root,
  $scope,
  $params,
  $location,
  Post,
  planFeature,
  identity,
  teamWrapModel,
  tracking,
  createPager,
  TeamGroup,
  paywallModal,
  scheduledPostModal
) {
  $scope.canPostMedia = false;
  $scope.canSchedulePosts = false;
  $scope.teamId = $params.teamId;
  $scope.groupId = $params.id;
  $scope.pending = true;
  $scope.feed = [];
  $scope.draftId = 'draft:teams/' + $scope.teamId + '/groups/' + $scope.groupId + '/post';
  $scope.group = null;
  $scope.myGroups = null;
  $scope.joinableGroups = null;
  $scope.team = null;
  $scope.user = null;
  $scope.onEdit = onEdit;
  $scope.onLike = onLike;
  $scope.onMediaLockedClick = onMediaLockedClick;
  $scope.onScheduleClick = onScheduleClick;
  $scope.onPost = onPost;
  $scope.onRemove = onRemove;
  $scope.onReport = onReport;
  $scope.onTogglePin = onTogglePin;
  $scope.onScroll = onScroll;
  $scope.onFilterChange = onFilterChange;
  $scope.onJoin = onJoin;

  function loadGroup(teamId) {
    return teamWrapModel.find(teamId).then(model =>
      run(() => {
        $scope.team = model.team;
        $scope.canPostMedia = planFeature.team.canAddTeamMedia(model.team);
        $scope.myGroups = model.myGroups;
        $scope.joinableGroups = model.joinableGroups;
        $scope.group = find($scope.myGroups, {
          id: $scope.groupId
        });
      })
    );
  }

  $scope.reloadGroups = () => {
    teamWrapModel.reload().then(model => run(() => {
      $scope.team = model.team;
      $scope.canPostMedia = planFeature.team.canAddTeamMedia(model.team);
      $scope.myGroups = model.myGroups;
      $scope.joinableGroups = model.joinableGroups;
      $scope.group = find($scope.myGroups, {
        id: $scope.groupId
      });
    }));
  };

  function onPage(e) {
    run(() => {
      if (e != null && e.data != null && e.data.results != null) {
        const data = e.data.results;
        $scope.feed = uniqBy([ ...$scope.feed, ...data ], 'id');
      }
    });
  }

  let pager = createPager($scope.groupId);
  loadData(pager);

  function loadData(pager) {
    pager.onPage.attach(onPage);

    all([pager.get(), identity.request(), teamWrapModel.find($scope.teamId)]).then(([ feed, user, team ]) => run(() => {
      $scope.feed = feed;
      $scope.user = user;
      $scope.canSchedulePosts = planFeature.user.canSchedulePosts(user);
      $scope.team = team.team;
      $scope.canPostMedia = planFeature.team.canAddTeamMedia(team.team);
      $scope.myGroups = team.myGroups;
      $scope.joinableGroups = team.joinableGroups;
      $scope.group = find($scope.myGroups, {
        id: $scope.groupId
      });
      $scope.pending = false;
    }));
  }

  function onEdit(post) {
    tracking.teamGroups.postEdited($scope.group, $scope.team, post);
  }

  function onLike(post) {
    tracking.teamGroups.postLiked($scope.group, $scope.team, post);
  }

  function onMediaLockedClick() {
    if($scope.team.isOwnedByUser($scope.user)) {
      $scope.pending = true;
      paywallModal.postMedia().result.then(() =>
        loadGroup($scope.teamId).finally(() =>
          run(() => $scope.pending = false)
        )
      );
    } else {
      paywallModal.postMediaOtherOwner();
    }
  }

  function onScheduleClick() {
    paywallModal.proUpgrade();
  }

  function onPost(fields) {
    return Post.postToGroup($scope.groupId, fields).then(post =>
      run(() => {
        tracking.teamGroups.postCreated($scope.group, $scope.team, post);

        $root.$broadcast('postCreated');

        // $scope.feed = [post].concat($scope.feed);
        loadData(pager);

        if (post.scheduledAt !== null) {
          scheduledPostModal.showScheduledPostModal(post, $scope.group).result;
        }
      })
    );
  }

  function onRemove(post) {
    tracking.teamGroups.postDeleted($scope.group, $scope.team, post);
    if (!post.isReply) {
      $scope.feed = reject($scope.feed, { id: post.id });
    }
  }

  function onReport(e) {
    tracking.teamGroups.postReported($scope.group, $scope.team, e.post);
    onRemove(e.post);
  }

  function onTogglePin(post) {
    $scope.pending = true;

    post.toggle_pin().then(function() {
      loadData(pager);
    });
  }

  function onScroll() {
    pager.next();
  }

  function onFilterChange(filters) {
    pager.destroy();
    pager = createPager($scope.groupId, filters);
    pager.onPage.attach(onPage);
    $scope.pending = true;

    pager.get().then(feed => run(() => {
      $scope.feed = feed;
      $scope.pending = false;
    }));
  }

  function onJoin(group) {
    $scope.pending = true;
    TeamGroup.join(group).then(() =>
      loadGroup(group.team.id).then(() =>
        run(() => {
          $scope.pending = false;
          $location.path(group.timelineUrl);
        })
      )
    );
  }
}

Controller.$inject = [
  '$rootScope',
  '$scope',
  '$routeParams',
  '$location',
  'core.services.Post',
  'core.services.planFeature',
  'core.services.identity',
  'teamWrapModel',
  'core.services.tracking',
  'groupFeedPagerFactory',
  'core.services.TeamGroup',
  'paywallModal',
  'scheduledPostModal'
];

export default {
  controller: Controller,
  template
};
