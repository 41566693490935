/**
 * @module Resources
 *
 */
import assign from 'lodash/assign';
import reject from 'lodash/reject';
import { isBlank } from 'app/utils';
import { fetchGet, fetchPost, fetchDelete, bindAngularResource } from 'app/utils/request';
import { postReported, postShared } from 'app/utils/analytics-helpers/posts';
import config from 'config/environment';

/**
 * @class Post
 *
 */
function Post($translate, railsSerializer, $property, $resource) {
  const endPoint = 'posts';
  const urlPath = 'api/v1/';
  const options = { version: 1 };
  const buildArgs = (type, args) => {
    return [type.replace(urlPath, ''), args, options];
  };

  const makeRequest = (req, args) => {
    return req(...args);
  };

  var Post = $resource({
    url: `${urlPath}${endPoint}`,
    name: 'post',
    serializer: railsSerializer(function () {
      this.resource('replies', 'Post');
      this.resource('datafile', 'Resource');
    })
  }, [
    $resource.acl({
      edit: 'edit',
      reply: 'reply',
      like: 'like',
      delete: 'delete',
      share: 'share',
      tag: 'tag'
    })
  ]);

  Post.perPage = 20;
  Post.repliesPerPost = 5;

  Post.post = function post(spaceId, post) {
    return new Post(assign({}, post, { spaceId: spaceId })).create();
  };

  Post.report = function report(id, message) {
    return makeRequest(fetchPost, buildArgs(Post.$url(id, 'report'), { report: { message: message }}))
      .then((data) => bindAngularResource(data, this));
  };

  Post.destinations = function destinations() {
    return makeRequest(fetchGet, buildArgs(`/posts/destinations`, {}))
      .then(data => bindAngularResource(data, this));
  };

  Post.prototype.share = function(externalId) {
    postShared(this);

    var self = this;
    return makeRequest(fetchPost, buildArgs(this.$url('share'), { external_id: externalId }))
      .then(data => bindAngularResource(data, this))
      .then(() => self.privacy = 'public')
      .then(() => $translate('MODELS.POST.SHARE_ERROR', { supportEmail: config.SUPPORT_EMAIL })
      .then(alert));
  };

  Post.prototype.like = function() {
    var _crement = this.liked ? -1 : 1;
    this.likesCount = this.likesCount + _crement;
    this.liked = !this.liked;

    if (this.liked) {
      return makeRequest(fetchPost, buildArgs(this.$url('likes'), {}))
        .then(data => bindAngularResource(data, this));
    } else {
      return makeRequest(fetchDelete, buildArgs(this.$url('likes'), {}))
        .then(data => bindAngularResource(data, this));
    }
  };

  Post.prototype.report = function(message) {
    postReported(this);
    return makeRequest(fetchPost, buildArgs(this.$url('report'), { report: { message: message }}))
      .then(data => bindAngularResource(data, this));
  };

  Post.prototype.reply = function reply(fields) {
    const replyRequest = new Post(assign({}, fields, { rootId: this.id, spaceId: this.spaceId })).create();

    replyRequest.then(reply => {
      this.repliesCount += 1;
      if (this.replies == null) {
        this.replies = [reply];
      } else {
        this.replies.push(reply);
      }
    });

    return replyRequest;
  };

  Post.prototype.removeReply = function removeReply(id) {
    return Post.$delete(Post.$url(id)).then(() => {
      this.replies = reject(this.replies, { id: id });
      this.repliesCount = this.replies.length;
    });
  };

  Post.prototype.reportReply = function reportReply(id, message) {
    return Post.report(id, message).then(() => {
      this.replies = reject(this.replies, { id: id });
      this.repliesCount = this.replies.length;
    });
  };

  Post.prototype.isReply = function () {
    return !!this.rootId;
  };

  Post.prototype.isAuthor = function(userId) {
    return (this.userId === userId);
  };

  Post.prototype.isImage = function () {
    return (this.type == 'image');
  };

  Post.prototype.isNote = function () {
    return (this.type == 'note');
  };

  Post.prototype.isDatafile = function () {
    return (this.type == 'datafile');
  };

  Post.prototype.isAudio = function () {
    return (this.type == 'audio');
  };

  Post.prototype.isVideo = function () {
    return (this.type == 'video');
  };

  Post.prototype.canDelete = function () {
    return (this.permissions.indexOf('delete') != -1);
  };

  Post.prototype.canEdit = function () {
    return (this.permissions.indexOf('edit') != -1);
  };

  Post.prototype.canTag = function () {
    return (this.permissions.indexOf('tag') != -1);
  };

  Post.prototype.canShare = function () {
    return (this.permissions.indexOf('share') != -1);
  };

  Post.prototype.canReply = function () {
    return (this.permissions.indexOf('reply') != -1);
  };

  Post.prototype.hasPermissions = function () {
    return (this.canTag() || this.canDelete());
  };

  // Plan Permissions

  Post.prototype.planCanTag = function() {
    return (this.planPermissions.indexOf('tag') !== -1);
  };

  Post.prototype.planCanDelete = function() {
    return (this.planPermissions.indexOf('delete') !== -1);
  };

  Post.prototype.planCanEdit = function() {
    return (this.planPermissions.indexOf('edit') !== -1);
  };

  Post.prototype.planCanShare = function() {
    return (this.planPermissions.indexOf('share') !== -1);
  };

  Post.prototype.planCanReply = function() {
    return (this.planPermissions.indexOf('reply') !== -1);
  };

  Post.prototype.planCanLike = function() {
    return (this.planPermissions.indexOf('like') !== -1);
  };

  $property.get(Post.prototype, 'isReply', function() {
    return !isBlank(this.rootId);
  });

  return Post;
}

Post.$inject = [
  '$translate',
  'railsSerializer',
  'core.$property',
  'core.services.$resource'
];

export default Post;
