
import map from 'lodash/map';
import { fetchGet } from 'app/utils/request';

function factory() {
  function toTemplates(response) {
    return map(response, ({ id, name }) => ({ id, name }));
  }

  function all() {
    return fetchGet('space_templates').then(toTemplates);
  }

  return { all: all };
}

factory.$inject = [];

export default factory;
