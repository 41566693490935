/**
 * @module Core.Services.Activity
 *
 */
import assign from 'lodash/assign';

/**
 * @class $OutgoingInvitationPager
 *
 */
function factory(createPager, Invitation) {
  var settings = {
    limit: 20,
    throttle: 10000
  };

  return function create(filters) {
    function load(request) {
      return Invitation.outgoing(
        assign(filters, {
          page: request.page,
          perPage: request.limit
        }));
    }

    return createPager(load, settings);
  };
}

factory.$inject = ['core.services.pagerFactory', 'core.services.Invitation'];

export default factory;
