/** @module resources */

/**
 * @class Invitation
 *
 */
function Invitation($resource, $http, $interpolate, $q, $rootScope) {
  const invitationsUrl = '/api/v1.2/invitations';
  const Invitation = $resource({
    url: `${invitationsUrl}/{{_id}}`,
    name: 'invitation'
  });

  Invitation.prototype.accept = function() {
    if (this.isPaid === true) {
      var acceptInvitePath = $interpolate('/memberships/{{membershipId}}?invitation_token={{token}}');
      window.location.href = acceptInvitePath(this);
    } else {
      var self = this;

      if (self.planCanAccept()) {
        return $http.post(self.$url('accept')).success(function() {
          $rootScope.$emit('invitation.accept', [self.id]);
          self.status = 'accepted';
        });
      }
    }
  };

  Invitation.prototype.mute = function() {
    // return fetchPost(`/invitations/${this._id}/mute`, {}, { version: 1.2 });
    return this.$post(this.$url('/mute'));
  };

  Invitation.prototype.decline = function() {
    var self = this;

    if (self.planCanDecline()) {
      return $http.post(self.$url('decline')).success(function() {
        $rootScope.$emit('invitation.decline', [self.id]);
        self.status = 'declined';
      });
    }
  };

  Invitation.prototype.isPending = function() {
    return this.status === 'pending';
  };

  Invitation.prototype.isAccepted = function() {
    return this.status === 'accepted';
  };

  Invitation.prototype.isDeclined = function() {
    return this.status === 'declined';
  };

  Invitation.prototype.isFromOwner = function() {
    return (this.inviterId === this.spaceOwnerId);
  };

  Invitation.prototype.isFromAthlete = function() {
    return (this.inviterName === this.spaceAthleteName);
  };

  Invitation.prototype.isToAthlete = function() {
    return (this.getInviteeName() === this.spaceAthleteName);
  };

  Invitation.prototype.removeFromSpace = function() {
    var deleteUrl = $interpolate('/api/v1/spaces/{{spaceId}}/invitations/{{_id}}');
    return $http.delete(deleteUrl(this));
  };

  Invitation.prototype.removeFromTeam = function() {
    var deleteUrl = $interpolate('/api/v1/groups/{{teamId}}/memberships/{{membershipId}}');
    return $http.delete(deleteUrl(this));
  };

  Invitation.unreadCount = function() {
    return $http.get(invitationsUrl + '/count');
  };

  Invitation.prototype.getInviteeName = function() {
    if(!this.name || (/^\s*$/.test(this.name))) {
      return (this.email || this.phone);
    } else {
      return this.name;
    }
  };

  Invitation.prototype.logo = function() {
    return this.isForSpace() ? this.inviterAvatar : this.teamLogo;
  };

  Invitation.prototype.isForSpace = function() {
    return this.object === 'space_invitation';
  };

  Invitation.prototype.isForTeam = function() {
    return this.object === 'team_invitation';
  };

  Invitation.prototype.canDelete = function () {
    return (this.permissions.indexOf('delete') !== -1);
  };

  Invitation.prototype.canChangeRole = function () {
    return (this.permissions.indexOf('change_role') !== -1);
  };

  Invitation.prototype.canEdit = function () {
    return (this.permissions.indexOf('edit') !== -1);
  };

  Invitation.prototype.canResend = function () {
    return (this.permissions.indexOf('resend') !== -1);
  };

  Invitation.interceptAfterResponse(function(invites) {
    if(invites && invites.length > 0) {
      angular.forEach(invites, function(invite) {
        if(!invite.permissions) {
          invite.permissions = [];
        }
      });
    }
  });

  // Plan Permissions

  Invitation.prototype.planCanAccept = function () {
    return (this.planPermissions.indexOf('accept') !== -1);
  };

  Invitation.prototype.planCanDecline = function () {
    return (this.planPermissions.indexOf('decline') !== -1);
  };

  Invitation.prototype.planCanManage = function () {
    return (this.planPermissions.indexOf('manage') !== -1);
  };

  return Invitation;
}

Invitation.$inject = ['core.services.$resource', '$http', '$interpolate', '$q', '$rootScope'];

export default Invitation;
