/**
 * @module Directives.BulkPost.Locations
 *
 */

/**
 * @class GroupLocations
 *
 */
function groupLocation($property, BulkPostLocation) {
  function GroupLocation(destination) {
    BulkPostLocation.call(this, destination);
  }

  GroupLocation.prototype = Object.create(BulkPostLocation.prototype);

  $property.get(GroupLocation.prototype, 'description', function() {
    return this._destination.name;
  });

  $property.get(GroupLocation.prototype, 'name', function() {
    return this._destination.teamName;
  });

  $property.get(GroupLocation.prototype, 'teamId', function() {
    return this._destination.teamId;
  });

  return GroupLocation;
}

groupLocation.$inject = ['core.$property', 'bulk_post.locations.$bulkPostLocation'];

export default groupLocation;
