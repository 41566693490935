
function ValidateEmail(validate) {
  function isEmail(value) {
    return validate.isEmpty(value) || validate.isEmail(value);
  }

  function link($scrope, $element,$attributes, $controller) {
    if ($controller != null && $controller.$validators.email != null) {
      $controller.$validators.email = isEmail;
    }
  }

  return {
    restrict: 'AC',
    require: '?ngModel',
    link: link
  };
}

ValidateEmail.$inject = ['core.validate'];

export default ValidateEmail;
