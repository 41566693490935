
function sanitizeFactory($sanitize) {
  return function sanitize(value) {
    return $sanitize(value);
  };
}

sanitizeFactory.$inject = ['$sanitize'];

export default sanitizeFactory;
