/**
 * @module Directives
 *
 */
import { isBlank } from 'app/utils';
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/directives/_simple_typeahead.html';

/**
 * @class SimpleTypeahead
 *
 */
function Controller($element, $scope) {
  $scope.hasQuery = false;
  $scope.currentIndex = 0;
  $scope.emptyButtonText = '';
  $scope.hasFocus = false;
  $scope.hasResults = false;
  $scope.pending = false;
  $scope.placeholder = $scope.$props.placeholder || '';
  $scope.query = '';
  $scope.showEmptyPrompt = false;
  $scope.onChange = onChange;
  $scope.onEmptyClick = null;
  $scope.onResultClick = onResultClick;
  $scope.$props.$onDestroy = $onDestroy;

  if ($scope.$props.empty != null && $scope.$props.empty.onClick != null) {
    if ($scope.$props.empty.buttonText != null && typeof $scope.$props.empty.onClick === 'function') {
      $scope.showEmptyPrompt = true;
      $scope.emptyButtonText = $scope.$props.empty.buttonText;
      $scope.onEmptyClick = $scope.$props.empty.onClick;
    } else {
      throw new Error('Empty prompt definition must include an onClick event callback and buttonText.');
    }
  }

  const $container = $element.find('.simple-typeahead-container');
  const $input = $container.find('.simple-typeahead-input');

  $container.on('focusin', onFocus);
  $container.on('focusout', onBlur);
  $container.on('keydown', onKeydown);

  function _decrement() {
    if($scope.hasResults) {
      if($scope.currentIndex > 0) {
        run(() => $scope.currentIndex = $scope.currentIndex - 1);
      }
    }
  }

  function _increment() {
    if($scope.hasResults) {
      if($scope.currentIndex < $scope.results.length - 1) {
        run(() => $scope.currentIndex = $scope.currentIndex + 1);
      }
    }
  }

  function _reset() {
    $scope.currentIndex = 0;
    $scope.hasFocus = false;
    $scope.hasQuery = false;
    $scope.hasResults = false;
    $scope.pending = false;
    $scope.query = '';
    $scope.results = [];
  }

  function _resultSelected (result) {
    if (result != null) {
      $scope.$props.onResultClick({ result: result }).then(() => _reset());
    } else {
      _reset();
    }
  }

  function _select() {
    if($scope.hasResults && $scope.currentIndex < $scope.results.length) {
      run(() => _resultSelected($scope.results[$scope.currentIndex]));
    }
  }

  function onBlur(e) {
    run(() => {
      let focusLost = false;

      if ($scope.hasFocus) {
        if (e.originalEvent.relatedTarget == null) {
          focusLost = true;
        } else if(!$element[0].contains(e.originalEvent.relatedTarget)) {
          focusLost = true;
        }

        if (focusLost) {
            _reset();
        } else {
          $input.focus();
        }
      }
    });
  }

  function onChange() {
    if (!isBlank($scope.query)) {
      $scope.hasFocus = true;
      $scope.hasQuery = true;
      $scope.pending = true;

      $scope.$props.onQuery({ query: $scope.query }).then(results => onResultsReturned(results));
    }
  }

  function onFocus() {
    run(() => {
      if (!$scope.hasFocus) {
        $scope.hasFocus = true;
        $input.focus();
      }
    });
  }

  function onKeydown(e) {
    if ($scope.hasFocus) {
      if(e.keyCode === 13 || e.keyCode == 38 || e.keyCode == 40) {
        e.preventDefault();

        switch(e.keyCode) {
          case 13:
            _select();
          break;
          case 38:
            _decrement();
            break;
          case 40:
            _increment();
            break;
          default:
            break;
        }
      }
    }
  }

  function $onDestroy() {
    $container.off('focusin', onFocus);
    $container.off('focusout', onBlur);
    $container.off('keydown', onKeydown);
  }

  function onResultsReturned(results) {
    if ($scope.hasFocus) {
      $scope.results = results || [];
      $scope.hasResults = $scope.results.length > 0;
      $scope.currentIndex = 0;
      $scope.pending = false;
    } else {
      _reset();
    }
  }

  function onResultClick (result) {
    _resultSelected(result);
  }
}

Controller.$inject = ['$element', '$scope'];

export default {
  bindings: {
    empty: '<',
    placeholder: '<',
    onQuery: '&',
    onResultClick: '&'
  },
  controller: Controller,
  controllerAs: '$props',
  template,
  transclude: true
};
