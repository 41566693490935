/**
 * @module Core.Services.Tracking
 *
 */

/**
 * @class $space
 *
 */
function factory($client, $post) {
  function extendEventData(event, space) {
    const spaceData = {
      'Space Id': space.id,
      'Space Name': space.athleteName,
      'Space Sport': space.sport
    };

    return Object.assign({}, event, spaceData);
  }

  function spaceSettingsEventData(space) {
    let settingsData = {
      'Email Notifications Enabled': false,
      'Push Notifications Enabled': false,
    };

    if (space && space.userSettings) {
      settingsData = {
        'Email Notifications Enabled': (space.userSettings.emailNotificationsEnabled || false),
        'Push Notifications Enabled': (space.userSettings.pushNotificationsEnabled || false)
      };
    }
    return extendEventData(settingsData, space);
  }

  function deleted(space) {
    $client.track('Space Deleted', extendEventData({}, space));
  }

  function postCreated(space, post) {
    $client.track('Space Post Created', extendEventData($post.extendEventData({}, post), space));
  }

  function postCreatedForJack(space, post) {
    $client.track('Space Post Created for Jumping Jack', extendEventData($post.extendEventData({}, post), space));
  }

  function postDeleted(space, post) {
    $client.track('Space Post Deleted', extendEventData($post.extendEventData({}, post), space));
  }

  function postEdited(space, post) {
    $client.track('Space Post Edited', extendEventData($post.extendEventData({}, post), space));
  }

  function postLiked(space, post) {
    var eventName = post.liked ? 'Space Post Liked' : 'Space Post Unliked';
    $client.track(eventName, extendEventData($post.extendEventData({}, post), space));
  }

  function postReported(space, post) {
    $client.track('Space Post Reported', extendEventData($post.extendEventData({}, post), space));
  }

  function updated(space) {
    $client.track('Space Updated', spaceSettingsEventData(space));
  }

  return {
    deleted: deleted,
    extendEventData: extendEventData,
    postCreated: postCreated,
    postCreatedForJack: postCreatedForJack,
    postDeleted: postDeleted,
    postEdited: postEdited,
    postLiked: postLiked,
    postReported: postReported,
    updated: updated
  };
}

factory.$inject = ['core.services.tracking.$client', 'core.services.tracking.$post'];

export default factory;

