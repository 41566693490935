
function factory(identity) {
  function Store() {
    this._disabled = false;
  }

  /**
    * Creates a user session specific key for a key value.
    *
    * @param {string} value
    */
  Store.key = function key(value) {
    return [identity.$id, value].join(':');
  };

  /**
    * Retrieves a value out of storage.
    *
    * @param {string} key
    * @returns {*}
    */
  Store.prototype.get = function get(key) {
    if (this._disabled) {
      return null;
    }

    var item = sessionStorage.getItem(Store.key(key));

    if (item != null) {
      return JSON.parse(item);
    }

    return null;
  };

  /**
    * Gets a value or returns a default value.
    *
    * @param {string} key
    * @param {*} defaultValue
    * @returns {*}
    */
  Store.prototype.getOrDefault = function getOrDefault(key, defaultValue) {
    return this.get(key) || defaultValue;
  };

  /**
    * Inserts a new value into store.
    *
    * @param {string} key
    * @param {any} value
    */
  Store.prototype.put = function put(key, value) {
    if (!this._disabled) {
      sessionStorage.setItem(Store.key(key), JSON.stringify(value));
    }
  };

  /**
    * Remove an entry from store.
    *
    * @param {string} key
    */
  Store.prototype.remove = function remove(key) {
    if (!this._disabled) {
      sessionStorage.removeItem(Store.key(key));
    }
  };

  return new Store();
}

factory.$inject = ['core.services.identity'];

export default factory;

