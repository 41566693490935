/**
 * @module Resources
 *
 */
import { fetchGet } from 'app/utils/request';

/**
 * @class FeedFilter
 *
 */
function FeedFilter() {
  function FeedFilterResource() {}

  FeedFilterResource.authors = function(params) {
    return fetchGet('feed_filters/authors', params, { version: 1});
  };

  FeedFilterResource.spaces = function(params) {
    return fetchGet('feed_filters/spaces', params, { version: 1});
  };

  FeedFilterResource.tags = function(params) {
    return fetchGet('feed_filters/tags', params, { version: 1 });
  };

  return FeedFilterResource;
}

FeedFilter.$inject = [];

export default FeedFilter;
