
import checkmark_green_png from 'app/images/checkmark-green.png';
import template from 'ngapp/templates/views/teams/_transfer_team_complete_modal.html';

function Controller($scope) {
  $scope.coach = $scope.$props.coach;
  $scope.onDone = onDone;
  $scope.checkmark_green_png = checkmark_green_png;

  function onDone() {
    $scope.$props.modal.close();
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    modal: '<',
    coach: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
