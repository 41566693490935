/**
 * @module Directives
 *
 */
import assign from 'lodash/assign';

/**
 * @class RepliesPagerFactory
 *
 */
function factory(Reply, createPager) {
  const settings = {
    limit: 8,
    throttle: 10000
  };

  return function create(id, filters) {
    delete settings.cursor;

    function load(request) {
      const query = assign({}, filters, settings, {
        limit: request.limit,
        order: 'desc',
        reverse: true
      });

      return Reply.getPostReplies(id, query).then(res => {
        settings.cursor = res[0].cursor;
        return res;
      });
    }

    return createPager(load, settings);
  };
}

factory.$inject = ['core.services.Reply', 'core.services.pagerFactory'];

export default factory;
