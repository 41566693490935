/** @module resource */
import assign from 'lodash/assign';
import template from 'lodash/template';
import each from 'lodash/each';
import { buildUrl } from 'app/utils/request';

/**
 * @class Subscriptions
 *
 */
function Subscriptions($resource, $q) {
  var Resource = $resource({
    url: 'api/v1/account/paid_subscriptions',
    name: 'subscription'
  });

  Resource.REACTIVATION_SOURCES = ['StripeRenewal', 'StripePurchase'];
  Resource.NON_CANCELABLE_TERMS = ['Trial'];
  Resource.UPGRADE_URI_TEMPLATE = template('/upgrade?plan=${encodeURIComponent(planCode)}');
  Resource.UPGRADE_URI_DEFAULT = '/upgrade';

  Resource.afterResponse(function (resources) {
    each(resources, function (resource) {
      var expired = Date.parse(resource.expiresAt) < Date.now();

      assign(resource, {
        expired: expired,
        canCancel: Resource.NON_CANCELABLE_TERMS.indexOf(resource.term) === - 1 && !expired,
        canReactivate: Resource.REACTIVATION_SOURCES.indexOf(resource.source) > -1 && expired,
        isTrial: resource.term === 'Trial'
      });
    });
  });

  Resource.prototype.saveAutoRenewalStatus = function saveAutoRenewalStatus() {
    if (!this.canManageRenewal) {
      return $q.reject();
    }

    const url = buildUrl('stripe/update_renewal', {}, 1);
    const params = { product: this.kind, enable: this.isAutoRenewing };
    const opts = { skipRequestProcessing: true };

    return Resource.$post(url, params, opts);
  };

  Resource.prototype.getUpgradeUrl = function getUpgradeUrl() {
    if (this.isTrial) {
      return Resource.UPGRADE_URI_DEFAULT;
    }

    return Resource.UPGRADE_URI_TEMPLATE({ planCode: this.planCode });
  };

  return Resource;
}

Subscriptions.$inject = [ 'core.services.$resource', '$q' ];

export default Subscriptions;
