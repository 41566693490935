
import range from 'lodash/range';
import defaultTo from 'lodash/defaultTo';
import template from 'ngapp/templates/views/feed_filters/_picker.html';

const NOW = new Date();
const YEAR_NOW = NOW.getFullYear();
export const YEAR_MIN = 2012;

function Controller($scope) {
  $scope.options = createOptions(YEAR_MIN, YEAR_NOW);
  $scope.min = YEAR_MIN;
  $scope.model = $scope.options[0];
  $scope.onChange = onChange;
  $scope.$props.$onChanges = $onChanges;

  function createOptions(min, max) {
    return range(max, min - 1).map(function(year) {
      return { value: year, label: year.toString() };
    });
  }

  function onChange() {
    $scope.$props.onChange({ value: $scope.model.value });
  }

  function $onChanges(props) {
    if (props.min != null) {
      $scope.options = createOptions(props.min.currentValue, YEAR_NOW);
      $scope.min = props.min.currentValue;
    }

    if (props.value != null) {
      $scope.model = defaultTo($scope.options[YEAR_NOW - props.value.currentValue], $scope.options[0]);
    }
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    value: '<?',
    min: '<?',
    onChange: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
