
import template from 'ngapp/templates/views/teams/_edit_team_member_modal.html';

function Controller($scope, $translate, validate, toast, TeamMember) {
  $scope.onCancel = onCancel;
  $scope.onSubmit = onSubmit;
  $scope.pending = false;
  $scope.member = $scope.$props.member;
  $scope.fields = {
    name: $scope.member.name,
    email: $scope.member.email,
    nickname: $scope.member.nickname,
    position: $scope.member.position,
    number: $scope.member.number,
    type: $scope.member.type
  };
  $scope.errors = validateForm($scope.fields).errors;

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onSubmit(e) {
    e.preventDefault();

    var validationResults = validateForm($scope.fields);

    if (validationResults.isValid) {
      $scope.pending = true;

      TeamMember.update($scope.member, $scope.fields)
        .then(
          function () {
            toast.success($translate.instant('TEAMS.EDIT_MEMBER.CHANGES_SAVED'));

            if ($scope.member.status === 'incomplete') {
              $scope.member.status = 'pending';
            }

            $scope.$props.modal.close();
          })
        .finally(function () {
          $scope.pending = false;
        });
    }

    $scope.errors = validationResults.errors;
  }

  function validateForm(form) {
    var result = {
      errors: {},
      isValid: true
    };

    if (validate.isEmpty(form.name)) {
      result.errors.name = 'required';
      result.isValid = false;
    }

    if (!validate.isEmail(form.email)) {
      result.errors.email = 'invalid';
      result.isValid = false;
    }

    return result;
  }
}

Controller.$inject = [ '$scope', '$translate', 'core.validate', 'core.ui.toast', 'core.services.TeamMember' ];

export default {
  bindings: {
    modal: '<',
    member: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
