/** @module directives/teams */

/**
 * @class TeamModalService
 *
 */
function TeamModal($modal) {
  function createTeam() {
    return $modal.open({
      template:  '<new-team-modal modal="modal"></new-team-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $scope) {
        $scope.modal = $modal;
      }]
    });
  }

  function created(team) {
    return $modal.open({
      template:  '<team-created-modal team="team" modal="modal"></team-created-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $scope) {
        $scope.modal = $modal;
        $scope.team = team;
      }]
    });
  }

  function editMember(member) {
    return $modal.open({
      template: '<edit-team-member-modal member="member" modal="modal"></edit-team-member-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
        $local.member = member;
      }]
    });
  }

  function pickInviteType() {
    return $modal.open({
      template:  '<team-member-invite-modal modal="modal"></team-member-invite-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
      }]
    });
  }

  function inviteByLink(team) {
    return $modal.open({
      template: '<team-member-link-invite-modal team="team" modal="modal"></team-member-link-invite-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
        $local.team = team;
      }]
    });
  }

  function editSettings(team) {
    return $modal.open({
      template: '<team-settings-modal team="team" modal="modal"></team-settings-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
        $local.team = team;
      }]
    });
  }

  function notificationSettings(team) {
    return $modal.open({
      template: '<team-notification-settings-modal team="team" modal="modal"></team-notification-settings-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
        $local.team = team;
      }]
    });
  }

  function reportPost(post) {
    return $modal.open({
      template: '<report-post-modal post="post" modal="modal"></report-post-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
        $local.post = post;
      }]
    });
  }

  function addMembersToChannels(team, members, teamGroups, doneText, closeText) {
    return $modal.open({
      template: `
        <team-member-add-to-channels
          team="team"
          members="members"
          team-groups="teamGroups"
          close-text="closeText"
          done-text="doneText"
          modal="modal"
        />
      `,
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
        $local.team = team;
        $local.members = members;
        $local.teamGroups = teamGroups;
        $local.doneText = doneText;
        $local.closeText = closeText;
      }]
    });

  }

  function importConfirm(team, members, teamGroups) {
    return $modal.open({
      template: `
        <team-import-confirm-modal
          modal="modal"
          team="team"
          team-groups="teamGroups"
          members="members"
        ></team-import-confirm-modal>
      `,
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
        $local.team = team;
        $local.members = members;
        $local.teamGroups = teamGroups;
      }]
    });
  }


  return {
    createTeam: createTeam,
    created: created,
    pickInviteType: pickInviteType,
    inviteByLink: inviteByLink,
    editMember: editMember,
    editSettings: editSettings,
    notificationSettings: notificationSettings,
    reportPost: reportPost,
    addMembersToChannels: addMembersToChannels,
    importConfirm: importConfirm,
  };
}

TeamModal.inject = ['$modal'];

export default TeamModal;
