/**
 * @module Core.Services
 *
 */
import { getExtension, getType } from 'app/utils/file';

/**
 * @class Resources
 *
 */
function factory($resource, $property) {
  var Resource = $resource({
    url: 'api/v2/resources',
    name: 'resource'
  });

  Resource.create = function create(properties) {
    return Resource.$post(Resource.$url(), properties, { rootWrapping: false });
  };

  $property.get(Resource.prototype, 'source', function() {
    return this.url || this.originalUrl;
  });

  $property.get(Resource.prototype, 'extension', function() {
    return getExtension(this.originalUrl || this.url);
  });

  $property.get(Resource.prototype, 'fileType', function() {
    return getType(this.source);
  });

  return Resource;
}

factory.$inject = ['core.services.$resource', 'core.$property'];

export default factory;
