/** @module resource */

function PostSpace($resource) {
  var resource = $resource({
    url: '/api/v2/posts/{{postId}}/posts',
    name: 'post_space'
  });

  resource.getSpaces = function(id) {
    return resource.query({}, { postId: id }).then(function(data) {
      return data.map(function(item) {
        return item.space || item.team || item.teamGroup;
      });
    });
  };

  return resource;
}

PostSpace.$inject = ['core.services.$resource'];

export default PostSpace;
