/** @module resources */

function ResourceTag($resource) {
  return $resource({
    url: 'api/v1/resources/tags',
    name: 'ResourceTag'
  });
}

ResourceTag.$inject = ['core.services.$resource'];

export default ResourceTag;
