/**
 * @module Directives.Upload.Pages
 *
 */
import { digest } from 'app/utils/runloop';
import template from 'ngapp/templates/directives/_upload_progress.html';

/**
 * @class Progress
 *
 */
function Controller($scope) {
  $scope.progress = 0;
  $scope.failed = false;
  $scope.onCancel = onCancel;
  $scope.$props.$onChanges = $onChanges;

  function onCancel() {
    $scope.$props.onCancel();
  }

  function $onChanges(props) {
    if (props.upload != null && props.upload.currentValue != null) {
      props.upload.currentValue.subscribe(
        (data) => digest(() => $scope.progress = (data.loaded / data.total) * 100),
        () => digest(() => $scope.failed = true)
      );
    }
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    upload: '<',
    onCancel: '&'
  },
  template,
  controllerAs: '$props',
  controller: Controller
};
