/**
 * @module Core.Services.Activity
 *
 */
import reject from 'lodash/reject';
import { replace } from 'app/utils/array';

/**
 * @class $Collection
 *
 */
function factory($q, Signal) {
  function $Collection(values) {
    this.$pending = 0;
    this.values = values || [];
    this.onChange = new Signal(this);
  }

  $Collection.prototype = {
    get pending() {
      return this.$pending > 0;
    }
  };

  $Collection.prototype.async = function $async(fn) {
    this.$pending += 1;
    this.$onChange();
    return $q.resolve(fn()).finally(() => {
      this.$pending -= 1;
      this.$onChange();
      return this;
    });
  };

  $Collection.prototype.remove = function remove(id) {
    return $q.resolve().then(() => {
      this.values = reject(this.values, { id });
      this.$onChange();
      return this;
    });
  };

  $Collection.prototype.removeAll = function removeAll() {
    return $q.resolve().then(() => {
      this.values = [];
      this.$onChange();
      return this;
    });
  };

  $Collection.prototype.replaceAll = function replaceAll(values) {
    return $q.resolve().then(() => {
      this.values = values;
      this.$onChange();
      return this;
    });
  };

  $Collection.prototype.push = function push(values) {
    if (values != null) {
      return $q.resolve().then(() => {
        this.values = this.values.concat(values);
        this.$onChange();
        return this;
      });
    }
    return $q.resolve(this);
  };

  $Collection.prototype.unshift = function unshift(values) {
    if (values != null && values.length) {
      let _values = this.values;

      // revese list so each item can be added to the front
      // of the existing values and stay in the original order
      values = values.reverse();

      // check if value is in list already and replace it or
      // add the value to the front of the list
      values.forEach(item => {
        let index = -1;
        _values.find((v, idx) => {
          if (v.id === item.id) { index = idx; }
        });

        if (index === -1) {
          _values = [ item ].concat(_values);
        } else {
          _values = replace(_values, index, item);
        }
      });

      return $q.resolve().then(() => {
        this.values = _values;
        this.$onChange();
        return this;
      });
    }
    return $q.resolve(this);
  };

  $Collection.prototype.$onChange = function $onChange() {
    const { pending, values } = this;
    this.onChange.dispatch(this, { pending, values });
  };

  return $Collection;
}

factory.$inject = ['$q', 'core.Signal'];

export default factory;
