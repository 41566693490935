/**
 * @module Core.Vendor
 *
 */
import videojs from 'video.js/dist/video.js';
import 'video.js/dist/video-js.css';
import { errorLogger } from 'app/utils';

/**
 * @class VideoJsFactory
 *
 */
function factory() {
  return function videoJsFactory(id, options, ready) {
    if (id == null) {
      errorLogger("videojs called with null id", { severity: 'error' });
    }
    return videojs(id, options, ready);
  };
}

factory.$inject = [];

export default factory;
