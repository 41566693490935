
import S3 from 'aws-sdk/clients/s3';

function factory() {
  function require() {
    return S3;
  }

  return {
    require: require
  };
}

factory.$inject = [];

export default factory;
