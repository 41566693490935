/**
 * @module Directives.Paywalls
 *
 */
import template from 'ngapp/templates/views/paywalls/_post_media_other_owner_modal.html';
import posting_icon_png from 'app/images/paywall-body-icon-media-posting.png';

/**
 * @class PostMediaOtherOwnerModal
 *
 */
function Controller($scope, tracking) {
  $scope.onCancel = onCancel;
  $scope.feature = 'Media Posting Other Owner';
  $scope.posting_icon_png = posting_icon_png;

  tracking.paywalls.shown($scope.feature);

  function onCancel() {
    $scope.$props.modal.dismiss();
  }
}

Controller.$inject = ['$scope', 'core.services.tracking'];

export default {
  bindings: {
    modal: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
