/** @module resources */

function SpaceFactory($resource, railsSerializer) {
  const Space = $resource({
    url: 'api/v2/spaces',
    name: 'space',
    serializer: railsSerializer(function() {
      this.resource('userSettings', 'SpaceUserSettings');
    })
  });

  Space.prototype.fullName = function(separator) {
    if(this.athleteName && this.athleteName !== '') {
      var result = this.athleteName;
      if(this.displayName && this.displayName !== '') {
        result += (separator || ' - ');
        result += this.displayName;
      }
      return result;
    } else {
      return this.displayName;
    }
  };

  Space.prototype.canLeave = function () {
    return (this.permissions.indexOf('leave') !== -1);
  };

  Space.prototype.canDelete = function () {
    return (this.permissions.indexOf('delete') !== -1);
  };

  Space.prototype.canEdit = function () {
    return (this.permissions.indexOf('edit') !== -1);
  };

  Space.prototype.canManageMembers = function () {
    return (this.permissions.indexOf('manage_members') !== -1);
  };

  Space.prototype.canMakeAthlete = function () {
    return (this.permissions.indexOf('make_athlete') !== -1);
  };

  Space.prototype.canPost = function () {
    return (this.permissions.indexOf('post') !== -1);
  };

  Space.prototype.canReply = function () {
    return (this.permissions.indexOf('reply') !== -1);
  };

  Space.prototype.isForTeam = function () {
    return (this.type === 'team');
  };

  Space.prototype.isForAthlete = function () {
    return (this.type === 'athlete');
  };

  Space.prototype.isForRecruiter = function () {
    return (this.type === 'recruiter');
  };

  Space.prototype.teamName = function () {
    return this.team.name;
  };

  // Plan Permissions

  Space.prototype.readonly = function() {
    return this.planAccess === 'readonly';
  };

  Space.prototype.planCanManageMembers = function() {
    return (this.planPermissions.indexOf('manage_members') !== -1);
  };

  Space.prototype.planCanEdit = function() {
    return (this.planPermissions.indexOf('edit') !== -1);
  };

  Space.prototype.planCanDelete = function() {
    return (this.planPermissions.indexOf('delete') !== -1);
  };

  Space.prototype.planCanLeave = function() {
    return (this.planPermissions.indexOf('leave') !== -1);
  };

  Space.prototype.planCanPost = function() {
    return (this.planPermissions.indexOf('post') !== -1);
  };

  Space.prototype.planCanReply = function() {
    return (this.planPermissions.indexOf('reply') !== -1);
  };

  return Space;
}

SpaceFactory.$inject = ['core.services.$resource', 'railsSerializer'];

export default SpaceFactory;
