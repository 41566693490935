/**
 * @module Directives.Activity
 *
 */
import each from 'lodash/each';
import template from 'ngapp/templates/views/activity/_outgoing_invitation_list.html';

/**
 * @class OutgoingInvitationList
 *
 */
function Controller($scope, time) {
  $scope.invitations = partition([]);
  $scope.onResend = onResend;
  $scope.onCancel = onCancel;
  $scope.$props.$onChanges = $onChanges;

  function partition(invitations) {
    const buckets = { today: [], week: [], month: [], rest: [] };

    each(invitations, n => {
      const span = time.span(n.createdAt, time.truncate(Date.now()));
      if (span.months > 0) {
        buckets.rest.push(n);
      } else if (span.days > 7) {
        buckets.month.push(n);
      } else if (span.days > 0) {
        buckets.week.push(n);
      } else {
        buckets.today.push(n);
      }
    });

    return buckets;
  }

  function onResend(invitation) {
    $scope.$props.onResend({ invitation });
  }

  function onCancel(invitation) {
    $scope.$props.onCancel({ invitation });
  }

  function $onChanges(props) {
    if (props.invitations != null) {
      $scope.invitations = partition(props.invitations.currentValue);
    }
  }
}

Controller.$inject = ['$scope', 'core.time'];

export default {
  bindings: {
    invitations: '<',
    onResend: '&',
    onCancel: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
