/**
 * @module Core.Services.Tracking
 *
 */
import {
  trialSelected,
  upgradeSelected,
  chatSelected,
  demoSelected,
  paywallShown
} from 'app/utils/analytics-helpers/paywalls';

/**
 * @class $paywalls
 *
 */
function factory() {
  return {
    shown: paywallShown,
    trialSelected,
    upgradeSelected,
    chatSelected,
    demoSelected
  };
}

factory.$inject = [];

export default factory;
