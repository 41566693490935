/**
 * @module Controllers.Spaces
 *
 */
import map from 'lodash/map';

/**
 * @class SpaceFeed
 *
 */
function Controller($scope, $routeParams, $translate, Space, Post, FeedFilter, Resource, listLoader, listFilter, listPaging) {
  $scope.showPrompt = false;
  $scope.spaceId = $routeParams.spaceId;
  $scope.space = {};
  $scope.posts = [];
  $scope.availableTags = [];
  $scope.authors = [];
  $scope.resourceTypes = angular.extend({}, Resource.types, {
    // NOTES — is translation id for 'Notes' string
    'note': 'NOTES'
  });
  $scope.postParams = {
    spaceId: $scope.spaceId
  };
  $scope.orderTypes = {
    created_at: 'CREATED_AT',
    captured_at: 'PUBLISHED_AT',
    content_captured_at: 'CAPTURED_AT'
  };

  listLoader($scope, 'posts', loadPostsPromise, loadPostsHandler);
  listPaging($scope, Post.perPage);
  listFilter($scope, {
    tags:     [],
    order_by: 'captured_at'
  }, 'posts');

  Space.get($scope.spaceId, {'options[include]': ['invitations_info', 'user_settings']}).then(function (space) {
    $scope.space = space;
    $scope.showPrompt = true;
  });

  FeedFilter.tags({ space_id: $scope.spaceId }).then(function(response) {
    var tagsData = map(response.data, function(obj) {
      return obj.name;
    });

    $scope.availableTags = tagsData;
  });

  $scope.authorsPage = 0;

  $scope.loadAuthors = function() {
    if ($scope.loadingAuthors === true) {
      return;
    }

    $scope.authorsPage += 1;
    $scope.loadingAuthors = true;

    FeedFilter.authors({ space_id: $scope.spaceId, page: $scope.authorsPage, per_page: 50 }).then(function(response) {
      $scope.loadingAuthors = false;
      $scope.authors.push.apply($scope.authors, response.data);
    });
  };

  $scope.loadAuthors();

  function loadPostsPromise() {
    var queryParams = {
      'options[include]': ['replies'],
      space_id: $scope.spaceId,
      skip:     $scope.posts.length,
      limit:    Post.perPage
    };

    angular.extend(queryParams, $scope.filterCriteria);

    return Post.query(queryParams);
  }

  function loadPostsHandler(posts) {
    $scope.posts.push.apply($scope.posts, posts);
  }

  $scope.remove = function(post) {
    if (post.planCanDelete()) {
      post.remove().then(function() {
        var index = $scope.posts.indexOf(post);
        $scope.posts.splice(index, 1);
      }, function() {
        $translate('CONTROLLERS.SPACE_FEED.DELETE_POST_ERROR_MESSAGE').then(alert);
      });
    }
  };

  $scope.loadList();
}

Controller.$inject = [
  '$scope',
  '$routeParams',
  '$translate',
  'Space',
  'Post',
  'FeedFilter',
  'Resource',
  'listLoader',
  'listFilter',
  'listPaging'
];

export default {
  name: 'SpaceFeedCtrl',
  controller: Controller
};

