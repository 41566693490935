
import template from 'ngapp/templates/views/teams/_csv_role_selector_modal.html';

function Controller($scope) {
  $scope.fields = {
    role: 'player'
  };
  $scope.allowBack = false;
  $scope.onBack = onBack;
  $scope.onSubmit = onSubmit;
  $scope.onCancel = onCancel;
  $scope.$props.$onChanges = $onChanges;

  function onBack() {
    $scope.$props.modal.dismiss({ reason: 'back' });
  }

  function onCancel() {
    $scope.$props.modal.dismiss({});
  }

  function onSubmit() {
    $scope.$props.modal.close({ role: $scope.fields.role });
  }

  function $onChanges(props) {
    if (props.allowBack != null) {
      $scope.allowBack = props.allowBack.currentValue || false;
    }
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    modal: '<',
    value: '<',
    allowBack: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
