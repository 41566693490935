/** @module directives/teams */
import logo_orange_png from 'app/images/logo-orange.png';
import mail_success_icon_png from 'app/images/mail-success-icon.png';
import incomplete_icon_png from 'app/images/incomplete-icon.png';
import template from 'ngapp/templates/views/teams/_csv_import_summary_modal.html';

/**
 * @class TeamCSVImportSummaryModal
 *
 */
function Controller($scope, $location) {
  $scope.team = null;
  $scope.imported = 0;
  $scope.invited = 0;
  $scope.incomplete = 0;
  $scope.onDone = onDone;
  $scope.onGotoIncomplete = onGotoIncomplete;
  $scope.$props.$onChanges = $onChanges;
  $scope.logo_orange_png = logo_orange_png;
  $scope.mail_success_icon_png = mail_success_icon_png;
  $scope.incomplete_icon_png = incomplete_icon_png;

  function onDone() {
    $location.path($scope.team.membersUrl).search({ status: 'pending' });
    $scope.$props.modal.close();
  }

  function onGotoIncomplete() {
    $location.path($scope.team.membersUrl).search({ status: 'incomplete' });
    $scope.$props.modal.close();
  }

  function $onChanges(props) {
    if (props.team != null) {
      $scope.team = props.team.currentValue;
    }

    if (props.imported != null) {
      $scope.imported = props.imported.currentValue;
    }

    if (props.invited != null) {
      $scope.invited = props.invited.currentValue;
    }

    if (props.incomplete != null) {
      $scope.incomplete = props.incomplete.currentValue;
    }
  }
}

Controller.$inject = ['$scope', '$location'];

export default {
  bindings: {
    modal: '<',
    team: '<',
    imported: '<',
    invited: '<',
    incomplete: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
