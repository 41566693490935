
function factory($location, $window, $url) {
  function upgrade(preselectedPlan) {
    var query = {
      plan: "coachpro+yearly", // default preselected plan
      returnUrl: $location.url()
    };

    // Take preselected plan from args if exist
    if (preselectedPlan !== null && preselectedPlan !== undefined) {
      query["plan"] = preselectedPlan;
    }

    $window.location = $url.build('/upgrade', query);
  }

  return {
    upgrade: upgrade
  };
}

factory.$inject = [
  '$location',
  '$window',
  'core.services.$url'
];

export default factory;
