/** @module core/ui/modal */
const $$sponch$popstate$handle = (() => {
  const $$state = { popping: false };
  window.addEventListener('popstate', () => $$state.popping = true);
  return $$state;
})();

/**
 * @class $events
 *
 */
function factory($document, $root, Signal) {
  var onKeyboardDismiss = new Signal('core.ui.modal.$events');
  var onNavigationDismiss = new Signal('core.ui.modal.$events');
  var onBackdropDismiss = new Signal('core.ui.modal.$events');

  $document.on('keydown', function(e) {
    if (e.key === 'Escape') {
      onKeyboardDismiss.dispatch('core.ui.modal.$events');
    }
  });

  $document.on('click', '.modal-backdrop', function() {
    onBackdropDismiss.dispatch('core.ui.modals.$event');
  });

  $root.$on('$locationChangeStart', function(e) {
    if ($$sponch$popstate$handle.popping) {
      onNavigationDismiss.dispatch('core.ui.modal.$events', {
        stopNavigation: function() {
          e.preventDefault();
        }
      });
    }

    $$sponch$popstate$handle.popping = false;
  });

  return {
    onBackdropDismiss: onBackdropDismiss,
    onKeyboardDismiss: onKeyboardDismiss,
    onNavigationDismiss: onNavigationDismiss
  };
}

factory.$inject = ['$document', '$rootScope', 'core.Signal'];

export default factory;
