/**
 * @module Controllers.Spaces
 *
 */
import moment from 'moment';
import { fetchDemoSpaceAction } from 'app/actions/DemoSpace';
import { run } from 'app/utils/runloop';
import { sortObjectsBy } from 'app/utils/array';
import { AuthService } from 'app/services';
import { errorLogger } from 'app/utils';
import template from 'ngapp/templates/views/spaces/_space_demo_timeline.html';

/**
 * @class SpaceDemoTimeline
 *
 */
function Controller($scope, $params, $location, toast) {
  $scope.pending = true;
  $scope.spaceId = $params.spaceId;
  $scope.demoSpace = null;
  $scope.space = {};
  $scope.feed = [];
  $scope.user = null;
  $scope.canPost = false;
  $scope.canPostMedia = false;
  $scope.onEdit = onEdit;
  $scope.onLike = onLike;
  $scope.onPost = onPost;
  $scope.onRemove = onRemove;
  $scope.onReply = onReply;
  $scope.onReport = onReport;
  $scope.onScroll = onScroll;
  $scope.onUpgradeToPostClick = onUpgradeToPostClick;
  $scope.onFilterChange = onFilterChange;
  $scope.isAuth = false;
  $scope.isFrame = $params.frame && $params.back;
  $scope.showDemoPostModal = false;
  $scope.demoPostType = '';

  $scope.navigateBack = () => {
    $location.path('/spaces');
  };

  if (AuthService.isAuthenticated) {
    $scope.isAuth = true;
    AuthService.getAuthUser().then(user => run(() => {
      $scope.user = user;
    }));
  }

  const setData = (state) => run(() => {
    if (state != null) {
      if (state.data != null) {
        const data = state.data.get($params.spaceId);
        if (data != null) {
          $scope.demoSpace = data;
          $scope.space = data.space;
          $scope.feed = data.posts;
        } else {
          errorLogger("state data not found in SpaceDemoTimeline", {
            severity: 'error',
            metaData: { spaceId: $params.spaceId, state }
          });
        }
        $scope.pending = state.pending;
      } else if (state.error != null) {
        $scope.pending = state.pending;
        toast.danger(state.error.slice(0, 1).toUpperCase() + state.error.slice(1));
      }
    }
  });

  $scope.actions = { demoSpace: () => fetchDemoSpaceAction($params.spaceId, null, true) };
  $scope.onStateChange = ({ demoSpace }) => setData(demoSpace);

  function onScroll() {
    return;
  }

  function onEdit() {
    return;
  }

  function onLike() {
    return;
  }

  function onPost() {
    return Promise.resolve();
  }

  function onReply() {
    return;
  }

  function onReport() {
    return;
  }

  function onRemove() {
    return;
  }

  function onUpgradeToPostClick(type) {
    $scope.showDemoPostModal = true;
    $scope.demoPostType = type;
  }

  $scope.closeDemoPost = () => {
    $scope.showDemoPostModal = false;
  };

  const isInRange = (to, createdAt) => {
    to = moment(to);
    createdAt = moment(createdAt);

    if (to.isAfter(createdAt)) {
      return true;
    }
    return false;
  };

  function onFilterChange(filters) {
    if ($scope.demoSpace != null && $scope.demoSpace.posts && $scope.demoSpace.posts.length) {
      if (filters) {
        $scope.feed = $scope.demoSpace.posts.filter(model => {
          if (filters.type.length) {
            if (filters.type.indexOf(model.type) === -1) {
              return false;
            }
          }

          if (!isInRange(filters.to, model[filters.orderBy])) {
            return false;
          }

          return true;
        });

        $scope.feed = sortObjectsBy($scope.feed, [ filters.orderBy ]);
      } else {
        $scope.feed = sortObjectsBy($scope.demoSpace.posts, [ 'createdAt' ]);
      }
    }
  }
}

Controller.$inject = [
  '$scope',
  '$routeParams',
  '$location',
  'core.ui.toast',
];

export default {
  controller: Controller,
  template
};
