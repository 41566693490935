
function InvitationPresenter($translate) {
  this.resendErrors = function(errorResponse) {
    var errors = errorResponse.errors;

    if (errors && errors.sent_at && errors.sent_at[0] === 'time threshold violated') {
      $translate('SERVICES.INVITATION_PRESENTER.RESEND_ERRORS.TIME_THRESHOLD_VIOLATED');
    } else {
      $translate('SERVICES.INVITATION_PRESENTER.RESEND_ERRORS.GENERAL_ERROR');
    }
  };

  return this;
}

InvitationPresenter.$inject = ['$translate'];

export default InvitationPresenter;

