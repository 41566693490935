/** @module resources */

function TimezoneFactory($resource) {
  var Timezone = $resource({
    url: 'api/v1/timezones',
    name: 'timezone'
  });

  return Timezone;
}

TimezoneFactory.$inject = ['core.services.$resource'];

export default TimezoneFactory;
