/**
 * @module Core.Services
 *
 */

/**
 * @class AvatarUploader
 *
 */
function factory($q, crop, uploader) {
  function upload(file, type) {
    return crop(file).then(result => {
      return uploader.blob(result.blob, 'avatar.jpeg', result.type, type)
        .then(data => ({ preview: result.url, object: data, url: data.url }));
    });
  }

  return upload;
}

factory.$inject = ['$q', 'core.services.avatarCrop', 'core.services.uploader'];

export default factory;
