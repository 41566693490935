/** @module core/services */
import { fetchGet, fetchPost, templateUrl } from 'app/utils/request';

/**
 * @class BulkTeamInvite
 *
 */
function factory($bulkJobPoller) {
  const URL = {
    imports: templateUrl('teams/{team}/memberships/imports'),
    import: templateUrl('teams/{team}/memberships/imports/{import}')
  };

  function toImportProgress({ id, status, importedMembersCount, totalMembersCount }) {
    return {
      id, status,
      loaded: importedMembersCount,
      total: totalMembersCount
    };
  }

  function enqueue(teamId, members, teamGroupIds) {
    const url = URL.imports({ team: teamId });
    return fetchPost(url, { memberships: members, teamGroupIds }).then(data => toImportProgress(data));
  }

  function getImportStatus(teamId, importId) {
    const url = URL.import({ team: teamId, import: importId });
    return fetchGet(url).then(data => toImportProgress(data));
  }

  function create(teamId, members, groupIds) {
    return $bulkJobPoller.enqueue(
      () => enqueue(teamId, members, groupIds),
      (id) => getImportStatus(teamId, id)
    );
  }

  return { create };
}

factory.$inject = [ 'core.services.$bulkJobPoller' ];

export default factory;
