
function alertServiceFactory($window, $q) {
  function alert(message) {
    $window.alert(message);
    return $q.resolve();
  }

  return alert;
}

alertServiceFactory.$inject = ['$window', '$q'];

export default alertServiceFactory;
