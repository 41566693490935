/** @module core/services */
import pickBy from 'lodash/pickBy';
import { fetchGet, fetchPost, templateUrl } from 'app/utils/request';

/**
 * @class TeamCsvImport
 *
 */
function factory(uploader, $import) {
  const URL = {
    enqueue: templateUrl('teams/{team}/memberships/imports/from_file'),
    status: templateUrl('teams/{team}/memberships/imports/{import}')
  };

  function toImportProgress({ id, status, importedMembersCount, incompleteMembersCount }) {
    const imported = importedMembersCount;
    const incomplete = incompleteMembersCount;
    const invited = imported - incomplete;

    return { id, status, imported, invited, incomplete };
  }

  const postTeamImportFile = function postTeamImportFile(sheet, columns, role, teamId, teamGroupIds, fd) {
    const url = URL.enqueue({ team: teamId });
    const file = {
      url: fd.url,
      meta: {
        sheets: [ sheet ],
        columns: pickBy(columns, v => v !== -1),
        type: role
      }
    };
    return fetchPost(url, { file, team_group_ids: teamGroupIds });
  };

  const getFileData = function getFileData(f) {
    return uploader.file(f, 'file').uploaded;
  };

  const enqueue = function enqueue(team, file, params, teamGroupIds) {
    return getFileData(file)
      .then(fd => postTeamImportFile(params.sheet, params.columns, params.role, team.id, teamGroupIds, fd))
      .then(data => toImportProgress(data));
  };

  const getImportStatus = function getImportStatus(teamId, importId) {
    const url = URL.status({ team: teamId, import: importId });
    return fetchGet(url)
      .then(data => toImportProgress(data));
  };

  return {
    invite(team, file, params, teamGroupIds) {
      return $import.enqueue(
        () => enqueue(team, file, params, teamGroupIds),
        (statusId) => getImportStatus(team.id, statusId)
      );
    }
  };
}

factory.$inject = [ 'core.services.uploader', 'core.services.$bulkJobPoller' ];

export default factory;

