function PostHelpers() {
  function getLocationUri(post) {
    var uri = null;

    if (post != null) {
      if (post.isBulk) {
        uri = '/feed';
      } else if (post.smartList != null) {
        uri = '/spaces';
      } else if (post.teamGroup != null) {
        uri = '/groups/' + post.teamGroup.team.id + '/channels/' + post.teamGroup.id;
      } else if (post.team != null) {
        uri = '/groups/' + post.team.id;
      } else if (post.space != null) {
        uri = '/spaces/' + post.space.id;
      }
    }

    return uri;
  }

  return {
    getLocationUri: getLocationUri
  };
}

PostHelpers.$inject = [];

export default PostHelpers;
