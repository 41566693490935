/**
 * @module Services
 *
 */
import filter from 'lodash/filter';

/**
 * @class Format
 *
 */
function factory($translate) {
  function memberSummary(member, isOwner) {
    if (member == null) {
      return '';
    }

    if (isOwner) {
      return $translate.instant('MEMBER.TYPE.OWNER');
    }
    return filter([ member.position, formatNumber(member.number) ]).join(' / ');
  }

  function formatNumber(number) {
    if (number) {
      number = '#' + number;
    }
    return number;
  }

  function segmentDescription(segment) {
    if (segment == null) {
      return '';
    }

    const spaces = segment.spaceIds || [];
    if (spaces.length > 0) {
      return $translate.instant('SEGMENTS.SEGMENTS_LIST.ONE_SPACE');
    }
    return $translate.instant('SEGMENTS.SEGMENTS_LIST.MULTIPLE_SPACES_FORMAT', { count: spaces.length });
  }

  return {
    memberSummary: memberSummary,
    segmentDescription: segmentDescription
  };
}

factory.$inject = ['$translate'];

export default factory;
