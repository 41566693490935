/** @module resources */
import { fetchGet, bindAngularResource } from 'app/utils/request';

/**
 * @class Organization
 *
 */
function Organization($resource) {
  const Organization = $resource({
    url: '/api/v1/organizations',
    name: 'organization'
  });

  Organization.get = function() {
    return fetchGet('organizations', {}, { version: 1 })
      .then(models => bindAngularResource(models, this));
  };

  return Organization;
}

Organization.$inject = ['core.services.$resource'];

export default Organization;
