/**
 * @module Directives
 *
 */
import template from 'ngapp/templates/directives/_tag_it.html';

/**
 * @class TagIt
 *
 */
export default function TagIt() {
  function link($scope, $element) {
    $scope.$watch('disabled', function (disabled) {
      var $input = $element.children();

      if (disabled) {
        $input.addClass('disabled');
      } else {
        $input.removeClass('disabled');
      }
    });
  }

  return {
    template,
    restrict: 'AE',
    link: link,
    scope: {
      tags: "=",
      availableTags: "=",
      appendToBody: "=",
      placeholder: "@",
      controlDisabled: "="
    }
  };
}

