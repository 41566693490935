/**
 * @module Controllers
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/space_template_members/_list.html';

/**
 * @class SpaceTemplateMembers
 *
 */
function Controller($scope, $translate, $location, listLoader, SpaceTemplate, $routeParams, toast) {
  $scope.members = [];
  $scope.spaceTemplate  = {};

  const templateLoader = () => SpaceTemplate.get($routeParams.spaceTemplateId);
  const templateHandler = (spaceTemplate) => {
    run(() => {
      $scope.spaceTemplate = spaceTemplate;
      $scope.members = spaceTemplate.members;
    });
  };

  listLoader($scope,'members', templateLoader, templateHandler);

  $scope.loadList();

  $scope.addMember = (value) => {
    $scope.member = null;

    if (value != null && value.length) {
      $scope.member = value[value.length-1].model;

      $scope.spaceTemplate.addMember($scope.member).then(() => {
        toast.success($translate.instant('CONTROLLERS.SPACE_TEMPLATE_MEMBERS.ADD_MEMBER_SUCCESS_MESSAGE'));
        run(() => $scope.members = $scope.spaceTemplate.members.slice(0));
      });
    }
  };

  $scope.remove = (member) => {
    $scope.spaceTemplate.removeMember(member).then(() => run(() => {
      toast.success($translate.instant('CONTROLLERS.SPACE_TEMPLATE_MEMBERS.REMOVE_MEMBER_SUCCESS_MESSAGE'));
      $scope.members = $scope.spaceTemplate.members.slice(0);
    }));
  };
}

Controller.$inject = [
  '$scope',
  '$translate',
  '$location',
  'listLoader',
  'SpaceTemplate',
  '$routeParams',
  'core.ui.toast'
];

export default {
  controller: Controller,
  template
};
