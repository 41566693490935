
export default function PropertyFactory() {
  /**
    * Creates a getter property on an object.
    *
    * @param {object} object
    * @param {string} name
    * @param {function} accessor
    */
  function get(object, name, accessor) {
    Object.defineProperty(object, name, {
      enumerable: true,
      get: accessor
    });
  }
  return { get: get };
}
