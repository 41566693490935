/**
 * @module Controllers.Spaces
 *
 */
import { all } from 'rsvp';
import uniqBy from 'lodash/uniqBy';
import reject from 'lodash/reject';
import { run } from 'app/utils/runloop';
import { spaceIncludesJack } from 'app/utils/appcues';
import template from 'ngapp/templates/views/spaces/_space_timeline.html';

/**
 * @class SpaceTimeline
 *
 */
function Controller(
  $root,
  $scope,
  $params,
  identity,
  tracking,
  planFeature,
  Post,
  Space,
  SpaceMember,
  SpaceInvitation,
  paywallModal,
  createPager,
  invitationModal,
  scheduledPostModal
) {
  $scope.canPost = true;
  $scope.pending = true;
  $scope.spaceId = $params.spaceId;
  $scope.space = {};
  $scope.feed = [];
  $scope.user = null;
  $scope.canPostMedia = true;
  $scope.canSchedulePosts = false;
  $scope.onEdit = onEdit;
  $scope.onLike = onLike;
  $scope.onPost = onPost;
  $scope.onRemove = onRemove;
  $scope.onReply = onReply;
  $scope.onReport = onReport;
  $scope.onTogglePin = onTogglePin;
  $scope.onScroll = onScroll;
  $scope.onUpgradeToPostClick = onUpgradeToPostClick;
  $scope.onScheduleClick = onScheduleClick;
  $scope.onFilterChange = onFilterChange;

  let pager = createPager($scope.spaceId);
  loadData(pager);

  function loadData(pager) {
    all([ pager.get(), identity.request(), Space.get($scope.spaceId, { 'options[include]': ['invitations_info', 'user_settings'] }) ]).then(([ feed, user, space ]) => run(() => {
      $scope.feed = feed;
      $scope.user = user;
      $scope.space = space;
      $scope.canPost = space.planAccess == null || space.planAccess !== 'readonly';
      $scope.canSchedulePosts = planFeature.user.canSchedulePosts(user);
      $scope.pending = false;

      if ($params.showModal && $scope.space.athleteShortInvitationUrl) {
        invitationModal.spaceCreatedCopyPhoneInviteLink($scope.space.athleteName, $scope.space.athleteShortInvitationUrl);
      }
    }));
  }

  // check if this space includes Jumping Jack
  $scope.isJumpingJackPost = false;
  spaceIncludesJack($scope.spaceId).then(hasJack => run(() => {
    if (hasJack) {
      $scope.isJumpingJackPost = true;
    }
  }));

  pager.onPage.attach(onPage);

  function onPage(e) {
    run(() => {
      $scope.feed = uniqBy($scope.feed.concat(e.data.results), 'id');
    });
  }

  function onScroll() {
    pager.next();
  }

  function onEdit(post) {
    tracking.spaces.postEdited($scope.space, post);
  }

  function onLike(post) {
    tracking.spaces.postLiked($scope.space, post);
  }

  function onPost(fields) {
    return Post.postToSpace($scope.spaceId, fields).then(post => run(() => {
      if ($scope.isJumpingJackPost) {
        tracking.spaces.postCreatedForJack($scope.space, post);
      } else {
        tracking.spaces.postCreated($scope.space, post);
      }

      $root.$broadcast('postCreated');

      // $scope.feed = [post].concat($scope.feed);
      loadData(pager);

      if (post.scheduledAt !== null) {
        scheduledPostModal.showScheduledPostModal(post, $scope.space).result;
      }
    }));
  }

  function onReply(reply) {
    tracking.spaces.postCreated($scope.space, reply);
  }

  function onReport(e) {
    tracking.spaces.postReported($scope.space, e.post);
    onRemove(e.post);
  }

  function onTogglePin(post) {
    $scope.pending = true;

    post.toggle_pin().then(function() {
      loadData(pager);
    });
  }

  function onRemove(post) {
    run(() => {
      tracking.spaces.postDeleted($scope.space, post);
      if (!post.isReply) {
        $scope.feed = reject($scope.feed, { id: post.id });
      }
    });
  }

  function onUpgradeToPostClick() {
    paywallModal.spaceLimitReadOnly();
  }

  function onScheduleClick() {
    paywallModal.proUpgrade();
  }

  function onFilterChange(filters) {
    pager.destroy();
    pager = createPager($scope.spaceId, filters);
    pager.onPage.attach(onPage);
    pager.get().then(feed => run(() => $scope.feed = feed));
  }
}

Controller.$inject = [
  '$rootScope',
  '$scope',
  '$routeParams',
  'core.services.identity',
  'core.services.tracking',
  'core.services.planFeature',
  'core.services.Post',
  'core.services.Space',
  'SpaceMember',
  'SpaceInvitation',
  'paywallModal',
  'spaceFeedPagerFactory',
  'invitationModal',
  'scheduledPostModal'
];

export default {
  controller: Controller,
  template
};
