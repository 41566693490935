
import map from 'lodash/map';
import includes from 'lodash/includes';
import fromPairs from 'lodash/fromPairs';

function ACLFactory() {
  /**
    * Iterates all actions mapping, and determines what action is enabled.
    *
    * @param {object} actions
    * @param {array} enabled
    * @returns {object}
    */
  function interpolate(actions, enabled) {
    var permissions = enabled || [];
    var pairs = map(actions || {}, function (action, name) {
      return [name, includes(permissions, action)];
    });

    return fromPairs(pairs);
  }

  return {
    interpolate: interpolate
  };
}

ACLFactory.$inject = [];

export default ACLFactory;
