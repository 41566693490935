/**
 * @module Directives.Spaces
 *
 */

/**
 * @class SpaceModalServices
 *
 */
function SpaceModal($modal) {
  function editSettings(space) {
    return $modal.open({
      template: '<space-settings-modal space="space" modal="modal"></space-settings-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
        $local.space = space;
      }]
    });
  }

  function invite(users, label) {
    return $modal.open({
      template:  '<invite-space-user-modal users="users" modal="modal" label="label"></invite-space-user-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
        $local.users = users;
        $local.label = label;
      }]
    });
  }

  function notificationSettings(space) {
    return $modal.open({
      template: '<space-notification-settings-modal space="space" modal="modal"></space-notification-settings-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $local) {
        $local.modal = $modal;
        $local.space = space;
      }]
    });
  }

  return {
    editSettings: editSettings,
    invite: invite,
    notificationSettings: notificationSettings
  };
}

SpaceModal.inject = ['$modal'];

export default SpaceModal;
