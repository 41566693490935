/** @module legacy/app/core/ui/dropdown */
import { run } from 'app/utils/runloop';

/**
 * @class Toggle
 *
 */
function controller($log, $scope, $document, $trigger, $animate) {
  const $dropdown = $trigger.closest('.dropdown');
  let warned = false;
  if ($scope.isOpen == null) {
    $scope.isOpen = false;
  }

  const onClick = (e) => {
    const element = $dropdown.get(0);
    if (element == null) {
      if (!warned) {
        $log.info('.dropdown-trigger is not inside of a .dropdown element', $trigger[0]);
        warned = true;
      }
      return;
    }

    if (element.contains(e.target)) { // click was triggered within this dropdown menu
      if ($trigger[0].contains(e.target)) { // click triggered on open/close dropdown menu button
        if ($trigger.prop('disabled')) { // if button is disabled then force close
          close();
        } else { // else call next state in toggle
          toggle();
        }
      } else { // click was triggered from within dropdown menu
        // if dropdown has a close menu and it triggered the click then close the menu
        const $dismissers = $dropdown.find("[dismiss='dropdown']");
        if ($dismissers.has(e.target).length > 0 || $dismissers.is(e.target)) {
          close();
        }
      }
    } else { // click triggered outside menu
      close();
    }
  };

  $document.on('click', onClick);

  const unsubscribeDestroy = $scope.$on('$destroy', onDestroy);
  const ubsubscribeLocationChange = $scope.$on('$locationChangeSuccess', close);

  function onDestroy() {
    $document.off('click', onClick);
    unsubscribeDestroy();
    ubsubscribeLocationChange();
  }

  function toggle() {
    if ($scope.isOpen) {
      close();
    } else {
      open();
    }
  }

  function close() {
    if ($scope.isOpen) {
      $animate.close($dropdown).then(() =>
        run(() => {
          $scope.isOpen = false;
          if ($scope.onClose) {
            $scope.onClose();
          }
        })
      );
    }
  }

  function open() {
    if (!$scope.isOpen) {
      $animate.open($dropdown).then(() =>
        run(() => {
          $scope.isOpen = true;
          if ($scope.onOpen) {
            $scope.onOpen();
          }
        })
      );
    }
  }
}

controller.$inject = [ '$log', '$scope', '$document', '$element', 'core.ui.dropdown.$animate' ];

function DropdownToggle($injector) {
  function link($scope, $element, $attributes) {
    $injector.invoke(controller, $scope, {
      $scope: $scope,
      $element: $element,
      $attributes: $attributes
    });
  }

  return {
    restrict: 'CA',
    scope: {
      isOpen: '=?',
      onOpen: '&',
      onClose: '&'
    },
    link: link
  };
}

DropdownToggle.$inject = ['$injector'];

export default DropdownToggle;
