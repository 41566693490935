
function factory($client) {
  function canDelete(space) {
    return $client.hasPlanPermission(space, 'delete');
  }

  function canEdit(space) {
    return $client.hasPlanPermission(space, 'edit');
  }

  function canLeave(space) {
    return $client.hasPlanPermission(space, 'leave');
  }

  function canManageMembers(space) {
    return $client.hasPlanPermission(space, 'manage_members');
  }

  function hasSpaceSettingsAccess(space) {
    return space.can.edit || space.can.delete;
  }

  return {
    canDelete: canDelete,
    canEdit: canEdit,
    canLeave: canLeave,
    canManageMembers: canManageMembers,
    hasSpaceSettingsAccess: hasSpaceSettingsAccess
  };
}

factory.$inject = ['core.services.planFeature.$client'];

export default factory;
