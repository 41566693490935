/**
 * @module Controllers
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/account/_password.html';

/**
 * @class AccountPasswordController
 *
 */
function Controller($root, $scope, $translate, toast) {
  $scope.processing = false;
  $scope.onSubmit = onSubmit;

  const setUser = (user) => {
    $scope.user = { ...user };
    $scope.user.current_password = '';
    $scope.user.password = '';
    $scope.user.password_confirmation = '';
  };
  setUser($root.user);

  $root.$on('userUpdated', (event, user) => {
    setUser(user);
  });

  function onSubmit(event) {
    event.preventDefault();
    $scope.validationErrors = null;
    $scope.processing = true;
    const { current_password, password, password_confirmation } = $scope.user;

    $root.user.resetPassword(current_password, password, password_confirmation)
      .then(() => toast.success($translate.instant('CONTROLLERS.ACCOUNT_PASSWORD.UPDATE_SUCCESS_MESSAGE')))
      .catch(error => handleErrors(event, error))
      .finally(() => run(() => $scope.processing = false));
  }

  function handleErrors(event, error) {
    event.stopPropagation();
    let err = error.originalErrors;

    let message = {};
    if ('current_password' in err) {
      message['current_password'] = $translate.instant('CONTROLLERS.ACCOUNT_PASSWORD.CURRENT_PASSWORD_REQUIRED');
    }

    if ('password' in err) {
      message['password'] = $translate.instant('CONTROLLERS.ACCOUNT_PASSWORD.PASSWORD_REQUIRED');
    }

    if ('password_confirmation' in err) {
      if (err.password_confirmation.indexOf('mismatch') !== -1) {
        message['password_confirmation'] = $translate.instant('CONTROLLERS.ACCOUNT_PASSWORD.PASSWORD_MISMATCH_ERROR');
      } else {
        message['password_confirmation'] = $translate.instant('CONTROLLERS.ACCOUNT_PASSWORD.PASSWORD_CONF_REQUIRED');
      }
    }

    run(() => $scope.validationErrors = message);
  }
}

Controller.$inject = [
  '$rootScope',
  '$scope',
  '$translate',
  'core.ui.toast',
];

export default {
  controller: Controller,
  template
};

