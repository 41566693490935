/**
 * @module Controllers
 *
 */
import { run } from 'app/utils/runloop';
import { postViewed, postDeleted } from 'app/utils/analytics-helpers/posts';
import template from 'ngapp/templates/views/posts/_show.html';

/**
 * @class PostShowController
 *
 */
function Controller($, $q, $scope, $routeParams, $location, $timeout, $translate, Post, tracking) {
  $scope.pending = true;
  $scope.activeReply = null;
  $scope.post = null;
  $scope.space = null;
  $scope.location = null;
  $scope.postId = $routeParams.postId;
  $scope.remove = remove;

  const postRequest = Post.query({'options[include]': ['replies', 'space']}, { id: $routeParams.postId });

  const threadRequest = postRequest.then((post) => {
    if (post.isReply) {
      return Post.query({ 'options[include]': ['replies', 'space'] }, { id: post.rootId });
    }
    return post;
  });

  const locationRequest = threadRequest.then((thread) => {
    if (thread.team != null) {
      return {
        name: thread.team.name,
        description: $translate.instant('CONTROLLERS.POST_SHOW.TEAM_CHANNELS'),
        logo: thread.team.logo,
        url: '/groups/' + thread.team.id
      };
    } else if (thread.teamGroup != null) {
      return {
        name: thread.teamGroup.team.name,
        description: thread.teamGroup.name,
        logo: thread.teamGroup.team.logo,
        url: '/groups/' + thread.teamGroup.team.id + '/channels/' + thread.teamGroup.id
      };
    } else if (thread.space != null) {
      return {
        name: thread.space.athleteName,
        description: thread.space.sport,
        logo: thread.space.avatar,
        url: '/spaces/' + thread.space.id
      };
    }

    return null;
  });

  $q.all([postRequest, threadRequest, locationRequest])
    .then(([ post, thread, location ]) => {
      run(() => {
        if (post.isReply) {
          $scope.activeReply = post;
        }
        $scope.post = thread;
        $scope.space = thread.space;
        $scope.location = location;
        postViewed(post);
        $scope.pending = false;
      });
    }).finally(() => scrollToReply());

  function remove(post) {
    if (post.can.delete) {
      post.remove().then(() => {
        if (post.space) {
          $location.path('/spaces/' + post.spaceId);
          postDeleted();
        } else if (post.team) {
          $location.path('/groups/' + post.team.id);
          tracking.teams.postDeleted(post.team, post);
        } else if (post.group) {
          $location.path('/groups/' + post.teamGroup.team.id + 'channels' + post.teamGroup.id);
          tracking.teamGroups.postDeleted(post.teamGroup, post.teamGroup.team, post);
        } else {
          $location.path('/feed');
        }
      })
      .catch(() => $translate('CONTROLLERS.POST_SHOW.REMOVE_ERROR_MESSAGE').then(alert));
    }
  }

  function scrollToReply() {
    if($scope.post.id !== $scope.postId) {
      const selector = '#reply-' + $scope.postId;
      $timeout(() => {
        const $reply = $(selector);
        $reply.scrollParent().animate(
          { scrollTop: $reply.position().top },
          250,
          () => $reply.addClass('a-triggered')
        );
      }, 500);
    }
  }
}

Controller.$inject = [
  'jQuery',
  '$q',
  '$scope',
  '$routeParams',
  '$location',
  '$timeout',
  '$translate',
  'core.services.Post',
  'core.services.tracking'
];

export default {
  controller: Controller,
  template
};
