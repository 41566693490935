
import assign from 'lodash/assign';

function StripeElementsFactory($stripe) {
  var ELEMENTS_CONFIG = {
    fonts: [
      { cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:300,300i,600,600&subset=cyrillic-ext,latin-ext" }
    ]
  };

  var STRIPE_THEME = {
    style: {
      base: {
        color: '#555',
        lineHeight: '1.5',
        fontSize: '16px',
        fontFamily: '"Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif',
        '::placeholder': {
          color: '#9b9b9b'
        }
      },
      invalid: {
        color: '#555'
      }
    },
    classes: {
      base: 'stripe-input',
      focus: 'focus'
    }
  };

  return function stripeElementsFactory() {
    var elements = $stripe.elements(ELEMENTS_CONFIG);

    return {
      create: function create(type, options) {
        return elements.create(type, assign({}, STRIPE_THEME, options));
      }
    };
  };
}

StripeElementsFactory.$inject = ['stripeJS'];

export default StripeElementsFactory;
