// jshint ignore: start

var translationsEn = {
  // Common
  ATHLETE: 'Athlete',
  COACH: 'Coach',
  PARENT_OR_FAN: 'Parent / Fan',
  CONTRIBUTOR: 'Member',
  LOADING: 'Loading...',
  CANCEL: 'Cancel',
  PLEASE_WAIT: 'Please wait...',
  TEAM: 'Group',
  POSTS_PLURAL: "{postsCount, plural, =1 {# Post} other {# Posts}}",
  SPACES_PLURAL: "{spacesCount, plural, =1 {# Space} other {# Spaces}}",
  SEGMENTS_PLURAL: "{segmentsCount, plural, =1 {# List} other {# Lists}}",
  GENDERS: {
    MALE: 'Male',
    FEMALE: 'Female'
  },
  COUNTRIES: {
    AF: 'Afghanistan',
    AX: 'Aland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia, Plurinational State of',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei Darussalam',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Congo, the Democratic Republic of the',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: 'Côte D\'ivoire',
    HR: 'Croatia',
    CU: 'Cuba',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands (Malvinas)',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island and Mcdonald Islands',
    VA: 'Holy See (Vatican City State)',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran, Islamic Republic of',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KP: 'Korea, Democratic People\'s Republic of',
    KR: 'Korea, Republic of',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: 'Lao People\'s Democratic Republic',
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libyan Arab Jamahiriya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia, the Former Yugoslav Republic of',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia, Federated States of',
    MD: 'Moldova, Republic of',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    AN: 'Netherlands Antilles',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territory, Occupied',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Romania',
    RU: 'Russian Federation',
    RW: 'Rwanda',
    BL: 'Saint Barthélemy',
    SH: 'Saint Helena, Ascension and Tristan Da Cunha',
    KN: 'Saint Kitts and Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin',
    PM: 'Saint Pierre and Miquelon',
    VC: 'Saint Vincent and the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome and Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard and Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syrian Arab Republic',
    TW: 'Taiwan, Province of China',
    TJ: 'Tajikistan',
    TZ: 'Tanzania, United Republic of',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UM: 'United States Minor Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela, Bolivarian Republic of',
    VN: 'Viet Nam',
    VG: 'Virgin Islands, British',
    VI: 'Virgin Islands, U.S.',
    WF: 'Wallis and Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe'
  },
  // Layout
  MENU: {
    HOME: 'Home',
    FEED: 'Feed',
    TRAININGSPACES: 'Spaces',
    TEAMS: 'Groups',
    SEGMENTS: 'Lists',
    LIBRARY: 'Library',
    TAGS: 'Tags',
    INVITATIONS: 'Invitations',
    NOTIFICATIONS: 'Notifications',
    TEMPLATES: 'Templates',
    SCHEDULED_POSTS: 'Schedule',
    FAQ: 'Support & FAQ',
    CONTACT_SUPPORT: 'Contact Support',
    OTHER: 'Other'
  },
  FEED_FILTER: {
    TRAININGSPACE: 'Space',
    AUTHOR: 'Author',
    ORDER_BY: 'Sort By',
    TYPE: 'Media Type',
    TAGS: 'Tags',
    DATE: 'Jump To',
    FROM: 'From',
    TO: 'Till',
    FILTER_BUTTON: 'Filter',
    SHOW_ALL: 'Show All',
    PLACEHOLDERS: {
      TRAININGSPACE: 'Select a Space',
      AUTHOR: 'Select an Author',
      ORDER_BY: 'Select an order',
      TYPE: 'Select a Type',
      TAG: 'Tags'
    },
    ORDER_TYPES: {
      CREATED_AT: 'Created at',
      PUBLISHED_AT: 'Published at',
      CAPTURED_AT: 'Captured at'
    },
    RESOURCE_TYPES: {
      AUDIO: 'Audio',
      VIDEO: 'Video',
      IMAGES: 'Images',
      DOCUMENTS: 'Documents',
      NOTES: 'Notes'
    },
    ALL_TS: ' All Spaces'
  },
  FEED: {
    NO_POSTS: 'No Posts Yet',
    NO_POSTS_SUMMARY: 'The feed contains all posts from Spaces and Teams you\'re a part of. Post something now for others to see.',
    NOT_FOUND: 'No results found',
    NOT_FOUND_SUMMARY: 'Try updating your filters.',
    FILTER: 'Filter'
  },
  TOP_MENU: {
    INVITATIONS: {
      TITLE: 'Invitations',
      SEE_ALL: 'See all your invitations',
      NO_INVITATIONS: 'You do not have pending invitations at this time.'
    },
    NOTIFICATIONS: {
      TITLE: 'Notifications',
      SEE_ALL: 'See all your notifications',
      NO_NOTIFICATIONS: 'You do not have unread notifications at this time.'
    },
    SEARCH: {
      PLACEHOLDER: 'Search posts, people or spaces',
      MOBILE: {
        PLACEHOLDER: 'Search'
      }
    },
    ADD: {
      NEW_TRAININGSPACE: 'New Space',
      UPLOAD_MEDIA: 'Upload Media',
      NEW_SEGMENT: 'New List',
      NEW_TEMPLATE: 'New Template',
      NEW_TEAM: 'New Group'
    }
  },
  // Footer
  FOOTER: {
    MENU: {
      TERMS: 'Terms of Service',
      POLICY: 'Privacy Policy',
      FEEDBACK: 'Support',
      CONTACT_US: 'Contact us'
    }
  },
  // Resources
  MODELS: {
    POST: {
      SHARE_ERROR: "Sorry, there was an error while sharing this post. Please, contact support at {{supportEmail}}"
    }
  },
  // Controllers
  CONTROLLERS: {
    POST_SHOW: {
      CANT_REMOVE_POST: "Can't remove post",
      TEAM_CHANNELS: 'Channels',
      GO_TO_TIMELINE_BUTTON: 'Go To Timeline'
    },
    SEGMENT_SPACES_MEMBERS: {
      ADD_ERROR_MESSAGE: "Can't add member to all spaces",
      LOAD_ERROR_MESSAGE: "Can't load Space List"
    },
    SEGMENT_SPACES: {
      REMOVE_SPACE_ERROR_MESSAGE: "Can't remove Space!",
      REMOVE_ERROR_MESSAGE: "Can't delete List!"
    },
    SEGMENT_SPACES_POST: {
      POST_CREATED_SUCCESS_MESSAGE: 'Post was successfully created'
    },
    SEGMENT_EDIT: {
      LOAD_ERROR_MESSAGE: "Can't load List",
      UPDATE_ERROR_MESSAGE: "Can't update List"
    },
    SEGMENTS: {
      LOAD_TS_ERROR_MESSAGE: "Can't load Space Lists"
    },
    SPACE_MEMBERSHIPS_INVITATIONS: {
      DELETE_SPACE_ERROR_MESSAGE: 'Error: Space was not deleted'
    },
    SPACE_MEMBERSHIPS: {
      LEAVE_ERROR_MESSAGE: "Can't leave space. Please try again later.",
      LEAVE_SUCCESS_MESSAGE: "You successfully left the {{sport}} Space",
      REMOVE_MEMBER_ERROR_MESSAGE: "Can't remove member. Please try again later.",
      REMOVE_MEMBER_SUCCESS_MESSAGE: "{{name}} has been successfully removed from the Space {{sport}}",
      MAKE_ATHLETE_ERROR_MESSAGE: "Can't change space athlete. Please try again later.",
      MAKE_ATHLETE_SUCCESS_MESSAGE: "{{name}} has been successfully set as the athlete and owner of this Space. You are no longer the owner of this Space and cannot make further modifications."
    },
    SPACE_TEMPLATE_EDIT: {
      LOAD_ERROR_MESSAGE: "Can't load SpaceTemplate"
    },
    SPACE_TEMPLATES: {
      DELETE_ERROR_MESSAGE: "Can't delete Space Template"
    },
    SPACE_EDIT: {
      UPDATE_SUCCESS_MESSAGE: 'Space was successfully updated!',
      DELETE_ERROR_MESSAGE: 'Error: Space was not deleted',
      LEAVE_SUCCESS_MESSAGE: "You successfully left the {{sport}} Space",
      LEAVE_ERROR_MESSAGE: 'Cannot leave space. Please try again later.'
    },
    SPACE_FEED: {
      DELETE_POST_ERROR_MESSAGE: "Can't delete post"
    },
    SPACE_INVITATION_EDIT: {
      UPDATE_SUCCESS_MESSAGE: 'Invitation was successfully updated and resent!'
    },
    SPACE_NEW: {
      CREATE_SUCCESS_MESSAGE: 'Space was successfully created.'
    },
    TAGS: {
      LOAD_ERROR_MESSAGE: "Can't load Tag"
    },
    TAGS_NEW: {
      DELETE_ERROR_MESSAGE: "Can't add tag"
    },
    TAGS_EDIT: {
      UPDATE_ERROR_MESSAGE: "Can't update tag"
    },
    TAGS_DELETE: {
      DELETE_ERROR_MESSAGE: "Can't remove tag"
    },
    SPACE_TEMPLATE_POSTS: {
      SPACE_TEMPLATES_DELETE_ERROR_MESSAGE: "Can't delete SpaceTemplates",
      POST_DELETE_ERROR_MESSAGE: "Can't remove post"
    },
    SPACE_TEMPLATE_POSTS_REORDER: {
      UPDATE_ERROR_MESSAGE: "Can't update posts order"
    },
    SPACE_TEMPLATE_MEMBERS: {
      ADD_MEMBER_SUCCESS_MESSAGE: 'User was added',
      REMOVE_MEMBER_SUCCESS_MESSAGE: 'User was removed'
    },
    ACCOUNT_BILLING_INFO: {
      UPDATE_SUCCESS_MESSAGE: 'Your billing info was successfully changed.',
      UPDATE_FAILURE_MESSAGE: 'Your billing info could not be updated, please verify your information and try again.'
    },
    STRIPE_BILLING_INFO_EDIT: {
      NAME_REQUIRED_ERROR: 'Please enter the name that appears on the card.',
      NUMBER_PLACEHOLDER: 'Credit Card Number',
      NUMBER_REQUIRED_ERROR: 'Please entered a credit card number.',
      NUMBER_INVALID_ERROR: 'The card number entered is not valid.',
      EXPIRY_PLACEHOLDER: 'MM / YY',
      EXPIRY_REQUIRED_ERROR: 'Please enter expiration date that appears on your card.',
      EXPIRY_INVALID_ERROR: 'The date entered is not valid or expired.',
      CVC_PLACEHOLDER: 'Security Code',
      CVC_REQUIRED_ERROR: 'Please enter your credit card security code.',
      CVC_INVALID_ERROR: 'The security code is not valid.',
      ZIP_CODE_REQUIRED_ERROR: 'Please enter your billing zip code.'
    },
    ACCOUNT_EDIT: {
      UPDATE_SUCCESS_MESSAGE: 'Your account was successfully updated.'
    },
    ACCOUNT_PASSWORD: {
      UPDATE_SUCCESS_MESSAGE: 'Your password was successfully changed.',
      CURRENT_PASSWORD_REQUIRED: 'Please enter your current password.',
      PASSWORD_REQUIRED: 'Please enter your new password.',
      PASSWORD_CONF_REQUIRED: 'Please confirm your new password.',
      PASSWORD_MISMATCH_ERROR: 'Passwords do not match'
    },
    ACCOUNT_SETTINGS: {
      UPDATE_SUCCESS_MESSAGE: 'Settings successfully saved',
      UPDATE_ERROR_MESSAGE: 'Due to server error we were not able to save your changes'
    },
    FEED: {
      POST_REMOVE_DEFAULT_ERROR_MESSAGE: 'Unable to remove post'
    },
    SPACE_INVITATIONS: {
      CREATE_SUCCESS_MESSAGE: 'Invitation was successfully created.'
    },
    RESOURCE_NEW: {
      ADD_SUCCESS_MESSAGE: 'File was added!',
      ADD_ERROR_MESSAGE: 'File was not uploaded due to server error.'
    },
    RESOURCE_EDIT: {
      SAVE_SUCCESS_MESSAGE: 'File was successfully changed!',
      SAVE_ERROR_MESSAGE: 'File was not updated due to server error.',
      DELETE_SUCCESS_MESSAGE: 'File was successfully deleted!',
      DELETE_ERROR_MESSAGE: 'File was not deleted due to server error.'
    },
    REPLIES: {
      DELETE_ERROR_MESSAGE: "Can't remove reply",
      REPORT_ERROR_MESSAGE: "There was an error reporting the reply."
    },
    POST_CREATOR: {
      PUBLISH_ERROR_MESSAGE: 'Post was not published!'
    },
    INVITATION: {
      UPDATE_ERROR_MESSAGE: 'Cannot update this record.',
      RESEND_SUCCESS_MESSAGE: 'Successfully resent this invitation.',
      VISIT_MESSAGE_FOR_SPACE: 'Accepted! Click to visit the Space.',
      VISIT_MESSAGE_FOR_TEAM: 'Accepted! Click to visit the Group.',
      DELETE_SPACE_ERROR_MESSAGE: 'Error: Space was not deleted',
      SPACE_NOT_FOUND_MESSAGE: 'Space not found',
      REMOVE_INVITATION_ERROR_MESSAGE: 'Cannot delete this invitation.'
    },
    INVITATIONS: {
      INCOMING: 'Incoming',
      OUTGOING: 'Outgoing',
      INVITE_SENT_SUCCESS_MESSAGE: "Invitation was successfully resent to {{email}}!",
      INVITE_RESEND_ERROR_MESSAGE: "Invitation has been already sent. Please try again later"
    }
  },
  // Directives
  DIRECTIVES: {
    ACCOUNT: {
      PHONE_VERIFICATION_LINK_MODAL: {
        HEADER: "Verify Phone Number",
        PHONE_NUMBER: "Phone Number",
        PHONE_NUMBER_ERROR: "Please provide a valid phone number",
        CONFIRMATION_SENT_TEXT: "We sent confirmation code to <br/> <strong>{phoneNormalized}</strong>",
        CODE: "Confirmation Code",
        CODE_ERROR: "Verification Code is invalid or expired",
        VERIFICATION_ERROR: "The number <strong>{phoneNormalized}</strong> is being used by another CoachNow account, please try a different number.",
        SUCCESS_MESSAGE: "Phone Number was successfully verified!",
        SEND_CODE: "Send Confirmation Code",
        CONFIRM_CODE: "Confirm Code",
        TRY_ANOTHER_NUMBER: "Try another Phone Number",
        DONE: "Done"
      }
    },
    SIMPLE_TYPEAHEAD: {
      EMPTY: 'There are no results to display.'
    },
    USER_TYPEAHEAD: {
      DEFAULT_EMPTY_BUTTON_TEXT: 'Add a New User'
    },
    APP_PROMT: {
      NOTE_1: "You have successfully joined {spaceFullName} Space!",
      NOTE_2: 'Welcome to {appName}!',
      NOTE_3: 'Go Mobile and access {appName} on your iPad, iPhone or Android device.'
    },
    AUDIO_PLAYER: {
      NOTE: 'This audio is currently being processed. It will be available shortly.'
    },
    UPLOAD_DIALOG: {
      TITLE: 'Select Media',
      CANCEL: 'Cancel',
      INVALID_FILE_TYPE_ERROR: 'This file type is not supported.',
      DROPZONE: {
        DRAG_HERE: 'Drag Media Here',
        UPLOAD_MEDIA: 'Upload Media',
        OR: 'Or you can...',
        SELECT_MEDIA: 'Select Media to Upload'
      },
      PROGRESS: {
        LOADING: 'Loading...',
        ERROR: 'Upload failed',
        COMPLETE: 'Upload complete'
      }
    },
    NAME_WITH_EMAIL_IMPUT: {
      EMAIL_PLACEHOLDER: 'Email (required)'
    },
    POST_DETAILS: {
      UPDATE: 'Update',
      UPDATE_ERROR_MESSAGE: "Can't update post details"
    },
    POST_LIKE: {
      LIKE: 'Like'
    },
    POST_TAGS: {
      EDIT_TAGS: 'Edit tags',
      UPDATE: 'Update',
      UPDATE_ERROR_MESSAGE: "Can't update post tags"
    },
    POSTING_FORM: {
      CANT_CREATE_REPLY: "Can't create reply",
      CREATE: 'Create',
      SCHEDULE: 'Schedule',
      LOAD_ERROR_MESSAGE: "Can't load post",
      RESOURCE_LOAD_ERROR_MESSAGE: "Can't load resource",
      TAGS_LOAD_ERROR_MESSAGE: "Can't load tags",
      CANT_REPLY_PLACEHOLDER: 'Only group managers can reply',
      CREATE_POST_PLACEHOLDER: 'Create post...',
      CREATE_REPLY_PLACEHOLDER: 'Say something...',
      TAG_PLACEHOLDER: 'Add some tags.',
      ERRORS: {
        NO_DETAILS: "Wouldn't you like to say something?",
        NO_LOCATIONS: "Please select a location",
        NO_SCHEDULED_AT: "Please select a schedule date",
        SCHEDULED_AT_INVALID: "Please select a future schedule date"
      }
    },
    TAG_IT: {
      DEFAULT_PLACEHOLDER: 'Select tags'
    },
    TEAM_INVITATION: {
      ACCEPT: 'Accept',
      DECLINE: 'Decline',
      GO_TO: "Go to the {teamName} Group page"
    },
    USER_SELECTOR: {
      NOT_FOUND: 'Nothing found...',
      YOU_CAN: 'You can',
      ADD_NEW_USER_DOWNCASE: 'add new user',
      ADD_NEW_USER: 'Add new user',
      NEW_USER: 'New user:',
      EMAIL: 'Email',
      NAME: 'Name',
      ADD: 'Add'
    },
    VIDEO_PLAYER: {
      PROCESSING: 'This video is currently being processed. It will be available shortly.'
    }
  },
  SERVICES: {
    INVITATION_PRESENTER: {
      RESEND_ERRORS: {
        TIME_THRESHOLD_VIOLATED: 'You can only send up to 1 invitation reminder to a person every 24 hours!',
        GENERAL_ERROR: 'Cannot resend this invitation, please try again later.'
      }
    }
  },
  // Modals
  MODALS: {
    CLOSE: 'Close',
    ATHLETE_INVITATION_DELETE: {
      HEADER: "You can't delete athlete invitations",
      NOTE: 'You can edit the invitation or delete this Space. Deleting the Space will delete the invitations and content attached to the Space.',
      EDIT: 'Edit Invitation',
      DELETE: 'Delete Space'
    },
    CANCEL_COACH_PLUS: {
      HEADER: '{{basePlanName}} Subscription',
      APP_STORE_INFO: 'Your {{basePlanName}} subscription is managed by the <b>App Store</b>. Learn more about how to manage your subscriptions <a href="https://support.apple.com/en-us/HT202039" target="_blank">here</a>.',
      PLAY_STORE_INFO: 'Your {{basePlanName}} subscription is managed by the <b>Play Store</b>. Learn more about how to manage your subscriptions <a href="https://support.google.com/googleplay/answer/7018481" target="_blank">here</a>.',
      CONTACT_SUPPORT: 'If you have any questions or concerns <a class="intercom-help-trigger" href="#">contact support</a> or email us at <a href="mailto:{{supportEmail}}">{{supportEmail}}</a>.',
      DEFAULT_INFO: 'To cancel your subscription please <a class="intercom-help-trigger" href="#">contact support</a> or email us at <a href="mailto:{{supportEmail}}">{{supportEmail}}</a>.',
      RENEWAL_UPDATE_STATUS: 'Unable to update the renewal status for {plane}. Please try again.'
    },
    FAILED_INVITATION: {
      HEADER: 'Failed to send SMS invitation',
      NOTE: 'SMS message was not delivered. Please, check the phone number you sent this invitation to. If there is a mistake in phone number — update invitation with correct phone number or remove failed invitation and try to re-invite a member.'
    },
    PAYMENT_REQUIRED: {
      HEADER: 'Oops, something went wrong',
      NOTE: "Please, <a class='intercom-help-trigger' href='#'>contact support</a> or email us at <a mailto='{{supportEmail}}'>{{supportEmail}}</a>"
    },
    POST_REPORT: {
      HEADER: "Why don't you want to see this?",
      REPORT: 'Report'
    },
    UPGRADE_OPTIONS: {
      HEADER: "Try CoachNow+",
      BODY: {
        TITLE: "Get the Most Out of Your Group",
        TEXT: "Posting notes is always free, but if you want everyone on your Group to be able to share videos, photos, and more, try <a href='https://coachnow.io/pricing/'>CoachNow+</a> free for 7 days!",
        TRIAL_BUTTON: "Start Trial",
        UPGRADE_BUTTON: "Upgrade Now"
      },
      FOOTER: {
        TITLE: "No Thanks",
        TEXT: "Stick to basic text communications for now.",
        BUTTON: "Continue for Free"
      }
    }
  },
  PAYWALLS: {
    BODY: {
      PRO: {
        TITLE: '',
        BODY: 'Upgrade to <a href="https://coachnow.io/pro" target="_blank">CoachNow PRO</a> to access all new features, including Post Scheduling, Advanced Group Controls, and access to the ConnectedCoach Blueprint.'
      },
      LIBRARY: {
        TITLE: 'Library Not Available',
        BODY: 'Upgrade to <a href="https://coachnow.io/pricing/" target="_blank">CoachNow+</a> to store and reuse your go-to videos, photos, and more!'
      },
      POST_MEDIA: {
        TITLE: 'Media Posting Not Enabled for This Group',
        BODY: 'Upgrade to <a href="https://coachnow.io/pricing/" target="_blank">CoachNow+</a> to post videos, photos, and more to Groups you own!'
      },
      POST_MEDIA_OTHER_OWNER: {
        TITLE: 'Media Posting Not Enabled for This Group',
        BODY: 'Your coach or Group owner must upgrade to <a href="https://coachnow.io/pricing/" target="_blank">CoachNow+</a> for everyone to post media.'
      },
      SEGMENTS: {
        TITLE: 'Lists Not Enabled',
        BODY: 'Upgrade to <a href="https://coachnow.io/pricing/" target="_blank">CoachNow+</a> to post or invite members to multiple Spaces and Groups at once!'
      },
      SPACE_LIMIT: {
        TITLE: 'Free Space Limit Reached',
        BODY: 'Upgrade to <a href="https://coachnow.io/pricing/" target="_blank">CoachNow+</a> for unlimited Spaces!'
      },
      SPACE_LIMIT_READ_ONLY: {
        TITLE: 'This Space is Read Only',
        BODY: 'You have exceeded your free space limit. Upgrade to <a href="https://coachnow.io/pricing/" target="_blank">CoachNow+</a> to post and reply in all your Spaces!'
      },
      TEMPLATES: {
        TITLE: 'Templates Not Enabled',
        BODY: 'Upgrade to <a href="https://coachnow.io/pricing/" target="_blank">CoachNow+</a> to create reusable series of posts for easy Space setup!'
      },
      PINNED_POSTS: {
        TITLE: 'Posts Pinning Not Enabled',
        BODY: 'Upgrade to <a href="https://coachnow.io/pricing/" target="_blank">CoachNow+</a> to pin posts in Spaces and Groups.'
      }
    },
    HEADER: {
      DEFAULT: {
        TITLE: 'Upgrade to CoachNow+'
      },
      PRO: {
        TITLE: 'Upgrade to CoachNow PRO'
      },
      LIBRARY: {
        TITLE: 'Upgrade to Add Media'
      },
      POST_MEDIA: {
        TITLE: 'Upgrade to Post Media'
      },
      POST_MEDIA_OTHER_OWNER: {
        TITLE: 'Media Posting Disabled'
      },
      SEGMENTS: {
        TITLE: 'Upgrade to Create a List'
      },
      SPACE_LIMIT: {
        TITLE: 'Upgrade for More Spaces'
      },
      SPACE_LIMIT_READ_ONLY: {
        TITLE: 'Upgrade to Post'
      },
      TEMPLATES: {
        TITLE: 'Upgrade to Create a Template'
      },
      PINNED_POSTS: {
        TITLE: 'Upgrade to Pin Posts'
      }
    },
    FOOTER: {
      CLOSE_BUTTON: 'Close',
      TRIAL: {
        BODY: 'Check out all the features CoachNow+ has to offer, with a free 7-day trial.',
        BUTTON: 'Start Trial',
        TITLE: 'Try It Out!'
      },
      PRO: {
        BODY: 'CoachNow PRO gives you the functionality you need to leverage automation, enhanced analytics, and educational curriculum not available anywhere else.',
      },
      UPGRADE: {
        BODY: 'Transform your coaching with unlimited Spaces, media posting, Library storage, Lists, and Templates.',
        BUTTON: 'Upgrade Now',
        TITLE: 'Why Upgrade?'
      }
    },
    TRIAL_STARTED: 'Your free trial has been started!'
  },
  // Views
  ACCOUNT: {
    WRAP: {
      SETTINGS: 'Profile',
      PASSWORD: 'Password',
      NOTIFICATIONS: 'Notifications',
      BILLING: 'Billing',
      SUBSCRIPTIONS: 'Subscriptions',
      CONNECTIONS: 'Connections',
      GET_PAID: 'Get Paid',
      BENEFITS: 'Benefits',
      TAGS: 'Tags',
    },
    HEADER: 'Settings',
    BILLING_INFO_EDIT: {
      HEADER: 'Update Credit Card',
      MONTHS: {
        JAN: '1 - January',
        FEB: '2 - February',
        MAR: '3 - March',
        APR: '4 - April',
        MAY: '5 - May',
        JUN: '6 - June',
        JUL: '7 - July',
        AUG: '8 - August',
        SEP: '9 - September',
        OCT: '10 - October',
        NOV: '11 - November',
        DEC: '12 - December'
      },
      BUTTONS: {
        SAVE_CHANGES: 'Save changes'
      }
    },
    BILLING_INFO: {
      CREDIT_CARD_ON_FILE: 'Credit Card on file',
      UPDATE_CREDIT_CARD: 'Update Credit Card',
      FIRST_NAME: 'First Name',
      LAST_NAME: 'Last Name',
      CREDIT_CARD: 'Credit Card',
      VALID_TILL: 'Valid Till',
      ADDRESS: 'Address'
    },
    EDIT: {
      UPLOAD_BUTTON: 'Upload New Avatar',
      PHOTO: 'Photo',
      REMOVE_PHOTO: 'Remove Photo',
      FIRST_NAME: 'First Name',
      LAST_NAME: 'Last Name',
      GENDER: 'Gender',
      EMAIL: 'Email',
      PHONE: 'Phone',
      TRACKING_OPT_IN: 'Allow analytics data tracking',
      KIND_LABEL: 'What role best decribes you?',
      KIND_TITLE_LABEL: 'What do you like to be called?',
      KIND_TITLE_PLACEHOLDER: 'A Friendly Name or Title (Optional)',
      SPORT_LABEL: 'What are you using CoachNow for?',
      TIMEZONE: 'Timezone',
      SUBSCRIPTIONS: 'Subscriptions',
      WEEKLY_INVITATIONS_REMINDER_HEADER: 'Pending invitations reminder',
      WEEKLY_INVITATIONS_REMINDER: 'I want to receive weekly pending invitations reminder',
      DEFAULT_NOTIFICATIONS_SETTINGS: 'Default notifications settings',
      DEFAULT_EMAIL_NOTIFICATIONS_ENABLED: "Receive email notifications",
      DEFAULT_PUSH_NOTIFICATIONS_ENABLED: "Receive push notifications",
      DAILY_DIGESTS_HEADER: 'Daily digest emails',
      RECEIVE_DAILY_DIGESTS: 'I want to receive daily digest emails',
      DAILY_DIGESTS_DELIVERY_TIME: 'What time of the day you want daily digest to be delivered to you?',
      WEEKLY_DIGESTS_HEADER: 'Weekly digest emails',
      RECEIVE_WEEKLY_DIGESTS: 'I want to receive weekly digest emails',
      SAVE: 'Save settings',
      DIGEST_DELIVERY_SETTINGS: {
        MORNING: 'Morning (around 8am)',
        AFTERNOON: 'Afternoon (around 2pm)',
        EVENING: 'Evening (around 6pm)'
      },
      PLEASE_SELECT: 'Please Select...',
      BUTTONS: {
        SAVE_CHANGES: 'Save changes'
      }
    },
    SUBSCRIPTIONS: {
      HEADER: 'Account Settings',
      COACHNOW_PLUS: 'CoachNow+',
      RENEWS_ON: 'Renews on: {{date | date : shortDate }}',
      EXPIRES_ON: 'Expires on: {{date | date : shortDate }}',
      EXPIRED_ON: 'Expired on: {{date | date : shortDate }}',
      AUTO_RENEW: 'Auto-renew',
      UPGRADE: 'Upgrade',
      REACTIVATE: 'Reactivate',
      UPDATE_SUCCESS_MESSAGE: "Your '{{name}}' subscription has been successfully updated.",
      UPDATE_ERROR_MESSAGE: "Your '{{name}}' subscription failed to update. Please try again.",
      BUTTONS: {
        CONFIRM_MESSAGE: 'Are you sure you want to cancel this subscription?',
        START_TRIAL: 'Start Free Trial',
        UPGRADE_NOW: 'Upgrade Now'
      }
    },
    PASSWORD: {
      CURRENT_PASSWORD: 'Current Password',
      NEW_PASSWORD: 'New Password',
      NEW_PASSWORD_CONFIRMATION: 'New Password Confirmation',
      BUTTONS: {
        SAVE_CHANGES: 'Save changes'
      }
    },
    CREATION: {
      INCOMPLETE: 'Please choose your role and sport!',
      PHOTO_UPLOADING: 'Please wait until photo upload is finished.',
      FAILED: "Unable to create account. Please contact support: \n{{supportEmail}}."
    }
  },
  CONTACTS: {
    IMPORT: {
      HEADER: 'Select contacts',
      DESCRIPTION: "We found {contactsCount} {contactsCount, plural, =0 {contacts} =1 {contact} other {contacts}} in your address book. Select the {contactsCount, plural, =0 {contacts} =1 {contact} other {contacts}} you'd like to create Spaces for.",
      SELECT_ALL: 'Select all',
      BUTTONS: {
        CONTINUE: 'Continue'
      }
    },
    PROVIDERS: {
      HEADER: 'Search your address book for athletes',
      DESCRIPTION: 'Choosing a service will open a window for you to log in securely and import your contacts to {{appName}}.'
    }
  },
  INVITATIONS: {
    MESSAGES: {
      INCOMING: {
        FOR_TEAM: "You have been invited to join the \"{teamName}\" Group",
        FOR_ATHLETE: "{inviterName} created an {appName} \"{spaceName}\" Space for you",
        FROM_ATHLETE: "{inviterName} invited you to join their \"{spaceName}\" Space as a {role}",
        FROM_MEMBER_WITH_ATHLETE: "{inviterName} invited you to join {spaceAthleteName}'s \"{spaceName}\" Space as a {role}",
        FROM_MEMBER_WITHOUT_ATHLETE: "{inviterName} invited you to join \"{spaceName}\" Space as a {role}",
        SHORT: {
          FROM_ATHLETE: "{inviterName} invited you to join their \"{spaceName}\" Space",
          FROM_MEMBER_WITH_ATHLETE: "{inviterName} invited you to join {spaceAthleteName}'s \"{spaceName}\" Space",
          FROM_MEMBER_WITHOUT_ATHLETE: "{inviterName} invited you to join \"{spaceName}\" Space"
        }
      },
      OUTGOING: {
        FOR_TEAM: "You invited {inviteeName} to join the \"{teamName}\" Group",
        FOR_ATHLETE: "You created an {appName} \"{spaceName}\" Space for {inviteeName}",
        FROM_ATHLETE: "You invited {inviteeName} to join your \"{spaceName}\" Space as a {role}",
        FROM_MEMBER_WITH_ATHLETE: "You invited {inviteeName} to join {spaceAthleteName}'s \"{spaceName}\" Space as a {role}",
        FROM_MEMBER_WITHOUT_ATHLETE: "You invited {inviteeName} to join \"{spaceName}\" Space as a {role}"
      }
    },
    EDIT_OUTGOING: {
      SPACE: 'Space',
      TEAM: 'Group',
      NAME: 'Name',
      EMAIL: 'Email',
      PHONE: 'Phone',
      ROLE: 'Role',
      PLACEHOLDERS: {
        NAME: 'Invitee Name',
        EMAIL: 'Invitee Email',
        PHONE: 'Invitee Phone'
      },
      BUTTONS: {
        UPDATE: 'Update'
      }
    },
    INCOMING: {
      DECLINED: 'Declined',
      ACCEPT: 'Accept',
      DECLINE: 'Decline'
    },
    LIST: {
      HEADER: 'Invitations',
      NO_INVITATIONS: "No {direction} invitations found."
    },
    OUTGOING: {
      ACCEPTED: 'Accepted',
      DECLINED: 'Declined',
      PENDING: 'Pending',
      EDIT: 'Edit',
      RESEND: 'Resend',
      DELETE: 'Delete',
      DELETE_CONFIRM_MESSAGE: 'Are you sure you want to delete this invitation?'
    },
    SPACE_INVITATION: {
      ACCEPT: 'Accept',
      DECLINE: 'Decline',
      GO_TO: 'Go to the Space page'
    }
  },
  INVITATION_MODALS: {
    SPACE_CREATED_COPY_LINK_MODAL: {
      TITLE: "Copy invitation link",
      COPY_LINK: "Copy invitation link",
      BODY: 'Space created! Would you like to send invitation link to {{inviteeName}} now?',
      HELPER_TEXT: 'You can also send invitation later from the Space members screen.',
      SEND_LATER: 'I will send the invite later'
    },
    COPY_LINK_MODAL: {
      TITLE: "Copy invitation link",
      COPY_LINK: "Copy link",
      DONE_BUTTON: "Done",
      COPY_SUCCESS: "Phone invitation link was copied to your clipboard!",
      COPY_ERROR: "We were unable to copy the Phone invitation link to your clipboard. Please try again."
    }
  },
  ORGANIZATIONS: {
    LIST: {
      HEADER: 'Organizations',
      UPDATE_BILLING_INFO: 'Update Billing Info'
    }
  },
  POSTS: {
    POST_INNER: {
      EDIT_TAGS: 'Tags',
      IN: 'in',
      TS_PLURAL: "{spacesCount} Spaces",
      BULK_IN_PROGRESS: 'Your post is being published.',
      TAG_PLACEHOLDER: 'Tags',
      ALL_CHANNELS: 'General',
      TEAM_CHANNEL_LOCATION: 'In {{team}} / {{channel}}',
      TEAM_LOCATION: 'In {{team}} / General',
      SPACE_LOCATION: 'In {{space}} / {{sport}}',
      BULK_LOCATION: 'In {count, plural, =1 {# Location} other {# Locations}}'
    },
    TOGGLE_PIN_NOT_ALLOWED_MODAL: {
      PIN_POST_HEADER: 'Pin this post',
      UNPIN_POST_HEADER: 'Unpin this post',
      PIN_POST_BODY: 'Sorry, you are not allowed to pin this post.',
      UNPIN_POST_BODY: 'Sorry, you are not allowed to unpin this post.',
      OK_BUTTON: 'OK'
    },
    TOGGLE_PIN_MODAL: {
      PIN_HEADER: 'Pin this post?',
      UNPIN_HEADER: 'Unpin from top?',
      CANCEL_BUTTON: 'Cancel',
      PIN_BUTTON: 'Pin',
      UNPIN_BUTTON: 'Unpin',
      PIN_TILL_BUTTON: 'Pin till...',
      PIN_TILL_BUTTON_WITH_DATE: 'Pin till {{ pinnedTill | date : shortDate }}',
      PIN_BODY: 'This post will be pinned to the top for everyone in this {{target}}.',
      UNPIN_BODY: 'This post will be unpinned from top for everyone in this {{target}}.',
      SELECT_DATE: 'Select Date'
    },
    SCHEDULED_POST_MODAL: {
      HEADER: "Post Created",
      BODY: "You added a new post to the {location} schedule!<br/>Other members will see it at",
      DATE: "<strong>{scheduledDate}</strong> at <strong>{scheduledTime}</strong>",
      PUBLISH_AT: "<strong>{scheduledDate}</strong> publish at <strong>{scheduledTime}</strong>",
      LOCATION_SCHEDULE_BUTTON: "Go to Schedule",
      OK_BUTTON: "Ok"
    }
  },
  REPLIES: {
    MORE_REPLIES: 'Show previous replies'
  },
  RESOURCES: {
    EDIT: {
      HEADER: 'Edit Media',
      DELETE: 'Delete',
      DELETE_CONFIRM: 'Are you sure you want to delete this media file?',
      TAGS: 'Tags',
      TAGS_PLACEHOLDER: 'Add some tags.',
      SAVE_CHANGES: 'Save changes',
      CREATE_POST: "Create post <span class='hidden-phone'>with media</span>"
    },
    LIST: {
      HEADER: 'Library',
      ADD_MEDIA: '+ Add Media',
      NO_MEDIA: 'No Library Items Yet',
      NO_MEDIA_SUMMARY: 'Easily collect and store all your instructional media.',
      NOT_FOUND: 'No results found',
      NOT_FOUND_SUMMARY: 'Try updating your filters.',
      FILTER: {
        TITLE: 'Filter',
        ALL_TYPES: 'All types',
        TYPE: 'Type',
        PICK_A_TAG: 'Pick a Tag',
        TAGS: 'Tags',
        DATE_LABEL: 'Jump To',
        FILTER_BUTTON: 'Filter',
        SHOW_ALL: 'Show all'
      }
    },
    NEW: {
      HEADER: 'Upload Media',
      MEDIA: 'Media',
      TAGS: 'Tags',
      TAG_PLACEHOLDER: 'Add some tags.',
      UPLOAD_BUTTON: 'Upload',
      ADD: 'Add'
    },
    POST: {
      HEADER: 'Create post with media',
    }
  },
  SHARED: {
    UNLOCK_WITH_COACHPLUS: 'Unlock with {{basePlanName}}',
    ACCOUNT_MENU: {
      ACCOUNT_SETTINGS: 'Account Settings',
      EMAIL_SUBSCRIPTIONS: 'Email Subscriptions',
      CHANGE_PASSWORD: 'Change Password',
      BILLING_INFO: 'Billing Info',
      SUBSCRIPTIONS: 'Subscriptions',
      UPGRADE: 'Upgrade to {{basePlanName}}',
      ORGANIZATIONS: 'Organizations',
      SIGNOUT: 'Sign Out',
      CONNECTIONS: 'Connections',
      TAGS: 'Tags'
    },
    TEAMS_MENU: {
      AD_TS: '+ Add Space',
      SETTINGS: 'Settings',
      DELETE: 'Delete',
      LEAVE_TEAM: 'Leave Group',
      SPACES: 'Spaces',
      MEMBERS: 'Members',
      DELETE_CONFIRM: 'Are you sure you want to delete this group?',
      LEAVE_CONFIRM: 'Are you sure you want to leave this group?'
    },
    YOU: 'You'
  },
  SEGMENT_SPACES: {
    TEMPLATE: {
      NO_POSTS: 'No Posts Yet',
    },
    EDIT: {
      SEGMENTS: 'Lists',
      TS_PLURAL: "{spacesCount, plural, =1 {Space} other {Spaces}}",
      SELECT_TS: "Select {name}'s Spaces",
      NO_TS: "You don't have any Space yet",
      ALREADY_IN_OTHER_SEGMENTS: 'Already in other lists',
      UPDATE: 'Update',
      SELECT_ALL: 'Select All'
    },
    LIST: {
      SEGMENTS: 'Lists',
      MANAGE_TS: 'Manage Spaces',
      POST_INTO_SEGMENT: 'Post into List',
      EDIT_NAME: 'Edit Name',
      DELETE_CONFIRM: 'Are you sure you want to delete this list?',
      DELETE: 'Remove',
      NO_TS_IN_SEGMENT: 'There are no Spaces in this List',
      ADD_TS_TO_SEGMENT: 'Add Spaces to this List',
      INVITE_MEMBERS: 'Invite Coaching Staff to all Spaces',
      SPACE_REMOVE_CONFIRM: 'Are you sure you want to remove this space from list?'
    },
    POST: {
      HEADER: "Create Post for '{segmentName}' List",
      WARNING: 'Warning',
      NOTE_1: "You don't have spaces in this list. <a href='/lists/{segmentId}/spaces/edit'>Add Spaces</a> into the list before create a post."
    }
  },
  SEGMENTS: {
    SEGMENTS_LIST: {
      NONE_FOUND: 'No Lists to Display',
      SEARCH_PLACEHOLDER: 'Search Your Lists',
      ONE_SPACE: '1 Space',
      MULTIPLE_SPACES_FORMAT: '{{count}} Spaces'
    },
    ADD_MEMBERS: {
      INVITE_MEMBER: 'Invite member to all Spaces in the List',
      USER: 'User',
      ROLE: 'Role',
      COACH: 'Coach',
      FAN: 'Member',
      ATHLETE: 'Athlete',
      ADD: 'Add'
    },
    EDIT: {
      HEADER: 'Edit List',
      NAME: 'Name',
      UPDATE: 'Update'
    },
    LIST: {
      HEADER: 'Lists',
      NEW_SEGMENT: "+ New List",
      NO_TS: "No Lists yet",
      NO_TS_SUMMARY: "All lists created by you will appear here",
      ADD_SEGMENT: 'Add Space List',
      EDIT_NAME: 'Edit Name',
      MANAGE_TS: 'Manage Spaces',
      POST_INTO_SEGMENT: 'Post into List',
      INVITE_MEMBERS: 'Invite Coaching Staff to all Spaces',
      DELETE_CONFIRM: 'Are you sure you want to delete this list?',
      DELETE: 'Delete'
    },
    NEW: {
      HEADER: 'New List',
      SEGMENT_NAME: 'List Name',
      NAME_REQUIRED_ERROR: 'Name Required',
      SUBMIT_BUTTON: 'Create',
      CANCEL_BUTTON: 'Cancel'
    }
  },
  SPACE_MEMBERSHIPS: {
    INVITATIONS_LIST: {
      HEADER: 'Invitations',
      FAILED: 'failed',
      EDIT: 'Edit',
      RESEND: 'Re-Send',
      COPY_LINK: 'Copy link',
      DELETE: 'Delete',
      DELETE_CONFIRM: 'Are you sure?',
      LOAD_MORE: 'Load more...',
      NOTE: 'To add more Members to this Space you must add them to the&nbsp;<a href="/groups/{teamId}/members"><b>{teamName}</b></a> Group. Important — only Group Managers can add new Members.',
      DECLINED: 'Declined'
    },
    LIST: {
      FIND_BY_NAME: 'Find by Name',
      ENTER_MEMBER_NAME: 'Enter member name',
      NO_MEMBERS: 'There are no members in this Space.',
      NOT_FOUND: 'Nothing Found',
      MEMBERS: 'Members',
      ATHLETE: 'Athlete',
      COACH: 'Coach',
      TEAM_OWNER: 'Group owner',
      RECRUITER: 'Recruiter',
      CHANGE_ATHLETE_NOTE: 'Changing athlete changes the current Space owner and athlete. Please confirm your action.',
      MAKE_ATHLETE: 'Make Athlete',
      DELETE_CONFIRM: 'Are you sure?',
      REMOVE: 'Remove',
      LEAVE_CONFIRM: 'Are you sure you want to leave this Space?',
      LEAVE: 'Leave',
      LOAD_MORE: 'Load more...'
    },
    NEW: {
      HEADER: 'Invite New Member',
      COACH: 'Coach',
      FAN: 'Member',
      INVITE_MEMBER: 'Invite member'
    }
  },
  SPACE_TEMPLATE_MEMBERS: {
    LIST: {
      TEMPLATES: 'Templates',
      TEMPLATE_MEMBERS: 'Template Members',
      ADD_NEW_MEMBER: 'Add new member:',
      NO_MEMBERS: 'There are no members in this template'
    }
  },
  SPACE_TEMPLATE_POSTS: {
    LIST: {
      TEMPLATES: 'Templates',
      EDIT_NAME: 'Edit Name',
      DELETE_CONFIRM: 'Are you sure?',
      DELETE: 'Delete',
      REORDER: 'Reorder',
      NO_POSTS: "You don't have Posts in this Space Template"
    },
    REORDER: {
      TEMPLATES: 'Templates',
      REORDERING: 'Reordering',
      NO_POSTS: "You don't have Posts in this Space Template",
      UPDATE: 'Update'
    }
  },
  SPACE_TEMPLATES: {
    EDIT: {
      HEADER: 'Edit Template',
      TEMPLATE_NAME: 'Template name',
      UPDATE: 'Update'
    },
    LIST: {
      HEADER: 'Templates',
      NEW_TEMPLATE: "+ New Template",
      NO_TEMPLATES: "No Templates Yet",
      NO_TEMPLATES_SUMMARY: "Templates make it easy to create the same content for any number of Spaces or Teams.",
      ADD_TEMPLATE: 'Add Space Template',
      EDIT_NAME: 'Edit Name',
      MANAGE_POSTS: 'Manage Posts',
      MANAGE_MEMBERS: 'Manage Members',
      DELETE_CONFIRM: 'Are you sure?',
      DELETE: 'Delete'
    },
    NEW: {
      HEADER: 'New Template',
      NAME_LABEL: 'Template Name',
      NAME_REQUIRED_ERROR: 'Name is required.',
      CREATE_BUTTON: 'Create Template',
      CANCEL_BUTTON: 'Cancel'
    }
  },
  SPACES: {
    ADD_EXTRA_MEMBER: 'Add a New Member',
    ADD_NEW_ATHLETE: 'Add a New Athlete',
    ADD_NEW_COACH: 'Add a New Coach',
    ADD_NEW_FAN: 'Add a New Parent or Fan',
    ALMOST_DONE: 'You\'re almost finished!',
    ALMOST_DONE_DESCRIPTION_FORMAT: 'You are about to create a new {sport} Space for:',
    ALMOST_DONE_MULTIPLE_DESCRIPTION_FORMAT: 'You are about to create {sport} Spaces for:',
    BACK: 'Back',
    CANCEL: 'Cancel',
    ERRORS: {
      ATHLETE_REQUIRED: 'An athlete is required.',
      SPORT_REQUIRED: 'A sport is required.'
    },
    TRANSFER_OWNERSHIP_MODAL: {
      TITLE: 'Transfer Space Ownership',
      HEADER: 'New Owner',
      CALL_TO_ACTION: 'Enter the email of a Member within the Space to transfer ownership.',
      EMAIL_LABEL: 'New Owner\'s Email',
      SPACE_SPORT_LABEL: 'Space Sport to Confirm',
      INVALID_MEMBER_EMAIL_ERROR: 'Please enter an email of a Member within the Space.',
      SPACE_SPORT_MISMATCH_ERROR: 'Entered sport does not match Space sport.',
      ALREADY_OWNER_EMAIL: 'But you already own this Space.',
      DISCLAIMER: 'By clicking "Confirm and Transfer", you are transferring Space ownership and permissions to this Member. You will still be part of the Space',
      CANCEL_BUTTON: 'Cancel',
      SUBMIT_BUTTON: 'Confirm and Transfer'
    },
    TRANSFER_OWNERSHIP_COMPLETE_MODAL: {
      TITLE: 'Ownership Transferred',
      HEADER: 'New Owner Confirmed',
      CONFIRMATION_TEXT: '{{name}} is now the Owner of this Space.',
      DONE_BUTTON: 'Done'
    },
    INVITE_USER_MODAL: {
      ADD_BUTTON: 'Add Member',
      CANCEL_BUTTON: 'Cancel',
      EMAIL_LABEL: 'Email',
      EMAIL_INVALID_ERROR: 'Email is invalid.',
      EMAIL_REQUIRED_ERROR: 'Email is required.',
      MEMBER_EXISTS_ERROR: 'Member has already been added.',
      NAME_LABEL: 'Name',
      NAME_REQUIRED_ERROR: 'Name is required.',
      TITLE: 'Add New Space Member'
    },
    NEXT: 'Next',
    SEARCH_BY_NAME_OR_EMAIL:'Search by name or email',
    SEGMENTS_HELP_TEXT: 'Add this Space to one or more of your lists for improved communication.',
    SUPPORT_NETWORK: 'You can invite coaches, trainers, parents, fans, or anyone else in the athlete\'s support network.',
    WHO_ELSE_INVITE: 'Who else would you like to invite?',
    EDIT: {
      HEADER: 'Space Settings',
      SPORT: 'Sport',
      SAVE_CHANGES: 'Save changes',
      SEND_INVITATION_REMINDERS: 'Send Invitation Reminders',
      NOTIFICATION_SETTINGS: 'Notifications Settings',
      NOTIFICATION_SETTINGS_NOTE: 'You can set notifications settings for new posts and replies',
      ENABLED: 'enabled',
      DISABLED: 'disabled',
      PUSH_NOTIFICATIONS_ARE: 'Push notifications are',
      DISABLE_PUSH_NOTIFICATIONS: 'Disable push notifications',
      ENABLE_PUSH_NOTIFICATIONS: 'Enable push notifications',
      EMAIL_NOTIFICATIONS_ARE: 'Email notifications are',
      DISABLE_EMAIL_NOTIFICATIONS: 'Disable email notifications',
      ENABLE_EMAIL_NOTIFICATIONS: 'Enable email notifications',
      TS_EMAIL: 'Space Email',
      TS_EMAIL_NOTE: "You can create posts in this Space by sending emails to the address below. Each Space has it's own separate address. Simply cut and paste, then save as new contact information in your e-mail account.",
      TS_EMAIL_NOTE_2: 'You can send emails only from the following email address:',
      TS_EMAIL_NOTE_3: "Each Space has it's own separate address. You can create posts in Space by sending emails to it.",
      TS_EMAIL_NOTE_4: 'After athlete accepts invitation to Space you will see Space address here.',
      DELETE_HEADER: 'Delete the Space',
      DELETE_NOTE: 'Once you delete a Space, there is no going back.',
      DELETE_CONFIRM: 'Are you sure?',
      DELETE: 'Delete This Space',
      LEAVE_HEADER: 'Leave Space',
      LEAVE_NOTE: "Once you leave a Space, you'll lose access to all its content.",
      LEAVE_CONFIRM: 'Are you sure?',
      LEAVE: 'Leave This Space'
    },
    FEED: {
      POSTING_NOTE_1: 'Sorry, only Group Managers are are allowed to post and reply in this Space. You can interact by liking posts though!',
      POSTING_NOTE_2: 'Sorry, only Group Managers are allowed to reply in this Space. You can like posts and create your own!',
      POSTING_NOTE_3: 'Sorry, only Group Managers are allowed to post in this Space. You can reply to posts and like them though!',
      NO_POSTS: 'No Posts Yet',
      NO_POSTS_SUMMARY: 'The feed contains all posts from Spaces and Teams you\'re a part of. Post something now for others to see.',
      NOT_FOUND: 'No results found',
      NOT_FOUND_SUMMARY: 'Try updating your filters.',
      FILTER: {
        TITLE: 'Filter',
        AUTHOR: 'Author',
        SELECT_AN_AUTHOR: 'Select an Author',
        ORDER_BY: 'Order by',
        SELECT_AN_ORDER: 'Select an order',
        TYPE: 'Type',
        SELECT_A_TYPE: 'Select a Type',
        TAG: 'Tag',
        PICK_A_TAG: 'Pick a tag',
        PUBLISHED_FROM: 'Published From',
        PUBLISHED_TILL: 'Published Till',
        SHOW_ALL: 'Show all'
      },
      PLACEHOLDER_NAME: "Space"
    },
    HEADER: {
      NOTIFICATIONS_SETTINGS: 'Notifications Settings',
      TURN_OFF_PUSH_NOTIFICATIONS: 'Turn off push notifications',
      TURN_ON_PUSH_NOTIFICATIONS: 'Turn on push notifications',
      TURN_OFF_EMAIL_NOTIFICATIONS: 'Turn off email notifications',
      TURN_ON_EMAIL_NOTIFICATIONS: 'Turn on email notifications',
      TIMELINE: 'Timeline',
      MEMBERS: 'Members',
      POSTS_PLURAL: "{postsCount, plural, =1 {<span class='space-stat-counter'>#</span>Post} other {<span class='space-stat-counter'>#</span>Posts}}",
      MEMBERS_PLURAL: "{membersCount, plural, =1 {<span class='space-stat-counter'>#</span>Member} other {<span class='space-stat-counter'>#</span>Members}}",
      SETTINGS: 'Settings',
      POPOVER_TITLE: 'Space Email',
      POPOVER_TEXT: "Create posts in this Space by sending emails to this address.<p>Each Space has it's own separate address.</p><p class='text-warning'>For security reasons, you can<br> send emails only from the: <b>{{email}}</b></p>"
    },
    INVITATION_EDIT: {
      EDIT_INVITATION: 'Edit Invitation',
      ROLE: 'Role',
      NAME: 'Name',
      EMAIL: 'Email',
      PHONE: 'Phone',
      SAVE_CHANGES: 'Save changes'
    },
    LIST: {
      HEADER: 'Spaces',
      NEW_TRAINING_SPACE: 'New Space',
      CREATE_ON_TS: 'Create one Space',
      CREATE_MULTIPLE_TS: 'Create multiple Spaces',
      CREATE_TS_USING_ADDRESS_BOOK: 'Create Spaces using Address Book',
      SEARCH_PLACEHOLDER: 'Search Spaces',
      NO_RESULTS: 'No Results',
      NO_RESULTS_SUMMARY: 'Nothing found for "{search}", please search for something else.',
      NO_TS: 'Welcome to Spaces',
      NO_TS_SUMMARY: 'Work one-on-one with a coach or player.'
    },
    NEW_MULTIPLE: {
      HEADER: 'Create Multiple Spaces',
      CREATE_MULTIPLE_TS_NOTE_1: 'All Coaches/Trainers listed will have access to the Spaces created below.',
      CREATE_MULTIPLE_TS_NOTE_2: 'Finally everyone on the same page!',
      REVIEW_ERRORS: 'Please review the problems below:',
      CSV_LABEL: 'Add from CSV',
      CSV_UPLOAD_BUTTON: 'Upload File',
      CSV_INSTRUCTIONS: 'First two columns in the file must be name and email in that order.',
      SPORT: 'What Will These Spaces Be For?',
      SELECT_SPORT: 'Sport, subject, activity, etc.',
      ENTER_SPORT: 'Enter Sport/Subject',
      TEMPLATE: 'Template',
      SELECT_TEMPLATE: 'Select Template',
      COACHES: 'Invite Coaches or Parents (Optional)',
      COACHES_NOTE: 'Will have access to every Space created',
      ATHLETES: 'Who Should Spaces Be Created For?',
      ATHLETES_NOTE_1: 'One Space will be created for each Athlete',
      ATHLETES_NOTE_2: "You are about to create {athletesCount} new {sport} Spaces",
      ADD_TO_SEGMENTS_NOTE_1: 'To keep your Spaces organized you may add this Spaces to one or multiple Lists.',
      SEGMENT: 'Lists',
      ADD_TO_SEGMENTS_NOTE_2: 'You may add these Spaces to one or many Lists',
      CREATE_TS: 'Create Spaces'
    },
    NEW_SINGLE: {
      HEADER: 'Create New Space',
      REVIEW_ERRORS: 'Please review the problems below:',
      WHO_FOR: 'Who is this Space for?',
      FOR_ME: "It's for me, I'm the Athlete",
      FOR_OTHER_ATHLETE: "It's for another Athlete",
      ADD_HELPER_TEXT: 'Add Athlete',
      NAME_REQUIRED: 'Name is required',
      PHONE_NUMBER_REQUIRED: 'Phone Number is required',
      NAME: 'Name',
      PHONE_NUMBER: 'Phone Number',
      ADD: 'Add',
      OR: 'or',
      ADD_BY_PHONE_NUMBER: 'Add by Phone Number',
      ADD_BY_NAME_OR_EMAIL: 'Add by Name or Email',
      NOTE_1: "If you're a Coach, Sports Parent, or Friend create a Space for your Athlete, Child or Buddy. Enter their e-mail not yours. If your child doesn't have an e-mail yet, either set one up (best option) or use your own in the meantime.",
      SPORT: 'Sport',
      SELECT_SPORT: 'Select Sport/Subject',
      ENTER_SPORT: 'Enter Sport/Subject',
      TEMPLATE: 'Template',
      SELECT_TEMPLATE: 'Select Template',
      NOTE_2: 'Who else would you like to invite into this Space? Could be other coaches, trainers, athletes or support network like parents aka “Fans”. Invite them!',
      COACHES: 'Coaches',
      MEMBERS: 'Members',
      NOTE_3: 'Invite friends & family that will keep this athlete going!',
      NOTE_4: 'You are about to create a new ',
      NOTE_5: 'To keep your Spaces organized you may add this Space to one or multiple Lists.',
      SEGMENTS: 'Lists',
      CREATE_TS: 'Create Space'
    },
    NAV: {
      TIMELINE: 'Timeline',
      SCHEDULED_POSTS: 'Schedule',
      MEMBERS: 'Members',
      SETTINGS: 'Settings'
    },
    SETTINGS: {
      TITLE: 'Space Settings',
      NAME_LABEL: 'Name',
      PHONE_LABEL: 'Athlete Phone',
      SPORT_SELECT_LABEL: 'Sport',
      POST_FROM_EMAIL: 'Post from Email',
      POST_EMAIL_INFO: 'Email this address to post to this Space.',
      SEND_INVITATION_REMINDERS: 'Send Invitation Reminders',
      DELETE_BUTTON: 'Delete Space',
      CANCEL_BUTTON: 'Cancel',
      SAVE_BUTTON: 'Save',
      TRANSFER_BUTTON: 'Transfer Space Ownership',
      DELETE_CONFIRMATION: 'Are you sure you would like to delete {{name}}?',
      LEAVE_CONFIRMATION: 'Are you sure you would like to leave {{name}}?',
      DELETED_MESSAGE: 'The space {{name}} has been deleted.',
      LEFT_MESSAGE: 'You have left {{name}}.',
      SAVE_SUCCESS_MESSAGE: "Your settings have been saved.",
      NOTIFICATIONS: {
        TITLE: "Your Notifications",
        PUSH_NOTIFICATIONS: {
          LABEL: 'Push Notifications',
          SUMMARY: 'Get a push for every Space notification.'
        },
        EMAIL: {
          LABEL: 'Email',
          SUMMARY: 'Get an email for every Space notification.'
        }
      },
      COPY_ERROR: 'We were unable to copy the space email to your clipboard. Please try again.',
      COPY_SUCCESS: 'The space email was copied to your clipboard!',
    },
    SETTINGS_MENU: {
      SPACE_SETTINGS_LABEL: 'Space Settings',
      NOTIFICATION_SETTINGS_LABEL: 'Your Notifications',
      LEAVE_SPACE_LABEL: 'Leave Space'
    }
  },
  TAGS: {
    DELETE: {
      HEADER: "Delete Tag \"{name}\"",
      CONFIRM: 'Are you sure you want to delete this tag?',
      DELETE_ALL_OCCURENCES: 'Delete all occurences of this tag in my posts',
      DELETE: 'Delete'
    },
    EDIT: {
      HEADER: 'Edit Tag',
      NAME: 'Name',
      UPDATE_ALL_OCCURENCES: 'Update all occurences of this tag in my posts',
      UPDATE: 'Update'
    },
    LIST: {
      HEADER: 'Tags',
      ADD_TAG: "+ Add Tag",
      NO_TAGS: "No Tags Yet",
      NO_TAGS_SUMMARY: "Tags make it easy to structure and search content in Spaces or Teams.",
      EDIT_NAME: 'Edit Name',
      DELETE: 'Delete'
    },
    NEW: {
      HEADER: 'Add Tag',
      NAME: 'Name',
      CREATE: 'Create'
    }
  },
  TEAM_MEMBERSHIPS: {
    LIST: {
      TEAM_OWNER: 'Group owner',
      TEAM_MANAGER: 'Group manager'
    }
  },
  SEARCH: {
    SEARCH_WITH_DOTS: 'Search...',
    SEARCH_REPLIES: 'Search replies',
    SEARCH: 'Search',
    NOT_FOUND: 'Nothing found'
  },
  // Error pages
  NOT_FOUND: {
    HEADER: '404 – Page not found',
    NOTE_1: "This page doesn't exist or you don't have enough permissions.",
    NOTE_2: "You could go back to <a href='javascript:history.back()'>where you were </a> or head straight to our <a href='/' target='_self'>home page</a>"
  },
  SERVER_ERROR: {
    HEADER: '500 – Internal server error',
    NOTE_1: "Sorry, due to server error we can't load/update some data this page has requested.<br/>Don't worry – we're already working on it.",
    NOTE_2: "You could go back to <a href='javascript:history.back()'>where you were</a> or head straight to our <a href='/' target='_self'>home page</a>"
  },
  FORBIDDEN: {
    HEADER: "403 — Forbidden",
    NOTE_1: "You don't have enough permissions to perform this action.",
    NOTE_2: "You could go back to <a href='javascript:history.back()'>where you were</a> or head straight to our <a href='/' target='_self'>home page</a>"
  },
  UPGRADE: {
    ERROR: {
      HEADER: 'Oops, something went wrong',
      NOTE_1: "Please, <a class='intercom-help-trigger' href='#'>contact support</a> or email us at <a mailto='{{supportEmail}}'>{{supportEmail}}</a>."
    }
  },
  FORM_COMMON: {
    VALIDATION_ERRORS: {
      BLANK: "Can't be blank."
    },
    BACK: 'Back'
  },
  SCHEDULED_POSTS: {
    PANEL: {
      SCHEDULED_FOR_FUTURE: "{postsCount, plural, =1 {1 post} other {# posts}} scheduled for future"
    },
    FEED: {
      HEADER: 'Scheduled Posts',
      PLACEHOLDER_NAME: 'Schedule Post'
    },
    EMPTY_STATE: {
      HEADER: 'No scheduled posts yet',
      BODY: 'Automate your business and keep your athletes engaged with scheduled posts. On this page you can create, manage, and schedule posts to publish automatically in any Space or Group.'
    }
  },
  TEAMS: {
    MEMBER_ROLES: {
      ATHLETE: {
        TITLE: 'Member',
        DESCRIPTION: 'Can post and interact.'
      },
      COACH: {
        TITLE: 'Coach/Admin',
        DESCRIPTION: 'Can post and interact, manage the Group, manage Group members, and manage Channels.'
      },
      FAN: {
        TITLE: 'Parent / Fan',
        DESCRIPTION: 'Can post and interact.'
      },
      VIEWER: {
        TITLE: 'Viewer',
        DESCRIPTION: 'View only.'
      }
    },
    CSV_COLUMN_SELECTOR: {
      TITLES: {
        EMAIL: 'Select Email Column',
        NAME: 'Select Name Column',
        POSITION: 'Select Position Column',
        JERSEY: 'Select Jersey Column',
      },
      CTA: {
        EMAIL: 'Which column from your file is for <b>email addresses?</b> Use the numbers or arrows to switch columns.',
        NAME: 'Which column from your file is for <b>names?</b> Use the numbers or arrows to switch columns.',
        POSITION: 'Which column from your file is for <b>positions?</b> Use the numbers or arrows to switch columns.',
        JERSEY: 'Which column from your file is for <b>jersy numbers?</b> Use the numbers or arrows to switch columns.'
      },
      EMPTY_ROW_PLACEHOLDER: 'Empty',
      SUBMIT_BUTTON: 'Select This Column',
      BACK_BUTTON: 'Back'
    },
    IMPORT_CONFIRM: {
      TITLE: 'Confirm Invites',
      STATUS: 'Almost Done.',
      STATUS_SUMMARY: 'Inviting {members, plural, =1 {1 Member} other {# Members}} to General {groups, plural, =0 {channel} other {and # other channels}}',
      MEMBERS: "Members",
      GROUPS: "Channels",
      DONE_BUTTON: 'Confirm',
      CANCEL_BUTTON: 'Back'
    },
    CSV_IMPORT_PROGRESS: {
      TITLE: 'Importing',
      STATUS: 'Importing and Inviting Members',
      STATUS_SUMMARY: 'This may take a moment...'
    },
    CSV_IMPORT_SUMMARY: {
      TITLE: 'Import Complete',
      STATUS: 'Members Imported!',
      STATUS_SUMMARY: '{imported, plural, =1 {1 Member was} other {# Members where}} imported to your Group. Here are the results.',
      INVITED_MEMBERS_SUMMARY: 'Invited',
      INVITED_MEMBERS_DETAILS: '{invited, plural, =1 {1 member} other {# members}} invited successfully.',
      INCOMPLETE_MEMBERS_SUMMARY: 'Incomplete',
      INCOMPLETE_MEMBERS_DETAILS: '{incomplete, plural, =1 {1 member} other {# members}} need a valid email to be invited.',
      DONE_BUTTON: 'Done',
      VIEW_INCOMPLETE_BUTTON: 'View Incomplete'
    },
    CSV_SELECT_FILE: {
      TITLE: 'ImportCSV or Excel File',
      DRAG_HERE: 'Drag File Here',
      DRAG_HERE_ALTERNATIVE: 'Or you can...',
      UPLOAD_FILE: 'Upload File',
      SELECT_FILE_BUTTON: 'Select a File to Upload',
      INVALID_FILE_TYPE_ERROR: 'File type not supported. Must be a .csv, .xls, or .xlsx file.',
      EMPTY_SHEETS_ERROR: 'The file uploaded doesnt contain any data.',
      CANCEL_BUTTON: 'Cancel'
    },
    CSV_SHEET_SELCTOR: {
      TITLE: 'Select Sheet',
      CALL_TO_ACTION: 'Select a Sheet from Your File',
      CALL_TO_ACTION_SUMMARY: 'It looks like there are multiple sheets in this file. Which one would you like to import?',
      CANCEL_BUTTON: 'Cancel',
      SUBMIT_BUTTON: 'Next'
    },
    CSV_ROLE_SELECTOR: {
      TITLE: 'Select Role',
      CALL_TO_ACTION: 'Select Member Role',
      CALL_TO_ACTION_SUMMARY: 'Imported members will be given the following role.',
      BACK_BUTTON: 'Back',
      CANCEL_BUTTON: 'Cancel',
      SUBMIT_BUTTON: 'Next'
    },
    CSV_ATTRIBUTE_SELECTOR: {
      TITLE: 'Assign Attributes',
      CALL_TO_ACTION: 'Assign Attributes to Columns',
      CALL_TO_ACTION_SUMMARY_1: 'Select columns from your file to be imported as the following attributes.',
      CALL_TO_ACTION_SUMMARY_2: 'An invitation will be sent to each email address and its associated row of attributes.',
      EMAIL_LABEL: 'Email (Required)',
      EMAIL_REQUIRED_ERROR: 'Please select a column for email addresses.',
      NAME_LABEL: 'Name',
      POSITION_LABEL: 'Position',
      JERSEY_LABEL: 'Jersey #',
      SELECT_COLUMN_BUTTON: 'Select Column',
      CHANGE_COLUMN_BUTTON: 'Change Column',
      CANCEL_BUTTON: 'Cancel',
      SUBMIT_BUTTON: 'Next'
    },
    SELECT_GROUPS: {
      DONE_BUTTON: 'Add To Selected',
      BACK_BUTTON: 'Back'
    },
    MEMBER_IMPORT_PROGRESS_MODAL: {
      TITLE: 'Sending',
      CANCEL_BUTTON: 'Cancel',
      STATUS: 'Sending Invitations',
      STATUS_SUMMARY: 'This may take a moment...',
    },
    MEMBER_IMPORT_SUCCESS_MODAL: {
      TITLE: 'Invitations Sent',
      DONE_BUTTON: 'Done',
      ADD_MORE_CHANNELS_BUTTON: 'Add to more Channels',
      STATUS: 'Member Invitations Sent!',
      STATUS_SUMMARY: '{sent, plural, =1 {1 member was} other {# members were}} invited to your group.'
    },
    REPORT_POST_MODAL: {
      TITLE: 'Report Post',
      BODY: 'Explain why this post is inappropriate or offensive, and should be removed.',
      MESSAGE_PLACEHOLDER: 'Enter Message',
      CANCEL_BUTTON: 'Cancel',
      SUBMIT_BUTTON: 'Report',
      SUCCESS_TOAST: 'This post has been reported and removed from your feed.'
    },
    REPORT_REPLY_MODAL: {
      TITLE: 'Report Reply',
      BODY: 'Explain why this reply is inappropriate or offensive, and should be removed.',
      MESSAGE_PLACEHOLDER: 'Enter Message',
      CANCEL_BUTTON: 'Cancel',
      SUBMIT_BUTTON: 'Report'
    },
    WRAP: {
      TITLE: 'General',
      MEMBERS_COUNT: '{count, plural, =1 {1 Member} other {# Members}}',
      ADD_BUTTON: 'Add',
      NEW_CHANNEL_BUTTON: 'New Channel',
      CHANNELS_LABEL: 'Channels',
      JOIN_CHANNEL_BUTTON: 'Join Channel'
    },
    MEMBERS: {
      TITLE: 'Members',
      SEARCH_PLACEHOLDER: "Search Members"
    },
    ROLE_FILTER: {
      TITLE: 'Role',
      FILTER_LABEL: 'Filter By Role',
      FILTERING_ALL_TYPES: 'All Roles',
      FILTERING_NO_TYPES: 'None',
      FILTERING_MANY_TYPES: '{count, plural, =1 {# Role} other {# Roles}}',
      FILTER_OPTIONS: {
        FILTER_BY_ALL: 'All',
        COACHES: 'Coaches/Admins',
        PLAYERS: 'Members',
        FANS: 'Fans',
        VIEWERS: 'Viewers'
      }
    },
    NAV: {
      TITLE: 'General',
      TIMELINE: 'Timeline',
      MEMBERS: 'Members',
      SETTINGS: 'Settings'
    },
    TEAMS: {
      TITLE: 'Groups',
      EMPTY_TEAMS_SUMMARY: 'All your groups will appear here',
      EMPTY_TEAMS_HEADING: 'No Groups yet',
      SEARCH_PLACEHOLDER: 'Search groups...',
      NO_RESULTS: 'No Results',
      NO_RESULTS_SUMMARY: 'Nothing found for "{search}", please search for something else.',
      NEW_TEAM_BUTTON: '+ New Group'
    },
    MEMBERS_INVITE_BUTTON:  'Invite Members',
    MEMBER_EMAIL_INVITE_MODAL: {
      TITLE: 'Invite With Email',
      CANCEL_BUTTON: 'Cancel',
      SUBMIT_BUTTON: 'Next',
      ADD_MORE_MEMBERS_BUTTON: 'Add More Members',
      NAME_LABEL: 'Name',
      EMAIL_LABEL: 'Email (Required)',
      POSITION_LABEL: 'Position',
      JERSEY_NUMBER_LABEL: 'Jersey #',
      ROLE_LABEL: 'Role',
      MEMBERS_INVITED_STATUS: 'Member invitations have been sent.',
      INVALID_EMAIL_ERROR: 'The email entered is not valid.',
      DUPLICATE_EMAIL_ERROR: 'This email has already been entered.'
    },
    MEMBER_EMAIL_INVITE_SENT_MODAL: {
      TITLE: 'Invitation Sent',
      INVITATION_SENT_SUMMARY: 'Invitation Sent to {name}!',
      INVITE_ANOTHER: 'Want to invite another Group Member?',
      INVITE_ANOTHER_BUTTON: 'Invite Another',
      DONE_BUTTON: 'Done'
    },
    MEMBER_LINK_INVITE_MODAL: {
      TITLE: 'Share a Link',
      HEADER: {
        LEAD: 'Share a Link for Members to Join',
        BODY: 'Select a role, copy the link below, and share to invite new members.'
      },
      BODY: {
        COPY_ERROR: 'We were unable to copy the share link to your clipboard. Please try again.',
        COPY_LINK: 'Copy Link',
        COPY_SUCCESS: 'The share link was copied to your clipboard!',
        ERROR: 'Could not load sharing link. Please try again.',
        RETRY: 'Try Again',
        ROLES: {
          ATHLETE: {
            TITLE: 'Member',
            DESCRIPTION: 'Can post and interact.'
          },
          COACH: {
            TITLE: 'Coach/Admin (Invite with email only)',
            DESCRIPTION: 'Can post and interact, manage the Group, manage Group members, and manage Channels.'
          },
          FAN: {
            TITLE: 'Parent / Fan',
            DESCRIPTION: 'Can post and interact.'
          },
          VIEWER: {
            TITLE: 'Viewer',
            DESCRIPTION: 'View only.'
          }
        }
      },
      FOOTER: {
        DONE_BUTTON: 'Done',
      }
    },
    NEW_TEAM: {
      TITLE: 'New Group',
      TEAM_NAME_LABEL: 'Group Name',
      TEAM_NAME_PLACEHOLDER: 'Name of a group, organization, etc.',
      TEAM_NAME_REQUIRED_ERROR: 'A group name is required.',
      SPORT_SELECT_LABEL: 'What is This For?',
      SPORT_SELECT_PLACEHOLDER: 'Sport, subject, activity, etc.',
      NOTIFICATIONS_SELECT_LABEL: 'Your Notifications',
      TEMPLATE_SELECT_LABEL: 'Apply Template',
      TEMPLATE_SELECT_DEFAULT_OPTION: 'Select Template',
      ADD_LOGO_BUTTON: 'Add Group Logo',
      CANCEL_BUTTON: 'Cancel',
      CREATE_TEAM_BUTTON: 'Create Group',
      NOTIFICATIONS: {
       EMAIL: {
         LABEL: 'Email',
         SUMMARY: 'Get an email for every Group notification.'
       },
       APP: {
         LABEL: 'Push Notifications',
         SUMMARY: 'Get a push for every Group notification.'
       }
      }
    },
    TRANSFER_OWNERSHIP_MODAL: {
      TITLE: 'Transfer Group Ownership',
      HEADER: 'New Owner',
      CALL_TO_ACTION: 'Enter the email of a Coach within the Group to transfer ownership.',
      EMAIL_LABEL: 'New Owner\'s Email',
      TEAM_NAME_LABEL: 'Group Name to Confirm',
      INVALID_MEMBER_EMAIL_ERROR: 'Please enter an email of a Coach within the Group.',
      TEAM_NAME_MISMATCH_ERROR: 'Entered name does not match Group name.',
      ALREADY_OWNER_EMAIL: 'But you already own this Group.',
      DISCLAIMER: 'By clicking "Confirm and Transfer", you are transferring Group ownership and permissions to this Coach. You will still be part of the Group, but moved to a Coach role.',
      CANCEL_BUTTON: 'Cancel',
      SUBMIT_BUTTON: 'Confirm and Transfer'
    },
    TRANSFER_OWNERSHIP_COMPLETE_MODAL: {
      TITLE: 'Ownership Transferred',
      HEADER: 'New Owner Confirmed',
      CONFIRMATION_TEXT: '{{name}} is now the Owner of this Group. Your role has been changed to Coach.',
      DONE_BUTTON: 'Done'
    },
    POST_FORM: {
      CUSTOM_TEXT_PLACEHOLDER: 'Post to {{name}}',
      TEXT_PLACEHOLDER: 'Say something...',
      TEXT_REQUIRED_ERROR: 'Wouldn\'t you like to say something?',
      SCHEDULED_AT_INVALID_ERROR: 'Please select a future schedule date',
      TAG_PLACEHOLDER: 'Add some tags.',
      SCHEDULE_BUTTON: 'Schedule',
      CANCEL_BUTTON: 'Cancel',
      POST_BUTTON: 'Post',
      REPLY_BUTTON: 'Reply',
      UPGRADE_TO_POST: 'Upgrade to post.',
      UPGRADE_TO_REPLY: 'Upgrade to reply.',
      UPDATE_BUTTON: 'Update'
    },
    FEED: {
      NO_POSTS_TITLE: 'No Posts Yet',
      NO_POSTS_SUMMARY: 'All posts you and other members make will appear here.',
      MORE_REPLIES: 'Show More Comments',
      POST: {
        ERRORS: {
          PROCESSING: 'Your file could not be processed. Please try again, or contact support at <a href="mailto:{supportEmail}">{supportEmail}</a> for assistance.'
        },
        PROCESSING: {
          VIDEO: 'Your video is currently processing and will be available soon.'
        }
      },
      PLACEHOLDER_NAME: "general",
      PINNED_POST_CAPTION: "Pinned by {{name}}",
      PINNED_TILL: " till {{pinnedTillFormatted}}",
      LOCATIONS_SUMMARY:  "{spaceNames} + {count} more",
      BULK_IN_PROGRESS: 'Your post is being published'
    },
    FEED_CONTROLS: {
      PIN_POST_CONFIRMATION: 'Are you sure you want to pin this post? All currently pinned posts will be unpined',
      UNPIN_POST_CONFIRMATION: 'Are you sure you want to unpin this post?',
      DELETE_POST_CONFIRMATION: 'Are you sure you want to delete this post?',
      DELETE_REPLY_CONFIRMATION: 'Are you sure you want to delete this reply?',
      EDIT_OPTION: 'Edit',
      REPORT_OPTION: 'Report',
      DELETE_OPTION: 'Delete',
      PIN_OPTION: 'Pin to top',
      UNPIN_OPTION: 'Unpin from top',
      MOVE: 'Move to ...',
      COPY: 'Copy to ...',
    },
    MEMBER_LIST_HEAD: {
      TABS: {
        ACCEPTED: 'Active',
        PENDING: 'Invited',
        INCOMPLETE: 'Incomplete'
      }
    },
    MEMBER_LIST: {
      MEMBER_COUNT: '{count, plural, =1 {# Member} other {# Members}}',
      NO_MEMBERS_FOUND: 'No members found.',
      RESEND: 'Resend Invitation',
      RESEND_SUCCESS: 'Invitation resent',
      EDIT_MEMBER: 'Edit Member',
      REMOVE_MEMBER: 'Remove From Group',
      REMOVE_CONFIRMATION: 'Are you sure you want to remove {{name}} from this Group?',
      REMOVE_SUCCESS: '{{name}} was removed.',
      INCOMPLETE_REASON: {
        MISSING_EMAIL: 'Missing Email'
      },
      RESEND_ERRORS: {
        SPAM: 'You can only send up to 1 invitation reminder to a person every 24 hours!',
        GENERAL_ERROR: 'Cannot resend this invitation, please try again later.'
      }
    },
    EDIT_MEMBER: {
      TITLE_EDIT: 'Edit Group Member',
      TITLE_INVITE: 'Edit Invitation',
      TITLE_RESOLVE: 'Resolve Incomplete Member',
      NAME_LABEL: 'Name',
      NAME_REQUIRED_ERROR: 'A name is required.',
      EMAIL_LABEL: 'Email',
      EMAIL_REQUIRED_ERROR: 'A valid email address is required.',
      NICKNAME_LABEL: 'Nickname',
      POSITION_LABEL: 'Position',
      JERSEY_NUMBER_LABEL: 'Jersey #',
      ROLE: {
        ROLE_LABEL: 'Role',
        COACH: 'Coach',
        COACH_SUMMARY: 'Can post and interact, manage the Group, manage Group members, and manage channels.',
        ATHLETE: 'Athlete',
        ATHLETE_SUMMARY: 'Can post and interact.',
        FAN: 'Parent / Fan',
        FAN_SUMMARY: 'Can post and interact.',
        VIEWER: 'Viewer',
        VIEWER_SUMMARY: 'View only.'
      },
      CANCEL_BUTTON: 'Cancel',
      SAVE_BUTTON: 'Save',
      SEND_BUTTON: 'Send Invitation',
      SEND_SUCCESS: 'Invitation was successfully sent.',
      CHANGES_SAVED: 'Changes saved.'
    },
    ONBOARDING_MODAL: {
      TITLE: 'Group Created',
      CREATED_SUCCESS: '{teamName} Created!',
      START_INVITING: 'Invite members to build your Group.',
      INVITE_MEMBERS_BUTTON: 'Invite Members to Group'
    },
    INVITE_ACTIONS_MODAL: {
      TITLE: 'Invite Members to Group',
      INVITE_BY_EMAIL_BUTTON: 'Invite with Email',
      INVITE_BY_LINK_BUTTON: 'Share a Link',
      IMPORT_FILE_BUTTON: 'Import CSV or Excel File',
      CANCEL_BUTTON: 'Cancel'
    },
    TEAM_SETTINGS_MENU: {
      TEAM_SETTINGS_LABEL: 'Group Settings',
      MEMBER_SETTINGS_LABEL: 'Your Notifications',
      LEAVE_TEAM_LABEL: 'Leave Group'
    },
    SETTINGS: {
      TITLE: "Group Settings",
      TEAM_NAME_LABEL: 'Group Name',
      TEAM_NAME_PLACEHOLDER: 'Name of a Group, organization, etc.',
      TEAM_NAME_REQUIRED_ERROR: 'A Group name is required.',
      SPORT_SELECT_LABEL: 'What is This For?',
      SPORT_SELECT_PLACEHOLDER: 'Sport, subject, activity, etc.',
      PRIVATE_TEAM: 'Allow members to view other members',
      MEMBERS_CAN_POST: 'Allow members to create posts',
      MEMBERS_CAN_REPLY: 'Allow members to reply to posts',
      MEMBERS_CAN_SEE_POST_DATA: 'Allow members to see post Views/Likes',
      NOTIFICATIONS: {
        TITLE: "Your Notifications",
        PUSH_NOTIFICATIONS: {
          LABEL: 'Push Notifications',
          SUMMARY: 'Get a push for every Group notification.'
        },
        EMAIL: {
          LABEL: 'Email',
          SUMMARY: 'Get an email for every Group notification.'
        }
      },
      CANCEL_BUTTON: "Cancel",
      OPTIONS_LABEL: 'Options',
      TRANSFER_BUTTON: 'Transfer Group Ownership',
      DELETE_BUTTON: "Delete Group",
      LEAVE_BUTTON: "Leave Group",
      SAVE_BUTTON: "Save",
      UPDATE_LOGO_BUTTON: 'Update Group Logo',
      DELETE_CONFIRMATION: 'Are you sure you would like to delete {{name}}?',
      LEAVE_CONFIRMATION: 'Are you sure you would like to leave {{name}}?',
      DELETED_MESSAGE: 'Group has been deleted.',
      LEFT_MESSAGE: 'You have left {{name}}.',
      SAVE_SUCCESS_MESSAGE: "Your settings have been saved."
    }
  },
  TEAM_CHANNELS: {
    CHANNEL_SETTINGS_MENU: {
      CHANNEL_SETTINGS_OPTION: 'Settings',
      LEAVE_CHANNEL_OPTION: 'Leave Channel',
      LEAVE_CHANNEL_CONFIRMATION: 'Are you sure you would like to leave {{channel}}?.',
      LEAVE_CHANNEL_SUCCESS: 'You are not longer part of {{channel}}.'
    },
    BULK_MEMBER_ADD_PROGRESS_MODAL: {
      TITLE: 'Adding Members...',
      STATUS: 'Adding Channel Members',
      STATUS_SUMMARY: 'This may take a moment...'
    },
    NEW_CHANNEL_FLOW: {
      ALL_TEAM_MEMBERS_ADDED_STATUS: 'All Group members have been added.'
    },
    NAV: {
      MEMBERS: 'Channel Members',
      TIMELINE: 'Timeline',
      SETTINGS: 'Settings'
    },
    MEMBERS: {
      TITLE: "Channel Members"
    },
    NEW_BUTTON: {
      TITLE: 'New Channel'
    },
    NEW_MODAL: {
      TITLE: 'New Channel',
      CANCEL_BUTTON: 'Cancel',
      SUBMIT_BUTTON: 'Create Channel',
      NAME_LABEL: 'Channel Name',
      NAME_REQUIRED_ERROR: 'A channel name is required.',
      DESCRIPTION_LABEL: 'Description',
      NOTIFICATIONS_LABEL: 'Your Notifications',
      OPTIONS_LABEL: 'Options',
      OPTIONS: {
        VIEW_ONLY: {
          TITLE: 'View Only',
          SUMMARY: 'Members can only view content in the channel.'
        }
      },
      TEMPLATE_LABEL: 'Apply Template',
      TEMPLATE_DEFAULT_OPTION: 'Select Template',
      GROUP_TYPES: {
        PRIVATE: {
          LABEL: 'Private',
          SUMMARY: 'Only visible to Group Members. Only coaches can add Group Members.'
        },
        JOINABLE: {
          LABEL: 'Joinable',
          SUMMARY: 'Visible to all Group Members. Any Group Member can join.'
        },
        AUTO_JOIN: {
          LABEL: 'Auto-Join',
          SUMMARY: 'All current and new Group members will be automatically added to the Group'
        }
      }
    },
    CHANNEL_CREATED_MODAL: {
      TITLE: 'Channel Created',
      HEADING: '{{name}} Created!',
      CALL_TO_ACTION: 'Choose Group Members to form the Channel.',
      ADD_MEMBERS_BUTTON: 'Select Group Members',
      ADD_ALL_MEMBERS_BUTTON: 'Add All Group Members',
      DONE_BUTTON: 'Done'
    },
    NEW_ONBOARDING_MODAL: {
      TITLE: 'Add Members to Channel',
      SKIP_BUTTON: 'Skip',
      SUBMIT_BUTTON: 'Add Selected',
    },
    ADD_MEMBERS_BUTTON: {
      TITLE: 'Add Members'
    },
    CHANNEL_MEMBER_SELECT: {
      SEARCH_PLACEHOLDER: 'Search Members',
      NO_MEMBERS_FOUND: 'No Members found.',
      INVITE_TO_TEAM: 'Invite Members to Group'
    },
    CHANNEL_MEMBER_LIST: {
      MEMBER_COUNT: '{count, plural, =1 {# Member} other {# Members}}',
      NO_MEMBERS_FOUND: 'No members found.',
      REMOVE: 'Remove From Channel',
      REMOVE_CONFIRMATION: 'Are you sure you would like to remove {{name}} from this channel?',
      REMOVE_YOURSELF_CONFIRMATION: 'Are you sure you would like to leave this channel?',
      SEARCH_PLACEHOLDER: 'Search Members'
    },
    SETTINGS: {
      TITLE: 'Channel Settings',
      NAME: {
        LABEL: 'Channel Name',
        REQUIRED_ERROR: 'A channel name is required.',
      },
      DESCRIPTION_LABEL: 'Description',
      SAVE_BUTTON: "Save",
      SAVE_SUCCESS_MESSAGE: "Your settings have been saved.",
      CANCEL_BUTTON: 'Cancel',
      DELETE_BUTTON: 'Delete Channel',
      DELETE_CONFIRMATION: "Are you sure you would like to remove the channel {{name}} from this Group?",
      DELETE_SUCCESS_MESSAGE: "The channel {{name}} has been deleted.",
      GROUP_TYPES: {
        PRIVATE: {
          LABEL: 'Private',
          SUMMARY: 'Only visible to Group Members. Only coaches can add Group Members.'
        },
        JOINABLE: {
          LABEL: 'Joinable',
          SUMMARY: 'Visible to all Group Members. Any Group Member can join.'
        },
        AUTO_JOIN: {
          LABEL: 'Auto-Join',
          SUMMARY: 'All current and new Group members will be automatically added to the Group'
        }
      }
    }
  },
  MEMBER: {
    TYPE: {
      COACH: 'Coach',
      PLAYER: 'Athlete',
      FAN: 'Fan',
      VIEWER: 'Viewer',
      OWNER: 'Owner',
      UNKNOWN: 'Unknown'
    }
  },
  CONFIRMATION: {
    TITLE: 'Confirm',
    BODY: 'Are you sure?',
    CANCEL_BUTTON: 'Cancel',
    CONFIRM_BUTTON: 'Confirm'
  },
  MEDIA: {
    BUTTON_LABEL: 'Media',
    MODAL: {
      TITLE: 'Select Media',
      CANCEL_BUTTON: 'Cancel',
      CONFIRM_BUTTON: 'Select',
      UPLOAD_TAB_LABEL: 'Upload',
      LIBRARY_TAB_LABEL: 'Library'
    },
    PICKER: {
      POST_COUNT: '{count, plural, =1 {# Post} other {# Posts}}',
      TAG_SEARCH_PLACEHOLDER: 'Search by tags',
      FILTERING_ALL_TYPES: 'All Types',
      FILTERING_NO_TYPES: 'None',
      FILTERING_MANY_TYPES: '{count, plural, =1 {# Type} other {# Types}}',
      NO_RESULTS: 'No library items found.',
      FILTER_BY_TYPE_LABEL: 'Filter By',
      FILTER_BY_ALL: 'All'
    },
    TYPES: {
      VIDEO: 'Video',
      AUDIO: 'Audio',
      IMAGES: 'Images',
      DOCUMENTS: 'Documents'
    }
  },
  POLICY_MODAL: {
    TITLE: 'We\'ve updated our Privacy Policy and Terms of Service',
    OVERVIEW: 'Please review our latest <a href="https://coachnow.io/terms-of-service" target="_blank">Privacy Policy</a>, <a href="https://coachnow.io/privacy-policy" target="_blank">Terms of Service</a> and <a href="https://coachnow.io/cookies" target="_blank">Cookie Policy</a> to understand how we use your data. Highlights of this update include:',
    PROMPT: 'To continue using CoachNow, please accept the terms as described above. If you have any questions, please contact or email us at <a href="mailto:privacy@coachnow.io">privacy@coachnow.io</a>',
    ACCEPT_BUTTON: 'Accept'
  },
  CONNECTIONS: {
    CONNECTED_STATUS: 'Connected',
    NOT_CONNECTED_STATUS: 'Not connected',
    CONNECT_BUTTON: 'Connect',
    DISCONNECT_BUTTON: 'Disconnect',
    FACEBOOK_CONNECT_ERROR: 'Unable to connect your Facebook account.',
    FACEBOOK_ACCOUNT_EXISTS: 'This Facebook account is already connected to another CoachNow account.',
    FACBEOOK_DISCONNECT_ERROR: 'Unable to disconnect your Facebook account.'
  },
  ELAPSED_TIME: {
    SECONDS: 'Just now',
    MINUTES: '{minutes, plural, =1 {# m} other {# m}}',
    HOURS: '{hours, plural, =1 {# h} other {# h}}',
    DAYS: '{days, plural, =1 {# d} other {# d}}',
    WEEKS: '{weeks, plural, =1 {# w} other {# w}}'
  },
  SITE_SIDEBAR: {
    COPYRIGHT: "© {{ year }} CoachNow",
    TERMS_OF_SERVICE: 'Terms of Service',
    PRIVACY_POLICY: 'Privacy Policy',
    DAYS_LEFT: '{days, plural, =1 {# Day Left} other {# Days Left}}',
    TRIAL_EXPIRED: 'Trial Expired',
    CONTACT_SALES: 'Contact Sales',
    UPGRADE: 'Upgrade',
    RENEW: 'Renew',
    EXPIRED: 'Expired',
    BUSINESS: 'Business'
  },
  PILLS: {
    ADD_OPTION: 'Add {{option}}...'
  },
  ACTIVITY: {
    NOTIFICATIONS: {
      EMPTY: 'No notifications'
    },
    INVITATIONS: {
      INCOMING: 'Incoming {{ count }}',
      OUTGOING: 'Outgoing {{ count }}',
      EMPTY: 'No invitations',
      RESEND_BUTTON: 'Resend',
      CANCEL_BUTTON: 'Cancel Invite'
    },
    OUTGOING: {
      SPACE: 'You invited <b>{{ name | sanitize }}</b> to {{athlete}} / {{space}} Space',
      TEAM: 'You invited <b>{{ name | sanitize }}</b> to {{team}} Group'
    },
    NOTIFICATION: {
      ALL_CHANNELS: 'General',
      POST_CREATED: {
        NOTE: '<b>{{ poster | sanitize }}</b> created a post: "{{ message | sanitize }}"',
        IMAGE: '<b>{{ poster | sanitize }}</b> posted an image: "{{ message | sanitize }}"',
        IMAGE_NO_MESSAGE: '<b>{{ poster | sanitize }}</b> posted an image.',
        VIDEO: '<b>{{ poster | sanitize }}</b> posted a video: "{{ message | sanitize }}"',
        VIDEO_NO_MESSAGE: '<b>{{ poster | sanitize }}</b> posted a video.',
        AUDIO: '<b>{{ poster | sanitize }}</b> posted audio: "{{ message | sanitize }}"',
        AUDIO_NO_MESSAGE: '<b>{{ poster | sanitize }}</b> posted audio.',
        DOCUMENT: '<b>{{ poster | sanitize }}</b> posted a document: "{{ message | sanitize }}"',
        DOCUMENT_NO_MESSAGE: '<b>{{ poster | sanitize }}</b> posted a document.',
      },
      REPLY_CREATED: {
        NOTE: '<b>{{ poster | sanitize }}</b> replied to a post: "{{ message | sanitize }}"',
        NOTE_NO_MESSAGE: '<b>{{ poster | sanitize }}</b> replied to a post.',
        IMAGE: '<b>{{ poster | sanitize }}</b> replied to an image: "{{ message | sanitize }}"',
        IMAGE_NO_MESSAGE: '<b>{{ poster | sanitize }}</b> replied to an image.',
        VIDEO: '<b>{{ poster | sanitize }}</b> replied to a video: "{{ message | sanitize }}"',
        VIDEO_NO_MESSAGE: '<b>{{ poster | sanitize }}</b> replied to a video.',
        AUDIO: '<b>{{ poster | sanitize }}</b> replied to an audio clip: "{{ message | sanitize }}"',
        AUDIO_NO_MESSAGE: '<b>{{ poster | sanitize }}</b> replied to an audio clip.',
        DOCUMENT: '<b>{{ poster | sanitize }}</b> replied to a document: "{{ message | sanitize }}"',
        DOCUMENT_NO_MESSAGE: '<b>{{ poster | sanitize }}</b> replied to a document.',
      },
      INVITATION_ACCEPTED: '<b>{{ name | sanitize }}</b> accepted your invitation.',
      INVITATION_DECLINED: '<b>{{ name | sanitize }}</b> rejected your invitation.',
      TEAM_CHANNEL_MEMBERSHIP: '<b>{{ name | sanitize }}</b> added you to {{ channel | sanitize }}.',
      TEAM_OWNERSHIP_TRANSFERRED: '<b>{{ name | sanitize }}</b> transferred ownership of {{ team | sanitize }} to you.',
      LIKE_CREATED: {
        NOTE: '<b>{{ name | sanitize }}</b> liked your post.',
        IMAGE: '<b>{{ name | sanitize }}</b> liked your image.',
        VIDEO: '<b>{{ name | sanitize }}</b> liked your video.',
        AUDIO: '<b>{{ name | sanitize }}</b> liked your audio.',
        DOCUMENT: '<b>{{ name | sanitize }}</b> liked your document.'
      }
    },
    INVITATION: {
      SPACE: '<b>{{ inviter | sanitize }}</b> invited you to {{ owner | sanitize }} / {{ space | sanitize }}.',
      TEAM: '<b>{{ inviter | sanitize }}</b> invited you to {{ team | sanitize }}.',
      JOIN_SPACE_BUTTON: 'Join Space',
      JOIN_TEAM_BUTTON: 'Join Group',
      DECLINE_BUTTON: 'Decline'
    },
    TABS: {
      NOTIFICATIONS: 'Notifications',
      INVITATIONS: 'Invitations'
    },
    NOTIFICATION_SUMMARY: {
      EMPTY: 'No notifications',
      VIEW_ALL_BUTTON: 'View All'
    },
    INVITATION_SUMMARY: {
      EMPTY: 'No invitations',
      VIEW_ALL_BUTTON: 'View All',
      JOIN_ALL_BUTTON: 'Join All',
      JOINING_ALL_STATUS: 'Joining all. This may take a moment.',
      JOIN_ALL_SUCCESS: 'Successfully joined all.',
      ACCEPT_BUTTON: 'Accept',
      DECLINE_BUTTON: 'Decline'
    },
    WRAP: {
      TITLE: 'Activity',
      TABS: {
        NOTIFICATIONS: 'Notifications',
        INVITATIONS: 'Invitations'
      }
    },
    TIMESPAN: {
      DAY: 'Today',
      WEEK: 'This Week',
      MONTH: 'This Month',
      DEFAULT: 'Everything Else'
    }
  },
  BULK_POST_SELECTOR: {
    BUTTON: "{locationsCount} {locationsCount, plural, =0 {Locations} =1 {Location} other {Locations}}",
    SEARCH_LABEL: "Search Location",
    SMART_LISTS: "Smart Lists",
    SEGMENTS: "Lists",
    SPACES: "Spaces",
    TEAMS: "Groups",
    GENERAL: "General",
    NO_DESTINATIONS: "You don't have any post locations.<br/> Please, <a href='/spaces/new'>create one</a>.",
    SEGMENT_POSTING_UPGRADE: "Upgrade to List Posting"
  },
  READMORE: {
    EXPAND_LINK: 'Read More'
  },
  MONTHS: {
    SHORT: {
      JAN: 'Jan',
      FEB: 'Feb',
      MAR: 'Mar',
      APR: 'Apr',
      MAY: 'May',
      JUN: 'Jun',
      JUL: 'Jul',
      AUG: 'Aug',
      SEPT: 'Sept',
      OCT: 'Oct',
      NOV: 'Nov',
      DEC: 'Dec'
    }
  },
  TEAM_ROLES: {
    COACH: 'Coach/Admin',
    PLAYER: 'Member',
    FAN: 'Parent / Fan',
    VIEWER: 'Viewer'
  },
  SELECT_LIST: {
    USE_PLACEHOLDER: 'Use "{{label}}"'
  },
  PUBLIC: {
    UNSUBSCRIBE: {
      PENDING: 'Please, wait...',
      RESUBSCRIBE: 'You are now subscribed to email notifications',
      UNSUBSCRIBE: 'You have unsubscribed from email notifications',
      FOR_USER_SPORT: "for {{name}}'s {{sport}} TrainingSpace",
      FOR_TEAM_SPORT: 'for {{name}} {{sport}} Group TrainingSpace',
      ERROR: 'Due to server error we were not able to save your changes',
    },
    USER: {
      SUCCESS: 'Settings successfully saved',
    },
    INVITATION: {
      SUCCESS: 'You have unsubscribed from email notifications for this invitation',
    }
  }
};

export default translationsEn;
