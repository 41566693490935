/**
 * @module Directives.Teams
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/teams/_member_link_invite_modal.html';
import share_link_icon_png from 'app/images/share-link-icon.png';

/**
 * @class TeamMemberLinkInviteModal
 *
 */
function Controller($q, $scope, $translate, $clipboard, toast, tracking, Team) {
  $scope.inviteUrl = null;
  $scope.hasError = false;
  $scope.pending = true;
  $scope.role = 'player';
  $scope.onCopyClick = onCopyClick;
  $scope.onDone = onDone;
  $scope.onRetryClick = onRetryClick;
  $scope.onRoleChange = onRoleChange;
  $scope.share_link_icon_png = share_link_icon_png;

  loadInviteUrl($scope.role);

  function loadInviteUrl (role) {
    $scope.pending = true;
    $scope.hasError = false;

    Team.getInviteUrl($scope.$props.team.id, role)
      .then(url => run(() => {
        url = url.replace(/^https?:\/\/[^/]*(.*)$/, '$1');
        url = window.location.origin + url;
        $scope.inviteUrl = url;
      }))
      .catch(() => run(() => $scope.hasError = true))
      .finally(() => run(() => $scope.pending = false));
  }

  function onCopyClick(e) {
    $q.resolve($clipboard.copy($scope.inviteUrl, e))
      .then(onCopySuccess, onCopyError);
  }

  function onCopyError() {
    $translate('TEAMS.MEMBER_LINK_INVITE_MODAL.BODY.COPY_ERROR').then(function(translation) {
      toast.danger(translation);
    });
  }

  function onCopySuccess() {
    $translate('TEAMS.MEMBER_LINK_INVITE_MODAL.BODY.COPY_SUCCESS').then(function(translation) {
      tracking.teams.memberInvited($scope.$props.team, 'Link', $scope.role);
      toast.success(translation);
    });
  }

  function onDone() {
    $scope.$props.modal.dismiss();
  }

  function onRetryClick() {
    loadInviteUrl($scope.role);
  }

  function onRoleChange() {
    loadInviteUrl($scope.role);
  }
}

Controller.$inject = [
  '$q',
  '$scope',
  '$translate',
  'core.services.$clipboard',
  'core.ui.toast',
  'core.services.tracking',
  'core.services.Team'
];

export default {
  bindings: {
    modal: '<',
    team: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
