
function Emojify(emoji) {
  function link($scope, $element, $attributes, $controller) {
    $controller.$parsers.push(function (value) {
      var filteredValue = emoji.filter(value);

      if (filteredValue !== value) {
        $controller.$setViewValue(filteredValue);
        $controller.$render();
      }

      return filteredValue;
    });
  }

  return {
    require: 'ngModel',
    scope: false,
    link: link
  };
}

Emojify.$inject = ['core.emoji'];

export default Emojify;
