/**
 * @module Angular.Directives.TeamsGroups
 *
 */
import AddGroupMembersFlow from './add_group_members_flow';
import groupAddMembersButton from './group_add_members_button';
import groupBulkMemberAddProgressModal from './group_bulk_member_add_progress_modal';
import groupCreatedModal from './group_created_modal';
import GroupFeedPagerFactory from './group_feed_pager_factory';
import groupMemberList from './group_member_list';
import groupMemberSelect from './group_member_select';
import GroupModalService from './group_modal_service';
import groupNav from './group_nav';
import groupSettingsMenu from './group_settings_menu';
import groupSettingsModal from './group_settings_modal';
import MemberForSelectPagerFactory from './members_for_select_pager_factory';
import newGroupButton from './new_group_button';
import NewGroupFlow from './new_group_flow';
import newGroupModal from './new_group_modal';
import newGroupOnboardingModal from './new_group_onboarding_modal';

export default function(__angular) {
  __angular.module('directives')
    .component('groupAddMembersButton', groupAddMembersButton)
    .component('groupBulkMemberAddProgressModal', groupBulkMemberAddProgressModal)
    .component('groupCreatedModal', groupCreatedModal)
    .component('groupMemberList', groupMemberList)
    .component('groupMemberSelect', groupMemberSelect)
    .component('groupNav', groupNav)
    .component('groupSettingsMenu', groupSettingsMenu)
    .component('groupSettingsModal', groupSettingsModal)
    .component('newGroupButton', newGroupButton)
    .component('newGroupModal', newGroupModal)
    .component('newGroupOnboardingModal', newGroupOnboardingModal);

  // factories
  __angular.module('directives')
    .factory('addGroupMembersFlow', AddGroupMembersFlow)
    .factory('groupFeedPagerFactory', GroupFeedPagerFactory)
    .factory('groupModal', GroupModalService)
    .factory('memberForSelectPagerFactory', MemberForSelectPagerFactory)
    .factory('newGroupFlow', NewGroupFlow);
}
