/** @module resources */

function TagFactory($resource, $q) {
  var Tag = $resource({
    url: 'api/v1/tags',
    name: 'tag'
  });

  Tag.onlyNames = function(options) {
    var deferred = $q.defer();

    Tag.query(options).
      then(function(response) {
        var tagNames = response.map(function(tag) { return tag.name; });
        deferred.resolve(tagNames);
      }, function() {
        deferred.reject();
      });
    return deferred.promise;
  };

  return Tag;
}

TagFactory.$inject = ['core.services.$resource', '$q'];

export default TagFactory;
