
import group_icon_png from 'app/images/group-icon.png';
import template from 'ngapp/templates/views/teams_groups/_group_bulk_member_add_progress_modal.html';

function Controller($scope) {
  $scope.group_icon_png = group_icon_png;
}

Controller.$inject = [ '$scope' ];

export default {
  bindings: {},
  template,
  controller: Controller,
  controllerAs: '$props'
};
