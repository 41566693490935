/**
 * @module Directives
 *
 */

/**
 * @class TeamMemberPager
 *
 */
function factory(TeamMember, createPager) {
  const settings = {
    limit: 20,
    throttle: 10000
  };

  function serialize(filters) {
    if (filters == null) { return {}; }

    const type = Object.keys(filters.roles || {}).filter(key =>
      filters.roles[key] === true
    );
    return { type, status: filters.status, userName: filters.query };
  }

  return function create(id, filters) {
    function load(request) {
      const params = Object.assign(
        {},
        serialize(filters),
        { page: request.page, perPage: request.limit }
      );
      return TeamMember.get({ teamId: id }, params);
    }
    return createPager(load, settings);
  };
}

factory.$inject = ['core.services.TeamMember', 'core.services.pagerFactory'];

export default factory;
