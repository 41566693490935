/**
 * @module Core.Services.Tracking
 *
 */

/**
 * @class $trial
 *
 */
function factory(/*$client*/) {
  function started() {
    // $client.track('Trial Started', { Source: 'Web' });
  }

  return {
    started: started
  };
}

factory.$inject = ['core.services.tracking.$client'];

export default factory;
