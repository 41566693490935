
import template from 'ngapp/templates/directives/_upload_button.html';

function Controller($scope, $modal) {
  $scope.disabled = false;
  $scope.onClick = onClick;
  $scope.$props.$onChanges = $onChanges;

  function onClick() {
    $modal.open({
      template: `
        <upload-modal
          modal="modal"
          accept="{{accept}}"
          on-cancel="onCancel()"
          on-upload="onUpload(resource)"
        ></upload-modal>
      `,
      controller: ['$scope', '$modalInstance', function ModalController($local, modal) {
        $local.modal = modal;
        $local.accept = $scope.$props.accept;
      }]
    }).result
      .then(function (resource) {
        $scope.$props.onUpload({ resource: resource });
      });
  }

  function $onChanges(props) {
    if (props.disabled != null) {
      $scope.disabled = props.disabled.currentValue;
    }
  }
}

Controller.$inject = ['$scope', '$modal'];

export default {
  bindings: {
    disabled: '<',
    accept: '@',
    onUpload: '&'
  },
  template,
  controllerAs: '$props',
  controller: Controller,
  transclude: true
};
