/** @module legacy.services */

/**
 * @class ConfirmFactory
 *
 */
function ConfirmFactory($q, $window, $translate) {
  return function confirm(options) {
    if (options == null) {
      return confirm({});
    }

    // chrome extension for selenium overrides the window.confirm function
    // and throws an error when the message is not a string
    let message = options.body || $translate.instant('CONFIRMATION.BODY');
    if (message != null && message.toString != null) {
      // convert $translate object to string
      message = message.toString();
    }

    if ($window.confirm(message)) {
      return $q.resolve({ confirmed: true });
    }

    return $q.resolve({ confirmed: false });
  };
}

ConfirmFactory.inject = ['$q', '$window', '$translate'];

export default ConfirmFactory;
