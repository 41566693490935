
import { StripeService } from 'app/services';
import { buildUrl } from 'app/utils/request';

function factory($http, $elements) {
  function Stripe($http, $elements) {
    this._$http = $http;
    this._stripe = StripeService.stripePromise();
    this._$elements = $elements;
  }

  Stripe.prototype.elements = function elements() {
    return this._$elements();
  };

  Stripe.prototype.getPaymentInfo = function getPaymentInfo() {
    return StripeService.getPaymentInfo();
  };

  Stripe.prototype.updatePaymentInfo = function updatePaymentInfo(card, name, zip) {
    var self = this;

    return this._stripe
      .createToken(card, { name: name, address_zip: zip })
      .then(function(response) {
        return self._updatePaymentInfo(response.token.id);
      });
  };

  Stripe.prototype._updatePaymentInfo = function _updatePaymentInfo(token) {
    return this._$http.put(buildUrl('stripe/payment_info', {}, 1), { token: token });
  };

  return new Stripe($http, $elements);
}

factory.$inject = ['$http', 'stripeElementsFactory'];

export default factory;
