
function TransferSpaceModal($modal) {
  function transferOwnership(space) {
    return $modal.open({
      template: '<transfer-space-modal space="space" modal="modal"></transfer-space-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $scope) {
        $scope.modal = $modal;
        $scope.space = space;
      }]
    });
  }

  function transferComplete(member) {
    return $modal.open({
      template: '<transfer-space-complete-modal member="member" modal="modal"></transfer-space-complete-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $scope) {
        $scope.modal = $modal;
        $scope.member = member;
      }]
    });
  }

  return {
    transferOwnership: transferOwnership,
    transferComplete: transferComplete
  };
}

TransferSpaceModal.inject = ['$modal'];

export default TransferSpaceModal;
