/** @module core/services/activity */
import find from 'lodash/find';

/***/
const PAGING = { page: 0, perPage: 50 };

/**
 * @class $OutgoingInvitationCollection
 *
 */
function factory(Signal, Invitation, $Collection, pagerFactory) {
  function $OutgoingInvitationCollection() {
    this.onChange = new Signal(this);
    this.pager = pagerFactory(PAGING);
    this.pager.onPage.attach(e => this.$collection.push(e.data.results));
    this.pager.get().then(invitations => {
      this.$collection = new $Collection(invitations);
      this.$collection.onChange.attach(this.$onChange.bind(this));
    });
  }

  $OutgoingInvitationCollection.query = function query() {
    return new $OutgoingInvitationCollection();
  };

  $OutgoingInvitationCollection.prototype = {
    get pending() {
      return this.$collection && this.$collection.pending;
    },

    get values() {
      return this.$collection && this.$collection.values; //TODO
    }
  };

  $OutgoingInvitationCollection.prototype.$onChange = function $onChange() {
    this.onChange.dispatch(this, {
      pending: this.pending,
      values: this.values
    });
  };

  $OutgoingInvitationCollection.prototype.accept = function (id) {
    const asyncFunc = () => {
      const invitation = find(this.values, { id });
      if (invitation != null) {
        return Invitation.accept(id).then(() => invitation.goto());
      }
    };

    return this.$collection
      .async(asyncFunc)
      .then(() => this.$collection.remove(id))
      .then(() => this);
  };

  $OutgoingInvitationCollection.prototype.cancel = function cancel(invitation) {
    return this.$collection
      .async(() => Invitation.cancel(invitation))
      .then(() => this.$collection.remove(invitation.id))
      .then(() => this);
  };

  $OutgoingInvitationCollection.prototype.resend = function resend(invitation) {
    return this.$collection
      .async(() => Invitation.resend(invitation))
      .then(() => this);
  };

  return $OutgoingInvitationCollection;
}

factory.$inject = [
  'core.Signal',
  'core.services.Invitation',
  'core.services.activity.$Collection',
  'outgoingInvitationPagerFactory'
];

export default factory;

