/**
 * @module Directives.Teams
 *
 */
import template from 'ngapp/templates/views/teams/_scheduled_posts_panel.html';

/**
 * @class scheduledPostsPanel
 *
 */
function Controller($root, $scope, Post) {
  $scope.object = $scope.$props.object || {};
  $scope.user = $scope.$props.user || {};
  $scope.scheduledPosts = [];

  getScheduledPosts();

  $root.$on('postCreated', () => {
    getScheduledPosts();
  });

  function getScheduledPosts() {
    Post.scheduledPosts($scope.object.scheduledPostsQueryParams).then((posts) => {
      $scope.scheduledPosts = posts;
    });
  }
}

Controller.$inject = [
  '$rootScope',
  '$scope',
  'core.services.Post'
];

export default {
  bindings: {
    object: '<',
    user: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
