
import template from 'ngapp/templates/views/teams/_attachment_lightbox_modal.html';

const PLAYABLE_MEDIA_TYPES = ['audio', 'video'];

function Controller($scope, $element) {

  function isPlayableMedia(attachment) {
    if (attachment && attachment.type) {
      return PLAYABLE_MEDIA_TYPES.indexOf(attachment.type) > -1;
    }
    return false;
  }

  function downloadUrl(attachment) {
    let url = '';
    if (attachment) {
      url = attachment.url || attachment.originalUrl;
      if (!url.length && attachment.image) {
        url = attachment.image.originalUrl;
      }
    }
    return url;
  }

  function onDismissTargetClick(e) {
    if (e.target === $container[0] || e.target === $top[0]) {
      $scope.$props.modal.dismiss();
    }
  }

  $scope.selectedAttachment = $scope.$props.selectedAttachment;
  $scope.isPlayableMedia = isPlayableMedia;
  $scope.downloadUrl = downloadUrl;

  const $container = $element.find('.lightbox-container');
  $container.on('click', onDismissTargetClick);

  const $top = $element.find('.lightbox-top');
  $top.on('click', onDismissTargetClick);
}

Controller.$inject = ['$scope', '$element'];

export default {
  bindings: {
    modal: '<',
    attachmentList: '<',
    selectedAttachment: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
