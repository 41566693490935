
import template from 'ngapp/templates/directives/_upload_form.html';

var VIEWS = {
  DROPZONE: 'dropzone',
  PROGRESS: 'progress'
};

var ERRORS = {
  UPLOAD_ERRROR: 'upload_error'
};

function Controller($scope, uploader) {
  $scope.tags = [];
  $scope.accept = '';
  $scope.view = VIEWS.DROPZONE;
  $scope.upload = null;
  $scope.onFiles = onFiles;
  $scope.onCancel = onCancel;
  $scope.$props.$onChanges = $onChanges;
  $scope.$props.$onDestroy = $onDestroy;

  function onFiles(files) {
    var file = files[0];
    $scope.view = VIEWS.PROGRESS;
    $scope.upload = uploader(file, $scope.$props.tags);
    $scope.upload.uploaded
      .then((resource) => $scope.$props.onUpload({ resource: resource }))
      .catch((e) => {
        if (e.code !== 'aborted') {
          $scope.error = ERRORS.UPLOAD_ERRROR;
        }
      })
      .finally(() => $scope.upload = null);
  }

  function onCancel() {
    abort();
    $scope.$props.onCancel();
  }

  function abort() {
    if ($scope.upload != null) {
      $scope.upload.abort();
    }
  }

  function $onDestroy() {
    abort();
  }

  function $onChanges(props) {
    if (props.accept != null) {
      $scope.accept = props.accept.currentValue;
    }

    if (props.tags != null) {
      $scope.tags = props.tags.currentValue;
    }
  }
}

Controller.$inject = [ '$scope', 'core.services.resourceUploader' ];

export default {
  bindings: {
    accept: '<?',
    tags: '<?',
    onUpload: '&',
    onCancel: '&'
  },
  template,
  controllerAs: '$props',
  controller: Controller
};
