/**
 * @module Directives.Teams
 *
 */
import size from 'lodash/size';
import template from 'ngapp/templates/views/teams/_member_role_filter.html';

const TYPE_TRANSLATION_KEYS = {
  'coach': 'TEAMS.ROLE_FILTER.FILTER_OPTIONS.COACHES',
  'player': 'TEAMS.ROLE_FILTER.FILTER_OPTIONS.PLAYERS',
  'fan': 'TEAMS.ROLE_FILTER.FILTER_OPTIONS.FANS',
  'viewer': 'TEAMS.ROLE_FILTER.FILTER_OPTIONS.VIEWERS'
};

/**
 * @class TeamMemberRoleFilter
 *
 */
function Controller($scope, $translate) {
  $scope.values = $scope.$props.values;
  $scope.formatTypeFilterLabel = formatTypeFilterLabel;
  $scope.formatTypeOptionLabel = formatTypeOptionLabel;
  $scope.onChange = onChange;
  $scope.$props.$onChanges = $onChanges;

  function formatTypeFilterLabel(values) {
    const activeRoles = Object.keys(values || {});
    const activeRolesCount = activeRoles.length;
    if (activeRolesCount === size(values)) {
      return $translate.instant('TEAMS.ROLE_FILTER.FILTERING_ALL_TYPES');
    } else if (activeRolesCount === 0) {
      return $translate.instant('TEAMS.ROLE_FILTER.FILTERING_NO_TYPES');
    } else if (activeRolesCount === 1) {
      return $translate.instant(TYPE_TRANSLATION_KEYS[ activeRoles[0] ]);
    }
    return $translate.instant('TEAMS.ROLE_FILTER.FILTERING_MANY_TYPES', { count: activeRolesCount }, 'messageformat');
  }

  function onChange() {
    $scope.$props.onChange();
  }

  function formatTypeOptionLabel(type) {
    return $translate.instant(TYPE_TRANSLATION_KEYS[type]);
  }

  function $onChanges($props) {
    if ($props.values != null) {
      $scope.values = $props.values.currentValue;
    }
  }
}

Controller.$inject = [
  '$scope',
  '$translate'
];

export default {
  bindings: {
    values: '<',
    onChange: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
