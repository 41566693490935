/**
 * @module Core.Services
 *
 */
import assign from 'lodash/assign';

/**
 * @class $cache
 *
 */
export default function CacheProvider() {
  var defaults = {
    disabled: false,
    expiry: 180,
    trimInterval: 600
  };

  function $get($interval, $property) {
    var NULL_CACHE_VALUE;

    /**
      * @param {number} id
      * @param {object} options
      */
    function Cache(id, options) {
      this._id = id;
      this._polling = false;
      this._entries = Object.create(null);
      this._disabled = options.disabled;
      this._expiry = options.expiry;
      this._trimInterval = options.trimInterval;

      if (options.expiry < 0) {
        throw new Error('options.expiry must be greater than 0.');
      }

      if (options.trimInterval < 0) {
        throw new Error('options.trimInterval must be greater than 0.');
      }

      this.get = this.get.bind(this);
      this.info = this.info.bind(this);
      this.put = this.put.bind(this);
      this.remove = this.remove.bind(this);
      this.removeAll = this.removeAll.bind(this);

      this._poll();
    }

    /**
      * The key values currently stored.
      *
      * @type {string[]}
      */
    $property.get(Cache.prototype, 'keys', function() {
      return Object.keys(this._entries || {});
    });

    /**
      * The number of entries that are currently stored.
      *
      * @type {number}
      */
    $property.get(Cache.prototype, 'size', function () {
      return this.keys.length;
    });

    /**
      * Attempts to retrieve a cache value.
      *
      * @param {string} key
      * @returns {any}
      */
    Cache.prototype.get = function get(key) {
      if (this._entries[key] && this._entries[key].expiry > Date.now()) {
        return this._entries[key].value;
      }

      return NULL_CACHE_VALUE;
    };

    /**
      * Retrieves info about the cache.
      *
      * @returns {object}
      */
    Cache.prototype.info = function info() {
      return {
        size: this.size,
        keys: this.keys,
        id: this._id,
        disabled: this._disabled,
        trimInterval: this._trimInterval,
        expiry: this._expiry
      };
    };

    /**
      * Adds a new cache entry.
      *
      * @param {string} key
      * @param {any} value
      * @returns {any} The value thats been cached.
      */
    Cache.prototype.put = function put(key, value) {
      if (!this._disabled) {
        this._entries[key] = {
          value: value,
          expiry: Date.now() + this._expiry * 1000
        };
      }
    };

    /**
      * Removes value from cache.
      *
      * @param {string} key
      */
    Cache.prototype.remove = function remove(key) {
      if (this._entries[key]) {
        delete this._entries[key];
      }
    };

    /**
      * Removes all cache entries.
      */
    Cache.prototype.removeAll = function removeAll() {
      this._entries = Object.create(null);
    };

    /**
      * Performs cache house keeping at regular intervals.
      */
    Cache.prototype._poll = function _poll() {
      if (!this._disabled && !this._polling) {
        var self = this;

        var cancel = $interval(function() {
          if (self._disabled) {
            cancel();
          } else {
            self._trim();
          }
        }, this._trimInterval * 1000, false);

        this._polling = true;
      }
    };

    /**
      * Removes all expired cache values.
      */
    Cache.prototype._trim = function _trim() {
      var keys = this.keys;

      for (var index = 0; index < keys.length; index++) {
        var key = keys[index];

        if (this._entries[key].expiry < Date.now()) {
          this.remove(key);
        }
      }
    };

    return new Cache('services.cache', assign({}, defaults));
  }

  $get.$inject = ['$interval', 'core.$property'];

  return {
    defaults: defaults,
    $get: $get
  };
}
