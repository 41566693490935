
function factory($resource) {
  var Subscriptions = $resource({
    url: '/api/v1/subscriptions',
    name: 'subscriptions'
  });

  Subscriptions.startTrial = function startTrial() {
    var url = Subscriptions.$url('trial');

    return Subscriptions.$post(url)
      .then(function(e) {
        return e.data;
      });
  };

  return Subscriptions;
}

factory.$inject = [
  'core.services.$resource'
];

export default factory;
