/** @module resources */

function SpaceImport($resource) {
  var SpaceImport = $resource({
    url: 'api/v1/space_imports',
    name: 'spaces_import'
  });

  return SpaceImport;
}

SpaceImport.$inject = ['core.services.$resource'];

export default SpaceImport;
