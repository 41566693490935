/**
 * @module Core.Services
 *
 */
import { readAsDataUrl } from 'app/utils/file';

/**
 * @class AvatarCrop
 *
 */
function factory($document, $q) {
  var CROP_SIZE = { width: 200, height: 200 };
  var OUTPUT_MIME_TYPE = 'image/jpeg';

  function cover($image) {
    var containerRatio = CROP_SIZE.height / CROP_SIZE.width;
    var imageRatio = $image.naturalHeight / $image.naturalWidth;

    if (containerRatio > imageRatio) {
      return {
        height: CROP_SIZE.height,
        width: (CROP_SIZE.height / $image.naturalHeight) * $image.naturalWidth
      };
    }

    return {
      height: (CROP_SIZE.width / $image.naturalWidth) * $image.naturalHeight,
      width: CROP_SIZE.width
    };
  }

  function crop(blob) {
    return readAsDataUrl(blob)
      .then(function(url) {
        var $image = new Image();
        var defer = $q.defer();

        $image.onload = function() {
          defer.resolve($image);
        };

        $image.src = url;

        return defer.promise;
      })
      .then(function ($image) {
        var $canvas = $document[0].createElement('canvas');
        var context = $canvas.getContext('2d');
        var size = cover($image);

        $canvas.height = CROP_SIZE.height;
        $canvas.width = CROP_SIZE.width;

        context.drawImage(
          $image,
          (CROP_SIZE.width - size.width) / 2,
          (CROP_SIZE.height - size.height) / 2,
          size.width,
          size.height
        );

        return $q(function(resolve) {
          $canvas.toBlob(function(result) {
            resolve({
              blob: result,
              url: $canvas.toDataURL(OUTPUT_MIME_TYPE),
              type: OUTPUT_MIME_TYPE
            });
          }, OUTPUT_MIME_TYPE);
        });
      });
  }

  return crop;
}

factory.$inject = ['$document', '$q'];

export default factory;
