
import ClipboardJSFactory from './clipboardJS';
import FacebookFactory from './facebook';
import JQueryFactory from './jQuery';
import LodashFactory from './lodash';
import MomentFactory from './moment';
import S3Factory from './s3';
import StripeJSProvider from './stripe';
import { URI, URITemplate } from './uri';
import VideoJsFactory from './video';
import WavesurferFactory from './wavesurfer';

export default function(__angular) {
  __angular.module('core')
    .factory('moment', MomentFactory)
    .provider('stripeJS', StripeJSProvider);

  __angular.module('core.vendor')
    .factory('clipboardJS', ClipboardJSFactory)
    .factory('facebook', FacebookFactory)
    .factory('jQuery', JQueryFactory)
    .factory('$', JQueryFactory)
    .factory('lodash', LodashFactory)
    .factory('_', LodashFactory)
    .factory('S3', S3Factory)
    .factory('URI', URI)
    .factory('URITemplate', URITemplate)
    .factory('videojs', VideoJsFactory)
    .factory('wavesurfer', WavesurferFactory);
}
