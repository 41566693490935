/**
 * @module Filters
 *
 */
import { capitalizeWords } from 'app/utils/strings';

/**
 * Capitalize all words in statement and all words separated by dashes
 * do not capitalize underscores and lowercase all other letters
 *
 * @method capitalize
 * @return {function} the capitalize method
 */
export default function capitalize() {
  return capitalizeWords;
}
