/**
 * @module Services
 *
 */
import get from 'lodash/get';
import { run } from 'app/utils/runloop';

/**
 * @class ListLoader
 *
 */
function ListLoader(AsyncQueue) {
  return function(scope, itemsName, loadItemsPromise, loadItemsHandler) {
    const queue = new AsyncQueue();

    scope.loadList = function loadList(beforeHandlerCallback) {
      beforeHandlerCallback = beforeHandlerCallback || function(){};

      const promiseHandler = () => {
        scope.startLoading();
        return loadItemsPromise();
      };

      const doneHandler = (data) => {
        beforeHandlerCallback(data);
        loadItemsHandler(data);
        scope.finishLoading();
      };

      queue.addPromise(promiseHandler, doneHandler);
      queue.exec();
    };

    scope.isInitialLoading = function isInitialLoading() {
      return scope.isLoading() && !scope.hasData();
    };

    scope.isLoading = function isLoading() {
      return scope.loading === true;
    };

    scope.hasData = function hasData() {
      const item = get(scope, itemsName);
      return item && item.length > 0;
    };

    scope.hasNoData = function hasNoData() {
      return !scope.isLoading() && !scope.hasData();
    };

    scope.startLoading = function startLoading() {
      run(() => {
        scope.$emit('startLoading');
        scope.loading = true;
      });
    };

    scope.finishLoading = function finishLoading() {
      run(() => {
        scope.loading = false;
        scope.$emit('finishLoading');
      });
    };
  };
}

ListLoader.$inject = ['AsyncQueue'];

export default ListLoader;
