
export default function HoverClass() {
  function link(scope, element, attrs) {
    element.bind({
      mouseenter: function() {
        element.removeClass(attrs.leaveClass);
        return element.addClass(attrs.hoverClass);
      },
      mouseleave: function() {
        element.removeClass(attrs.hoverClass);
        return element.addClass(attrs.leaveClass);
      }
    });
  }

  return {
    restrict: 'A',
    link: link
  };
}
