/**
 * legacy app loader
 *
 */
import React, { Component } from 'react';
import { isAuthenticated, isPublicDomain } from 'app/utils/auth';
import { Authenticated } from 'app/components/Wrappers';
import appRunner from './main';

const bootstrap = (runner, ref) => {
  if (document.readyState === 'complete') {
    runner(ref);
  } else {
    document.addEventListener('DOMContentLoaded', runner(ref));
  }
};

class LegacyApp extends Component {
  constructor(props) {
    super(props);
    this.appRef = React.createRef();
  }

  componentDidMount() {
    bootstrap(appRunner, this.appRef);
  }

  render() {
    if (!isAuthenticated() && isPublicDomain()) {
      return (
        <div id="app-init" ref={this.appRef}></div>
      );
    } else {
      return (
        <Authenticated>
          <div id="app-init" ref={this.appRef}></div>
        </Authenticated>
      );
    }
  }
}

export default LegacyApp;

/* eslint-disable */
if (module.hot) {
  module.hot.accept("./main.js", () => {
    const appRunner = require('./main');
    bootstrap(appRunner.default);
  });
}
/* eslint-enable */
