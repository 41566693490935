/**
 * @module Directives.Posts
 *
 */

/**
 * @class ScheduleFeedPagerFactory
 *
 */
function factory(Post, createPager) {
  const settings = {
    limit: 20,
    throttle: 10000
  };

  return function create(filters) {
    function load(request) {
      const query = Object.assign({}, filters, {
        page: request.page,
        perPage: settings.perPage
      });

      return Post.scheduledPosts(query);
    }

    return createPager(load, settings);
  };
}

factory.$inject = ['core.services.Post', 'core.services.pagerFactory'];

export default factory;
