/**
 * @module Core.Services
 *
 */
import reject from 'lodash/reject';
import { isBlank } from 'app/utils';
import { fetchGet, fetchPost, templateUrl, bindAngularResource } from 'app/utils/request';

/**
 * @class Post
 *
 */
function factory($serializer, $property, $resource) {
  const Post = $resource({
    url: '/api/v2/posts',
    name: 'post',
    serializer: $serializer(function () {
      this.resource('replies', 'core.services.Post');
    })
  }, [
    $resource.acl({
      edit: 'edit',
      reply: 'reply',
      like: 'like',
      pin: 'pin',
      delete: 'delete',
      update: 'update'
    })
  ]);

  Post.perPage = 20;
  Post.repliesPerPost = 3;

  function createReply(post, fields) {
    var reply = Object.assign({ rootId: post.id }, fields);

    if (post.teamGroup != null) {
      return Object.assign(reply, { teamGroupIds: [post.teamGroup.id] });
    } else if (post.team != null) {
      return Object.assign(reply, { teamIds: [post.team.id] });
    } else if (post.space != null) {
      return Object.assign(reply, { spaceId: post.space.id });
    }

    return reply;
  }

  /**
    * Creates and saves a new team post.
    *
    * @param {string} teamId
    * @param {object} post
    * @returns {Promise}
    */
  Post.post = function post(teamId, post) {
    return fetchPost('posts', { post: { ...post, teamIds: [teamId] } }).then(model =>
      // the post saves but does not return the user avatat
      // calling fetchGet here will get the new post with the user avatar
      fetchGet(`posts/${model.id}`, {}).then(data => bindAngularResource(data, this))
    );
    // return new Post(Object.assign({}, post, { teamIds: [teamId] })).create();
  };

  /**
    * Get the list of scheduled posts.
    *
    * @param {object} params
    * @returns {Promise}
    */
  Post.scheduledPosts = function post(params = {}) {
    let queryParams = Object.assign(params, {
      'options[include]': ['posts'],
      orderBy: 'scheduled_at',
      order: 'asc',
      timezoneOffset: (new Date).getTimezoneOffset()
    });

    return Post.$get(Post.$url() + '/scheduled', queryParams);
  };

  /**
    * Creates and saves a new group post.
    *
    * @param {string} groupId
    * @param {object} post
    * @returns {Promise}
    */
  Post.postToGroup = function postToGroup(groupId, post) {
    return fetchPost('posts', { post: { ...post, teamGroupIds: [groupId] } }).then(model =>
      // the post saves but does not return the user avatat
      // calling fetchGet here will get the new post with the user avatar
      fetchGet(`posts/${model.id}`, {}).then(data => bindAngularResource(data, this))
    );
    // return new Post(Object.assign({}, post, { teamGroupIds: [groupId] })).create();
  };

  /**
    * Creates and saves a new space post.
    *
    * @param {string} spaceId
    * @param {object} post
    * @returns {Promise}
    */
  Post.postToSpace = function postToSpace(spaceId, post) {
    return fetchPost('posts', { post: { ...post, spaceId: [spaceId] } }).then(model =>
      // the post saves but does not return the user avatat
      // calling fetchGet here will get the new post with the user avatar
      fetchGet(`posts/${model.id}`, {}).then(data => bindAngularResource(data, this))
    );
    // return new Post(Object.assign({}, post, { spaceId: [spaceId] })).create();
  };

  /**
    * Deletes a post.
    *
    * @param {string} id
    * @returns {Promise}
    */
  Post.remove = function remove(id) {
    return Post.$delete(Post.$url(id));
  };

  const URL = {
    reportPost: templateUrl('posts/{id}/report')
  };

  /**
    * Reports a post.
    *
    * @param {string} id
    * @param {string} message
    * @returns {Promise}
    */
  Post.report = function report(id, message) {
    const params = { report: { message: message } };

    return fetchPost(URL.reportPost({ id: id }), params)
      .then(data => bindAngularResource(data, this));
  };

  /**
  * Updates a post.
  *
  * @param {object} post
  * @param {object} fields
  * @returns {Promise}
  */
  Post.update = function updatePost(post, fields) {
    return Object.assign(post, fields).save();
  };

  Post.queryTeamFeed = function queryTeamFeed(id, filters) {
    const query = Object.assign({
      teamId: id, 'options[include]': ['replies']
    }, filters);
    return Post.query(query);
  };

  Post.queryGroupFeed = function queryGroupFeed(groupId, filters) {
    const query = Object.assign({
      teamGroupId: groupId, 'options[include]': ['replies']
    }, filters);

    return Post.query(query);
  };

  Post.querySpaceFeed = function querySpaceFeed(id, page, limit, filters) {
    const query = Object.assign({
      spaceId: id,
      'options[include]': ['replies'],
      skip: (page - 1) * limit,
      limit: limit
    }, filters);

    return Post.query(query);
  };

  /**
    * Is this post a reply?
    *
    * @type {boolean}
    */
  $property.get(Post.prototype, 'isReply', function () {
    return !isBlank(this.rootId);
  });

  /**
    * Is this post a scheduled post?
    *
    * @type {boolean}
    */
  $property.get(Post.prototype, 'isScheduled', function () {
    if (isBlank(this.scheduledAt)) {
      return false;
    }

    const scheduledAt = new Date(this.scheduledAt);
    const now = new Date();

    return isBlank(this.targetCode) && scheduledAt > now;
  });

    /**
    * Is this post a template's schedule post?
    *
    * @type {boolean}
    */
    $property.get(Post.prototype, 'isTemplatePage', function () {
      if (!window.location.pathname.includes('space_templates')) {
        return false;
      } else {
        return true;
      }
    });

  /**
    * List of bulk post locations names
    *
    * @type {array}
    */
  $property.get(Post.prototype, 'locations', function () {
    let result = [];
    let posts = this.posts || [];
  
    posts.forEach(inBulkPost => {
      if (inBulkPost.space && inBulkPost.space.shortFullName) {
        result.push(inBulkPost.space.shortFullName);
      } else if (inBulkPost.teamGroup && inBulkPost.teamGroup.team && inBulkPost.teamGroup.name) {
        result.push(inBulkPost.teamGroup.team.name + ' / ' + inBulkPost.teamGroup.name);
      } else if (inBulkPost.team && inBulkPost.team.name) {
        result.push(inBulkPost.team.name);
      }
    });
  
    return result;
  });
  

  /**
    * Post's location URL
    *
    * @type {string}
    */
  $property.get(Post.prototype, 'locationUrl', function () {
    if (this.space) {
      return '/spaces/' + this.space.id;
    } else if (this.teamGroup) {
      return '/groups/' + this.teamGroup.team.id + '/channels/' + this.teamGroup.id;
    } else if (this.team) {
      return '/groups/' + this.team.id;
    }
  });

  /**
    * Post's location name
    *
    * @type {string}
    */
  $property.get(Post.prototype, 'locationName', function () {
    if (this.space) {
      return this.space.shortFullName;
    } else if (this.teamGroup) {
      return this.teamGroup.team.name + ' / ' + this.teamGroup.name;
    } else if (this.team) {
      return this.team.name;
    }
  });

  /**
    * Replies to specific post.
    *
    * @param {object} fields
    * @returns {Promise}
    */
  Post.prototype.reply = function replyToPost(fields) {
    var self = this;
    var replyRequest = new Post(createReply(self, fields)).create();

    replyRequest.then(function (reply) {
      self.repliesCount += 1;

      if (self.replies == null) {
        self.replies = [reply];
      } else {
        self.replies.push(reply);
      }
    });

    return replyRequest;
  };

  /**
    * Delete a reply.
    *
    * @param {string} id
    * @return {Promise}
    */
  Post.prototype.removeReply = function removeReply(id) {
    var self = this;

    return Post.remove(id)
      .then(function () {
        self.replies = reject(self.replies, { id: id });
        self.repliesCount = self.replies.length;
      });
  };

  Post.prototype.reportReply = function reportReply(id, message) {
    var self = this;

    return Post.report(id, message)
      .then(function () {
        self.replies = reject(self.replies, { id: id });
        self.repliesCount = self.replies.length;
      });
  };

  /**
    * Likes or unlikes this post.
    *
    * @returns {Promise}
    */
  Post.prototype.like = function like() {
    if (this.liked) {
      this.liked = false;
      this.likesCount -= 1;
    } else {
      this.liked = true;
      this.likesCount += 1;
    }

    if (this.liked) {
      return Post.$post(Post.$url(this.id, 'likes'));
    }

    return Post.$delete(Post.$url(this.id, 'likes'));
  };
  /**
    * Pins or unpins this post.
    *
    * @returns {Promise}
    */
  Post.prototype.toggle_pin = function toggle_pin() {
    return Post.$post(Post.$url(this.id, 'toggle_pin'), (({ pinnedTill }) => ({ pinnedTill }))(this));
  };


  $property.get(Post, 'isReply', function () {
    return !!this.rootId;
  });

  Post.prototype.isAuthor = function (userId) {
    return (this.userId === userId);
  };

  Post.prototype.isImage = function () {
    return (this.type == 'image');
  };

  Post.prototype.isNote = function () {
    return (this.type == 'note');
  };

  Post.prototype.isDatafile = function () {
    return (this.type == 'datafile');
  };

  Post.prototype.isAudio = function () {
    return (this.type == 'audio');
  };

  Post.prototype.isVideo = function () {
    return (this.type == 'video');
  };

  Post.prototype.canCopy = function () {
    if (this.post.permissions && this.post.planPermissions) {
      return (this.post.permissions.includes('copy') && this.post.planPermissions.includes('copy'));
    }

    return false;
  };


  Post.prototype.canMove = function () {
    if (this.post.permissions && this.post.planPermissions) {
      return (this.post.permissions.includes('move') && this.post.planPermissions.includes('move'));
    }

    return false;
  };

  Post.prototype.canAddToRecruit = function () {
    if (this.post.permissions && this.post.planPermissions) {
      return (this.post.permissions.includes('create_recruit_milestone') && this.post.planPermissions.includes('create_recruit_milestone'));
    }

    return false;
  };

  return Post;
}

factory.$inject = [
  'railsSerializer',
  'core.$property',
  'core.services.$resource'
];

export default factory;
