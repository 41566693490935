/**
 * @module Directives
 *
 */

/**
 * @class GroupMemberPager
 *
 */
function factory(GroupMember, createPager) {
  const settings = {
    limit: 20,
    throttle: 10000
  };

  function serialize(filters) {
    const result = {};
    if (filters && filters.roles) {
      result.type = Object.keys(filters.roles || {});
    }
    if (filters && filters.query) {
      result.userName = filters.query;
    }
    return result;
  }

  return function create(id, filters) {
    function load(request) {
      const params = Object.assign(
        {},
        serialize(filters),
        { page: request.page, perPage: request.limit }
      );
      return GroupMember.get({ teamGroupId: id }, params);
    }

    return createPager(load, settings);
  };
}

factory.$inject = [
  'core.services.TeamGroupMember',
  'core.services.pagerFactory'
];

export default factory;
