/**
 * @module Directives.Teams
 *
 */
import filter from 'lodash/filter';
import template from 'ngapp/templates/views/teams/_team_list.html';
import null_team from 'app/images/null-groups.png';

/**
 * @class TeamList
 *
 */
function TeamsListController($scope, Team) {
  $scope.onCreate = onCreate;
  $scope.onFilter = onFilter;
  $scope.teams = [];
  $scope.filteredTeams = [];
  $scope.pending = true;
  $scope.empty = false;
  $scope.query = '';
  $scope.null_team = null_team;


  Team.query()
  .then(onReceiveTeams)
  .finally(() => {
    $scope.pending = false;
  });


  function onCreate(team) {
    $scope.$props.onCreate({ team: team });
  }

  function onFilter (query) {
    $scope.query = query;
    $scope.filteredTeams = filter($scope.teams, function (team) {
      return team.name.toLowerCase().includes(query.toLowerCase());
    });
  }

  function onReceiveTeams(teams) {
    $scope.teams = teams;
    $scope.filteredTeams = teams;
    $scope.empty = $scope.filteredTeams.length === 0;
  }
}

TeamsListController.$inject = ['$scope', 'core.services.Team'];

export default {
  bindings: {
    user: '<',
    onCreate: '&'
  },
  template,
  controller: TeamsListController,
  controllerAs: '$props'
};
