/**
 * @module Directives.TeamsGroups
 *
 */
import template from 'ngapp/templates/views/teams_groups/_group_settings_modal.html';

/**
 * @class GroupSettingsModal
 *
 */
function Controller($scope, $location, $translate, toast, confirm, validators, tracking, TeamGroup) {
  $scope.errors = {};
  $scope.pending = false;
  $scope.group = $scope.$props.group;
  $scope.fields = { name: $scope.group.name, groupType: $scope.group.groupType, description: $scope.group.description };
  $scope.onRemove = onRemove;
  $scope.onSubmit = onSubmit;

  function onRemove() {
    const name = $scope.group.name;

    confirm({
      body: $translate.instant('TEAM_CHANNELS.SETTINGS.DELETE_CONFIRMATION', { name })
    })
    .then(result => {
      if (result.confirmed) {
        return remove($scope.group);
      }
    });
  }

  function onSubmit(e) {
    e.preventDefault();
    const validationResults = validate($scope.fields);

    if (validationResults.isValid) {
      $scope.pending = true;

      TeamGroup.update($scope.group, $scope.fields)
        .then(result => {
          tracking.teamGroups.updated(result, result.team);
          toast.success($translate.instant('TEAM_CHANNELS.SETTINGS.SAVE_SUCCESS_MESSAGE'));
        })
        .finally(() => {
          $scope.pending = false;
          $scope.$props.modal.close({ group: $scope.group });
        });
    }
    $scope.errors = validationResults.errors;
  }

  function remove(group) {
    $scope.pending = true;

    return TeamGroup.remove(group)
      .then(() => {
        tracking.teamGroups.deleted(group, group.team);
        toast.success(
          $translate.instant('TEAM_CHANNELS.SETTINGS.DELETE_SUCCESS_MESSAGE', { name: group.name })
        );
      })
      .finally(() => {
        $scope.pending = false;
        $location.replace();
        $location.path('/groups/' + group.team.id);
        $scope.$props.modal.close({});
      });
  }

  function validate(fields) {
    const result = { errors: {}, isValid: true };
    if (validators.isBlank(fields.name)) {
      result.errors.name = 'required';
      result.isValid = false;
    }
    return result;
  }
}

Controller.$inject = [
  '$scope',
  '$location',
  '$translate',
  'core.ui.toast',
  'confirm',
  'core.validate',
  'core.services.tracking',
  'core.services.TeamGroup'
];

export default {
  bindings: {
    group: '<',
    modal: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
