export default function duration() {
  return function (ms) {

    if (ms === 0) return;

    let seconds = Math.floor((ms / 1000) % 60);
    let minutes = Math.floor((ms / (1000 * 60)) % 60);
    let hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
    let finalTime = "";

    if(hours !== 0) {
      hours = (hours < 10) ? "0" + hours : hours;
      finalTime += hours + ":";
    }

    if(minutes === 0) {
      finalTime += minutes + ":";
    }
    else {
      minutes = (minutes < 10) ? "0" + minutes : minutes;
      finalTime += minutes + ":";
    }

    seconds = (seconds < 10) ? "0" + seconds : seconds;
    finalTime += seconds;

    return finalTime;
  };
}
