/**
 * @module Core.Vendor
 *
 */
import { StripeService } from 'app/services';

/**
 * @class Stripe
 *
 */
export default function provider() {
  return {
    "$get": function() {
      return StripeService;
    }
  };
}
