/**
 * @module Legacy
 *
 */
import './vendor';
import 'ngapp/main';
import 'ngapp/help_video';
import { isAuthenticated } from 'app/utils/auth';
import appLoader from 'ngapp/app';
import configLoader from 'ngapp/config';
import diagnosticsLoader from 'ngapp/diagnostics';
import routeLoader from 'ngapp/routes';
import coreLoader from 'ngapp/core';
import controllerLaoader from 'ngapp/controllers';
import directiveLoader from 'ngapp/directives';
import rcDirectivesLoader from './react-directives';
import filterLoader from 'ngapp/filters';
import resourceLoader from 'ngapp/resources';
import servicesLoader from 'ngapp/services';
import interceptorsLoader from 'ngapp/interceptors';
import { ngState } from 'app/utils/bridge';

const ng = window.angular;
appLoader(ng);
configLoader(ng);
diagnosticsLoader(ng);
coreLoader(ng);
filterLoader(ng);
resourceLoader(ng);
servicesLoader(ng);
interceptorsLoader(ng);
routeLoader(ng);
controllerLaoader(ng);
directiveLoader(ng);
rcDirectivesLoader(ng);

const buildDDOM = () => {
  const dot1 = document.createElement('div');
  dot1.setAttribute('class', 'loading-dot');
  const dot2 = document.createElement('div');
  dot2.setAttribute('class', 'loading-dot');
  const dot3 = document.createElement('div');
  dot3.setAttribute('class', 'loading-dot');

  const dots = document.createElement('div');
  dots.setAttribute('class', 'loading-dots');
  dots.appendChild(dot1);
  dots.appendChild(dot2);
  dots.appendChild(dot3);

  const loader = document.createElement('div');
  loader.setAttribute('class', 'app-loader');
  loader.setAttribute('ng-if', 'pending');
  loader.appendChild(dots);

  const innerDiv = document.createElement('div');
  innerDiv.setAttribute('ng-view', '');
  innerDiv.setAttribute('class', 'app-view');

  let siteWrap;
  if (isAuthenticated()) {
    siteWrap = document.createElement('site-wrap');
    siteWrap.appendChild(innerDiv);
  } else {
    siteWrap = document.createElement('public-wrap');
    siteWrap.appendChild(innerDiv);
  }

  const appContainer = document.createElement('div');
  appContainer.setAttribute('class', 'app-container ng-cloak');
  appContainer.setAttribute('ng-hide', 'pending');
  appContainer.appendChild(siteWrap);

  const ngApp = document.createElement('div');
  ngApp.setAttribute('class', 'app-main');
  ngApp.appendChild(loader);
  ngApp.appendChild(appContainer);

  return ngApp;
};

const ngAppRunner = (ref, count=0) => {
  let main = document.getElementById('app-init');
  if (!main) {
    main = ref.current;
  }

  if (main) {
    main.innerHTML = '';

    const modal = document.getElementById('legacy-modal-dialog');
    if (modal) { modal.remove(); }

    const toast = document.getElementById('angular-toast');
    if (toast) { toast.remove(); }

    const ngApp = buildDDOM();
    main.appendChild(ngApp);
    const app = ng.bootstrap(ngApp, ['app']);

    const [ state, setState ] = ngState();
    setState({ ...state, angular, app });

    window.__APP__ = { angular, app };
  } else if (count < 10) {
    setTimeout(() => ngAppRunner(ref, count+1), 300);
  }
};

export default ngAppRunner;
