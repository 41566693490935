/** @module resources */
import { fetchPost, templateUrl } from 'app/utils/request';

function SegmentFactory ($resource, $http) {
  var Segment = $resource({
    url: 'api/v1/segments',
    name: 'segment'
  });

  var URL = {
    addMember: templateUrl('segments/{id}/add_member')
  };

  Segment.prototype.addMember = function(member) {
    return fetchPost(URL.addMember({ id: this.id }), member);
  };

  Segment.prototype.spaces = function() {
    return $http.get(this.$url('spaces'));
  };

  Segment.prototype.spacesCount = function() {
    return this.spaceIds.length;
  };

  Segment.prototype.spacesCountWithTitle = function() {
    return this.spaceIds.length + " Spaces";
  };

  Segment.prototype.hasSpaces = function() {
    return this.spacesCount() > 0;
  };

  // Plan Permissions

  Segment.prototype.planCanPost = function () {
    return (this.planPermissions.indexOf('post') !== -1);
  };

  Segment.prototype.planCanInvite = function () {
    return (this.planPermissions.indexOf('invite') !== -1);
  };

  Segment.prototype.planCanManageSpaces = function () {
    return (this.planPermissions.indexOf('manage_spaces') !== -1);
  };

  Segment.prototype.planCanEdit = function () {
    return (this.planPermissions.indexOf('edit') !== -1);
  };

  Segment.prototype.planCanDelete = function () {
    return (this.planPermissions.indexOf('delete') !== -1);
  };

  return Segment;
}

SegmentFactory.$inject = ['core.services.$resource', '$http'];

export default SegmentFactory;
