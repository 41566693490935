/**
 * @module Services
 *
 */
import { errorLogger } from 'app/utils';

/**
 * @class Diagnostics
 *
 */
function DiagnosticsFactory($logger) {
  function submitDiagnostics() {
    errorLogger(
      'Diagnostic Report (Manual Submission)',
      { severity: 'info', metaData: { logs: $logger.entries() }}
    );
  }

  return {
    submitDiagnostics: submitDiagnostics
  };
}

DiagnosticsFactory.$inject = ['core.loggerFactory'];

export default DiagnosticsFactory;
