/**
 * @module Directives
 *
 */
import template from 'ngapp/templates/directives/_datafile.html';

/**
 * @class Datafile
 *
 */
export default function() {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      resource: '='
    },
    template
  };
}
