/**
 * @module Directives.Posts
 *
 */
import template from 'ngapp/templates/views/posts/_toggle_pin_modal.html';
import moment from 'moment';

/**
 * @class TogglePinModal
 *
 */
function Controller($scope) {
  $scope.post = $scope.$props.post;
  $scope.fields = { pinnedTill: null };
  $scope.pending = false;
  $scope.postTarget = postTarget;
  $scope.toggleDatePicker = toggleDatePicker;
  $scope.onCancel = onCancel;
  $scope.onSubmit = onSubmit;
  $scope.datePickerShown = false;
  $scope.minDate = moment().add(1, 'days').format('YYYY-MM-DD'); // tomorrow

  function toggleDatePicker() {
    $scope.datePickerShown = !$scope.datePickerShown;
  }

  function postTarget() {
    if ($scope.post.spaceId) { return 'Space'; }
    if ($scope.post.teamGroupId !== null) { return 'Channel'; }
    if ($scope.post.teamId !== null) { return 'Group'; }

    return 'Space Template';
  }

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onSubmit(event) {
    event.preventDefault();

    $scope.pending = false;
    $scope.$props.modal.close($scope.fields);
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    modal: '<',
    post: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
