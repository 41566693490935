/**
 * @module Directives.Posts
 *
 *
 *
 * @class ScheduledPostModalService
 *
 */
function ScheduledPostModalService($modal) {
  function showScheduledPostModal(post, location) {
    return $modal.open({
      template:  '<scheduled-post-modal post="post" location="location" modal="modal"></scheduled-post-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
        $local.post = post;
        $local.location = location;
      }]
    });
  }

  return {
    showScheduledPostModal: showScheduledPostModal
  };
}

ScheduledPostModalService.inject = ['$modal'];

export default ScheduledPostModalService;
