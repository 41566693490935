/**
 * @module Directives
 *
 */
import template from 'ngapp/templates/directives/_resource_tags.html';

/**
 * @class ResourceTags
 *
 */
export default function ResourceTags() {
  function link(scope) {
    scope.has_tags = (scope.tags && scope.tags.length > 0);
    scope.tags_string = scope.has_tags ? scope.tags.join(', ') : '';
    scope.many_tags = false;

    if (scope.tags_string.length > 20) {
      scope.many_tags = true;
      scope.tags_short_string = scope.tags_string.substring(0, 20);
      scope.tags_short_string = scope.tags_short_string.replace(/,$/, '');
    }
  }

  return {
    restrict: 'E',
    template,
    replace: true,
    scope: {
      tags: '='
    },
    link: link
  };
}
