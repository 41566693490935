/**
 * @module Controllers
 *
 */
import {
  run
} from 'app/utils/runloop';
import template from 'ngapp/templates/views/segment_spaces/_post.html';

/**
 * @class SegmentSpacesPost
 *
 */
function Controller($scope, $location, $translate, $routeParams, toast, Segment, $window) {
  $scope.isSmartList = $location.$$path.includes('smart_list');
  $scope.url = window.location.pathname;
  $scope.smartListFunction = smartListFunction();
  $scope.posts = [];
  $scope.postParams = {};

  if (!$scope.isSmartList) {
    $scope.postParams = {
      segmentIds: [$routeParams.segmentId],
    };
  } else {
    $scope.postParams = {
      smartListIds: [$routeParams.id]
    };
  }

  $scope.loading = true;

  Segment.get($routeParams.segmentId).then(segment => run(() => {
    $scope.segment = segment;
    $scope.loading = false;
  }));

  $scope.cancel = function () {
    if ($scope.isSmartList) {
      // $location.path('/spaces');
      $window.history.back();
    } else {
      $location.path('/lists/' + $routeParams.segmentId + '/spaces');
    }
  };

  $scope.$watchCollection('posts', (posts) => {
    if (posts.length === 0) return;
    $location.path('/');
    toast.success($translate.instant('CONTROLLERS.SEGMENT_SPACES_POST.POST_CREATED_SUCCESS_MESSAGE'));
  });


  function smartListFunction() {
    if ($scope.isSmartList) {
      $scope.allowSelectSpace = true;
      $scope.expanded = true;
    }
  }

}

Controller.$inject = [
  '$scope',
  '$location',
  '$translate',
  '$routeParams',
  'core.ui.toast',
  'Segment',
  '$window'
];

export default {
  controller: Controller,
  template
};
