/**
 * @module Directives.BulkPost
 *
 */
import each from 'lodash/each';
import forOwn from 'lodash/forOwn';
import map from 'lodash/map';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import remove from 'lodash/remove';

/**
 * @class BulkPostHelpers
 *
 */
function BulkPostHelpers(locationFactory) {
  function buildBulkPostParams(locations) {
    const postParams = {};

    // for angularjs version of locations dropdown
    // each(locations, location => {
    //   if (isNil(postParams[location.paramKey])) {
    //     postParams[location.paramKey] = [];
    //   }
    //   postParams[location.paramKey].push(location.id);
    // });

    // for react version of locations dropdown
    each(locations, location => {
      if (isNil(postParams[location.object])) {
        postParams[location.object] = [];
      }
     
      switch (location.object) {
        case 'smart_list':
          if (!postParams['smart_list_ids']) {
            postParams['smart_list_ids'] = [];
          }
          postParams['smart_list_ids'].push(location.id);
          break;
        case 'athlete_space':
          if (!postParams['space_id']) {
            postParams['space_id'] = [];
          }
          postParams['space_id'].push(location.id);
          break;
        case 'team':
          if (!postParams['team_ids']) {
            postParams['team_ids'] = [];
          }
          postParams['team_ids'].push(location.id);
          break;
        case 'segment':
          if (!postParams['segment_ids']) {
            postParams['segment_ids'] = [];
          }
          postParams['segment_ids'].push(location.id);
          break;
        case 'team_group':
          if (!postParams['team_group_ids']) {
            postParams['team_group_ids'] = [];
          }
          postParams['team_group_ids'].push(location.id);
          break;
        default:
          break;
      }
      // postParams[location.object].push(location.id);
    });

    return postParams;
  }

  function getSmartLists(locations) {
    return alphabetize(locations[locationFactory.smartList()]);
  }

  function getSegments(locations) {
    return alphabetize(locations[locationFactory.segment()]);
  }

  function getSpaces(locations) {
    return alphabetize(locations[locationFactory.space()], ['name', 'sport']);
  }

  function getTeamsWithGroups(locations) {
    return resolveTeamsWithGroups(locations[locationFactory.team()], locations[locationFactory.group()]);
  }

  function hasUnpostableMediaSelection(locations) {
    return isNil(find(locations, {
      canPostMedia: false
    }));
  }

  function resolveLocations(destinations) {
    const resolvedLocations = {};

    forOwn(locationFactory.kinds(), kind => {
      const destinationsByKind = filter(destinations, {
        object: kind.API_KEY
      }) || [];

      let locations = null;
      if (destinationsByKind.length > 0) {
        locations = map(destinationsByKind, destination => locationFactory.create(destination));
      }

      resolvedLocations[kind.API_KEY] = resolvePostableLocations(locations || []);
    });

    return resolvedLocations;
  }

  function resolveTeamsWithGroups(teams, groups) {
    let teamsWithGroups = null;

    if (teams.length > 0) {
      teamsWithGroups = [];
      teams = alphabetize(teams);

      each(teams, team => {
        teamsWithGroups.push(team);
        if (groups != null && groups.length > 0) {
          const teamGroups = getGroupsByTeam(team.id, groups);
          if (teamGroups.length > 0) {
            teamsWithGroups = teamsWithGroups.concat(teamGroups);
          }
        }
      });
    } else {
      teamsWithGroups = alphabetize(groups) || [];
    }

    return teamsWithGroups;
  }

  function toggleMediaPosting(locations, mediaPostPending) {
    if (mediaPostPending) {
      forEachLocation(locations, location =>
        location.setStatusByMediaPosting()
      );
    } else {
      forEachLocation(locations, location =>
        location.setStatusByDefault()
      );
    }

    return locations;
  }

  function alphabetize(locations, props) {
    props = props || ['name'];

    const actions = map(props, prop =>
      (location => location[prop].toLowerCase())
    );

    return orderBy(locations, actions, props);
  }

  function getGroupsByTeam(teamId, groups) {
    return alphabetize(filter(groups, {
      teamId: teamId
    }));
  }

  function forEachLocation(locations, action) {
    forOwn(locations, locationsByKind =>
      (each(locationsByKind, location => action(location)))
    );
  }

  function resolvePostableLocations(locations) {
    const locs = locations.slice(0);
    remove(locs, {
      pendingRemoval: true
    });
    return locs;
  }

  return {
    buildBulkPostParams: buildBulkPostParams,
    getSegments: getSegments,
    getSmartLists: getSmartLists,
    getSpaces: getSpaces,
    getTeamsWithGroups: getTeamsWithGroups,
    hasUnpostableMediaSelection: hasUnpostableMediaSelection,
    resolveLocations: resolveLocations,
    toggleMediaPosting: toggleMediaPosting
  };
}

BulkPostHelpers.$inject = ['bulk_post.bulkPostLocationFactory'];

export default BulkPostHelpers;
