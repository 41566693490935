/**
 * @module Directives
 *
 */
import template from 'ngapp/templates/directives/_loading.html';

/**
 * @class Loading
 *
 */
function Controller() {}

export default {
  controller: Controller,
  template
};
