/**
 * @module Angular.Filters
 *
 */
import capitalize from './capitalize';
import duration from './duration';
import elapsed from './elapsed';
import highlight from './highlight_match';
import isEmpty from './is_empty';
import mapFilter from './map';
import sanatize from './sanatize';
import tags from './tags';
import { formatText, unsafe, escapeHTML, simpleFormat, linkify, joinBy, normalizeTag, truncateTags } from './text';
import toTimezoneOffsetString from './to_timezone_offset_string';
import truncate from './truncate';

export default function(__angular) {
  __angular.module('filters')
    .filter('capitalize', capitalize)
    .filter('elapsed', elapsed)
    .filter('highlight', highlight)
    .filter('isEmpty', isEmpty)
    .filter('map', mapFilter)
    .filter('sanitize', sanatize)
    .filter('tags', tags)
    .filter('formatText', formatText)
    .filter('unsafe', unsafe)
    .filter('escapeHTML', escapeHTML)
    .filter('simpleFormat', simpleFormat)
    .filter('linkify', linkify)
    .filter('joinBy', joinBy)
    .filter('normalizeTag', normalizeTag)
    .filter('truncateTags', truncateTags)
    .filter('toTimezoneOffsetString', toTimezoneOffsetString)
    .filter('truncate', truncate)
    .filter('duration', duration);
}
