/**
 * @module Directives
 *
 */
import { AuthService } from 'app/services';
import { run } from 'app/utils/runloop';
import { featureIsEnabled } from 'app/utils/features';
import { localSet } from 'app/utils/LocalStorage';
// import { localGet, localSet } from 'app/utils/LocalStorage';
import { openIntercom } from 'app/utils/intercom';
import template from 'ngapp/templates/views/site_wrap/_site_wrap.html';
import edufii_logo from 'app/images/Logo.svg';
import edufii_logo_dark from 'app/images/Logo_dark.svg';
import arrow_icon from 'app/images/arrow-left.png';
import pro_icon from 'app/images/pro.svg';
import config from 'config/environment';

const SIDE_BAR_PROPPED_KEY = 'side-bar-propped';

/**
 * @class SiteWrap
 *
 */
function Controller($root, $scope, $, $element, $translate, $location, paywallModal, planFeature, Organization) {
  const $container = $element.find('.site-content')[0];

  $scope.query = '';
  $scope.user = $root.user;
  $scope.organizations = [];
  $scope.sideBarExpanded = false;
  // $scope.sideBarPropped = localGet(SIDE_BAR_PROPPED_KEY, true);
  $scope.sideBarPropped = true;
  $scope.shouldShowTrialText = shouldShowTrialText;
  $scope.trialWarningClass = trialWarningClass;
  $scope.trialText = trialText;
  $scope.supportUrl = config.SUPPORT_URL;
  $scope.defaultTranslationData = { basePlanName: 'CoachNow PRO' };
  $scope.onSideBarExpandClick = onSideBarExpandClick;
  $scope.onSideBarPropClick = onSideBarPropClick;
  $scope.onQuery = onQuery;
  $scope.onUpgradeClick = onUpgradeClick;
  $scope.onScheduledPostsClick = onScheduledPostsClick;
  $scope.canSchedulePosts = canSchedulePosts;
  $scope.onBenefits = onBenefits;
  $scope.onGetPaid = onGetPaid;
  $scope.edufii_logo = edufii_logo;
  $scope.edufii_logo_dark = edufii_logo_dark;
  $scope.showBenefits = false;
  $scope.currentYear = config.CURRENT_YEAR;
  $scope.arrow_icon = arrow_icon;
  $scope.pro_icon = pro_icon;

  $scope.logout = () => {
    AuthService.invalidate();
  };

  $scope.contactSales = (event) => {
    if (event != null) {
      event.stopPropagation();
    }
    openIntercom();
  };

  $root.$on('$routeChangeSuccess', () => {
    run(() => {
      $scope.sideBarExpanded = false;
      $scope.query = $location.search().q;
      $container.scrollTop = 0;
    });
  });

  $root.$on('userUpdated', (event, user) => run(() => {
    $scope.user = user;
    if (featureIsEnabled('benefitsPage')) {
      $scope.showBenefits = user.hasCoachPlusSubscription && !user.hasTrialSubscription;
    }
  }));

  Organization.get().then(orgs => {
    run(() => {
      $scope.organizations = orgs;
    });
  });

  $scope.debounce = null;
  $scope.profileClick = (evt) => {
    if ($scope.debounce == null) {
      setTimeout(() => {
        const dropdown = $(evt.currentTarget).parent().parent().find('.dropdown > .dropdown-toggle');
        if (dropdown.length) {
          $scope.debounce = setTimeout(() => $scope.debounce = null, 300);
          dropdown.trigger('click');
        }
      }, 1);
    }
  };

  $scope.hasActivePlusSubscription = () => {
    if ($scope.user != null) {
      const plan = $scope.user.plan;

      if (plan != null) {
        return !$scope.user.hasTrialSubscription &&
          plan.planCode.startsWith("coach+") &&
          $scope.user.subscription.status === 'active';
      }
    }
    return false;
  };

  $scope.hasActiveProSubscription = () => {
    if ($scope.user != null) {
      const plan = $scope.user.plan;

      if (plan != null) {
        return !$scope.user.hasTrialSubscription &&
          plan.planCode.startsWith("coachpro+") &&
          $scope.user.subscription.status === 'active';
      }
    }
    return false;
  };

  $scope.hasOrganization = () => {
    if ($scope.user != null) {
      return (
        $scope.user.traits &&
        $scope.user.traits.company &&
        $scope.user.traits.company.name
      );
    }
    return false;
  };

  $scope.hasExpiredSubscription = () => {
    if ($scope.user != null) {
      return (
        !$scope.user.hasCoachPlusSubscription &&
        !$scope.user.hasTrialSubscription &&
        $scope.user.subscription.status === 'expired'
      );
    }
    return false;
  };

  $scope.isDefaultAvatar = () => {
    if ($scope.user
      && $scope.user.avatar
      && $scope.user.avatar.includes('default')
    ) {
      return true;
    }

    return false;
  };

  $scope.getNameInitials = () => {
    const separateNames = $scope.user.name.split(" ");
    const prepareInitials = separateNames[0][0] + separateNames[separateNames.length - 1][0];
    return prepareInitials.toUpperCase();
  };

  function shouldShowTrialText(user) {
    if (user != null) {
      const isTrialExpired = !user.hasCoachPlusSubscription
        && user.subscription.status == 'trial_expired';

      return user.hasTrialSubscription || isTrialExpired;
    }
    return false;
  }

  function trialWarningClass(user) {
    return user &&
      ((user.hasTrialSubscription &&
        user.plan.trialDaysLeft <= 3) ||
        user.subscription.status === 'trial_expired');
  }

  function trialText(user) {
    if (user.hasTrialSubscription) {
      return $translate.instant('SITE_SIDEBAR.DAYS_LEFT', {
        days: user.plan.trialDaysLeft
      }, 'messageformat');
    } else {
      return $translate.instant('SITE_SIDEBAR.TRIAL_EXPIRED');
    }
  }

  function onSideBarExpandClick() {
    $scope.sideBarExpanded = !$scope.sideBarExpanded;
  }

  function onSideBarPropClick() {
    const propped = !$scope.sideBarPropped;
    $scope.sideBarPropped = propped;
    localSet(SIDE_BAR_PROPPED_KEY, propped);
  }

  function onUpgradeClick(event) {
    if (event != null) {
      event.stopPropagation();
    }

    if ($scope.user.plan.planCode.startsWith("coachpro+")) {
      paywallModal.proUpgrade();
    } else {
      paywallModal.explicit();
    }
  }

  function canSchedulePosts() {
    if ($scope.user) {
      return planFeature.user.canSchedulePosts($scope.user);
    } else {
      return false;
    }
  }

  function onScheduledPostsClick(event) {
    if (event != null) {
      event.stopPropagation();
    }

    paywallModal.proUpgrade();
  }

  function onBenefits() {
    window.location = '/benefits';
  }

  function onGetPaid() {
    window.open('https://coachnow.io/coachnowpartners', '_blank');
  }

  function onQuery(e) {
    if (e != null) {
      e.preventDefault();
    }
    $location.path('search').search('q', $scope.query);
  }
}

Controller.$inject = [
  '$rootScope',
  '$scope',
  '$',
  '$element',
  '$translate',
  '$location',
  'paywallModal',
  'core.services.planFeature',
  'Organization'
];

export default {
  bindings: {
    post: '<',
    onRemove: '&'
  },
  template,
  controller: Controller,
  transclude: true
};
