
function factory(createTeamCachePolicy, Team) {
  return function create() {
    var teamCachePolicy = createTeamCachePolicy(Team);

    function onAccept() {
      teamCachePolicy.onAddMember();
    }

    return {
      onAccept: onAccept
    };
  };
}

factory.$inject = [
  'core.services.cache.$teamCachePolicyFactory',
  'core.services.Team'
];

export default factory;
