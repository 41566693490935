
import template from 'ngapp/templates/views/teams/_team_created_modal.html';

function Controller($scope) {
  $scope.team = $scope.$props.team;
  $scope.onCancel = onCancel;
  $scope.onInviteClicked = onInviteClicked;

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onInviteClicked() {
    $scope.$props.modal.close({
      team: $scope.team
    });
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    modal: '<',
    team: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
