/**
 * @module Controllers
 *
 */
// import { uniq } from 'app/utils/array';
// import { run, digest } from 'app/utils/runloop';
import template from 'ngapp/templates/views/resources/_new.html';

/**
 * @class ResourceNew
 *
 */
// function ResourceNewController($scope, $translate, $location, Resource, toast, media) {
//   $scope.resource_id = null;
//   $scope.resource_type = 'image';
//   $scope.processing = false;
//   $scope.uploading = false;
//   $scope.tags = [];
//   $scope.inputTags = [];
//   $scope.previewCSS = null;
//   $scope.previewPlaceholderClassName = null;
//   $scope.onUpload = onUpload;
//   $scope.onClearClick = onClearClick;

//   $scope.add = function () {
//     $scope.processing = true;
//     $scope.uploading = true;
//     Resource.get($scope.resource_id).then(resource => {
//       resource.tmp = false;
//       resource.tags = $scope.tags;
//       $scope.resource_type = resource.type;
//       resource.update()
//         .then(() => {
//           toast.success($translate.instant('CONTROLLERS.RESOURCE_NEW.ADD_SUCCESS_MESSAGE'));
//           $location.path('/resources');
//           digest();
//         })
//         .catch(() => toast.danger($translate.instant('CONTROLLERS.RESOURCE_NEW.ADD_ERROR_MESSAGE')))
//         .finally(() => run(() => {
//           $scope.processing = false;
//           $scope.uploading = false;
//         }));
//     });
//   };

//   $scope.onUpdateTags = (tags) => {
//     run(() => {
//       tags = tags != null ? tags : [];
//       const values = tags.map(item => item.name);
//       const curTagsAdded = ($scope.inputTags || []).slice(0).filter(item => values.indexOf(item) !== -1);
//       const currentTags = ($scope.tags || []).slice(0).filter(item => values.indexOf(item) !== -1);
//       const newTags = (values || []).slice(0).filter(item => currentTags.indexOf(item) === -1);

//       $scope.inputTags = uniq([ ...curTagsAdded, ...newTags ]);
//       $scope.tags = uniq([ ...currentTags, ...newTags ]);
//     });
//   };

//   function onUpload(resource) {
//     run(() => {
//       $scope.resource_id = resource.id;
//       $scope.previewPlaceholderClassName = media.getSpriteClassName(resource.source);
//       $scope.previewCSS = null;

//       const previewUrl = media.getPreviewUrl(resource);

//       if(previewUrl != null) {
//         $scope.previewCSS = { 'background-image': 'url(' + media.getPreviewUrl(resource) + ')'};
//       }
//     });
//   }

//   function onClearClick() {
//     run(() => {
//       $scope.resource_id = null;
//       $scope.previewCSS = null;
//       $scope.previewPlaceholderClassName = null;
//     });
//   }
// }

// ResourceNewController.$inject = [
//   '$scope',
//   '$translate',
//   '$location',
//   'Resource',
//   'core.ui.toast',
//   'mediaHelpers'
// ];

export default {
  // controller: ResourceNewController,
  template
};
