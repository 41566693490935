/**
 * @module Directives
 *
 */
import template from 'ngapp/templates/directives/_file_picker.html';

/**
 * @class FilePicker
 *
 */
function Controller($scope, $element) {
  $scope.accept = '';
  $scope.theme = '';
  $scope.disabled = false;
  $scope.$props.$onChanges = $onChanges;
  $scope.$props.$onDestroy = $onDestroy;

  var $input = $element.find('input[type="file"]');

  $element.addClass('file-picker');
  $input.on('change', onChange);

  function onChange(e) {
    $scope.$props.onChange({ files: e.target.files });
  }

  function $onDestroy() {
    $input.off('change', onChange);
  }

  function $onChanges(props) {
    if (props.accept != null) {
      $scope.accept = props.accept.currentValue;
    }

    if (props.theme != null) {
      $scope.theme = props.theme.currentValue;
    }

    if (props.disabled != null) {
      $scope.disabled = props.disabled.currentValue;
    }
  }
}

Controller.$inject = ['$scope', '$element'];

export default {
  bindings: {
    accept: '@',
    theme: '@',
    disabled: '<',
    onChange: '&',
  },
  template,
  controller: Controller,
  controllerAs: '$props',
  transclude: true
};
