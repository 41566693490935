
export default function Localtime() {
  function link(scope, element, attrs) {
    attrs.$observe('title', function(value) {
      if (!value) {
        return;
      }

      element.localtime();
    });
  }

  return {
    restrict: 'C',
    link: link
  };
}

