
import { digest } from 'app/utils/runloop';

function LinkTo($location) {
  function link($scope, $element, $attributes) {
    $element.on('click', function () {
      var path = $scope.$eval($attributes.linkTo);

      if (path != null) {
        $location.path(path);
        digest();
      }
    });
  }

  return {
    restrict: 'A',
    scope: false,
    link: link
  };
}

LinkTo.$inject = ['$location'];

export default LinkTo;
