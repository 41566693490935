/**
 * @module Controllers.Billing
 *
 */
import range from 'lodash/range';
import { digest } from 'app/utils/runloop';

/***/
const CURRENT_TIME = new Date();

/**
 * @class RecurlyBillingInfo
 *
 */
function Controller($scope, $location, $translate, ISO3166, toast, AuthService, UserBillingInfo) {
  $scope.processing = false;
  $scope.billingInfoYears = range(CURRENT_TIME.getFullYear(), CURRENT_TIME.getFullYear() + 10);
  $scope.countryCodes = ISO3166.countryCodes;

  $scope.updateBillingInfo = function() {
    $scope.processing = true;

    $scope.billingInfo
      .$put('/api/v1/account/billing_info')
      .then(() => {
        $scope.validationErrors = null;
        $scope.processing = false;
        $scope.billingInfo.number = '';
        $scope.billingInfo.verificationValue = '';
        toast.success($translate.instant('CONTROLLERS.ACCOUNT_BILLING_INFO.UPDATE_SUCCESS_MESSAGE'));
        $location.path('/account/billing_info');
      }).catch((error) => {
        $scope.validationErrors = error.data.errors;
        $scope.processing = false;
      });
  };

  AuthService
    .getCurrentUser({'options[include]': ['billing_info']})
    .then(user => digest(() => $scope.user = user));

  UserBillingInfo.get().then(billingInfo =>
    digest(() => $scope.billingInfo = billingInfo)
  );
}

Controller.$inject = [
  '$scope',
  '$location',
  '$translate',
  'ISO3166',
  'core.ui.toast',
  'Auth',
  'UserBillingInfo'
];

export default {
  name: 'RecurlyBillingInfoCtrl',
  controller: Controller
};
