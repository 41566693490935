/**
 * @module Core.Services.Cache
 *
 */

/**
 * @class $team_policy
 *
 */
function factory($cache) {
  return function create(Team) {
    function onRemoveMember(id) {
      $cache.remove(Team.$url());
      $cache.remove(Team.$url(id));
    }

    function onAddMember() {
      $cache.remove(Team.$url());
    }

    function onCreate() {
      $cache.remove(Team.$url());
    }

    function onRemove(id) {
      $cache.remove(Team.$url());
      $cache.remove(Team.$url(id));
    }

    function onUpdate(id) {
      $cache.remove(Team.$url(id));
    }

    return {
      onAddMember: onAddMember,
      onRemoveMember: onRemoveMember,
      onCreate: onCreate,
      onRemove: onRemove,
      onUpdate: onUpdate
    };
  };
}

factory.$inject = ['core.services.$cache'];

export default factory;
