/**
 * @module Directives.BulkPost.Locations
 *
 */
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import map from 'lodash/map';

/**
 * @class $bulkPostLocation
 *
 */
function $bulkPostLocation($property, planFeature) {
  function BulkPostLocation(destination) {
    this._destination = destination;
    this._paramKey = this._setParamKey(destination);
    this._status = null;

    this.setStatusByDefault();
  }

  BulkPostLocation.KINDS = {
    SMART_LIST: {
      API_KEY: 'smart_list',
      PARAM_KEY: 'smartListIds'
    },
    SEGMENT: {
      API_KEY: 'segment',
      PARAM_KEY: 'segmentIds'
    },
    SPACE: {
      API_KEY: 'athlete_space',
      PARAM_KEY: 'spaceId'
    },
    TEAM: {
      API_KEY: 'team',
      PARAM_KEY: 'teamIds'
    },
    GROUP: {
      API_KEY: 'team_group',
      PARAM_KEY: 'teamGroupIds'
    }
  };

  BulkPostLocation.MODAL_KINDS = {
    POST_MEDIA: 'postMedia',
    POST_MEDIA_OTHER_OWNER: 'postMediaOtherOwner',
    SEGMENTS: 'segments',
    SPACES: 'spaceLimitReadOnly',
    SMART_LIST: ''
  };

  BulkPostLocation.STATUSES = {
    PENDING_REMOVAL: 'pendingRemoval',
    POST_LOCKED: 'postLocked',
    POST_MEDIA_LOCKED: 'postMediaLocked',
    UNLOCKED: 'unlocked'
  };

  $property.get(BulkPostLocation.prototype, 'apiKey', function () {
    return this._destination.object;
  });

  $property.get(BulkPostLocation.prototype, 'canPostMedia', function () {
    return planFeature.hasPlanPermission(this._destination, 'post_media');
  });

  $property.get(BulkPostLocation.prototype, 'canPost', function () {
    return planFeature.hasPlanPermission(this._destination, 'post');
  });

  $property.get(BulkPostLocation.prototype, 'id', function () {
    return this._destination.id;
  });

  $property.get(BulkPostLocation.prototype, 'isTeam', function () {
    return this.apiKey === BulkPostLocation.KINDS.TEAM.API_KEY;
  });

  $property.get(BulkPostLocation.prototype, 'locked', function () {
    return this.postLocked || this.postMediaLocked;
  });

  $property.get(BulkPostLocation.prototype, 'name', function () {
    return this._destination.name;
  });

  $property.get(BulkPostLocation.prototype, 'paramKey', function () {
    return this._paramKey;
  });

  $property.get(BulkPostLocation.prototype, 'paywallModalName', function () {
    var result = null;

    if (this.postMediaLocked) {
      result = BulkPostLocation.MODAL_KINDS.POST_MEDIA;
    }

    return result;
  });

  $property.get(BulkPostLocation.prototype, 'pendingRemoval', function () {
    return this._status === BulkPostLocation.STATUSES.PENDING_REMOVAL;
  });

  $property.get(BulkPostLocation.prototype, 'postLocked', function () {
    return this._status === BulkPostLocation.STATUSES.POST_LOCKED;
  });

  $property.get(BulkPostLocation.prototype, 'postMediaLocked', function () {
    return this._status === BulkPostLocation.STATUSES.POST_MEDIA_LOCKED;
  });

  BulkPostLocation.prototype.setStatusByDefault = function () {
    if (planFeature.hasPermission(this._destination, 'post')) {
      if (planFeature.hasPlanPermission(this._destination, 'post')) {
        this._status = BulkPostLocation.STATUSES.UNLOCKED;
      } else {
        this._status = BulkPostLocation.STATUSES.POST_LOCKED;
      }
    } else {
      this._status = BulkPostLocation.STATUSES.PENDING_REMOVAL;
    }
  };

  BulkPostLocation.prototype.setStatusByMediaPosting = function () {
    if (this.canPostMedia) {
      this._status = BulkPostLocation.STATUSES.UNLOCKED;
    } else {
      this._status = BulkPostLocation.STATUSES.POST_MEDIA_LOCKED;
    }
  };

  BulkPostLocation.prototype._setParamKey = function (destination) {
    var result = find(BulkPostLocation.KINDS, {
      API_KEY: destination.object
    });

    if (isNil(result)) {
      var paramKeys = map(BulkPostLocation.KINDS, 'PARAM_KEY').join(', ');
      throw new Error('The destination object kind of \'' + destination.object +
        '\' does not map to a bulk post param key: ' + paramKeys + '.');
    }

    return result.PARAM_KEY;
  };

  return BulkPostLocation;
}

$bulkPostLocation.$inject = ['core.$property', 'core.services.planFeature'];

export default $bulkPostLocation;
