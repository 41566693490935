/**
 * @module Directives.Activity
 *
 */
import each from 'lodash/each';
import template from 'ngapp/templates/views/activity/_notification_list.html';

/**
 * @class NotificationList
 *
 */
function Controller($scope, time) {
  $scope.notifications = partition([]);
  $scope.paging = false;
  $scope.onRead = onRead;
  $scope.onScroll = onScroll;
  $scope.$props.$onChanges = $onChanges;

  function partition(notifications) {
    var buckets = {
      today: [],
      week: [],
      month: [],
      rest: []
    };

    each(notifications, function (n) {
      var span = time.span(n.createdAt, time.truncate(Date.now()));

      if (span.months > 0) {
        buckets.rest.push(n);
      } else if (span.days > 7) {
        buckets.month.push(n);
      } else if (span.days > 0) {
        buckets.week.push(n);
      } else {
        buckets.today.push(n);
      }
    });

    return buckets;
  }

  function onRead(notification) {
    $scope.$props.onRead({ notification: notification });
  }

  function onScroll() {
    $scope.$props.onScroll();
  }

  function $onChanges(props) {
    if (props.notifications != null) {
      $scope.notifications = partition(props.notifications.currentValue);
    }

    if (props.paging != null) {
      $scope.paging = props.paging.currentValue;
    }
  }
}

Controller.$inject = ['$scope', 'core.time'];

export default {
  bindings: {
    notifications: '<',
    paging: '<',
    onRead: '&',
    onScroll: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
