/** @module directives/activity */
import template from 'ngapp/templates/views/activity/_notification.html';

/**
 * @class Notification
 *
 */
function Controller($scope, $translate, media) {
  $scope.notification = null;
  $scope.createThumbnailCSS = createThumbnailCSS;
  $scope.getMediaIconClassName = getMediaIconClassName;
  $scope.formatPostSummary = formatPostSummary;
  $scope.formatReplySummary = formatReplySummary;
  $scope.formatLocation = formatLocation;
  $scope.formatLikeSummary = formatLikeSummary;
  $scope.$props.$onChanges = $onChanges;

  function createThumbnailCSS(notification) {
    if (notification.meta != null && notification.meta.post != null) {
      if (notification.meta.post.mediaThumbnail != null) {
        return { 'background-image': 'url(' + notification.meta.post.mediaThumbnail + ')' };
      }
    }

    return {};
  }

  function getMediaIconClassName(notification) {
    if (notification.meta != null && notification.meta.post != null) {
      if (notification.meta.post.type != null) {
        return ['ico', media.getIconClassName(notification.meta.post.type)];
      }
    }

    return [];
  }

  function formatLocation(notification) {
    var meta = notification.meta;

    if (meta.teamGroup != null) {
      var team = meta.team || {};

      return [
        team.teamName || team.name,
        meta.teamGroup.teamGroupName || meta.teamGroup.name
      ].join(' / ');
    } else if (meta.team != null) {
      return [
        meta.team.teamName || meta.team.name,
        $translate.instant('ACTIVITY.NOTIFICATION.ALL_CHANNELS')
      ].join(' / ');
    } else if (meta.space != null) {
      return [meta.space.athleteName, meta.space.sport].join(' / ');
    }

    return '';
  }

  function formatPostSummary(notification) {
    var post = notification.meta.post;
    var values = {
      message: post.headline || '',
      poster: post.authorName
    };

    switch (post.type) {
      case 'image':
        if (values.message !== '') {
          return $translate.instant('ACTIVITY.NOTIFICATION.POST_CREATED.IMAGE', values);
        }

        return $translate.instant('ACTIVITY.NOTIFICATION.POST_CREATED.IMAGE_NO_MESSAGE', values);
      case 'video':
        if (values.message !== '') {
          return $translate.instant('ACTIVITY.NOTIFICATION.POST_CREATED.VIDEO', values);
        }

        return $translate.instant('ACTIVITY.NOTIFICATION.POST_CREATED.VIDEO_NO_MESSAGE', values);
      case 'audio':
        if (values.message !== '') {
          return $translate.instant('ACTIVITY.NOTIFICATION.POST_CREATED.AUDIO', values);
        }

        return $translate.instant('ACTIVITY.NOTIFICATION.POST_CREATED.AUDIO_NO_MESSAGE', values);
      case 'datafile':
        if (values.message !== '') {
          return $translate.instant('ACTIVITY.NOTIFICATION.POST_CREATED.DOCUMENT', values);
        }

        return $translate.instant('ACTIVITY.NOTIFICATION.POST_CREATED.DOCUMENT_NO_MESSAGE', values);
    }

    return $translate.instant('ACTIVITY.NOTIFICATION.POST_CREATED.NOTE', values);
  }

  function formatReplySummary(notification) {
    var post = notification.meta.post;
    var values = {
      message: post.headline || '',
      poster: notification.initiator.nameShort
    };

    switch (post.type) {
      case 'image':
        if (values.message !== '') {
          return $translate.instant('ACTIVITY.NOTIFICATION.REPLY_CREATED.IMAGE', values);
        }

        return $translate.instant('ACTIVITY.NOTIFICATION.REPLY_CREATED.IMAGE_NO_MESSAGE', values);
      case 'video':
        if (values.message !== '') {
          return $translate.instant('ACTIVITY.NOTIFICATION.REPLY_CREATED.VIDEO', values);
        }

        return $translate.instant('ACTIVITY.NOTIFICATION.REPLY_CREATED.VIDEO_NO_MESSAGE', values);
      case 'audio':
        if (values.message !== '') {
          return $translate.instant('ACTIVITY.NOTIFICATION.REPLY_CREATED.AUDIO', values);
        }

        return $translate.instant('ACTIVITY.NOTIFICATION.REPLY_CREATED.AUDIO_NO_MESSAGE', values);
      case 'datafile':
        if (values.message !== '') {
          return $translate.instant('ACTIVITY.NOTIFICATION.REPLY_CREATED.DOCUMENT', values);
        }

        return $translate.instant('ACTIVITY.NOTIFICATION.REPLY_CREATED.DOCUMENT_NO_MESSAGE', values);
      default:
        if (values.message !== '') {
          return $translate.instant('ACTIVITY.NOTIFICATION.REPLY_CREATED.NOTE', values);
        }

        return $translate.instant('ACTIVITY.NOTIFICATION.REPLY_CREATED.NOTE_NO_MESSAGE', values);
    }
  }

  function formatLikeSummary(notification) {
    var post = notification.meta.post;
    var initiator = notification.initiator;

    switch (post.type) {
      case 'image':
        return $translate.instant('ACTIVITY.NOTIFICATION.LIKE_CREATED.IMAGE', { name: initiator.nameShort });
      case 'video':
        return $translate.instant('ACTIVITY.NOTIFICATION.LIKE_CREATED.VIDEO', { name: initiator.nameShort });
      case 'audio':
        return $translate.instant('ACTIVITY.NOTIFICATION.LIKE_CREATED.AUDIO', { name: initiator.nameShort });
      case 'datafile':
        return $translate.instant('ACTIVITY.NOTIFICATION.LIKE_CREATED.DOCUMENT', { name: initiator.nameShort });
    }

    return $translate.instant('ACTIVITY.NOTIFICATION.LIKE_CREATED.NOTE', { name: initiator.nameShort });
  }

  function $onChanges(props) {
    if (props.notification != null) {
      $scope.notification = props.notification.currentValue;
    }
  }
}

Controller.$inject = ['$scope', '$translate', 'mediaHelpers'];

export default {
  bindings: {
    notification: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};

