/**
 * @module Core.UI.Dropdown
 *
 */

/**
 * @module $animate
 *
 */
function factory($q, $css) {
  const invisible = {
    opacity: 0,
    transform: 'scale(0)'
  };

  const visible = {
    opacity: 1,
    transform: 'scale(1)'
  };

  const baseStyles = {
    duration: 0.125,
    easing: 'ease-in'
  };

  function open($dropdown) {
    const $menu = $dropdown.find('.dropdown-menu');
    const animation = $q.resolve().then(() =>
      $css($menu, { from: invisible, to: visible, ...baseStyles, }).start()
    );
    $dropdown.addClass('open');
    $menu.css({ opacity: '0' });
    return animation;
  }

  function close($dropdown) {
    const $menu = $dropdown.find('.dropdown-menu');
    return $q.resolve()
      .then(() =>$css($menu, { from: visible, to: invisible, ...baseStyles }).start())
      .then(() => $dropdown.removeClass('open'));
  }

  return { open: open, close: close };
}

factory.$inject = ['$q', '$animateCss'];

export default factory;
