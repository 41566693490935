/**
 * @module Angular.Resources
 *
 */
import FeedFilter from './feed_filter';
import Invitation from './invitation';
import Organization from './organization';
import Post from './post';
import PostSpace from './post_space';
import Resource from './resource';
import ResourcePagerFactory from './resource_pager_factory';
import ResourceTag from './resource_tag';
import Search from './search';
import Segment from './segment';
import SmartList from './smart_list';
import Space from './space';
import SpaceImport from './space_import';
import SpaceInvitation from './space_invitation';
import SpaceMember from './space_member';
import SpaceTemplate from './space_template';
import SpaceUserSettings from './space_user_settings';
import Sports from './sports';
import Subscriptions from './subscriptions';
import Tag from './tag';
import TeamSpace from './team_space';
import Timezone from './timezone';
import UserBillingInfo from './user_billing_info';
import UserSettings from './user_settings';
import UserTag from './user_tag';
import PostViews from './post_views';


export default function (__angular) {
  __angular.module('services')
    .factory('Post', Post)
    .factory('FeedFilter', FeedFilter)
    .factory('Invitation', Invitation)
    .factory('Organization', Organization)
    .factory('PostSpace', PostSpace)
    .factory('Resource', Resource)
    .factory('resourcePagerFactory', ResourcePagerFactory)
    .factory('ResourceTag', ResourceTag)
    .factory('Search', Search)
    .factory('Segment', Segment)
    .factory('SmartList', SmartList)
    .factory('Space', Space)
    .factory('SpaceImport', SpaceImport)
    .factory('SpaceMember', SpaceMember)
    .factory('SpaceInvitation', SpaceInvitation)
    .factory('SpaceTemplate', SpaceTemplate)
    .factory('SpaceUserSettings', SpaceUserSettings)
    .factory('Sports', Sports)
    .factory('Subscriptions', Subscriptions)
    .factory('Tag', Tag)
    .factory('TeamSpace', TeamSpace)
    .factory('Timezone', Timezone)
    .factory('UserBillingInfo', UserBillingInfo)
    .factory('UserSettings', UserSettings)
    .factory('UserTag', UserTag)
    .factory('PostViews', PostViews);
}
