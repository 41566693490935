
import URI from 'urijs/src/URI';
import URITemplate from 'urijs/src/URITemplate';

function UrlFactory() {
  /**
    * Builds a url from a path and query object without interpolation.
    *
    * @param {string} path
    * @param {object} query
    * @returns {string}
    */
  function build(path, query) {
    var url = new URI(path);

    if (query != null) {
      url.addQuery(query);
    }

    return url.toString();
  }

  /**
    * Creates a url template function that will expand the url template with the provided values.
    *
    * @param {string} path
    * @returns {function}
    */
  function template(url) {
    var $template = new URITemplate(url);

    return function expand(values) {
      return $template.expand(values || {});
    };
  }

  return {
    build: build,
    template: template
  };
}

UrlFactory.$inject = [];

export default UrlFactory;
