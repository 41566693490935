
import concat from 'lodash/concat';
import slice from 'lodash/slice';

function filter() {
  return function map(value, fn) {
    if (typeof fn === 'function') {
      return fn.apply(null, concat([value], slice(arguments, 2)));
    }

    return value;
  };
}

filter.$inject = [];

export default filter;
