/**
 * @module Interceptors
 *
 */

/**
 * @class http402ErrorInterceptor
 *
 */
function http402ErrorInterceptor($root, $q) {
  return {
    responseError: function(response) {
      if (response.status === 402) {
        $root.$broadcast('upgradePlus');
        // $location.path('/upgrade-plus');
        // $location.replace();
      }
      return $q.reject(response);
    }
  };
}

http402ErrorInterceptor.$inject = ['$rootScope', '$q'];

export default http402ErrorInterceptor;
