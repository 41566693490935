/** @module legacy/app/directives/activity */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/activity/_activity_wrap.html';

/**
 * @class ActivityWrap
 *
 */
function Controller($rootScope, $scope) {
  $scope.route = $scope.$props.route;
  $scope.pending = false;
  $scope.counts = { invitations: 0, notifications: 0, total: 0 };
  $scope.timeout = null;

  $rootScope.$on('onNotification', (e, counts) => run(() => {
    $scope.counts = { ...$scope.counts, ...counts };

    if ($scope.counts.notifications > 0) {
      // clear the notification after 10 seconds
      // if the user is on the `/notifications` page
      $scope.timeout = setTimeout(() => {
        $rootScope.markAllNotificationsSeen();
      }, 10000);
    }
  }));

  $scope.$on('$destroy', () => {
    if ($scope.timeout != null) {
      clearTimeout($scope.timeout);
    }
  });
}

Controller.$inject = [
  '$rootScope',
  '$scope'
];

export default {
  bindings: {
    route: '@'
  },
  template,
  controller: Controller,
  controllerAs: '$props',
  transclude: true
};
