/**
 * @module Directives
 *
 */
import template from 'ngapp/templates/directives/_audio_player.html';
import { run } from "app/utils/runloop";

/**
 * @class AudioPlayer
 *
 */
function AudioPlayer(moment, media) {
  function link(scope, element) {
    // scope.shareable = angular.isDefined(scope.shareable) ? scope.shareable : false;

    // scope.play = function () {
    //   const resource = scope.resource;
    //   const container = element.find('.audio-container');
    //   const player = angular.element('<audio width="100%" controls="true" preload="none"></audio>');
    //   const sources = [
    //     { src: resource.versions.mp3.url, type: 'audio/mpeg' }
    //   ];

    //   container.find('.audio-player-preview').remove();
    //   container.append(player);

    //   new window.MediaElementPlayer(player, {
    //     type: ['audio/mpeg'],
    //     success: function (mediaElement, domObject) {
    //       if (!scope.shareable) {
    //         domObject.setAttribute('data-no-contextmenu', '');
    //       }
    //       mediaElement.setSrc(sources);
    //       mediaElement.load();
    //       mediaElement.play();
    //     }
    //   });
    // };

    scope.onAudioClick = onAudioClick;
    scope.onInit = onInit;
    scope.getDuration = getDuration;
    let playerId = null;

    function onAudioClick() {
      scope.isPlaying = media.togglePlayer(playerId, 'audio');
    }

    function onInit(resource) {
      const audio = element.find('.audio')[0];
      playerId = media.createPlayer(resource, audio, onStatusUpdate);
    }

    function onStatusUpdate(status) {
      run(() => {
        scope.isPlaying = status.playing;
        scope.remaining = moment.utc(1000 * status.remaining).format('mm:ss');
        scope.isReady = status.isReady;
      });
    }

    function getDuration(attachment) {
      return moment.utc(attachment.duration).format('mm:ss');
    }
  }

  return {
    restrict: 'E',
    replace: true,
    scope: {
      resource: '=',
      shareable: '='
    },
    template,
    link: link
  };
}

AudioPlayer.$inject = ['moment', 'core.services.media'];

export default AudioPlayer;
