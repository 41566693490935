/**
 * @module Controllers.ScheduledPosts
 *
 */
import { all } from 'rsvp';
import reject from 'lodash/reject';
import { run } from 'app/utils/runloop';
import uniqBy from 'lodash/uniqBy';
import template from 'ngapp/templates/views/scheduled_posts/_scheduled_posts_feed.html';

/**
 * @class ScheduledPostsFeed
 *
 */
function Controller(
  $scope,
  identity,
  Post,
  createPager
) {
  $scope.user = {};
  $scope.filters = {
    query: null,
    scheduledAt: null,
    scheduleTargetIds: null
  };
  $scope.pager = createPager();
  $scope.scheduledPosts = [];
  $scope.pending = true;
  $scope.onRemove = onRemove;
  $scope.onScroll = onScroll;
  $scope.onSearchSubmit = onSearchSubmit;
  $scope.onCalendarValueChange = onCalendarValueChange;
  $scope.onCalendarValueRemove = onCalendarValueRemove;
  $scope.onFilterReset = onFilterReset;
  $scope.onFilterSubmit = onFilterSubmit;

  function onPage(e) {
    run(() => {
      if (e != null && e.data != null && e.data.results != null) {
        const data = e.data.results;
        $scope.scheduledPosts = uniqBy([ ...$scope.scheduledPosts, ...data ], 'id');
      }
    });
  }

  loadData();

  function loadData() {
    $scope.pager.onPage.attach(onPage);

    all([
      $scope.pager.get(),
      identity.request()
    ]).then(([
      scheduledPosts,
      user
    ]) => run(() => {
      $scope.scheduledPosts = scheduledPosts;
      $scope.user = user;
      $scope.pending = false;
    }));
  }

  function onFilterReset() {
    $scope.pending = true;
    $scope.filters.query = null;
    $scope.filters.scheduledAt = null;
    $scope.filters.scheduleTargetIds = null;
    $scope.pager = createPager($scope.filters);

    loadData($scope.pager);
  }

  function onFilterSubmit(data) {
    $scope.filters.scheduleTargetIds = data.scheduleTargetIds;
    reloadPager();
  }

  function onSearchSubmit(data) {
    $scope.filters.query = data;
    reloadPager();
  }

  function onCalendarValueChange(data) {
    $scope.filters.scheduledAt = new Date(data).toISOString();
    reloadPager();
  }

  function onCalendarValueRemove() {
    $scope.filters.scheduledAt = null;
    reloadPager();
  }

  function reloadPager() {
    $scope.pending = true;
    $scope.pager = createPager($scope.filters);

    loadData($scope.pager);
  }

  function onRemove(post) {
    run(() => { $scope.scheduledPosts = reject($scope.scheduledPosts, { id: post.id }); });
  }

  function onScroll() {
    $scope.pager.next();
  }
}

Controller.$inject = [
  '$scope',
  'core.services.identity',
  'core.services.Post',
  'scheduleFeedPagerFactory'
];

export default {
  controller: Controller,
  template
};
