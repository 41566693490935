
function SerializerFactory(json, qs) {
  return {
    json: json,
    qs: qs
  };
}

SerializerFactory.$inject = [
  'core.services.$serializer.json',
  'core.services.$serializer.qs'
];

export default SerializerFactory;
