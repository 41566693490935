/**
 * @module Controllers
 *
 */
import {
  fetchPost
} from 'app/utils/request';
import {
  digest
} from 'app/utils/runloop';
import template from 'ngapp/templates/views/space_template_posts/_reorder.html';

/**
 * @class SpaceTemplatePostsReorderController
 *
 */
function SpaceTemplatePostsReorderController($scope, $translate, $location, listLoader, $routeParams, SpaceTemplate, Post, mediaHelpers, $filter) {
  $scope.spaceTemplate = {};
  $scope.posts = [];
  $scope.postParams = {
    spaceTemplateId: $routeParams.spaceTemplateId
  };
  $scope.processing = false;
  $scope.postsWithScheduledIn = [];
  $scope.postsWithoutScheduledIn = [];

  listLoader(
    $scope,
    'posts',
    () => SpaceTemplate.get($routeParams.spaceTemplateId).then((spaceTemplate) => {
      $scope.spaceTemplate = spaceTemplate;
      return Post.query({
        space_template_id: spaceTemplate.id
      });
    }),
    (posts) => {
      const filteredPosts = $filter('orderBy')(posts, 'position');
      $scope.posts.push.apply($scope.posts, posts);
      $scope.postsWithScheduledIn.push.apply($scope.postsWithScheduledIn, filteredPosts.filter(post => post.scheduledIn));
      $scope.postsWithoutScheduledIn.push.apply($scope.postsWithoutScheduledIn, filteredPosts.filter(post => !post.scheduledIn));
      $scope.postsWithScheduledIn = $filter('orderBy')($scope.postsWithScheduledIn, 'scheduledIn');
      $scope.postsWithoutScheduledIn = $filter('orderBy')($scope.postsWithoutScheduledIn, 'position');
    }
  );
  

  $scope.loadList();

  $scope.shouldDisplayScheduledAlert = function (post) {
    const index = $scope.postsWithScheduledIn.findIndex(p => p.scheduledIn === post.scheduledIn);
    return index === $scope.postsWithScheduledIn.indexOf(post);
  };

  $scope.getScheduledInValue = function (post) {
    const index = $scope.postsWithScheduledIn.findIndex(p => p.scheduledIn === post.scheduledIn);
    return index === $scope.postsWithScheduledIn.indexOf(post) ? post.scheduledIn : '';
  };

  $scope.update = () => {
    $scope.processing = true;
    const posts = {};
    $scope.postsWithoutScheduledIn.forEach((post, index) => posts[post.id] = index);

    const sortUrl = 'space_templates/' + $scope.spaceTemplate.id + '/posts/sort';
    fetchPost(sortUrl, {
        posts
      }, {
        version: 1
      })
      .then(() => digest(() => $location.path('/space_templates/' + $scope.spaceTemplate.id + '/posts')))
      .catch(() => digest(() => $translate('CONTROLLERS.SPACE_TEMPLATES_POSTS_REORDER.UPDATE_ERROR_MESSAGE').then(alert)))
      .finally(() => digest(() => $scope.processing = false));
  };

  $scope.getMediaIcon = function (attachment) {
    return mediaHelpers.getSpriteClassName(attachment);
  };
}

SpaceTemplatePostsReorderController.$inject = [
  '$scope',
  '$translate',
  '$location',
  'listLoader',
  '$routeParams',
  'SpaceTemplate',
  'core.services.Post',
  'mediaHelpers',
  '$filter'
];

export default {
  controller: SpaceTemplatePostsReorderController,
  template
};
