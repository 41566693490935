
function Audio() {
  function link(scope, element /*, attrs*/) {
    element.mediaelementplayer();
  }

  return {
    restrict: 'E',
    link: link
  };
}

export default Audio;
