/** @module directives/teams */
import template from 'ngapp/templates/views/teams/_team_import_confirm_modal.html';
import logo_orange_png from 'app/images/logo-orange.png';

/**
 * @class TeamCSVImportConfirmModal
 *
 */
function Controller($scope) {
  $scope.team = $scope.$props.team;
  $scope.members = $scope.$props.members;
  $scope.teamGroups = $scope.$props.teamGroups;
  $scope.onCancel = onCancel;
  $scope.onDone = onDone;
  $scope.logo_orange_png = logo_orange_png;

  $scope.showMembers = true;
  $scope.showGroups = true;

  function onCancel() {
    $scope.$props.modal.dismiss({ reason: 'back' });
  }

  function onDone() {
    $scope.$props.modal.close();
  }
}

Controller.$inject = [ '$scope' ];

export default {
  bindings: {
    modal: '<',
    members: '<',
    teamGroups: '<',
    team: '<',
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
