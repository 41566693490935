
import some from 'lodash/some';
import find from 'lodash/find';
import template from 'ngapp/templates/views/spaces/_transfer_space_modal.html';

function Controller($scope, SpaceMember, Space) {
  $scope.space = $scope.$props.space;
  $scope.members = [];
  $scope.fields = {
    email: '',
    spaceSport: ''
  };
  $scope.errors = {};
  $scope.pending = true;
  $scope.submitting = false;
  $scope.onCancel = onCancel;
  $scope.onSubmit = onSubmit;

  SpaceMember.get({ spaceId: $scope.space.id })
    .then(function (members) {
      $scope.members = members;
      $scope.pending = false;
    });

  function isCoachEmail(email) {
    return some($scope.members, { email: email });
  }

  function isSpaceName(sport) {
    return sport === $scope.space.sport;
  }

  function isOwner(email) {
    var member = find($scope.members, { email: email });

    if (member != null) {
      return $scope.$props.space.isOwnedByMember(member);
    }

    return false;
  }

  function onCancel() {
    $scope.$props.modal.dismiss();
  }

  function onSubmit(e) {
    e.preventDefault();

    var validationResult = validate($scope.fields);

    if (validationResult.isValid) {
      $scope.submitting = true;
      $scope.pending = true;

      Space.transfer($scope.space.id, $scope.fields.email)
        .then(function() {
          $scope.$props.modal.close({
            coach: find($scope.members, { email: $scope.fields.email })
          });
        });
    }

    $scope.errors = validationResult.errors;
  }

  function validate(fields) {
    var results = {
      errors: {},
      isValid: true
    };

    if (!isCoachEmail(fields.email)) {
      results.isValid = false;
      results.errors.email = 'invalid_member_email';
    } else if(isOwner(fields.email)) {
      results.isValid = false;
      results.errors.email = 'already_owner';
    }

    if (!isSpaceName(fields.spaceSport)) {
      results.isValid = false;
      results.errors.spaceSport = 'space_sport_mismatch';
    }

    return results;
  }
}

Controller.$inject = [ '$scope', 'core.services.SpaceMember', 'core.services.Space' ];

export default {
  bindings: {
    modal: '<',
    space: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
