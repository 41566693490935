/**
 * @module Directives
 *
 */
import intlTelInput from 'intl-tel-input';

/**
 * @class IntlTel
 *
 */
export default function() {
  function link(scope, element) {
    scope.intl = intlTelInput(element.get(0), {});

    scope.$watch('user', () => {
      if (scope.user && scope.user.phoneCountry) {
        scope.intl.setCountry(scope.user.phoneCountry.toLowerCase());
      }
    });

    element.on('focus blur keyup change', () => {
      if (scope.user) {
        const countryCode = scope.intl.getSelectedCountryData().iso2;
        if (countryCode) {
          scope.user.phoneCountry = countryCode.toUpperCase();
        }
      }
    });
  }

  return {
    restrict: "E",
    replace: true,
    template: '<input type="text"/>',
    link: link
  };
}
