/**
 * @module Config
 *
 */
import { errorLogger } from 'app/utils';

/**
 * @class Config
 *
 */
export default function (__angular) {
  var app = __angular.module('app');

  __angular.module('exceptionOverride', []).factory('$exceptionHandler', [function () {
    return function (exception, cause) {
      errorLogger(
        exception,
        {
          severity: 'error',
          metaData: { diagnostics: { cause: cause }}
        }
      );
    };
  }]);

  app.config([
    '$animateProvider',
    function ($animate) {
      $animate.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);
    }
  ]);

  app.config([
    'core.ui.toastProvider',
    function(toasts) {
      toasts.options.timeout = 4000;
    }
  ]);
}
