/** @module resources */
import { buildUrl } from 'app/utils/request/url';

/**
 * @class UserSettings
 *
 */
function UserSettings($resource) {
  const settingsUrl = buildUrl('account/notifications_settings', {}, 1);
  const resource = $resource({
    url: 'api/v1/account/notifications_settings',
    name: 'notifications_settings'
  });

  resource.settingsUrl = settingsUrl;

  return resource;
}

UserSettings.$inject = ['core.services.$resource'];

export default UserSettings;
