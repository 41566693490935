/**
 * @module Controllers.Tags
 *
 */
// import {
//   run
// } from 'app/utils/runloop';
import template from 'ngapp/templates/views/tags/_edit.html';

/**
 * @class TagsEdit
 *
 */
// function Controller($scope, $translate, $location, $routeParams, UserTag) {
  function Controller($scope, $translate, $location, $routeParams) {
  $scope.processing = true;
  $scope.tag = {};
  $scope.updateAllUsages = false;
  $scope.invalid = false;

  $scope.TagsUrl = function () {
    $location.path('/account/tags');
  };

  $scope.tagId = $routeParams.tagId;


  // UserTag.get($routeParams.tagId)
  //   .then(tag => run(() => {
  //     $scope.tag = tag;
  //     $scope.processing = false;
  //   }))
  //   .catch(() => {
  //     run(() => $scope.processing = false);
  //     $translate('CONTROLLERS.TAGS.LOAD_ERROR_MESSAGE').then(alert);
  //   });

  // $scope.update = () => {
  //   $scope.invalid = false;
  //   if ($scope.tag.name != null) {
  //     $scope.tag.name = $scope.tag.name.trim();
  //   }

  //   if (!$scope.tag.name.length) {
  //     $scope.invalid = true;
  //     return;
  //   }

  //   $scope.processing = true;
  //   $scope.tag.updateAllUsages = $scope.updateAllUsages;
  //   $scope.tag.update()
  //     .then(() => {
  //       $scope.processing = false;
  //       $location.path('/account/tags');
  //     })
  //     .catch(error => {
  //       $scope.processing = false;
  //       if (error.status === 422) {
  //         alert(error.data);
  //       } else {
  //         $translate('CONTROLLERS.TAGS_EDIT.UPDATE_ERROR_MESSAGE').then(alert);
  //       }
  //     });
  // };
}

Controller.$inject = [
  '$scope',
  '$translate',
  '$location',
  '$routeParams',
  'UserTag'
];

export default {
  controller: Controller,
  template
};
