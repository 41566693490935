
import 'fancybox';

window.$(function() {
  window.$("a.help-video-play").fancybox({
    content: '<video id="help-video-player"></video>',
    beforeShow: function() {
      const videoParams = this.element.data();
      window.createVideoPlayer(videoParams, '#help-video-player');
    }
  });
});
