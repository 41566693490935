/**
 * @module Directives
 *
 */

/**
 * @class Readmore
 *
 */
function Readmore($, $css, $translate, onResize) {
  var MAX_HEIGHT = 200;

  function link($scope, $element) {
    var unsubscribeFromResize = onResize(update);
    var destroyed = false;
    var collapsed = false;
    var $container = $element.parent();
    var $trigger = $('<a class="mb-1" href="#" data-dismiss="readmore"></a>')
      .css({ display: 'inline-block' })
      .text($translate.instant('READMORE.EXPAND_LINK'));

    var unsubscribeFromScope = $scope.$watch(function() {
      update();
    });

    $scope.$on('$destroy', function() {
      destroy();
    });

    $container.on('click.readmore', '[data-dismiss="readmore"]', function() {
      destroy();
    });

    function update() {
      if (!destroyed) {
        if ($element[0].scrollHeight > MAX_HEIGHT) {
          if (!collapsed) {
            $element.addClass('readmore');
            $element.css({ maxHeight: MAX_HEIGHT, overflow: 'hidden' });
            $trigger.insertAfter($element);
            collapsed = true;
          }
        } else if (collapsed) {
          $element.removeClass('readmore');
          $element.css({ maxHeight: '', overflow: '' });
          $trigger.remove();
          collapsed = false;
        }
      }
    }

    function destroy() {
      if (!destroyed) {
        $container.off('click.readmore');
        $element.removeClass('readmore');
        $trigger.remove();

        expand();
        unsubscribeFromResize();
        unsubscribeFromScope();
      }

      destroyed = true;
    }

    function expand() {
      return $css($element, {
        from: { maxHeight: $element[0].offsetHeight + 'px', overflow: 'hidden' },
        to: { maxHeight: $element[0].scrollHeight + 'px' },
        easing: 'ease-in',
        duration: 0.2
      })
      .start()
      .then(function() {
        $element.css({ maxHeight: '', overflow: '' });
      });
    }
  }

  return {
    restrict: 'A',
    link: link
  };
}

Readmore.$inject = ['$', '$animateCss', '$translate', 'core.ui.resize'];

export default Readmore;
