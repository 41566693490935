/**
 * @module Controllers.Segments
 *
 */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/segments/_edit.html';

/**
 * @class SegmentEdit
 *
 */
function Controller($scope, $location, $routeParams, $translate, Segment) {
  $scope.processing = true;
  $scope.segment = {};
  $scope.resourceId = $routeParams.segmentId;

  Segment.get($routeParams.segmentId)
    .then(segment => run(() => {
      $scope.segment = segment;
      $scope.processing = false;
    }))
    .catch(() => {
      run(() => $scope.processing = false);
      $translate('CONTROLLERS.SEGMENT_EDIT.LOAD_ERROR_MESSAGE').then(alert);
    });

  $scope.update = () => {
    $scope.processing = true;

    $scope.segment.update()
      .then(() => {
        $scope.processing = false;
        $location.path('/lists');
      })
      .catch(() => {
        $scope.processing = false;
        $translate('CONTROLLERS.SEGMENT_EDIT.UPDATE_ERROR_MESSAGE').then(alert);
      });
  };
}

Controller.$inject = [
  '$scope',
  '$location',
  '$routeParams',
  '$translate',
  'Segment'
];

export default {
  controller: Controller,
  template
};
