
import http401ErrorInterceptor from './http_401_error_interceptor';
import http402ErrorInterceptor from './http_402_error_interceptor';
import http403ErrorInterceptor from './http_403_error_interceptor';
import http404ErrorInterceptor from './http_404_error_interceptor';
import http500ErrorInterceptor from './http_500_error_interceptor';
import httpArrayQueryHandler from './http_array_query_handler';

export default function(__angular) {
  __angular.module('interceptors')
    .factory('http401ErrorInterceptor', http401ErrorInterceptor)
    .factory('http402ErrorInterceptor', http402ErrorInterceptor)
    .factory('http403ErrorInterceptor', http403ErrorInterceptor)
    .factory('http404ErrorInterceptor', http404ErrorInterceptor)
    .factory('http500ErrorInterceptor', http500ErrorInterceptor)
    .factory('httpArrayQueryHandler', httpArrayQueryHandler);
}
