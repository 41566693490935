
export default function highlight() {
  return function (text, search, caseSensitive) {
    if (text && (search || angular.isNumber(search))) {
      text = text.toString();
      search = search.toString();
      if (caseSensitive) {
        return text.split(search).join('<span class="hl">' + search + '</span>');
      } else {
        return text.replace(new RegExp(search, 'gi'), '<span class="hl">$&</span>');
      }
    } else {
      return text;
    }
  };
}
