/**
 * @module Directives.Account
 *
 */
import template from 'ngapp/templates/views/account/_account_wrap.html';

/**
 * @class AccountWrap
 *
 */
function Controller($root, $scope) {
  $scope.route = $scope.$props.route;
  $scope.hasBillingTab = hasBillingTab;
  $scope.user = $root.user;

  $root.$on('userUpdated', (event, user) => {
    $scope.user = user;
  });

  function hasBillingTab(user) {
    return user != null && user.hasBillingInfo;
  }
}

Controller.$inject = ['$rootScope', '$scope'];

export default {
  bindings: {
    route: '@'
  },
  template,
  controller: Controller,
  controllerAs: '$props',
  transclude: true
};

