/**
 * @module Controllers.Tags
 *
 */
// import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/tags/_delete.html';

/**
 * @class TagDelete
 *
 */
function Controller($scope, $translate, $location, $routeParams) {
  $scope.processing = true;
  $scope.tag = {};
  $scope.deleteAllUsages = false;

  $scope.TagsUrl = function () {
    $location.path('/account/tags');
  };

  $scope.tagId = $routeParams.tagId;


  // UserTag.get($routeParams.tagId)
  //   .then(tag => run(() => {
  //     $scope.tag = tag;
  //     $scope.processing = false;
  //   }))
  //   .catch(() => {
  //     run(() => $scope.processing = false);
  //     $translate('CONTROLLERS.TAGS.LOAD_ERROR_MESSAGE').then(alert);
  //   });

  // $scope.remove = () => {
  //   $scope.processing = true;
  //   $scope.tag.deleteWithParams({deleteAllUsages: $scope.deleteAllUsages})
  //     .then(() =>  {
  //       $scope.processing = false;
  //       $location.path('/tags');
  //     })
  //     .catch(() => {
  //       $scope.processing = false;
  //       $translate('CONTROLLERS.TAGS_DELETE.DELETE_ERROR_MESSAGE').then(alert);
  //     });
  // };
}

Controller.$inject = [
  '$scope',
  '$translate',
  '$location',
  '$routeParams',
  'UserTag'
];

export default {
  controller: Controller,
  template
};

