
function SegmentModalService($modal) {
  function createSegment() {
    return $modal.open({
      template: '<new-segment-modal modal="modal"></new-segment-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
      }]
    });
  }

  return {
    createSegment: createSegment
  };
}

SegmentModalService.inject = ['$modal'];

export default SegmentModalService;
