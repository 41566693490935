/** @module directives/teams */
import csv_import_png from 'app/images/csv-import.png';
import csv_import_progress_modal_template from 'ngapp/templates/views/teams/_csv_import_progress_modal.html';

/**
 * @class TeamCsvImportModals
 *
 */
function TeamCSVImportModals($modal) {
  function selectFile() {
    return $modal.open({
      template: `
        <team-csv-select-file-modal
          modal="modal"
        ></team-csv-select-file-modal>
      `,
      controller: ['$scope', '$modalInstance', function($scope, modal) {
        $scope.modal = modal;
      }]
    });
  }

  function roleSelector(canNavigateBack) {
    return $modal.open({
      template: `
        <team-csv-role-selector-modal
          modal="modal"
          allow-back="allowBack"
        ></team-csv-role-selector-modal>
      `,
      controller: ['$scope', '$modalInstance', function($scope, modal) {
        $scope.modal = modal;
        $scope.allowBack = canNavigateBack;
      }]
    });
  }

  function sheetSelector(workbook, sheet) {
    return $modal.open({
      template: `
        <team-csv-sheet-selector-modal
          modal="modal"
          workbook="workbook"
          sheet="sheet"
        ></team-csv-sheet-selector-modal>
      `,
      controller: ['$scope', '$modalInstance', function($scope, modal) {
        $scope.modal = modal;
        $scope.workbook = workbook;
        $scope.sheet = sheet;
      }]
    });
  }

  function attributeSelector(sheet, columns) {
    return $modal.open({
      template: `
        <team-csv-attribute-selector-modal
          modal="modal"
          sheet="sheet"
          columns="columns"
        ></team-csv-attribute-selector-modal>
      `,
      controller: ['$scope', '$modalInstance', function($scope, modal) {
        $scope.modal = modal;
        $scope.sheet = sheet;
        $scope.columns = columns;
      }]
    });
  }

  function columnSelector(sheet, column) {
    return $modal.open({
      template: `
        <team-csv-column-selector-modal
          modal="modal"
          sheet="sheet"
          column="column"
        ></team-csv-column-selector-modal>
      `,
      controller: ['$scope', '$modalInstance', function($scope, modal) {
        $scope.modal = modal;
        $scope.column = column;
        $scope.sheet = sheet;
      }]
    });
  }

  function importProgress() {
    return $modal.open({
      keyboard: false,
      template: csv_import_progress_modal_template, //templateUrl: 'teams.csv-import-progress-modal'
      controller: ['$scope', function($scope) {
        $scope.csv_import_png = csv_import_png;
      }]
    });
  }

  function importSummary(team, imported, invited, incomplete) {
    return $modal.open({
      template: `
        <team-csv-import-summary-modal
          modal="modal"
          team="team"
          imported="imported"
          invited="invited"
          incomplete="incomplete"
        ></team-csv-import-summary-modal>
      `,
      controller: ['$scope', '$modalInstance', function($scope, modal) {
        $scope.modal = modal;
        $scope.team = team;
        $scope.imported = imported;
        $scope.invited = invited;
        $scope.incomplete = incomplete;
      }]
    });
  }

  return {
    attributeSelector: attributeSelector,
    columnSelector: columnSelector,
    selectFile: selectFile,
    roleSelector: roleSelector,
    sheetSelector: sheetSelector,
    importProgress: importProgress,
    importSummary: importSummary
  };
}

TeamCSVImportModals.$inject = ['$modal'];

export default TeamCSVImportModals;

