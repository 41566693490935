
import { isBlank } from 'app/utils';
import template from 'ngapp/templates/views/teams/_team_post_disabled.html';

function Controller($scope, $translate) {
  $scope.onUpgradeClick = onUpgradeClick;
  $scope.upgradePrompt = $scope.$props.upgradePrompt;

  if (isBlank($scope.upgradePrompt)) {
    $scope.upgradePrompt = $translate.instant('TEAMS.POST_FORM.UPGRADE_TO_POST');
  }

  function onUpgradeClick() {
    $scope.$props.onUpgradeClick();
  }
}

Controller.$inject = ['$scope', '$translate'];

export default {
  bindings: {
    onUpgradeClick: '&',
    upgradePrompt: '@'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
