/**
 * @module Directives.Paywalls
 *
 */
import { digest } from 'app/utils/runloop';
import template from 'ngapp/templates/views/paywalls/_modal_footer.html';

/**
 * @class ModalFooter
 *
 */
function Controller($scope, $root, $, tracking, Trial, Upgrade) {
  $scope.user = null;
  $scope.pending = true;
  $scope.showTrial = false;
  $scope.onStartTrialClick = onStartTrialClick;
  $scope.onUpgradeClick = onUpgradeClick;

  Trial.status().then(status => {
    $scope.pending = false;
    $scope.showTrial = status != null && status.canStartTrial;
    digest();
  });

  $scope.toggleCalendly = (status) => {
    if (status) {
      $('#legacy-modal-dialog').hide();
    } else {
      // delay allows the react-dialog to close
      // before showing the upgrade dialog.
      setTimeout(() => {
        $('#legacy-modal-dialog').show();
      }, 200);
    }
  };

  function onStartTrialClick() {
    $scope.pending = true;
    tracking.paywalls.trialSelected($scope.$props.feature);
    Trial.start().then(() => {
      $scope.pending = false;
      $scope.$props.modal.close();
      Trial.started();
      digest();
    });
  }

  function onUpgradeClick() {
    $scope.pending = true;
    tracking.paywalls.upgradeSelected($scope.$props.feature);

    Upgrade.upgrade($scope.$props.plan);
  }
}

Controller.$inject = [
  '$scope',
  '$rootScope',
  '$',
  'core.services.tracking',
  'core.services.Trial',
  'core.services.Upgrade'
];

export default {
  bindings: {
    plan: '@',
    feature: '<',
    modal: '<'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
