/**
 * @module Directives
 *
 */
import template from 'ngapp/templates/directives/_video_player.html';

/**
 * @class VideoPlayer
 *
 */
function VideoPlayer(ClientHelper) {
  function link(scope, element) {
    scope.shareable = angular.isDefined(scope.shareable) ? scope.shareable : false;

    scope.isSmallPreview = function() {
      return scope.previewSize === 'small';
    };

    scope.video = {
      resource:  scope.resource,
      horizonatal: function() {
        return this.resource.width > this.resource.height;
      },
      vertical: function() {
        return !this.horizonatal();
      },
      stretch: function() {
        return this.horizonatal() ||
              (ClientHelper.smartphone() && this.vertical()) ||
              (ClientHelper.tablet()     && this.resource.width > 320);
      },
      playerWidth: function() {
        if ( this.stretch() ) { return '100%'; } else { return this.maxPlayerSize().width; }
      },
      playerHeight: function() {
        if ( this.stretch() ) { return '100%'; } else { return this.maxPlayerSize().height; }
      },
      maxPlayerSize: function() {
        var maxWidth = 538, maxHeight = 480;
        var srcWidth = this.resource.width, srcHeight = this.resource.height;
        var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
        return { width: Math.round(srcWidth*ratio), height: Math.round(srcHeight*ratio) };
      },
      playerSources: function() {
        return [{
          src: this.resource.versions.default.url,
          type: 'video/mp4'
        }];
      },
      playerTypes: function() {
        return ['video/mp4'];
      }
    };

    scope.play = function() {
      const player = angular.element('<div/>');
      const container = element.find('.video-container');
      const video = scope.video;

      container.find('.video-player-preview').remove();
      container.append(player);

      new window.MediaElementPlayer(player, {
        type: video.playerTypes(),
        videoWidth: video.playerWidth(),
        videoHeight:video.playerHeight(),
        success(mediaElement, domObject) {
          if (!scope.shareable) {
            domObject.setAttribute('data-no-contextmenu', '');
          }
          mediaElement.setSrc(video.playerSources());
          mediaElement.load();
          mediaElement.play();
        }
      });
    };
  }

  return {
    restrict: 'E',
    replace: true,
    scope: {
      resource: '=',
      shareable: '=',
      previewSize: '@'
    },
    template,
    link: link
  };
}

VideoPlayer.$inject = ['ClientHelper'];

export default VideoPlayer;
