/**
 * @module Directives
 *
 */
import template from 'ngapp/templates/directives/_image.html';

/**
 * @class EdufiiImage
 *
 */
function EdufiiImage() {
  function link(scope /*, element, attrs*/) {
    if (scope.previewSize && scope.previewSize === 'small') {
      scope.previewSrc = scope.resource.smallUrl;
    } else {
      scope.previewSrc = scope.resource.mediumUrl;
    }

    scope.fullSrc = scope.resource.originalUrl;
  }

  return {
    restrict: 'E',
    replace: true,
    scope: {
      resource: '=',
      previewSize: '@'
    },
    template,
    link: link
  };
}

export default EdufiiImage;
