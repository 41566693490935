
function factory(Signal) {
  var $handle = '$teamGroupMemberEvents';

  return {
    $handle: $handle,
    onMembersAdded: new Signal({ handle: $handle }),
    onMemberRemoved: new Signal({ handle: $handle })
  };
}

factory.$inject = ['core.Signal'];

export default factory;
