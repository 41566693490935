/** @module controllers/teams */
import reject from 'lodash/reject';
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/teams/_members.html';

/**
 * @class TeamMembersController
 *
 */
function Controller($scope, $params, $q, $location, teamWrapModel, TeamGroup, createPager) {
  $scope.teamId = $params.id;
  $scope.team = null;
  $scope.myGroups = null;
  $scope.joinableGroups = null;
  $scope.members = [];
  $scope.pending = true;
  $scope.status = $params.status || 'accepted';
  $scope.filters = {};
  $scope.onMemberRemoved = onMemberRemoved;
  $scope.onMembersInvited = onMembersInvited;
  $scope.onStatusChange = onStatusChange;
  $scope.onFilter = onFilter;
  $scope.onPage = onPage;
  $scope.onJoin = onJoin;

  let pager = null;
  const membersRequest = recreatePager();
  const teamWrapModelRequest = teamWrapModel.find($scope.teamId).then(result => {
    run(() => {
      $scope.team = result.team;
      $scope.myGroups = result.myGroups;
      $scope.joinableGroups = result.joinableGroups;
    });
  });

  $scope.reloadGroups = () => {
    teamWrapModel.reload().then(result => {
      run(() => {
        $scope.team = result.team;
        $scope.myGroups = result.myGroups;
        $scope.joinableGroups = result.joinableGroups;
      });
    });
  };

  $q.all([membersRequest, teamWrapModelRequest]).then(() =>
    run(() => $scope.pending = false)
  );

  const setMembers = (members) => {
    run(() => $scope.members = members);
  };

  function onMemberRemoved(member) {
    setMembers(reject($scope.members, { id: member.id }));
  }

  function onMembersInvited() {
    recreatePager();
  }

  function onStatusChange(status) {
    $scope.status = status;
    recreatePager();
  }

  function onPage() {
    pager.next();
  }

  function recreatePager() {
    if (pager != null) {
      pager.destroy();
    }
    pager = createPager($scope.teamId, Object.assign({}, { status: $scope.status }, $scope.filters));
    pager.onPage.attach((e) => setMembers([ ...$scope.members, ...e.data.results]));
    return pager.get().then(members => setMembers(members));
  }

  function onFilter(filters) {
    $scope.filters = filters;
    recreatePager();
  }

  function onJoin(group) {
    $scope.pending = true;
    TeamGroup.join(group).then(() => {
      teamWrapModel.find(group.team.id).then((result) => {
        run(() => {
          $scope.myGroups = result.myGroups;
          $scope.joinableGroups = result.joinableGroups;
          $scope.pending = false;
          $location.path(group.timelineUrl);
        });
      });
    });
  }
}

Controller.$inject = [
  '$scope',
  '$routeParams',
  '$q',
  '$location',
  'teamWrapModel',
  'core.services.TeamGroup',
  'teamMemberPagerFactory'
];

export default {
  controller: Controller,
  template
};

