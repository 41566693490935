import template from 'ngapp/templates/views/space_template_posts/_list.html';

function SpaceTemplatePostsController($scope, $translate, $location, listLoader, $routeParams, SpaceTemplate, Post) {
  $scope.spaceTemplate = {};
  $scope.posts = [];
  $scope.pending = true;
  $scope.canApplyTemplate = true;
  $scope.activeTab = 'Posts';
  $scope.tabHandler = tabHandler;

  $scope.postParams = {
    spaceTemplateId: $routeParams.spaceTemplateId
  };

  function tabHandler(tabName) {
    $scope.activeTab = tabName;
  }

  listLoader($scope, 'posts', function () {
    return SpaceTemplate.get($routeParams.spaceTemplateId).then(function (spaceTemplate) {
      $scope.spaceTemplate = spaceTemplate;
      return Post.query({
        space_template_id: spaceTemplate.id,
        order_by: 'scheduled_in',
        order: 'asc'
      });
    });
  }, function (posts) {
    $scope.posts = posts;
    $scope.pending = false;
  });

  $scope.loadList();

  $scope.$on('loadListEvent', function () {
    $scope.loadList();
  });

  $scope.editTemplate = function (spaceTemplate) {
    $location.path('/space_templates/' + spaceTemplate.id + '/edit');
  };

  $scope.removeTemplate = function (spaceTemplate) {
    spaceTemplate.remove().then(function () {
      $location.path('/space_templates/');
    }, function () {
      $translate('CONTROLLERS.SPACE_TEMPLATE_POSTS.SPACE_TEMPLATES_DELETE_ERROR_MESSAGE').then(alert);
    });
  };

  $scope.remove = function (post) {
    post.remove().then(function () {
      var index = $scope.posts.indexOf(post);
      $scope.posts.splice(index, 1);
    }, function () {
      $translate('CONTROLLERS.SPACE_TEMPLATE_POSTS.POST_DELETE_ERROR_MESSAGE').then(alert);
    });
  };

  // $scope.onEdit = function () {
  //   $scope.pending = true;
  //   $scope.loadList();
  // };

  $scope.onTogglePin = function (post) {
    $scope.pending = true;

    post.toggle_pin().then(function () {
      $scope.loadList();
    });
  };


}

SpaceTemplatePostsController.$inject = [
  '$scope',
  '$translate',
  '$location',
  'listLoader',
  '$routeParams',
  'SpaceTemplate',
  'core.services.Post',
];

export default {
  controller: SpaceTemplatePostsController,
  template
};
