/** @module directives/teams */
import template from 'ngapp/templates/views/teams/_member_invite_button.html';

/**
 * @class TeamMemberInviteButton
 *
 */
function Controller($scope, teamInviteFlow) {
  $scope.onInviteButtonClick = onInviteButtonClick;

  function onInviteButtonClick() {
    teamInviteFlow($scope.$props.team)
      .then(function() {
        $scope.$props.onInvitesSent();
      });
  }
}

Controller.$inject = ['$scope', 'teamInviteFlow'];

export default {
  bindings: {
    team: '<',
    onInvitesSent: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
