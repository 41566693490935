
function SpaceTemplateModal($modal) {
  function newTemplate() {
    return $modal.open({
      template: '<new-template-modal modal="modal"></new-template-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
      }]
    });
  }

  return {
    newTemplate: newTemplate
  };
}

SpaceTemplateModal.inject = ['$modal'];

export default SpaceTemplateModal;
