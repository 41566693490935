
function http403ErrorInterceptor($q, $location) {
  return {
    responseError: function(response) {
      if (response.status === 403) {
        $location.path('/403');
        $location.replace();
      }
      return $q.reject(response);
    }
  };
}

http403ErrorInterceptor.$inject = ['$q', '$location'];

export default http403ErrorInterceptor;
