/**
 * @module Directives.Paywalls
 *
 */

/**
 * @class PaywallModalService
 *
 */
function PaywallModal($modal) {
  function proUpgrade() {
    return $modal.open({
      template:  '<paywalls-pro-upgrade-modal modal="modal"></paywalls-pro-upgrade-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
      }]
    });
  }

  function explicit() {
    return $modal.open({
      template:  '<paywalls-explicit-modal modal="modal"></paywalls-explicit-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
      }]
    });
  }

  function library() {
    return $modal.open({
      template:  '<paywalls-library-modal modal="modal"></paywalls-library-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
      }]
    });
  }

  function postMedia() {
    return $modal.open({
      template:  '<paywalls-post-media-modal modal="modal"></paywalls-post-media-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
      }]
    });
  }

  function postMediaOtherOwner() {
    return $modal.open({
      template:  '<paywalls-post-media-other-owner-modal modal="modal"></paywalls-post-media-other-owner-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
      }]
    });
  }

  function segments() {
    return $modal.open({
      template:  '<paywalls-segments-modal modal="modal"></paywalls-segments-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
      }]
    });
  }

  function spaceLimit() {
    return $modal.open({
      template:  '<paywalls-space-limit-modal modal="modal"></paywalls-space-limit-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
      }]
    });
  }

  function spaceLimitReadOnly() {
    return $modal.open({
      template:  '<paywalls-space-limit-read-only-modal modal="modal"></paywalls-space-limit-read-only-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
      }]
    });
  }

  function templates() {
    return $modal.open({
      template:  '<paywalls-templates-modal modal="modal"></paywalls-templates-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
      }]
    });
  }

  function pinnedPosts() {
    return $modal.open({
      template:  '<paywalls-pinned-posts-modal modal="modal"></paywalls-pinned-posts-modal>',
      controller: ['$modalInstance', '$scope', function ModalController(modal, $local) {
        $local.modal = modal;
      }]
    });
  }

  return {
    proUpgrade: proUpgrade,
    explicit: explicit,
    library: library,
    postMedia: postMedia,
    postMediaOtherOwner: postMediaOtherOwner,
    segments: segments,
    spaceLimit: spaceLimit,
    spaceLimitReadOnly: spaceLimitReadOnly,
    templates: templates,
    pinnedPosts: pinnedPosts
  };
}

PaywallModal.inject = ['$modal'];

export default PaywallModal;
