
import template from 'ngapp/templates/views/resources/_post.html';

// function ResourcePostController($scope, $location, $routeParams, Resource, mediaHelpers, postHelpers) {
  function ResourcePostController($scope, $location, $routeParams) {
  // $scope.pending = true;
  // $scope.posts = [];
  $scope.resourceId = $routeParams.resourceId;
  // $scope.attachment = null;
  // $scope.onPost = onPost;
  // $scope.cancel = cancel;

  // Resource.get($scope.resourceId)
  //   .then(function(resource) {
  //     $scope.attachment = mediaHelpers.toAttachment(resource);
  //     $scope.pending = false;
  //   });

  // function onPost(post) {
  //   $scope.pending = true;
  //   $location.path(postHelpers.getLocationUri(post));
  // }

  // function cancel() {
  //   $location.path('/resources');
  // }
}

ResourcePostController.$inject = [
  '$scope',
  '$location',
  '$routeParams',
  'Resource',
  'mediaHelpers',
  'postHelpers'
];

export default {
  controller: ResourcePostController,
  template
};
