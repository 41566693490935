
function factory($createTeamCachePolicy, Team) {
  return function create() {
    var teamCachePolicy = $createTeamCachePolicy(Team);

    function onRemove(teamId) {
      teamCachePolicy.onRemoveMember(teamId);
    }

    return {
      onRemove: onRemove
    };
  };
}

factory.$inject = [
  'core.services.cache.$teamCachePolicyFactory',
  'core.services.Team'
];

export default factory;
