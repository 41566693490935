/**
  * Generates a v4 uid, without dashes.
  *
  * @returns {string}
  */
var v4 = (function () {
  /**
    * Produce 4 byte random number buffer.
    *
    * @return {number[]}
    */
  function s4() {
    var value = (Math.random() * 0x100000000) >>> 0;

    return [
      value & 0xff,
      (value >> 8) & 0xff,
      (value >> 16) & 0xff,
      (value >> 24) & 0xff
    ];
  }

  /**
    * Turns a number[] buffer into a uuid string, without hypthens.
    *
    * @param {number[]} buffer
    * @returns {string}
    */
  function stringify(buffer) {
    var builder = [];

    for (var index = 0; index < buffer.length; index++) {
      builder.push(('00' + buffer[index].toString(16)).slice(-2));
    }

    return builder.join('');
  }

  /**
    * Generate next v4 uuid.
    *
    * @returns {string}
    */
  function v4() {
    var bytes = [].concat(s4(), s4(), s4(), s4());
    bytes[6] = (bytes[6] & 0x0f) | 0x40;
    bytes[8] = (bytes[8] & 0x3f) | 0x80;

    return stringify(bytes);
  }

  return v4;
})();

/**
  * Generates a short, dom compatible id. These are not globally unique.
  *
  * @returns {string}
  */
var short = (function () {
  var counter = 0;

  /**
    * Formats sequence value to printable string.
    *
    * @param {number} value
    * @returns {string}
    */
  function stringify(value) {
    return value.toString(36);
  }

  /**
    * Generates next sid in sequence.
    *
    * @returns {string}
    */
  function short() {
    return ['s',  stringify(counter++)].join('-');
  }

  return short;
})();

/**
  * Creates a hash id from a string
  *
  * @param {string} value
  * @returns {string}
  */
function digest(value) {
  var result = (2 << 31) - 1;

  for (var i = 0; i < value.length; i++) {
    result += (37 * value.charCodeAt(i)) & 0xffffffff;
  }

  return result.toString(36);
}

export default function factory() {
  return {
    v4: v4,
    short: short,
    digest: digest
  };
}
