
function TransferSpaceFlowFactory($location, transferSpaceModal) {
  function TransferSpaceFlow(space) {
    this._space = space;
  }

  TransferSpaceFlow.prototype.start = function start() {
    var self = this;

    return transferSpaceModal.transferOwnership(self._space).result
      .then(function (e) {
        $location.path('/spaces');
        return self._showConfirmation(e.member);
      });
  };

  TransferSpaceFlow.prototype._showConfirmation = function _showConfirmation(member) {
    return transferSpaceModal.transferComplete(member);
  };

  return function start(space) {
    return new TransferSpaceFlow(space).start();
  };
}

TransferSpaceFlowFactory.inject = ['$location', 'transferSpaceModal'];

export default TransferSpaceFlowFactory;
