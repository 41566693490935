
function animation($css) {
  function enter(element, done) {
    done();
  }

  function leave(element, done) {
    return $css(element, {
      from: { opacity: 1, transform: 'scale(1)' },
      to: { opacity: 0, transform: 'scale(0)' },
      duration: 0.125,
      easing: 'ease-in'
    })
    .start()
    .then(done);
  }

  return { leave: leave, enter: enter };
}

animation.$inject = ['$animateCss'];

export default animation;
