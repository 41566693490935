
import template from 'ngapp/templates/views/segment_spaces/_list.html';

function SegmentSpacesController($scope, $location, listLoader, $routeParams, $translate, Segment, Space) {
  $scope.segment = {};
  $scope.spaces = [];

  listLoader($scope, 'spaces', function() {
    return Segment.get($routeParams.segmentId).then(function (segment) {
      $scope.segment = segment;

      var queryParams = {
        'segment_ids': [segment.id],
        'type': 'athlete'
      };

      return Space.query(queryParams);
    });
  }, function (spaces) {
    $scope.spaces = spaces;
  });

  $scope.loadList();

  $scope.removeSpace = function(space) {
    var spaceIndex   = $scope.spaces.indexOf(space);
    var spaceIdIndex = $scope.segment.spaceIds.indexOf(space.id);

    if (spaceIdIndex > -1) {
      $scope.segment.spaceIds.splice(spaceIdIndex, 1);
      $scope.segment.update().then(function () {
        $scope.spaces.splice(spaceIndex, 1);
      }, function () {
        $translate('CONTROLLERS.SEGMENT_SPACES.REMOVE_SPACE_ERROR_MESSAGE').then(alert);
      });
    }
  };

  $scope.remove = function(segment) {
    segment.remove().then(function() {
      $location.path('/lists');
    }, function(){
      $translate('CONTROLLERS.SEGMENT_SPACES.REMOVE_ERROR_MESSAGE').then(alert);
    });
  };
}

SegmentSpacesController.$inject = [
  '$scope',
  '$location',
  'listLoader',
  '$routeParams',
  '$translate',
  'Segment',
  'Space'
];

export default {
  controller: SegmentSpacesController,
  template
};
