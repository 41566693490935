
import URIMethod from 'urijs/src/URI';
import URITemplateMethod from 'urijs/src/URITemplate';

export function URI() {
  return URIMethod;
}

URI.$inject = [];

export function URITemplate() {
  return URITemplateMethod;
}

URITemplate.$inject = [];
