/**
 * @module Directives.Teams
 *
 */
import template from 'ngapp/templates/views/teams/_feed.html';
import null_feed_png from 'app/images/null-feed.png';

/**
 * @class TeamFeed
 *
 */
function Controller($scope) {
  $scope.canPost = $scope.$props.canPost;
  $scope.canPostMedia = $scope.$props.canPostMedia;
  $scope.feed = $scope.$props.feed;
  $scope.pending = true;
  $scope.user = $scope.$props.user;
  $scope.team = $scope.$props.team;
  $scope.isDemo = $scope.$props.isDemo;
  $scope.onEdit = onEdit;
  $scope.onLike = onLike;
  $scope.onMediaLockedClick = onMediaLockedClick;
  $scope.onRemove = onRemove;
  $scope.onReply = onReply;
  $scope.onReport = onReport;
  $scope.onTogglePin = onTogglePin;
  $scope.onScroll = onScroll;
  $scope.onUpgradeToPostClick = onUpgradeToPostClick;
  $scope.$props.$onChanges = $onChanges;
  $scope.null_feed_png = null_feed_png;

  function onEdit(post) {
    $scope.$props.onEdit({ post: post });
  }

  function onLike(post) {
    $scope.$props.onLike({ post: post });
  }

  function onMediaLockedClick() {
    $scope.$props.onMediaLockedClick();
  }

  function onRemove(post) {
    $scope.$props.onRemove({ post: post });
  }

  function onTogglePin(post) {
    $scope.$props.onTogglePin({ post: post });
  }

  function onReply(reply) {
    $scope.$props.onReply({ reply: reply });
  }

  function onReport(e) {
    $scope.$props.onReport({ event: e });
  }

  function onScroll() {
    $scope.$props.onScroll();
  }

  function onUpgradeToPostClick() {
    $scope.$props.onUpgradeToPostClick();
  }

  function $onChanges(props) {
    if (props.feed != null) {
      $scope.feed = props.feed.currentValue;
    }

    if (props.pending != null) {
      $scope.pending = props.pending.currentValue;
    }
  }
}

Controller.$inject = ['$scope'];

export default {
  bindings: {
    canPost: '<',
    canPostMedia: '<',
    pending: '<',
    feed: '<',
    user: '<',
    team: '<',
    isDemo: '<',
    onEdit: '&',
    onLike: '&',
    onMediaLockedClick: '&',
    onUpgradeToPostClick: '&',
    onRemove: '&',
    onReply: '&',
    onReport: '&',
    onTogglePin: '&',
    onScroll: '&'
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
