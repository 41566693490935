
function ActiveReply($, $timeout) {
  function link(scope, element, attrs) {
    scope.$watch(attrs.activeReply, function(isActive) {
      if (isActive) {
        var scrollPos = element.offset().top;
        $('body,html').animate({scrollTop: scrollPos}, 'slow', function() {
          element.addClass('viewed_reply');

          $timeout(function() {
            element.removeClass('viewed_reply');
          }, 1000);
        });
      }
    });
  }

  return {
    restrict: 'A',
    link: link
  };
}

ActiveReply.$inject = ['$', '$timeout'];

export default ActiveReply;
