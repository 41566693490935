
function TransferTeamModal($modal) {
  function transferOwnership(team) {
    return $modal.open({
      template: '<transfer-team-modal team="team" modal="modal"></transfer-team-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $scope) {
        $scope.modal = $modal;
        $scope.team = team;
      }]
    });
  }

  function transferComplete(coach) {
    return $modal.open({
      template: '<transfer-team-complete-modal coach="coach" modal="modal"></transfer-team-complete-modal>',
      controller: ['$modalInstance', '$scope', function ModalController($modal, $scope) {
        $scope.modal = $modal;
        $scope.coach = coach;
      }]
    });
  }

  return {
    transferOwnership: transferOwnership,
    transferComplete: transferComplete
  };
}

TransferTeamModal.inject = ['$modal'];

export default TransferTeamModal;
