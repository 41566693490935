/** @module legacy/app/controllers */
import { run } from 'app/utils/runloop';
import template from 'ngapp/templates/views/activity/_notifications.html';

/**
 * @class NotificationsController
 *
 */
function Notifications($scope, activityCollection) {
  $scope.pending = true;
  $scope.paging = false;
  $scope.notifications = [];
  $scope.unread = 0;
  $scope.onRead = onRead;
  $scope.onReadAll = onReadAll;
  $scope.onScroll = onScroll;

  let unsubscribeFromNotificationActivity = null;

  activityCollection.query().then(e => run(() => {
    unsubscribeFromNotificationActivity = e.notifications.onChange.attach(onNotificationActivity);
    $scope.notifications = e.notifications.values;
    $scope.unread = e.notifications.unread;
    $scope.pending = false;
  }));


  $scope.$on('$destroy', function () {
    if (unsubscribeFromNotificationActivity) {
      unsubscribeFromNotificationActivity();
    }
  });

  function onRead(notification) {
    notification.goto();
    activityCollection.query().then(e =>
      e.notifications.read(notification.id)
    );
  }

  function onReadAll() {
    activityCollection.query().then(e =>
      e.notifications.readAll()
    );
  }

  function onScroll() {
    $scope.paging = true;
    activityCollection.query()
      .then(e => e.notifications.fetch())
      .finally(() => run(() => $scope.paging = false));
  }

  function onNotificationActivity(e) {
    $scope.notifications = e.data.values;
    $scope.unread = e.data.unread;
  }
}

Notifications.$inject = ['$scope', 'core.services.activityCollection'];

export default {
  controller: Notifications,
  template
};

