
function animation($css) {
  function px(value) {
    return value + 'px';
  }

  function enter(element, done) {
    done();
  }

  function leave(element, done) {
    return $css(element, {
      from: { opacity: 1, height: px(element[0].offsetHeight), overflow: 'hidden' },
      to: { opacity: 0, height: px(0), overflow: 'hidden' },
      duration: 0.2,
      easing: 'ease-out'
    })
    .start()
    .then(done);
  }

  return { leave: leave, enter: enter };
}

animation.$inject = ['$animateCss'];

export default animation;
