/**
 * @module Directives.BulkPost.Locations
 *
 */

/**
 * @class SegmentLocation
 *
 */
function smartListLocation($property, BulkPostLocation) {
  function smartListLocation(destination) {
    BulkPostLocation.call(this, destination);
  }

  smartListLocation.prototype = Object.create(BulkPostLocation.prototype);

  $property.get(smartListLocation.prototype, 'paywallModalName', function() {
    return BulkPostLocation.MODAL_KINDS.SEGMENTS;
  });

  return smartListLocation;
}

smartListLocation.$inject = ['core.$property', 'bulk_post.locations.$bulkPostLocation'];

export default smartListLocation;
