
import snakeCase from 'lodash/snakeCase';
import map from 'lodash/map';

function QSFactory() {
  function stringify(values) {
    if (values == null) {
      return '';
    }

    return map(values, function (value, key) {
      return [encodeURIComponent(snakeCase(key)), encodeURIComponent(value)].join('=');
    }).join('&');
  }

  return {
    stringify: stringify
  };
}

QSFactory.$inject = [];

export default QSFactory;
