/**
 * @module Services
 *
 */
import { run } from 'app/utils/runloop';

/**
 * @class ListPaging
 *
 */
function ListPaging($timeout) {
  return function(scope, perPage) {
    const oldLoadList = scope.loadList;
    let scrollDisableTimeout;

    scope.page = 1;

    scope.$on('startLoading', (event) => {
      if (event.targetScope !== scope) { return; }
      run(() => scope.scrollDisabled = true);
      event.stopPropagation();
    });

    scope.$on('finishLoading', (event) => {
      if (event.targetScope !== scope) { return; }

      if (scrollDisableTimeout) {
        $timeout.cancel(scrollDisableTimeout);
      }

      scrollDisableTimeout = $timeout(() => run(() =>
        scope.scrollDisabled = false
      ), 1000);

      event.stopPropagation();
    });

    scope.loadList = (beforeHandler) => oldLoadList((data) => {
      if (beforeHandler) {
        beforeHandler(data);
      }
      setIsAllLoaded(data);
    });

    function setIsAllLoaded(items) {
      run(() => scope.allLoaded  = items.length < perPage);
    }

    scope.showMore = () => run(() => {
      scope.page += 1;
      scope.loadList();
    });

    scope.isAllLoaded = () => {
      return scope.allLoaded === true;
    };

    scope.canShowLoadMore = () => {
      return !scope.isAllLoaded() && !scope.isLoading();
    };

    scope.isScrollDisabled = () => {
      return (scope.isLoading() || scope.scrollDisabled || scope.isAllLoaded());
    };
  };
}

ListPaging.$inject = ['$timeout'];

export default ListPaging;
